import axios from "../client/axios";
import config from "../config/index";

// //console.log('config ', config);
export default {
  BANK_SEARCH: ({ keyword, fetch }) => {
    ////console.log("search bank api: ")
    return axios({
      //headers: {},
      method: "POST",
      url: `${config.BANK_SEARCH}`,
      data: {
        keyword: keyword,
        fetch: fetch,
      },
    });
  },
  BANK_SEARCH_TYPE: ({ keyword, enable }) => {
    //console.log("search bank type api: ")
    return axios({
      //headers: {},
      method: "POST",
      url: `${config.BANK_SEARCH_TYPE}`,
      data: { keyword: keyword, enable: enable },
    });
  },
  BANK_CREATE: (data) => {
    //console.log("create bank api: ")
    return axios({
      //headers: {},
      method: "POST",
      url: `${config.BANK_CREATE}`,
      data: data,
    });
  },
  BANK_UPDATE: (data) => {
    //console.log("update bank api: ")
    return axios({
      //headers: {},
      method: "POST",
      url: `${config.BANK_UPDATE}`,
      data: data,
    });
  },
  BANK_TIER_SEARCH: () => {
    //console.log("banking search tier")
    return axios({
      method: "GET",
      url: `${config.BANK_TIER_SEARCH}`,
    });
  },
  BANK_TIER_UPDATE: (data) => {
    //console.log("banking update tier")
    return axios({
      method: "POST",
      url: `${config.BANK_TIER_UPDATE}`,
      data: data,
    });
  },
  BANK_GENERATE_OTP: (data) => {
    //console.log("banking generate otp")
    return axios({
      method: "POST",
      url: `${config.BANK_GENERATE_OTP}`,
      data: data,
    });
  },
  BANK_CONFIRM_OTP: (data) => {
    //console.log("banking confirm otp")
    return axios({
      method: "POST",
      url: `${config.BANK_CONFIRM_OTP}`,
      data: data,
    });
  },
  BANK_TESTING: (data) => {
    //console.log("banking test")
    return axios({
      method: "POST",
      url: `${config.BANK_TESTING}`,
      data: data,
    });
  },
  BANK_LIST_MANUAL_LOGIN: () => {
    return axios({
      method: "GET",
      url: `${config.BANK_LIST_MANUAL_LOGIN}`,
    });
  },
  BANK_SUBMIT_MANUAL_LOGIN: (data) => {
    return axios({
      method: "POST",
      url: `${config.BANK_SUBMIT_MANUAL_LOGIN}`,
      data: data,
    });
  },
  GET_WITHDRAW_LIMIT_SETTING: () => {
    return axios({
      method: "GET",
      url: `${config.GET_WITHDRAW_SETTING}`,
    });
  },
  UPDATE_WITHDRAW_SETTING: (data) => {
    return axios({
      method: "POST",
      url: `${config.UPDATE_WITHDRAW_SETTING}`,
      data: data,
    });
  },
  GET_UPDATE_WITHDRAW_TIME_CONFIG: () => {
    return axios({
      method: "GET",
      url: `${config.GET_UPDATE_WITHDRAW_TIME_CONFIG}`,
    });
  },
  UPDATE_WITHDRAW_TIME_CONFIG: (data) => {
    return axios({
      method: "POST",
      url: `${config.UPDATE_WITHDRAW_TIME_SETTING}`,
      data: data,
    });
  },
  BANK_DELETE: (data) => {
    return axios({
      method: "POST",
      url: `${config.BANK_DELETE}`,
      data: data,
    });
  },
  BANK_STATEMENTS: (data) => {
    return axios({
      method: "POST",
      url: `${config.BANK_STATEMENTS}`,
      data: data,
    });
  },
  BANK_STATEMENTS_DETAILS: (data) => {
    return axios({
      method: "POST",
      url: `${config.BANK_STATEMENTS_DETAILS}`,
      data: data,
    });
  },
  BANK_VIEW_PASSWORD: (data) => {
    return axios({
      method: "POST",
      url: `${config.BANK_VIEW_PASSWORD}`,
      data: data,
    });
  },
  BANK_VIEW_PASSWORD_LOGS: (data) => {
    return axios({
      method: "POST",
      url: `${config.BANK_VIEW_PASSWORD_LOGS}`,
      data: data,
    });
  },
  BANK_STATEMENTS_ERROR: (data) => {
    return axios({
      method: "POST",
      url: `${config.BANK_STATEMENTS_ERROR}`,
      data: data,
    });
  },
  STATEMENT_DELAY_CONFIG: () => {
    return axios({
      method: "GET",
      url: `${config.STATEMENT_DELAY_CONFIG}`,
    });
  },
  UPDATE_STATEMENT_DELAY_CONFIG: (data) => {
    return axios({
      method: "POST",
      url: `${config.UPDATE_STATEMENT_DELAY_CONFIG}`,
      data: data,
    });
  },
  GET_QR_TRANSFER_CONFIG: (data) => {
    return axios({
      method: "GET",
      url: `${config.QR_TRANSFER_CONFIG}`,
      // data: data,
    });
  },
  GET_BANK_TRANSFER_CONFIG: (data) => {
    return axios({
      method: "GET",
      url: `${config.BANK_TRANSFER_CONFIG}`,
      // data: data,
    });
  },
  QR_TRANSFER_CONFIG: (data) => {
    return axios({
      method: "POST",
      url: `${config.UPDATE_QR_TRANSFER_CONFIG}`,
      data: data,
    });
  },
  BANK_TRANSFER_CONFIG: (data) => {
    return axios({
      method: "POST",
      url: `${config.UPDATE_BANK_TRANSFER_CONFIG}`,
      data: data,
    });
  },
  BANK_VIEW_FACE_IMAGES: (data) => {
    return axios({
      method: "POST",
      url: `${config.VIEW_BANK_FACE_IMAGES}`,
      data: data,
    });
  },
  BANK_UPDATE_STATUS: (data) => {
    return axios({
      method: "POST",
      url: `${config.BANK_UPDATE_STATUS}`,
      data: data,
    });
  },
  BANK_SETTLEMENT_SEARCH: (data) => {
    return axios({
      method: "POST",
      url: `${config.SETTLEMENT_SEARCH}`,
      data: data,
    });
  },
  READ_STATEMENT: (data) => {
    return axios({
      method: "POST",
      url: `${config.READ_STATEMENT}`,
      data: data,
    });
  },
  GET_VIZPAY_CONFIG: (data) => {
    return axios({
      method: "GET",
      url: `${config.GET_VIZPAY_CONFIG}`,
      data: data,
    });
  },
  UPDATE_VIZPAY_CONFIG: (data) => {
    return axios({
      method: "POST",
      url: `${config.UPDATE_VIZPAY_CONFIG}`,
      data: data,
    });
  },
  GET_ACCOUNT_CONFIG: (data) => {
    return axios({
      method: "GET",
      url: `${config.GET_ACCOUNT_CONFIG}`,
      data: data,
    });
  },
  UPDATE_ACCOUNT_CONFIG: (data) => {
    return axios({
      method: "POST",
      url: `${config.UPDATE_ACCOUNT_CONFIG}`,
      data: data,
    });
  },
  VALIDATE_VIZ_KEYS:(data)=>{
    return axios({
      method: "POST",
      url: `${config.VALIDATE_VIZ_KEYS}`,
      data: data,
    });
  },
  GET_COREPAY_CONFIG:(data)=>{
    return axios({
      method: "GET",
      url: `${config.GET_COREPAY_CONFIG}`,
      data: data,
    });
  },
  UPDATE_COREPAY_CONFIG:(data)=>{
    return axios({
      method: "POST",
      url: `${config.UPDATE_COREPAY_CONFIG}`,
      data: data,
    });
  },
  GET_HENGPAY_CONFIG:(data)=>{
    return axios({
      method: "GET",
      url: `${config.GET_HENGPAY_CONFIG}`,
      data: data,
    });
  },
  UPDATE_HENGPAY_CONFIG:(data)=>{
    return axios({
      method: "POST",
      url: `${config.UPDATE_HENGPAY_CONFIG}`,
      data: data,
    });
  },
  UPDATE_AUTOSWEEP:(data)=>{
    return axios({
      method: "POST",
      url: `${config.UPDATE_AUTO_SWEEP}`,
      data: data,
    });
  },
  DELETE_AUTOSWEEP:(data)=>{
    return axios({
      method: "POST",
      url: `${config.DELETE_AUTO_SWEEP}`,
      data: data,
    });
  },
  GET_PERFECTPAYS_CONFIG:(data)=>{
    return axios({
      method: "GET",
      url: `${config.GET_PERFECTPAYS_CONFIG}`,
      data: data,
    });
  },
  UPDATE_PERFECTPAYS_CONFIG:(data)=>{
    return axios({
      method: "POST",
      url: `${config.UPDATE_PERFECTPAYS_CONFIG}`,
      data: data,
    });
  },
  GET_PAYONEX_CONFIG:(data)=>{
    return axios({
      method: "GET",
      url: `${config.GET_PAYONEX_CONFIG}`,
      data: data,
    });
  },
  UPDATE_PAYONEX_CONFIG:(data)=>{
    return axios({
      method: "POST",
      url: `${config.UPDATE_PAYONEX_CONFIG}`,
      data: data,
    });
  },
  GET_WEALTHWAVE_CONFIG:(data)=>{
    return axios({
      method: "GET",
      url: `${config.GET_WEALTHWAVE_CONFIG}`,
      data: data,
    });
  },
  UPDATE_WEALTHWAVE_CONFIG:(data)=>{
    return axios({
      method: "POST",
      url: `${config.UPDATE_WEALTHWAVE_CONFIG}`,
      data: data,
    });
  },
  GET_MINIMUM_WITHDRAW_AMOUNT:(data)=>{
    return axios({
      method: "GET",
      url: `${config.GET_MINIMUM_WITHDRAW_AMOUNT}`,
      data: data,
    });
  },
  UPDATE_MINIMUM_WITHDRAW_AMOUNT:(data)=>{
    return axios({
      method: "POST",
      url: `${config.UPDATE_MINIMUM_WITHDRAW_AMOUNT}`,
      data: data,
    });
  },
  GET_GATEWAY_DEPOSIT_AMOUNT:(data)=>{
    return axios({
      method: "GET",
      url: `${config.GET_GATEWAY_DEPOSIT_AMOUNT}`,
      data: data,
    });
  },
  UPDATE_GATEWAY_DEPOSIT_AMOUNT:(data)=>{
    return axios({
      method: "POST",
      url: `${config.UPDATE_GATEWAY_DEPOSIT_AMOUNT}`,
      data: data,
    });
  },
};
