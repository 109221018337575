import React, { useState } from 'react'
import {Modal, Button, Form, Col, Spinner} from 'react-bootstrap'
import api_workorder from "../../api/workorder"
import { useGlobalStore } from '../../store/globalContext'

export default function ConfirmRejectWorkOrder(props) {
    const [{ subscribe, dashboard_work_order_deposit, customers, status }, dispatch] = useGlobalStore();
    const { data, isShow, onClose, callBack } = props
    const [show, setShow] = useState(isShow);
    const { id, user_id, first_name, last_name, amount, credit_status } = data
    const [comment, setComment] = useState("")
    const [isLoading, setLoading] = useState(false);

    const RejectWorkOrder = async()=>{
        setLoading(true)
        console.log("PROPS credit_status: ", credit_status, "comment: ", comment)
        const work_order_delete_request = {
            id: id,
            comment: comment
        }
        console.log("update work_order_deposit_request : ", work_order_delete_request)

        try {
            await api_workorder.REJECT_WORKORDER(work_order_delete_request)
            dispatch({
                type: "TOAST2",
                value: {
                    show: true,
                    type: 'success',
                    title: `ยกเลิกใบงาน`,
                    message: `ยกเลิกใบงานใบงาน UserID ${user_id} สำเร็จ`,
                }
            });
        } catch (e) {
            console.log("reject work order failed", e)
        } finally {
            setTimeout(() => {
                setLoading( false );
                callBack();
            }, 500)
        }
    }

    return (
        <div>
            <Modal show={isShow} onHide={onClose} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>ยกเลิกใบงาน</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Row>
                            <Form.Group as={Col} md="3" lg="3">
                                <Form.Label>UserID</Form.Label>
                                <Form.Control type="text" value={user_id} readOnly={true}/>
                            </Form.Group>
                            <Form.Group as={Col} md="3" lg="3">
                                <Form.Label>ชื่อ</Form.Label>
                                <Form.Control type="text" value={first_name} readOnly={true}/>
                            </Form.Group>
                            <Form.Group as={Col} md="3" lg="3">
                                <Form.Label>นามสกุล</Form.Label>
                                <Form.Control type="text" value={last_name} readOnly={true}/>
                            </Form.Group>
                            <Form.Group as={Col} md="3" lg="3">
                                <Form.Label>จำนวนเงิน</Form.Label>
                                <Form.Control type="text" value={amount} readOnly={true}/>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Label>หมายเหตุ</Form.Label>
                            <Form.Control type="text" value={comment}onChange={(e) => setComment(e.target.value)}/>
                        </Form.Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={RejectWorkOrder} disabled={isLoading}>
                        {isLoading ?
                            (
                            <>
                            <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        /> กำลังดำเนินการ</>)  : 'ยืนยันยกเลิกใบงาน'}
                    </Button>
                    <Button variant="secondary" onClick={onClose}>
                        ยกเลิก
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
