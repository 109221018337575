import React, {useState, useEffect, useMemo} from 'react'
import {Button, Card, Col, Form, FormControl, InputGroup, Spinner} from 'react-bootstrap'
import TableCCUsersCardComponent from './TableCCUsersCardComponent'
import { useGlobalStore } from '../../store/globalContext'
import api from "../../api/ccusers";
import GlobalTable from "../manage_customer/GlobalTable";
import moment from "moment/moment";
import {useForm} from "react-hook-form";
import api_dashboard from "../../api/dashboard";
import {useRecoilState} from "recoil";
import {notificationsObjectRecoilState} from "../../store/atoms";


export const CCWorkOrder = () =>{
  const {register, reset, handleSubmit, errors} = useForm({
    defaultValues: {
      start_date: moment().subtract(7, "days").format("YYYY-MM-DD"),
      end_date: moment().format("YYYY-MM-DD"),
    }
  })
  const [buttonLoading, setButtonLoading] = useState(false)
  const [notificationObject, setNotificationObject]= useRecoilState(notificationsObjectRecoilState)
  const [ccWorkOrderData, setCCWorkOrderData] = useState([])
  const ccWorkOrderDataMemo = useMemo(()=>{
    return ccWorkOrderData
  },[ccWorkOrderData])

  const submitCCStatistic = async (request)=>{
    console.log(request)
    try {
        setButtonLoading(true)
        const response = await api_dashboard.CC_WORK_ORDERS({
          start: request.start_date,
          end: request.end_date
        })
        console.log("response:", response.data)
      const {data} = response.data

      const dataNewHeader = data.map((d)=>{
        return {
                Username: d.username,
                ใบงานรวมกะเช้า: d.total_day,
                ใบงานรวมกะดึก: d.total_night,
                ใบงานรวมทั้งหมด: d.total
        }
      })
      console.log("dataNewHeader:", dataNewHeader)
      setCCWorkOrderData(dataNewHeader)
    }catch (e) {
        console.log("error get cc stats: ", e)
      setNotificationObject({
        text: `ขออภัยไม่สามารถทำรายการได้ในขณะนี้ กรุณาทำรายการใหม่ภายหลัง`, type: "error", icon:<i className={`fas fa-times-circle bg-danger`}/>
      })
    }finally {
        setButtonLoading(false)
    }

  }

  return(
      <Card className={"card-secondary"}>
        <Card.Header>CC Statistic</Card.Header>
        <Card.Body>
          <Form noValidate onSubmit={handleSubmit(submitCCStatistic)}>
            <Form.Row>
            {/*<Form.Group as={Col} controlId={"inlineFormInputGroupStartDate"}>*/}
                <Col sm={12} md={5} lg={5} xl={5}>
                  <Form.Label srOnly>
                    2020-09-21
                  </Form.Label>
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text>Start-Date</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                        type="date"
                        name="start_date"
                        // id="inlineFormInputGroupStartDate"
                        placeholder="2020-09-10"
                        // onChange={(e) =>
                        //     setStartDate(
                        //         moment(e.target.value).format("YYYY-MM-DD")
                        //     )
                        // }
                        isInvalid={!!errors.start_date}
                        ref={register({ required: true })}
                        // defaultValue={moment().format("YYYY-MM-DD")}
                    />
                    <Form.Control.Feedback type="invalid" tooltip>
                      กรุณาระบุวันที่เริ่มต้น
                    </Form.Control.Feedback>
                  </InputGroup>
                </Col>
            {/*</Form.Group>*/}
            {/*  <Form.Group as={Col} id={"inlineFormInputGroupEndDate"}>*/}
                <Col sm={12} md={5} lg={5} xl={5}>
                  <Form.Label htmlFor="inlineFormInputGroupEndDate" srOnly>
                    2020-09-21
                  </Form.Label>
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text>End-Date</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                        type="date"
                        name="end_date"
                        id="inlineFormInputGroupEndDate"
                        placeholder="2020-09-21"
                        // onChange={(e) =>
                        //     setEndDate(moment(e.target.value).format("YYYY-MM-DD"))
                        // }
                        isInvalid={!!errors.end_date}
                        ref={register({ required: true })}
                        // defaultValue={moment().format("YYYY-MM-DD")}
                    />
                    <Form.Control.Feedback type="invalid" tooltip>
                      กรุณาระบุวันที่สิ้นสุด
                    </Form.Control.Feedback>
                  </InputGroup>
                </Col>
                <Col sm={12} md={2} lg={2} xl={2}>
                  {/*{loading ? (*/}
                  {/*    <Button type="button" disabled={true}>*/}
                  {/*      <Spinner*/}
                  {/*          as="span"*/}
                  {/*          animation="border"*/}
                  {/*          size="sm"*/}
                  {/*          role="status"*/}
                  {/*          aria-hidden="true"*/}
                  {/*      />{" "}*/}
                  {/*      กำลังค้นหา*/}
                  {/*    </Button>*/}
                  {/*) : (*/}
                      <Button type="submit" disabled={buttonLoading}>
                        {
                          (buttonLoading) ? (<Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />):( <i className={"fas fa-search"} />)
                        }
                      </Button>
                  {/*)}*/}
                </Col>
            {/*</Form.Group>*/}
            </Form.Row>
          </Form>

          <div className={"pt-4"}>
          {
              (ccWorkOrderDataMemo.length >0) &&
            <GlobalTable tableData={ccWorkOrderDataMemo} pageSizing={100} />
          }
          </div>
        </Card.Body>
      </Card>
  )

}


export default function ManageCCUsers() {
  const [{login_profile, subscribe, status }, dispatch] = useGlobalStore();
  const [ccusers, setUsers] = useState([])

  const keySearchInput = async(searchInput) => {
    // console.log("cc users search input :", searchInput)
    let searchData = {
      keyword: ""
    }

    if (searchInput !== "" && searchInput?.length !== 0 && searchInput?.length < 4) {
      return
    }

    // console.log("searchInput :", searchInput)
    searchData = {
      keyword: searchInput
    }

    try {
      const response = await api.SEARCH_CC_USER(searchData)
      if (response.status !== 200) {
        //console.log("get CC user failed ");
        return
      }
      //console.log("get CC user response :", response);
      const { data } = response.data
      //console.log("get CC user data :", data);
      setUsers(data)
    } catch (error) {
      //console.log("error get CC user data :", error);
    }
  }

  useEffect(() => {
    void keySearchInput("")
  }, [])

  return (
    <section className="content pt-4 pr-4 pl-4">
      <div className="container-fluid">
        <div>
          {/* <Alert variant="danger" show={show} onClose={handleClose} dismissible>
            <Alert.Heading>{errorMessage.alertHeading}</Alert.Heading>
            <p>
              {errorMessage.content}
            </p>
          </Alert> */}
          <Card className="card-primary">
            <Card.Header>จัดการ CC Users</Card.Header>
            <Card.Body>
            <label htmlFor="searchCCUsers">ค้นหา CC Users</label>
              <input type="text" name="searchCCUsers" className="form-control"
                id="searchCCUsers" placeholder="login id, ชื่อ - นามสกุล"
                onKeyUp={(e) => keySearchInput(e.target.value)}
              />
              <TableCCUsersCardComponent ccUserSearchList={ccusers} callBack={keySearchInput}/>
            </Card.Body>
            {/* <Card.Footer>
              <Button className="btn-success">เพิ่ม CC Users</Button>
            </Card.Footer> */}
          </Card>
        </div>
        <div hidden={login_profile.name !== "Programmer Admin"}>
          <CCWorkOrder/>
        </div>
      </div>
    </section>
  )
}
