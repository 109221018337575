import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button, Col, Form } from 'react-bootstrap'
import moment from "moment";
import { useGlobalStore } from "../../store/globalContext";

export default function EditABNormalStatement ( { data, onSubmitForm, onClose, alertError } ) {
    const [ { login_profile } ] = useGlobalStore();
    const { register, handleSubmit, watch, errors, setValue, getValues } = useForm( {
        defaultValues: { ...data, button_name:"save" }, criteriaMode: "all"
    } );

    const [ buttonIsLoading, setButtonIsLoading ] = useState( false );
    const [ disableButton, setDisableButton ] = useState( false );

    const [ validated, setValidated ] = useState( false );

    const onSubmit = editData => {
        //console.log("submit data: ", {...editData})
        setButtonIsLoading( true );
        onSubmitForm(
            {
                updateData: {
                    id: data?.id,
                    comment: editData.comment,
                    completed: false,
                }
            }
        );
    };

    const onDelete = () => {
        setButtonIsLoading( true );
        onSubmitForm(
            {
                updateData: {
                    id: data?.id,
                    comment: `${getValues("comment")} ตรวจสอบแล้วโดย ${login_profile.name}`,
                    completed: true,
                }
            }
        )
        // onClose()
    }

    useEffect( () => {
        if ( alertError !== "" ) {
            setButtonIsLoading( false )
        }
    }, [ alertError ] )

    return (
        <Form noValidate validated={validated} onSubmit={handleSubmit( onSubmit )}>
            <div>
                <p className="font-semibold text-lg">ชื่อบัญชี: <span
                    className={"text-red-500"}>{data?.bank_account_name}</span></p>
                <p className="font-semibold text-lg">ธนาคาร: <span
                    className={"text-red-500"}>{data?.bank_short_name.toUpperCase()}</span></p>
                <p className="font-semibold text-lg">เลขที่บัญชี: <span
                    className={"text-red-500"}>{data?.account_no}</span></p>
                <p className="font-semibold text-lg">วันเวลาที่ทำรายการ: <span
                    className={"text-red-500"}>{
                    ( data?.transaction_at ) ?
                        moment( data?.transaction_at ).format( "DD/MM/YYYY HH:mm:ss" )
                        : ( "" )
                }</span></p>
                <p className="font-semibold text-lg">ยอดเงินที่โอนออก: <span
                    className={"text-red-500"}>{data?.debit}</span></p>
                <p className="font-semibold text-lg">Teller: <span
                    className={"text-red-500"}>{data?.teller}</span></p>
                <p className="font-semibold text-lg">Channel: <span
                    className={"text-red-500"}>{data?.channel}</span></p>
                <p className="font-semibold text-lg">{data?.description}</p>
            </div>
            <Form.Row className={"mt-4"}>
                <Form.Group as={Col} md="12" lg="12" controlId="validation26">
                    <Form.Label>หมายเหตุ</Form.Label>
                    <Form.Control
                        type="text"
                        as="textarea" rows={3}
                        name="comment"
                        defaultValue={data?.comment}
                        ref={register}
                        placeholder="ใส่หมายเหตุเพิ่มเติม เช่น กำลังตรวจสอบ"
                    />
                </Form.Group>
            </Form.Row>
            {buttonIsLoading
                ? ( <Button>
                    <i className="fas fa-circle-notch fa-spin"/> ระบบกำลังดำเนินการ
                </Button> )
                : <>
                    {'  '}<Button type="submit" variant="primary" >บันทึกการแก้ไข</Button>
                    {'  '}<Button type = "button" variant="success" onClick={()=>onDelete()}>ยืนยันการตรวจสอบ</Button>
                    {'  '}<Button variant="secondary" onClick={onClose}>ปิด</Button>
                </>
            }
        </Form>
    )
}