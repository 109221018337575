import {Button, Card, Col, Form} from "react-bootstrap";
import React, {useEffect} from "react";
import {useForm} from "react-hook-form";
import api_bank from "../../api/managebank";
import {useGlobalStore} from "../../store/globalContext";
import { number_format } from "../utils/Number";


export default function ManageMinimumWithdraw(){
    const [,dispatch] =
        useGlobalStore();
    const { register:registerMinimum, handleSubmit:handleSubmitMinimum, setValue:setValueMinimum, getValues:getValuesMinimum, formState:{isDirty:isDirtyMinimum}, reset:resetMinimum} = useForm({
        defaultValues:{withdraw_amount_limit:0},
        shouldUnregister: false
    });

    const { register:registerGatewayAmount, handleSubmit:handleSubmitGatewayAmount, setValue:setValueGatewayAmount, getValues:getValuesGatewayAmount, formState:{isDirty:isDirtyGatewayAmount}, reset:resetGatewayAmount} = useForm({
        defaultValues:{min_gateway_deposit_amount: 0, max_gateway_deposit_amount:1},
        shouldUnregister: false
    });

    const getMinimumAmount = async()=>{
            try {
                const response = await api_bank.GET_MINIMUM_WITHDRAW_AMOUNT()
                const {data} = response.data
                setValueMinimum("withdraw_amount_limit", data.withdraw_amount_limit)
            }catch (error){
                console.log("error get minimum withdraw amount: ", error)
            }
    }

    const updateMinimumAmount = async (submitData)=>{

        try {
            const request = {
                withdraw_amount_limit: submitData.withdraw_amount_limit
            }
            const response = await api_bank.UPDATE_MINIMUM_WITHDRAW_AMOUNT(request)
            const {data} = response.data
            // console.log("get minimum withdraw amount", data)
            resetMinimum({})
            setValueMinimum("withdraw_amount_limit", submitData.withdraw_amount_limit)
            dispatch({
                type: "TOAST2",
                value: {
                    show: true,
                    type: "success",
                    title: `แก้ไขตั้งค่าจำนวนเงินถอนขั้นต่ำ`,
                    message: `แก้ไขตั้งค่าจำนวนเงินถอนขั้นต่ำ สำเร็จ`,
                },
            });
        }catch (error){
            console.log("error update minimum withdraw amount: ", error)
            dispatch({
                type: "TOAST2",
                value: {
                    show: true,
                    type: "error",
                    title: `แก้ไขตั้งค่าจำนวนเงินถอนขั้นต่ำ`,
                    message: `แก้ไขตั้งค่าจำนวนเงินถอนขั้นต่ำ ไม่สำเร็จ ${error.response?.data.message}`,
                },
            });
        }
    }

    const getGatewayDepositAmount = async()=>{
        try {
            const response = await api_bank.GET_GATEWAY_DEPOSIT_AMOUNT()
            const {data} = response.data
            setValueGatewayAmount("min_gateway_deposit_amount", data.min_gateway_deposit_amount)
            setValueGatewayAmount("max_gateway_deposit_amount", data.max_gateway_deposit_amount)
        }catch (error){
            console.log("error get minimum withdraw amount: ", error)
        }
    }

    const updateGatewayDepositAmount = async (submitData)=>{

        try {
            const request = {
                min_gateway_deposit_amount: submitData.min_gateway_deposit_amount,
                max_gateway_deposit_amount: submitData.max_gateway_deposit_amount
            }

            // console.log("update gateway deposit amount: ", request)

            const response = await api_bank.UPDATE_GATEWAY_DEPOSIT_AMOUNT(request)
            const {data} = response.data
            console.log("update gateway deposit amount", data)
            resetGatewayAmount({})
            setValueGatewayAmount("min_gateway_deposit_amount", submitData.min_gateway_deposit_amount)
            setValueGatewayAmount("max_gateway_deposit_amount", submitData.max_gateway_deposit_amount)
            dispatch({
                type: "TOAST2",
                value: {
                    show: true,
                    type: "success",
                    title: `แก้ไขตั้งค่าจำนวนเงินฝากขั้นต่ำ/สูงสุด`,
                    message: `แก้ไขตั้งค่าจำนวนเงินฝากขั้นต่ำ/สูงสุด สำเร็จ`,
                },
            });
        }catch (error){
            console.log("error update gateway deposit amount: ", error)
            dispatch({
                type: "TOAST2",
                value: {
                    show: true,
                    type: "error",
                    title: `แก้ไขตั้งค่าจำนวนเงินฝากขั้นต่ำ/สูงสุด`,
                    message: `แก้ไขตั้งค่าจำนวนเงินฝากขั้นต่ำ/สูงสุด ไม่สำเร็จ ${error.response?.data.message}`,
                },
            });
        }
    }

    useEffect(()=>{
        void getMinimumAmount()
        void getGatewayDepositAmount()
    },[])

    return(
        <Card className="flex card-danger">
            <Card.Header>จัดการฝาก/ถอน ขั้นต่ำ - สูงสุด แสดงหน้าลูกค้า</Card.Header>
            <Card.Body>
                <Form id="gateway_deposit_amount" onSubmit={handleSubmitGatewayAmount(updateGatewayDepositAmount)}>
                    <Form.Row>
                        <Form.Group as={Col} className={"col-6"}>
                            <Form.Label>จำนวนเงินฝากต่ำสุดแสดงหน้าลูกค้าผ่าน GATEWAY (บาท)</Form.Label>
                            <Form.Control
                                type="number"
                                name="min_gateway_deposit_amount"
                                placeholder="ฝากขั้นต่ำจำนวน"
                                defaultValue={1}
                                ref={registerGatewayAmount({valueAsNumber:true})}
                            />
                        </Form.Group>
                        <Form.Group as={Col} className={"col-6"}>
                            <Form.Label>จำนวนเงินฝากสูงสุดแสดงหน้าลูกค้าผ่าน GATEWAY (บาท)</Form.Label>
                            <Form.Control
                                type="number"
                                name="max_gateway_deposit_amount"
                                placeholder="ฝากขั้นต่ำจำนวน"
                                defaultValue={1}
                                ref={registerGatewayAmount({valueAsNumber:true})}
                            />
                        </Form.Group>
                    </Form.Row>
                    <Button className={"btn-success"}
                            type={"submit"}
                            disabled={isDirtyGatewayAmount === false}
                    >
                        เปลี่ยนการตั้งค่าฝาก
                    </Button>
                </Form>
                <Form id="withdraw_minimum_amount" onSubmit={handleSubmitMinimum(updateMinimumAmount)} className={"pt-4"}>
                    <Form.Row>
                        <Form.Group as={Col} className={"col-6"}>
                            <Form.Label>จำนวนเงินถอนขั้นต่ำที่แสดงหน้าลูกค้า(บาท)</Form.Label>
                            <Form.Control
                                type="number"
                                name="withdraw_amount_limit"
                                placeholder="ถอนขั้นต่ำจำนวน"
                                defaultValue={0}
                                ref={registerMinimum({valueAsNumber:true})}
                            />
                        </Form.Group>
                    </Form.Row>
                    <Button className={"btn-danger"}
                            type={"submit"} disabled={isDirtyMinimum === false}
                    >
                        เปลี่ยนการตั้งค่า
                    </Button>
                </Form>
            </Card.Body>
        </Card>
    )
}