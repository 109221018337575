import React, {useEffect, useRef, useState} from 'react'
import {
    Badge,
    Button,
    Card,
    Col,
    Form,
    FormControl,
    InputGroup,
    Modal,
    Pagination,
    Spinner,
    Table,
} from 'react-bootstrap'
import {useGlobalStore} from '../../store/globalContext'
import api_workorder from "../../api/workorder"
import moment from 'moment';
import {CustomerRetryModal} from "./ModalCustomerAccept";


export default function RefundCustomersAccept() {
    const [searchButton, setSearchButton] = useState(false);
    const [{login_profile}, dispatch] = useGlobalStore();
    const [startDate, setStartDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
    const [effectiveDate, setEffectiveDate] = useState(moment().format("YYYY-MM-DD"))
    const [refundFiles, setRefundFiles] = useState(undefined);
    const [dataTable, setDataTable] = useState([])
    const [searchInput, setSearchInput] = useState("")
    const [currentPage, setCurrentPage] = useState(1)
    const [dictLastIndices, setDictLastIndices] = useState({1: ""})
    const limitPage = 100
    const [modalProps, setModalProps] = useState({
        show: false,
        data: {},
        closeModal: {}
    })
    const uploadFileRef = useRef()

    const [showConfirmUploadModal, setShowConfirmUploadModal] = useState(false);
    const [confirmInput, setConfirmInput] = useState("");

    const renderStatus = (status) => {
        if (status === "success") {
            return <td><h5><Badge variant="success">{"สำเร็จ"}</Badge></h5></td>;
        } else if (status === "pending") {
            return <td><h5><Badge variant="info">{"รอลูกค้ากดรับ"}</Badge></h5></td>;
        } else {
            return <td><h5><Badge variant="info">{"ไม่สำเร็จ"}</Badge></h5></td>;
        }
    }

    const searchRefundWithoutKeyword = async () => {

        const response = await api_workorder.SEARCH_REFUND_BONUS(
            {
                keyword: searchInput,
                date: startDate,
                limit: limitPage,
                last_element_id: dictLastIndices[currentPage]
            }
        )

        if (response.status !== 200) {
            console.log(`search customer by user id failed`, response.message);
            return
        }
        console.log("search refund customer response : ", dictLastIndices)
        if (response.data.data === undefined) {
            return
        }
        const {bonuses, last_index} = response.data.data
        if (bonuses === undefined) {
            return
        }

        console.log("search bonus ", response.data.data)

        setDataTable(bonuses)
        if (bonuses) {
            const newIndex = {...dictLastIndices, [currentPage + 1]: bonuses[bonuses.length - 1].id}
            setDictLastIndices(newIndex)
        }
    }

    const searchRefund = async () => {
        if (!startDate) {
            return
        }
        setSearchButton(true)
        console.log("search keyword :", searchInput)
        const response = await api_workorder.SEARCH_REFUND_BONUS(
            {
                keyword: searchInput,
                date: startDate,
                limit: limitPage,
                last_element_id: dictLastIndices[currentPage]
            }
        )
        // console.log("search customer by user id response: ", response);
        if (response.status !== 200) {
            console.log(`search customer by user id failed`, response.message);
            setSearchButton(false)
            return
        }

        if (response.data.data === undefined) {
            setSearchButton(false)
            return
        }
        const {bonuses, last_index} = response.data.data
        if (bonuses === undefined) {
            setSearchButton(false)
            return
        }

        console.log("search bonus ", response.data.data)

        setTimeout(() => {
            setSearchButton(false)
        }, 300)
        setDataTable(bonuses)
        if (bonuses) {
            const newIndex = {...dictLastIndices, [currentPage + 1]: bonuses[bonuses.length - 1].id}
            setDictLastIndices(newIndex)
        }
    }

    const uploadRefundFiles = async () => {
        // if ( refundFiles === undefined ) {
        //     return
        // }

        let currentFile = refundFiles[0];
        console.log("current file: ", currentFile)
        let formData = new FormData();
        formData.append("effective_date", moment(effectiveDate).format("YYYY-MM-DD"))
        formData.append("file", currentFile);

        setShowConfirmUploadModal(false);
        setConfirmInput("");

        try {
            const response = await api_workorder.UPLOAD_REFUND_CUSTOMERS_ACCEPT(formData)
            console.log("response upload refund: ", response)
            dispatch({
                type: "TOAST2",
                value: {
                    show: true,
                    type: 'success',
                    title: `upload คืนยอดเสีย`,
                    message: `upload คืนยอดเสีย สำเร็จ`,
                }
            });
        } catch (e) {
            console.log(e)
            dispatch({
                type: "TOAST2",
                value: {
                    show: true,
                    type: 'danger',
                    title: `upload คืนยอดเสีย`,
                    message: `upload คืนยอดเสีย ไม่สำเร็จ`,
                }
            });
        }
        setShowConfirmUploadModal(false);
        setConfirmInput("");
        uploadFileRef.current.value = ""
    }

    const openConfirmUploadModal = () => {
        if (refundFiles !== undefined) {
            setShowConfirmUploadModal(true);
        } else {
            dispatch({
                type: "TOAST2",
                value: {
                    show: true,
                    type: 'danger',
                    title: `upload คืนยอดเสีย`,
                    message: `กรุณาเลือกไฟล์ที่ต้องการอัพโหลด`,
                }
            });
        }
    };

    const closeConfirmUploadModal = () => {
        setShowConfirmUploadModal(false);
    }

    const handlePrevPage = () => {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1)
        }
    }

    const handleNextPage = () => {
        if (!dataTable) {
            return
        }

        if (dataTable.length === limitPage) {
            setCurrentPage(currentPage + 1)
        }
    }

    const closeModal = () => {
        setModalProps(
            {
                ...modalProps,
                show: false,
            })
        searchRefundWithoutKeyword()
    }

    const retryData = (data) => {
        console.log(data)
        if (data.status === "failed") {
            setModalProps({
                ...modalProps,
                data: data,
                show: true,
                closeModal: {closeModal}
            })
        }
    }


    useEffect(() => {
        searchRefund();
    }, [currentPage])

    return (
        <>
            <CustomerRetryModal
                show={modalProps.show}
                data={modalProps.data}
                handleClose={closeModal}
            />
            <Modal
                show={showConfirmUploadModal}
                onHide={closeConfirmUploadModal}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>ยืนยันการคืนยอดเสีย</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>กรุณาตรวจสอบไฟล์คืนยอดเสียก่อน พิมพ์ <span className='text-red-500'>ยืนยัน</span> การอัพโหลดไฟล์</Form.Label>
                            <Form.Control as="input"
                                          name=""
                                          placeholder="ยืนยัน"
                                          defaultValue=""
                                          onChange={(e) => setConfirmInput(e.target.value)}
                            >
                            </Form.Control>
                            {/*<p className="text-red-500 font-bold pl-1 pt-1">{errorConfirmMsg}</p>*/}
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={uploadRefundFiles}
                            disabled={confirmInput !== "ยืนยัน"}>ยืนยัน</Button>
                    <Button variant="secondary" onClick={() => closeConfirmUploadModal()}>
                        ปิด
                    </Button>
                </Modal.Footer>
            </Modal>
            {
                (login_profile.role === "super_admin" || login_profile.role === "advanced_admin" || login_profile.role === "admin" || login_profile.role === "user_swap") ?
                    <div className="pt-4 pr-4 pl-4">
                        <Card className="card-success">
                            <Card.Header>คืนยอดเสีย</Card.Header>
                            <Card.Body>
                                <Form>
                                    <div className="mb-3 ">
                                        <Form.Row className="col-md-12">
                                            <Form.File id="formcheck-api" className="pr-4">
                                                <Form.File.Label>ดาวน์โหลด Template ไฟล์คืนยอดเสีย</Form.File.Label>
                                                {/*<Row>*/}
                                                <div>
                                                    <Button href="ufa_template.xlsx" download="ufa_template.xlsx"
                                                            variant="outline-primary">สำหรับ
                                                        UFA</Button>{' '}
                                                    <Button href="gclub_template.xlsx" download="gclub_template.xlsx"
                                                            variant="outline-success">สำหรับ
                                                        GCLUB</Button>
                                                </div>
                                                {/*</Row>*/}
                                            </Form.File>
                                        </Form.Row>
                                        <Form.Row>
                                            <Form.Group as={Col} md="4" lg="4" controlId="validation012">
                                                <Form.Label>เลือกวันที่ต้องการคืนยอดเสีย</Form.Label>
                                                <InputGroup>
                                                    <InputGroup.Prepend>
                                                        <InputGroup.Text>Effective-date</InputGroup.Text>
                                                    </InputGroup.Prepend>
                                                    <FormControl
                                                        type="date"
                                                        name="effective_date"
                                                        placeholder="2020-09-10"
                                                        required
                                                        onChange={(e) => setEffectiveDate(moment(e.target.value).format("YYYY-MM-DD"))}
                                                        defaultValue={effectiveDate}
                                                    />
                                                    <Form.Control.Feedback type="invalid" tooltip>
                                                        กรุณาเลือกวันที่ต้องการค้นหา
                                                    </Form.Control.Feedback>
                                                </InputGroup>
                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row className="col-md-12 pt-4">
                                            <Form.Group as={Col} md="3" lg="3" controlId="validation02">
                                                <Form.File id="formcheck-api-regular">
                                                    <Form.File.Label>อัพโหลดไฟล์คืนยอดเสีย</Form.File.Label>
                                                    <p style={{fontWeight: "bold", color: "red"}}>(ไฟล์ล่ะไม่เกิน 1000 รายการ)</p>
                                                    <Form.File.Input ref={uploadFileRef}
                                                                     onChange={(e) => setRefundFiles(e.target.files)}/>
                                                </Form.File>

                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row className="col-md-12">
                                            <Form.Group as={Col} md="3" lg="3" controlId="validation02">
                                                <Button as={Col} md="3" variant="success"
                                                        onClick={openConfirmUploadModal}>คืนยอดเสีย</Button>
                                            </Form.Group>
                                        </Form.Row>

                                    </div>
                                </Form>
                            </Card.Body>
                        </Card>
                    </div>
                    : <></>
            }
            <div className="pt-4 pl-4 pr-4">
                <Card className="card-primary">
                    <Card.Header>ค้นหาคืนยอดเสีย</Card.Header>
                    <Card.Body>
                        <Form>
                            <Form.Row className="col-md-12">
                                <Form.Group as={Col} md="3" lg="3" controlId="validation02">
                                    <Form.Label>เลือกวันที่ต้องการค้นหาใบงาน</Form.Label>
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <InputGroup.Text>Start-Date</InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <FormControl
                                            type="date"
                                            name="start_date"
                                            placeholder="2020-09-10"
                                            required
                                            // isInvalid={!!errors.start_date}
                                            onChange={(e) => setStartDate(moment(e.target.value).format("YYYY-MM-DD"))}
                                            defaultValue={startDate}
                                            // ref={register({ required: true })}
                                        />
                                        <Form.Control.Feedback type="invalid" tooltip>
                                            กรุณาเลือกวันที่ต้องการค้นหา
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>
                                <Form.Group as={Col} md="6" lg="6" className="col-md-12">
                                    <Form.Label>พิมพ์ค้นหาลูกค้า</Form.Label>
                                    <Form.Control as="input"
                                                  name="search_customer"
                                                  placeholder="eg. ufyu88xxxx/ahag2xxx, ชื่อ - นามสกุล, เบอร์โทรศัพท์, เลขที่บัญชี"
                                                  defaultValue=""
                                                  onChange={(e) => setSearchInput(e.target.value)}
                                    >
                                    </Form.Control>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} md="6" lg="6" className="col-md-12">
                                    <Button md="2" lg="2" onClick={() => searchRefund()} disabled={searchButton}>
                                        {
                                            (searchButton) ?
                                                (<Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />) : (<>ค้นหา</>)
                                        }
                                    </Button>
                                </Form.Group>
                                <Pagination className={"ml-auto"}>
                                    <Pagination.Prev onClick={handlePrevPage}/>
                                    <Pagination.Item active={true}>
                                        {currentPage}
                                    </Pagination.Item>
                                    <Pagination.Next onClick={handleNextPage}/>
                                </Pagination>
                            </Form.Row>


                            <Table striped borderless hover responsive
                                   className="table table-striped table-valign-middle">
                                <thead className="text-center">
                                <tr>
                                    <th>#</th>
                                    <th>วันที่/เวลา</th>
                                    <th>UserID</th>
                                    <th>ชื่อ-นามสกุล</th>
                                    <th>จำนวนยอดเสีย</th>
                                    <th>จำนวนยอดคืน</th>
                                    <th>สถานะการคืนยอด</th>
                                    <th>ผู้ทำรายการ</th>
                                    <th>อัพเดทล่าสุด</th>
                                    <th>หมายเหตุ</th>
                                </tr>
                                </thead>
                                <tbody className="text-center">

                                {
                                    dataTable?.map((
                                        {
                                            id,
                                            created_at,
                                            user_id,
                                            first_name,
                                            last_name,
                                            lost_amount,
                                            amount,
                                            status,
                                            updated_at,
                                            updated_by,
                                            comment

                                        }, index) =>
                                        <tr key={id} onClick={() => retryData(dataTable[index], true)}>
                                            <td>{((currentPage - 1) * limitPage) + index + 1}</td>
                                            <td>{moment(created_at).format('DD/MM/YYYY HH:mm')}</td>
                                            <td>{user_id || "-"}</td>
                                            <td>{first_name || "-"} {last_name || "-"}</td>
                                            <td>{lost_amount || "-"}</td>
                                            <td>{amount}</td>
                                            {renderStatus(status)}
                                            <td>{updated_by}</td>
                                            <td>{moment(updated_at).format('DD/MM/YYYY HH:mm')}</td>
                                            <td>{comment}</td>
                                        </tr>
                                    )
                                }
                                </tbody>
                            </Table>
                            <Form.Row>
                                <Pagination className={"ml-auto"}>
                                    <Pagination.Prev onClick={handlePrevPage}/>
                                    <Pagination.Item active={true}>
                                        {currentPage}
                                    </Pagination.Item>
                                    <Pagination.Next onClick={handleNextPage}/>
                                </Pagination>
                            </Form.Row>
                        </Form>
                        {/* <Row>
            <div className="mb-3 col-md-12 col-lg-12">
              <WorkOrderDetails data={workOrderDetailsData} />
            </div>
          </Row> */}
                    </Card.Body>
                </Card>
            </div>
            {/*<div>*/}
            {/*    <WorkOrderDetails data={workOrderDetailsData}/>*/}
            {/*</div>*/}
        </>
    )
}
