import React, {useState, useEffect, useRef} from 'react'
import {useGlobalStore} from '../../store/globalContext'
import WorkOrderDetails from './WorkOrderDetails'
import {Card, Accordion, Button, Col, Form, InputGroup} from 'react-bootstrap';
import {useForm} from 'react-hook-form'
import api from "../../api/customer";
import api_bank from "../../api/managebank";
import api_workorder from "../../api/workorder";
import {AsyncTypeahead} from 'react-bootstrap-typeahead'
import ModalConfirmWorkOrder from './ModalConfirmWorkOrder'
import {LogoWithAccountNoSimple} from "../banks_logo";


export default function TrueWalletWorkOrder() {
  const [{dashboard_work_order_true_wallet, login_profile}, dispatch] = useGlobalStore();
  const [workOrderDetailsData, setWorkOrderDetailsData] = useState(
    {
      work_order_type: "deposit",
      work_order_sub_type: "true_wallet",
      cardHeader: "สรุปข้อมูลใบงานฝาก True Wallet",
      total_work_order_today: 0,
      total_work_order_all: 0,
      total_sweep_withdraw_amount_today: 0,
      total_amount_today: 0,
    });

  const [validated, setValidated] = useState(false);
  const {register, handleSubmit, watch, errors, getValues, setValue, reset} = useForm({
    defaultValues: {},
    criteriaMode: "all"
  });

  const [customerSearchList, setCustomerSeachList] = useState([])

  const [isLoading, setIsLoading] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false)
  const [confirmModalData, setConfirmModalData] = useState(
    {
      user_id: "",
      first_name: "",
      last_name: "",
      amount: "",
    })
  const [confirmRequestData, setConfirmRequestData] = useState({})
  const typeAheadRef = useRef()

  useEffect(() => {
    //console.log("register customer screen before dispatch");
    try {
      dispatch({
        type: "SOCKET_SEND",
        value: {method: "subscribe", topic: "dashboard_work_order_true_wallet"}
      });
    } catch (error) {
      console.log("error :", error);
      dispatch({
        type: "TOAST",
        value: {
          show: true,
          type: 'danger',
          title: `ระบบมีปัญหา`,
          message: `ขออภัยระบบมีปัญหา กรุณาติดต่อ admin`,
        }
      });
    }
  }, [])

  useEffect(() => {
    //console.log("dashboard_work_order_deposit page: ", dashboard_work_order_deposit);
    if (dashboard_work_order_true_wallet === undefined) {
      return
    }
    //console.log("dashboard_work_order_deposit :", dashboard_work_order_deposit)

    setWorkOrderDetailsData(
      {
        ...workOrderDetailsData,
        total_work_order_all: dashboard_work_order_true_wallet.total_work_order_all,
        total_work_order_today: dashboard_work_order_true_wallet.total_work_order_today,
        total_amount_today: dashboard_work_order_true_wallet.total_amount_today,
        current_amount: dashboard_work_order_true_wallet.current_amount,
        bank_balance: dashboard_work_order_true_wallet.bank_balance,
      }
    )

  }, [dashboard_work_order_true_wallet])

  const confirmSubmit = async (work_order_deposit_request) => {
    console.log("create work_order_deposit_request : ", work_order_deposit_request)
    //return
    const response = await api_workorder.WORK_ORDER_CREATE(work_order_deposit_request)

    if (response.status !== 200) {
      console.log(`create work_order_deposit_failed`, response.message);
      return
    }

    dispatch({
      type: "TOAST2",
      value: {
        show: true,
        type: 'success',
        title: `สร้างใบงาน Manual`,
        message: `สร้างใบงาน UserID ${work_order_deposit_request.user_id} สำเร็จ`,
      }
    });

    setConfirmRequestData({})
    setConfirmModalData({})
    setConfirmModal(false)
    typeAheadRef.current.clear()
    reset(register)
  }

  const onSubmit = workOrderData => {

    const work_order_deposit_request = {
      ...workOrderData,
      work_order_type: "deposit",
      channel: "manual",
      source_of_fund: "true_wallet",
      created_by: login_profile.name,
      updated_by: login_profile.name
    }

    setConfirmRequestData(work_order_deposit_request)
    setConfirmModalData({
      work_order_label_type: "ฝาก",
      user_id: workOrderData.user_id,
      first_name: workOrderData.first_name,
      last_name: workOrderData.last_name,
      amount: workOrderData.amount,
    })

    setConfirmModal(true)
  }

  const handleSearchDataFromUserID = async (userIdForSearch) => {
    // const userIdForSearch = getValues("user_id")
    //console.log("user id input", userIdForSearch);

    // if (userIdForSearch.length < ) {
    //     return
    // }
    const response = await api.SEARCH_CUSTOMER({keyword: userIdForSearch})
    // console.log("search customer by user id response: ", response);
    if (response.status !== 200) {
      console.log(`search customer by user id failed`, response.message);
      return
    }

    const {data} = response.data
    if (data === null) {
      setCustomerSeachList([]);
      return
    }
    //console.log("search customer by user id response data: ", data);

    let dataArr = []
    data.map(e => {
      // console.log("element data search: ", e)
      if (e.agents.length === 2) {
        const agentsArr = e.agents
        agentsArr.map(ex => {
          console.log("agents elements : ", ex)
          let dTemp = {...e, user_id: ex.username}
          // dTemp.user_id = ex.username
          dataArr.push(dTemp)
        })

      } else {
        dataArr.push(e)
      }
    })
    //console.log("element dataArr: ", dataArr)
    setCustomerSeachList(dataArr);
  }

  const handleSetDataFromUserID = (value) => {
    if (value.length === 0) {
      return
    }
    //console.log("handleSetDataFromUserID : ", ...value)
    const result = value[0]
    //const result = value.filter(value => value.user_id === value).pop()
    const {user_id, first_name, last_name} = result
    //console.log("handleSetDataFromUserID : ", user_id, first_name, last_name, mobile_no, bank_accounts)
    setValue("user_id", user_id)
    setValue("first_name", first_name)
    setValue("last_name", last_name)
  }

  return (
    <>
      <ModalConfirmWorkOrder openModal={confirmModal} data={confirmModalData} onConfirm={confirmSubmit}
                             onClose={() => setConfirmModal(false)} workOrderData={confirmRequestData}/>
      <div className="col-md-12 pt-4 pr-4 pl-4">
        <Card className="card-warning">
          <Card.Header>
            เพิ่มใบงานฝาก True Wallet เติมมือ
          </Card.Header>
          <Card.Body>
            <Form noValidate validated={validated} onSubmit={handleSubmit(onSubmit)}>
              <Form.Row>
                <Form.Group as={Col} md="4" lg="4">
                  <Form.Label>ค้นหาลูกค้า</Form.Label>
                  <AsyncTypeahead
                    // size="md"
                    delay={1500}
                    id="search_customer"

                    isLoading={isLoading}
                    labelKey={(option) => `${option.user_id} ${option.first_name} ${option.last_name}`}
                    minLength={4}
                    onSearch={handleSearchDataFromUserID}
                    options={customerSearchList}
                    placeholder="user_id/ชื่อ/นามสกุล/เบอร์โทรศัพท์/เลขที่บัญชี"
                    searchText="กำลังค้นหาลูกค้า"
                    onChange={handleSetDataFromUserID}
                    ref={typeAheadRef}
                    renderMenuItemChildren={(option) => (
                        <div className={"flex hover:bg-blue-200"}>
                          <div style={{"width":"50%"}}>
                            <p><b className={"text-primary"}>{option.user_id}</b></p>
                            <p><b>{option.first_name} - {option.last_name}</b></p>
                          </div>
                          <div className={"flex-row"}>
                            {
                              option.bank_accounts.map((bank) => {
                                return(
                                    <p>
                                      <LogoWithAccountNoSimple
                                          bankShortName={ bank.bank_short_name }
                                          bankFullName={bank.bank_account_name}
                                          bankName={ bank.bank_account_name }
                                          accountNo={ bank.bank_account_no }
                                      />
                                    </p>
                                )
                              })
                            }
                          </div>
                        </div>
                    )}
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} md="2" lg="2" controlId="validation01">
                  <Form.Label>UserID</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    name="user_id"
                    placeholder="ufyuxxxxx/ahagxxxx"
                    isInvalid={!!errors.first_name}
                    // ref={register({required: true})}
                    ref={register}
                    readOnly={true}
                  />

                  <Form.Control.Feedback type="invalid" tooltip>
                    กรุณาระบุ ชื่อ
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="2" lg="2" controlId="validation02">
                  <Form.Label>ชื่อ</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    name="first_name"
                    placeholder="ชื่อ"
                    isInvalid={!!errors.first_name}
                    // ref={register({required: true})}
                    ref={register}
                    readOnly={true}
                  />

                  <Form.Control.Feedback type="invalid" tooltip>
                    กรุณาระบุ ชื่อ
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="2" lg="2" controlId="validation03">
                  <Form.Label>นามสกุล</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    name="last_name"
                    placeholder="นามสกุล"
                    isInvalid={!!errors.last_name}
                    // ref={register({required: true})}
                    ref={register}
                    readOnly={true}
                  />

                  <Form.Control.Feedback type="invalid" tooltip>
                    กรุณาระบุนามสกุล
                  </Form.Control.Feedback>
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} md="2" lg="2" controlId="validation08">
                  <Form.Label>จำนวนเงิน</Form.Label>
                  <Form.Control
                    required
                    type="number"
                    name="amount"
                    step={1}
                    placeholder="100/200.50"
                    isInvalid={!!errors.amount}
                    ref={register({required: true})}
                  />

                  <Form.Control.Feedback type="invalid" tooltip>
                    กรุณาระบุ จำนวนเงิน
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="6" lg="6" controlId="validation09">
                  <Form.Label>หมายเหตุ</Form.Label>
                  <Form.Control
                    type="text"
                    name="comment"
                    placeholder="อ่าน statement ไม่เจอ/ธนาคารมีปัญหา"
                    isInvalid={!!errors.comment}
                    ref={register}
                  />

                  <Form.Control.Feedback type="invalid" tooltip>
                    กรุณาระบุ จำนวนเงิน
                  </Form.Control.Feedback>
                </Form.Group>
              </Form.Row>
              <Button variant="success" type="submit">เพิ่มใบงานฝากเติมมือ (Manual)</Button>
            </Form>
          </Card.Body>
        </Card>
      </div>
      {/* <div className="pt-4 pr-4 pl-4"> */}
      <WorkOrderDetails data={workOrderDetailsData}/>
      {/* </div> */}
    </>
  )
}
