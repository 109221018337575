import React, {useEffect, useRef, useState, lazy, useMemo} from "react";
import BankCardComponent from "./BankCardComponent";
import {
  Alert,
  Button,
  Card,
  Col,
  Form, FormCheck,
  FormControl,
  InputGroup,
  Row,
  ToggleButton,
} from "react-bootstrap";
import { useGlobalStore } from "../../store/globalContext";
import api from "../../api/managebank";
import api_customer from "../../api/customer";
import {useForm} from "react-hook-form";
import FormCheckInput from "react-bootstrap/FormCheckInput";
import moment from "moment/moment";
import ManageVizPayConfig from "./ManageVizPayConfig";
import ManageBankAccountConfig from "./ManageBankAccountConfig";
import ManageAutoSweep from "./ManageAutoSweep";
import ManageCorePayConfig from "./ManageCorePayConfig";
import ManageHengPayConfig from "./ManageHengPayConfig";
import ManagePerfectPaysConfig from "./ManagePerfectPaysConfig";
import ManagePayOneXConfig from "./ManagePayOneXConfig";
import ManageWealthWaveConfig from "./ManageWealthWaveConfig";
import ManageMinimumWithdraw from "./ManageMinimumDisplay";

const QRCode = require("qrcode.react");

const LazyQRSMSApp = () => {
  return (
      <QRCode
          value={`https://sms-fw-service.sfo2.digitaloceanspaces.com/NewForwardSMS_v3.apk`}
          size={150}
      />
  )
}

const LazyQRSMSConfig = ()=>{
  return(
      <QRCode
          value={`https://${process.env.REACT_APP_API_PATH}/banking/read_sms`}
          size={150}
      />
  )
}


export default function ManageBanks() {

  const [{ subscribe, banks, status, login_profile }, dispatch] =
      useGlobalStore();
  const [searchInputValue, setSearchInputValue] = useState("");
  const [bankList, setBankList] = useState({
    cardHeader: "รายชื่อธนาคาร",
    cardTitle: "บัญชีธนาคาร",
    cardBody: [],
    cardFooter: "",
  });
  const [disableFetch, setDisableFetch] = useState(false);
  const [banklistForSearch, setBanklistForSearch] = useState([])

  const banklistForSearchMemo = useMemo(()=>{
    // console.log("banklistForSearchMemo has changed")
    return banklistForSearch
  },[banklistForSearch])


  const [manageBankAccount, setManageBankAccount] = useState({
    bank_transfer_config: false
  })
  const [officialBank, setOfficialBank] = useState([])

  useEffect(() => {
    try {
      dispatch({
        type: "SOCKET_SEND",
        value: { method: "subscribe", topic: "dashboard_bank" },
      });

      // let searchData = {
      //   method: "bank_search",
      //   keyword: ""
      // }
      // dispatch({ type: "SOCKET_SEND", value: searchData });
    } catch (error) {
      console.log("error :", error);
      // setShowErrorAlert({ showAlert: true, showAlertType: "danger", showAlertMessage: "ขออภัย ระบบไม่สามารถทำรายได้ในขณะนี้" })
    }
  }, []);

  const getAllBank = async () => {
    setDisableFetch(true);
    try {
      const response = await api.BANK_SEARCH({ keyword: "", fetch: false });
      //console.log("search_bank response:", response.data)
      const { data } = response.data;
      // console.log("search_bank data:", data)
      if (data === null) {
        return;
      }
      let dataTableArr = [];
      data.forEach((element) => {
        //console.log("banks elements ", element)
        let dataTable = {
          id: element.id,
          bank_name: element.bank_name,
          bank_account_no: element.bank_account_no,
          bank_account_name: element.bank_account_name,
          bank_type: element.bank_type,
          bank_short_name: element.bank_short_name,
          username: element.username,
          password: element.password,
          comment: element.comment,
          auto_threads: element.auto_threads,
          auto_port: element.auto_port,
          created_at: element.created_at,
          created_by: element.created_by,
          updated_at: element.updated_at,
          updated_by: element.updated_by,
          auto_approve: element.auto_approve,
          is_lure: element.is_lure,
          enable: element.enable,
          balance: element.balance,
          deposit_tier: element.deposit_tier,
          monthly_deposit_count: element.monthly_deposit_count,
          monthly_withdraw_count: element.monthly_withdraw_count,
          total_sum: element.total_sum,
          total_in: element.total_in,
          total_out: element.total_out,
          show_customer: element.show_customer,
          crawler_type: element.crawler_type,
          mobile_sms: element.mobile_sms,
          pin: element.pin,
          card_id: element.card_id,
          date_of_birth: element.date_of_birth,
          login_with_qr: element.login_with_qr,
          read_statement_at: element.read_statement_at,
          device_id: element.device_id,
          api_refresh: element.api_refresh,
          lock: element.lock,
          obsolete: element.obsolete,
          agent_name: element.agent_name,
          api_login_token: element.api_login_token,
          wallet_tmn_id: element.wallet_tmn_id,
          tmnone_key_id: element.tmnone_key_id,
          kplus_data_raw: element.kplus_data_raw,
          kplus_data: element.kplus_data,
          scb_bulk_transfer: element.scb_bulk_transfer,
          user_ids: element.user_ids
        };
        dataTableArr.push(dataTable);
      });
      // console.log(dataTableArr)
      setBankList({
        ...bankList,
        cardBody: dataTableArr,
      });
      setBanklistForSearch(dataTableArr)
    } catch (error) {
      console.log("error :", error);
    }
    setDisableFetch(false);
  };
  const getBankList = async () => {
    try {
      const response = await api_customer.GET_THAI_BANK_API();
      if (response.status !== 200) {
        console.log("get bank list failed");
        return;
      }
      const { data } = response.data;
      //console.log("bank_list_data: ", data)
      let dropdownBankList = [];
      data.forEach((key) => {
        //   console.log("bank_list_data key : ", key);
        dropdownBankList.push({
          innerText: key.text,
          value: key.value,
          code: key.code,
        });
      });
      setOfficialBank(dropdownBankList);
    } catch (error) {
      console.log("get bank list error : ", error);
    }
  };

  const [show, setShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    alertHeading: "",
    content: "",
  });

  const handleClose = () => {
    setShow(false);
  };

  const handleError = (props) => {
    const { alertHeading, content } = props;
    setErrorMessage({ alertHeading, content });
    setShow(true);
  };

  const keySearchInput = async () => {
    let searchData = {
      keyword: "",
    };

    // console.log("searchInputValue :", searchInputValue);

    searchData = {
      keyword: searchInputValue,
    };
    // console.log("searchData:", searchData);
    // dispatch({ type: "SOCKET_SEND", value: searchData });
    try {
      const response = await api.BANK_SEARCH(searchData);
      // console.log("search_bank response:", response.data)
      const { data } = response.data;
      // console.log("search_bank data:", data);
      if (data === null) {
        return;
      }
      let dataTableArr = [];
      data.forEach((element) => {
        // console.log("banks elements query", element)
        let dataTable = {
          id: element.id,
          bank_name: element.bank_name,
          bank_account_no: element.bank_account_no,
          back_account_name: element.bank_account_name,
          bank_type: element.bank_type,
          bank_short_name: element.bank_short_name,
          username: element.username,
          password: element.password,
          comment: element.comment,
          auto_threads: element.auto_threads,
          auto_port: element.auto_port,
          created_at: element.created_at,
          created_by: element.created_by,
          updated_at: element.updated_at,
          updated_by: element.updated_by,
          auto_approve: element.auto_approve,
          is_lure: element.is_lure,
          enable: element.enable,
          balance: element.balance,
          bank_account_name: element.bank_account_name,
          deposit_tier: element.deposit_tier,
          crawler_type: element.crawler_type,
          login_with_qr: element.login_with_qr,
          device_id: element.device_id,
          api_refresh: element.api_refresh,
          pin: element.pin,
          show_customer: element.show_customer,
          lock: element.lock,
          obsolete: element.obsolete,
          agent_name: element.agent_name,
          api_login_token: element.api_login_token,
          wallet_tmn_id: element.wallet_tmn_id,
          tmnone_key_id: element.tmnone_key_id,
          kplus_data_raw: element.kplus_data_raw,
          kplus_data: element.kplus_data,
          card_id:element.card_id,
          scb_bulk_transfer: element.scb_bulk_transfer
        };
        dataTableArr.push(dataTable);
      });
      // console.log(dataTableArr);
      setBankList({
        ...bankList,
        cardBody: dataTableArr,
      });
    } catch (error) {
      console.log("error :", error);
    }
  };

  const handleFetchTransaction = async () => {
    setDisableFetch(true);
    try {
      const response = await api.BANK_SEARCH({ keyword: "", fetch: true });
      const { data } = response.data;
      // console.log("search_bank data:", data)
      if (data === null) {
        return;
      }
      let dataTableArr = [];

      // data.forEach((element) => {
      //   let dataTable = {
      //     id: element.id,
      //     bank_name: element.bank_name,
      //     bank_account_no: element.bank_account_no,
      //     bank_account_name: element.bank_account_name,
      //     bank_type: element.bank_type,
      //     bank_short_name: element.bank_short_name,
      //     username: element.username,
      //     password: element.password,
      //     comment: element.comment,
      //     auto_threads: element.auto_threads,
      //     auto_port: element.auto_port,
      //     created_at: element.created_at,
      //     created_by: element.created_by,
      //     updated_at: element.updated_at,
      //     updated_by: element.updated_by,
      //     auto_approve: element.auto_approve,
      //     is_lure: element.is_lure,
      //     enable: element.enable,
      //     balance: element.balance,
      //     deposit_tier: element.deposit_tier,
      //     monthly_deposit_count: element.monthly_deposit_count,
      //     monthly_withdraw_count: element.monthly_withdraw_count,
      //     total_sum: element.total_sum,
      //     total_in: element.total_in,
      //     total_out: element.total_out,
      //     show_customer: element.show_customer,
      //     crawler_type: element.crawler_type,
      //     mobile_sms: element.mobile_sms,
      //     pin: element.pin,
      //     card_id: element.card_id,
      //     date_of_birth: element.date_of_birth,
      //     login_with_qr: element.login_with_qr,
      //     read_statement_at: element.read_statement_at,
      //     device_id: element.device_id,
      //     api_refresh: element.api_refresh,
      //     lock: element.lock,
      //     obsolete: element.obsolete,
      //     agent_name: element.agent_name,
      //     api_login_token: element.api_login_token,
      //     wallet_tmn_id: element.wallet_tmn_id,
      //     tmnone_key_id: element.tmnone_key_id,
      //     kplus_data_raw: element.kplus_data_raw,
      //     kplus_data: element.kplus_data
      //   };
      //
      //   dataTableArr.push(dataTable);
      // });

      let map1 = data.reduce(function(map, obj) {
            map[obj.id] = obj;
            return map;
          }, {});

      bankList.cardBody.map(obj =>{
        if (map1[obj.id] !== undefined) {
          dataTableArr.push(map1[obj.id])
        }
      })

      setBankList({
        ...bankList,
        cardBody: dataTableArr,
        show: false
      });


    } catch (error) {
      console.log("error :", error);
    }
    setDisableFetch(false);
  };

  const [lureSetting, setLureSetting] = useState({
    deposit_lure_times: "",
    deposit_lure_baht: "",
    deposit_lure_condition: "or",
    withdraw_lure_times: "",
    withdraw_lure_baht: "",
    withdraw_lure_condition: "or",
  });

  const handleLureInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    const setting = {
      ...lureSetting,
      [name]: value,
    };
    setLureSetting(setting);
  };

  const handleUpdateLureAccount = () => {
    const updateLureSetting = async () => {
      try {
        const setting = {
          deposit: {
            times: parseInt(lureSetting.deposit_lure_times, 10),
            baht: parseFloat(lureSetting.deposit_lure_baht),
            condition: lureSetting.deposit_lure_condition,
          },
          withdraw: {
            times: parseInt(lureSetting.withdraw_lure_times, 10),
            baht: parseFloat(lureSetting.withdraw_lure_baht),
            condition: lureSetting.withdraw_lure_condition,
          },
        };

        const response = await api.BANK_TIER_UPDATE(setting);
        //console.log("update tier bank response:", response.data)
      } catch (error) {
        console.log("error :", error);
      }
    };
    updateLureSetting();
  };

  const getWithdrawSetting = async () => {
    try {
      const response = await api.GET_WITHDRAW_LIMIT_SETTING();
      //console.log("search get withdraw setting  response:", response.data)
      const { data } = response.data;

      const setting = {
        automate_withdraw_amount: data.automate_withdraw_amount,
        minimum_withdraw_count: data.minimum_withdraw_count,
      };

      setAutomateWithdraw(setting);
    } catch (error) {
      console.log("error :", error);
    }
  };

  const getWithdrawBlockTimeSetting = async () => {
    try {
      const response = await api.GET_UPDATE_WITHDRAW_TIME_CONFIG();
      //console.log("search get withdraw setting  response:", response.data)
      const { data } = response.data;

      const setting = {
        start_time: data.start_time,
        end_time: data.end_time,
        message: data.message,
        enable: data.enable,
      };

      setWithdrawBlockTimePanel(setting);
    } catch (error) {
      console.log("error :", error);
    }
  };

  const [statementDelay, setStatementDelay] = useState();
  const getStatementDelay = async () => {
    try {
      const response = await api.STATEMENT_DELAY_CONFIG();
      const { data } = response.data;
      setStatementDelay(data?.statement_delay_duration);
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const handleUpdateStatementDelay = async () => {
    try {
      const request = {
        delay: parseInt(statementDelay),
      };

      await api.UPDATE_STATEMENT_DELAY_CONFIG(request);
      dispatch({
        type: "TOAST2",
        value: {
          show: true,
          type: "success",
          title: `Update Statement Delay`,
          message: `Update Statement Delay สำเร็จ`,
        },
      });
    } catch (error) {
      console.log("error: ", error);
      dispatch({
        type: "TOAST2",
        value: {
          show: true,
          type: "danger",
          title: `Update Statement Delay`,
          message: `Update Statement Delay ไม่สำเร็จ`,
        },
      });
    }
  };

  useEffect(() => {
    const getLureSetting = async () => {
      try {
        const response = await api.BANK_TIER_SEARCH();
        //console.log("search tier bank response:", response.data)
        const { data } = response.data;

        const setting = {
          deposit_lure_times: data.deposit.times.toString(),
          deposit_lure_baht: data.deposit.baht.toString(),
          deposit_lure_condition: data.deposit.condition.toString(),
          withdraw_lure_times: data.withdraw.times.toString(),
          withdraw_lure_baht: data.withdraw.baht.toString(),
          withdraw_lure_condition: data.withdraw.condition.toString(),
        };

        setLureSetting(setting);
      } catch (error) {
        console.log("error :", error);
      }
    };

    void getLureSetting();
    // void getWithdrawSetting();
    // void getWithdrawBlockTimeSetting();
    // void getStatementDelay();
    // void getQRTransfer();
    // void getBankTransfer();
  }, []);

  const [automateWithdraw, setAutomateWithdraw] = useState({
    automate_withdraw_amount: 0,
    minimum_withdraw_count: 1,
  });

  const handleUpdateWithdrawMinimum = () => {
    const updateWithdrawMinimumSetting = async () => {
      const awa = parseInt(automateWithdraw.automate_withdraw_amount);
      const amw = parseInt(automateWithdraw.minimum_withdraw_count);

      try {
        const setting = {
          automate_withdraw_amount: awa,
          minimum_withdraw_count: amw,
        };

        //console.log("handleUpdateWithdrawMinimum : ", setting)
        const response = await api.UPDATE_WITHDRAW_SETTING(setting);
        //console.log("update withdraw setting response:", response.data)
        getWithdrawSetting();
        dispatch({
          type: "TOAST2",
          value: {
            show: true,
            type: "success",
            title: `Update Setting ถอน`,
            message: `Update Setting ถอนสำเร็จ`,
          },
        });
      } catch (error) {
        console.log("error :", error);
        dispatch({
          type: "TOAST2",
          value: {
            show: true,
            type: "danger",
            title: `Update Setting ถอน`,
            message: `ขออภัยไม่สามารถ Update Setting ถอน ได้ กรุณาติดต่อ Admin`,
          },
        });
      }
    };
    updateWithdrawMinimumSetting();
  };

  const str2bool = (value) => {
    if (value && typeof value === "string") {
      if (value.toLowerCase() === "true") return true;
      if (value.toLowerCase() === "false") return false;
    }
    return value;
  };

  const [withdrawBlockTimeOptions, setWithdrawBlockTimeOptions] = useState([
    { innerText: "เปิดการใช้งาน", value: true },
    { innerText: "ปิดการใช้งาน", value: false },
  ]);

  const [withdrawBlockTimePanel, setWithdrawBlockTimePanel] = useState({
    start_time: "",
    end_time: "",
    message: "",
    enable: false,
  });

  const [withdrawBlockTimePanelError, setWithdrawBlockTimePanelError] =
      useState({
        start_time: false,
        end_time: false,
        message: false,
      });

  const handleUpdateWithdrawBlockTime = () => {
    setWithdrawBlockTimePanelError({
      start_time: false,
      end_time: false,
      message: false,
    });

    //console.log("withdrawBlockTimePanel : ", withdrawBlockTimePanel)

    if (withdrawBlockTimePanel.start_time === "") {
      setWithdrawBlockTimePanelError({
        ...withdrawBlockTimePanelError,
        start_time: true,
      });
      return;
    }

    if (withdrawBlockTimePanel.end_time === "") {
      setWithdrawBlockTimePanelError({
        ...withdrawBlockTimePanelError,
        end_time: true,
      });
      return;
    }

    if (withdrawBlockTimePanel.message === "") {
      setWithdrawBlockTimePanelError({
        ...withdrawBlockTimePanelError,
        message: true,
      });
      return;
    }

    const updateWithdrawTimeSetting = async () => {
      try {
        //console.log("handleUpdateWithdrawMinimum : ", setting)
        const response = await api.UPDATE_WITHDRAW_TIME_CONFIG(
            withdrawBlockTimePanel
        );
        //console.log("update withdraw setting response:", response.data)
        dispatch({
          type: "TOAST2",
          value: {
            show: true,
            type: "success",
            title: `Update Time Setting ถอน`,
            message: `Update Time Setting ถอนสำเร็จ`,
          },
        });
      } catch (error) {
        console.log("error :", error);
        dispatch({
          type: "TOAST2",
          value: {
            show: true,
            type: "danger",
            title: `Update Time Setting ถอน`,
            message: `ขออภัยไม่สามารถ Update Time Setting ถอน ได้ กรุณาติดต่อ Admin`,
          },
        });
      }
    };
    void updateWithdrawTimeSetting();
  };

  const { register, handleSubmit, setValue, getValues, trigger, reset, formState:{isDirty, dirtyFields} } = useForm({
    defaultValues:{
      bank_transfer_config: false,
      customer_register_datetime: null,
    },
    shouldUnregister: false
  });

  const { register:register2, handleSubmit:handleSubmit2, setValue:setValue2, getValues:getValues2, trigger:trigger2 , reset:reset2} = useForm({
    defaultValues:{
      filter_account_type:'',
      filter_account_enable:'',
      filter_account_show:'',
    },
    shouldUnregister: false
  });

  const getQRTransfer = async () => {
    try {
      //console.log("handleUpdateWithdrawMinimum : ", setting)
      const response = await api.GET_QR_TRANSFER_CONFIG();
      // console.log("get qr transfer response:", response.data);

      const { enable, updated_by, customer_register_datetime } =
          response.data.data;
      setValue("qr_transfer_config", enable)
      setValue("customer_register_datetime", moment(customer_register_datetime).format("YYYY-MM-DDTHH:mm:ss.SSS"))
    } catch (error) {
      console.log("get qr transfer error :", error);
    }
  };

  const getBankTransfer = async () => {
    try {
      //console.log("handleUpdateWithdrawMinimum : ", setting)
      const response = await api.GET_BANK_TRANSFER_CONFIG();
      // console.log("get bank transfer response:", response.data);

      const { enable, updated_by, customer_register_datetime } =
          response.data.data;
      console.log(
        "get bank transfer response enable, updated_by, customer_register_datetime:",
        enable,
        updated_by,
        customer_register_datetime
      );
      // setManageBankAccount({
      //   bank_transfer_config: enable,
      // });
      setValue("bank_transfer_config", enable)
      console.log("getValues(bank_transfer_config): ", getValues("bank_transfer_config"))
    } catch (error) {
      console.log("get qr bank error :", error);
    }finally {
      reset(getValues())
    }
  };
  const ChangeQRTransfer = async (qrTransferStatus, customerDateTime) => {
    // console.log ("qrTransferStatus, customerDateTime : ", qrTransferStatus, customerDateTime)
    let customerDateTimeTemp = customerDateTime
    if (customerDateTime === ""){
      customerDateTimeTemp = null
    }else{
      customerDateTimeTemp = moment(customerDateTime).format("YYYY-MM-DDT12:00:00.000+07:00")
    }

    try {
      //console.log("handleUpdateWithdrawMinimum : ", setting)
      const response = await api.QR_TRANSFER_CONFIG({
        enable: qrTransferStatus,
        customer_register_datetime:customerDateTimeTemp
      });
      // console.log("change qr transfer response:", response.data);
      setValue("qr_transfer_config", qrTransferStatus)
      setValue("customer_register_datetime", customerDateTime)
      dispatch({
        type: "TOAST2",
        value: {
          show: true,
          type: "success",
          title: `Update สถานะเปิดใช้ QR ฝาก/ถอน`,
          message: `Update สถานะเปิดใช้ QR ฝาก/ถอน ถอนสำเร็จ`,
        },
      });
    } catch (error) {
      console.log("change qr transfer error :", error);
      dispatch({
        type: "TOAST2",
        value: {
          show: true,
          type: "danger",
          title: `Update สถานะเปิดใช้ QR ฝาก/ถอน ไม่สำเร็จ`,
          message: `${error.response.data.message} `,
        },
      });
    }
  };

  const bankTransferRef = useRef()
  const ChangeBankTransfer = async (bankTransferStatus) => {
    try {
      console.log("change bank transfer : ", bankTransferStatus);
      const response = await api.BANK_TRANSFER_CONFIG({
        enable: bankTransferStatus,
      });
      // console.log("change bank transfer response:", response.data);

      // setManageBankAccount({
      //   bank_transfer_config: bankTransferStatus,
      // });
      dispatch({
        type: "TOAST2",
        value: {
          show: true,
          type: "success",
          title: `Update สถานะเปิดใช้บัญชีฝาก/ถอน`,
          message: `Update สถานะเปิดใช้บัญชีฝาก/ถอน ถอนสำเร็จ`,
        },
      });
    } catch (error) {
      console.log("get qr transfer error :", {...error});
      const { message } = error.response.data
      dispatch({
        type: "TOAST2",
        value: {
          show: true,
          type: "danger",
          title: `ไม่สามารถบันทึกสถานะเปิดใช้บัญชีฝาก/ถอนได้`,
          message: `${message}`,
        },
      });
      // console.log("bankTransferRef.current : ", bankTransferRef.current.value)
      // bankTransferRef.current.value = manageBankAccount.bank_transfer_config
    }
  };

  const onSubmit = (data) => {
     console.log("data : ", data)
    void ChangeBankTransfer(str2bool(data.bank_transfer_config))
    void ChangeQRTransfer(str2bool(data.qr_transfer_config) , data.customer_register_datetime)
  };

  const dateFormat = (date)=>{

    if (date !== ''){
      // console.log("dateFormat : ", date, moment(date).format("YYYY-MM-DDTHH:mm"))
      return moment(date).format("YYYY-MM-DDTHH:mm")
    }
    return null
  }

  useEffect(() => {
    void getBankList();
    void getAllBank();
  }, []);


  const clearAllFilter = () =>{
    reset2();
    void getAllBank();
  }

  const submitFilter = ({filter_account_type, filter_account_enable, filter_account_show}) => {
    // console.log("{filter_account_type, filter_account_enable, filter_account_show}: ",filter_account_type, filter_account_enable, filter_account_show)
    if (filter_account_type === "" && filter_account_enable === "" && filter_account_show === ""){
      setBankList({...bankList, cardBody: banklistForSearchMemo})
    }

    let bcmFilter = banklistForSearchMemo

    if(filter_account_type !== ""){
      bcmFilter = bcmFilter.filter(bcm =>{
        return bcm.bank_type === filter_account_type
      })
    }

    if(filter_account_enable !== ""){
      bcmFilter = bcmFilter.filter(bcm =>{
        return bcm.enable.toString() === filter_account_enable
      })
    }

    if(filter_account_show !== ""){
      bcmFilter = bcmFilter.filter(bcm =>{
        return bcm.show_customer.toString() === filter_account_show
      })
    }
    setBankList({...bankList, cardBody: bcmFilter})
    // console.log("{...bankList}", {...bankList})
  }

  return (
      <section className="content pt-4 pl-4 pr-4">
        <div className="container-fluid">
          <div>
            <Alert variant="danger" show={show} onClose={handleClose} dismissible>
              <Alert.Heading>{errorMessage.alertHeading}</Alert.Heading>
              <p>{errorMessage.content}</p>
            </Alert>
            <Card className="card-primary">
              <Card.Header>ค้นหาธนาคาร</Card.Header>
              <Card.Body>
                <Form as={Col} className={"flex"}>
                  <Form.Group as={Col} className={"col-lg-6"}>
                    <Form.Label>กรุณากรอกข้อมูลเพื่อค้นหาบัญชี</Form.Label>
                    <InputGroup>
                      <Form.Control
                          type="text"
                          name="searchBank"
                          placeholder="ชื่อ - นามสกุล, ประเภทบัญชี, เลขที่บัญชี"
                          onChange={(e) => setSearchInputValue(e.target.value)}
                          aria-describedby="button_search_01"
                      />
                      <InputGroup.Append>
                        <Button
                            id={"button_search_01"}
                            variant="primary"
                            onClick={() => keySearchInput()}
                        >
                          ค้นหา
                        </Button>
                      </InputGroup.Append>
                    </InputGroup>
                    <Form noValidate onSubmit={handleSubmit2(submitFilter)}>
                      <div className={"pt-2"}>
                        <label>กรุณาเลือกประเภทบัญชีที่ต้องการกรอง</label>
                        {/*<div className={"flex gap-2"}>*/}
                        {/*  <Form.Check custom label={"ฝาก"} type={"radio"} name={"filter_account_type"} id={"filter_account_type1"} value={"deposit"}  ref={register2}/>*/}
                        {/*  <Form.Check custom label={"ถอน"} type={"radio"} name={"filter_account_type"} id={"filter_account_type2"} value={"withdraw"} ref={register2}/>*/}
                        {/*  <Form.Check custom label={"พัก"} type={"radio"} name={"filter_account_type"} id={"filter_account_type3"} value={"holding"} ref={register2}/>*/}
                        {/*</div>*/}
                        <div className={"flex gap-2"}>
                          <InputGroup>
                            <InputGroup.Prepend>
                              <InputGroup.Text className={"bg-primary"}>ประเภทบัญชี</InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control
                                as="select"
                                name="filter_account_type"
                                required
                                ref={register2}
                                custom
                                defaultValue={""}
                            >
                              <option value="">กรุณาเลือก</option>
                              <option value="deposit">ฝาก</option>
                              <option value="withdraw">ถอน</option>
                              <option value="holding">พัก</option>
                              <option value="sweepout">โยกออก</option>
                              <option value="vvip">VVIP</option>
                            </Form.Control>
                          </InputGroup>
                          <InputGroup>
                            <InputGroup.Prepend >
                              <InputGroup.Text className={"bg-success"}>สถานะบัญชี</InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control
                                as="select"
                                name="filter_account_enable"
                                required
                                ref={register2}
                                custom
                                defaultValue={""}
                            >
                              <option value="">กรุณาเลือก</option>
                              <option value={true}>เปิดใช้งาน</option>
                              <option value={false}>ปิดใช้งาน</option>
                            </Form.Control>
                          </InputGroup>
                          <InputGroup>
                            <InputGroup.Prepend >
                              <InputGroup.Text className={"bg-danger"}>สถานะแจกลูกค้า</InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control
                                as="select"
                                name="filter_account_show"
                                required
                                ref={register2}
                                // isInvalid={errors2.type}
                                custom
                                defaultValue={""}
                            >
                              <option value="">กรุณาเลือก</option>
                              <option value={true}>เปิดแจก</option>
                              <option value={false}>ปิดแจก</option>
                            </Form.Control>
                          </InputGroup>
                        </div>
                        {/*<div className={"flex gap-2 pt-2"}>*/}
                        {/*  <Form.Check custom label={"เปิดแจก"} type={"radio"} name={"filter_account_show"} id={"filter_account_show1"} value={true} ref={register2}/>*/}
                        {/*  <Form.Check custom label={"ปิดแจก"} type={"radio"} name={"filter_account_show"} id={"filter_account_show2"} value={false} ref={register2}/>*/}
                        {/*</div>*/}
                        <div className={"pt-2"}>
                          <Button type="submit" variant={"primary"}>กรองบัญชี</Button>{' '}
                          <Button type="button" variant={"danger"} onClick={clearAllFilter}>ล้างค่า</Button>
                        </div>
                      </div>
                    </Form>
                  </Form.Group>
                  <Form.Group
                      as={Col}
                      md="2"
                      lg="2"
                      className={"align-items-center text-center justify-content-center"}>
                    <Form.Label>
                      SMS Application <p/>(ลิงค์ Application SMS Forward)
                    </Form.Label>
                    <div className={"flex justify-content-center"}>
                      <LazyQRSMSApp />
                    </div>
                    <Form.Control.Feedback type="invalid" tooltip>
                      กรุณาระบุชื่อบัญชี
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                      as={Col}
                      md="2"
                      lg="2"
                      className={"align-items-center text-center justify-content-center"}
                  >
                    <Form.Label>SMS QR Code <p/>(กดปุ่ม Scan QR และสแกน ตรงนี้)</Form.Label>
                    <div className={"flex justify-content-center"}>
                      <LazyQRSMSConfig />
                    </div>
                    <Form.Control.Feedback type="invalid" tooltip>
                      กรุณาระบุชื่อบัญชี
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form>
              </Card.Body>
            </Card>
            <BankCardComponent
                cardInfo={bankList}
                cardHeaderColor="card-primary"
                onError={handleError}
                onClose={handleClose}
                afterClose={getAllBank}
                fetch={handleFetchTransaction}
                disableFetch={disableFetch}
                officialBank={officialBank}
            />
            <Card className="flex card-primary">
              <Card.Header>จัดการบัญชีล่อ</Card.Header>
              <Card.Body>
                <Form>
                  <Form.Row>
                    <Form.Group as={Col}>
                      <Form.Label>จำนวนครั้งเข้าบัญชีฝากล่อ</Form.Label>
                      <Form.Control
                          type="number"
                          name="deposit_lure_times"
                          placeholder="จำนวนครั้ง"
                          defaultValue={lureSetting.deposit_lure_times}
                          onChange={handleLureInputChange}
                      />
                    </Form.Group>

                    <Form.Group as={Col}>
                      <Form.Label>จำนวนเงินเข้าบัญชีฝากล่อ</Form.Label>
                      <Form.Control
                          type="number"
                          name="deposit_lure_baht"
                          placeholder="จำนวนเงิน"
                          defaultValue={lureSetting.deposit_lure_baht}
                          onChange={handleLureInputChange}
                      />
                    </Form.Group>

                    <Form.Group as={Col}>
                      <Form.Label>สถานะบัญชีฝาก</Form.Label>
                      <Form.Control
                          as="select"
                          defaultValue={lureSetting.deposit_lure_condition}
                      >
                        <option value="and">และ</option>
                        <option value="or">หรือ</option>
                      </Form.Control>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} controlId="withdraw_lure_times">
                      <Form.Label>จำนวนครั้งออกบัญชีถอนล่อ</Form.Label>
                      <Form.Control
                          type="number"
                          name="withdraw_lure_times"
                          placeholder="จำนวนครั้ง"
                          defaultValue={lureSetting.withdraw_lure_times}
                          onChange={handleLureInputChange}
                      />
                    </Form.Group>

                    <Form.Group as={Col} controlId="withdraw_lure_baht">
                      <Form.Label>จำนวนเงินออกบัญชีถอนล่อ</Form.Label>
                      <Form.Control
                          type="number"
                          name="withdraw_lure_baht"
                          placeholder="จำนวนเงิน"
                          defaultValue={lureSetting.withdraw_lure_baht}
                          onChange={handleLureInputChange}
                      />
                    </Form.Group>
                    <Form.Group as={Col}>
                      <Form.Label>สถานะบัญชีถอน</Form.Label>
                      <Form.Control
                          as="select"
                          defaultValue={lureSetting.withdraw_lure_condition}
                      >
                        <option value="and">และ</option>
                        <option value="or">หรือ</option>
                      </Form.Control>
                    </Form.Group>
                  </Form.Row>
                  <Button
                      onClick={handleUpdateLureAccount}
                      style={{
                        backgroundColor: "#9914d2",
                        color: "#fafafa",
                        border: "none",
                      }}
                  >
                    เปลี่ยน
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          </div>
          <Row>
            <Col className={"col-12"}>
              <ManageMinimumWithdraw/>
            </Col>
            <Col className={"col-12"}>
              <ManageBankAccountConfig/>
            </Col>
            {/*<Col>*/}
            {/*  <ManageAutoSweep/>*/}
            {/*</Col>*/}
            <Col className={"col-12"}>
              <ManageVizPayConfig/>
            </Col>
          </Row>
          <Row>
            <Col className={"col-12"}>
              <ManageCorePayConfig/>
            </Col>
            <Col className={"col-12"}>
              <ManageHengPayConfig/>
            </Col>
            <Col className={"col-12"}>
              <ManagePerfectPaysConfig/>
            </Col>
            <Col className={"col-12"}>
              <ManagePayOneXConfig/>
            </Col>
            <Col className={"col-12"}>
              <ManageWealthWaveConfig/>
            </Col>
          </Row>
        </div>
      </section>
  );
}
