import React from 'react'
import {number_format} from "../utils/Number";
import {Table} from "react-bootstrap";

export default function TableSummary ( props ) {
    const { data } = props

    return (
        <Table responsive hover size="md" className="text-center">
            <thead>
            <tr data-aos="fade-down">
                <th>รายการ</th>
                <th>จำนวน</th>
                <th>ราคาต่อหน่วย</th>
                <th>ราคา</th>
            </tr>
            </thead>
            <tbody>
            {
                data.list.map(({
                                   description,
                                   unit,
                                   price_per_unit,
                                   total_price,
                               }, index) =>

                    <tr key={ index } style={ index % 2 ? { background: "#ddd" } : { background: "#fff" } }>
                        <td style={ { textAlign: "left" } }><h6>{ description }</h6></td>
                        <td style={ { textAlign: "right" } }><h6>{ number_format(unit) }</h6></td>
                        <td style={ { textAlign: "right" } }><h6>{ number_format(price_per_unit, 2) }</h6></td>
                        <td style={ { textAlign: "right" } }><h6>{ number_format(total_price, 2) }</h6></td>
                    </tr>
                )
            }
            <tr style={ { background: "#f6c7c7", borderTop: "5px solid black" } }>
                <td style={ { textAlign: "left" } }><h6>{ data.total.description }</h6></td>
                <td style={ { textAlign: "right" } }><h6>{ number_format(data.total.unit) }</h6></td>
                <td/>
                <td/>
            </tr>
            <tr style={ { background: "#f8e7e7" } }>
                <td/>
                <td/>
                <td style={ { textAlign: "left" } }><h6>{ data.subtotal.description }</h6></td>
                <td style={ { textAlign: "right" } }><h6>{ number_format(data.subtotal.total_price, 2) }</h6></td>
            </tr>
            {
                data.discount.map(({
                                       description,
                                       unit,
                                       price_per_unit,
                                       total_price,
                                   }, index) =>
                    <tr key={ index } style={ { background: "#f8e7e7" } }>
                        <td style={ { textAlign: "left" } }><h6>{ description }</h6></td>
                        <td/>
                        <td/>
                        <td style={ { textAlign: "right" } }><h6>-{ number_format(total_price, 2) }</h6></td>
                    </tr>
                )
            }
            <tr/>
            <tr
                style={ { background: "#c7f6f6", borderTop: "5px solid black", borderBottom: "5px solid black" } }>
                <td/>
                <td/>
                <td style={ { textAlign: "left" } }><h6>รวม</h6></td>
                <td style={ { textAlign: "right" } }><h6>{ number_format(data.summary.total_price, 2) }</h6></td>
            </tr>
            </tbody>
        </Table>
    )
}