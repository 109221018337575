import React, {useEffect, useRef, useState} from 'react'
import {
    Badge,
    Button,
    Card,
    Col,
    Form,
    FormControl,
    InputGroup,
    Modal,
    OverlayTrigger,
    Row,
    Spinner,
    Table,
    Tooltip
} from 'react-bootstrap'
import {useGlobalStore} from '../../store/globalContext'
import api_workorder from "../../api/workorder"
import WorkOrderDetails from '../work_order/WorkOrderDetails'
import moment from 'moment';
import {useForm} from "react-hook-form";
import api from "../../api/customer";
import api_config from "../../api/config";
import ModalConfirmWorkOrder from "../work_order/ModalConfirmWorkOrder";
import apiBank from "../../api/managebank";
// import UFATemplate from '../../../public/ufa_template.xlsx';

export default function RefundCustomers() {
    const [searchButton, setSearchButton] = useState(false);
    const [keyword, setKeyword] = useState("");
    const [{dashboard_work_order_bonus_lost, login_profile}, dispatch] = useGlobalStore();
    const [validated, setValidated] = useState(false);
    const [startDate, setStartDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
    const [refundFiles, setRefundFiles] = useState(undefined);
    const [workOrderDetailsData, setWorkOrderDetailsData] = useState(
        {
            work_order_type: "bonus",
            work_order_sub_type: "lost",
            cardHeader: "สรุปข้อมูลคืนยอดเสีย",
            total_work_order_today: 0,
            total_amount_today: 0,
            current_amount: 0,
            bank_balance: 0,
        });
    const [dataTable, setDataTable] = useState([])
    const [retryInfo, setRetryInfo] = useState({})
    const [showRetryDialog, setShowRetryDialog] = useState(false)

    const {register, handleSubmit, errors, setValue, reset} = useForm({
        defaultValues: {},
        criteriaMode: "all"
    });
    const [customerSearchList, setCustomerSearchList] = useState([])
    const [confirmModal, setConfirmModal] = useState(false)
    const [confirmModalData, setConfirmModalData] = useState(
        {
            user_id: "",
            first_name: "",
            last_name: "",
            amount: "",
        })
    const [confirmRequestData, setConfirmRequestData] = useState({})
    const uploadFileRef = useRef()

    const handleCloseModal = () => {
        setRetryInfo("")
        setShowRetryDialog(false);
    }

    const renderSwitch = (props) => {
        // console.log("renderSwitch :", props)
        const {credit_status, credit_reason} = props
        //console.log("statusText :", status)
        switch (credit_status) {
            case "success":
                return <td><h5><Badge variant="success">{"สำเร็จ"}</Badge></h5></td>;
            case "failed":
                return <td>
                    <h5>
                        <OverlayTrigger
                            placement='top'
                            overlay={
                                credit_reason ?
                                    <Tooltip id={`tooltip-status`}>
                                        {credit_reason}
                                    </Tooltip> : <div>
                                    </div>
                            }
                        >
                            <Badge variant="danger">{"ไม่สำเร็จ"}</Badge>
                        </OverlayTrigger>
                    </h5>
                </td>
            case "deleted":
                return <td><h5><Badge variant="warning">{"ยกเลิก"}</Badge></h5></td>;
            default:
                return <td><Spinner animation="grow" variant="info"/></td>;
        }
    }

    const handleRetry = async () => {
        console.log("retry objectId: ", retryInfo.objectId);
        const work_order_request_retry = {
            id: retryInfo.objectId,
            updated_by: login_profile.name,
        }

        try {
            const response = await api_workorder.WORK_ORDER_RETRY(work_order_request_retry)
            const {data} = response.data
            console.log(`retry work order data: `, data);
            setRetryInfo("")
            setShowRetryDialog(false);
            searchRefundWithoutDate(retryInfo.objectId);
        } catch (e) {
            console.log(`retry work order failed`, e.message);
        }
    }


    const searchRefundWithoutDate = async (keyword) => {

        if (!keyword && keyword.length < 4) {
            return
        }

        console.log("search keyword without date:", keyword)
        const response = await api_workorder.WORK_ORDER_SEARCH(
            {
                keyword: keyword,
                work_order_type: "bonus",
                work_order_sub_type: "lost"
            }
        )
        // console.log("search customer by user id response: ", response);
        if (response.status !== 200) {
            console.log(`search customer by user id failed`, response.message);
            return
        }

        console.log("search refund customer data : ", response.data)
        const {data} = response.data
        setDataTable(data.work_orders)
    }

    const searchRefund = async () => {
        setSearchButton(true)

        if (!keyword && keyword.length === 0) {
            setDataTable([])
            setSearchButton(false)
            return
        }

        if (!keyword && keyword.length < 4) {
            setSearchButton(false)
            return
        }

        console.log("search keyword :", keyword)
        const response = await api_workorder.WORK_ORDER_SEARCH(
            {
                keyword: keyword,
                work_order_type: "bonus",
                work_order_sub_type: "lost",
                date: startDate,
            }
        )
        // console.log("search customer by user id response: ", response);
        if (response.status !== 200) {
            console.log(`search customer by user id failed`, response.message);
            setSearchButton(false)
            return
        }

        let data
        if (response.data.data.work_orders) {
            data = response.data.data.work_orders
        }

        console.log("search refund customer data : ", response.data)
        if (!data) {
            setTimeout(() => {
                setSearchButton(false)
            }, 300)
            return
        }

        setTimeout(() => {
            setSearchButton(false)
        }, 300)
        setDataTable(data)
    }

    const uploadRefundFiles = async () => {
        if (refundFiles === undefined) {
            return
        }

        let currentFile = refundFiles[0];
        console.log("current file: ", currentFile)
        let formData = new FormData();
        formData.append("file", currentFile);

        const response = await api_workorder.UPLOAD_REFUND(formData)
        // console.log("response upload refund: ", response)
        if (response.status !== 200) {
            console.log(`search bank_list by user id failed`, response.message);
            return
        }
        dispatch({
            type: "TOAST2",
            value: {
                show: true,
                type: 'success',
                title: `upload คืนยอดเสีย`,
                message: `upload คืนยอดเสีย สำเร็จ`,
            }
        });
        uploadFileRef.current.value = ""
    }

    const retryRefund = (data) => {
        console.log("retry refund: ", data)
        if (data.credit_status !== "failed") {
            return
        }
        setRetryInfo({
            objectId: data.id,
            user_id: data.user_id,
            first_name: data.first_name,
            last_name: data.last_name,
            amount: data.amount
        })
        setShowRetryDialog(true)
    }

    const onSubmit = workOrderData => {

        const work_order_deposit_request = {
            ...workOrderData,
            work_order_type: "bonus",
            work_order_sub_type: "lost",
            channel: "manual",
            created_by: login_profile.name,
            updated_by: login_profile.name
        }

        setConfirmRequestData(work_order_deposit_request)
        setConfirmModalData({
            work_order_label_type: "โบนัส",
            user_id: workOrderData.user_id,
            first_name: workOrderData.first_name,
            last_name: workOrderData.last_name,
            amount: workOrderData.amount,
        })

        setConfirmModal(true)
    }

    const handleSearchDataFromUserID = async (userIdForSearch) => {
        // const userIdForSearch = getValues("user_id")
        //console.log("user id input", userIdForSearch);

        // if (userIdForSearch.length < ) {
        //     return
        // }
        const response = await api.SEARCH_CUSTOMER({keyword: userIdForSearch})
        // console.log("search customer by user id response: ", response);
        if (response.status !== 200) {
            console.log(`search customer by user id failed`, response.message);
            return
        }

        const {data} = response.data
        if (data === null) {
            setCustomerSearchList([]);
            return
        }
        //console.log("search customer by user id response data: ", data);

        let dataArr = []
        data.map(e => {
            // console.log("element data search: ", e)
            if (e.agents.length === 2) {
                const agentsArr = e.agents
                agentsArr.map(ex => {
                    console.log("agents elements : ", ex)
                    let dTemp = {...e, user_id: ex.username}
                    // dTemp.user_id = ex.username
                    dataArr.push(dTemp)
                })

            } else {
                dataArr.push(e)
            }
        })
        //console.log("element dataArr: ", dataArr)
        setCustomerSearchList(dataArr);
    }

    const handleSetDataFromUserID = (value) => {
        if (value.length === 0) {
            return
        }
        //console.log("handleSetDataFromUserID : ", ...value)
        const result = value[0]
        //const result = value.filter(value => value.user_id === value).pop()
        const {user_id, first_name, last_name} = result
        //console.log("handleSetDataFromUserID : ", user_id, first_name, last_name, mobile_no, bank_accounts)
        setValue("user_id", user_id)
        setValue("first_name", first_name)
        setValue("last_name", last_name)
    }

    const handleSetBankData = (value) => {
        //console.log("handleSetBankData : ", value)
        setValue("from_bank_account_no", value)
    }

    const handleSetDepositBankData = (value) => {
        //console.log("handleSetDepositBankData : ", value)
        setValue("to_bank_account_no", value)
    }

    const confirmSubmit = async (work_order_deposit_request) => {
        console.log("create work_order_deposit_request : ", work_order_deposit_request)
        const response = await api_workorder.WORK_ORDER_CREATE(work_order_deposit_request)

        if (response.status !== 200) {
            console.log(`create work_order_deposit_failed`, response.message);
            return
        }

        dispatch({
            type: "TOAST2",
            value: {
                show: true,
                type: 'success',
                title: `สร้างใบงาน Manual`,
                message: `สร้างใบงาน UserID ${work_order_deposit_request.user_id} สำเร็จ`,
            }
        });

        setConfirmRequestData({})
        setConfirmModalData({})
        setConfirmModal(false)
        reset(register)
    }

    const [instantBonusLostUpload, setInstantBonusLostUpload] = useState({});

    const [isLoading, setIsLoading] = useState(false);
    const {
        register: registerConfig,
        handleSubmit: handleSubmitConfig,
        setValue: setValueConfig,
    } = useForm({});

    const searchConfig = async () => {
        try {
            const request = {
                "name": "instant_bonus_lost_upload",
            };
            const response = await api_config.SEARCH_CONFIG(request);

            setInstantBonusLostUpload(response.data.data?.enable_instant_bonus_lost_upload);
            setValueConfig("enable_instant_bonus_lost_upload", response.data.data?.enable_instant_bonus_lost_upload)
        } catch (e) {
            console.log(e);
        }
    };

    const updateConfig = async (data) => {
        setIsLoading(true);

        const request = {
            ...data,
            "name": "instant_bonus_lost_upload",
        };

        try {
            await api_config.UPDATE_CONFIG(request);

            dispatch({
                type: "TOAST2",
                value: {
                    show: true,
                    type: 'success',
                    title: 'แก้ไขการ เปิด/ปิด คืนยอดเสียอัตโนมัติ',
                    message: `แก้ไขการ เปิด/ปิด คืนยอดเสียอัตโนมัติ สำเร็จ`,
                },
            }, setInstantBonusLostUpload(data?.enable_instant_bonus_lost_upload));

        } catch (error) {
            console.log(error);
            dispatch({
                type: "TOAST2",
                value: {
                    show: true,
                    type: 'danger',
                    title: 'แก้ไขการ เปิด/ปิด คืนยอดเสียอัตโนมัติ',
                    message: `แก้ไขการ เปิด/ปิด คืนยอดเสียอัตโนมัติ ไม่สำเร็จ กรุณาติดต่อ admin`,
                }
            });
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        //console.log("register customer screen before dispatch");
        searchConfig();
        try {
            dispatch({
                type: "SOCKET_SEND",
                value: {method: "subscribe", topic: "dashboard_work_order_bonus_lost"}
            });
        } catch (error) {
            console.log("error :", error);
            dispatch({
                type: "TOAST",
                value: {
                    show: true,
                    type: 'danger',
                    title: `ระบบมีปัญหา`,
                    message: `ขออภัยระบบมีปัญหา กรุณาติดต่อ admin`,
                }
            });
        }
    }, [])

    useEffect(() => {
        console.log("dashboard_work_order_bonus_lost page: ", dashboard_work_order_bonus_lost);
        if (dashboard_work_order_bonus_lost === undefined) {
            return
        }
        setWorkOrderDetailsData(
            {
                ...workOrderDetailsData,
                total_work_order_all: dashboard_work_order_bonus_lost.total_work_order_all,
                total_work_order_today: dashboard_work_order_bonus_lost.total_work_order_today,
                total_amount_today: dashboard_work_order_bonus_lost.total_amount_today,
                work_order_type: "bonus",
                work_order_sub_type: "lost",
                // work_order_sub_type: "lost"
            }
        )
    }, [dashboard_work_order_bonus_lost])

    const downloadTemplateFile = () => {

    }

    return (
        <>
            <ModalConfirmWorkOrder openModal={confirmModal} data={confirmModalData} onConfirm={confirmSubmit}
                                   onClose={() => setConfirmModal(false)} workOrderData={confirmRequestData}/>
            <div className="pt-4 pl-4 pr-4">
                <Modal show={showRetryDialog}>
                    <Modal.Header>
                        <Modal.Title>คืนยอดเสีย Manual</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <p>User ID: {retryInfo.user_id}</p>
                        <p>ชื่อ: {retryInfo.user_id}</p>
                        <p>นามสกุล: {retryInfo.user_id}</p>
                        <p>จำนวนยอด: {retryInfo.amount}</p>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="danger" onClick={() => handleRetry()}>ยืนยัน</Button>
                        <Button variant="secondary" onClick={() => handleCloseModal(false)}>Close</Button>
                    </Modal.Footer>
                </Modal>
                <Card className="card-primary">
                    <Card.Header>ค้นหาคืนยอดเสีย</Card.Header>
                    <Card.Body>
                        <Form>
                            <Form.Row className="col-md-12">
                                <Form.Group as={Col} md="3" lg="3" controlId="validation02">
                                    <Form.Label>เลือกวันที่ต้องการค้นหาใบงาน</Form.Label>
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <InputGroup.Text>Start-Date</InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <FormControl
                                            type="date"
                                            name="start_date"
                                            placeholder="2020-09-10"
                                            required
                                            // isInvalid={!!errors.start_date}
                                            onChange={(e) => setStartDate(moment(e.target.value).format("YYYY-MM-DD"))}
                                            defaultValue={startDate}
                                            // ref={register({ required: true })}
                                        />
                                        <Form.Control.Feedback type="invalid" tooltip>
                                            กรุณาเลือกวันที่ต้องการค้นหา
                                        </Form.Control.Feedback>
                                    </InputGroup>

                                </Form.Group>
                                <Form.Group as={Col} md="6" lg="6" className="col-md-12">
                                    <Form.Label>พิมพ์ค้นหาลูกค้า</Form.Label>
                                    <Form.Control as="input"
                                                  name="search_customer"
                                                  placeholder="eg. ufyu88xxxx/ahag2xxx, ชื่อ - นามสกุล, เบอร์โทรศัพท์, เลขที่บัญชี"
                                                  defaultValue=""
                                                  onChange={(e) => setKeyword(e.target.value)}
                                    >
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group as={Col} md="2" lg="2" className="col-md-12">
                                    <Button className="mt-8 ml-2" md="2" lg="2" onClick={() => searchRefund()}
                                            disabled={searchButton}>
                                        {
                                            (searchButton) ?
                                                (<Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />) : (<>ค้นหา</>)
                                        }
                                    </Button>
                                </Form.Group>
                            </Form.Row>
                            <Table striped borderless hover responsive
                                   className="table table-striped table-valign-middle">
                                <thead className="text-center">
                                <tr>
                                    <th>#</th>
                                    <th>วันที่/เวลา</th>
                                    {/* <th>เวลา</th> */}
                                    <th>UserID</th>
                                    <th>ชื่อ-นามสกุล</th>
                                    <th>บัญชีโอน</th>
                                    <th>บัญชีรับ</th>
                                    <th>จำนวนเงิน</th>
                                    <th>สถานะ credit</th>
                                    <th>Credit คงเหลือ</th>
                                    <th>ผู้ทำรายการ</th>
                                    <th>อัพเดทล่าสุด</th>
                                    <th>หมายเหตุ</th>
                                </tr>
                                </thead>
                                <tbody className="text-center">

                                {
                                    dataTable?.map((
                                            {
                                                id,
                                                created_at,
                                                user_id,
                                                first_name,
                                                last_name,
                                                from_bank_name,
                                                from_bank_account_no,
                                                to_bank_name,
                                                to_bank_account_no,
                                                amount,
                                                status,
                                                credit_status,
                                                credit_reason,
                                                updated_at,
                                                updated_by,
                                                comment

                                            }, index) =>

                                            <tr key={id} onClick={() => retryRefund(dataTable[index])}>

                                                <td>{index + 1}</td>
                                                <td>{moment(created_at).format('DD/MM/YYYY HH:mm')}</td>
                                                <td>{user_id || "-"}</td>
                                                <td>{first_name || "-"} {last_name || "-"}</td>
                                                <td>{from_bank_name || "-"}/{from_bank_account_no || "-"}</td>
                                                <td>{to_bank_name || "-"}/{to_bank_account_no || "-"}</td>
                                                <td>{amount}</td>
                                                {
                                                    renderSwitch({credit_status, credit_reason})
                                                }
                                                {/* <td>{status}</td> */}
                                                <td>-</td>
                                                <td>{updated_by}</td>
                                                <td>{moment(updated_at).format('DD/MM/YYYY HH:mm')}</td>
                                                <td>{comment}</td>
                                            </tr>
                                        // <tr>
                                        //     <td>2</td>
                                        //     <td>12/09/2020 02:25</td>
                                        //     <td>ufyu8880001</td>
                                        //     <td>สิริมล เรืองโห้</td>
                                        //     <td>กสิกร 038xxxxxxx</td>
                                        //     <td>ไทยพาณิชย์ 409xxxxxxx</td>
                                        //     <td className="text-center"><NumberFormat thousandSeparator={true} value={2500.00} displayType="text" /></td>
                                        //     <td className="text-center"><h5><Badge variant="success">Success</Badge></h5></td>
                                        //     <td className="text-center"><NumberFormat thousandSeparator={true} value={5000.00} displayType="text" /></td>
                                        //     <td className="text-center">feveradmin</td>
                                        //     <td className="text-center">12/09/2020 09:09</td>
                                        //     <td>ตัวอย่าง deposit work order success</td>
                                        //     <td className="text-center"><Button size="sm" disabled><i className="fas fa-edit" size="sm" /></Button></td>
                                        // </tr>
                                    )
                                }

                                {/* <tr>
                                                <td>3</td>
                                                <td>12/09/2020 02:25</td>
                                                <td>ufyu8880001</td>
                                                <td>สิริมล เรืองโห้</td>
                                                <td>กสิกร 038xxxxxxx</td>
                                                <td>ไทยพาณิชย์ 409xxxxxxx</td>
                                                <td className="text-center"><NumberFormat thousandSeparator={true} value={2500.00} displayType="text" /></td>
                                                <td className="text-center"><h5><Badge variant="danger">Failed</Badge></h5></td>
                                                <td className="text-center"><NumberFormat thousandSeparator={true} value={5000.00} displayType="text" /></td>
                                                <td className="text-center">feveradmin</td>
                                                <td className="text-center">12/09/2020 09:09</td>
                                                <td><b>Cannot get OTP Ref.</b></td>

                                            </tr>
                                            <tr>
                                                <td>4</td>
                                                <td>12/09/2020 02:25</td>
                                                <td>ufyu8880001</td>
                                                <td>จักรพงษ์ ศรีประสม</td>
                                                <td>กสิกร 049xxxxxxx</td>
                                                <td>กรุงศรี AUTO 091xxxxxxx</td>
                                                <td className="text-center"><NumberFormat thousandSeparator={true} value={2500.00} displayType="text" /></td>
                                                <td className="text-center"><h5><Badge variant="warning">Delete</Badge></h5></td>
                                                <td className="text-center"><NumberFormat thousandSeparator={true} value={5000.00} displayType="text" /></td>
                                                <td className="text-center">feveradmin</td>
                                                <td className="text-center">12/09/2020 09:09</td>
                                                <td><b>น้องหน้างานทำใบงานผิด</b></td>
                                            </tr> */}
                                </tbody>
                            </Table>
                        </Form>
                        {/* <Row>
            <div className="mb-3 col-md-12 col-lg-12">
              <WorkOrderDetails data={workOrderDetailsData} />
            </div>
          </Row> */}
                    </Card.Body>
                </Card>
            </div>
            {
                login_profile.role === "super_admin" &&
                <div className="pt-4 pr-4 pl-4">
                    <Card className="card-warning">
                        <Card.Header>ตั้งค่าการคืนยอดเสียอัตโนมัติ</Card.Header>
                        <Card.Body>
                            <Form onSubmit={handleSubmitConfig(updateConfig)}>
                                <Form.Row>
                                    <Form.Group as={Col} md="3" lg="3" controlId="validation100">
                                        <InputGroup>
                                            <Form.Check
                                                type="switch"
                                                name="enable_instant_bonus_lost_upload"
                                                custom
                                                label="เปิด/ปิดการทำงาน คืนยอดเสียอัตโนมัติ"
                                                isInvalid={!!errors.enable_instant_bonus_lost_upload}
                                                ref={registerConfig}
                                            />
                                            <Form.Control.Feedback type="invalid" tooltip>
                                                กรุณาเลือกสถานะเปิด/ปิด
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Button variant="success" type="submit" disabled={isLoading}>
                                        {isLoading ?
                                            (
                                                <>
                                                    <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    /> กำลังดำเนินการ</>) : `ยืนยันการแก้ไข`}
                                    </Button>
                                </Form.Row>
                            </Form>
                        </Card.Body>
                    </Card>
                </div>
            }
            <div className="pt-4 pr-4 pl-4">
                <Card className="card-danger">
                    <Card.Header>คืนยอดเสีย</Card.Header>
                    <Card.Body>
                        <Form>
                            <div className="mb-3 ">
                                <Row>
                                    <Form.File id="formcheck-api" className="pr-4">
                                        <Form.File.Label>ดาวน์โหลด Template ไฟล์คืนยอดเสีย</Form.File.Label>
                                        <div>
                                            <Button href="ufa_template.xlsx" download="ufa_template.xlsx"
                                                    variant="outline-primary">สำหรับ UFA</Button>{' '}
                                            <Button href="gclub_template.xlsx" download="gclub_template.xlsx"
                                                    variant="outline-success">สำหรับ GCLUB</Button>
                                        </div>
                                    </Form.File>
                                </Row>
                                {
                                    ((login_profile.role === "super_admin" || login_profile.role === "advanced_admin" || login_profile.role === "admin") && instantBonusLostUpload) ?
                                        <>
                                            <Row className={"pt-4"}>
                                                <Form.File id="formcheck-api-regular">
                                                    <Form.File.Label>อัพโหลดไฟล์คืนยอดเสีย</Form.File.Label>
                                                    <p style={{fontWeight: "bold", color: "red"}}>(ไฟล์ล่ะไม่เกิน 1000 รายการ)</p>
                                                    <Form.File.Input ref={uploadFileRef}
                                                                     onChange={(e) => setRefundFiles(e.target.files)}/>
                                                </Form.File>

                                            </Row>
                                            <Row className={"pt-2"}>
                                                <Button variant="danger" onClick={uploadRefundFiles}>คืนยอดเสีย</Button>
                                            </Row>
                                        </> : <p className="text-red-500 font-bold">** ปิดการใช้งานชั่วคราว
                                            จะเปิดกลับมาให้ใช้งานได้ในภายหลัง</p>
                                }
                            </div>
                        </Form>
                    </Card.Body>
                </Card>
            </div>
            <div>
                <WorkOrderDetails data={workOrderDetailsData}/>
            </div>
        </>
    )
}
