import React, { useEffect, useState } from 'react'
import {Button, Card, Fade, Form, Pagination, Spinner} from 'react-bootstrap'
import TableABNormalStatement from "./TableABNormalStatement";
import ModalSearchABNormalStatement from "./ModalSearchABNormalStatement";
import api from "../../api/statement";


export default function ManageABNormalStatement () {
    const [ searchButton, setSearchButton ] = useState( false );
    const [ searchData, setSearchData ] = useState( [] );
    const [ searchKeyword, setSearchKeyword ] = useState( "" );

    const [ modalInfo, setModalInfo ] = useState( {} );
    const [ isOpenModal, setIsOpenModal ] = useState( false );
    const [ errorAlertModal, setErrorAlertModal ] = useState( "" );

    const openModal = ( data, flag, completed ) => {
        if (completed){
            return
        }
        setModalInfo( data );
        setIsOpenModal( flag );
    }

    const handleCloseModal = () => {
        setIsOpenModal( false )
    }

    const [currentPage, setCurrentPage] = useState(1)
    const [dictLastIndices, setDictLastIndices] = useState({1: ""})
    const limitPage = 25

    const clickSearch = async() => {
        setDictLastIndices({1: ""})
        setCurrentPage(1)
    }

    const keySearchInput = async() => {
        if ( searchKeyword.length !== 0 && searchKeyword.length < 2 ) {
            setSearchButton( false )
            return
        }

        setSearchButton( true )

        const request = {
            bank_account_no: searchKeyword,
            limit: limitPage,
            last_element_id: dictLastIndices[currentPage],
        }

        try {
            const response = await api.SEARCH_ABNORMAL_STATEMENT( request );
            const { data } = response.data;
            setSearchData( data );
            if (data) {
                const newIndex = {...dictLastIndices, [currentPage + 1]: data[data.length - 1].id}
                setDictLastIndices(newIndex)
            }
        } catch (e) {
            console.log( "search failed" );
        } finally {
            setTimeout( () => {
                setSearchButton( false );
            }, 300 );
        }
    };

    const handleEditData = async( { updateData } ) => {
        try {
            const request = {
                id: updateData?.id,
                comment: updateData?.comment,
                completed: updateData?.completed,
            };

            await api.UPDATE_ABNORMAL_STATEMENT( request );

            setIsOpenModal( false );
            setSearchKeyword( updateData?.account_no ? updateData?.account_no : "" );
            keySearchInput();
        } catch ( e ) {
            console.log( "error call api: ", e )
            setErrorAlertModal( "ขออภัยไม่สามารถแก้ไขข้อมูลได้ กรุณาตรวจสอบข้อมูลเพิ่มเติม" )
        }
    }

    const handlePrevPage = () => {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1)
        }
    }

    const handleNextPage = () => {
        if (!searchData) {
            return
        }

        if (searchData.length === limitPage) {
            setCurrentPage(currentPage + 1)
        }
    }

    useEffect( () => {
        keySearchInput();
    }, [currentPage] )

    return (
        <div className="pt-4 pr-4 pl-4">
            <ModalSearchABNormalStatement
                isOpenModal={isOpenModal}
                data={modalInfo}
                onClose={handleCloseModal}
                onSave={handleEditData}
                callBackSearch={() => keySearchInput()}
                alertError={errorAlertModal}
            />
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 pt-3">
                            <Card className="card-primary">
                                <Card.Header><h5>ค้นหา statement ผิดปกติ</h5></Card.Header>
                                <Card.Body className="row">
                                    <Form.Row className="col-md-12">
                                        <Form.Group className="col-md-6">
                                            <Form.Label>พิมพ์เลขบัญชี</Form.Label>
                                            <Form.Control as="input"
                                                          name="search_bank_account_no"
                                                          placeholder="eg. เลขบัญชี"
                                                          defaultValue=""
                                                          onChange={( e ) => setSearchKeyword( e.target.value )}
                                            >
                                            </Form.Control>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row className="col-md-12">
                                        <Button style={{ marginBottom: "10px" }}
                                                onClick={()=>clickSearch()}
                                                disabled={searchButton}>
                                            {
                                                ( searchButton ) ?
                                                    ( <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    /> ) : ( <>ค้นหา</> )
                                            }
                                        </Button>
                                        <Pagination className={"ml-auto"}>
                                            <Pagination.Prev onClick={handlePrevPage}/>
                                            <Pagination.Item active={true}>
                                                {currentPage}
                                            </Pagination.Item>
                                            <Pagination.Next onClick={handleNextPage}/>
                                        </Pagination>
                                    </Form.Row>
                                    <Form.Row className="col-md-12">
                                        <Form.Group className="col-md-12">
                                            <Fade in={true}>
                                                {
                                                    ( searchButton ) ?
                                                        <></>:
                                                        <TableABNormalStatement data={searchData} editData={openModal}/>
                                                }
                                            </Fade>
                                        </Form.Group>
                                    </Form.Row>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="col-md-6">
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
};