import React, {useEffect, useMemo, useState} from "react"
import {Modal} from "react-bootstrap";

export default function ModalCustomerLogs(props){

    const modalShow = useMemo(()=>{
        //console.log("use memo open modal : ", props.openModal)
        if (typeof props.openModal !== "boolean"){
            return false
        }else{
            return props.openModal
        }

    },[props.openModal])


    return(
        <Modal show={modalShow} size={"xl"} onHide={()=>props.onClose()}>
            <Modal.Header closeButton={true} >
                <Modal.Title>
                    {props.titleModal}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {props.children}
            </Modal.Body>
            <Modal.Footer>
                {props.footerModal}
            </Modal.Footer>
        </Modal>

    )

}