import React, { useState } from 'react'
import { Button, Col, Form, Modal, Spinner } from 'react-bootstrap'
import api_workorder from "../../api/workorder"
import { useGlobalStore } from '../../store/globalContext'

export default function ConfirmEditWorkOrder ( props ) {
    const [ {
        subscribe,
        dashboard_work_order_deposit,
        customers,
        login_profile
    }, dispatch ] = useGlobalStore();
    const { data, isShow, onClose, callBack } = props
    const [ show, setShow ] = useState( isShow );
    const { id, user_id, first_name, last_name, amount, work_order_type, variant, button_name, status, credit_status } = data
    const [ isLoading, setLoading ] = useState( false );

    const editWorkOrder = async() => {
        setLoading( true )

        const work_order_update_request = {
            work_order_type: work_order_type,
            id: id,
            user_id: user_id,
            first_name: first_name,
            last_name: last_name,
            updated_by: login_profile.name,
        }

        try {
            await api_workorder.WORK_ORDER_UPDATE( work_order_update_request )
            dispatch( {
                type: "TOAST2",
                value: {
                    show: true,
                    type: 'success',
                    title: `อัพเดทใบงาน`,
                    message: `อัพเดทใบงาน UserID ${user_id} สำเร็จ`,
                }
            } );
        } catch (e) {
            console.log("update work order failed", e)
        } finally {
            setTimeout(() => {
                setLoading( false );
                callBack();
            }, 500)
        }
    }


    return (
        <div>
            <Modal show={isShow} onHide={onClose} size="lg" centered={true}>
                <Modal.Header closeButton>
                    <Modal.Title>ยืนยัน{button_name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Row>
                            <Form.Group as={Col} md="3" lg="3">
                                <Form.Label>UserID</Form.Label>
                                <Form.Control type="text" value={user_id} readOnly={true}/>
                            </Form.Group>
                            <Form.Group as={Col} md="3" lg="3">
                                <Form.Label>ชื่อ</Form.Label>
                                <Form.Control type="text" value={first_name} readOnly={true}/>
                            </Form.Group>
                            <Form.Group as={Col} md="3" lg="3">
                                <Form.Label>นามสกุล</Form.Label>
                                <Form.Control type="text" value={last_name} readOnly={true}/>
                            </Form.Group>
                            <Form.Group as={Col} md="3" lg="3">
                                <Form.Label>จำนวนเงิน</Form.Label>
                                <Form.Control type="text" value={amount} readOnly={true}/>
                            </Form.Group>
                        </Form.Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant={variant}
                        disabled={isLoading}
                        onClick={editWorkOrder}
                    >
                        {isLoading ?
                            (
                                <>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    /> กำลังดำเนินการ</> ) : `ยืนยัน${button_name}`}
                    </Button>
                    <Button variant="secondary" onClick={onClose}>
                        ยกเลิก
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
