import React, {useState, useEffect, useRef, useMemo, useCallback} from 'react'
import { useGlobalStore } from '../../store/globalContext'
import WorkOrderDetails from './WorkOrderDetails'
import { Card, Accordion, Button, Row, Col, Form, InputGroup } from 'react-bootstrap';
import { useForm } from 'react-hook-form'
import api from "../../api/customer";
import api_bank from "../../api/managebank";
import api_workorder from "../../api/workorder";
import { AsyncTypeahead } from 'react-bootstrap-typeahead'
import ModalConfirmWorkOrder, {ModalConfirmForceMappingSuccess} from './ModalConfirmWorkOrder'
import {LogoWithAccountNo2, LogoWithAccountNoSimple} from "../banks_logo/index";
import {useRecoilState} from "recoil";
import {notificationsRecoilState, notificationsObjectRecoilState} from '../../store/atoms'
import ShowBankListComponent from "./ShowBankListComponent";


const searchConditions = [
    {label:"ยูสเซอร์(user id)", value:"user_id"},
    {label:"ชื่อ - นามสกุล", value:"name"},
    {label:"เบอร์โทรศัพท์", value:"mobile_no"},
    {label:"หมายเลขบัญชี", value:"bank_account_no"},
    // {label:"หมายเลขอ้างอิงธนาคาร", value:"mapping_bank"}
]

export default function DepositWorkOrder() {
    const [{ subscribe, dashboard_work_order_deposit, customers, status, login_profile }, dispatch] = useGlobalStore();
    const [workOrderDetailsData, setWorkOrderDetailsData] = useState(
        {
            work_order_type: "deposit",
            cardHeader: "สรุปข้อมูลใบงานฝาก",
            total_work_order_today: 0,
            total_work_order_all: 0,
            total_sweep_withdraw_amount_today: 0,
            total_amount_today: 0,
        });

    const [depositBank, setDepositBank] = useState([]);
    const [validated, setValidated] = useState(false);
    const { register, handleSubmit, watch, errors, getValues, setValue, reset } = useForm({
        defaultValues: {},
        criteriaMode: "all"
    });

    const [customerSearchList, setCustomerSearchList] = useState([])
    const [customerBankOptions, setCustomerBankOptions] = useState([])

    const [isLoading, setIsLoading] = useState(false);
    const [confirmModal, setConfirmModal] = useState(false)
    const [confirmModalData, setConfirmModalData] = useState(
        {
            user_id:"",
            first_name:"",
            last_name:"",
            amount:"",
        })
    const [confirmRequestData, setConfirmRequestData] = useState({})
    const typeAheadRef = useRef()
    const [forceMappingModal, setForceMappingModal] = useState(false)

    const [searchConditionSelected, setSearchConditionSelected] = useState("")
    const [searchPlaceholder, setSearchPlaceHolder] = useState("ยูสเซอร์ไอดี/ชื่อ/นามสกุล/เบอร์โทรศัพท์/เลขที่บัญชี")


    const handleforceMappingStatus = (status)=>{
        // console.log("mappingValue before : ", status)
        setValue("mapping_status", status)
        setForceMappingModal(false)
        // console.log("register : ", register)
    }

    useEffect(() => {
        //console.log("register customer screen before dispatch");
        try {
            getDepositBankList();
            dispatch({
                type: "SOCKET_SEND",
                value: { method: "subscribe", topic: "dashboard_work_order_deposit" }
            });
        } catch (error) {
            console.log("error :", error);
            dispatch({
                type: "TOAST",
                value: {
                    show: true,
                    type: 'danger',
                    title: `ระบบมีปัญหา`,
                    message: `ขออภัยระบบมีปัญหา กรุณาติดต่อ admin`,
                }
            });
        }
    }, [])

    useEffect(() => {
        //console.log("dashboard_work_order_deposit page: ", dashboard_work_order_deposit);
        if (dashboard_work_order_deposit === undefined) {
            return
        }
        //console.log("dashboard_work_order_deposit :", dashboard_work_order_deposit)

        setWorkOrderDetailsData(
            {
                ...workOrderDetailsData,
                total_work_order_all: dashboard_work_order_deposit.total_work_order_all,
                total_work_order_today: dashboard_work_order_deposit.total_work_order_today,
                total_amount_today: dashboard_work_order_deposit.total_amount_today,
                current_amount: dashboard_work_order_deposit.current_amount,
                bank_balance: dashboard_work_order_deposit.bank_balance,
            }
        )

    }, [dashboard_work_order_deposit])


    const getDepositBankList = useCallback(async () => {

        const response = await api_bank.BANK_SEARCH_TYPE({ keyword: "deposit+vvip" })
        if (response.status !== 200) {
            console.log(`search bank_list by user id failed`, response.message);
            return
        }

        const { data } = response.data
        //console.log("search deposit bank response data: ", data);
        setDepositBank(data);
    },[])

    const confirmSubmit = async(work_order_deposit_request)=>{
        // console.log("forceSuccessValue in submit: ", getValues("force_success"))
        // console.log("mappingStatusValue in submit: ", getValues("mapping_status"))
        //
        // console.log("create work_order_deposit_request : ", work_order_deposit_request)
        const response =  await api_workorder.WORK_ORDER_CREATE(work_order_deposit_request)

        if (response.status !== 200) {
            console.log(`create work_order_deposit_failed`, response.message);
            return
        }

        dispatch({
            type: "TOAST2",
            value: {
                show: true,
                type: 'success',
                title: `สร้างใบงาน Manual`,
                message: `สร้างใบงาน UserID ${work_order_deposit_request.user_id} สำเร็จ`,
            }
        });
        
        setConfirmRequestData({})
        setConfirmModalData({})
        setConfirmModal(false)
        typeAheadRef.current.clear()
        reset(register)
        setValue("mapping_status", "")
        setValue("force_success", false)
    }

    const onSubmit = workOrderData => {
        // console.log("workOrderData :", workOrderData)
        const bankCustomer = customerBankOptions.filter(customerBankOptions => customerBankOptions.bank_account_no === workOrderData.from_bank_name).pop()
        const bankWorkOrder = depositBank.filter(depositBank => depositBank.bank_account_no === workOrderData.to_bank_name).pop()

        const work_order_deposit_request = {
            ...workOrderData,
            work_order_type: "deposit",
            from_bank_name: bankCustomer.bank_account_name,
            from_bank_account_no: bankCustomer.bank_account_no,
            from_bank_short_name: bankCustomer.bank_short_name,
            to_bank_name: bankWorkOrder.bank_name,
            to_bank_code: bankWorkOrder.bank_code,
            to_bank_account_no: bankWorkOrder.bank_account_no,
            to_bank_short_name: bankWorkOrder.bank_short_name,
            channel: "manual",
            created_by: login_profile.name,
            updated_by: login_profile.name
        }

        setConfirmRequestData(work_order_deposit_request)
        setConfirmModalData({
            work_order_label_type: "ฝาก",
            user_id: workOrderData.user_id,
            first_name: workOrderData.first_name,
            last_name: workOrderData.last_name,
            amount: workOrderData.amount,
        })
        
        setConfirmModal(true)
    }

    const handleSearchDataFromUserID = async (userIdForSearch) => {

        if (searchConditionSelected === "") {
            return
        }

        const response = await api.SEARCH_CUSTOMER({
            field: searchConditionSelected,
            keyword: userIdForSearch
        })
        // console.log("search customer by user id response: ", response);
        if (response.status !== 200) {
            console.log(`search customer by user id failed`, response.message);
            return
        }

        const { data } = response.data
        if (data === null) {
            setCustomerSearchList([]);
            return
        }
        //console.log("search customer by user id response data: ", data);

        let dataArr = []
        data.map(e => {
            // console.log("element data search: ", e)
            if (e.agents.length === 2){
                const agentsArr = e.agents
                agentsArr.map(ex =>{
                    //console.log("agents elements : ", ex)
                    let dTemp = {...e, user_id : ex.username}
                    // dTemp.user_id = ex.username
                    dataArr.push(dTemp)
                }) 
                
            }else{
                dataArr.push(e)
            }
        })
        setCustomerSearchList(dataArr);
    }

    const handleSetDataFromUserID = (value) => {
        if (value.length === 0) {
            typeAheadRef.current.clear()
            return
        }
        //console.log("handleSetDataFromUserID : ", ...value)
        const result = value[0]
        //const result = value.filter(value => value.user_id === value).pop()
        const { user_id, first_name, last_name, mobile_no, bank_accounts } = result
        //console.log("handleSetDataFromUserID : ", user_id, first_name, last_name, mobile_no, bank_accounts)
        setValue("user_id", user_id)
        setValue("first_name", first_name)
        setValue("last_name", last_name)
        
        //console.log("bank_accounts : ", bank_accounts)
        let customerBank = []
        bank_accounts.map(({ bank_short_name, bank_account_name, bank_account_no }, index) => {
            //console.log("in loop", bank_short_name, bank_account_name, bank_account_no)
           
            if (bank_accounts.length === 1){
                customerBank.push({ bank_short_name: bank_short_name, bank_account_name: bank_account_name, bank_account_no: bank_account_no, selected: true })
                setValue("from_bank_account_no", customerBank[0].bank_account_no)
            }else{
                customerBank.push({ bank_short_name: bank_short_name, bank_account_name: bank_account_name, bank_account_no: bank_account_no, selected: false })
            }
        })

        setCustomerBankOptions(customerBank);
        typeAheadRef.current.clear()
    }

    const handleSetBankData = (value) => {
        //console.log("handleSetBankData : ", value)
        setValue("from_bank_account_no", value)
    }
    const handleSetDepositBankData = (value) => {
        //console.log("handleSetDepositBankData : ", value)
        setValue("to_bank_account_no", value)
    }

    const handleForceSuccessModal = () =>{
        const forceSuccessValue = getValues("force_success")
        //console.log("forceSuccessValue :", forceSuccessValue)
        if (forceSuccessValue){
            setForceMappingModal(forceSuccessValue)
        }else{
            setValue("force_success", "")
            setValue("mapping_status", "")
        }
    }

    const handleSearchCondition = (value) =>{
        // console.log("handleSearchCondition value : ", value)
        if (value === ""){
            setValue("field", "")
            setSearchConditionSelected("")
            setSearchPlaceHolder("ยูสเซอร์ไอดี/ชื่อ/นามสกุล/เบอร์โทรศัพท์/เลขที่บัญชี/หมายเลขอ้างอิงธนาคาร")
            reset(register)
        }else{
            setSearchConditionSelected(value)
            const labelFilter = searchConditions.find(e=>
                value === e.value
            )

            // console.log("handleSearchCondition labels : ", labelFilter.label)
            setSearchPlaceHolder(`กรุณาค้นหาข้อมูลด้วย ${labelFilter.label}`)
        }
        typeAheadRef.current.clear()
    }

    const [notificationObject, setNotificationObject]= useRecoilState(notificationsObjectRecoilState)

    const appendNoti = ()=>{
        // if (notificationObject.type === "info"){
        //     setNotificationObject({text: "test toast danger", type: "info", icon:<i className={`fa fa-exclamation-circle`}/>})
        // }else if (notificationObject.type === "info"){
        //     setNotificationObject({text: "test toast info", type: "success", icon:<i className={`fas fa-check-circle`}/>})
        // }

        dispatch({
            type: "TOAST2",
            value: {
                show: true,
                type: 'danger',
                title: `สร้างใบงาน Manual`,
                message: `ทดสอบ new TOAST by dispatch`,
            }
        });

    }

    return (
        <>  
            <ModalConfirmWorkOrder openModal={confirmModal} data={confirmModalData} onConfirm={confirmSubmit} onClose={()=>setConfirmModal(false)} workOrderData={confirmRequestData} />
            <ModalConfirmForceMappingSuccess showModal={forceMappingModal}  onClose={()=>{
                setForceMappingModal(false); setValue("force_success")}} onConfirm={handleforceMappingStatus}/>
            <div className="col-md-12 pt-4 pr-4 pl-4">
                <Accordion eventKey="0">
                    <Card className="card-warning">
                        <Card.Header >
                            <Accordion.Toggle as={"div"} variant="link" eventKey="0" >
                                เพิ่มใบงานฝากเติมมือ (Manual) {' '}
                                <Button variant={"outline-dark"} style={{borderRadius: "50px"}} size={"sm"} as={"button"} >
                                    <i className={`fas fa-plus`}/>
                                </Button>
                            </Accordion.Toggle>
                                {/*<Button variant={"outline-dark"} style={{borderRadius: "50px"}} size={"sm"} onClick={()=>appendNoti()}>*/}
                                {/*    <i className={`fa fa-exclamation-circle`}/>*/}
                                {/*</Button>*/}
                        </Card.Header>
                        <Accordion.Collapse eventKey="0" >
                            <Card.Body>
                                <Form noValidate validated={validated} onSubmit={handleSubmit(onSubmit)}>
                                    <Form.Row>
                                        <Form.Group as={Col} md="6" lg="6" controlId="validation001">
                                            <Form.Label>ค้นหาลูกค้า</Form.Label>
                                            <Row>
                                                <Col md="4" lg="4">
                                                    <Form.Control
                                                        ref={register}
                                                        as={"select"}
                                                        name={"field"}
                                                        custom
                                                        onChange={(e)=>handleSearchCondition(e.currentTarget.value)}
                                                        selected={searchConditionSelected}
                                                    >
                                                        <option value="">เลือกเงื่อนไขการค้นหา</option>
                                                        {
                                                            searchConditions?.map(({ label, value }, index) =>
                                                                <option
                                                                    key={value}
                                                                    value={value}
                                                                >
                                                                    {label}
                                                                </option>
                                                            )
                                                        }
                                                    </Form.Control>
                                                </Col>

                                            <Col md="8" lg="8">
                                            <AsyncTypeahead 
                                            // size="md"
                                                clearButton
                                                delay={1500}
                                                id="search_customer"
                                                className={"inline"}
                                                isLoading={isLoading}
                                                labelKey={
                                                    (option) => `${option.user_id} ${option.first_name} ${option.last_name} ${option.mobile_no} ${JSON.stringify(option.bank_accounts.map((e)=>{
                                                            return { เลขที่บัญชี: e.bank_account_no, value: e.Value }
                                                    }))}`
                                                }
                                                minLength={5}
                                                onSearch={handleSearchDataFromUserID}
                                                options={customerSearchList}
                                                placeholder={searchPlaceholder}
                                                searchText="กำลังค้นหาลูกค้า"
                                                onChange={handleSetDataFromUserID}
                                                disabled={searchConditionSelected === ""}
                                                ref={typeAheadRef}
                                                renderMenuItemChildren={(option) => (
                                                    <div className={"flex hover:bg-blue-200"}>
                                                        <div style={{"width":"55%"}}>
                                                            <p>
                                                                <b className={"text-primary"}>{option.user_id}</b>
                                                            </p>
                                                            <p>
                                                                <b>{option.first_name} - {option.last_name}</b>
                                                                {' | '}
                                                                <b className={"text-success"}>{option.mobile_no}</b>
                                                            </p>
                                                        </div>
                                                        <div className={"flex-row"}>
                                                        {
                                                            option.bank_accounts.map((bank) => {
                                                                return(
                                                                        <p>
                                                                            <LogoWithAccountNoSimple
                                                                                bankShortName={ bank.bank_short_name }
                                                                                bankFullName={bank.bank_account_name}
                                                                                bankName={ bank.bank_account_name }
                                                                                accountNo={ bank.bank_account_no }
                                                                            />
                                                                        </p>
                                                                )
                                                            })
                                                        }
                                                        </div>
                                                    </div>
                                                )}

                                            >
                                            </AsyncTypeahead>
                                            </Col>
                                                {/*<Col md="1" lg="1">*/}
                                                {/*    <Button variant={"primary"}><i className="fas fa-search"/></Button>*/}
                                                {/*</Col>*/}
                                            </Row>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} md="2" lg="2" controlId="validation01">
                                            <Form.Label>UserID</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                name="user_id"
                                                placeholder="ufyuxxxxx/ahagxxxx"
                                                isInvalid={!!errors.first_name}
                                                ref={register({ required: true })}
                                                readOnly={true}
                                            />

                                            <Form.Control.Feedback type="invalid" tooltip>
                                                กรุณาระบุ ชื่อ
                                                    </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="2" lg="2" controlId="validation02">
                                            <Form.Label>ชื่อ</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                name="first_name"
                                                placeholder="ชื่อ"
                                                isInvalid={!!errors.first_name}
                                                ref={register({ required: true })}
                                                readOnly={true}
                                            />

                                            <Form.Control.Feedback type="invalid" tooltip>
                                                กรุณาระบุ ชื่อ
                                                    </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="2" lg="2" controlId="validation03">
                                            <Form.Label>นามสกุล</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                name="last_name"
                                                placeholder="นามสกุล"
                                                isInvalid={!!errors.last_name}
                                                ref={register({ required: true })}
                                                readOnly={true}
                                            />

                                            <Form.Control.Feedback type="invalid" tooltip>
                                                กรุณาระบุนามสกุล
                                                    </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="2" lg="2" controlId="validation04">
                                            <Form.Label>บัญชีธนาคารลูกค้า</Form.Label>
                                            <Form.Control
                                                required
                                                as="select"
                                                custom
                                                name="from_bank_name"
                                                placeholder="ufyuxxxx/ahagxxxx"
                                                isInvalid={!!errors.from_bank_name}
                                                ref={register({ required: true })}
                                                onChange={(e) => handleSetBankData(e.target.value)}
                                            >
                                                <option value="">Please Select</option>
                                                {
                                                    customerBankOptions.map(({ bank_short_name, bank_account_name, bank_account_no, selected }, index) =>
                                                        <option
                                                            key={index}
                                                            value={bank_account_no}
                                                            // defaultValue={bank_account_no}
                                                            selected={selected}
                                                        // onChange={handleSetDataFromUserID({ user_id, first_name, last_name, mobile_no, bank_accounts })}
                                                        >
                                                            {bank_account_name}
                                                        </option>
                                                    )
                                                }
                                            </Form.Control>

                                            <Form.Control.Feedback type="invalid" tooltip>
                                                กรุณาระบุบัญชีธนาคารลูกค้า
                                                    </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="2" lg="2" controlId="validation05">
                                            <Form.Label>เลขที่บัญชีธนาคารลูกค้า</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                name="from_bank_account_no"
                                                placeholder="-"
                                                isInvalid={!!errors.from_bank_account_no}
                                                ref={register({ required: true })}
                                                readOnly={true}
                                            />

                                            <Form.Control.Feedback type="invalid" tooltip>
                                                กรุณาระบุเลขที่บัญชีธนาคารลูกค้า
                                                    </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} md="2" lg="2" controlId="validation06">
                                            <Form.Label>เลือกธนาคารฝาก</Form.Label>
                                            <InputGroup>
                                                <Form.Control
                                                    as="select"
                                                    name="to_bank_name"
                                                    custom
                                                    required
                                                    isInvalid={!!errors.to_bank_name}
                                                    ref={register({ required: true })}
                                                    onChange={(e) => handleSetDepositBankData(e.target.value)}
                                                >
                                                    <option value="">Please Select</option>
                                                    {
                                                        depositBank?.map(({ bank_name, bank_account_no }, index) =>
                                                            <option
                                                                key={bank_account_no}
                                                                value={bank_account_no}
                                                            >
                                                                {bank_name}
                                                            </option>
                                                        )
                                                    }
                                                </Form.Control>
                                                <Form.Control.Feedback type="invalid" tooltip>
                                                    กรุณาระบุธนาคารฝาก
                                                    </Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>
                                        <Form.Group as={Col} md="2" lg="2" controlId="validation07">
                                            <Form.Label>เลขที่บัญชีธนาคารฝาก</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                name="to_bank_account_no"
                                                placeholder="-"
                                                isInvalid={!!errors.to_bank_account_no}
                                                ref={register({ required: true })}
                                                disabled
                                            />

                                            <Form.Control.Feedback type="invalid" tooltip>
                                                กรุณาระบุเลขที่บัญชีธนาคารฝาก
                                                    </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="2" lg="2" controlId="validation08">
                                            <Form.Label>จำนวนเงิน</Form.Label>
                                            <Form.Control
                                                required
                                                type="number"
                                                name="amount"
                                                step={1}
                                                placeholder="100/200.50"
                                                isInvalid={!!errors.amount}
                                                ref={register({ required: true })}
                                            />

                                            <Form.Control.Feedback type="invalid" tooltip>
                                                กรุณาระบุ จำนวนเงิน
                                                    </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" lg="6" controlId="validation09">
                                            <Form.Label>หมายเหตุ</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="comment"
                                                placeholder="อ่าน statement ไม่เจอ/ธนาคารมีปัญหา"
                                                isInvalid={!!errors.comment}
                                                ref={register}
                                            />

                                            <Form.Control.Feedback type="invalid" tooltip>
                                                กรุณาระบุ จำนวนเงิน
                                                    </Form.Control.Feedback>
                                        </Form.Group>
                                        </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} md="6" lg="6" controlId="validation09">
                                            <Form.Check
                                                as={"input"}
                                                type="checkbox"
                                                label={"**กรณีไม่ต้องการทำการจับคู่ภายหลัง"}
                                                name="force_success"
                                                placeholder="อ่าน statement ไม่เจอ/ธนาคารมีปัญหา"
                                                ref={register}
                                                onChange={()=>handleForceSuccessModal()}
                                            />
                                            <Form.Control type="hidden" name="mapping_status" ref={register}/>
                                        </Form.Group>
                                    </Form.Row>
                                            <Button variant="success" type="submit">เพิ่มใบงานฝากเติมมือ</Button>{' '}
                                </Form>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            </div>
            <ShowBankListComponent BankList={depositBank} reloadBankList={getDepositBankList}/>
            <WorkOrderDetails data={workOrderDetailsData} />
            {/* </div> */}
        </>
    )
}
