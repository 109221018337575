import React, {useEffect, useLayoutEffect, useMemo, useState} from 'react'
import {Button, Modal, Table} from "react-bootstrap";
import moment from "moment";


export default function ModalCommentTracking(props) {
    const {showModal, data, onClose} = props;

    const showByMemo = useMemo(()=>{
        // console.log("show by memo : ", showModal)
        // console.log("show by memo data : ", data)
        return showModal
    },[showModal])


    return (
        <Modal size="xl" show={showByMemo} onHide={() => onClose()}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <p>หมายเหตุเพิ่มเติม</p>
                    {
                        (data.user_id && data.first_name && data.last_name) &&
                    <p>เลขยูส : <b className={"text-primary"}>{data.user_id ? data.user_id  : "-"}</b><b> {data.first_name ? data.first_name: "-"} {data.last_name ? data.last_name: ""}</b></p>
                    }
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    (data.comment_tracking && data.comment_tracking.length > 0) ?
                        <Table responsive hover size="md" className="text-center" bordered={true}>
                            <thead className="text-center">
                            <tr>
                                <th>#</th>
                                <th>วันที่/เวลา</th>
                                <th>อัพเดทโดย</th>
                                <th>หมายเหตุ</th>
                            </tr>
                            </thead>
                            <tbody className="text-center">
                            {
                                data.comment_tracking.map(({
                                                         updated_by,
                                                         update_at,
                                                         comment,
                                                     }, index) =>
                                    <tr key={index + 1} className="" style={{"cursor": "pointer"}}>
                                        <td><h6>{index + 1}</h6></td>
                                        <td><h6>{update_at ?  moment(update_at).format('DD/MM/YYYY HH:mm:ss'): "-"}</h6></td>
                                        <td><h6>{(updated_by && updated_by !== "system_auto")? updated_by: <i className={`nav-icon fas fa-laptop-code fa-2x`}/>}</h6></td>
                                        <td className={"text-left"}><h6>{comment ? comment : "-"}</h6></td>
                                    </tr>
                                )
                            }
                            </tbody>
                        </Table>
                        : <p className="font-bold text-red-500 text-xl">ไม่มีข้อมูลหมายเหตุเพิ่มเติม</p>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => onClose()}>ปิด</Button>
            </Modal.Footer>
        </Modal>
    )
}