import React from 'react'
import { Badge, Table } from 'react-bootstrap'
import { useGlobalStore } from "../../store/globalContext";

export default function TableSearchLuckyDraw ( props ) {
    const [{ login_profile }] = useGlobalStore();
    const { luckyDrawSearchList, editLuckyDrawData, viewLuckyDrawStats } = props;

    const generateActiveStatus = ( active ) => {
        if ( active ) {
            return ( <td><h6><Badge variant="success">{"เปิด"}</Badge>{' '}</h6></td> )
        } else {
            return ( <td><h6><Badge variant="danger">{"ปิด"}</Badge>{' '}</h6></td> )
        }
    };

    const generateStatus = ( active, deleted, completed ) => {
        if ( deleted ) {
            return ( <h6><Badge variant="warning">{"ยกเลิก"}</Badge>{' '}</h6> )
        } else if ( completed ) {
            return ( <h6><Badge variant="success">{"สำเร็จ"}</Badge>{' '}</h6> )
        } else {
            if ( active ) {
                return ( <h6><Badge variant="primary">{"เปิด"}</Badge>{' '}</h6> )
            } else {
                return ( <h6><Badge variant="danger">{"ปิด"}</Badge>{' '}</h6> )
            }
        }
    };

    const descriptionLength = 60;

    const handleClick = (index) => {
        if (login_profile.role === "super_admin" ) {
            editLuckyDrawData( luckyDrawSearchList[ index ], true )
        }
    }

    return (
        <Table responsive hover size="md" className="text-center">
            <thead className="text-center">
            <tr>
                <th>#</th>
                <th>ชื่อ</th>
                <th>คำอธิบาย</th>
                <th>รูปภาพ</th>
                <th>สถานะเกมชิงโชค</th>
                <th>ดูสถิติ</th>
            </tr>
            </thead>
            <tbody className="text-center">
            {
                luckyDrawSearchList.map( ( {
                                               id,
                                               name,
                                               description,
                                               image_url,
                                               active,
                                               deleted,
                                               completed,
                                           }, index ) =>
                    <tr key={index + 1} className="" objectId={id} style={{ "cursor": "pointer" }}>
                        <td onClick={() => handleClick(index)}><h6>{index + 1}</h6>
                        </td>
                        <td onClick={() => handleClick(index)}><h6>{name}</h6></td>
                        <td onClick={() => handleClick(index)}><h6>{
                            description.length > descriptionLength ?
                                description.substring( 0, descriptionLength - 3 ) + "..." :
                                description}</h6></td>
                        <td align="center" onClick={() => handleClick(index)}>
                            <img src={image_url} alt={description} height={300} width={200} className="mx-auto"/>
                        </td>
                        <td onClick={() => handleClick(index)}>
                            {generateStatus( active, deleted, completed )}
                        </td>
                        <td>
                            <button className='bg-blue-500 p-2 rounded hover:bg-yellow-500 focus:outline-none'
                                    onClick={() => viewLuckyDrawStats( luckyDrawSearchList[ index ], true )}>ดูสถิติ
                            </button>
                        </td>
                    </tr>
                )
            }
            </tbody>
        </Table>
    )
}
