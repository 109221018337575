import React, {useEffect, useState} from 'react'
import {Badge, Button, Card, Col, Fade, Form, FormControl, InputGroup, Row, Spinner, Table} from "react-bootstrap";
import {number_format} from "../utils/Number";
import ModalTopupCreditGclub from "./ModalTopupCreditGclub";
import api from "../../api/agents";
import moment from "moment";
import ModalUnlockGclub from "./ModalUnlockGclub";

export default function ManageTopupCreditGclub() {
    const [modalInfo, setModalInfo] = useState({});
    const [isOpenModal, setIsOpenModal] = useState(false);
    const handleOpenModal = (data, flag) => {
        setModalInfo(data);
        setIsOpenModal(flag);
    };

    const handleCloseModal = () => {
        setIsOpenModal(false);
    };

    const handleSaveData = () => {
        setIsOpenModal(false);
        getAgentListGClub();
    };

    const [unlockModalInfo, setUnlockModalInfo] = useState({});
    const [isOpenUnlockModal, setIsOpenUnlockModal] = useState(false);
    const handleOpenUnlockModal = (data, flag) => {
        setUnlockModalInfo(data);
        setIsOpenUnlockModal(flag);
    };

    const handleCloseUnlockModal = () => {
        setIsOpenUnlockModal(false);
    };

    const [agentListGClub, setAgentListGClub] = useState([]);
    const getAgentListGClub = async () => {
        try {
            const response = await api.AGENT_LIST_GCLUB();
            setAgentListGClub(response.data.data)
        } catch (e) {
            console.log(e);
        }
    };

    const ed = new Date();
    const std = new Date().setDate(ed.getDate() - 5);
    const [startDate, setStartDate] = useState(moment(std).format("YYYY-MM-DD"));
    const [endDate, setEndDate] = useState(moment(ed).format("YYYY-MM-DD"));
    const [loadingHistory, setLoadingHistory] = useState(false);
    const [topupHistoryData, setTopupHistoryData] = useState([]);

    const searchHistory = async () => {
        setLoadingHistory(true);
        try {
            const request = {
                start_date: startDate, end_date: endDate,
            };
            const response = await api.ADD_CREDIT_GCLUB_HISTORY(request);
            const {data} = response.data;
            setTopupHistoryData(data);
        } catch (e) {
            console.log(e);
        } finally {
            setLoadingHistory(false);
        }
    };

    const generateStatus = (status) => {
        if (status === "success") {
            return (<td><h6><Badge variant="success">สำเร็จ</Badge>{' '}</h6></td>)
        } else if (status === "failed") {
            return (<td><h6><Badge variant="danger">ไม่สำเร็จ</Badge>{' '}</h6></td>)
        } else {
            return (<td><h6><Badge variant="primary">{status}</Badge>{' '}</h6></td>)
        }
    };

    useEffect(() => {
        getAgentListGClub();
        searchHistory();
    }, []);

    return (<section className="content pt-4 pr-4 pl-4">
        <div className="container-fluid">
            <div>
                <ModalTopupCreditGclub
                    data={modalInfo}
                    isOpenModal={isOpenModal}
                    onClose={handleCloseModal}
                    onSave={handleSaveData}
                />
                <ModalUnlockGclub
                    data={unlockModalInfo}
                    isOpenModal={isOpenUnlockModal}
                    onClose={handleCloseUnlockModal}
                />
                <Card className="card-primary">
                    <Card.Header>เติมเครดิต gclub</Card.Header>
                    <Card.Body>
                        <Fade in={true}>
                            <Table responsive hover size="md">
                                <thead className="text-center">
                                <tr>
                                    <th>Username</th>
                                    <th>ยอดเครดิตคงเหลือ (บาท)</th>
                                    <th>เติมเครดิต</th>
                                    <th>ปลดล็อก</th>
                                </tr>
                                </thead>
                                <tbody className="text-center">
                                {agentListGClub?.map(({
                                                          id, username, remaining_credit_line,
                                                      }, index) => <tr key={id}>
                                    <td><h6>{username}</h6></td>
                                    <td><h6>{number_format(remaining_credit_line, 2)}</h6></td>
                                    <td>
                                        <button
                                            className='bg-red-500 p-2 rounded hover:bg-yellow-500 focus:outline-none'
                                            onClick={() => handleOpenModal({username, remaining_credit_line}, true)}>
                                            เติมเครดิต
                                        </button>
                                    </td>
                                    <td>
                                        <button
                                            className='bg-blue-500 p-2 rounded hover:bg-green-500 focus:outline-none'
                                            onClick={() => handleOpenUnlockModal({username}, true)}>
                                            ปลดล็อก
                                        </button>
                                    </td>
                                </tr>)}
                                </tbody>
                            </Table>
                        </Fade>
                    </Card.Body>
                </Card>
                <Card className="card-info">
                    <Card.Header>ประวัติการเติมเครดิต gclub</Card.Header>
                    <Card.Body>
                        <Form>
                            <Form.Row>
                                <Col sm={5} md={5} lg={5}>
                                    <Form.Label htmlFor="inlineFormInputGroupStartDate" srOnly>
                                        2020-09-21
                                    </Form.Label>
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <InputGroup.Text>Start-Date</InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <FormControl
                                            type="date"
                                            id="inlineFormInputGroupStartDate"
                                            placeholder="2020-09-10"
                                            onChange={(e) => setStartDate(moment(e.target.value).format("YYYY-MM-DD"))}
                                            defaultValue={startDate}
                                        />
                                    </InputGroup>
                                </Col>
                                <Col sm={5} md={5} lg={5}>
                                    <Form.Label htmlFor="inlineFormInputGroupEndDate" srOnly>
                                        2020-09-21
                                    </Form.Label>
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <InputGroup.Text>End-Date</InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <FormControl
                                            type="date"
                                            id="inlineFormInputGroupEndDate"
                                            placeholder="2020-09-21"
                                            onChange={(e) => setEndDate(moment(e.target.value).format("YYYY-MM-DD"))}
                                            defaultValue={endDate}
                                        />
                                    </InputGroup>
                                </Col>
                                <Col xs="auto">
                                    {loadingHistory ? <>
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        /> กำลังค้นหา
                                    </> : <Button type="button" onClick={searchHistory}>ค้นหาข้อมูล</Button>}
                                </Col>
                            </Form.Row>
                        </Form>
                        <Row>
                            <Col lg={12} className="text-center pt-4">
                                <Fade in={true}>
                                    <Table responsive hover size="md">
                                        <thead className="text-center">
                                        <tr>
                                            <th>Username</th>
                                            <th>วันที่</th>
                                            <th>ผู้ทำรายการ</th>
                                            <th>จำนวนเครดิตที่เติม (บาท)</th>
                                            <th>ยอดก่อนเติมเครดิต (บาท)</th>
                                            <th>ยอดหลังเติมเครดิต (บาท)</th>
                                            <th>สถานะ</th>
                                        </tr>
                                        </thead>
                                        {topupHistoryData?.map(({
                                                                    history,
                                                                }, index) => (history.map(({
                                                                                               id,
                                                                                               username,
                                                                                               created_at,
                                                                                               created_by,
                                                                                               amount,
                                                                                               before_balance,
                                                                                               after_balance,
                                                                                               status
                                                                                           }, index) => <tr
                                            key={index + 1} className="" objectId={id}
                                            style={{"cursor": "pointer"}}>
                                            <td><h6>{username}</h6></td>
                                            <td><h6>{moment(created_at).format('DD/MM/YYYY HH:mm:ss')}</h6></td>
                                            <td><h6>{created_by}</h6></td>
                                            <td><h6>{number_format(amount, 2)}</h6></td>
                                            <td><h6>{number_format(before_balance, 2)}</h6></td>
                                            <td><h6>{number_format(after_balance, 2)}</h6></td>
                                            {generateStatus(status)}
                                        </tr>)))}
                                    </Table>
                                </Fade>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </div>
        </div>
    </section>)
}
