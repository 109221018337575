import React, { useState } from 'react'
import moment from 'moment'
import { Table, Badge, Button} from 'react-bootstrap'
import ModalCreateCCUsers from './ModalCreateCCUsers'
import { useGlobalStore } from '../../store/globalContext'

export default function TableCCUsersCardComponent(props) {
  const [{login_profile}, dispatch] = useGlobalStore();
  const { ccUserSearchList, callBack } = props;
  // const [tempCCUserList, setTempCCUserList] = useState(ccUserSearchList)

  const [modalProps, setModalProps] = useState({ action: "create", modalHeader: "เพื่ม CC Users ใหม่", buttonSubmit: "เพิ่ม CC Users", data: {}, show: false, openModal: null, closeModal: null })
  // console.log(customerSearchList);

  const handleCreate = () => {
    console.log("handleCreate")
    setModalProps(
      {
        ...modalProps,
        action: "create",
        modalHeader: "เพิ่ม CC Users",
        buttonSubmit: "เพิ่ม User",
        closeModal: { closeModal },
        show: true,
      }
    )
  }

  const editCCUserData = (data) => {
      console.log("edit user data: ", data)
      console.log("login_profile: ", login_profile)
        if (login_profile.role !== "admin" && login_profile.role !== "advanced_admin" && login_profile.role !== "super_admin" ){
                alert("ขออภัย คุณไม่มีสิทธิ์แก้ไขข้อมูลนี้ได้")
                return
        }
      if (login_profile.role === "admin" && (data.role === "super_admin" || data.role === "advanced_admin")){
          alert("ขออภัย คุณไม่มีสิทธิ์แก้ไขข้อมูล super admin / advanced_admin ได้")
          return
      }
      if ((login_profile.role === "super_admin" && login_profile.name !== "Programmer Admin") && data.username === "pgadmin"){
          alert("ขออภัย คุณไม่มีสิทธิ์แก้ไขข้อมูล")
          return
      }
      if ((login_profile.role === "super_admin" && login_profile.name !== "Programmer Admin") && data.username === "pgadmin"){
          alert("ขออภัย คุณไม่มีสิทธิ์แก้ไขข้อมูล")
          return
      }
    setModalProps(
      {
        ...modalProps,
        action: "update",
        modalHeader: "แก้ไข CC Users",
        buttonSubmit: "แก้ไขข้อมูล",
        data: data,
        show: true,
        closeModal: { closeModal }
      }
    )
  }

  const closeModal = () => {
    setModalProps(
      {
        ...modalProps,
        show: false,
        action: "create"
      })
  }

  return (
    <>
      <ModalCreateCCUsers
        action={modalProps.action}
        show={modalProps.show}
        modalHeader={modalProps.modalHeader}
        buttonSubmit={modalProps.buttonSubmit}
        data={modalProps.data}
        openModal={null}
        closeModal={closeModal}
        callBack={callBack}
      />

      <Table responsive hover size="md">
        <thead className="text-center">
          <tr>
            <th>#</th>
            {/*<th>Agent</th>*/}
            <th>ชื่อ</th>
            <th>Role</th>
            <th>Username</th>
            {/* <th>Password</th> */}
            <th>สถานะ</th>
            <th>สร้างเมื่อ</th>
            <th>อัพเดทล่าสุด</th>
            <th>อัพเดทโดย</th>
            <th>ล๊อคอินล่าสุด</th>
            <th>หมายเหตุ</th>

          </tr>
        </thead>
        <tbody className="text-center">
          {
            ccUserSearchList?.map(({
              id,
              // partner,
              name,
              role,
              username,
              password,
              status,
              created_at,
              updated_at,
              updated_by,
              last_login,
              comment,

            }, index) =>

              <tr key={id} onClick={() => editCCUserData(ccUserSearchList[index], true)}>
                <td><h6>{index + 1}</h6></td>
                {/* <td><h6>{created_at}</h6></td> */}
                {/*<td><h6>{partner}</h6></td>*/}
                <td><h6>{name}</h6></td>
                <td><h6>{role}</h6></td>
                <td><h6>{username}</h6></td>
                {/* <td><h6>-</h6></td> */}
                {status === "active" ? (
                  <td><h5><Badge variant="success">Active</Badge>{' '}</h5></td>
                ) : (
                    <td><h5><Badge variant="danger">Suspend</Badge>{' '}</h5></td>
                  )}
                <td><h6>{moment(created_at).format('DD/MM/YYYY')}</h6></td>
                <td><h6>{moment(updated_at).format('DD/MM/YYYY')}</h6></td>
                <td><h6>{updated_by}</h6></td>
                <td><h6>{moment(last_login).format('DD/MM/YYYY')}</h6></td>
                <td><h6>{comment}</h6></td>
              </tr>

            )
          }
        </tbody>
      </Table>
      <Button className="btn-success" onClick={() => handleCreate()}>เพิ่ม CC Users</Button>
    </>
  )
}
