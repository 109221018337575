import React from 'react'
import moment from 'moment'
import { Badge, Table } from 'react-bootstrap'

export default function TableSearchProspectCustomer ( props ) {
  const { customerSearchList, editCustomerData } = props;

  const generateStatus = ( doneContact ) => {
    if ( doneContact ) {
      return ( <td><h6><Badge variant="success">ติดต่อเรียบร้อย</Badge>{' '}</h6></td> )
    } else {
      return ( <td><h6><Badge variant="primary">รอการติดต่อ</Badge>{' '}</h6></td> )
    }
  };

  return (
    <Table responsive hover size="md" className="text-center">
      <thead>
      <tr data-aos="fade-down">
        <th>วันที่สนใจสมัคร</th>
        <th>ชื่อ</th>
        <th>นามสกุล</th>
        <th>เบอร์โทรศัพท์</th>
        <th>สถานะการติดต่อ</th>
        <th>หมายเหตุ</th>
      </tr>
      </thead>
      <tbody>
      {
        customerSearchList?.map( ( {
                                     id,
                                     created_at,
                                     first_name,
                                     last_name,
                                     mobile_no,
                                     done_contact,
                                     remark,
                                   }, index ) =>

          <tr key={index + 1} className="" objectId={id} style={{ "cursor": "pointer" }}
              onClick={() => editCustomerData( customerSearchList[ index ], true )}>
            <td><h6>{moment( created_at ).format( 'DD/MM/YYYY HH:mm:ss' )}</h6></td>
            <td><h6>{first_name}</h6></td>
            <td><h6>{last_name}</h6></td>
            <td><h6>{mobile_no}</h6></td>
            {
              generateStatus( done_contact )
            }
            <td><h6>{remark}</h6></td>
          </tr>
        )
      }
      </tbody>
    </Table>
  )
}
