import React, {useState} from 'react'
import {useGlobalStore} from "../../store/globalContext";
import {Button, Modal, Spinner} from "react-bootstrap";
import api from "../../api/agents";

export default function ModalUnlockGclub(props) {
    const [{}, dispatch] = useGlobalStore();
    const {data, isOpenModal, onClose} = props;

    const [loadingButton, setLoadingButton] = useState(false);

    const handleSubmit = async () => {
        const request = {
            username: data.username
        };
        setLoadingButton(true);
        try {
            await api.UNLOCK_AGENT_GCLUB(request);
            dispatch({
                type: "TOAST2", value: {
                    show: true, type: 'success', title: `ปลดล็อค ยูสเซอร์ gclub`, message: `ทำรายการสำเร็จ`,
                }
            });
        } catch (e) {
            console.log("unlock user gclub error ", e);
            dispatch({
                type: "TOAST2", value: {
                    show: true, type: 'danger', title: `ปลดล็อค ยูสเซอร์ gclub`, message: `ทำรายการไม่สำเร็จ`,
                }
            });
        } finally {
            setTimeout(() => {
                setLoadingButton(false);
                onClose();
            }, 500);
        }
    }

    return (<>
        <Modal show={isOpenModal} onHide={onClose} size="md">
            <Modal.Header closeButton>
                <Modal.Title className={"font-bold"}>ปลดล็อก ยูสเซอร์ gclub: {data.username} </Modal.Title>
            </Modal.Header>
            <Modal.Footer>
                <Button variant="success" onClick={handleSubmit} disabled={loadingButton}>
                    {loadingButton ? (<>
                        <Spinner as="span" animation="border" size="sm" role="status"
                                 aria-hidden="true"/> กำลังดำเนินการ</>) : 'ยืนยันปลดล็อค'}
                </Button>
                <Button variant="secondary" onClick={onClose}>ปิด</Button>
            </Modal.Footer>
        </Modal>
    </>)
}