import React from 'react'
import {Modal} from 'react-bootstrap'
import TableSummary from "../invoice/TableSummary";

export default function ModalDetail(props) {
    const {data, isOpenModal, onClose} = props

    return (
        <>
            <Modal show={isOpenModal} onHide={onClose} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title className={"font-bold"}>รายละเอียดเพิ่มเติม</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <TableSummary data={data.invoice_detail}/>
                </Modal.Body>
            </Modal>
        </>
    )
}