import React from 'react';
import ReactDOM from 'react-dom';
import './tailwind.output.css'
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {GlobalProvider} from "./store/globalContext"
import {RecoilRoot} from "recoil";

ReactDOM.render(
  // <React.StrictMode>
        <GlobalProvider>
            <RecoilRoot>
                <App />
            </RecoilRoot>
        </GlobalProvider>
  // </React.StrictMode>
  ,
  document.getElementById('root')
);
serviceWorker.unregister();
