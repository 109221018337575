import React, {useEffect, useState} from 'react'
import {Button, Col, Form, Modal, Spinner} from 'react-bootstrap'
import {useGlobalStore} from "../../store/globalContext";
import {useForm} from "react-hook-form";
import api from "../../api/workorder";


export default function ConfirmEditStatusWorkOrder(props) {
    const [{}, dispatch] = useGlobalStore();
    const {data, isShow, onClose, callBack} = props;
    const {id, user_id, first_name, last_name, amount, credit_status, status} = data;
    const [isLoading, setLoading] = useState(false);

    const statusOptions = [
        {label: "สำเร็จ", value: "success"},
        {label: "ไม่สำเร็จ", value: "failed"},
        {label: "ยกเลิก", value: "deleted"},
        {label: "กรุณาจับคู่รายการ", value: "mapping"},
        {label: "รอดำเนินการ", value: "waiting"},
        {label: "กรุณาตรวจสอบรายการ", value: "pending"},
        {label: "กรุณาตรวจสอบ", value: "unknown"}
    ];

    const [validated, setValidated] = useState(false);
    const {register, handleSubmit, setValue, errors} = useForm({defaultValues: {}, criteriaMode: "all"});

    useEffect(() => {
        setValue("status", status);
        setValue("credit_status", credit_status);
    }, [data, isShow]);

    const onSubmit = async (data) => {
        setLoading(true);

        const request = {
            ...data, id,
        };

        try {
            await api.WORK_ORDER_UPDATE_STATUS(request);
            callBack();
            setLoading(false);

            dispatch({
                type: "TOAST2",
                value: {
                    show: true,
                    type: 'success',
                    title: `แก้ไขสถานะใบงาน`,
                    message: `แก้ไขสถานะใบงานสำเร็จ`,
                }
            });
        } catch (error) {
            console.log(error);
            setLoading(false);
            onClose();
            dispatch({
                type: "TOAST2",
                value: {
                    show: true,
                    type: 'danger',
                    title: `แก้ไขสถานะใบงาน`,
                    message: `แก้ไขสถานะใบงานไม่สำเร็จ กรุณาติดต่อ admin`,
                }
            });
        }
    };

    return (
        <div>
            <Modal show={isShow} onHide={onClose} size="lg" centered={true}>
                <Form noValidate validated={validated} onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Header closeButton>
                        <Modal.Title>แก้ไขสถานะใบงาน</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Row>
                            <Form.Group as={Col} md="3" lg="3">
                                <Form.Label>UserID</Form.Label>
                                <Form.Control type="text" value={user_id} readOnly={true}/>
                            </Form.Group>
                            <Form.Group as={Col} md="3" lg="3">
                                <Form.Label>ชื่อ</Form.Label>
                                <Form.Control type="text" value={first_name} readOnly={true}/>
                            </Form.Group>
                            <Form.Group as={Col} md="3" lg="3">
                                <Form.Label>นามสกุล</Form.Label>
                                <Form.Control type="text" value={last_name} readOnly={true}/>
                            </Form.Group>
                            <Form.Group as={Col} md="3" lg="3">
                                <Form.Label>จำนวนเงิน</Form.Label>
                                <Form.Control type="text" value={amount} readOnly={true}/>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} md="6">
                                <Form.Label>สถานะ</Form.Label>
                                <Form.Control as="select"
                                              name="status"
                                              ref={register({
                                                  required: true,
                                              })}
                                >
                                    {statusOptions.map((option, index) => (
                                        <option key={option.value} value={option.value}>{option.label}</option>))}
                                </Form.Control>
                            </Form.Group>
                            <Form.Group as={Col} md="6">
                                <Form.Label>สถานะ credit</Form.Label>
                                <Form.Control as="select"
                                              name="credit_status"
                                              ref={register({
                                                  required: true,
                                              })}
                                >
                                    {statusOptions.map((option, index) => (
                                        <option key={option.value} value={option.value}>{option.label}</option>))}
                                </Form.Control>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} md="12" lg="12" controlId="validation01">
                                <Form.Label>หมายเหตุ</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="comment"
                                    placeholder="หมายเหตุในการแก้ไขสถานะ เช่น กดปุ่มอัปเดตใบงานผิดปุ่ม เลยแก้ไขสถานะกลับ"
                                    isInvalid={!!errors.comment}
                                    ref={register({
                                        required: true,
                                    })}
                                />
                                <Form.Control.Feedback type="invalid" tooltip>
                                    กรุณาระบุ หมายเหตุ
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" type="submit" disabled={isLoading}>
                            {isLoading ?
                                (
                                    <>
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        /> กำลังดำเนินการ</>) : `ยืนยันการแก้ไขสถานะ`}
                        </Button>
                        <Button variant="secondary" onClick={onClose}>
                            ยกเลิก
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </div>
    )
}
