import React, {useEffect, useRef, useState} from 'react'
import {Alert, Badge, Button, Col, Form, Modal, Row, Spinner, Table} from 'react-bootstrap'
import {useForm} from 'react-hook-form'
import api from "../../api/customer";
import {AsyncTypeahead} from 'react-bootstrap-typeahead'
import moment from 'moment'
import api_workorder from "../../api/workorder";
import {useGlobalStore} from '../../store/globalContext'
import ConfirmDeleteWorkOrder from './ConfirmDeleteWorkOrder'
import {LogoWithAccountNo} from '../banks_logo/index'
import ConfirmRejectWorkOrder from "./ConfirmRejectWorkOrder";
import ConfirmRetryWorkOrder from "./ConfirmRetryWorkOrder";
import ConfirmManualSuccessWorkOrder from "./ConfirmManualSuccessWorkOrder";
import ConfirmEditWorkOrder from "./ConfirmEditWorkOrder";
import ConfirmUpdateWorkOrder from "./ConfirmUpdateWorkOrder";
import ConfirmManualCredit from "./ConfirmManualCredit";
import ConfirmButtonMapping from "./ConfirmButtonMapping";
import {number_format} from "../utils/Number";
import QRCode from "qrcode.react";
import ConfirmEditStatusWorkOrder from "./ConfirmEditStatusWorkOrder";
import api_bank from "../../api/managebank";

const statusOptions = [{label: "success", value: "success"}, {label: "waiting", value: "waiting"}, {
    label: "pending",
    value: "pending"
}, {label: "failed", value: "failed"}, {label: "deleted", value: "deleted"}, {
    label: "mapping",
    value: "mapping"
}, {label: "unknown", value: "unknown"},]

const searchConditions = [{label: "ยูสเซอร์(user id)", value: "user_id"}, {
    label: "ชื่อ - นามสกุล",
    value: "name"
}, {label: "เบอร์โทรศัพท์", value: "mobile_no"}, {
    label: "หมายเลขบัญชี",
    value: "bank_account_no"
}, // {label:"หมายเลขอ้างอิงธนาคาร", value:"mapping_bank"}
]

export default function WorkOrderModal(props) {

    const [{login_profile}, dispatch] = useGlobalStore();
    const {register, handleSubmit, errors, setValue, getValues, reset} = useForm({
        defaultValues: {first_name: "", user_id: "", status: ""}, criteriaMode: "all"
    });

    const {workOrderType, modalHeader, buttonSubmit, data, show, closeUpdateModal, selectFromBank} = props
    const [validated, setValidated] = useState(false);
    const [customerSearchList, setCustomerSeachList] = useState([])
    const [objectId, setObjectId] = useState("")
    const [workOrderStatus, setWorkOrderStatus] = useState("")
    const [creditStatus, setCreditStatus] = useState("")
    const [withdrawImage, setWithdrawImage] = useState("")
    const [userIds, setUserIds] = useState(null)
    const [dataMappingList, setDataMappingList] = useState([])
    const [selectMappingId, setSelectMappingId] = useState("")
    const [isLoading, setIsLoading] = useState(false);
    const [buttonIsLoading, setButtonIsLoading] = useState(false)
    const [fromBankSelect, setFromBankSelect] = useState([])
    const [editComment, setEditComment] = useState(false)
    const [comment, setComment] = useState("")
    const [channelOrder, setChannelOrder] = useState("")


    const [searchConditionSelected, setSearchConditionSelected] = useState("")
    const [searchPlaceholder, setSearchPlaceHolder] = useState("ยูสเซอร์ไอดี/ชื่อ/นามสกุล/เบอร์โทรศัพท์/เลขที่บัญชี")

    const role = login_profile.role

    const typeAheadRef = useRef()

    const onSubmit = async registerData => {
        //console.log( registerData );
        // console.log(workOrderType);

        const {user_id, first_name, last_name, updated_by} = registerData

        const work_order_deposit_update_request = {
            work_order_type: workOrderType,
            id: objectId,
            user_id: user_id,
            first_name: first_name,
            last_name: last_name,
            updated_by: updated_by
        }
        //console.log("update work_order_deposit_request : ", work_order_deposit_update_request)
        const response = await api_workorder.WORK_ORDER_UPDATE(work_order_deposit_update_request)

        if (response.status !== 200) {
            //console.log(`create work_order_deposit_failed`, response.message);
            return
        }

        dispatch({
            type: "TOAST2", value: {
                show: true, type: 'success', title: `อัพเดทใบงาน`, message: `อัพเดทใบงาน UserID ${user_id} สำเร็จ`,
            }
        });
        closeUpdateModal();
    }

    useEffect(() => {
        // console.log("in modal :", props)
        if (data === undefined) {
            return
        }
        const {
            id,
            customer_id,
            user_id,
            created_at,
            first_name,
            last_name,
            from_bank_name,
            from_bank_account_no,
            to_bank_name,
            to_bank_account_no,
            amount,
            status,
            credit_status,
            created_by,
            updated_at,
            comment,
            user_ids,
            to_bank_type,
            channel,
        } = data
        // console.log("data: ", data)
        setObjectId(id)
        setValue("user_id", user_id)
        setValue("created_at", moment(created_at).format('DD/MM/YYYY HH:mm'))
        setValue("first_name", first_name)
        setValue("last_name", last_name)
        setValue("from_bank", `${from_bank_account_no}`)
        if (selectFromBank) {
            setFromBankSelect(selectFromBank.filter(d => d.enable === true))
        } else {
            setFromBankSelect([{
                "bank_name": `${from_bank_name}/${from_bank_account_no}`, "bank_account_no": `${from_bank_account_no}`
            }])
        }
        setValue("to_bank", `${to_bank_name}/${to_bank_account_no}`)
        setValue("amount", amount)
        setValue("status", status)
        setValue("credit_status", credit_status)
        setWorkOrderStatus(status)
        setCreditStatus(credit_status)
        setValue("created_by", created_by)
        setValue("updated_at", moment(updated_at).format('DD/MM/YYYY HH:mm'))
        setValue("comment", comment)
        setUserIds(user_ids)
        setCreditStatus(credit_status)
        setComment(comment)
        setChannelOrder(channel)
        if (to_bank_type === "holding") {
            setEditComment(true)
        }
    }, [data, show])

    const handleSearchDataFromUserID = async (userIdForSearch) => {
        if (searchConditionSelected === "") {
            return
        }
        // setIsLoading(true);

        const response = await api.SEARCH_CUSTOMER({keyword: userIdForSearch})
        if (response.status !== 200) {
            return
        }

        const {data} = response.data
        if (data === null) {
            setCustomerSeachList([]);
            // setIsLoading(false);
            return
        }
        //console.log("search customer by user id response data: ", data);

        let dataArr = []
        data.map(e => {
            // console.log("element data search: ", e)
            if (e.agents.length === 2) {
                const agentsArr = e.agents
                agentsArr.map(ex => {
                    // console.log( "agents elements : ", ex )
                    let dTemp = {...e, user_id: ex.username}
                    // dTemp.user_id = ex.username
                    dataArr.push(dTemp)
                })

            } else {
                dataArr.push(e)
            }
        })
        //console.log("element dataArr: ", dataArr)
        setCustomerSeachList(dataArr);
        setIsLoading(false);
    }

    const handleSetDataFromUserID = (value) => {
        if (value.length === 0) {
            typeAheadRef.current.clear()
            return
        }
        //console.log("handleSetDataFromUserID : ", ...value)
        const result = value[0]
        //const result = value.filter(value => value.user_id === value).pop()
        const {user_id, first_name, last_name, mobile_no, bank_accounts} = result
        //console.log("handleSetDataFromUserID : ", user_id, first_name, last_name, mobile_no, bank_accounts)
        setValue("user_id", user_id)
        setValue("first_name", first_name)
        setValue("last_name", last_name)
        //console.log("bank_accounts : ", bank_accounts)
        let customerBank = []
        bank_accounts.map(({bank_short_name, bank_account_name, bank_account_no}, index) => {
            //console.log("in loop", bank_short_name, bank_account_name, bank_account_no)
            customerBank.push({
                bank_short_name: bank_short_name, bank_account_name: bank_account_name, bank_account_no: bank_account_no
            })
        })
        typeAheadRef.current.clear()
    }

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteModalData, setDeleteModalData] = useState({});
    const [alertModal, setAlertModal] = useState(false)
    const [alertText, setAlertText] = useState("")

    const [showRejectModal, setShowRejectModal] = useState(false);
    const [rejectModalData, setRejectModalData] = useState({});

    const [topupMultiLoading, setTopupMultiLoading] = useState(false)
    const [customerHistoryTable, setCustomerHistoryTable] = useState([])

    //TODO
    const [showRetryModal, setShowRetryModal] = useState(false);
    const [retryModalData, setRetryModalData] = useState({});

    const [showManualSuccessModal, setShowManualSuccessModal] = useState(false);
    const [manualSuccessModalData, setManualSuccessModalData] = useState({});

    const [showEditModal, setShowEditModal] = useState(false);
    const [editModalData, setEditModalData] = useState({});

    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [updateModalData, setUpdateModalData] = useState({});

    const [showManualCreditModal, setShowManualCreditModal] = useState(false);
    const [manualCreditModalData, setManualCreditModalData] = useState({});

    const [showMappingModal, setShowMappingModal] = useState(false);
    const [mappingModalData, setMappingModalData] = useState({});

    //TODO
    const handleRetry = (buttonName, variant) => {
        const newData = data;
        newData["button_name"] = buttonName;
        newData["variant"] = variant;
        newData["user_id"] = getValues("user_id")
        newData["first_name"] = getValues("first_name")
        newData["last_name"] = getValues("last_name")
        setRetryModalData(newData);
        setShowRetryModal(true);
    }

    const handleRetryModalCallBack = () => {
        setShowRetryModal(false);
        closeUpdateModal();
    }

    const handleManualSuccess = async (buttonName, variant) => {
        const newData = data;
        newData["button_name"] = buttonName;
        newData["variant"] = variant;
        newData["user_id"] = getValues("user_id")
        newData["first_name"] = getValues("first_name")
        newData["last_name"] = getValues("last_name")
        setManualSuccessModalData(newData);
        setShowManualSuccessModal(true);
    }

    const handleManualSuccessCallBack = () => {
        setShowManualSuccessModal(false);
        closeUpdateModal();
    }

    const handleEdit = (buttonName, variant) => {
        const newData = data;
        newData["button_name"] = buttonName;
        newData["variant"] = variant;
        newData["user_id"] = getValues("user_id")
        newData["first_name"] = getValues("first_name")
        newData["last_name"] = getValues("last_name")
        // newData[ "status" ] = getValues( "status" )
        // newData[ "credit_status" ] = getValues( "credit_status" )
        setEditModalData(newData);
        setShowEditModal(true);
    }

    const handleEditCallBack = () => {
        setShowEditModal(false);
        closeUpdateModal();
    }

    const handleUpdateOrder = async (buttonName, variant) => {
        const newData = data;
        newData["button_name"] = buttonName;
        newData["variant"] = variant;
        newData["user_id"] = getValues("user_id")
        newData["first_name"] = getValues("first_name")
        newData["last_name"] = getValues("last_name")
        // console.log("selectFromBank: ", fromBankSelect)
        newData["bank"] = fromBankSelect.find(e => e.bank_account_no === getValues('from_bank'));
        setUpdateModalData(newData);
        setShowUpdateModal(true);
    }

    const handleUpdateOrderCallBack = () => {
        setShowUpdateModal(false);
        closeUpdateModal();
    }

    const handleManualCredit = async (buttonName, variant) => {
        const newData = data;
        newData["button_name"] = buttonName;
        newData["variant"] = variant;
        newData["user_id"] = getValues("user_id")
        newData["first_name"] = getValues("first_name")
        newData["last_name"] = getValues("last_name")
        setManualCreditModalData(newData);
        setShowManualCreditModal(true);
    }

    const handleManualCreditCallBack = () => {
        setShowManualCreditModal(false);
        closeUpdateModal();
    }

    const handleButtonMapping = async (buttonName, variant) => {
        const newData = data;
        newData["button_name"] = buttonName;
        newData["variant"] = variant;
        newData["user_id"] = getValues("user_id")
        newData["first_name"] = getValues("first_name")
        newData["last_name"] = getValues("last_name")
        // console.log("get value mapping id :", getValues("mapping_id"))
        // const mappingIdArr = selectMappingId
        if (selectMappingId === "") {
            alert("กรุณาเลือกรายการที่ต้องการจับคู่")
            return
        }
        // console.log("mappingIdArr[0] :", mappingIdArr)
        newData["mapping_id"] = selectMappingId
        setMappingModalData(newData);
        setShowMappingModal(true);
    }

    const handleButtonMappingCallBack = () => {
        setShowMappingModal(false);
        closeUpdateModal();
    }
    //

    const handleDelete = () => {
        //console.log("delete data : ", data);
        // setShowDeleteModalText(``);
        const newData = data;
        newData["user_id"] = getValues("user_id")
        newData["first_name"] = getValues("first_name")
        newData["last_name"] = getValues("last_name")
        setDeleteModalData(newData);
        setShowDeleteModal(true);
    }

    const handleReject = () => {
        //console.log("delete data : ", data);
        // setShowDeleteModalText(``);
        const newData = data;
        newData["user_id"] = getValues("user_id")
        newData["first_name"] = getValues("first_name")
        newData["last_name"] = getValues("last_name")
        setRejectModalData(newData);
        setShowRejectModal(true);
    }

    const handleDeleteModalCallBack = () => {
        setShowDeleteModal(false);
        closeUpdateModal();
    }

    const handleRejectModalCallBack = () => {
        setShowRejectModal(false);
        closeUpdateModal();
    }

    const getCustomerHistory = async () => {
        //setCustomerHistoryTable([])
        const userID = getValues("user_id")
        //console.log("user_id : ", userID)
        if (userID === "" || userID === undefined) {
            return
        }

        const request = {
            user_id: userID,
        }

        await api_workorder.CUSTOMER_HISTORY(request).then(response => {
            //console.log('customer history data: ', response.data.data)
            setCustomerHistoryTable(response.data.data)
        }).catch(error => {
            //console.log("error customer history:", error)
        })
    }

    const handleUpdateOrderNoRetry = async (id, comment) => {

        const requestUpdate = {
            id: objectId, comment: comment,
        }

        const response = await api_workorder.WORK_ORDER_UPDATE_NO_RETRY(requestUpdate)
        //console.log(response.data)
        closeUpdateModal();
    }

    const DepositButtonModel = (props) => {
        //console.log("DepositButtonModel props: ", props)
        const {status, creditStatus} = props
        if (status === "success" && creditStatus === "success") {
            //ปิด
            return (<>
                    <Button variant="danger" onClick={handleDelete}>ลบใบงาน</Button>
                    <Button variant="secondary" onClick={closeUpdateModal}>ปิด</Button>
                </>)
        } else if (status === "success" && creditStatus === "waiting") {
            // แก้ไข | ปิด
            return (<>
                    <Button variant="success" type="button"
                            onClick={() => handleEdit(buttonSubmit, "success")}>{buttonSubmit}</Button>
                    <Button variant="secondary" onClick={closeUpdateModal}>ปิด</Button>
                </>)
        } else if (status === "success" && creditStatus === "failed") {
            // ทำซ้ำ | เติมมือแล้ว | ลบ | ปิด
            if (props.userId === "") {
                return (<>
                        <Button variant="success" type="button"
                                onClick={() => handleEdit(buttonSubmit, "success")}>{buttonSubmit}</Button>
                        <Button variant="danger" onClick={handleDelete}>ลบใบงาน</Button>
                        <Button variant="secondary" onClick={closeUpdateModal}>ปิด</Button>
                    </>)
            }
            return (<>
                    <Button variant="success" type="button"
                            onClick={() => handleEdit(buttonSubmit, "success")}>{buttonSubmit}</Button>
                    <Button variant="primary" type="button"
                            onClick={() => handleRetry("ทำซ้ำ", "primary")}>ทำซ้ำ</Button>
                    <Button variant="warning"
                            onClick={() => handleManualSuccess("เติมมือแล้ว", "warning")}>เติมมือแล้ว</Button>
                    <Button variant="danger" onClick={handleDelete}>ลบใบงาน</Button>
                    <Button variant="secondary" onClick={closeUpdateModal}>ปิด</Button>
                </>)
        } else if (status === "success" && creditStatus === "mapping") {
            // ทำซ้ำ | เติมมือแล้ว | ลบ | ปิด
            return (<>
                    {/*<Button variant="success" type="submit" >{buttonSubmit}</Button>*/}
                    {/*<Button variant="primary" type="button" onClick={handleRetry}>ทำซ้ำ</Button>*/}
                    {/*<Button variant="warning" onClick={handleManualSuccess}>เติมมือแล้ว</Button>*/}
                    {dataMappingList.length > 0 ? <Button variant="success"
                                                          onClick={() => handleButtonMapping("จับคู่ใบงาน", "success")}>ยืนยันจับคู่ใบงาน</Button> : <></>}
                    <Button variant="primary" type="button"
                            onClick={() => handleRetry("ทำซ้ำ", "primary")}>ทำซ้ำ</Button>
                    <Button variant="danger" onClick={handleDelete}>ลบใบงาน</Button>
                    <Button variant="secondary" onClick={closeUpdateModal}>ปิด</Button>
                </>)
        } else {
            return (<>
                    <Button variant="secondary" onClick={closeUpdateModal}>ปิด</Button>
                </>)
        }
    }


    const WithdrawButtonModel = (props) => {
        const {status, creditStatus} = props
        if (status === "success" && creditStatus === "success") {
            //ปิด
            return (<>
                    <Button variant="secondary" onClick={closeUpdateModal}>ปิด</Button>
                </>)
        } else if (status === "failed" && creditStatus === "failed") {
            // ทำซ้ำ | ลบ | ปิด
            return (<>
                    <Button variant="primary" type="button"
                            onClick={() => handleRetry("ทำรายการซ้ำอีกครั้ง", "primary")}>ทำรายการซ้ำอีกครั้ง</Button>
                    <Button variant="danger" onClick={handleDelete}>ลบใบงาน</Button>
                    <Button variant="secondary" onClick={closeUpdateModal}>ปิด</Button>
                </>)
        } else if (status === "failed" && creditStatus === "success") {
            // แก้ไข | ทำรายการซ้ำอีกครั้ง | ถอนมือแล้ว | ลบ | ปิด
            return (<>
                    <Button variant="dark" type="button"
                            onClick={() => handleUpdateOrder("แก้ไขข้อมูลใบงาน", "dark")}>แก้ไขข้อมูลใบงาน</Button>
                    <Button variant="success" type="button"
                            onClick={() => handleRetry("ทำรายการซ้ำอีกครั้ง", "success")}>ทำรายการซ้ำอีกครั้ง</Button>
                    <Button variant="warning"
                            onClick={() => handleManualSuccess("โอนมือแล้ว", "warning")}>โอนมือแล้ว</Button>
                    <Button variant="danger" onClick={handleDelete}>ลบใบงาน</Button>
                    <Button variant="secondary" onClick={closeUpdateModal}>ปิด</Button>
                </>)
        } else if (status === "pending" && creditStatus === "success") {
            // แก้ไข | ทำซ้ำ | ถอนมือแล้ว | ลบ | ปิด
            return (<>
                    <Button variant="dark" type="button"
                            onClick={() => handleUpdateOrder("แก้ไขข้อมูลใบงาน", "dark")}>แก้ไขข้อมูลใบงาน</Button>
                    <Button variant="success" type="button" onClick={() => handleRetry("การทำรายการ", "success")}><i
                        className={`nav-icon fas fa-check`}/>{' '}ยืนยันการทำรายการ</Button>
                    <Button variant="warning" onClick={() => handleManualSuccess("โอนมือแล้ว", "warning")}><i
                        className={`nav-icon fas fa-cog`}/>{' '}โอนมือแล้ว</Button>
                    <Button variant="danger" onClick={handleDelete}><i
                        className={`nav-icon fas fa-ban`}/>{' '}ลบใบงาน</Button>
                    <Button variant="secondary" onClick={closeUpdateModal}>ปิด</Button>
                </>)
        } else if (status === "pending" && creditStatus === "unknown") {
            // ยืนยันเครดิตแล้ว | ทำรายการซ้ำอีกครั้ง | ยกเลิก | ปิด
            return (<>
                    <Button variant="warning"
                            onClick={() => handleManualCredit("เครดิตแล้ว", "warning")}>ยืนยันเครดิตแล้ว</Button>
                    <Button variant="primary" type="button"
                            onClick={() => handleRetry("ทำรายการซ้ำอีกครั้ง", "primary")}>ทำรายการซ้ำอีกครั้ง</Button>
                    <Button variant="danger" onClick={handleReject}>ยกเลิก</Button>
                    <Button variant="secondary" onClick={closeUpdateModal}>ปิด</Button>
                </>)
        } else if (status === "pending" && creditStatus === "failed") {
            return (<>
                    <Button variant="primary" type="button"
                            onClick={() => handleRetry("ทำรายการซ้ำอีกครั้ง", "primary")}>ทำรายการซ้ำอีกครั้ง</Button>
                    <Button variant="warning"
                            onClick={() => handleManualSuccess("โอนมือแล้ว", "warning")}>โอนมือแล้ว</Button>
                    <Button variant="danger" onClick={handleDelete}>ลบใบงาน</Button>
                    <Button variant="secondary" onClick={closeUpdateModal}>ปิด</Button>
                </>)
        } else if (status === "deleted" && creditStatus === "failed") {
            return (<>
                    <Button variant="primary" type="button"
                            onClick={() => handleRetry("ทำรายการคืนเครดิตอีกครั้ง (ทำซ้ำ)", "primary")}>ทำรายการคืนเครดิตอีกครั้ง
                        (ทำซ้ำ)</Button>
                    <Button variant="warning"
                            onClick={() => handleManualSuccess("ปรับสถานะเครดิตเป็นสำเร็จ (เครดิตเข้าลูกค้าแล้ว)", "warning")}>ปรับสถานะเครดิตเป็นสำเร็จ
                        (เครดิตเข้าลูกค้าแล้ว)</Button>
                    <Button variant="secondary" onClick={closeUpdateModal}>ปิด</Button>
                </>)
        } else {
            return (<>
                    <Button variant="secondary" onClick={closeUpdateModal}>ปิด</Button>
                </>)
        }
    }

    const SweepButtonModel = (props) => {
        const {status, id, comment} = props
        if (status === "success") {
            //ปิด
            if (channelOrder === "manual") {
                return (<>
                        <Button variant="primary" type="button"
                                onClick={() => handleUpdateOrderNoRetry(id, comment)}>แก้ไขข้อมูล</Button>
                        <Button variant="danger" onClick={handleDelete}>ลบใบงาน</Button>
                        <Button variant="secondary" onClick={closeUpdateModal}>ปิด</Button>
                    </>)
            } else {
                return (<>
                        <Button variant="primary" type="button"
                                onClick={() => handleUpdateOrderNoRetry(id, comment)}>แก้ไขข้อมูล</Button>
                        <Button variant="secondary" onClick={closeUpdateModal}>ปิด</Button>
                    </>)
            }

        } else if (status === "failed") {
            // ทำซ้ำ | ลบ | ปิด
            return (<>
                    <Button variant="primary" type="button"
                            onClick={() => handleRetry("ทำซ้ำ", "primary")}>ทำซ้ำ</Button>
                    <Button variant="warning"
                            onClick={() => handleManualSuccess("เติมมือแล้ว", "warning")}>เติมมือแล้ว</Button>
                    <Button variant="danger" onClick={handleDelete}>ลบใบงาน</Button>
                    <Button variant="secondary" onClick={closeUpdateModal}>ปิด</Button>
                </>)
        } else {
            return (<>
                    <Button variant="secondary" onClick={closeUpdateModal}>ปิด</Button>
                </>)
        }
    }

    const BonusButtonModel = (props) => {
        const {creditStatus} = props
        if (creditStatus === "success") {
            //ปิด
            return (<>
                    <Button variant="danger" onClick={handleDelete}>ลบใบงาน</Button>
                    <Button variant="secondary" onClick={closeUpdateModal}>ปิด</Button>
                </>)
        } else if (creditStatus === "failed") {
            // ทำซ้ำ | ลบ | ปิด
            return (<>
                    <Button variant="primary" type="button"
                            onClick={() => handleRetry("ทำซ้ำ", "primary")}>ทำซ้ำ</Button>
                    <Button variant="warning"
                            onClick={() => handleManualSuccess("เติมมือแล้ว", "warning")}>เติมมือแล้ว</Button>
                    <Button variant="danger" onClick={handleDelete}>ลบใบงาน</Button>
                    <Button variant="secondary" onClick={closeUpdateModal}>ปิด</Button>
                </>)
        } else {
            return (<>
                    <Button variant="danger" onClick={handleDelete}>ลบใบงาน</Button>
                    <Button variant="secondary" onClick={closeUpdateModal}>ปิด</Button>
                </>)
        }
    }

    const ButtonModal = (props) => {
        const {status, creditStatus, workOrderType, userId} = props
        //console.log("button Modal workOrderType: ", workOrderType)
        //console.log("button Modal props: ", props)
        switch (workOrderType) {
            case "deposit":
                return (<DepositButtonModel status={status} creditStatus={creditStatus} userId={userId}/>)
            case "withdraw":
                return (<WithdrawButtonModel status={status} creditStatus={creditStatus}/>)
            case "sweep":
                return (<SweepButtonModel status={status} id={objectId} comment={comment}/>)
            case "bonus":
                return (<BonusButtonModel creditStatus={creditStatus}/>)
            default:
                return (<>
                        <Button variant="secondary" onClick={closeUpdateModal}>ปิด</Button>
                    </>)
        }
    }


    const depositApprove = async (props) => {
        setTopupMultiLoading(true)
        const {id, agent_name, user_id} = props
        //console.log("ButtonPending :", id, agent_name)
        try {
            const keyword = {id: id, agent_name: agent_name}
            //console.log("keyword: ", keyword)
            const response = await api_workorder.APPROVE_DEPOSIT_WORKORDER(keyword)
            //console.log("response data :", response.data)
            dispatch({
                type: "TOAST2", value: {
                    show: true, type: 'success', title: `เติม Credit`, message: `เติม Credit ${user_id} สำเร็จ`,
                }
            });
        } catch (error) {
            //console.log("error call deposit approve :", error)
            dispatch({
                type: "TOAST2", value: {
                    show: true,
                    type: 'danger',
                    title: `เติม Credit`,
                    message: `เติม Credit ${user_id} ไม่สำเร็จ กรุณาตรวจสอบ`,
                }
            });
        } finally {
            setTimeout(() => {
                setTopupMultiLoading(false);
                closeUpdateModal();
            }, 1000);
        }
    }

    const ButtonPending = (props) => {
        const {status, creditStatus, workOrderType, id} = props
        //console.log("ButtonPending :", status, creditStatus, workOrderType)
        if (workOrderType !== "deposit" && creditStatus !== "pending") {
            return (<></>)
        }

        if (topupMultiLoading) {
            return (<Button variant="primary" type="button" disabled={true}>
                    <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />กำลังดำเนินการ
                </Button>)
        }

        //console.log("user_ids :", userIds)
        return (<>
                {userIds?.map(e => {
                    if (e.agent_name === "gclub") {
                        return (<>
                                <Button variant="info" type="button" className={"mr-2"}
                                        onClick={() => depositApprove({
                                            id: id, agent_name: e.agent_name, user_id: e.user_id
                                        })}>เติม
                                    Credit {e.user_id}</Button>
                                {' '}
                            </>)
                    } else {
                        return (<>
                                <Button variant="warning" type="button" className={"mr-2"}
                                        onClick={() => depositApprove({
                                            id: id, agent_name: e.agent_name, user_id: e.user_id
                                        })}>เติม
                                    Credit {e.user_id}</Button>
                            </>)
                    }
                })}
            </>)
    }

    const handleCheckboxMapping = (value) => {
        setSelectMappingId(value)
    }

    const getWorkOrderMapping = async (customer_id) => {
        setDataMappingList([])
        const keyword = {
            work_order_type: "deposit", channel: "manual", mapping_status: "failed", customer_id: customer_id, limit: 15
        }
        const response = await api_workorder.WORK_ORDER_SEARCH_TYPE(keyword)
        if (response.status !== 200) {
            //console.log(`create work_order_deposit_failed`, response.message);
            return
        }

        if (response.data.data === null || response.data.data === undefined) {
            return
        }

        const depositManualList = response.data.data
        //console.log("depositManualList: ", depositManualList)
        let depositManualListTable = []
        depositManualList.map(e => {
            //console.log("EEEEEEEEEEEEEEe : ", e)
            if (e.mapping_status !== "success") {
                const data = {
                    id: e.id,
                    mapping_id: e.id,
                    from_bank_short_name: e.from_bank_short_name,
                    from_bank_name: e.from_bank_name,
                    from_bank_account_no: e.from_bank_account_no,
                    to_bank_short_name: e.to_bank_short_name,
                    to_bank_name: e.to_bank_name,
                    to_bank_account_no: e.to_bank_account_no,
                    work_order_type: "deposit",
                    work_order_sub_type: "",
                    amount: e.amount,
                    comment: e.comment
                }
                depositManualListTable.push(data)
            }
        })
        setDataMappingList(depositManualListTable)
    }

    useEffect(() => {
        if (!data) {
            return
        }
        //console.log("DATA CUSTOMER ID: ", data.customer_id)
        void getWorkOrderMapping(data.customer_id)
        void getCustomerHistory()
    }, [data])

    const forceSuccessMapping = async ({id, mapping_id}) => {
        const request = {
            id: id, mapping_id: mapping_id
        }
        const response = await api_workorder.FORCE_MAPPING_SUCCESS(request)

        if (response.status !== 200) {
            //console.log(`force work order status to success failed `, response.message);
            setAlertText(`ขออภัยระบบไม่สามารถดำเนินการได้ในขณะนี้ กรุณาติดต่อ admin [${response.message}]`)
            setAlertModal(true)
            return
        }

        void getWorkOrderMapping(data.customer_id)
        setAlertModal(false)
    }

    const getThaiWorkOrderType = (type) => {
        switch (type) {
            case "deposit" :
                return ("ฝาก")
            case "withdraw" :
                return ("ถอน")
            case "holding" :
                return ("พัก")
            case "bonus" :
                return ("โบนัส")
            default :
                return ("-")
        }
    }

    const renderBadge = (status) => {

        switch (status) {
            case "success":
                return (<Badge variant="success">{"สำเร็จ"}</Badge>)
            case "failed":
                return (<Badge variant="danger">{"ไม่สำเร็จ"}</Badge>)
            case "deleted":
                return (<Badge variant="warning">{"ยกเลิก"}</Badge>)
            case "mapping":
                return (<Badge variant="primary">{"กรุณาจับคู่รายการ"}</Badge>)
            case "pending":
                return (<Badge variant="info">กรุณาตรวจสอบและยืนยันรายการ</Badge>)
            case "unknown":
                return (<Badge variant="dark">กรุณาตรวจสอบรายการ</Badge>)
            default:
                return (<Spinner animation="grow" variant="info"/>)
        }
    }


    useEffect(() => {
        if (!show) {
            setCustomerSeachList([])
        }
    }, [show])

    const handleUpdateStatusPG = async () => {
        const bankInfo = fromBankSelect.find(f => f.bank_account_no === getValues("from_bank"))

        const req = {
            id: objectId,
            status: getValues("status"),
            credit_status: getValues("credit_status"),
            from_bank_account_no: getValues("from_bank"),
            from_bank_name: bankInfo.bank_name,
            from_bank_short_name: bankInfo.bank_short_name,
            from_bank_code: bankInfo.bank_code,
        }
        // console.log("pgadmin update request: ", req)

        // console.log("request pg update: ", req )
        try {
            const response = await api_workorder.WORK_ORDER_UPDATE_PGADMIN(req)
            dispatch({
                type: "TOAST2", value: {
                    show: true,
                    type: 'success',
                    title: `อัพเดท status ใบงาน`,
                    message: `อัพเดท status ใบงาน Object ID ${objectId} สำเร็จ`,
                }
            });

        } catch {
            dispatch({
                type: "TOAST2", value: {
                    show: true,
                    type: 'danger',
                    title: `อัพเดท status ใบงาน`,
                    message: `อัพเดท status ใบงาน Object ID ${objectId} ไม่สำเร็จ`,
                }
            });
        }

        closeUpdateModal()
    }

    const handleSearchCondition = (value) => {
        // console.log("handleSearchCondition value : ", value)
        if (value === "") {
            setValue("field", "")
            setValue("user_id", "")
            setValue("first_name", "")
            setValue("last_name", "")
            setSearchConditionSelected("")
            setSearchPlaceHolder("ยูสเซอร์ไอดี/ชื่อ/นามสกุล/เบอร์โทรศัพท์/เลขที่บัญชี/หมายเลขอ้างอิงธนาคาร")
        } else {
            setSearchConditionSelected(value)
            const labelFilter = searchConditions.find(e => value === e.value)

            // console.log("handleSearchCondition labels : ", labelFilter.label)
            setSearchPlaceHolder(`กรุณาค้นหาข้อมูลด้วย ${labelFilter.label}`)
        }
        typeAheadRef.current.clear()
    }

    const [showEditStatusModal, setShowEditStatusModal] = useState(false);
    const [editStatusData, setEditStatusData] = useState({});

    const handleEditStatusCallBack = () => {
        setShowEditStatusModal(false);
        closeUpdateModal();
    }
    const handleEditStatus = (data) => {
        setEditStatusData(data);
        setShowEditStatusModal(true);
    };

    const getTransferBank = async ()=>{
        const response = await api_bank.BANK_SEARCH_TYPE({ keyword: `${workOrderType}+vvip`, enable: true })
        if (response.status !== 200) {
            console.log(`search bank_list by user id failed`, response.message);
            return
        }

        const { data } = response.data
        //console.log("search withdraw bank response data: ", data);
        setFromBankSelect(data.filter(d => d.enable === true));
    }

    return (<>
            <div>
                <ConfirmUpdateWorkOrder isShow={showUpdateModal} data={updateModalData}
                                        onClose={() => setShowUpdateModal(false)}
                                        callBack={handleUpdateOrderCallBack}/>
                <ConfirmEditWorkOrder isShow={showEditModal} data={editModalData}
                                      onClose={() => setShowEditModal(false)} callBack={handleEditCallBack}/>
                <ConfirmRetryWorkOrder isShow={showRetryModal} data={retryModalData}
                                       onClose={() => setShowRetryModal(false)} callBack={handleRetryModalCallBack}/>
                <ConfirmManualSuccessWorkOrder isShow={showManualSuccessModal} data={manualSuccessModalData}
                                               onClose={() => setShowManualSuccessModal(false)}
                                               callBack={handleManualSuccessCallBack}/>
                <ConfirmManualCredit isShow={showManualCreditModal} data={manualCreditModalData}
                                     onClose={() => setShowManualCreditModal(false)}
                                     callBack={handleManualCreditCallBack}/>
                <ConfirmButtonMapping isShow={showMappingModal} data={mappingModalData}
                                      onClose={() => setShowMappingModal(false)}
                                      callBack={handleButtonMappingCallBack}/>
                <ConfirmDeleteWorkOrder isShow={showDeleteModal} data={deleteModalData}
                                        onClose={() => setShowDeleteModal(false)}
                                        callBack={handleDeleteModalCallBack}/>
                <ConfirmRejectWorkOrder isShow={showRejectModal} data={rejectModalData}
                                        onClose={() => setShowRejectModal(false)}
                                        callBack={handleRejectModalCallBack}/>
                <ConfirmEditStatusWorkOrder isShow={showEditStatusModal} data={editStatusData}
                                 onClose={() => setShowEditStatusModal(false)} callBack={handleEditStatusCallBack}/>
            </div>
            <div>
                <Modal show={show} size="xl">
                    <Form noValidate validated={validated} onSubmit={handleSubmit(onSubmit)}>
                        <Modal.Header>
                            <Modal.Title>{modalHeader}</Modal.Title>
                            <Modal.Title>
                                {(userIds?.length > 1 && creditStatus === "pending") ? <ButtonPending
                                    status={workOrderStatus}
                                    creditStatus={creditStatus}
                                    workOrderType={workOrderType}
                                    id={objectId}
                                /> : <></>}
                            </Modal.Title>
                            <Modal.Title>
                                <Button variant="danger"
                                        hidden={!(login_profile.role === 'super_admin' && login_profile.name === 'Programmer Admin')}
                                        onClick={handleUpdateStatusPG}>
                                    แก้ไข status PG Admin
                                </Button>
                                {(login_profile.role === "super_admin" || login_profile.role === "advanced_admin") && (
                                    <Button variant={"info"} className={"ml-2"}
                                            onClick={() => handleEditStatus(data)}>แก้ไขสถานะ</Button>)}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form.Row>
                                <Form.Group as={Col} md="12" lg="12" controlId="validationCustom01">
                                    <Form.Label>ค้นหา User ลูกค้า</Form.Label>
                                    <Row>
                                        <Col md="3" lg="3">
                                            <Form.Control
                                                ref={register}
                                                as={"select"}
                                                name={"field"}
                                                custom
                                                onChange={(e) => handleSearchCondition(e.currentTarget.value)}
                                                selected={searchConditionSelected}
                                            >
                                                <option value="">เลือกเงื่อนไขการค้นหา</option>
                                                {searchConditions?.map(({label, value}, index) => <option
                                                    key={value}
                                                    value={value}
                                                >
                                                    {label}
                                                </option>)}
                                            </Form.Control>
                                        </Col>
                                        <Col md="6" lg="6">
                                            <AsyncTypeahead
                                                id="async-example"
                                                className='w-full'
                                                isLoading={isLoading}
                                                labelKey={(option) => `${option.user_id} ${option.first_name} ${option.last_name} ${option.mobile_no} ${JSON.stringify(option.bank_accounts.map((e) => {
                                                    return {เลขที่บัญชี: e.bank_account_no, value: e.Value}
                                                }))}`}
                                                minLength={5}
                                                delay={1500}
                                                onSearch={handleSearchDataFromUserID}
                                                options={customerSearchList}
                                                placeholder="user_id/ชื่อ/นามสกุล/เบอร์โทรศัพท์/เลขที่บัญชี"
                                                searchText="กำลังค้นหาลูกค้า"
                                                onChange={handleSetDataFromUserID}
                                                disabled={searchConditionSelected === ""}
                                                ref={typeAheadRef}
                                                renderMenuItemChildren={({
                                                                             user_id, first_name, last_name, mobile_no
                                                                         }, props) => (<>
                                                        <span>{user_id} - {first_name} - {last_name} - {mobile_no}</span>
                                                    </>)}
                                            />
                                            {/*<Button className='ml-4' variant="primary" onClick={()=>newSearch()}>*/}
                                            {/*  <i className='fas fa-search'/>*/}
                                            {/*</Button>*/}
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} md="3" lg="3" controlId="validationCustom02">
                                    <Form.Label>วันที่/เวลา</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        name="created_at"
                                        placeholder="-"
                                        isInvalid={!!errors.bank_name}
                                        ref={register({required: true})}
                                        readOnly
                                    />
                                    <Form.Control.Feedback type="invalid"
                                                           tooltip>กรุณาระบุวันที่/เวลา</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="3" lg="3" controlId="validationCustom03">
                                    <Form.Label>UserID</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        name="user_id"
                                        placeholder="-"
                                        isInvalid={!!errors.user_id}
                                        ref={register({required: true})}
                                        readOnly
                                    />
                                    <Form.Control.Feedback type="invalid" tooltip>กรุณาระบุ
                                        UserID</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="3" lg="3" controlId="validationCustom04">
                                    <Form.Label>ชื่อ</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        name="first_name"
                                        placeholder="-"
                                        isInvalid={!!errors.bank_account_no}
                                        ref={register({required: true})}
                                        readOnly
                                    />
                                    <Form.Control.Feedback type="invalid" tooltip>กรุณาระบุชื่อ</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="3" lg="3" controlId="validationCustom05">
                                    <Form.Label>นามสกุล</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        name="last_name"
                                        placeholder="-"
                                        isInvalid={!!errors.last_name}
                                        ref={register({required: true})}
                                        readOnly
                                    />
                                    <Form.Control.Feedback type="invalid"
                                                           tooltip>กรุณาระบุนามสกุล</Form.Control.Feedback>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} md="6" lg="6" controlId="validationCustom06">
                                    <Form.Label>บัญชีโอน</Form.Label>
                                    {/*{*/}
                                    {/*    fromBankSelect.length === 1 ? <Form.Control*/}
                                    {/*    type="select"*/}
                                    {/*    name="from_bank"*/}
                                    {/*    placeholder="-"*/}
                                    {/*    ref={register}*/}
                                    {/*    defaultValue={data.from_bank_account_no}*/}
                                    {/*    custom*/}
                                    {/*    readOnly*/}
                                    {/*/> : fromBankSelect.length > 1 ?*/}

                                            <Form.Control
                                        as="select"
                                        name="from_bank"
                                        placeholder="-"
                                        ref={register}
                                        custom
                                        defaultValue={data?.from_bank_account_no}
                                        onClick={()=>getTransferBank()}
                                        disabled={creditStatus === "success" && workOrderStatus === "success" && login_profile.name !== "Programmer Admin"}
                                    >
                                        {
                                            fromBankSelect?.map(({bank_name, bank_account_no}, index) =>
                                                <option
                                            key={index}
                                            value={bank_account_no}
                                            selected={bank_account_no === data?.from_bank_account_no}
                                        >
                                            {bank_name} - {bank_account_no}
                                        </option>)
                                        }
                                    </Form.Control>
                                    {/*:*/}
                                    {/*<Form.Control*/}
                                    {/*//     type="select"*/}
                                    {/*//     name="from_bank"*/}
                                    {/*//     placeholder="-"*/}
                                    {/*//     ref={register}*/}
                                    {/*//     custom*/}
                                    {/*//     readOnly*/}
                                    {/*// />*/}
                                     {/*}*/}
                                    <Form.Control.Feedback type="invalid"
                                                           tooltip>กรุณาระบุบัญชีโอน</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="6" lg="6" controlId="validationCustom07">
                                    <Form.Label>บัญชีรับ</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="to_bank"
                                        placeholder="-"
                                        ref={register}
                                        readOnly
                                    />
                                    <Form.Control.Feedback type="invalid"
                                                           tooltip>กรุณาระบุบัญชีรับ</Form.Control.Feedback>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} md="3" lg="3" controlId="validationCustom08">
                                    <Form.Label>จำนวนเงิน</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="amount"
                                        placeholder="-"
                                        ref={register}
                                        readOnly
                                    />
                                    {/* <Form.Control.Feedback type="invalid" tooltip>กรุณาระบุ</Form.Control.Feedback> */}
                                </Form.Group>
                                <Form.Group as={Col} md="3" lg="3" controlId="validationCustom09">
                                    <Form.Label>เครดิตคงเหลือ</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="current_credit"
                                        placeholder="-"
                                        ref={register}
                                        readOnly
                                    />
                                    {/* <Form.Control.Feedback type="invalid" tooltip>กรุณาระบุเลขที่บ</Form.Control.Feedback> */}
                                </Form.Group>
                                <Form.Group as={Col} md="3" lg="3" controlId="validationCustom10">
                                    <Form.Label>ผู้ทำรายการ</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="updated_by"
                                        placeholder="-"
                                        ref={register}
                                        readOnly
                                    />
                                    {/* <Form.Control.Feedback type="invalid" tooltip>กรุณาระบุเลขที่บัญชี</Form.Control.Feedback> */}
                                </Form.Group>
                                <Form.Group as={Col} md="3" lg="3" controlId="validationCustom11">
                                    <Form.Label>อัพเดทล่าสุด</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="updated_at"
                                        placeholder="-"
                                        ref={register}
                                        readOnly
                                    />
                                    <Form.Control.Feedback type="invalid"
                                                           tooltip>กรุณาระบุเลขที่บัญชี</Form.Control.Feedback>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} md="6" controlId="validationCustom12">
                                    <Form.Label>หมายเหตุ</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="comment"
                                        placeholder="-"
                                        ref={register}
                                        readOnly={!editComment}
                                        onChange={(e) => setComment(e.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="validationCustom39">
                                    {(data?.qr_ref) ? <>
                                        <Form.Label>Slip QR Code (สำหรับใช้แอพธนาคารตรวจสอบ)</Form.Label>
                                        <QRCode
                                            value={data.qr_ref}
                                            size={100}/>
                                    </> : <>
                                        <Form.Label>ไม่มี Slip QR Code</Form.Label>
                                    </>}
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} md="6"
                                            hidden={!(login_profile.role === 'super_admin' && login_profile.name === 'Programmer Admin')}
                                >
                                    <Form.Label>status</Form.Label>
                                    <Form.Control as="select"
                                                  name="status"
                                                  ref={register}
                                                  custom
                                    >
                                        {statusOptions.map((option, index) => (
                                            <option key={option.value} value={option.value}>{option.label}</option>))}
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group as={Col} md="6"
                                            hidden={!(login_profile.role === 'super_admin' && login_profile.name === 'Programmer Admin')}
                                >
                                    <Form.Label>credit status</Form.Label>
                                    <Form.Control as="select"
                                                  name="credit_status"
                                                  ref={register}
                                                  custom
                                    >
                                        {statusOptions.map((option, index) => (
                                            <option value={option.value}>{option.label}</option>))}
                                    </Form.Control>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                {(workOrderType === "withdraw") ? (<Form.Group as={Col} xs="12" sm="12" md="12" lg="12"
                                                                               controlId="validationCustom13">
                                        <Form.Label>รายการธุรกรรมล่าสุด</Form.Label>
                                        <Table striped bordered hover responsive={true}>
                                            <thead className="text-center">
                                            <tr>
                                                <th>วันที่/เวลา</th>
                                                <th>ประเภทธุรกรรม</th>
                                                <th>จำนวนเงิน</th>
                                                <th>ยอดคงเหลือ</th>
                                                <th>สถานะการทำธุรกรรม</th>
                                            </tr>
                                            </thead>
                                            <tbody className="text-center">
                                            {customerHistoryTable.map(history => {
                                                return (<tr key={history.id}>
                                                        <td>{moment(history.created_at).format("DD/MM/YYYY HH:mm:ss")}</td>
                                                        <td>{getThaiWorkOrderType(history.work_order_type)}</td>
                                                        <td>{history.amount} บาท</td>
                                                        <td>{history.after_balance} บาท</td>
                                                        <td>{renderBadge(history.status)}</td>
                                                    </tr>)
                                            })}

                                            </tbody>
                                        </Table>
                                    </Form.Group>) : (<></>)}

                            </Form.Row>
                            <Alert variant={"danger"} show={alertModal} onClose={() => setAlertModal(false)}
                                   dismissible>
                                {alertText}
                            </Alert>
                            {(creditStatus === "mapping") ? (<Form.Row>

                                <Form.Group as={Col} md="12" lg="12" controlId="validationCustom06">
                                    <Form.Label>รายการจับคู่</Form.Label>
                                    <Table striped bordered hover responsive={true}>
                                        <thead>
                                        <tr>
                                            <th className={"text-md-center"}
                                                style={{width: "15px"}}>เลือกรายการ
                                            </th>
                                            <th className={"text-md-center"}>วันที่/เวลา</th>
                                            <th className={"text-md-center"}>บัญชีโอน</th>
                                            <th className={"text-md-center"}>บัญชีรับ</th>
                                            <th className={"text-md-center"}>จำนวนเงิน</th>
                                            <th className={"text-md-center"}>หมายเหตุ</th>
                                            {(role === "super_admin" || role === "advanced_admin" ||role === "admin") ? (
                                                <th className={"text-md-center"}
                                                    style={{width: "15px"}}>ยืนยันสลิป</th>) : (<></>)}
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {dataMappingList.map(e => {
                                            return (<tr key={e.mapping_id}>
                                                    <td colSpan="1" className={"text-md-center"}>
                                                        <Form.Check
                                                            //required
                                                            name="mapping_id"
                                                            ref={register}
                                                            value={e.mapping_id}
                                                            type="radio"
                                                            onClick={(e) => handleCheckboxMapping(e.target.value)}
                                                            //feedback="You must agree before submitting."
                                                        />
                                                    </td>
                                                    <td colSpan="1" className={"text-md-center"}>
                                                        {moment(e.created_at).format("DD/MM/YYYY HH:mm:ss")}
                                                    </td>
                                                    <td colSpan="1" className={"text-md-center"}>
                                                        <LogoWithAccountNo
                                                            bankShortName={e.from_bank_short_name}
                                                            bankName={e.from_bank_name}
                                                            accountNo={e.from_bank_account_no}
                                                            workOrderType={e.work_order_type}
                                                            workOrderSubType={e.work_order_sub_type}
                                                            column={"1"}
                                                        />
                                                    </td>
                                                    <td colSpan="1" className={"text-md-center"}>
                                                        <LogoWithAccountNo
                                                            bankShortName={e.to_bank_short_name}
                                                            bankName={e.to_bank_name}
                                                            accountNo={e.to_bank_account_no}
                                                            workOrderType={e.work_order_type}
                                                            workOrderSubType={e.work_order_sub_type}
                                                            column={"2"}
                                                        />
                                                    </td>
                                                    <td colSpan="1" className={"text-md-center"}>
                                                        {number_format(e.amount, 2, '.', ',')}
                                                    </td>
                                                    <td colSpan="1" className={"text-md-center"}>
                                                        {e.comment}
                                                    </td>
                                                    {(role === "super_admin" || role === "advanced_admin" || role === "admin") ? (
                                                        <td className={"text-md-center"}>
                                                            <Button variant="outline-danger"
                                                                    size="sm"
                                                                    onClick={() => forceSuccessMapping({
                                                                        id: e.id, mapping_id: e.mapping_id
                                                                    })}>
                                                                <i className="far fa-sticky-note"/>
                                                            </Button>
                                                        </td>) : (<></>)}
                                                </tr>)
                                        })}

                                        </tbody>
                                    </Table>
                                </Form.Group>
                            </Form.Row>) : (<></>)}
                        </Modal.Body>
                        <Modal.Footer>
                            {!buttonIsLoading && (<ButtonModal
                                    status={workOrderStatus}
                                    creditStatus={creditStatus}
                                    workOrderType={workOrderType}
                                    id={objectId}
                                    userId={getValues("user_id")}
                                />)}
                            {buttonIsLoading && (<Button>
                                    <i className="fas fa-circle-notch fa-spin"/> ระบบกำลังดำเนินการ
                                </Button>)}
                        </Modal.Footer>
                    </Form>
                </Modal>
            </div>
        </>)
}