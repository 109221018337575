import React, {useEffect, useState} from 'react'
import {Button, Col, Form, InputGroup, Modal} from 'react-bootstrap'
import {useForm} from 'react-hook-form'
import {useGlobalStore} from '../../store/globalContext'
import agent_api from "../../api/agents";
import api from "../../api/ccusers";

// const ws = new WebSocket(config.WEBSOCKET_LOCAL)

export default function ModalCreateCCUsers(props) {
    const [{login_profile}, dispatch] = useGlobalStore();
    const {action, modalHeader, buttonSubmit, data, show, closeModal, callBack} = props
    const [officialPartner, setOfficialPartner] = useState([{innerText: "ufafever", value: "fever"}])
    const [officialRole, setOfficialRole] = useState(
        [
            {innerText: "ยูสใหม่", value: "user_new", tier: 0},
            {innerText: "ยูสใหม่ห้ามถอน", value: "user_no_withdraw", tier: 5},
            {innerText: "ยูส-ฝาก-ถอนโยก", value: "user_swap", tier: 7},
            {innerText: "ยูสปกติ", value: "normal", tier: 10},
            {innerText: "แอดมิน", value: "admin", tier: 15},
            {innerText: "advanced_admin", value: "advanced_admin", tier: 18},
            {innerText: "agent", value: "agent", tier: 20},
            {innerText: "super_admin", value: "super_admin", tier: 20}
        ]);

    const {register, handleSubmit, watch, errors, setValue, reset, getValues} = useForm({
        defaultValues: {username: "", password: "", auto_threads: "", auto_port: "", comment: "", status: "active"},
        criteriaMode: "all"
    });

    const watchRole = watch("role", false);

    const [showConfirmUploadModal, setShowConfirmUploadModal] = useState(false);
    const [confirmInput, setConfirmInput] = useState("");

    const onCloseModal = () => {
        reset();
        closeModal();
        // setCheckedAgentState( new Array( agents.length ).fill( false ) );
    }

    const openConfirmUploadModal = () => {
        setShowConfirmUploadModal(true);
    };

    const closeConfirmUploadModal = () => {
        setShowConfirmUploadModal(false);
    }

    const onSubmit = async registerData => {
        //console.log("CC DATA :", data);
        let ccUserData = {}
        //console.log(`send ${action} cc data : `, ccUserData);

        const checkedAgents = [];
        for (let i = 0; i < agents.length; i++) {
            if (checkedAgentState[i] === true) {
                checkedAgents.push(agents[i])
            }
        }

        if (registerData.role === "agent" && checkedAgents.length === 0) {
            dispatch({
                type: "TOAST2",
                value: {
                    show: true,
                    type: 'danger',
                    title: `${action} CC Users`,
                    message: `กรุณาเลือก agent`,
                }
            });
            return
        }

        try {
            let response = null
            if (action === "create") {
                ccUserData = {
                    ...registerData,
                    created_by: login_profile.name,
                    updated_by: login_profile.name,
                    agents: checkedAgents
                }
                response = await api.CREATE_CC_USER(ccUserData)
            } else {
                ccUserData = {
                    ...registerData,
                    id: data.id,
                    updated_by: login_profile.name,
                    agents: checkedAgents
                }
                response = await api.UPDATE_CC_USER(ccUserData)
            }

            if (response.status !== 200) {
                //console.log(`${action} cc user failed`, response.message);
                return
            }

            // const { resp } = response.data
            console.log(`${action} cc user success`, response.data);
            onCloseModal();
            dispatch({
                type: "TOAST2",
                value: {
                    show: true,
                    type: 'success',
                    title: `${action} CC Users`,
                    message: `ทำรายการสำเร็จ`,
                }
            });
            callBack(data.id);
        } catch (error) {
            //console.log(`${action} cc user error`, error);
            return
        }

        onCloseModal();
    }

    const [isopenModal, setIsOpenModal] = useState(false)
    const [checked, setChecked] = useState(false);
    useEffect(() => {
        if (action === "update") {
            Object.keys(data).map(function (key, index) {
                setValue(key, data[key])
            });
        }

        let dropdown = []
        switch (login_profile.role) {

            case "super_admin":
                dropdown = officialRole.filter(role => role.tier <= 20)
                break;
            case "advanced_admin":
                dropdown = officialRole.filter(role => role.tier <= 18)
                break;
            case "admin":
                dropdown = officialRole.filter(role => role.tier <= 15)
                break;
            case "normal":
                dropdown = officialRole.filter(role => role.tier <= 10)
                break;
            default:
                dropdown = officialRole.filter(role => role.tier <= 5)
        }
        setOfficialRole(dropdown)

    }, [show])

    const [validated, setValidated] = useState(false);
    const [selectBankType, setSelectBankType] = useState(
        [{innerText: "บัญชีฝาก", value: "deposit"},
            {innerText: "บัญชีถอน", value: "withdraw"},
            {innerText: "บัญชีพัก", value: "holding"}
        ])

    const [passwordVisible, setPasswordVisible] = useState("password")

    const [agents, setAgents] = useState([]);

    const handleSearchAgent = async () => {
        try {
            const keyword = {
                "keyword": "",
            }
            const response = await agent_api.SEARCH_AGENTS(keyword)
            const {data} = response.data
            // console.log( data )
            let moneySiteData = []
            data.forEach((d => {
                    moneySiteData.push(d.username)
                })
            )
            setAgents(moneySiteData)
            setCheckedAgentState(new Array(moneySiteData.length).fill(false))
        } catch (error) {
            console.log("call search agents error ", error)
        }
    }

    const [checkedAgentState, setCheckedAgentState] = useState([])

    const handleCheckedBoxChange = (position) => {
        const updatedCheckedAgentState = checkedAgentState.map((item, index) =>
            index === position ? !item : item
        );

        setCheckedAgentState(updatedCheckedAgentState);
    }

    const handleCheckedAgentState = async () => {
        if (data.agents !== null && data.agents !== undefined) {
            const previousCheckedAgentState = checkedAgentState
            for (let i = 0; i < data.agents.length; i++) {
                for (let j = 0; j < agents.length; j++) {
                    if (agents[j] === data.agents[i]) {
                        previousCheckedAgentState[j] = true
                        break
                    }
                }
            }
            // console.log( previousCheckedAgentState )
            setCheckedAgentState(previousCheckedAgentState)
        }
    }

    const resetTwoFactor = async () => {
        try {
            const request = {
                "id": data.id,
            }
            onCloseModal();
            await api.RESET_TWO_FACTOR(request)
            dispatch({
                type: "TOAST2",
                value: {
                    show: true,
                    type: 'success',
                    title: `Reset Two Factor CC Users`,
                    message: `ทำรายการสำเร็จ`,
                }
            });
        } catch (error) {
            console.log("call reset two factor error ", error)
            dispatch({
                type: "TOAST2",
                value: {
                    show: true,
                    type: 'danger',
                    title: `Reset Two Factor CC Users`,
                    message: `ทำรายการไม่สำเร็จ โปรดลองอีกครั้ง`,
                }
            });
        }
    }

    const removeUser = async () => {
        try {
            const request = {
                "id": data.id,
            }
            onCloseModal();
            await api.DELETE_CC_USER(request)
            dispatch({
                type: "TOAST2",
                value: {
                    show: true,
                    type: 'success',
                    title: `ลบ CC Users`,
                    message: `ทำรายการสำเร็จ`,
                }
            });
        } catch (error) {
            console.log("call delete cc user error ", error)
            dispatch({
                type: "TOAST2",
                value: {
                    show: true,
                    type: 'danger',
                    title: `ลบ CC Users`,
                    message: `ทำรายการไม่สำเร็จ โปรดลองอีกครั้ง`,
                }
            });
        }
        setShowConfirmUploadModal(false)
        callBack()
    }

    useEffect(() => {
        handleSearchAgent()
    }, [])

    useEffect(() => {
        handleCheckedAgentState()
    }, [data.agents])

    return (
        <div>
            <Modal show={show} size="xl">
                <Modal
                    className="mt-10"
                    show={showConfirmUploadModal}
                    onHide={closeConfirmUploadModal}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>ยืนยันการลบ cc user</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group>
                                <Form.Label className="text-red-500">พิมพ์ ยืนยัน การลบ cc user</Form.Label>
                                <Form.Control as="input"
                                              name=""
                                              placeholder="ยืนยัน"
                                              defaultValue=""
                                              onChange={(e) => setConfirmInput(e.target.value)}
                                >
                                </Form.Control>
                                {/*<p className="text-red-500 font-bold pl-1 pt-1">{errorConfirmMsg}</p>*/}
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={() => removeUser()}
                                disabled={confirmInput !== "ยืนยัน"}>ยืนยัน</Button>
                        <Button variant="secondary" onClick={() => closeConfirmUploadModal()}>
                            ปิด
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Form noValidate validated={validated} onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Header>
                        <Modal.Title>{modalHeader}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <Form.Row>
                            <Form.Group as={Col} md="6" lg="6" controlId="validation00">
                                <Form.Label>เปิด/ปิดการใช้งาน CC User</Form.Label>
                                <InputGroup>
                                    <Form.Control
                                        as="select"
                                        name="status"
                                        custom
                                        required
                                        isInvalid={!!errors.role}
                                        ref={register({required: true})}
                                    >
                                        <option value="active">Active</option>
                                        <option value="suspend">Suspend</option>
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid" tooltip>
                                        กรุณาเลือกสถานะ CC User
                                    </Form.Control.Feedback>
                                </InputGroup>

                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} md="6" lg="6" controlId="validationCustom02">
                                <Form.Label>ชื่อ CC Users</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    name="name"
                                    placeholder="ชื่อ-นามสกุล"
                                    isInvalid={!!errors.name}
                                    ref={register({required: true})}
                                />
                                <Form.Control.Feedback type="invalid"
                                                       tooltip>กรุณาระบุชื่อน้องหน้างาน</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} md="6" lg="6" controlId="validation03">
                                <Form.Label>Role</Form.Label>
                                <InputGroup>
                                    <Form.Control
                                        as="select"
                                        name="role"
                                        custom
                                        required
                                        isInvalid={!!errors.role}
                                        ref={register({required: true})}
                                    >
                                        <option value="">Please Select</option>
                                        {
                                            officialRole.map(({innerText, value, tier}, index) =>
                                                <option
                                                    key={index}
                                                    value={value}
                                                >
                                                    {innerText}
                                                </option>
                                            )
                                        }
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid" tooltip>
                                        กรุณาเลือก Role
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                        </Form.Row>
                        {watchRole === "agent" &&
                            <Form.Row>
                                <Form.Group as={Col} md="6" lg="6" controlId="validation07">
                                    <Form.Label>เลือกเอเย่นต์</Form.Label>
                                    <Form.Row className="px-3">
                                        {
                                            agents.map((value, index) =>
                                                <Form.Check
                                                    className="pr-3"
                                                    label={value}
                                                    type="checkbox"
                                                    id={index}
                                                    key={index}
                                                    name={value}
                                                    checked={checkedAgentState[index]}
                                                    onChange={() => handleCheckedBoxChange(index)}
                                                />
                                            )
                                        }
                                    </Form.Row>
                                </Form.Group>
                            </Form.Row>
                        }
                        <Form.Row>
                            <Form.Group as={Col} md="3" lg="3" controlId="validationCustom04">
                                <Form.Label>Username*</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="username"
                                    placeholder="username"
                                    required
                                    isInvalid={!!errors.username}
                                    ref={register({required: true})}
                                />
                                <Form.Control.Feedback type="invalid" tooltip>กรุณาระบุ Username</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} md="3" lg="3" controlId="validationCustom05">
                                <Form.Label>Password*</Form.Label>
                                <Form.Control
                                    type={passwordVisible}
                                    name="password"
                                    placeholder="password"
                                    required
                                    isInvalid={!!errors.password}
                                    ref={register({required: true})}
                                    onFocus={() => setPasswordVisible("text")}
                                    onBlur={() => setPasswordVisible("password")}
                                />
                                <Form.Control.Feedback type="invalid" tooltip>กรุณาระบุ Password</Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} md="6" controlId="validationCustom06">
                                <Form.Label>Comment เพิ่มเติม</Form.Label>
                                <Form.Control
                                    type="textarea"
                                    name="comment"
                                    placeholder="สมัครเมื่อ 2020/09/05"
                                    ref={register}
                                />
                                {/* <Form.Control.Feedback type="invalid" tooltip>
                  Please provide a valid city.
          </Form.Control.Feedback> */}
                            </Form.Group>
                        </Form.Row>
                    </Modal.Body>
                    <Modal.Footer>
                        {
                            // login_profile.role === "super_admin" && login_profile.name === "Programmer Admin" &&
                            ((login_profile.role === "super_admin" && getValues("role") !== "super_admin") || login_profile.name === "Programmer Admin") &&
                            <Button variant="danger" onClick={openConfirmUploadModal}>ลบ User CC</Button>
                        }
                        {
                            login_profile.role === "super_admin" &&
                            <Button variant="primary" onClick={() => resetTwoFactor()}>Reset Two Factor</Button>
                        }
                        <Button variant="success" type="submit">{buttonSubmit}</Button>
                        <Button variant="secondary" onClick={() => onCloseModal()}>ปิด</Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </div>
    )
}