import {atom, selector, useRecoilState} from "recoil";
import localStorage from "./localStorage";
import config from "../config";
import React from "react";

export const notificationsRecoilState = atom({
    key: 'notificationsRecoilState',
    default: [],
    // effects: [
    //     ({onSet}) => {
    //         onSet((newValue, oldValue) => {
    //             console.log("notificationsRecoilState oldValue:", oldValue);
    //             console.log("notificationsRecoilState newValue:", newValue);
    //         });
    //     },
    // ],
});

export const notificationsObjectRecoilState = atom({
    key: 'notificationsObjectRecoilState',
    default: {text:"", type:"info", icon:<i className={`fa fa-exclamation-circle`}/>},
    // effects: [
    //     ({onSet}) => {
    //         onSet((newValue, oldValue) => {
    //             console.log("notificationsObjectRecoilState oldValue:", oldValue);
    //             console.log("notificationsObjectRecoilState newValue:", newValue);
    //         });
    //     },
    // ],
});

export const reconnectSocketObjectRecoilState = atom({
        key: 'reconnectSocketObjectRecoilState',
        default: {show: false},
});
