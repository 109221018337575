import axios from '../client/axios'
import config from '../config/index'

export default {
  SEARCH_BLACK_LIST: (keyword) => {
    //console.log("request search black list api: ", keyword)
    return axios({
      //headers: {},
      method: 'POST',
      url: `${config.SEARCH_BLACK_LIST}`,
      data:keyword
    })
  },
  BLACK_LIST: (keyword) => {
    //console.log("request black list api: ", keyword)
    return axios({
      //headers: {},
      method: 'POST',
      url: `${config.BLACK_LIST}`,
      data:keyword
    })
  },
  UN_BLACK_LIST: (keyword) => {
    //console.log("request unblock blacklist api: ", keyword)
    return axios({
      //headers: {},
      method: 'POST',
      url: `${config.UN_BLACK_LIST}`,
      data:keyword
    })
  },
}