import React from 'react'
import { Badge, Col, Form, Modal, Spinner, Table } from 'react-bootstrap'
import moment from "moment";
import { number_format } from "../utils/Number";

export default function ModalSearchTransactionCustomer ( props ) {

  const { customerData, showModal, onClose } = props
  const { header, transactions } = customerData

  const getThaiWorkOrderType = ( type ) => {
    switch ( type ) {
      case "deposit" :
        return ( "ฝาก" )
      case "withdraw" :
        return ( "ถอน" )
      case "holding" :
        return ( "พัก" )
      case "bonus" :
        return ( "โบนัส" )
      case "lost" :
        return ( "คืนยอดเสีย" )
      case "manual" :
        return ( "เติมมือ" )
      default :
        return ( "-" )
    }
  }

  const renderBadge = ( status ) => {

    switch ( status ) {
      case "success":
        return ( <Badge variant="success">{"สำเร็จ"}</Badge> )
      case "failed":
        return ( <Badge variant="danger">{"ไม่สำเร็จ"}</Badge> )
      case "deleted":
        return ( <Badge variant="warning">{"ยกเลิก"}</Badge> )
      case "mapping":
        return ( <Badge variant="primary">{"กรุณาจับคู่รายการ"}</Badge> )
      case "pending":
        return ( <Badge variant="info">กรุณาตรวจสอบและยืนยันรายการ</Badge> )
      case "unknown":
        return ( <Badge variant="dark">กรุณาตรวจสอบรายการ</Badge> )
      default:
        return ( <Spinner animation="grow" variant="info"/> )
    }
  }

  const markManualStyle = ( channel, work_order_type ) => {
    if ( work_order_type === "deposit" ) {
      return {
        backgroundColor: "#A4EDA6"
      }
    } else if ( work_order_type === "withdraw" ) {
      return {
        backgroundColor: "#EDA6A4"
      }
    } else {
      return {}
    }
  };

  return (
    <Modal
      size="lg"
      show={showModal}
      onHide={() => onClose()}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          {header}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group as={Col} xs="12" sm="12" md="12" lg="12" controlId="validationCustom13">
          <Form.Label>รายการธุรกรรมล่าสุด</Form.Label>
          <Table striped bordered hover responsive={true}>
            <thead className="text-center">
            <tr>
              <th>วันที่/เวลา</th>
              <th>ประเภทธุรกรรมหลัก</th>
              <th>ประเภทธุรกรรมย่อย</th>
              <th>จำนวนเงิน</th>
              <th>ยอดคงเหลือ</th>
              <th>สถานะการทำธุรกรรม</th>
            </tr>
            </thead>
            <tbody className="text-center">
            {
              ( transactions !== null && transactions !== undefined ) ?
                (
                  transactions.map( history => {
                    return (
                      <tr key={history.id} style={markManualStyle( history.channel, history.work_order_type )}>
                        <td>{moment( history.created_at ).format( "DD/MM/YYYY HH:mm:ss" )}</td>
                        <td>{getThaiWorkOrderType( history.work_order_type )}</td>
                        <td>{getThaiWorkOrderType( history.work_order_sub_type )}</td>
                        <td>{number_format( history.amount, 2, '.', ',' )} บาท</td>
                        <td>{number_format( history.after_balance, 2, '.', ',' )} บาท</td>
                        <td>{renderBadge( history.status )}</td>
                      </tr>
                    )
                  } )
                ) : ( <tr>
                  <td colSpan={12}>ไม่มีรายการธุรกรรมล่าสุด</td>
                </tr> )

            }
            </tbody>
          </Table>
        </Form.Group>
      </Modal.Body>
    </Modal>
  )
}

