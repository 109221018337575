import React, {useEffect, useState} from 'react';
import {Button, Card, Col, Fade, Form, FormControl, InputGroup, Row, Spinner, Table} from "react-bootstrap";
import {useForm} from "react-hook-form";
import api_bank from "../../api/managebank";
import {number_format} from "../utils/Number";
import moment from "moment";
import ModalStatementDetail from "./ModalStatementDetail";

export default function ManageBankStatement() {
    const [validated, setValidated] = useState(false);
    const {register, handleSubmit, errors, getValues} = useForm({
        defaultValues: {}, criteriaMode: "all"
    });

    const [banks, setBanks] = useState([]);
    const [statements, setStatements] = useState([]);

    const [fetchAll, setFetchAll] = useState(false);
    const [startDate, setStartDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
    const fetchAllOptions = [{innerText: "รายการล่าสุด", value: false}, {innerText: "รายการทั้งหมด", value: true}]

    const getBankList = async () => {
        try {
            // const response = await api_bank.BANK_SEARCH_TYPE({keyword: "withdraw"});
            const response = await api_bank.BANK_SEARCH({keyword: "", fetch: false})
            const {data} = response.data;
            //console.log("search deposit bank response data: ", data);
            setBanks(data);
        } catch (e) {
            console.log(`search bank_list failed`, e);
        }
    };

    const [loading, setLoading] = useState(false);

    const onSubmit = async bank => {
        setLoading(true);

        try {
            const request = {
                bank_account_no: bank.bank_account_no,
                fetch_all: fetchAll,
                date: startDate,
            };
            const response = await api_bank.BANK_STATEMENTS(request);
            const {data} = response.data;
            setStatements(data);
        } catch (e) {
            console.log(e);
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    };

    const [modalStatementDetailInfo, setModalStatementDetailInfo] = useState({});
    const [isOpenStatementDetailModal, setIsOpenStatementDetailModal] = useState(false);
    const handleOpenStatementDetailModal = (data, flag) => {
        setModalStatementDetailInfo({...data, bank_account_no: getValues("bank_account_no")});
        setIsOpenStatementDetailModal(flag);
    };

    const handleCloseHistoryModal = () => {
        setIsOpenStatementDetailModal(false);
    };

    useEffect(() => {
        getBankList();
    }, []);

    return (<section className="content pt-4 pr-4 pl-4">
        <div className="container-fluid">
            <div>
                <ModalStatementDetail
                    data={modalStatementDetailInfo}
                    isOpenModal={isOpenStatementDetailModal}
                    onClose={handleCloseHistoryModal}
                />
                <Card className="card-info">
                    <Card.Header>ตรวจสอบรายการเดินบัญชี</Card.Header>
                    <Card.Body>
                        <Form noValidate validated={validated} onSubmit={handleSubmit(onSubmit)}>
                            <Form.Row>
                                <p className={"my-auto font-bold text-red-700 mr-2"}>เลือกธนาคาร</p>
                                <Col sm={3} md={3} lg={3}>
                                    <InputGroup>
                                        <Form.Control
                                            as="select"
                                            name="bank_account_no"
                                            custom
                                            isInvalid={!!errors.bank_account_no}
                                            ref={register({required: true})}
                                        >
                                            <option value="">Please Select</option>
                                            {banks?.filter((bank) => (bank.bank_short_name === "kbank" || bank.bank_short_name === "scb" || bank.bank_short_name === "gsb") && bank.crawler_type === "api")
                                                .map(({bank_name, bank_account_no}, index) =>
                                                <option
                                                    key={bank_account_no}
                                                    value={bank_account_no}
                                                >
                                                    {bank_name} - {bank_account_no}
                                                </option>)}
                                        </Form.Control>
                                        <Form.Control.Feedback type="invalid" tooltip>
                                            กรุณาเลือกธนาคารที่ต้องการค้นหา
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Col>
                                <p className={"my-auto font-bold text-red-700 mx-2"}>เลือกวันที่ต้องการค้นหา</p>
                                <Col sm={2} md={2} lg={2}>
                                    <InputGroup>
                                        <FormControl
                                            type="date"
                                            name="start_date"
                                            placeholder="2020-09-10"
                                            required
                                            onChange={(e) => setStartDate(moment(e.target.value).format("YYYY-MM-DD"))}
                                            defaultValue={startDate}
                                        />
                                        <Form.Control.Feedback type="invalid" tooltip>
                                            กรุณาเลือกวันที่ต้องการค้นหา
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Col>
                                <p className={"my-auto font-bold text-red-700 mx-2"}>เลือกประเภทการค้นหา</p>
                                <Col sm={2} md={2} lg={2}>
                                    <InputGroup>
                                        <Form.Control
                                            as="select"
                                            name="fetch_all"
                                            required
                                            onChange={(e) => setFetchAll(e.target.value === 'true')}
                                        >
                                            {fetchAllOptions.map(({innerText, value}, index) => <option
                                                key={index}
                                                value={value}
                                            >
                                                {innerText}
                                            </option>)}
                                        </Form.Control>
                                    </InputGroup>
                                </Col>
                                <Col xs="auto">
                                    {loading ? <>
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="md"
                                            role="status"
                                            aria-hidden="true"
                                        /> กำลังค้นหา
                                    </> : <Button type="submit" disabled={loading}>ค้นหาข้อมูล</Button>}
                                </Col>
                            </Form.Row>
                        </Form>
                        <Row>
                            <Col lg={12} className="text-center pt-4">
                                <Fade in={true}>
                                    <Table responsive hover size="md">
                                        <thead className="text-center">
                                        <tr>
                                            <th>เลขที่บัญชี</th>
                                            <th>วันเวลาที่ทำรายการ</th>
                                            <th>ช่องทาง</th>
                                            <th>ประเภทรายการ</th>
                                            <th>จำนวนเงิน (บาท)</th>
                                            <th>รายละเอียด</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {statements?.map(({
                                                              transactionUxDate,
                                                              channel,
                                                              transactionType,
                                                              amount,
                                                              rqUid,
                                                              transactionNumber,
                                                              sourceSystemId,
                                                              transactionDescription,
                                                          }, index) => <tr key={index}>
                                            <td><h6>{getValues("bank_account_no")}</h6></td>
                                            <td><h6>{moment.unix(transactionUxDate).format('DD/MM/YYYY HH:mm:ss')}</h6>
                                            </td>
                                            <td><h6>{channel}</h6></td>
                                            <td><h6>{transactionType === "DR" ? "ถอน" : "ฝาก"}</h6></td>
                                            <td><h6>{number_format(amount, 2)}</h6></td>
                                            <td>
                                                <button
                                                    className='bg-blue-500 p-2 rounded hover:bg-green-500 focus:outline-none'
                                                    onClick={() => handleOpenStatementDetailModal({
                                                        transactionUxDate,
                                                        sourceSystemId,
                                                        transactionNumber,
                                                        rqUid,
                                                        amount,
                                                        transactionType,
                                                        transactionDescription,
                                                    }, true)}
                                                >
                                                    ดูรายละเอียด
                                                </button>
                                            </td>
                                        </tr>)}
                                        </tbody>
                                    </Table>
                                </Fade>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </div>
        </div>
    </section>)
}