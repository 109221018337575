import React, { useEffect } from 'react'
import WorkOrder from './WorkOrder'
import { useGlobalStore } from '../../store/globalContext'

export default function TrueWalletSummaryWorkOrder() {
  const dispatch = useGlobalStore()[1];

  useEffect(() => {
    try {
      dispatch({
        type: "SOCKET_SEND",
        value: { method: "subscribe", topic: "dashboard_work_order_true_wallet" }
      });
      //console.log("dispatch from deposit summary");
    } catch (error) {
      console.log("error :", error);
      dispatch({
        type: "TOAST",
        value: {
          show: true,
          type: 'danger',
          title: `ระบบมีปัญหา`,
          message: `ขออภัยระบบมีปัญหา กรุณาติดต่อ admin`,
        }
      });
    }
  }, [])

  return (
    <div className="pt-4 pr-4 pl-4">
      <WorkOrder
        workOrderTitleHeader="ค้นหาใบงานฝาก True Wallet"
        workOrderTitleBody="พิมพ์เพื่อค้นหาใบงานฝาก True Wallet"
        workOrderType="deposit"
        workOrderSubType="true_wallet"
        showSelectedBank={false}
      />
    </div>
  )
}
