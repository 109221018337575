import React, {useState} from 'react';
import {Button, Col, Form, InputGroup, Modal} from "react-bootstrap";
import {useForm} from "react-hook-form";
import api_bank from "../../api/managebank";
import api from "../../api/customer";

export default function ModalViewBankDetail(props) {
    const {data, isOpenModal, onClose} = props;
    const {id} = data;

    const [errorViewPassword, setErrorViewPassword] = useState("");

    const handleClose = () => {
        setErrorViewPassword("");
        setBankDetail({});
        onClose();
    };

    const [validated, setValidated] = useState(false);
    const {register, handleSubmit, errors} = useForm({
        defaultValues: {}, criteriaMode: "all"
    });

    const [bankDetail, setBankDetail] = useState({});

    const onSubmit = async (data) => {
        const request = {...data, id};
        try {
            const response = await api_bank.BANK_VIEW_PASSWORD(request);
            const {data} = response.data;
            setBankDetail(data);
        } catch (e) {
            setErrorViewPassword(e.response.data.message);
            console.log("error view password", e.response.data.message);
        }
    };

    return (<>
        <Modal show={isOpenModal} onHide={handleClose} size="lg">
            <Form noValidate validated={validated} onSubmit={handleSubmit(onSubmit)}>
                <Modal.Header closeButton>
                    <Modal.Title className={"font-bold"}>{Object.keys(bankDetail).length === 0 ? "กรุณากรอกข้อมูลยืนยันตัวตน" : "รายละเอียดธนาคาร"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        Object.keys(bankDetail).length === 0 ?
                            <>
                                <Form.Row>
                                    <Form.Group as={Col} controlId="formLoginNoOTP1">
                                        <InputGroup className="col-12" size="lg">
                                            <InputGroup.Prepend>
                                                <InputGroup.Text id="inputGroup-sizing-default"><i
                                                    className="fas fa-user-shield mr-2"/></InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <Form.Control
                                                type="text"
                                                name="username"
                                                placeholder="Username"
                                                isInvalid={!!errors.username}
                                                ref={register({required: true})}
                                            />
                                            <Form.Control.Feedback type="invalid" tooltip>
                                                กรุณาใส่ username
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col} controlId="formLoginNoOTP2">
                                        <InputGroup className="col-12" size="lg">
                                            <InputGroup.Prepend>
                                                <InputGroup.Text id="inputGroup-sizing-default2"><i
                                                    className="fas fa-key mr-2"/></InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <Form.Control
                                                required
                                                type="password"
                                                name="password"
                                                placeholder="Password"
                                                isInvalid={!!errors.password}
                                                ref={register({required: true})}
                                            />
                                            <Form.Control.Feedback type="invalid" tooltip>
                                                กรุณาใส่ password
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col} controlId="formLoginNoOTP3">
                                        <InputGroup className="col-12" size="lg">
                                            <InputGroup.Prepend>
                                                <InputGroup.Text id="inputGroup-sizing-default3"><i className="fab fa-google mr-2"/></InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <Form.Control
                                                type="text"
                                                name="token"
                                                placeholder="OTP"
                                                ref={register({required: true})}
                                                required
                                                isInvalid={!!errors.token}
                                            />
                                            <Form.Control.Feedback type="invalid" tooltip>
                                                กรุณาระบุ otp
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                </Form.Row>
                                <p className={"ml-2 text-red-600 font-bold"}>{errorViewPassword}</p>
                            </> :
                            <>
                                <p>ชื่อธนาคาร: {bankDetail.bank_name}</p>
                                <p>ธนาคาร: {bankDetail.bank_short_name.toUpperCase()}</p>
                                <p>เลขที่บัญชี: {bankDetail.bank_account_no}</p>
                                <p>ชื่อบัญชี: {bankDetail.bank_account_name}</p>
                                <p>username: <span className={"text-red-600 font-bold"}>{bankDetail.username}</span></p>
                                <p>password: <span className={"text-red-600 font-bold"}>{bankDetail.password}</span></p>
                            </>
                    }
                </Modal.Body>
                <Modal.Footer>
                    {
                        Object.keys(bankDetail).length === 0 &&
                            <Button type="submit" variant="primary">ยืนยันเพื่อดูรายละเอียด</Button>
                    }
                    <Button variant="secondary" onClick={handleClose}>ปิด</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    </>)
}