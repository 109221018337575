import React, {useEffect, useState} from 'react';
import {Badge, Button, Col, Form, Modal, Spinner, Table} from 'react-bootstrap';
import {useForm} from "react-hook-form";
import api_workorder from "../../api/workorder";
import api_config from "../../api/config";
import {number_format} from "../utils/Number";
import {useGlobalStore} from "../../store/globalContext";
import moment from "moment";


export default function ModalNewUser(props) {
    const [{login_profile}, dispatch] = useGlobalStore();

    const [isLoading, setIsLoading] = useState(false);
    const {modalData, isOpenModal, onClose} = props;

    const {register, handleSubmit, errors} = useForm({});

    const [bonusNewCustomerAmountList, setBonusNewCustomerAmountList] = useState([]);
    const onSubmit = async (data) => {
        setIsLoading(true);

        const depositAmount = bonusNewCustomerAmountList.filter((obj) => obj.bonus_amount === data?.bonus_amount);
        const request = {
            "amount": number_format(data?.bonus_amount, 2, '.', ''),
            "work_order_type": "bonus",
            "work_order_sub_type": "promotion",
            "channel": "manual",
            "created_by": login_profile.name,
            "updated_by": login_profile.name,
            "user_id": modalData.user_id,
            "comment": `เติมโบนัสโปรโมชั่นลูกค้าใหม่ ฝาก ${depositAmount[0].deposit_amount} รับ ${data?.bonus_amount}`,
            "first_name": modalData.first_name,
            "last_name": modalData.last_name,
        };

        try {
            await api_workorder.WORK_ORDER_CREATE(request);

            dispatch({
                type: "TOAST2", value: {
                    show: true,
                    type: 'success',
                    title: 'เติมโบนัสโปรโมชั่นลูกค้าใหม่',
                    message: `เติมโบนัสโปรโมชั่นลูกค้าใหม่ สำเร็จ`,
                },
            });

        } catch (error) {
            console.log(error);
            dispatch({
                type: "TOAST2", value: {
                    show: true,
                    type: 'danger',
                    title: 'เติมโบนัสโปรโมชั่นลูกค้าใหม่',
                    message: `เติมโบนัสโปรโมชั่นลูกค้าใหม่ ไม่สำเร็จ กรุณาติดต่อ admin`,
                }
            });
        } finally {
            setTimeout(() => setIsLoading(false), 1000);
            onClose();
        }
    };

    const searchBonusNewCustomerList = async () => {
        try {
            const request = {
                "name": "bonus_new_customer_amount_list",
            };
            const response = await api_config.SEARCH_CONFIG(request);
            const bonus_amount_list = response.data.data?.bonus_amount_list


            if (bonus_amount_list !== null){
                const bonusAmountList = bonus_amount_list?.map((obj) => {
                    return {...obj, innerText: obj.bonus_amount}
                });
                setBonusNewCustomerAmountList(bonusAmountList);
            }


        } catch (e) {
            console.log(e);
        }
    };

    //Style transactions table
    const getThaiWorkOrderType = ( type ) => {
        switch ( type ) {
            case "deposit" :
                return ( "ฝาก" )
            case "withdraw" :
                return ( "ถอน" )
            case "holding" :
                return ( "พัก" )
            case "bonus" :
                return ( "โบนัส" )
            case "lost" :
                return ( "คืนยอดเสีย" )
            case "manual" :
                return ( "เติมมือ" )
            default :
                return ( "-" )
        }
    };

    const renderBadge = ( status ) => {

        switch ( status ) {
            case "success":
                return ( <Badge variant="success">{"สำเร็จ"}</Badge> )
            case "failed":
                return ( <Badge variant="danger">{"ไม่สำเร็จ"}</Badge> )
            case "deleted":
                return ( <Badge variant="warning">{"ยกเลิก"}</Badge> )
            case "mapping":
                return ( <Badge variant="primary">{"กรุณาจับคู่รายการ"}</Badge> )
            case "pending":
                return ( <Badge variant="info">กรุณาตรวจสอบและยืนยันรายการ</Badge> )
            case "unknown":
                return ( <Badge variant="dark">กรุณาตรวจสอบรายการ</Badge> )
            default:
                return ( <Spinner animation="grow" variant="info"/> )
        }
    };

    const markManualStyle = ( channel, work_order_type ) => {
        if ( work_order_type === "deposit" ) {
            return {
                backgroundColor: "#A4EDA6"
            }
        } else if ( work_order_type === "withdraw" ) {
            return {
                backgroundColor: "#EDA6A4"
            }
        } else {
            return {}
        }
    };

    const [transactions, setTransactions] = useState([]);
    const searchTransactions = async () => {
        const request = {
            user_id: modalData?.user_id,
        };
        try {
            const response = await api_workorder.CUSTOMER_HISTORY(request);
            setTransactions(response.data.data);
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        searchBonusNewCustomerList();
    }, []);

    useEffect(() => {
        searchTransactions();
    }, [modalData])

    return (
        <>
            <Modal show={isOpenModal} size="xl" onHide={onClose}>
                <Modal.Header>
                    <Modal.Title>เติมโบนัสโปรโมชั่นลูกค้าใหม่</Modal.Title>
                </Modal.Header>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Body>
                        <Form.Row>
                            <Form.Group as={Col} md="3" lg="3">
                                <Form.Label>UserID</Form.Label>
                                <Form.Control type="text" value={modalData?.user_id} readOnly={true}/>
                            </Form.Group>
                            <Form.Group as={Col} md="3" lg="3">
                                <Form.Label>ชื่อ</Form.Label>
                                <Form.Control type="text" value={modalData?.first_name} readOnly={true}/>
                            </Form.Group>
                            <Form.Group as={Col} md="3" lg="3">
                                <Form.Label>นามสกุล</Form.Label>
                                <Form.Control type="text" value={modalData?.last_name} readOnly={true}/>
                            </Form.Group>
                            <Form.Group as={Col} md="3" lg="3">
                                <Form.Label>เลือกจำนวนเงิน (บาท)</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="bonus_amount"
                                    custom
                                    required
                                    isInvalid={!!errors.bonus_amount}
                                    ref={register({required: true})}
                                >
                                    <option value="">เลือกจำนวนเงิน</option>
                                    {bonusNewCustomerAmountList.map(({innerText, bonus_amount}, index) => <option
                                        key={index}
                                        value={bonus_amount}
                                    >
                                        {innerText}
                                    </option>)}
                                </Form.Control>
                                <Form.Control.Feedback type="invalid" tooltip>
                                    กรุณาเลือกจำนวนเงิน
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} md="12" lg="12">
                                <Form.Label>รายการธุรกรรมล่าสุด</Form.Label>
                                <Table striped bordered hover responsive={true}>
                                    <thead className="text-center">
                                    <tr>
                                        <th>วันที่/เวลา</th>
                                        <th>ประเภทธุรกรรมหลัก</th>
                                        <th>ประเภทธุรกรรมย่อย</th>
                                        <th>จำนวนเงิน</th>
                                        <th>ยอดคงเหลือ</th>
                                        <th>สถานะการทำธุรกรรม</th>
                                    </tr>
                                    </thead>
                                    <tbody className="text-center">
                                    {
                                        ( transactions !== null && transactions !== undefined ) ?
                                            (
                                                transactions.map( history => {
                                                    return (
                                                        <tr key={history.id} style={markManualStyle( history.channel, history.work_order_type )}>
                                                            <td>{moment( history.created_at ).format( "DD/MM/YYYY HH:mm:ss" )}</td>
                                                            <td>{getThaiWorkOrderType( history.work_order_type )}</td>
                                                            <td>{getThaiWorkOrderType( history.work_order_sub_type )}</td>
                                                            <td>{number_format( history.amount, 2, '.', ',' )} บาท</td>
                                                            <td>{number_format( history.after_balance, 2, '.', ',' )} บาท</td>
                                                            <td>{renderBadge( history.status )}</td>
                                                        </tr>
                                                    )
                                                } )
                                            ) : ( <tr>
                                                <td colSpan={12}>ไม่มีรายการธุรกรรมล่าสุด</td>
                                            </tr> )

                                    }
                                    </tbody>
                                </Table>
                            </Form.Group>
                        </Form.Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="success" type="submit" disabled={isLoading}>
                            {isLoading ? (<>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                /> กำลังดำเนินการ</>) : `เติมเงินโปรโมชั่น`}
                        </Button>
                        <Button variant="secondary" onClick={onClose}>ปิด</Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    )
}