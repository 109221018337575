import React, {useEffect, useState} from 'react'
import {Button, Col, Form, Row} from "react-bootstrap";
import {useForm} from "react-hook-form";
import api from "../../api/customer";
import {useGlobalStore} from "../../store/globalContext";


export default function RegisterOtherAgentsPanel(data){

    const [{ login_profile }, dispatch] = useGlobalStore();

    const { register:registerOtherAgent, control:controlOtherAgent, trigger:triggerOtherAgent,  handleSubmit:handleSubmitOtherAgent, watch:watchOtherAgent, errors:errorsOtherAgent, getValues: getValuesOtherAgent, setValue:setValueOtherAgent, reset:resetOtherAgent} = useForm({
        defaultValues: {...data, sub_agent_list:[]},
        criteriaMode: "all",
        shouldUnregister: false
    });

    const watchAgentId = watchOtherAgent("agent_name")
    const submitOtherAgent = async (d)=>{

        try {
            const response = await api.REGISTER_OTHER_AGENT_CUSTOMER({
                id: d.id,
                agent_name: d.agent_name,
                agent_id: d.agent_id
            })
            dispatch( {
                type: "TOAST2",
                value: {
                    show: true,
                    type: 'success',
                    title: `สมัคร Agent ${d.agent_id}`,
                    message: `สมัคร Agent ${d.agent_id} สำเร็จ`,
                }
            } );
        }catch (e){
            console.log("error register other agent: ", e)
            dispatch( {
                type: "TOAST2",
                value: {
                    show: true,
                    type: 'danger',
                    title: `สมัคร Agent ${d.agent_id}`,
                    message: `สมัคร Agent ${d.agent_id} ไม่สำเร็จ ${e.message}`,
                }
            } );
        }finally {
            data.closeModal()
        }

    }

    useEffect(() => {

        if(watchAgentId === undefined || watchAgentId === ""){
            setValueOtherAgent("sub_agent_list", [])
            resetOtherAgent({})
            return
        }
        const agentList = getValuesOtherAgent("allow_add_agents").filter((ag) => ag.value === watchAgentId)
        setValueOtherAgent("sub_agent_list", agentList)
        void triggerOtherAgent(["agent_id", "agent_name"])
    }, [watchAgentId]);

    return (
        <Form noValidate control={controlOtherAgent} as={"form"} id={"register_other_agents"}
              onSubmit={e => {
                  // e.preventDefault()
                  handleSubmitOtherAgent(submitOtherAgent)(e)
                  e.stopPropagation()
              }
        }>
            <Row>
                <Form.Group as={Col} md="5" lg="5" controlId="register_other_agents01">
                    <Form.Label>เลือก Agent ที่ต้องการสมัครเพิ่มเติม</Form.Label>
                    <Form.Control as="select"
                                  required
                                  // id="agent_id"
                                  name="agent_name"
                                  defaultValue=""
                                  isInvalid={!!errorsOtherAgent.agent_name}
                                  custom
                                  ref={registerOtherAgent({required:"กรุณาเลือก Agent"})}
                    >
                        <option key={"00"} value="">Please Select</option>
                        {
                            getValuesOtherAgent("allow_add_agents")?.map(({ name, value }, index) =>
                                <option
                                    key={value}
                                    value={value}
                                >
                                    {name}
                                </option>
                            )
                        }
                    </Form.Control>
                </Form.Group>
                <Form.Group as={Col} md="5" lg="5" controlId="register_other_agents02">
                    <Form.Label>เลือก Sub-Agent ที่ต้องการสมัคร</Form.Label>
                    <Form.Control as="select"
                                  required
                                  // id="sub_agent_id"
                                  name="agent_id"
                                  defaultValue=""
                                  isInvalid={!!errorsOtherAgent.agent_id}
                                  custom
                                  ref={registerOtherAgent({required:"กรุณาเลือก Sub-agent"})}
                    >
                        <option key={"00"} value="">Please Select</option>
                        {
                            getValuesOtherAgent("sub_agent_list")?.map(l =>
                                l.list.map(ll =>
                                    <option
                                        key={ll.agent_id}
                                        value={ll.agent_id}
                                    >
                                        {
                                            l.list.map(ll => `${ll.agent_name} - ${ll.agent_id}`)
                                        }
                                    </option>
                                )
                            )
                        }
                    </Form.Control>
                </Form.Group>
                <Form.Group as={Col} md="2" lg="2" controlId="register_other_agents03">
                    <Form.Label className={"text-white"}>สมัคร</Form.Label>
                    <Button type={"submit"} className={"my-auto"}>สมัคร Agent เพิ่ม</Button>
                </Form.Group>
            </Row>
        </Form>
    )
}