import React, { useState } from 'react'
import { Button, Col, Form, Modal, Spinner } from 'react-bootstrap'
import api_workorder from "../../api/workorder"
import { useGlobalStore } from '../../store/globalContext'

export default function ConfirmManualSuccessWorkOrder ( props ) {
  const [ { subscribe, dashboard_work_order_deposit, customers, status, login_profile }, dispatch ] = useGlobalStore();
  const { data, isShow, onClose, callBack } = props
  const [ show, setShow ] = useState( isShow );
  const { id, user_id, first_name, last_name, amount, variant, button_name } = data
  const [ isLoading, setLoading ] = useState( false );

  const manualSuccessWorkOrder = async() => {
    setLoading( true )

    const work_order_force_success = {
      id: id,
      comment: "(กดโอนมือ/กดเติมมือ)",
    }

    try {
      await api_workorder.WORK_ORDER_FORCE_SUCCESS( work_order_force_success )
      dispatch( {
        type: "TOAST2",
        value: {
          show: true,
          type: 'success',
          title: `เติมมือแล้ว`,
          message: `เติมมือแล้วใบงาน UserID ${user_id} สำเร็จ`,
        }
      } );
    } catch (e) {
      console.log("force success work order failed", e)
    } finally {
      setTimeout(() => {
        setLoading( false );
        callBack();
      }, 500)
    }
  }


  return (
    <div>
      <Modal show={isShow} onHide={onClose} size="lg" centered={true}>
        <Modal.Header closeButton>
          <Modal.Title>ยืนยัน{button_name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Row>
              <Form.Group as={Col} md="3" lg="3">
                <Form.Label>UserID</Form.Label>
                <Form.Control type="text" value={user_id} readOnly={true}/>
              </Form.Group>
              <Form.Group as={Col} md="3" lg="3">
                <Form.Label>ชื่อ</Form.Label>
                <Form.Control type="text" value={first_name} readOnly={true}/>
              </Form.Group>
              <Form.Group as={Col} md="3" lg="3">
                <Form.Label>นามสกุล</Form.Label>
                <Form.Control type="text" value={last_name} readOnly={true}/>
              </Form.Group>
              <Form.Group as={Col} md="3" lg="3">
                <Form.Label>จำนวนเงิน</Form.Label>
                <Form.Control type="text" value={amount} readOnly={true}/>
              </Form.Group>
            </Form.Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant={variant}
            disabled={isLoading}
            onClick={manualSuccessWorkOrder}
          >
            {isLoading ?
              (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  /> กำลังดำเนินการ</> ) : `ยืนยัน${button_name}`}
          </Button>
          <Button variant="secondary" onClick={onClose}>
            ยกเลิก
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
