import axios from '../client/axios'
import config from '../config/index'


export default {

  GET_THAI_BANK_API: () => {
    ////console.log("request thai bank api: ")
    return axios( {
      //headers: {},
      method: 'GET',
      url: `${config.THAI_BANK_API}`,
    } )
  },

  GET_AGENT_LIST: () => {
    return axios( {
      //headers: {},
      method: 'GET',
      url: `${config.GET_AGENT_LIST}`,
    } )
  },

  SEARCH_CUSTOMER: ( value ) => {
    // //console.log("search customer api: ", keyword)
    return axios( {
      //headers: {"KAK":"YANAA"},
      method: 'POST',
      url: `${config.SEARCH_CUSTOMER}`,
      data: value
    } )
  },

  CREATE_CUSTOMER: ( data ) => {
    ////console.log("update customer api: ", data)
    return axios( {
      //headers: {},
      method: 'POST',
      url: `${config.CREATE_CUSTOMER}`,
      data: data
    } )
  },

  UPDATE_CUSTOMER: ( data ) => {
    ////console.log("update customer api: ", data)
    return axios( {
      //headers: {},
      method: 'POST',
      url: `${config.UPDATE_CUSTOMER}`,
      data: data
    } )
  },

  REGISTER_OTHER_AGENT_CUSTOMER: ( data ) => {
    ////console.log("update customer api: ", data)
    return axios( {
      //headers: {},
      method: 'POST',
      url: `${config.REGISTER_OTHER_AGENT_CUSTOMER}`,
      data: data
    } )
  },
  DELETE_CUSTOMER: ( data ) => {
    //console.log("delete customer api: ", data)
    return axios( {
      //headers: {},
      method: 'POST',
      url: `${config.DELETE_CUSTOMER}`,
      data: data
    } )
  },
  RESET_CUSTOMER_PASSWORD: ( data ) => {
    //console.log("delete customer api: ", data)
    return axios( {
      //headers: {},
      method: 'POST',
      url: `${config.RESET_CUSTOMER_PASSWORD}`,
      data: data
    } )
  },
  TURNOVER_CUSTOMER: ( data ) => {
    //console.log("delete customer api: ", data)
    return axios( {
      //headers: {},
      method: 'POST',
      url: `${config.TURNOVER_CUSTOMER}`,
      data: data
    } )
  },
  SEARCH_PROSPECT: ( value ) => {
    return axios( {
      method: 'POST',
      url: `${config.SEARCH_PROSPECT}`,
      data: value
    } )
  },
  UPDATE_PROSPECT: ( value ) => {
    return axios( {
      method: 'POST',
      url: `${config.UPDATE_PROSPECT}`,
      data: value
    } )
  },
  LOCK_UNLOCK_CUSTOMER: (value) => {
    return axios( {
      method: 'POST',
      url: `${config.LOCK_UNLOCK_CUSTOMER}`,
      data: value
    } )
  },
  INQUIRY_CUSTOMER_LOG: (value) => {
    return axios( {
      method: 'POST',
      url: `${config.INQUIRY_CUSTOMER_LOG}`,
      data: value
    } )
  },
  LIST_AGENTS: (value) => {
    return axios( {
      method: 'GET',
      url: `${config.LIST_AGENTS}`,
      data: value
    } )
  },
}