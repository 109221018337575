import {Button, Card, Col, Form, InputGroup} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import api from "../../api/managebank";
import {Controller, useFieldArray, useForm} from "react-hook-form";
import {useGlobalStore} from "../../store/globalContext";
import {update} from "store/plugins/all_tests";
export default function ManageBankAccountConfig() {

    const { register:registerAccount, handleSubmit:handleSubmitAccount, setValue:setValueAccount, getValues:getValuesAccount, watch:watchAccount, formState:{isDirty:isDirtyAccount, dirtyFields:dirtyFieldsAccount}, reset:resetAccount, control:controlAccount} = useForm({
        defaultValues:{
            prevent_deposit_enable: false,
            prevent_withdraw_enable: false,
            priority: [],
        },
        shouldUnregister: false
    });

    const {
        fields,
        swap,
        move,
    } = useFieldArray({
        control:controlAccount,
        name: "priority"
    });

    const [{ subscribe, banks, status, login_profile }, dispatch] =
        useGlobalStore();

    const getDepositAccountConfigFromAPI = async ()=>{
        try {
            const response =  await api.GET_ACCOUNT_CONFIG()
            // console.log("get deposit account config: ", response.data)

            const {data} = response.data
            setValueAccount("priority", data.priority)
            setValueAccount("prefer_deposit", data.prefer_deposit)
            setValueAccount("statement_delay_duration", data.statement_delay_duration)
            setValueAccount("prevent_deposit_enable", !data.prevent_deposit_enable)
            setValueAccount("prevent_deposit_start_time", data.prevent_deposit_start_time)
            setValueAccount("prevent_deposit_end_time", data.prevent_deposit_end_time)
            setValueAccount("prevent_deposit_message", data.prevent_deposit_message)
            setValueAccount("prevent_withdraw_enable", !data.prevent_withdraw_enable)
            setValueAccount("prevent_withdraw_start_time", data.prevent_withdraw_start_time)
            setValueAccount("prevent_withdraw_end_time", data.prevent_withdraw_end_time)
            setValueAccount("prevent_withdraw_message", data.prevent_withdraw_message)
            setValueAccount("automate_withdraw_amount", data.automate_withdraw_amount)
            setValueAccount("minimum_withdraw_count", data.minimum_withdraw_count)
        }catch (e) {
            console.log("get deposit account config error: ", e, e.response.data)
        }finally {
            // console.log(getValuesAccount("priority"))
        }
    }

    const updateDepositAccountConfig = async (updateObject)=>{
         // console.log("update deposit account config: ", updateObject)
        try {
            const response =  await api.UPDATE_ACCOUNT_CONFIG(updateObject)

            // console.log("update deposit account config: ", response.data)
            const {data} = response.data

            // console.log("update deposit account config response data : ", data)
            dispatch({
                type: "TOAST2",
                value: {
                    show: true,
                    type: "success",
                    title: `แก้ไขตั้งค่าจัดการข้อมูลบัญชีทั่วไป`,
                    message: `แก้ไขตั้งค่าจัดการข้อมูลบัญชีทั่วไป สำเร็จ`,
                },
            });
        }catch (e) {
            console.log("update deposit account config error: ", e, e.response?.data)
            dispatch({
                type: "TOAST2",
                value: {
                    show: true,
                    type: "error",
                    title: `แก้ไขตั้งค่าจัดการข้อมูลบัญชีทั่วไป`,
                    message: `แก้ไขตั้งค่าจัดการข้อมูลบัญชีทั่วไป ไม่สำเร็จ ${e.response?.data.message}`,
                },
            });
        }finally {
            // resetAccount(getValuesAccount())
        }
    }

    const submitDepositAccountConfig = (fields2)=>{
        // console.log("fields2: ", fields2)

        if(isDirtyAccount === false){
            return
        }

        let updateObject= {}

        Object.keys(dirtyFieldsAccount).map((e)=>{
            // console.log("dirtyFieldsAccount: ", dirtyFieldsAccount)
            if(e === "prevent_deposit_enable" || e === "prevent_withdraw_enable"){
                updateObject[e] = Boolean(!getValuesAccount(e))
            }else if(e === "priority"){
                updateObject["priority"] = fields2.priority
            }else{
                updateObject[e] = getValuesAccount(e)
            }
        })

        // console.log("updateObject", updateObject)
        // return;
        void updateDepositAccountConfig(updateObject)
    }

    const toggleDepositWithdrawAccountConfig = (field)=>{
         // console.log("toggleDepositAccountConfig getValuesAccount(prevent_deposit_enable): ", !getValuesAccount(field))

        let updateObject = {}
        updateObject[field]= Boolean(!getValuesAccount(field))
        void updateDepositAccountConfig(updateObject)
    }

    const watchPreventDeposit = watchAccount("prevent_deposit_enable")
    const watchPreventWithdraw = watchAccount("prevent_withdraw_enable")
    const watchPriority = watchAccount("priority")
    const watchPreferDeposit = watchAccount("prefer_deposit")

    useEffect( ()=>{
         void getDepositAccountConfigFromAPI();
    },[])



    return(
        <Card className={"card-cyan"}>
            <Card.Header><i className={"fas fa-money-bill-alt pr-2"}/> จัดการข้อมูลแจ้งลูกค้าของบัญชี ฝาก/ถอน ทั่วไป </Card.Header>
            <Card.Body>
                <Form id="deposit01" onSubmit={handleSubmitAccount(submitDepositAccountConfig)}>
                <Form.Row className={"text-bold py-2 bg-gradient-primary"}>
                    <Form.Label className={"px-3"}><u>เลือกธนาคารเริ่มต้น</u></Form.Label>
                </Form.Row>
                <Form.Row className={"flex my-auto py-2 border-primary border-2 border-top-0 border-bottom-0"} hidden={login_profile.role !== "admin" && login_profile.role !== "super_admin" && login_profile.role !== "advanced_admin"}>
                    <Col className="flex-row col-6 col-lg-12" hidden={true}>
                        <Form.Label>เลือกธนาคารฝากเริ่มต้น</Form.Label>
                            {/*<div className={"flex mx-auto"}>*/}
                            {/*    {*/}
                            {/*        fields.map((p,index) =>*/}
                            {/*                <Form.Check key={`prefer_deposit_${index}`} type={"radio"} id={`prefer_deposit_${p.value}`} name="prefer_deposit" defaultValue={p.value} label={p.key} custom className={"col-xs-12 mx-2 my-auto"} ref={registerAccount} />*/}
                            {/*       )*/}
                            {/*    }*/}
                            {/*</div>*/}
                        </Col>
                </Form.Row>
                    <Form.Row className={"flex my-auto py-2 border-primary border-2 border-top-0 border-bottom-0"} hidden={login_profile.role !== "admin" && login_profile.role !== "super_admin" && login_profile.role !== "advanced_admin"}>
                        <Col className="flex-row col-6 col-lg-12">
                            <Form.Label>ปรับแต่งการแสดงผลฝากหน้าลูกค้า</Form.Label>
                            <div className={"flex py-4 justify-content-around"}>
                                {
                                    fields.map((p,index) =>
                                        <div className={"grid-colum text-center"} key={`priority_label_${index}_div`}>
                                            <label htmlFor={`priority_label_${index}.alias_name`}>{p.key}</label>
                                            <InputGroup as={"div"} className="flex justify-content-center" key={p.id}>
                                                {/*<Form.Label>ปรับแต่งการแสดงผลฝากหน้าลูกค้า</Form.Label>*/}
                                                <InputGroup.Prepend hidden={index===0} id={`priority_label_${index}.key`}>
                                                    <Button variant="outline-primary" onClick={() =>move(index, index-1)} >{`<`}</Button>
                                                </InputGroup.Prepend>
                                                <Form.Control
                                                    key={`priority.${index}.alias_name`}
                                                    id={`priority.${index}.alias_name`}
                                                    name={`priority.${index}.alias_name`}
                                                    ref={registerAccount}
                                                    defaultValue={p.alias_name}
                                                    className={"text-center"}
                                                />
                                                <Form.Control
                                                    key={`priority.${index}.key`}
                                                    id={`priority.${index}.key`}
                                                    name={`priority.${index}.key`}
                                                    ref={registerAccount}
                                                    defaultValue={p.key}
                                                    className={"text-center"}
                                                    hidden
                                                />
                                                <Form.Control
                                                    key={`priority.${index}.value`}
                                                    id={`priority.${index}.value`}
                                                    name={`priority.${index}.value`}
                                                    ref={registerAccount}
                                                    defaultValue={p.value}
                                                    className={"text-center"}
                                                    hidden
                                                />
                                                {/*</Button>*/}
                                                <InputGroup.Append hidden={index === fields.length-1}>
                                                    <Button variant="outline-primary" onClick={()=>move(index, index+1)}>{`>`}</Button>
                                                </InputGroup.Append>
                                            </InputGroup>
                                        </div>
                                    )
                                }
                            </div>
                        </Col>
                    </Form.Row>
                    {/*<Form.Row className={"flex my-auto py-2 border-primary border-2 border-top-0 border-bottom-0"}>*/}
                    {/*    <Col className="flex-row col-6 col-lg-12">*/}
                    {/*        <Form.Label>ตั้งชื่อ Tabs เอง</Form.Label>*/}
                    {/*        <Form.Control*/}
                    {/*        />*/}
                    {/*    </Col>*/}
                    {/*</Form.Row>*/}
                    <Form.Row className={"py-2 px-2 border-primary border-2 border-top-0"}>
                        {/*<input name={"priority"} ref={registerAccount} hidden/>*/}
                        <Button className={"bg-gradient-primary"} type={"submit"}
                                disabled={isDirtyAccount === false}>ตั้งค่าจัดการธนาคารเริ่มต้น</Button>
                    </Form.Row>
                <Form.Row className={"text-bold py-2 mt-2 bg-gradient-green"}>
                    <Form.Label className={"px-3"}><u>จัดการระบบบัญชีฝาก (ธนาคาร)</u></Form.Label>
                </Form.Row>
                    <Form.Row className={"flex my-auto py-2 px-2 border-success border-2 border-bottom-0"}>
                        <Col className={"col-xs-12 col-md-4 my-auto"}>
                            <Form.Label>สถานะใช้งาน <b className={"text-green"}>ฝากรูปแบบบัญชี</b></Form.Label>
                            <Form.Switch
                                custom
                                id="prevent_deposit_enable"
                                name="prevent_deposit_enable"
                                ref={registerAccount}
                                label={watchPreventDeposit === false ? "ปิดฝากอยู่":"เปิดฝาก"}
                                onChange={()=>toggleDepositWithdrawAccountConfig("prevent_deposit_enable")}
                            />
                            {/*<div className={"flex my-auto justify-content-between"}>*/}
                            {/*    <Form.Check*/}
                            {/*        type={"radio"}*/}
                            {/*        custom*/}
                            {/*        id="prevent_deposit_enable_false"*/}
                            {/*        name="prevent_deposit_enable"*/}
                            {/*        defaultValue={false}*/}
                            {/*        // defaultChecked={getValuesAccount("prevent_deposit_enable") === false}*/}
                            {/*        // ref={registerAccount({setValueAs:(v)=>Boolean(v)})}*/}
                            {/*        ref={registerAccount}*/}
                            {/*        label={"เปิดฝาก"}*/}
                            {/*        // onChange={() => toggleDepositWithdrawAccountConfig("prevent_deposit_enable", false)}*/}
                            {/*    />*/}
                            {/*    <Form.Check*/}
                            {/*        type={"radio"}*/}
                            {/*        custom*/}
                            {/*        id="prevent_deposit_enable_true"*/}
                            {/*        name="prevent_deposit_enable"*/}
                            {/*        // ref={registerAccount({setValueAs:(v)=>Boolean(v)})}*/}
                            {/*        ref={registerAccount}*/}
                            {/*        defaultValue={true}*/}
                            {/*        // defaultChecked={getValuesAccount("prevent_deposit_enable") === true}*/}
                            {/*        label={"ปิดฝาก"}*/}
                            {/*        // onChange={() => toggleDepositWithdrawAccountConfig("prevent_deposit_enable", true)}*/}
                            {/*    />*/}
                            {/*</div>*/}
                        </Col>
                        {/*<Col className="col-xs-12 col-md-4 my-auto">*/}
                        {/*    <Form.Label>ตั้งเวลาปิดห้ามฝากอัตโนมัติ(เริ่ม)</Form.Label>*/}
                        {/*    <Form.Control ref={registerAccount} as="input" type="time" name="prevent_deposit_start_time" defaultValue={moment("22:50", 'HH:mm').format( 'HH:mm' )}></Form.Control>*/}
                        {/*</Col>*/}
                        {/*<Col className="col-xs-12 col-md-4 my-auto">*/}
                        {/*    <Form.Label>ตั้งเวลาปิดห้ามฝากอัตโนมัติ(สิ้นสุด)</Form.Label>*/}
                        {/*    <Form.Control ref={registerAccount} as="input" type="time" name="prevent_deposit_end_time" defaultValue={moment("02:00", 'HH:mm').format( 'HH:mm' )}></Form.Control>*/}
                        {/*</Col>*/}
                    </Form.Row>
                    <Form.Row className={"py-2 px-2 border-success border-2 border-top-0 border-bottom-0"}>
                        <Col className="col-xs-12 col-md-12">
                            <Form.Label>ข้อความแจ้งลูกค้าเมื่อปิดฝาก</Form.Label>
                            <Form.Control ref={registerAccount} as="textarea" rows={5} id="prevent_deposit_message" name="prevent_deposit_message" defaultValue={""}></Form.Control>
                        </Col>
                    </Form.Row>
                    <Form.Row className={"py-2 px-2 mb-1 border-success border-2 border-top-0"}>
                        <Button className={"bg-gradient-success"} type={"submit"} disabled={isDirtyAccount === false}>ตั้งค่าจัดการระบบฝาก</Button>
                    </Form.Row>
                    <Form.Row className={"text-bold py-2 bg-gradient-red"}>
                        <Form.Label className={"px-3"}><u>จัดการระบบบัญชีถอน (ธนาคาร)</u></Form.Label>
                    </Form.Row>
                    <Form.Row className={"py-2 px-2 border-danger border-2 border-bottom-0"}>
                        <Col className={"col-xs-12 col-md-4 my-auto"}>
                            <Form.Label>สถานะใช้งาน <b className={"text-red"}>ถอนรูปแบบบัญชี</b></Form.Label>
                            <Form.Switch
                                custom
                                id="prevent_withdraw_enable"
                                name="prevent_withdraw_enable"
                                ref={registerAccount}
                                label={watchPreventWithdraw === false ? "ปิดถอนอยู่":"เปิดถอน"}
                                onChange={()=>toggleDepositWithdrawAccountConfig("prevent_withdraw_enable")}
                            />
                            {/*<div className={"flex my-auto justify-content-between"}>*/}
                            {/*    <Form.Check*/}
                            {/*        type={"radio"}*/}
                            {/*        custom*/}
                            {/*        id="prevent_withdraw_enable_false"*/}
                            {/*        name="prevent_withdraw_enable"*/}
                            {/*        defaultValue={false}*/}
                            {/*        ref={registerAccount}*/}
                            {/*        label={"เปิดถอน"}*/}
                            {/*        // onChange={() => toggleDepositWithdrawAccountConfig("prevent_withdraw_enable", false)}*/}
                            {/*    />*/}
                            {/*    <Form.Check*/}
                            {/*        type={"radio"}*/}
                            {/*        custom*/}
                            {/*        id="prevent_withdraw_enable_true"*/}
                            {/*        name="prevent_withdraw_enable"*/}
                            {/*        defaultValue={true}*/}
                            {/*        ref={registerAccount}*/}
                            {/*        label={"ปิดถอน"}*/}
                            {/*        // onChange={() => toggleDepositWithdrawAccountConfig("prevent_withdraw_enable", true)}*/}
                            {/*    />*/}
                            {/*</div>*/}
                        </Col>
                        {/*<Col className="col-xs-12 col-md-4 my-auto">*/}
                        {/*    <Form.Label>ตั้งเวลาปิดห้ามถอนอัตโนมัติ (เริ่ม)</Form.Label>*/}
                        {/*    <Form.Control ref={registerAccount} as="input" type="time" name="prevent_withdraw_start_time" defaultValue={moment("22:50", 'HH:mm').format( 'HH:mm' )}></Form.Control>*/}
                        {/*</Col>*/}
                        {/*<Col className="col-xs-12 col-md-4 my-auto">*/}
                        {/*    <Form.Label>ตั้งเวลาปิดห้ามถอนอัตโนมัติ (สิ้นสุด)</Form.Label>*/}
                        {/*    <Form.Control ref={registerAccount} as="input" type="time" name="prevent_withdraw_end_time" defaultValue={moment("02:00", 'HH:mm').format( 'HH:mm' )}></Form.Control>*/}
                        {/*</Col>*/}
                    </Form.Row>
                    <Form.Row className={"py-2 px-2 border-danger border-2 border-top-0 border-bottom-0"}>
                        <Col className="col-xs-12 col-md-12">
                            <Form.Label>ข้อความแจ้งลูกค้าเมื่อปิดถอน</Form.Label>
                            <Form.Control ref={registerAccount} as="textarea" rows={5} id="prevent_withdraw_message" name="prevent_withdraw_message" defaultValue={""}></Form.Control>
                        </Col>
                    </Form.Row>
                    <Form.Row className="py-2 px-2 border-danger border-2 border-top-0 border-bottom-0">
                        <Col className="col-xs-12 col-md-6">
                            <Form.Label>จำนวนขั้นต่ำเงินถอน Auto <br/>(ยอดต่ำกว่าหรือเท่ากันระบบถอนให้อัตโนมัติ)</Form.Label>
                            <Form.Control type="number" ref={registerAccount({valueAsNumber:true})}  id={"automate_withdraw_amount"} name={"automate_withdraw_amount"} defaultValue={100}/>
                        </Col>
                        <Col className="col-xs-12 col-md-6">
                            <Form.Label>จำนวนครั้งแจ้งถอนขั้นต่ำ <br/>(ถึงจำนวนที่กำหนดเพื่อเปิดถอนอัตโนมัติ )</Form.Label>
                            <Form.Control type="number" ref={registerAccount({valueAsNumber:true})} id={"minimum_withdraw_count"} name={"minimum_withdraw_count"} defaultValue={5}/>
                        </Col>
                    </Form.Row>
                    <Form.Row className={"py-2 px-2 border-danger border-2 border-top-0"}>
                        <Button className={"bg-gradient-red"} type={"submit"} disabled={isDirtyAccount === false}>ตั้งค่าจัดการระบบถอน</Button>
                    </Form.Row>
                </Form>
            </Card.Body>
        </Card>
    )

}