import {
    Button,
    Card,
    Col,
    Fade,
    Form,
    Modal,
    OverlayTrigger,
    Pagination,
    Spinner,
    Table,
    Tooltip
} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import TableSearchCustomer from "./TableSearchCustomer";
import {useForm} from "react-hook-form";
import api_work_order from "../../api/workorder";
import moment from "moment";
import QRCode from "qrcode.react";


const renderTooltip = (props) => {
    // console.log("render tooltip props: ", props)
    return(
        <Tooltip id="button-tooltip" {...props}>
            {props}
        </Tooltip>
    )
};

const ModalQr = (props) =>{
    const {show, qr_info, OnClose} = props

    return(
        <Modal show={show} onHide={OnClose} size={"lg"}>
            <Modal.Header closeButton>
                <b className={"text-lg text-blue"} hidden={qr_info?.qr_type !== "payonex"}>
                    Transaction UUID: {qr_info?.transaction_uuid || "-"}
                </b>
                <b className={"text-lg text-blue"} hidden={qr_info?.qr_type !== "hengpay68"}>
                    Invoice Number: {qr_info?.invoice_number|| "-"}<br/>  Ref. ID: {qr_info?.object_id || "-"}
                </b>
            </Modal.Header>
            <Modal.Body>
                <div className={"flex justify-content-center my-4"}>
                    <QRCode value={qr_info?.qr_code} size={400}/>
                </div>
                <div className={"flex justify-content-center"}>
                    <b className={"text-lg"}>ประเภท: {qr_info?.qr_type}</b>
                </div>
                <div className={"flex justify-content-center"}>
                    <b className={"text-lg"}>ต้นทาง: {qr_info?.from_bank_name} ({qr_info?.from_bank_account_no})</b>
                </div>
                <div className={"flex justify-content-center"}>
                    <b className={"text-lg"}>UserID: {qr_info?.user_id}</b>
                    <b className={"text-lg"}>{'/ '}{qr_info?.first_name}{' '}{qr_info?.last_name}</b>
                </div>
                <div className={"flex justify-content-center"}>
                    <b className={"text-lg"}>สร้างเมื่อ: {moment(qr_info?.created_at).format("DD/MM/YYYY HH:mm")}</b>
                </div>
                <div className={"flex justify-content-center"}>
                    <b className={"text-lg"}>จำนวนเงินสร้าง: {qr_info?.request_amount} บาท</b>
                </div>
                <div className={"flex justify-content-center"}>
                    <b className={"text-lg text-red"}>จำนวนเงิน QR: {qr_info?.actual_amount} บาท</b>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant={"danger"} onClick={() => OnClose()}>ปิด</Button>
            </Modal.Footer>
        </Modal>
    )

}
export default function CheckCustomerQRPayment() {

    const currentDate = new Date()

    const {
        register: registerCustomerQr, handleSubmit: handleSubmitCustomerQr, errors: errorsCustomerQr,
        setValue: setValueQr, getValues: getValueCustomerQr, formState:formStateCustomerQr,
         watch: watchCustomerQr, reset:resetCustomerQr} = useForm({
             defaultValues:{
                 start_date: moment(new Date().setDate(currentDate.getDate()-7)).format("YYYY-MM-DD"),
                 end_date: moment(currentDate).format("YYYY-MM-DD")
             }, shouldUnregister:false
         }
     )

    const [isLoading, setIsLoading] = useState(false)
    const [workOrderData, setWorkOrderData] = useState([])
    const [showQrModal, setShowQrModal] = useState(false)
    const [qrModalInfo, setQrModalInfo] = useState()
    const [searchConditions, setSearchCondition] = useState([{label:"PAYONEX", value:"payonex"}, {label:"HENGPAY68", value:"hengpay68"}])
    const [currentPage, setCurrentPage] = useState(1);
    const [lastElementId, setLastElementId] = useState("")

    const submitCustomerQr = async (formData) =>{
        // console.log("submitCustomerQr formData: ", formData)

        if(lastElementId !== ""){
            formData.last_element_id = workOrderData[workOrderData.length-1].id
        }

        try {
            setIsLoading(true)
            const response = await api_work_order.GATEWAY_SEARCH(formData)
            // console.log("submit customer qr response: ", response)
            const {data} = response.data
            // console.log("submit customer qr response response: ", data)
            const {work_orders} = data
            // console.log("submit customer qr response work order: ", work_orders)
            if(work_orders !== null){
                setWorkOrderData(work_orders)
                // setValueQr("last_element_id", work_orders[work_orders.length-1].id)
            }else{
                setWorkOrderData(work_orders)
                // setValueQr("last_element_id", "")
            }

        }catch (e){
            console.log("submit customer qr error : ", e)
        }finally {
            setIsLoading(false)
        }
    }

    const clearData = ()=>{
        resetCustomerQr();
        setWorkOrderData([])
    }

    const enableQrModal = (wrd)=>{
        // console.log("wrd: ", wrd)
        setQrModalInfo({
            qr_code:wrd.qr_code,
            qr_type: getValueCustomerQr("type"),
            request_amount: wrd.request_amount,
            actual_amount: wrd.actual_amount,
            created_at: wrd.created_at,
            object_id: wrd.id,
            user_id: wrd.user_id,
            first_name:wrd.first_name,
            last_name: wrd.last_name,
            transaction_uuid: wrd.transaction_uuid,
            from_bank_name: wrd.from_bank_name,
            from_bank_account_no: wrd.from_bank_account_no,
            invoice_number:wrd.invoice_number,
        })
        setShowQrModal(true)
    }

    const handlePrevPage  = ()=>{
        // console.log("go to prev page: ", lastElementId)
        setCurrentPage(currentPage-1)
        setValueQr("last_element_id", getValueCustomerQr("prev_element_id"))
    }

    const handleNextPage  = ()=>{
        // console.log("go to next page", lastElementId)
        setCurrentPage(currentPage+1)
        setValueQr("last_element_id", workOrderData[workOrderData.length-1].id)
    }

    useEffect(() => {
        // console.log("current page update to: ", currentPage)
        if(workOrderData.length > 0){
            // setValueQr("prev_element_id", workOrderData[0].id)
            // setValueQr("last_element_id", workOrderData[workOrderData.length-1].id)
            // console.log("prev element id update to: ", workOrderData[0].id)
            // console.log("last element id update to: ", workOrderData[workOrderData.length-1].id)
            handleSubmitCustomerQr(submitCustomerQr)()
        }

    }, [currentPage]);


    return(
        <>
            <ModalQr show={showQrModal} qr_info={qrModalInfo} OnClose={()=>setShowQrModal(false)}/>
        <Card>
            <Card.Header className={"bg-gradient-danger"}>ตรวจสอบรายการชำระผ่าน QR</Card.Header>
            <Card.Body>
                <Form onSubmit={handleSubmitCustomerQr(submitCustomerQr)}>
                    <Form.Row className="col-12">
                        <Col>
                            <Form.Group className="col-md-12">
                                <Form.Label>พิมพ์ค้นหาลูกค้า</Form.Label>
                                <Form.Control as="input"
                                              id="keyword"
                                              name="keyword"
                                              placeholder="eg. ufyu88xxxx/ahag2xxx, ชื่อ - นามสกุล, เบอร์โทรศัพท์, เลขที่บัญชี"
                                              ref={registerCustomerQr({required:true})}
                                              isInvalid={!!errorsCustomerQr.keyword}
                                >
                                </Form.Control>
                                {errorsCustomerQr && <Form.Control.Feedback type="invalid" tooltip>กรุณาระบุข้อมูลที่ใช้ค้นหา</Form.Control.Feedback>}
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="col-md-12">
                                <Form.Label>รูปแบบQR</Form.Label>
                                <Form.Control
                                    ref={registerCustomerQr}
                                    as={"select"}
                                    name={"type"}
                                    custom
                                >
                                    <option value="">เลือกเงื่อนไขการค้นหา</option>
                                    {
                                        searchConditions?.map(({ label, value }, index) =>
                                            <option
                                                key={value}
                                                value={value}
                                            >
                                                {label}
                                            </option>
                                        )
                                    }
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="col-md-12">
                                <Form.Label>ระบุวันที่เริ่มต้น</Form.Label>
                                <Form.Control type="date"
                                              id="start_date"
                                              name="start_date"
                                              placeholder="2020-09-10"
                                              required
                                              ref={registerCustomerQr({ required: true })}
                                >
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="col-md-12">
                                <Form.Label>ระบุวันที่สิ้นสุด</Form.Label>
                                <Form.Control type="date"
                                              id="end_date"
                                              name="end_date"
                                              placeholder="2020-11-12"
                                              required
                                              ref={registerCustomerQr({ required: true })}
                                >
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Form.Row>
                    <Form.Row>
                        <Col>
                            <Form.Group controlId="validation01">
                                {
                                    (isLoading) ?
                                        (
                                            <Button variant="primary" disabled={ isLoading }>
                                                <Spinner
                                                    as="span"
                                                    animation="grow"
                                                    size="sm"

                                                />ค้นหา</Button>
                                        ) : (<Button variant="primary" type={"submit"}>
                                                ค้นหา</Button>
                                        )
                                }
                                { ' ' }<Button variant="secondary"
                                               onClick={ () => clearData() }
                            >ล้างค่า</Button>
                            </Form.Group>
                        </Col>
                        {/*<Pagination className={ "ml-auto" }>*/}
                        {/*    <Pagination.Prev onClick={ handlePrevPage }/>*/}
                        {/*    <Pagination.Item active={ true }>*/}
                        {/*        { currentPage }*/}
                        {/*    </Pagination.Item>*/}
                        {/*    <Pagination.Next onClick={ handleNextPage }/>*/}
                        {/*</Pagination>*/}
                    </Form.Row>
                    <Form.Row className="col-md-12">
                        {/*<Form.Group className="col-md-12">*/}
                        {/*    <Fade in={ true }>*/}
                                {/*<TableSearchCustomer customerSearchList={ searchData } editCustomerData={ getCustomerHistory }/>*/}
                        {/*    </Fade>*/}
                        {/*</Form.Group>*/}
                    </Form.Row>
                </Form>

                {
                    (workOrderData)?
                        <Pagination className={"ml-auto mt-3"}>
                            <Pagination.Prev onClick={handlePrevPage} disabled={currentPage === 1}/>
                            <Pagination.Item active={true}>
                                {currentPage}
                            </Pagination.Item>
                            <Pagination.Next onClick={handleNextPage}  disabled={workOrderData.length !== 100}/>
                        </Pagination>:<></>
                }

                <Table>
                    <thead>
                    <tr className={"text-center"}>
                        {/*<th>TXN ID</th>*/}
                        <th>
                            ยูสเซอร์ ID<br/>
                            ชื่อ - นามสกุล
                        </th>
                        {/*<th>ชื่อ - นามสกุล</th>*/}
                        <th>โอนจากธนาคาร</th>
                        <th>วันที่ทำรายการ</th>
                        {/*<th>จำนวนเงินที่กรอก</th>*/}
                        <th>จำนวนเงินใน QR</th>
                        <th>สถานะ</th>
                        <th>ประเภท QR</th>
                        {/*<th>QR ของ</th>*/}
                        <th>รูปภาพ QR</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        workOrderData?.map(wrd =>{
                            return(
                                <tr key={wrd.id} className={"text-center"}>
                                    {/*<td>{wrd.id}</td>*/}
                                    <td>
                                        {wrd.user_id}<br/>
                                        {wrd.first_name} {wrd.last_name}
                                    </td>
                                    <td>
                                        {wrd.from_bank_name}<br/>
                                        {wrd.from_bank_account_no}
                                    </td>
                                    <td>{moment(wrd.created_at).format("DD/MM/YYYY HH:mm")}</td>
                                    {/*<td>{wrd.request_amount}</td>*/}
                                    <td>{wrd.actual_amount}</td>
                                    <td>{wrd.status}</td>
                                    <td>{getValueCustomerQr("type")}</td>
                                    {/*<td>*/}
                                    {/*    <OverlayTrigger*/}
                                    {/*        placement="bottom"*/}
                                    {/*        overlay={renderTooltip(wrd.pay_url)}*/}
                                    {/*    >*/}
                                    {/*        <b>{wrd.pay_url.substring(0,20)}...</b>*/}
                                    {/*    </OverlayTrigger>*/}
                                    {/*</td>*/}

                                    <td>
                                        <Button onClick={() => enableQrModal(wrd)}>
                                            <i className="fas fa-qrcode"/>
                                        </Button>
                                    </td>
                                </tr>
                            )
                        })
                    }
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
        </>
    )
}