import React, {useCallback, useMemo, useState} from 'react'
import moment from 'moment'
import {Badge, Button, Form, OverlayTrigger, Spinner, Table, Tooltip} from 'react-bootstrap'
import {LogoWithAccountNoSimple} from "../banks_logo";
import api from "../../api/customer";
import ModalCustomerLogs from "./ModalCustomerLogs";
import GlobalTable from "./GlobalTable";
import {useRecoilState} from "recoil";
import {notificationsObjectRecoilState} from "../../store/atoms";
import ModalNewUser from "./ModalNewUser";
import ModalAffiliate from "./ModalAffiliate";

const generateStatus = ( status ) => {
    if ( status === "normal" ) {
        return ( <td style={{"width":"2%", verticalAlign:"middle"}}><h6><Badge variant="success">ปกติ</Badge>{' '}</h6></td> )
    } else if ( status === "waiting" ) {
        return ( <td style={{"width":"2%", verticalAlign:"middle"}}><Spinner animation="grow" variant="primary"/></td> )
    } else if ( status === "blacklist" ) {
        return ( <td style={{"width":"2%", verticalAlign:"middle"}}><h6><Badge variant="warning">มิจฉาชีพ</Badge>{' '}</h6></td> )
    } else {
        return ( <td style={{"width":"2%", verticalAlign:"middle"}}><h6><Badge variant="danger">ไม่สำเร็จ</Badge>{' '}</h6></td> )
    }
}

const trColorRow = ( channel, verified ) => {
    //console.log("channel, verified", channel, verified)
    if ( verified === false ) {
        //console.log("non-verify")
        return "table-danger"
    }

    if ( channel === "registered_by_customer" ) {
        return "table-info"
    } else {
        return ""
    }
}

const TableSearchCustomerProps = (props)=>{
    const {data, inquiryCustomerLog, copyUserIdOnly, editCustomerData, copyObjectIdOnly, copyToClipboard} = props

    if(!data){
        return (<></>)
    }

    return (
        <Table responsive hover size="md" className="text-center align-items-baseline">
        <thead>
        <tr data-aos="fade-down">
            <th>#</th>
            <th>วันที่สมัคร</th>
            <th>รายละเอียดข้อมูลลูกค้า</th>
            <th>สถานะ user</th>
            <th>ธนาคารหลัก</th>
            <th>บัญชีฝากเข้า</th>
            <th>MN Site</th>
            <th>หมายเหตุ</th>
        </tr>
        </thead>
        <tbody className={"align-middle"}>
        {
            data?.map(({
                                           id,
                                           created_at,
                                           user_id,
                                           first_name,
                                           last_name,
                                           warning_status,
                                           status,
                                           bank_accounts,
                                           main_bank_short_name,
                                           main_bank_account_name,
                                           main_bank_account_no,
                                           sub1_bank_account_name,
                                           sub1_bank_account_no,
                                           sub2_bank_account_name,
                                           sub2_bank_account_no,
                                           mobile_no,
                                           comment,
                                           agents,
                                           agent_name,
                                           username,
                                           channel,
                                           verified,
                                           agent_login_name,
                                           money_site_name,
                                           deposit_inquiry_bank_account_no,
                                           deposit_inquiry_bank_account_name,
                                           deposit_inquiry_bank_name,
                                           deposit_inquiry_bank_short_name,
                                           deposit_inquiry_bank_code,
                                           new_customer,
                                       }, index ) =>

                <tr key={index + 1} className={
                    trColorRow( channel, verified )
                } id={id} style={{ "cursor": "pointer" }}>
                    <td style={{width:"6%", margin: "2px", verticalAlign:"middle"}}>
                        <OverlayTrigger
                            key={"user-id-tip"}
                            placement={"bottom"}
                            overlay={
                                <Tooltip id={`tooltip-id`}>
                                    <strong>กดเพื่อคัดลอกเฉพาะ USER ID</strong>
                                </Tooltip>
                            }
                        >
                            <Button variant={"danger"} size="sm" className={"mr-2"} onClick={() => copyUserIdOnly( user_id )}>
                                <i className="fas fa-copy"/>
                            </Button>
                        </OverlayTrigger>
                        <OverlayTrigger
                            key={"customer-logs-tip"}
                            placement={"bottom"}
                            overlay={
                                <Tooltip id={`tooltip-customer-logs`}>
                                    <strong>ตรวจสอบประวัติการแก้ไขข้อมูล</strong>
                                </Tooltip>
                            }
                        >
                            <Button variant={"primary"} size="sm" className={"mr-2"} onClick={()=>inquiryCustomerLog(id, user_id, first_name, last_name)}>
                                <i className="fas fa-list-ol"/>
                            </Button>
                        </OverlayTrigger>
                        {
                            new_customer &&
                            <OverlayTrigger
                                key={"new-customer-tip"}
                                placement={"bottom"}
                                overlay={
                                    <Tooltip id={`tooltip-new-customer`}>
                                        <strong>ลูกค้าใหม่</strong>
                                    </Tooltip>
                                }>
                                <Button variant={"success"} size="sm" className={"mr-2"}>
                                    <i className="fas fa-user"/>
                                </Button>
                            </OverlayTrigger>
                        }
                        <OverlayTrigger
                            key={"id-tip"}
                            placement={"bottom"}
                            overlay={
                                <Tooltip id={`tooltip-id`}>
                                    <strong>{id}</strong>
                                </Tooltip>
                            }
                        >
                            <Button variant={"warning"} size="sm" className={"mr-2"} onClick={() => copyObjectIdOnly( id )}>
                                <i className="fas fa-key"/>
                            </Button>
                        </OverlayTrigger>
                    </td>
                    {/* <td><h6>{created_at}</h6></td> */}
                    <td onClick={() => editCustomerData( data[ index ], true )} style={{"width":"3%", verticalAlign:"middle"}}>
                        <h6>{moment( created_at ).format( 'DD/MM/YYYY HH:mm:ss' )}</h6></td>
                    <td onClick={() => copyToClipboard( username, mobile_no, agent_name, agent_login_name, agents )} style={{"width":"5%",verticalAlign:"middle"}} >
                        <p>{<b className="text-primary">{user_id} </b>}</p>
                        <p> {first_name}{' '}{last_name} </p>
                        <p>{mobile_no} </p>

                    </td>
                    {
                        generateStatus( status )
                    }
                    <td onClick={() => editCustomerData( data[ index ], true )} style={{"width":"5%", verticalAlign:"middle", alignItems:"center"}} >
                        {/*<h6>{main_bank_account_name}</h6>*/}
                        <div>
                            <LogoWithAccountNoSimple
                                bankShortName={main_bank_short_name}
                                bankFullName={main_bank_account_name}
                                bankName={main_bank_account_name}
                                accountNo={main_bank_account_no}
                                workOrderType={"deposit"}
                                workOrderSubType={""}
                                column={"1"}
                            />
                        </div>
                    </td>
                    <td onClick={() => editCustomerData( data[ index ], true )} style={{"width":"5%", verticalAlign:"middle"}}>
                        <div className={"align-items-center"}>
                            <LogoWithAccountNoSimple
                                bankShortName={deposit_inquiry_bank_short_name}
                                bankFullName={deposit_inquiry_bank_account_name}
                                bankName={deposit_inquiry_bank_account_name}
                                accountNo={deposit_inquiry_bank_account_no}
                                workOrderType={"deposit"}
                                workOrderSubType={""}
                                column={"1"}
                            />
                        </div>
                    </td>
                    <td onClick={() => editCustomerData( data[ index ], true )} style={{"width":"5%", verticalAlign:"middle"}}>
                        <h6>
                            {money_site_name ?
                                ( <b>{money_site_name}</b> ) : ( <b>-</b> )
                            }
                        </h6>
                    </td>
                    <td onClick={() => editCustomerData( data[ index ], true )} style={{"width":"5%", verticalAlign:"middle"}}>
                        <h6>{comment || "-"}</h6></td>
                    {/*{generateNewCustomer(new_customer, index)}*/}
                </tr>
            )
        }
        </tbody>
        </Table>
        )

}

export default function TableSearchCustomer ( props ) {
    // const dispatch = useGlobalStore()[ 1 ];
    const { customerSearchList, editCustomerData } = props;
    const [notificationObject, setNotificationObject]= useRecoilState(notificationsObjectRecoilState)

    const copyToClipboard = ( user_id, mobile_no, agent_name, agent_login_name, agents ) => {
        const agentObject = agents.find( agent => agent.username === user_id )
        const agents_login_name = agentObject.agent_login_name


        if ( agentObject.customer_message === "" || agentObject.customer_message === null || agentObject.customer_message === undefined ) {
            let ufaLink = ""
            switch ( agents_login_name ) {
                case "luckygame78":
                    ufaLink = "https://luckygame78.com"
                    break
                case "ufaclicks":
                    ufaLink = "https://ufaclicks.com"
                    break
                case "zstar88":
                    ufaLink = "https://zstar88.com"
                    break
                default:
                    ufaLink = process.env.REACT_APP_UFA_LINK
            }

            if ( user_id.startsWith( "ufyua884" ) ) {
                ufaLink = process.env.REACT_APP_UFA_LINK2
            }

            if ( process.env.REACT_APP_UFA_TWO_LINK === "true" ) {
                ufaLink = `${process.env.REACT_APP_UFA_LINK}
  ${process.env.REACT_APP_UFA_LINK2}`
            }

            let gclubLink = process.env.REACT_APP_GCLUB_LINK
            if ( process.env.REACT_APP_GCLUB_TWO_LINK === "true" ) {
                gclubLink = `${process.env.REACT_APP_GCLUB_LINK}
  ${process.env.REACT_APP_GCLUB_LINK2}`
            }


            if ( agent_name === "ufa" ) {
                navigator.clipboard.writeText( `
      🤩⭐<<< สมาชิกใหม่ >>⭐🤩
 ID : ${user_id}
 Pass : aa${mobile_no}
 ❗️ID:  ${process.env.REACT_APP_UFA_PREFIX}❗️
 ❗Pass : เอ เอ และ ตามด้วยเลข❗️
 +++ทางเข้าเว็บเดิมพันออนไลน์+++
 ${ufaLink}
 🎉ขอให้คุณลูกค้าโชคดีและเฮงๆรวยๆนะคะ Enjoyค่ะ 🤩💰
 ` )
            } else {
                const mobileLength6 = mobile_no.substr( 0, 6 )
                navigator.clipboard.writeText( `
      ✨<<<สมาชิกใหม่ >>✨
      (การใส่ยูสเซอร์ เเละพาสเวิร์ด เเนะนำให้พิมพ์ห้ามก็อปปี้วางนะคะ)
      ID : ${user_id}
      Password : aa${mobileLength6}
  ❗️ID: ${process.env.REACT_APP_GCLUB_PREFIX}
  ❗️Pass : เอ เอ และ ตามด้วยเบอร์โทร 6 ตัวแรก❗️  
  ++ทางเข้าเว็บเดิมพันออนไลน์++
  ${gclubLink}
  🎉ขอให้คุณลูกค้าโชคดีและเฮงๆรวยๆนะคะ Enjoyค่ะ 🤩💰
` )
            }
        } else {
            navigator.clipboard.writeText( agentObject.customer_message )
        }

        setNotificationObject({
            text: `คัดลอกข้อมูลลูกค้า ${user_id} สำเร็จ`, type: "success", icon:<i className={`far fa-check-circle btn-success`}/>
        })
    };

    const copyUserIdOnly = useCallback(( user_id ) => {
        navigator.clipboard.writeText( user_id )
        setNotificationObject({
            text: `คัดลอกเฉพาะ UserId ${user_id} แล้ว`, type: "success", icon:<i className={`fas fa-check-circle`}/>
        })
    },[])

    const copyObjectIdOnly = ( id ) => {
        navigator.clipboard.writeText( id )
        setNotificationObject({
            text: `คัดลอก ${id} `, type: "success", icon:<i className={`fas fa-check-circle`}/>
        })
    }

    const [customerTitleModal, setCustomerTitleModal] = useState("ตรวจสอบการแก้ไขข้อมูลลูกค้า")
    const [customerLogs, setCustomerLogs] = useState([])

    const customerLogsMemo = useMemo(()=>{
        return customerLogs
    },[customerLogs])

    const [customerLogsModal, setCustomerLogsModal] = useState(false)

    const inquiryCustomerLog = useCallback(async (objId, user_id, first_name, last_name) => {
        console.log("inquiryCustomerLog : ", objId, user_id, first_name, last_name)
        try {
            const response = await api.INQUIRY_CUSTOMER_LOG(
                {
                    id: objId,
                }
            );
            const { data } = response.data
            //console.log("inquiry customer logs : ", data)
            if (data === null){
                setNotificationObject({
                    text: `ไม่พบข้อมูลการแก้ไขข้อมูลลูกค้า`, type: "error", icon:<i className={`fas fa-times-circle bg-danger`}/>
                })
                return
            }
            setCustomerTitleModal(`ตรวจสอบการแก้ไขข้อมูลลูกค้า ${user_id} ${first_name} - ${last_name}`)
            let dataConvert = []
            data.map((element)=>{
                //console.log("customer logs element : ", {...element})
                delete element.id
                delete element.customer_id

                element.updated_at = moment(element.updated_at).format( "DD/MM/YYYY HH:mm:ss" )
                dataConvert.push(element)
            })
            const dataConvertTransformed = dataConvert.map(({ type, path, from, to, updated_by, updated_at }) => (
                { ประเภท: type, ค่าที่อัพเดท: path, ค่าเดิม: from, ค่าใหม่: to, แก้ไขโดย: updated_by, แก้ไขเมื่อ: updated_at }
            ));
            //console.log("dataConvertTransformed : ", data)
            setCustomerLogs(dataConvertTransformed);
            openModalCustomerLogs()
        } catch (error) {
            console.log("inquiry customer logs error : ", error);
        }
    },[])

    const [modalInfo, setModalInfo] = useState({});
    const [isOpenNewUserModal, setIsOpenNewUserModal] = useState(false);

    // const openNewUserModal = (customerData, flag) => {
    //     setModalInfo(customerData);
    //     setIsOpenNewUserModal(flag);
    // };
    const handleCloseModal = () => {
        setIsOpenNewUserModal(false)
    };

    // const generateNewCustomer = ( new_customer , index) => {
    //     if ( new_customer ) {
    //         return ( <td onClick={() => openNewUserModal( customerSearchList[ index ], true )} style={{"width":"5%", verticalAlign:"middle"}}><h6><Badge variant="success">ใช่</Badge>{' '}</h6></td> )
    //     } else {
    //         return ( <td style={{"width":"5%", verticalAlign:"middle"}}><h6><Badge variant="danger">ไม่ใช่</Badge>{' '}</h6></td> )
    //     }
    // }

    const openModalCustomerLogs = () => {
        setCustomerLogsModal(true);
    };

    const closeModalCustomerLogs = () => {
        setCustomerLogsModal(false);
    };

    return (
        <>
        <ModalCustomerLogs titleModal={customerTitleModal}
                           openModal={customerLogsModal}
                           footerModal={
                                   <Button variant="secondary" onClick={closeModalCustomerLogs}>ปิด</Button>
                           }
                           onClose={closeModalCustomerLogs}>
            <Form.Group>
                <Form.Label>ประวัติการแก้ไขข้อมูล</Form.Label>
                {/*<GlobalTable tableData={[{name:"Pipe", surname:"Test"}]}/>*/}
                <GlobalTable tableData={customerLogsMemo}/>
            </Form.Group>
        </ModalCustomerLogs>
        <ModalNewUser
            modalData={modalInfo}
            isOpenModal={isOpenNewUserModal}
            onClose={handleCloseModal}
        />
            <TableSearchCustomerProps data={customerSearchList}
                                      inquiryCustomerLog={inquiryCustomerLog} copyUserIdOnly={copyUserIdOnly}
                                      editCustomerData={editCustomerData} copyObjectIdOnly={copyObjectIdOnly} copyToClipboard={copyToClipboard}/>
        </>
    )
}
