import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button, Col, Form, InputGroup } from 'react-bootstrap'
import moment from "moment";

export default function EditProspectCustomer ( { data, onSubmitForm, onClose, alertError } ) {
  const {
    id,
    done_contact,
    remark,
    created_at,
  } = data;

  const { register, handleSubmit, watch, errors, setValue, getValues } = useForm( {
    defaultValues: { ...data }, criteriaMode: "all"
  } );

  const [ buttonIsLoading, setButtonIsLoading ] = useState( false );
  const [ disableButton, setDisableButton ] = useState( false );

  const [ validated, setValidated ] = useState( false );

  const onSubmit = editData => {
    setButtonIsLoading( true );

    onSubmitForm(
      {
        updateProspect: {
          ...editData,
          id: id,
        }
      }
    );
  };

  useEffect( () => {
    if ( alertError !== "" ) {
      setButtonIsLoading( false )
    }
  }, [ alertError ] )

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit( onSubmit )}>
      <Form.Row>
        <Form.Group as={Col} md="3">
          <Form.Label>วันที่สมัคร</Form.Label>
          <Form.Control
            required
            type="text"
            name="created_at"
            readOnly
            placeholder="DD/MM/YYYY"
            defaultValue={
              ( created_at ) ?
                moment( created_at ).format( "DD/MM/YYYY" )
                : ( "" )
            }
          />
        </Form.Group>
        <Form.Group as={Col} md="3" controlId="validationFormik02">
          <Form.Label>ชื่อ</Form.Label>
          <Form.Control
            required
            type="text"
            name="first_name"
            placeholder="ยูฟ่า"
            ref={register( { required: true } )}
            isInvalid={!!errors.first_name}
            readOnly
          />
          <Form.Control.Feedback type="invalid" tooltip>
            กรุณากรอกชื่อ
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="3" controlId="validationFormik03">
          <Form.Label>นามสกุล</Form.Label>
          <Form.Control
            required
            type="text"
            name="last_name"
            placeholder="ฟีเวอร์"
            ref={register( { required: true } )}
            isInvalid={!!errors.last_name}
            readOnly
          />
          <Form.Control.Feedback type="invalid" tooltip>
            กรุณากรอกนามสกุล
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="3" controlId="validationFormik04">
          <Form.Label>เบอร์โทรศัพท์</Form.Label>
          <Form.Control
            required
            type="text"
            placeholder="eg. 081xxxxxxx"
            name="mobile_no"
            ref={register( {
              required: true,
              pattern: /^\d{10}$/
            } )}
            isInvalid={!!errors.mobile_no}
            readOnly
          />
          <Form.Control.Feedback type="invalid" tooltip>
            กรุณาระบุเบอร์โทรศัพท์
          </Form.Control.Feedback>
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} md="4" controlId="validation24">
          <InputGroup>
            <Form.Check
              type="switch"
              name="done_contact"
              custom
              label="สถานะการติดต่อ"
              default={done_contact}
              ref={register}
            />
            <Form.Control.Feedback type="invalid" tooltip>
              กรุณาเลือกสถานะการติดต่อ
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} md="12" lg="12" controlId="validation26">
          <Form.Label>หมายเหตุ</Form.Label>
          <Form.Control
            type="text"
            as="textarea" rows={3}
            name="remark"
            defaultValue={remark}
            ref={register}
            placeholder="eg. หมายเหตุ"
          />
        </Form.Group>
      </Form.Row>
      {buttonIsLoading
        ? ( <Button>
          <i className="fas fa-circle-notch fa-spin"/> ระบบกำลังดำเนินการ
        </Button> )
        : <>
          {'  '}<Button type="submit" variant="primary" disabled={disableButton}>บันทึกการแก้ไข</Button>
          {'  '}<Button variant="secondary" onClick={onClose}>ปิด</Button>
        </>
      }
    </Form>
  )
}