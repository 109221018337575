import React from 'react'
import { Alert, Modal } from 'react-bootstrap'
import EditABNormalStatement from "./EditABNormalStatement";

export default function ModalSearchABNormalStatement ( props ) {
    const { data, isOpenModal, onSave, onClose, callBackSearch, alertError } = props

    return (
        <>
            <Modal show={isOpenModal} size="xl">
                <Modal.Header>
                    <Modal.Title className={"font-bold"}>ตรวจสอบรายการธนาคาร {data?.bank_name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        ( alertError !== "" ) ?
                            ( <Alert variant={"danger"}>{alertError}</Alert> ) : ( <></> )
                    }
                    <EditABNormalStatement data={data} onSubmitForm={onSave} onClose={onClose} alertError={alertError}/>
                </Modal.Body>
            </Modal>
        </>
    )
}