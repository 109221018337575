import AES from 'crypto-js/aes'
import Crypto from 'crypto-js'
import store from 'store'
import config from '../config'

const UFA_ACCESS_TOKEN = 'ufa_access_token'
const UFA_ACCESS_ROLE = 'ufa_access_role'
const UFA_ACCESS_NAME = 'ufa_access_name'
const UFA_ACCESS_USERNAME = 'ufa_access_username'
// const DIANCAI_TUTORIAL = 'diancai_tutorial'

// const SECRET = 'UFAFEVERADMINMOTHERFUCKER4444'

// const pages = [
//   'all',
//   'orders',
//   'menus_main',
//   'menus_create',
//   'shop-profile',
//   'qr',
// ]

const getSecret = () => {
  const envSecret = config.UFA_ACCESS_TOKEN
  return envSecret
}

const encrypt = (value) => {
  const valueJSON = JSON.stringify(value)
  return AES.encrypt(valueJSON, getSecret()).toString()
}

const decrypt = (value) => {
  try {
    value = value ? AES.decrypt(value, getSecret()) : null
    value = value ? JSON.parse(value.toString(Crypto.enc.Utf8)) : null
    return value
  } catch (e) {
    store.clearAll()
  }
}

const getAccessToken = () => {
  let value = store.get(UFA_ACCESS_TOKEN)
  return decrypt(value)
}

const getAccessRole = () => {
  let value = store.get(UFA_ACCESS_ROLE)
  return decrypt(value)
}

const getAccessName = () => {
  let value = store.get(UFA_ACCESS_NAME)
  return decrypt(value)
}

const getAllAccess = () =>{
  //console.log("get login access all")
  return {
    name: decrypt(store.get(UFA_ACCESS_NAME)),
    role: decrypt(store.get(UFA_ACCESS_ROLE)), 
    token: decrypt(store.get(UFA_ACCESS_TOKEN))
  }
}

const getUsername = () =>{
  //console.log("get username access")
  return decrypt(store.get(UFA_ACCESS_USERNAME))
}

const getRedirect = () => {
  return decrypt(store.get("redirect"))
}
// const getRefreshToken = () => {
//   let value = store.get(DIANCAI_REFRESH_TOKEN)
//   return decrypt(value)
// }
// const getExpireAt = () => {
//   let value = store.get(DIANCAI_EXPIRE_AT)
//   return decrypt(value)
// }
// const getRole = () => {
//   let value = store.get(DIANCAI_ROLE)
//   return decrypt(value)
// }
// const getLocale = () => {
//   let value = store.get(DIANCAI_LOCALE)
//   return decrypt(value)
// }
// const getLockedUntil = () => {
//   let value = store.get(DIANCAI_LOCKED_UNTIL)
//   return decrypt(value)
// }
// const getOrderLayout = () => {
//   let value = store.get(DIANCAI_ORDER_LAYOUT)
//   return decrypt(value)
// }
// const getTutorialConfigByPage = (page) => {
//   let value = store.get(DIANCAI_TUTORIAL + '_' + page)
//   return decrypt(value)
// }
// const getTutorialConfig = () => {
//   let result = {}
//   pages.forEach((page) => {
//     result[page] = getTutorialConfigByPage(page) ?? false
//   })
//   return result
// }

const setAllAccess = (data) =>{
  //console.log("set login access all :", data.token)
  store.set(UFA_ACCESS_TOKEN, encrypt(data.token));
  store.set(UFA_ACCESS_ROLE, encrypt(data.role));
  store.set(UFA_ACCESS_NAME, encrypt(data.name));
}

const setAccessToken = (value) => {
  store.set(UFA_ACCESS_TOKEN, encrypt(value))
}

const setAccessRole = (value) => {
  store.set(UFA_ACCESS_ROLE, encrypt(value))
}

const setAccessName = (value) => {
  store.set(UFA_ACCESS_NAME, encrypt(value))
}

const setRedirect = (value) => {
  store.set("redirect", encrypt(value))
}

const setUsername = (value) => {
  store.set(UFA_ACCESS_USERNAME, encrypt(value))
}

const removeAllAccess = () => {
  store.remove(UFA_ACCESS_TOKEN);
  store.remove(UFA_ACCESS_ROLE);
  store.remove(UFA_ACCESS_NAME);
  store.remove(UFA_ACCESS_USERNAME);
}
const removeAccessToken = () => store.remove(UFA_ACCESS_TOKEN)
// const removeTutorialConfig = () => {
//   pages.forEach((page) => {
//     store.remove(DIANCAI_TUTORIAL + '_' + page)
//   })
// }
const removeAll = () => {
  removeAccessToken()
}

export default {
  getAccessToken,
  getAccessRole,
  getAccessName,
  getAllAccess,
  getRedirect,
  getUsername,
  removeAccessToken,
  removeAllAccess,
  removeAll,
  setAllAccess,
  setAccessToken,
  setAccessRole,
  setAccessName,
  setRedirect,
  setUsername,
}
