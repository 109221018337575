import axios from '../client/axios'
import config from '../config/index'

// //console.log('config ', config);
export default {
  LOGIN_API: ({ username, password, partner, token}) => {
    //console.log("request login api: ", { username, password, partner, token})
    return axios({
      headers: {},
      method: 'POST',
      url: `${config.LOGIN_API}`,
      data: {
        username: username, password: password, partner: partner, token: token,
      }
    })
  },
  GET_QR_AUTH: () => {
    //console.log("request login with qr auth api")
    return axios({
      // headers: {},
      method: 'GET',
      url: `${config.GET_QR_AUTH}`,
      data: {
        // username: username, password: password, partner: partner,
      }
    })
  },
  CONFIRM_QR_AUTH: ({ token, username }) => {
    //console.log("request login with qr auth api", token)
    return axios({
      // headers: {},
      method: 'POST',
      url: `${config.CONFIRM_QR_AUTH_V2}`,
      data: {
        token: token,
        username: username
      }
    })
  },
  LOGIN_CHECK: ({ username }) => {
    //console.log("request login api: ", { username})
    return axios({
      headers: {},
      method: 'POST',
      url: `${config.LOGIN_CHECK}`,
      data: {
        username: username,
      }
    })
  },
}