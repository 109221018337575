import {Button, Card, Col, Form} from "react-bootstrap";
import React, {useEffect} from "react";
import api from "../../api/managebank";
import {useForm} from "react-hook-form";
import moment from "moment/moment";
import {useGlobalStore} from "../../store/globalContext";



const dropdownEnable = [
    {innerText:"เปิดใช้งาน", value: "true"},
    {innerText:"ปิดใช้งาน", value: "false"}
]
export default function ManagePerfectPaysConfig() {

    const { register:registerPerfectPays, handleSubmit:handleSubmitPerfectPays, setValue:setValuePerfectPays, getValues:getValuesPerfectPays, trigger:triggerPerfectPays, watch:watchPerfectPays, formState:{isDirty: isDirtyPerfectPays, dirtyFields:dirtyFieldsPerfectPays},reset:resetPerfectPays } = useForm({
        shouldUnregister: false
    });

    const [{ subscribe, banks, status, login_profile }, dispatch] =
        useGlobalStore();

    const getPerfectPaysFromAPI = async ()=>{
        try {
            const response =  await api.GET_PERFECTPAYS_CONFIG()
            const {data} = response.data

            // console.log("get perfectpays config response data: ", data)

            setValuePerfectPays("perfectpays_merchant_id", data.perfectpays_merchant_id)
            setValuePerfectPays("perfectpays_merchant_key", data.perfectpays_merchant_key)
            setValuePerfectPays("perfectpays_callback_deposit_url", data.perfectpays_callback_deposit_url)
            setValuePerfectPays("perfectpays_callback_withdraw_url", data.perfectpays_callback_withdraw_url)
            setValuePerfectPays("perfectpays_updated_at", moment(data.updated_at).format("DD/MM/YYYY HH:mm"))
            setValuePerfectPays("enable", data.enable)
            setValuePerfectPays("perfectpays_qr_transfer_enable", data.perfectpays_qr_transfer_enable)
            setValuePerfectPays("perfectpays_bank_transfer_enable", data.perfectpays_bank_transfer_enable)

        }catch (e) {
            console.log("get vizpay config error: ", e, e.response.data)
        }finally {

        }
    }

    const submitPerfectPaysConfig = async (submitValue)=>{

        // console.log("submitPerfectPaysConfig: ", submitValue)
        let updateObject= {}

        Object.keys(dirtyFieldsPerfectPays).map((e)=>{
            updateObject[e] = getValuesPerfectPays(e)
        })
        // console.log("submitPerfectPaysConfig updateObject: ", updateObject)

        try {

            const response =  await api.UPDATE_PERFECTPAYS_CONFIG(updateObject)
            // console.log("get vizpay config response data : ", response)
            dispatch({
                type: "TOAST2",
                value: {
                    show: true,
                    type: "success",
                    title: `แก้ไขข้อมูล PERFECTPAYS`,
                    message: `แก้ไขข้อมูล PERFECTPAYS สำเร็จ`,
                },
            });
        }catch (e) {
            console.log("get perfectpays config error: ", e, e.response.data)
            dispatch({
                type: "TOAST2",
                value: {
                    show: true,
                    type: "error",
                    title: `แก้ไขข้อมูล PERFECTPAYS ไม่สำเร็จ`,
                    message: `แก้ไขข้อมูล PERFECTPAYS ไม่สำเร็จ ${e.response.data.message}`,
                },
            });
        }finally {
            resetPerfectPays(getValuesPerfectPays())
        }
    }

    const updateEnablePerfectPaysStatus = async (key, checked)=>{
        // console.log("updateEnablePerfectPaysStatus checked: ", checked)
        try {
            const response =  await api.UPDATE_PERFECTPAYS_CONFIG({[key]: checked})
            // console.log("get vizpay config response data : ", response)
            dispatch({
                type: "TOAST2",
                value: {
                    show: true,
                    type: "success",
                    title: `แก้ไขสถานะเปิด/ปิด ใช้งาน PERFECTPAYS`,
                    message: `แก้ไขสถานะเปิด/ปิด ใช้งาน PERFECTPAYS สำเร็จ`,
                },
            });
        }catch (e) {
            console.log("get perfectpays config error: ", e, e.response.data)
            dispatch({
                type: "TOAST2",
                value: {
                    show: true,
                    type: "error",
                    title: `แก้ไขสถานะเปิด/ปิด ใช้งาน PERFECTPAYS`,
                    message: `แก้ไขสถานะเปิด/ปิด ใช้งาน PERFECTPAYS ไม่สำเร็จ ${e.response.data.message}`,
                },
            });
        }finally {
            resetPerfectPays(getValuesPerfectPays())
        }
    }

    useEffect( ()=>{
        void getPerfectPaysFromAPI();
    },[])

    const watchEnable = watchPerfectPays("enable")
    const watchQREnable = watchPerfectPays("perfectpays_qr_transfer_enable")
    const watchBankEnable = watchPerfectPays("perfectpays_bank_transfer_enable")

    return(
        <Card className={"card-indigo"}>
            <Card.Header><i className={"fas fa-qrcode pr-2"}/> จัดการข้อมูลแจ้งลูกค้าของ PERFECTPAYS </Card.Header>
            <Card.Body>
                <Form noValidate onSubmit={handleSubmitPerfectPays(submitPerfectPaysConfig)} id={"perfectpays_form"}>
                    <Form.Row className={"flex"}>
                        <Col className={"col-xs-12 col-md-3"}>
                            <Form.Label>สถานะใช้งาน PERFECTPAYS</Form.Label>
                            <Form.Switch
                                // type="switch"
                                custom
                                id="perfectpays_enable"
                                name="enable"
                                label={watchEnable === true ? "เปิดใช้งาน PERFECTPAYS":"ปิด PERFECTPAYS"}
                                onChange={(e)=>updateEnablePerfectPaysStatus("enable", e.target.checked)}
                                ref={registerPerfectPays}
                            />
                        </Col>
                        <Col className={"col-xs-12 col-md-3"}>
                            <Form.Label>ฝากใช้งาน PERFECTPAYS แบบ QR</Form.Label>
                            <Form.Switch
                                // type="switch"
                                custom
                                id="perfectpays_qr_transfer_enable"
                                name="perfectpays_qr_transfer_enable"
                                label={watchQREnable === true ? "เปิดใช้ QR":"ปิด"}
                                onChange={(e)=>updateEnablePerfectPaysStatus("perfectpays_qr_transfer_enable", e.target.checked)}
                                ref={registerPerfectPays}
                                disabled={watchEnable === false}
                            />
                        </Col>
                        <Col className={"col-xs-12 col-md-3"}>
                            <Form.Label>ฝากใช้งาน PERFECTPAYS แบบ บัญชี</Form.Label>
                            <Form.Switch
                                // type="switch"
                                custom
                                id="perfectpays_bank_transfer_enable"
                                name="perfectpays_bank_transfer_enable"
                                label={watchBankEnable === true ? "เปิดใช้บัญชี":"ปิด"}
                                onChange={(e)=>updateEnablePerfectPaysStatus("perfectpays_bank_transfer_enable", e.target.checked)}
                                ref={registerPerfectPays}
                                disabled={watchEnable === false}
                            />
                        </Col>
                        <Col className={"col-3"}>
                            <Form.Label>แก้ไขล่าสุดเมื่อ</Form.Label>
                            <Form.Control id="perfectpays_updated_at" name={"perfectpays_updated_at"}  type="datatime" ref={registerPerfectPays} readOnly defaultValue={moment(new Date()).format("DD/MM/YYYY HH:mm")}/>
                        </Col>
                    </Form.Row>
                    <Form.Row className={"flex my-2"}>
                        <Col className={"col-6"}>
                            <Form.Label>PerfectPays Merchant ID</Form.Label>
                            <Form.Control id="perfectpays_merchant_id" name={"perfectpays_merchant_id"} ref={registerPerfectPays}/>
                        </Col>
                        <Col className={"col-6"}>
                            <Form.Label>PerfectPays Merchant Keys</Form.Label>
                            <Form.Control id="perfectpays_merchant_key" name={"perfectpays_merchant_key"}  ref={registerPerfectPays}/>
                        </Col>
                    </Form.Row>
                    <Form.Row className={"pt-2"} hidden={watchEnable === false}>
                        <Col className="col-xs-12 col-md-6">
                            <Form.Label>PERFECTPAYS Deposit Callback URL</Form.Label>
                            <Form.Control ref={registerPerfectPays} id="perfectpays_callback_deposit_url" name="perfectpays_callback_deposit_url" defaultValue={""} readOnly></Form.Control>
                        </Col>
                        <Col className="col-xs-12 col-md-6">
                            <Form.Label>PERFECTPAYS Withdraw Callback URL</Form.Label>
                            <Form.Control ref={registerPerfectPays} id="perfectpays_callback_withdraw_url" name="perfectpays_callback_withdraw_url" defaultValue={""} readOnly></Form.Control>
                        </Col>
                    </Form.Row>
                    <Form.Row className={"pt-2"}>
                        <Button type={"submit"} className={"bg-indigo"} disabled={isDirtyPerfectPays === false}>ตั้งค่าจัดการ PERFECTPAYS</Button>
                    </Form.Row>
                </Form>
            </Card.Body>
        </Card>
    )

}