import { Col, Form } from "react-bootstrap";
import LuckyDrawOptions from "./LuckyDrawOptions";
import React from "react";

export const defaultLuckyDrawOptions = {
    "amount": "1",
    "quota": 1,
    "deposit_back_date": 7,
    "image_url": "",
    "user_ids": [],
    "minimum_deposit_amount": 100,
};
export default function LuckyDrawForm ( props ) {
    const { register, errors, control, watch, } = props;

    const periodOptions = [
        { innerText: "รายวัน", value: "daily" },
        { innerText: "รายสัปดาห์", value: "weekly" },
        { innerText: "รายเดือน", value: "monthly" },
        { innerText: "ทั้งหมด", value: "campaign" }
    ];

    return (
        <>
            <Form.Row>
                <Form.Group as={Col} md="3" lg="3" controlId="validation01">
                    <Form.Label>ชื่อเกมชิงโชค</Form.Label>
                    <Form.Control
                        required
                        type="text"
                        name="name"
                        placeholder={"Lucky-Draw 101"}
                        isInvalid={!!errors.name}
                        ref={register( { required: true } )}
                    />
                    <Form.Control.Feedback type="invalid" tooltip>
                        กรุณาใส่ชื่อเกมชิงโชค
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="3" lg="3" controlId="validation02">
                    <Form.Label>คำอธิบายเกมชิงโชค</Form.Label>
                    <Form.Control
                        required
                        type="text"
                        name="description"
                        placeholder={"สุ่ม Lucky-Draw วันนี้ ลุ้นเครดิตฟรีสุงสุดถึง 100,000 บาท!"}
                        isInvalid={!!errors.description}
                        ref={register( { required: true } )}
                    />
                    <Form.Control.Feedback type="invalid" tooltip>
                        กรุณาใส่คำอธิบายเกมชิงโชค
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="3" lg="3" controlId="validation03">
                    <Form.Label>ลิงค์รูปภาพเกมชิงโชค</Form.Label>
                    <Form.Control
                        required
                        type="text"
                        name="image_url"
                        placeholder={"https://d22eqwgf5ils9u.cloudfront.net/lucky/aefe5eba-fbbf-4a8a-9cf1-600713a2f77d-mcity.jpg"}
                        isInvalid={!!errors.image_url}
                        ref={register( { required: true } )}
                    />
                    <Form.Control.Feedback type="invalid" tooltip>
                        กรุณาใส่รูปภาพเกมชิงโชค
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="3" lg="3" controlId="validation06">
                    <Form.Label>เลือกรูปแบบการแจก luckydraw</Form.Label>
                    <Form.Control as="select"
                                  required
                                  name="period_type"
                                  defaultValue=""
                                  isInvalid={!!errors.period_type}
                                  custom
                                  ref={register( { required: true } )}
                    >
                        <option value="">Please Select</option>
                        {
                            periodOptions.map( ( { innerText, value }, index ) =>
                                <option
                                    key={index}
                                    value={value}
                                >
                                    {innerText}
                                </option>
                            )
                        }
                    </Form.Control>
                    <Form.Control.Feedback type="invalid" tooltip>
                        กรุณาเลือกรูปแบบการแจก Lucky Draw
                    </Form.Control.Feedback>
                </Form.Group>
            </Form.Row>
            <LuckyDrawOptions register={register} control={control} watch={watch}/>
        </>
    )

}