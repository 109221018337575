import React, {useState} from 'react'
import {Button, Col, Form, Modal, Spinner} from 'react-bootstrap'
import {useForm} from "react-hook-form";
import {number_format} from "../utils/Number";
import api from "../../api/agents";
import {useGlobalStore} from "../../store/globalContext";

export default function ModalTopupCreditGclub(props) {
    const [{}, dispatch] = useGlobalStore();
    const {data, isOpenModal, onClose, onSave} = props;

    const [validated, setValidated] = useState(false);
    const {register, handleSubmit, watch, errors, setValue, reset, getValues} = useForm({
        defaultValues: {credit_line: ""}, criteriaMode: "all"
    });

    const [loadingButton, setLoadingButton] = useState(false);

    const onSubmit = async registerData => {
        const request = {
            username: data.username, credit_line: parseFloat(registerData.credit_line),
        };
        setLoadingButton(true);
        try {
            await api.ADD_CREDIT_GCLUB(request);
            dispatch({
                type: "TOAST2", value: {
                    show: true, type: 'success', title: `เติมเครดิต gclub`, message: `ทำรายการสำเร็จ`,
                }
            });
        } catch (e) {
            console.log("add credit gclub error ", e);
            dispatch({
                type: "TOAST2", value: {
                    show: true, type: 'danger', title: `เติมเครดิต gclub`, message: `ทำรายการไม่สำเร็จ`,
                }
            });
        } finally {
            setTimeout(() => {
                setLoadingButton(false);
                onSave();
            }, 500);
        }
    }

    return (<>
        <Modal show={isOpenModal} onHide={onClose} size="xl">
            <Modal.Header closeButton>
                <Modal.Title className={"font-bold"}>เติมเครดิต gclub</Modal.Title>
            </Modal.Header>
            <Form noValidate validated={validated} onSubmit={handleSubmit(onSubmit)}>
                <Modal.Body>
                    <p className={"text-lg"}>Username: <span className={"text-red font-bold"}> {data.username} </span>
                    </p>
                    <p className={"text-lg"}>ยอดเครดิตคงเหลือ: <span
                        className={"text-red font-bold"}> {number_format(data.remaining_credit_line, 2)} </span>บาท</p>
                    <Form.Row>
                        <Form.Group as={Col} md="12" controlId="validationCustom01">
                            <Form.Label>จำนวนเครดิตที่ต้องการเติม (บาท)</Form.Label>
                            <Form.Control
                                // className={"text-red"}
                                required
                                type="number"
                                name="credit_line"
                                placeholder="100/200.50"
                                isInvalid={!!errors.credit_line}
                                ref={register({required: true})}
                            />
                            <Form.Control.Feedback type="invalid"
                                                   tooltip>กรุณาระบุจำนวนเครดิต</Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>

                </Modal.Body>
                <Modal.Footer>
                    {/*TODO กดแล้วมี delay ปุ่มหมุนๆ*/}
                    <Button variant="success" type="submit" disabled={loadingButton}>
                        {loadingButton ? (<>
                            <Spinner as="span" animation="border" size="sm" role="status"
                                     aria-hidden="true"/> กำลังดำเนินการ</>) : 'ยืนยันเติมเครดิต'}
                    </Button>
                    <Button variant="secondary" onClick={onClose}>ปิด</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    </>)
}