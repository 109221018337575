import React, {useEffect, useState} from 'react'
import {Badge, Button, Card, Col, Fade, Form, Modal, Pagination, Spinner, Table} from 'react-bootstrap'
import TableSearchCustomer from './TableSearchCustomer'
import {useGlobalStore} from '../../store/globalContext'
import api_blacklist from "../../api/blacklist";
import moment from "moment";
import {number_format} from "../utils/Number";
import api_workorder from "../../api/workorder";

export default function WarningUsers() {
    const [searchButton, setSearchButton] = useState(false);
    const [{login_profile}, dispatch] = useGlobalStore();
    const [isOpenModal, setisOpenModal] = useState(false)
    const [searchData, setSearchData] = useState([]);
    const [modalConfirmValue, setModalConfirmValue] = useState({user_id: "", firstname: "", last_name: ""});
    const [keyword, setKeyword] = useState("")
    const [currentPage, setCurrentPage] = useState(1)
    const [dictLastIndices, setDictLastIndices] = useState({1: ""})
    const [transactions, setTransactions] = useState([])
    const limitPage = 20

    const keySearchInput = async () => {
        //console.log("blacklist search input: ", keyword)
        setSearchButton(true)
        if (keyword.length !== 0 && keyword.length < 4) {
            setSearchData([])
            setSearchButton(false)
            return
        }

        const searchData = {
            // method: "customer_search",
            keyword: keyword, limit: limitPage, last_element_id: dictLastIndices[currentPage]
        }

        try {
            const response = await api_blacklist.SEARCH_BLACK_LIST(searchData);

            const {data} = response.data
            console.log("search blacklist response data: ", data)
            let searchDataList = [];
            if (!data) {
                console.log("search blacklist not found data")
                setSearchData([]);
                setTimeout(() => {
                    setSearchButton(false)
                }, 300)
                return
            }
            data.forEach(element => {

                const customerBankList = element.bank_accounts
                let dataBank = {}
                customerBankList.map((ele, index) => {

                    let bankObj
                    if (index === 0 || ele.is_main_bank_account === true) {
                        bankObj = {
                            main_bank_account_name: ele.bank_account_name,
                            main_bank_account_no: ele.bank_account_no,
                            main_bank_short_name: ele.bank_short_name
                        }
                        dataBank = ({...dataBank, ...bankObj})
                    }

                    if (index === 1) {
                        bankObj = {
                            sub1_bank_account_name: ele.bank_account_name,
                            sub1_bank_account_no: ele.bank_account_no,
                            sub1_bank_short_name: ele.bank_short_name
                        }
                        dataBank = ({...dataBank, ...bankObj})
                    }

                    if (index > 1) {
                        bankObj = {
                            sub2_bank_account_name: ele.bank_account_name,
                            sub2_bank_account_no: ele.bank_account_no,
                            sub2_bank_short_name: ele.bank_short_name
                        }
                        dataBank = ({...dataBank, ...bankObj})
                    }
                    // console.log("data bank :", dataBank)
                })
                // console.log("mapping bank data element: ", element)
                // console.log("mapping bank data array: ", dataArray)
                searchDataList.push({...element, ...dataBank})
            })
            setTimeout(() => {
                setSearchButton(false)
            }, 300)
            setSearchData(searchDataList)

            if (searchDataList) {
                const newIndex = {...dictLastIndices, [currentPage + 1]: searchDataList[searchDataList.length - 1].id}
                setDictLastIndices(newIndex)
            }
        } catch (e) {
            console.log("search blacklist failed");
            setSearchButton(false);
            setSearchData([]);
            dispatch({
                type: "TOAST2",
                value: {
                    show: true,
                    type: 'danger',
                    title: `ค้นหา BLACKLIST`,
                    message: `ค้นหา BLACKLIST ไม่สำเร็จ`,
                }
            });
            return
        }
    }

    const openCustomerModal = (customerData, flag) => {
        //console.log("in function ", customerData, flag);
        setModalConfirmValue(customerData);
        setisOpenModal(flag);
    }

    const closeCustomerModal = () => {
        setisOpenModal(false);
    }

    const unBlacklist = async (modalConfirmValue) => {
        // console.log("unblacklist user id : ", userId)

        const keyword = {
            user_id: modalConfirmValue.user_id,
            first_name: modalConfirmValue.first_name,
            last_name: modalConfirmValue.last_name,
            mobile_no: modalConfirmValue.mobile_no,
            updated_by: login_profile.name
        }

        try {
            const response = await api_blacklist.UN_BLACK_LIST(keyword);
            dispatch({
                type: "TOAST2", value: {
                    show: true,
                    type: 'success',
                    title: `ปลด Blacklist`,
                    message: `ปลด Blacklist user id ${modalConfirmValue.user_id} สำเร็จ`,
                }
            });
        } catch (e) {
            console.log(`unblacklist user id ${modalConfirmValue.user_id} failed`);
            dispatch({
                type: "TOAST", value: {
                    show: true,
                    type: 'danger',
                    title: `ปลด Blacklist`,
                    message: `ขออภัยระบบไม่สามารถทำรายการได้ในขณะนี้`,
                }
            });
            return
        } finally {
            setSearchData([])
            setisOpenModal(false);
        }
    }

    const handleKeyword = () => {

        setCurrentPage(1)
    }

    const handlePrevPage = () => {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1)
        }
    }

    const handleNextPage = () => {
        if (!searchData) {
            return
        }

        const setId = [...new Set(searchData.map(e => e.id))]
        if (setId.length === limitPage) {
            setCurrentPage(currentPage + 1)
        }
    }

    const getThaiWorkOrderType = (type) => {
        switch (type) {
            case "deposit" :
                return ("ฝาก")
            case "withdraw" :
                return ("ถอน")
            case "holding" :
                return ("พัก")
            case "bonus" :
                return ("โบนัส")
            case "lost" :
                return ("คืนยอดเสีย")
            case "manual" :
                return ("เติมมือ")
            default :
                return ("-")
        }
    }

    const renderBadge = (status) => {

        switch (status) {
            case "success":
                return (<Badge variant="success">{"สำเร็จ"}</Badge>)
            case "failed":
                return (<Badge variant="danger">{"ไม่สำเร็จ"}</Badge>)
            case "deleted":
                return (<Badge variant="warning">{"ยกเลิก"}</Badge>)
            case "mapping":
                return (<Badge variant="primary">{"กรุณาจับคู่รายการ"}</Badge>)
            case "pending":
                return (<Badge variant="info">กรุณาตรวจสอบและยืนยันรายการ</Badge>)
            case "unknown":
                return (<Badge variant="dark">กรุณาตรวจสอบรายการ</Badge>)
            default:
                return (<Spinner animation="grow" variant="info"/>)
        }
    }

    const markManualStyle = (channel, work_order_type) => {
        if (work_order_type === "deposit") {
            return {
                backgroundColor: "#A4EDA6"
            }
        } else if (work_order_type === "withdraw") {
            return {
                backgroundColor: "#EDA6A4"
            }
        } else {
            return {}
        }
    };

    const getCustomerHistory = async (props) => {
        //console.log("getCustomerHistory props", props)
        const {user_id, first_name, last_name} = props
        //setCustomerHistoryTable([])
        const userID = user_id
        //console.log("user_id : ", userID)
        if (userID === "" || userID === undefined) {
            return
        }

        const request = {
            user_id: userID,
        }

        await api_workorder.CUSTOMER_HISTORY(request).then(response => {
            //console.log('customer history data: ', response.data.data)
            setTransactions(response.data.data)
        }).catch(error => {
            console.log('error get customer history data: ', error)
            dispatch({
                type: "TOAST", value: {
                    show: true,
                    type: 'danger',
                    title: `ปลด Blacklist`,
                    message: `ขออภัยระบบไม่สามารถทำรายการได้ในขณะนี้`,
                }
            });
        })
    }

    useEffect(() => {
        keySearchInput(keyword)
    }, [currentPage])

    useEffect(() => {
        getCustomerHistory({user_id: modalConfirmValue.user_id})
    }, [modalConfirmValue])

    return (<>
        <Modal size="lg" show={isOpenModal}>
            <Modal.Header>
                <Modal.Title>ยืนยันยกเลิก Blacklist</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group as={Col}>
                    <Form.Row>
                        <Col>
                            <Form.Label>UserID : </Form.Label>
                            <b style={{"color": "red"}}>{' '}{modalConfirmValue.user_id}</b>
                        </Col>
                        <Col>
                            <Form.Label> ชื่อ - นามสกุล : </Form.Label>
                            <b style={{"color": "red"}}>{' '}{modalConfirmValue.first_name} {modalConfirmValue.last_name}</b>
                        </Col>
                        <Col>
                            <Form.Label> เบอร์โทรศัพท์ : </Form.Label>
                            <b style={{"color": "red"}}>{' '}{modalConfirmValue.mobile_no}</b>
                        </Col>
                    </Form.Row>
                </Form.Group>

                <Form.Group as={Col} xs="12" sm="12" md="12" lg="12" controlId="validationCustom13">
                    <Form.Label>รายการธุรกรรมล่าสุด</Form.Label>
                    <Table striped bordered hover responsive={true}>
                        <thead className="text-center">
                        <tr>
                            <th>วันที่/เวลา</th>
                            <th>ประเภทธุรกรรมหลัก</th>
                            <th>ประเภทธุรกรรมย่อย</th>
                            <th>จำนวนเงิน</th>
                            <th>ยอดคงเหลือ</th>
                            <th>สถานะการทำธุรกรรม</th>
                        </tr>
                        </thead>
                        <tbody className="text-center">
                        {(transactions !== null && transactions !== undefined) ? (transactions.map(history => {
                            return (<tr key={history.id}
                                        style={markManualStyle(history.channel, history.work_order_type)}>
                                <td>{moment(history.created_at).format("DD/MM/YYYY HH:mm:ss")}</td>
                                <td>{getThaiWorkOrderType(history.work_order_type)}</td>
                                <td>{getThaiWorkOrderType(history.work_order_sub_type)}</td>
                                <td>{number_format(history.amount, 2, '.', ',')} บาท</td>
                                <td>{number_format(history.after_balance, 2, '.', ',')} บาท</td>
                                <td>{renderBadge(history.status)}</td>
                            </tr>)
                        })) : (<tr>
                            <td colSpan={12}>ไม่มีรายการธุรกรรมล่าสุด</td>
                        </tr>)

                        }
                        </tbody>
                    </Table>
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="success" onClick={() => unBlacklist(modalConfirmValue)}>ยกเลิก Blacklist</Button>
                <Button variant="secondary" onClick={() => closeCustomerModal()}>ปิด</Button>
            </Modal.Footer>
        </Modal>
        <div className="col-md-12 pt-4 pr-4 pl-4">
            <Card className="card-success">
                <Card.Header><h5>ค้นหา Blacklist</h5></Card.Header>
                <Card.Body>
                    <Form.Row className="col-md-12">
                        <Form.Group className="col-md-12">
                            <Form.Label>ค้นหารายชื่อ</Form.Label>
                            <Form.Control as="input"
                                          name="search_customer"
                                          placeholder="eg. ufyu88xxxx/ahag2xxx, ชื่อ - นามสกุล, เบอร์โทรศัพท์, เลขที่บัญชี"
                                          defaultValue=""
                                          onChange={(e) => setKeyword(e.target.value)}
                            >
                            </Form.Control>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group className="col-md-1">
                            {/*<Button variant="primary" type="submit"*/}
                            {/*        onClick={() => keySearchInput()}>ค้นหา</Button>*/}
                            <Button onClick={() => keySearchInput()} disabled={searchButton}>
                                {(searchButton) ? (<Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />) : (<>ค้นหา</>)}
                            </Button>
                            {' '}
                        </Form.Group>
                    </Form.Row>
                    <Form.Row className="col-md-12">
                        <Pagination className={"ml-auto"}>
                            <Pagination.Prev onClick={handlePrevPage}/>
                            <Pagination.Item active={true}>
                                {currentPage}
                            </Pagination.Item>
                            <Pagination.Next onClick={handleNextPage}/>
                        </Pagination>
                    </Form.Row>
                    <Form.Row className="col-md-12">
                        <Form.Group className="col-md-12">
                            <Fade in={true}>
                                <TableSearchCustomer customerSearchList={searchData}
                                                     editCustomerData={openCustomerModal}/>
                            </Fade>
                        </Form.Group>
                    </Form.Row>
                </Card.Body>
            </Card>
        </div>
    </>)
}
