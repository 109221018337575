import React, { useState, useEffect } from 'react'
import { Modal, Jumbotron, Button, Card } from 'react-bootstrap'
import {useGlobalStore} from "../../store/globalContext";

export default function SweepWorkOrder(props) {
  const [ {}, dispatch ] = useGlobalStore();
  const [openModal, setOpenModal] = useState(false)
  const { modalData, isOpenModal, confirmData} = props
  const {
    headerText,
    fromAccountName,
    fromAccountNo,
    amount,
    toAccountName,
    toAccountNo,
    openModalState,
    confirmButton,
    closeButton
  } = modalData

  const [loading, setLoading] = useState(false)

  const handleConfirm = async () => {
    try {
      setLoading(true)
      confirmButton(confirmData)
      // Add timeout for dispatch
      // setTimeout(() => {
      //   dispatch({
      //     type: "TOAST2",
      //     value: {
      //       show: true,
      //       type: 'success',
      //       title: `โยกเงิน`,
      //       message: `ทำการโยกเงิน จำนวนเงิน ${ amount } บาท สำเร็จ`,
      //     }
      //   })
      // }, 2000)
    } catch (e) {
      console.log("error", e)
    } finally {
      setTimeout(() => {
        setLoading(false)
        closeButton()
      }, 2000)
    }
  }

  useEffect(() => {
    //console.log("SWEEP MODAL ENABLE :", openModalState)
    setOpenModal(openModalState);
  }, [openModalState])

  return (
    <div>
      <Modal show={isOpenModal}>
        <Modal.Header closeButton>
          <Modal.Title>{headerText}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card
            bg={"danger"}
            key={1}
            className="mb-2"
          >
            <Card.Header><h4><u>บัญชีฝาก</u></h4></Card.Header>
            <Card.Body>
              <Card.Text>
                <h5>
                  ชื่อบัญชี :  {fromAccountName}
                </h5>
              </Card.Text>
              <Card.Text>
                <h5>
                  เลขที่บัญชี : {fromAccountNo}
                </h5>
              </Card.Text>
            </Card.Body>
          </Card>
    
          <Card
            bg={"secondary"}
            key={2}
            className="mb-2"
          >
            <Card.Body className="text-center">
              <Card.Text>
                <h4>
                <i className={`nav-icon fas fa-arrow-circle-down nav-icon`} /> จำนวน {amount} บาท
                </h4>
              </Card.Text>
            </Card.Body>
          </Card>
          <Card
            bg={"success"}
            key={3}
            className="mb-2"
          >
            <Card.Header><h4><u>บัญชีถอน</u></h4></Card.Header>
            <Card.Body>
              <Card.Text>
                <h5>
                  ชื่อบัญชี :  {toAccountName}
                </h5>
              </Card.Text>
              <Card.Text>
                <h5>
                  เลขที่บัญชี : {toAccountNo}
                </h5>
              </Card.Text>
            </Card.Body>
          </Card>
        </Modal.Body>
        <Modal.Footer>
          {
            loading ?
                (<Button disabled={true}>
                  <i className="fas fa-circle-notch fa-spin"/> ระบบกำลังดำเนินการ
                </Button>):
                (
                    <Button className="btn-confirm" onClick={handleConfirm}>ยืนยันการโยกเงิน</Button>
                )
          }
          <Button className="btn-secondary" onClick={closeButton}>ยกเลิก</Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
