import React, { useEffect, useRef, useState } from 'react'
import { Accordion, Badge, Button, Card, Col, Form, Modal, Table } from "react-bootstrap";
import api from "../../api/agents";
import { useGlobalStore } from "../../store/globalContext";

export default function NewsUploads () {
    const dispatch = useGlobalStore()[ 1 ];
    const pageList = [
        { "text": "หน้าก่อน Login", "value": "before_login", },
        { "text": "หน้าหลัง Login", "value": "after_login", },
    ]
    const [ agentList, setAgentList ] = useState( [] )
    const [ createNewsParams, setCreateNewsParams ] = useState( {
        "title": "",
        "message": "",
        "money_site_name": "",
        "show": "",
        "file": undefined,
        "url": "",
        "image_url": "",
    } )
    const urlLength = 40;
    const [ updateNewsParams, setUpdateNewsParams ] = useState( {
        "title": "",
        "message": "",
        "money_site_name": "",
        "show": "",
        "file": undefined,
        "enable": false,
        "url": "",
        "image_url": "",
    } )
    const [ maintenanceNews, setMaintenanceNews ] = useState()
    const [ dataTable, setDataTable ] = useState( [] )
    const [ filterTable, setFilterTable ] = useState( [] )
    const [ filter, setFilter ] = useState( "" )
    const [ showModal, setShowModal ] = useState( false )
    const createImageRef = useRef()
    const maintenanceImageRef = useRef()

    const [ isUploadFile, setIsUploadFile ] = useState( true );
    const [ isUploadFileModal, setIsUploadFileModal ] = useState( true );
    const [ isUploadFileMaintenance, setIsUploadFileMaintenance ] = useState( true );

    const getAgentList = async() => {
        try {
            const keyword = {
                "keyword": "",
            }
            const response = await api.SEARCH_AGENTS( keyword )
            const { data } = response.data
            console.log( data )
            let moneySiteData = []
            data.forEach( ( d => {
                    d.money_sites.forEach( m => {
                        moneySiteData.push( m.id )
                    } )
                } )
            )
            let uniqueAgent = [ ...new Set( moneySiteData ) ]
            setAgentList( uniqueAgent )
        } catch ( error ) {
            console.log( "call search agents error ", error )
        }
    }

    const handleFilterNews = ( value ) => {
        if ( value === "" ) {
            setFilterTable( dataTable )
            return
        }

        let filterNews = dataTable.filter( news => news.money_site_name === value )
        setFilterTable( filterNews )
    }

    const uploadNewsFiles = async() => {
        if ( createNewsParams.file === undefined && createNewsParams.image_url === "" ) {
            dispatch( {
                type: "TOAST2",
                value: {
                    show: true,
                    type: 'danger',
                    title: `Upload ข่าวสาร`,
                    message: `กรุณาเลือกไฟล์หรือลิงค์รูปภาพข่าวสาร`,
                }
            } );
            return
        }

        if ( createNewsParams.title === "" ) {
            dispatch( {
                type: "TOAST2",
                value: {
                    show: true,
                    type: 'danger',
                    title: `Upload ข่าวสาร`,
                    message: `กรุณาใส่หัวข้อ`,
                }
            } );
            return
        }
        if ( createNewsParams.message === "" ) {
            dispatch( {
                type: "TOAST2",
                value: {
                    show: true,
                    type: 'danger',
                    title: `Upload ข่าวสาร`,
                    message: `กรุณาใส่หัวข้อความ`,
                }
            } );
            return
        }
        if ( createNewsParams.show === "" ) {
            dispatch( {
                type: "TOAST2",
                value: {
                    show: true,
                    type: 'danger',
                    title: `Upload ข่าวสาร`,
                    message: `กรุณาใส่หน้าที่จะแสดงข่าว`,
                }
            } );
            return
        }

        let formData = new FormData();

        if ( createNewsParams.file !== undefined ) {
            let currentFile = createNewsParams.file[ 0 ];
            formData.append( "file", currentFile );
        }
        formData.append( "title", createNewsParams.title );
        formData.append( "message", createNewsParams.message );
        formData.append( "money_site_name", createNewsParams.money_site_name );
        formData.append( "show", createNewsParams.show );
        formData.append( "url", createNewsParams.url );
        formData.append( "image_url", createNewsParams.image_url );

        try {
            const response = await api.NEWS_CREATE( formData )
            console.log( "create news complete", response )
            handleNewsSearch()
            dispatch( {
                type: "TOAST2",
                value: {
                    show: true,
                    type: 'success',
                    title: `Upload ข่าวสาร`,
                    message: `Upload ข่าวสารสำเร็จ`,
                }
            } );
        } catch ( e ) {
            console.log( `can't create a news`, e.message );
            dispatch( {
                type: "TOAST2",
                value: {
                    show: true,
                    type: 'danger',
                    title: `Upload ข่าวสาร`,
                    message: `สร้างข่าวไม่สำเร็จกรุณาลองอีกครั้ง`,
                }
            } );
        }
        setCreateNewsParams( {
            "title": "",
            "message": "",
            "money_site_name": "",
            "show": "",
            "file": undefined,
            "url": "",
            "image_url": "",
        } )
        setIsUploadFile( true )
        if ( createNewsParams.file !== undefined ) {
            createImageRef.current.value = ""
        }
    }

    const handleNewsSearch = async() => {
        try {
            const response = await api.NEWS_SEARCH()
            const { data } = response.data
            console.log( data )
            let alignData = []
            data.forEach( ( news => {
                    const align = {
                        "id": news.id,
                        "title": news.title,
                        "message": news.message,
                        "url": news.url,
                        "money_site_name": news?.money_site_name ? news?.money_site_name : "",
                        "image_url": news.image_url,
                        "status": news.status,
                        "enable": news.enable,
                        "show": news.show,
                        "maintenance": news.maintenance,
                    }
                    if ( align.maintenance === true ) {
                        console.log( align )
                        setMaintenanceNews( align )
                    } else {
                        alignData.push( align )
                    }
                } )
            )
            setDataTable( alignData )
        } catch ( error ) {
            console.log( "call search news error ", error )
        }
    }

    const handleUpdateNews = async() => {
        if ( updateNewsParams.title === "" ) {
            dispatch( {
                type: "TOAST2",
                value: {
                    show: true,
                    type: 'danger',
                    title: `Update ข่าวสาร`,
                    message: `กรุณาใส่หัวข้อ`,
                }
            } );
            return
        }
        if ( updateNewsParams.message === "" ) {
            dispatch( {
                type: "TOAST2",
                value: {
                    show: true,
                    type: 'danger',
                    title: `Update ข่าวสาร`,
                    message: `กรุณาใส่หัวข้อความ`,
                }
            } );
            return
        }
        if ( updateNewsParams.show === "" ) {
            dispatch( {
                type: "TOAST2",
                value: {
                    show: true,
                    type: 'danger',
                    title: `Update ข่าวสาร`,
                    message: `กรุณาใส่หน้าที่จะแสดงข่าว`,
                }
            } );
            return
        }

        let formData = new FormData();
        formData.append( "id", updateNewsParams.id )
        formData.append( "title", updateNewsParams.title );
        formData.append( "message", updateNewsParams.message );
        formData.append( "show", updateNewsParams.show );
        formData.append( "enable", updateNewsParams.enable );

        if ( updateNewsParams.file !== undefined ) {
            let currentFile = updateNewsParams.file[ 0 ];
            formData.append( "file", currentFile );
        }

        if ( updateNewsParams.url !== undefined ) {
            formData.append( "url", updateNewsParams.url )
        }

        if ( updateNewsParams.image_url !== undefined && updateNewsParams.image_url !== "" ) {
            formData.append( "image_url", updateNewsParams.image_url )
        }

        if ( updateNewsParams.money_site_name === "undefined" ) {
            // Old case
            formData.append( "money_site_name", "" );
        } else {
            formData.append( "money_site_name", updateNewsParams.money_site_name );
        }

        try {
            const response = await api.NEWS_UPDATE( formData )
            console.log( "update news complete", response )
            handleNewsSearch()
            dispatch( {
                type: "TOAST2",
                value: {
                    show: true,
                    type: 'success',
                    title: `Update ข่าวสาร`,
                    message: `Update ข่าวสาร สำเร็จ`,
                }
            } );
        } catch ( e ) {
            console.log( `can't update a news`, e.message );
            dispatch( {
                type: "TOAST2",
                value: {
                    show: true,
                    type: 'danger',
                    title: `Update ข่าวสาร`,
                    message: `อัพเดทข่าวไม่สำเร็จกรุณาลองอีกครั้ง`,
                }
            } );
        }
        setShowModal( false )
        setIsUploadFileModal(true)
    }

    const handleDeleteNews = async() => {
        let formData = new FormData();
        formData.append( "id", updateNewsParams.id )

        try {
            const response = await api.NEWS_DELETE( formData )
            console.log( "delete news complete", response )
            handleNewsSearch()
            dispatch( {
                type: "TOAST2",
                value: {
                    show: true,
                    type: 'success',
                    title: `ลบข่าวสาร`,
                    message: `ลบข่าวสารสำเร็จ`,
                }
            } );
        } catch ( e ) {
            console.log( `can't delete a news`, e.message );
            dispatch( {
                type: "TOAST2",
                value: {
                    show: true,
                    type: 'danger',
                    title: `ลบข่าวสาร`,
                    message: `ลบข่าวไม่สำเร็จกรุณาลองอีกครั้ง`,
                }
            } );
        }
        setShowModal( false )
    }

    const handleOpenModal = ( index ) => {
        console.log( dataTable[ index ] )
        setShowModal( true )
        setUpdateNewsParams( {
            "id": dataTable[ index ].id,
            "title": dataTable[ index ].title,
            "message": dataTable[ index ].message,
            "money_site_name": dataTable[ index ].money_site_name,
            "enable": dataTable[ index ].enable,
            "show": dataTable[ index ].show,
            "url": dataTable[ index ].url,
        } )
    }

    const handleCloseModal = () => {
        setShowModal( false )
        setIsUploadFileModal( true )
    }

    const uploadMaintenance = async() => {
        if ( maintenanceNews.file === undefined && maintenanceNews.image_url === "" ) {
            dispatch( {
                type: "TOAST2",
                value: {
                    show: true,
                    type: 'danger',
                    title: `Upload ข่าวสาร`,
                    message: `สร้างข่าวไม่สำเร็จกรุณาลองอีกครั้ง`,
                }
            } );
            return
        }

        let formData = new FormData();

        if ( maintenanceNews.file !== undefined ) {
            let currentFile = maintenanceNews.file[ 0 ];
            formData.append( "file", currentFile );
        }
        formData.append( "title", maintenanceNews.title );
        formData.append( "message", maintenanceNews.message );
        formData.append( "money_site_name", "" );
        formData.append( "show", "before_login" );
        formData.append( "maintenance", true )
        formData.append( "enable", maintenanceNews.enable )
        formData.append( "image_url", maintenanceNews.image_url )

        try {
            const response = await api.NEWS_CREATE( formData )
            console.log( "create news complete", response )
            handleNewsSearch()
            dispatch( {
                type: "TOAST2",
                value: {
                    show: true,
                    type: 'success',
                    title: `Upload ข่าวสาร`,
                    message: `Upload ข่าวสารสำเร็จ`,
                }
            } );
        } catch ( e ) {
            console.log( `can't create a news`, e.message );
            dispatch( {
                type: "TOAST2",
                value: {
                    show: true,
                    type: 'danger',
                    title: `Upload ข่าวสาร`,
                    message: `สร้างข่าวไม่สำเร็จกรุณาลองอีกครั้ง`,
                }
            } );
        }

        setIsUploadFileMaintenance( true )
        if ( maintenanceImageRef.file !== undefined ) {
            maintenanceImageRef.current.value = ""
        }
    }

    const updateMaintenance = async() => {
        let formData = new FormData();
        if ( maintenanceNews.file !== undefined ) {
            let currentFile = maintenanceNews.file[ 0 ];
            formData.append( "file", currentFile );
        }
        formData.append( "id", maintenanceNews.id )
        formData.append( "title", maintenanceNews.title );
        formData.append( "message", maintenanceNews.message );
        formData.append( "money_site_name", "" );
        formData.append( "show", "before_login" );
        formData.append( "maintenance", true )
        formData.append( "enable", maintenanceNews.enable )
        formData.append( "image_url", maintenanceNews.image_url )

        try {
            const response = await api.NEWS_UPDATE( formData )
            console.log( "create news complete", response )
            handleNewsSearch()
            dispatch( {
                type: "TOAST2",
                value: {
                    show: true,
                    type: 'success',
                    title: `Update ข่าวสาร`,
                    message: `Update ข่าวสารสำเร็จ`,
                }
            } );
        } catch ( e ) {
            console.log( `can't update a news`, e.message );
            dispatch( {
                type: "TOAST2",
                value: {
                    show: true,
                    type: 'danger',
                    title: `Update ข่าวสาร`,
                    message: `อัพเดทข่าวไม่สำเร็จกรุณาลองอีกครั้ง`,
                }
            } );
        }
        setIsUploadFileMaintenance( true )
        if ( maintenanceImageRef.file !== undefined ) {
            maintenanceImageRef.current.value = ""
        }
    }

    useEffect( () => {
        getAgentList()
        handleNewsSearch()
    }, [] )

    useEffect( () => {
        handleFilterNews( filter )
    }, [ dataTable, filter ] )

    return (
        <section className="content pt-4 pr-4 pl-4">
            <div className="container-fluid">
                <div>
                    <Modal show={showModal}>
                        <Modal.Header>
                            <Modal.Title>จัดการข่าว</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                <Form.Row className="col-md-12 pt-4">
                                    <Form.Group as={Col} md="12" controlId="validation01">
                                        <Form.Label>หัวข้อ</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder={"ทดสอบข่าว, ฉันรักลุง"}
                                            value={updateNewsParams.title}
                                            onChange={( e ) => setUpdateNewsParams( {
                                                ...updateNewsParams,
                                                "title": e.target.value,
                                            } )}/>
                                    </Form.Group>
                                    <Form.Group as={Col} md="12" controlId="validation02">
                                        <Form.Label>ข้อความ</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder={"ทดสอบข่าว, ฉันรักลุง"}
                                            value={updateNewsParams.message}
                                            onChange={( e ) => setUpdateNewsParams( {
                                                ...updateNewsParams,
                                                "message": e.target.value,
                                            } )}/>
                                    </Form.Group>
                                    <Form.Group as={Col} md="12" controlId="validation03">
                                        <Form.Label>Money Site</Form.Label>
                                        <Form.Control
                                            as="select"
                                            value={updateNewsParams.money_site_name}
                                            onChange={( e ) => setUpdateNewsParams( {
                                                ...updateNewsParams,
                                                "money_site_name": e.target.value,
                                            } )}>
                                            <option value="">Please Select</option>
                                            {
                                                agentList.map( ( text, index ) =>
                                                    <option
                                                        key={index}
                                                        value={text}
                                                    >
                                                        {text}
                                                    </option>
                                                )
                                            }
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group as={Col} md="12" controlId="validation99">
                                        <Form.Label>หน้าที่ต้องการจะแสดงข่าว</Form.Label>
                                        <Form.Control
                                            as="select"
                                            value={updateNewsParams.show}
                                            onChange={( e ) => setUpdateNewsParams( {
                                                ...updateNewsParams,
                                                "show": e.target.value,
                                            } )}>
                                            <option value="">Please Select</option>
                                            {
                                                pageList.map( ( { text, value }, index ) =>
                                                    <option
                                                        key={index}
                                                        value={value}
                                                    >
                                                        {text}
                                                    </option>
                                                )
                                            }
                                        </Form.Control>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Group as={Col} md="12" controlId="validation06">
                                    <Form.Label>ลิ้งค์ข่าวสาร</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder={"https://ufafevers.com"}
                                        value={updateNewsParams.url}
                                        onChange={( e ) => setUpdateNewsParams( {
                                            ...updateNewsParams,
                                            "url": e.target.value,
                                        } )}/>
                                </Form.Group>
                                <Form.Row>
                                    <Form.Group as={Col} md="12" controlId="validation043939">
                                        <Form.Check
                                            label="เปิดใช้งาน"
                                            type="switch"
                                            name="enable"
                                            checked={updateNewsParams.enable}
                                            onChange={() => setUpdateNewsParams( {
                                                ...updateNewsParams,
                                                "enable": !updateNewsParams.enable,
                                            } )}
                                        />
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row className="col-md-12 pt-4">
                                    <Form.Label>เลือกวิธีใส่รูปภาพข่าวสาร</Form.Label>
                                    <Form.Group controlId="validation07">
                                        <Form.Check
                                            inline
                                            label="อัพโหลดรูปภาพข่าวสาร"
                                            name="file_modal"
                                            type="radio"
                                            id={`inline-radio-01`}
                                            checked={true === isUploadFileModal}
                                            onChange={() => setIsUploadFileModal( true )}
                                        />
                                        <Form.Check
                                            inline
                                            label="ใส่ลิงค์รูปภาพข่าวสาร"
                                            name="link_modal"
                                            type="radio"
                                            id={`inline-radio-02`}
                                            checked={false === isUploadFileModal}
                                            onChange={() => setIsUploadFileModal( false )}
                                        />
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row className="col-md-12 pt-4">
                                    <Form.Group as={Col} md="12" controlId="validation05">
                                        {isUploadFileModal ?
                                            <>
                                                <Form.File id="form-check-api-regular">
                                                    <Form.File.Label>อัพโหลดรูปภาพข่าวสารใหม่</Form.File.Label>
                                                    <Form.File.Input
                                                        onChange={( e ) => setUpdateNewsParams( {
                                                            ...updateNewsParams,
                                                            "file": e.target.files,
                                                        } )}/>
                                                </Form.File>
                                            </> :
                                            <>
                                                <Form.Label>ใส่ลิงค์รูปภาพข่าวสาร</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder={"https://ufafevers.com"}
                                                    value={updateNewsParams.image_url}
                                                    onChange={( e ) => setUpdateNewsParams( {
                                                        ...updateNewsParams,
                                                        "image_url": e.target.value,
                                                    } )}/>
                                            </>
                                        }
                                    </Form.Group>
                                </Form.Row>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="success" onClick={handleUpdateNews}>บันทึกข้อมูล</Button>
                            <Button variant="danger" onClick={handleDeleteNews}>ลบข่าวนี้</Button>
                            <Button variant="secondary" onClick={handleCloseModal}>ปิด</Button>
                        </Modal.Footer>
                    </Modal>
                    <Card className="card-warning">
                        <Card.Header>เพิ่มข่าวสาร</Card.Header>
                        <Card.Body>
                            <Form>
                                <Form.Row className="col-md-12 pt-4">
                                    <Form.Group as={Col} md="3" lg="3" controlId="validation01">
                                        <Form.Label>หัวข้อ</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder={"ทดสอบข่าว, ฉันรักลุง"}
                                            value={createNewsParams.title}
                                            onChange={( e ) => setCreateNewsParams( {
                                                ...createNewsParams,
                                                "title": e.target.value,
                                            } )}/>
                                    </Form.Group>
                                    <Form.Group as={Col} md="3" lg="3" controlId="validation02">
                                        <Form.Label>ข้อความ</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder={"ทดสอบข่าว, ฉันรักลุง"}
                                            value={createNewsParams.message}
                                            onChange={( e ) => setCreateNewsParams( {
                                                ...createNewsParams,
                                                "message": e.target.value,
                                            } )}/>
                                    </Form.Group>
                                    <Form.Group as={Col} md="3" lg="3" controlId="validation03">
                                        <Form.Label>Money Site</Form.Label>
                                        <Form.Control
                                            as="select"
                                            value={createNewsParams.money_site_name}
                                            onChange={( e ) => setCreateNewsParams( {
                                                ...createNewsParams,
                                                "money_site_name": e.target.value,
                                            } )}>
                                            <option value="">Please Select</option>
                                            {
                                                agentList.map( ( text, index ) =>
                                                    <option
                                                        key={index}
                                                        value={text}
                                                    >
                                                        {text}
                                                    </option>
                                                )
                                            }
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group as={Col} md="3" lg="3" controlId="validation04">
                                        <Form.Label>หน้าที่ต้องการจะแสดงข่าว</Form.Label>
                                        <Form.Control
                                            as="select"
                                            value={createNewsParams.show}
                                            onChange={( e ) => setCreateNewsParams( {
                                                ...createNewsParams,
                                                "show": e.target.value,
                                            } )}>
                                            <option value="">Please Select</option>
                                            {
                                                pageList.map( ( { text, value }, index ) =>
                                                    <option
                                                        key={index}
                                                        value={value}
                                                    >
                                                        {text}
                                                    </option>
                                                )
                                            }
                                        </Form.Control>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row className="col-md-12">
                                    <Form.Group as={Col} md="6" lg="6" controlId="validation06">
                                        <Form.Label>ลิ้งค์ข่าวสาร</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder={"https://ufafevers.com"}
                                            value={createNewsParams.url}
                                            onChange={( e ) => setCreateNewsParams( {
                                                ...createNewsParams,
                                                "url": e.target.value,
                                            } )}/>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row className="col-md-12 pt-4">
                                    <Form.Label>เลือกวิธีใส่รูปภาพข่าวสาร</Form.Label>
                                    <Form.Group as={Col} md="6" lg="6" class="ml-4" controlId="validation07">
                                        <Form.Check
                                            inline
                                            label="อัพโหลดรูปภาพข่าวสาร"
                                            name="file"
                                            type="radio"
                                            id={`inline-radio-1`}
                                            checked={true === isUploadFile}
                                            onChange={() => setIsUploadFile( true )}
                                        />
                                        <Form.Check
                                            inline
                                            label="ใส่ลิงค์รูปภาพข่าวสาร"
                                            name="link"
                                            type="radio"
                                            id={`inline-radio-2`}
                                            checked={false === isUploadFile}
                                            onChange={() => setIsUploadFile( false )}
                                        />
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row className="col-md-12 pt-4">
                                    <Form.Group as={Col} md="3" lg="3" controlId="validation04">
                                        {isUploadFile ?
                                            <>
                                                <Form.Label>อัพโหลดรูปภาพข่าวสาร</Form.Label>
                                                <p style={{fontWeight: "bold", color: "red"}}>(ขนาดรูปภาพไม่เกิน 1 MB)</p>
                                                <Form.File id="form-check-api-regular">
                                                    <Form.File.Input
                                                        ref={createImageRef}
                                                        onChange={( e ) => setCreateNewsParams( {
                                                            ...createNewsParams,
                                                            "file": e.target.files,
                                                        } )}/>
                                                </Form.File>
                                            </> :
                                            <>
                                                <Form.Label>ใส่ลิงค์รูปภาพข่าวสาร</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder={"https://ufafevers.com"}
                                                    value={createNewsParams.image_url}
                                                    onChange={( e ) => setCreateNewsParams( {
                                                        ...createNewsParams,
                                                        "image_url": e.target.value,
                                                    } )}/>
                                            </>
                                        }
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row className="col-md-12">
                                    <Form.Group as={Col} md="3" lg="3" controlId="validation05">
                                        <Button as={Col} md="6" variant="success"
                                                onClick={uploadNewsFiles}>เพิ่มข่าว</Button>
                                    </Form.Group>
                                </Form.Row>
                            </Form>
                        </Card.Body>
                    </Card>
                    <Accordion eventKey="0">
                        <Card className="card-danger">
                            <Card.Header>
                                เพิ่มข่าวสารเมื่อระบบปิดปรับปรุง
                                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                    <Button variant={"outline-light"} style={{borderRadius: "20px"}} size={"sm"}><i className={`fas fa-plus`}/></Button>
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                    <Form>
                                        <Form.Row>
                                            <Form.Group as={Col} md="12" controlId="validation3838">
                                                <Form.Check
                                                    label="เปิดใช้งาน"
                                                    type="switch"
                                                    name="enable"
                                                    checked={maintenanceNews?.enable}
                                                    onChange={() => setMaintenanceNews( {
                                                        ...maintenanceNews,
                                                        "enable": !maintenanceNews?.enable,
                                                    } )}
                                                />
                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row className="col-md-12 pt-4">
                                            <Form.Group as={Col} md="3" lg="3" controlId="validation392399">
                                                <Form.Label>หัวข้อ</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder={"ทดสอบข่าว, ฉันรักลุง"}
                                                    value={maintenanceNews?.title}
                                                    onChange={( e ) => setMaintenanceNews( {
                                                        ...maintenanceNews,
                                                        "title": e.target.value,
                                                    } )}/>
                                            </Form.Group>
                                            <Form.Group as={Col} md="3" lg="3" controlId="validation05803">
                                                <Form.Label>ข้อความ</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder={"ทดสอบข่าว, ฉันรักลุง"}
                                                    value={maintenanceNews?.message}
                                                    onChange={( e ) => setMaintenanceNews( {
                                                        ...maintenanceNews,
                                                        "message": e.target.value,
                                                    } )}/>
                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row className="col-md-12 pt-4">
                                            <Form.Label>เลือกวิธีใส่รูปภาพข่าวสาร</Form.Label>
                                            <Form.Group className="ml-4" controlId="validation07">
                                                <Form.Check
                                                    inline
                                                    label="อัพโหลดรูปภาพข่าวสาร"
                                                    name="file_maintenance"
                                                    type="radio"
                                                    id={`inline-radio-001`}
                                                    checked={true === isUploadFileMaintenance}
                                                    onChange={() => setIsUploadFileMaintenance( true )}
                                                />
                                                <Form.Check
                                                    inline
                                                    label="ใส่ลิงค์รูปภาพข่าวสาร"
                                                    name="link_maintenance"
                                                    type="radio"
                                                    id={`inline-radio-002`}
                                                    checked={false === isUploadFileMaintenance}
                                                    onChange={() => setIsUploadFileMaintenance( false )}
                                                />
                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row className="col-md-12 pt-4">
                                            <Form.Group as={Col} md="3" lg="3" controlId="validation033234">
                                                {
                                                    isUploadFileMaintenance ?
                                                        <>
                                                            <Form.File id="form-check-api-regular">
                                                                <Form.File.Label>อัพโหลดรูปภาพข่าวสาร</Form.File.Label>
                                                                <Form.File.Input
                                                                    ref={maintenanceImageRef}
                                                                    onChange={( e ) => setMaintenanceNews( {
                                                                        ...maintenanceNews,
                                                                        "file": e.target.files,
                                                                    } )}/>
                                                            </Form.File>
                                                        </>:
                                                        <>
                                                            <Form.Label>ใส่ลิงค์รูปภาพข่าวสาร</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder={"https://ufafevers.com"}
                                                                value={maintenanceNews?.image_url}
                                                                onChange={( e ) => setMaintenanceNews( {
                                                                    ...maintenanceNews,
                                                                    "image_url": e.target.value,
                                                                } )}/>
                                                        </>
                                                }
                                            </Form.Group>
                                            <Form.Group as={Col} md="6" lg="6">
                                                {
                                                    maintenanceNews?.image_url ?
                                                        <img src={maintenanceNews?.image_url}
                                                             alt={maintenanceNews?.title} height={300}
                                                             width={200}/>
                                                        : <>ไม่มีรูปแสดงระหว่างระบบปรับปรุง</>
                                                }
                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row className="col-md-12">
                                            <Form.Group as={Col} md="4" lg="4" controlId="validation032325">
                                                <Button as={Col} md="4" variant="success"
                                                        onClick={uploadMaintenance}>เพิ่มข่าว</Button>
                                                {' '}
                                                <Button as={Col} md="4" variant="primary"
                                                        onClick={updateMaintenance}>อัพเดทข่าว</Button>
                                            </Form.Group>
                                            <Form.Group as={Col} md="3" lg="3" controlId="validation063232">
                                            </Form.Group>
                                        </Form.Row>
                                    </Form>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                    <Card className="card-primary">
                        <Card.Header>ค้นหาข่าวสารสำหรับลูกค้า</Card.Header>
                        <Card.Body>
                            <Form>
                                <Form.Row className="col-md-12">
                                    <Form.Group as={Col} md="3" lg="3" controlId="validation03">
                                        <Form.Label>Money Site</Form.Label>
                                        <Form.Control
                                            as="select"
                                            onChange={( e ) => setFilter( e.target.value )}>
                                            <option value="">ทั้งหมด</option>
                                            {
                                                agentList.map( ( text, index ) =>
                                                    <option
                                                        key={index}
                                                        value={text}
                                                    >
                                                        {text}
                                                    </option>
                                                )
                                            }
                                        </Form.Control>
                                    </Form.Group>
                                </Form.Row>
                            </Form>
                            <React.Fragment>
                                <Table responsive hover size="md">
                                    <thead className="text-center">
                                    <tr>
                                        <th>หัวข้อ</th>
                                        <th>ข้อความ</th>
                                        <th>Money Site</th>
                                        <th>หน้าที่แสดง</th>
                                        <th>ลิ้งค์ข่าวสาร</th>
                                        <th>สถานะ</th>
                                        <th>รูปภาพ</th>
                                    </tr>
                                    </thead>
                                    <tbody className="text-center">
                                    {
                                        filterTable?.map( ( {
                                                                id,
                                                                title,
                                                                message,
                                                                url,
                                                                money_site_name,
                                                                image_url,
                                                                money_site_id,
                                                                show,
                                                                status,
                                                                enable,
                                                            }, index ) =>
                                            <tr key={index} onClick={() => handleOpenModal( index )}>
                                                <td><h6>{title}</h6></td>
                                                <td><h6>{message}</h6></td>
                                                <td><h6>{money_site_name}</h6></td>
                                                <td>
                                                    {show === "before_login" ? "ก่อนหน้า Login" : "หลังหน้า Login"}
                                                </td>
                                                <td><h6>{
                                                    url === undefined ?
                                                        "" :
                                                        url.length > urlLength ?
                                                            url.substring( 0, urlLength - 3 ) + "..." :
                                                            url
                                                }
                                                </h6></td>
                                                <td> {
                                                    enable ? <Badge variant="success">{"เปิด"}</Badge> :
                                                        <Badge variant="danger">{"ปิด"}</Badge>}
                                                </td>
                                                <td align="center">
                                                    <img src={image_url} alt={title} height={300} width={200}/>
                                                </td>
                                            </tr>
                                        )
                                    }
                                    </tbody>
                                </Table>
                            </React.Fragment>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </section>
    )
}