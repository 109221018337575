import React, {useEffect, useState} from "react";
import api_SMS from "../../api/sms";

function SMSReader() {

  const [smsTable, setSMSTable] = useState([]);
  console.log("begin");

  const getSMSData = async () => {
    const response = await api_SMS.SMS_READER();
    console.log(response);
    if (response.status !== 200) {
      console.log("search sms failed");
      return;
    }
    const {data} = response.data;
    console.log("search sms response data: ", data);

    if (!data) {
      return;
    }

    const smsData = []
    data.forEach(key => {
      smsData.push({
        bankCode: key.bank_code,
        bankName: key.bank_name,
        content: key.content,
        otp: key.otp,
        refCode: key.ref_code,
        sender: key.sender,
        toAccountName: key.to_account_name,
        toAccountNo: key.to_account_no,
        toBankName: key.to_bank_name
      })
    })

    console.log(smsData)
    setSMSTable(smsData);
  }

  useEffect(() => {
    getSMSData();
  }, [])


  return (
    <div>
      {smsTable.map((sms, index) => {
        return (
          <div key={index}>
            <p>Content: {sms.content}</p>
            <hr/>
          </div>
        );
      })}
    </div>
  )
}

export default SMSReader;