import React, {useContext, useEffect, useRef, useState} from "react";
import {Badge, Button, Card, Col, Form, FormControl, InputGroup, Modal, Row, Spinner, Table} from "react-bootstrap";
import ModalRegisterBanks from "./ModalRegisterBanks";
import api_customer from "../../api/customer";
import GlobalTable from "../manage_customer/GlobalTable";
import api_bank from "../../api/managebank";
import moment from "moment";
import {LogoWithAccountNoSimple} from "../banks_logo";
import {useForm} from "react-hook-form";
import {useRecoilState} from "recoil";
import {notificationsObjectRecoilState} from "../../store/atoms";
import NumberFormat from "react-number-format";

export const MonitorBankAccountContext = React.createContext()

export const DataListWithSearchModal = ({accountName, accountNo, showModal, closeModal})=>{
    const currentDate = new Date()
    const { register, handleSubmit, getValues } = useForm({
        defaultValues:{
            currentPage:1,
            account_no: accountNo,
            end_date: moment(currentDate).format("YYYY-MM-DD"),
            start_date: moment(currentDate.setDate(currentDate.getDate()-2)).format("YYYY-MM-DD"),
        },
        criteriaMode: "all",
    });
    const [monitorBankAccountList, setMonitorBankAccountList] = useState([])
    const [notificationObject, setNotificationObject]= useRecoilState(notificationsObjectRecoilState)
    const [loading, setLoading] = useState(false)
    const ed = new Date();
    const onSubmit = async (data) => {
        // console.log(data)

        const request = {
            "bank_account_no": accountNo,
            "end_date": data.end_date,
            "last_element_id": "",
            "start_date": data.start_date,
            "limit": 100
        }

        // console.log(request)

        try {
            setLoading(true)
            const response = await api_bank.BANK_SETTLEMENT_SEARCH( request )
            // console.log(`get bank settlement search response: `, response.data)
            const {data} = response.data

            if(data.statements === null){
                return
            }

            let dataConvertMap = []

            data.statements.map((d)=>{
                // console.log("d: ", d)
                dataConvertMap.push(
                    {
                        วันที่: moment(d.date).format("DD/MM/YYYY"),
                        เวลา: d.time,
                        โอนเข้า: d.credit || "-",
                        โอนออก: d.debit || "-",
                        รายละเอียด: d.description,
                        Teller_Channel: `${d.teller||""} ${d.channel||""}`,
                        เลขที่อ้างอิง: d.ref_no,
                        เวลาอ้างอิงธนาคาร: moment(d.transaction_at).format("DD/MM/YYYY HH:mm")
                    }
                )
            })

            setMonitorBankAccountList(dataConvertMap)

        } catch (e) {
            console.log(`get bank settlement search failed: `, e.message);
            setNotificationObject({
                text: `ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่ภายหลัง`, type: "error", icon:<i className={`fas fa-times-circle bg-danger`}/>
            })
        } finally {
            setLoading(false)
        }
    }

    useEffect(()=>{
        if(accountNo && showModal === true){
            handleSubmit(onSubmit)();
        }

    },[showModal])

    return(
        <Modal size={"xl"} show={showModal} onHide={closeModal} >
            <Modal.Header closeButton><b>ข้อมูลบัญชี {accountName} - {accountNo}</b></Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Row >
                        <Col sm={6} md={3} lg={3}>
                            <Form.Label htmlFor="inlineFormInputGroupStartDate" srOnly>
                                2020-09-21
                            </Form.Label>
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Start-Date</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                    type="date"
                                    id="RegisterChannelStartDate"
                                    name={"start_date"}
                                    placeholder="2020-09-10"
                                    ref={register({ required: true })}
                                    // onChange={(e) =>
                                    //     setRegisterChannelStartDate(
                                    //         moment(e.target.value).format("YYYY-MM-DD")
                                    //     )
                                    // }
                                    defaultValue={getValues("start_date")}
                                />
                            </InputGroup>
                        </Col>
                        <Col sm={6} md={3} lg={3}>
                            <Form.Label htmlFor="inlineFormInputGroupEndDate" srOnly>
                                2020-09-21
                            </Form.Label>
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text>End-Date</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                    type="date"
                                    id="RegisterChannelEndDate"
                                    placeholder="2020-09-21"
                                    name={"end_date"}
                                    ref={register({ required: true })}
                                    // onChange={(e) =>
                                    //     setRegisterChannelEndDate(
                                    //         moment(e.target.value).format("YYYY-MM-DD")
                                    //     )
                                    // }
                                    defaultValue={ moment(ed).format("YYYY-MM-DD")}
                                />
                            </InputGroup>
                        </Col>
                        <Col xs="auto">
                            {/*{loadingRegisterChannel ? (*/}
                            {/*    <>*/}
                            {/*        <Spinner*/}
                            {/*            as="span"*/}
                            {/*            animation="border"*/}
                            {/*            size="sm"*/}
                            {/*            role="status"*/}
                            {/*            aria-hidden="true"*/}
                            {/*        />{" "}*/}
                            {/*        กำลังค้นหา*/}
                            {/*    </>*/}
                            {/*) : (*/}
                            <Button type="submit" disabled={loading}>
                                {
                                    (loading)?(<Spinner animation={"border"} size={"sm"}/>):(<i className={"fas fa-search"}/>)
                                }
                            </Button>
                            {' '}
                            {/*<Button type="button" className={"btn-danger"} disabled={loading}>*/}
                            {/*    {*/}
                            {/*        (loading)?(<Spinner animation={"border"} size={"sm"}/>):(<i className={"fas fa-sync-alt"}/>)*/}
                            {/*    }*/}
                            {/*</Button>*/}
                            {/*)}*/}
                        </Col>
                    </Row>
                </Form>

                <div className={"py-2"}>
                    {
                        (monitorBankAccountList.length === 0)?
                            ( <div className={"py-2 text-center"}>ไม่พบข้อมูล</div>):
                            (<GlobalTable tableData={monitorBankAccountList} pageSizing={100}/>)
                    }
                </div>
            </Modal.Body>
        </Modal>
    )
}
const MonitorBankAccountTable = (props)=>{
    const {getBankList} = props
    const {officialBankListData, monitorBankList} = useContext(MonitorBankAccountContext)

    const [dataList, setDatalist] = useState({accountName:"", accountNo:""})
    const [showDataListModal, setShowDataListModal] = useState(false)
    const [showUpdateBankModal, setShowUpdateBankModal] = useState(false)
    const [notificationObject, setNotificationObject]= useRecoilState(notificationsObjectRecoilState)

    const [isLoadingStmts, setIsLoadingStmts] = useState(false)

    const [ updateModalPropsInfo, setUpdateModalPropsInfo ] = useState({
        action: "update",
        modalHeader: "แก้ไขข้อมูลธนาคาร",
        buttonSubmit: "แก้ไข",
        data: {},
        afterCloseModal:()=>{
            getBankList();
            setShowUpdateBankModal(false);
        },
        officialBank: [],
        onClose:  ()=> setShowUpdateBankModal(false)
    })

    const showDatalist = (accountName, accountNo) =>{
        setDatalist({accountName: accountName, accountNo: accountNo})
        // console.log("datalist: ", dataList)
        setShowDataListModal(true)
    }

    const updateMonitorBankData = (index)=>{
        // console.log("select monitor bank: ", monitorBank[index])
        setUpdateModalPropsInfo({...updateModalPropsInfo, data:monitorBankList[index], officialBank: officialBankListData})
        setShowUpdateBankModal(true)
    }

    const forceReadStatement = async (index)=>{
        // console.log("select monitor bank: ", monitorBank[index])
        try {
            setIsLoadingStmts(true)
            const response = await api_bank.READ_STATEMENT( {
                bank_account_no: monitorBankList[index].bank_account_no
            } )
            // console.log(`force read statement: `, response.data)

        } catch (e) {
            console.log(`get bank settlement search failed: `, e.message);
            setNotificationObject({
                text: `ไม่สามารถอ่านรายการสำหรับบัญชี ${monitorBankList[index].bank_account_no} กรุณาทำรายการใหม่ภายหลัง`, type: "error", icon:<i className={`fas fa-times-circle bg-danger`}/>
            })
        } finally {
            // console.log(`get bank settlement search finally`);
            setIsLoadingStmts(false)
            getBankList();
            setConfirmForceReadStatementModal({show:false, index: -1})
        }
    }


    const [confirmForceReadStatementModal, setConfirmForceReadStatementModal] = useState({
        show: false,
        index:-1
    })
    const ConfirmForceReadStatement = ()=>{

        const mBank = monitorBankList[confirmForceReadStatementModal.index]

        return(
            <Modal size={"lg"}  show={confirmForceReadStatementModal.show}>
                <Modal.Header className={"bg-danger"} closeButton={true} onHide={(prev)=>setConfirmForceReadStatementModal({...prev, show:false})}>
                    ยืนยันการบังคับอ่าน Statements
                </Modal.Header>
                <Modal.Body className={"text-center text-lg"}>
                    ยืนยันการอ่าน Statements ของบัญชี {
                    (mBank)?(<b className={"text-red"}>{mBank.bank_account_name} - {mBank.bank_account_no}</b>):("-")
                }
                <p/>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={"primary"} onClick={()=>forceReadStatement(confirmForceReadStatementModal.index)}>ยืนยัน</Button>
                    <Button variant={"secondary"} onClick={()=>setConfirmForceReadStatementModal({show:false, index: -1})}>ปิด</Button>
                </Modal.Footer>
            </Modal>
        )

    }

    return(
        <>
        <ConfirmForceReadStatement show={confirmForceReadStatementModal.show} index={confirmForceReadStatementModal.index}/>
        <ModalRegisterBanks {...updateModalPropsInfo} show={showUpdateBankModal}/>
        <DataListWithSearchModal accountName={dataList.accountName} accountNo={dataList.accountNo} showModal={showDataListModal} closeModal={()=>setShowDataListModal(false)}/>
        <Table>
            <thead>
            <tr >
                <th>#</th>
                <th>ชื่อธนาคาร</th>
                <th>ยอดคงเหลือล่าสุด</th>
                <th>วันที่สร้าง</th>
                <th>อ่านรายการล่าสุด</th>
                <th>เปิดใช้งาน</th>
            </tr>
            </thead>
            <tbody>
            {
                monitorBankList.map((mba, index)=>(
                    <tr key={index} >
                        <td className={"flex"}>
                            <div>
                                <Button className={"btn-primary"} size={"sm"} onClick={()=>showDatalist(mba.bank_account_name, mba.bank_account_no)}>
                                    <i className={"fas fa-list-ul"}/>
                                </Button>
                            </div>
                            <div className={"pl-2"}>
                                <Button className={"btn-warning"} size={"sm"} onClick={()=>updateMonitorBankData(index)}>
                                    <i className={"fas fa-edit"}/>
                                </Button>
                            </div>
                            <div className={"pl-2"}>
                                <Button className={"btn-danger"} size={"sm"} onClick={()=>setConfirmForceReadStatementModal({show: true, index: index})} disabled={isLoadingStmts}>
                                    {
                                        (isLoadingStmts)?
                                            (
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                    className={"justify-content-center"}
                                                />
                                            ):
                                            (
                                                <i className={"fas fa-sync-alt"}/>
                                            )
                                    }

                                </Button>
                            </div>
                        </td>
                        <td>
                            <LogoWithAccountNoSimple
                                bankShortName={mba.bank_short_name}
                                bankFullName={mba.bank_account_name}
                                bankName={mba.bank_account_name}
                                accountNo={mba.bank_account_no}
                        />

                        </td>

                        <td>{
                                (mba.balance >= 0) ?(
                                <NumberFormat
                                    thousandSeparator={true}
                                    decimalScale={0}
                                    fixedDecimalScale={true}
                                    value={mba.balance}
                                    displayType="text"
                                />):
                                ("-")
                            }
                        </td>
                        <td>{moment(mba.created_at).format("DD/MM/YYYY HH:mm:ss")}</td>
                        <td>{
                            (mba.read_statement_at === '0001-01-01T00:00:00Z')?('-'):
                            moment(mba.read_statement_at).format("DD/MM/YYYY HH:mm:ss")
                        }</td>
                        <td>{mba.enable === false ? ( <Badge variant="danger">ปิดใช้งาน</Badge>):( <Badge variant="success">เปิดใช้งาน</Badge>)}</td>
                    </tr>
                    ))
            }
            </tbody>
        </Table>
        </>
    )

}

export default function MonitorBankAccount(){

    const [monitorBankList, setMonitorBankList] = useState([])
    const [officialBankList, setOfficialBankList] = useState([])
    const [showBankModal, setShowBankModal] = useState(false)

    const toggleShowBankModal = prev =>{
        setShowBankModal(!prev)
    }

    const getOfficialBankList = async () => {
        try {
            const response = await api_customer.GET_THAI_BANK_API();
            if (response.status !== 200) {
                console.log("get bank list failed");
                return;
            }
            const { data } = response.data;
            //console.log("bank_list_data: ", data)
            let dropdownBankList = [];
            data.forEach((key) => {
                // console.log("bank_list_data key : ", key);
                dropdownBankList.push({
                    innerText: key.text,
                    value: key.value,
                    code: key.code,
                });
            });
            setUpdateModalPropsInfo({...updateModalPropsInfo,
                officialBank: dropdownBankList
            })
            setOfficialBankList(dropdownBankList)
        } catch (error) {
            console.log("get bank list error : ", error);
        }
    };

    const getBankList = async () => {
        const response = await api_bank.BANK_SEARCH_TYPE({
            keyword: "monitor",
        });
        if (response.status !== 200) {
            console.log(`search bank_list by user id failed`, response.message);
            return;
        }

        const { data } = response.data;
        // console.log(`search bank_list response`, data);
        if(data === null){
            return
        }
        setMonitorBankList(data);
    };

    const MonitorBankAccountContextValue ={
        monitorBankList: monitorBankList,
        officialBankListData: officialBankList,
    }

    const [ updateModalPropsInfo, setUpdateModalPropsInfo ] = useState({
        action: "create",
        modalHeader: "เพิ่มข้อมูลธนาคาร Monitoring",
        buttonSubmit: "เพิ่ม",
        data: {},
        afterCloseModal:(prev)=>{
            void getBankList();
            void setShowBankModal(false);
        },
        officialBank: [],
        onClose: ()=> setShowBankModal(false),
    })

    useEffect(()=>{
        void getOfficialBankList()
        void getBankList()
    },[])

    return(
        <>
            <MonitorBankAccountContext.Provider value={MonitorBankAccountContextValue}>
            <ModalRegisterBanks {...updateModalPropsInfo} show={showBankModal}/>
            <div className="pt-4 pl-4 pr-4">
                <Card className={"card-primary"}>
                    <Card.Header>รายการบัญชี Monitor</Card.Header>
                    <Card.Body>
                        <MonitorBankAccountTable
                            // officialBank={updateModalPropsInfo.officialBank}
                            // monitorBank={monitorBankList}
                            getBankList={()=>getBankList()}
                        />
                    </Card.Body>
                    <Card.Footer>
                        <Button className={"btn-primary"} onClick={()=> toggleShowBankModal()}>เพิ่มบัญชี</Button>
                    </Card.Footer>
                </Card>
            </div>
                </MonitorBankAccountContext.Provider>
        </>
    )
}