import React, {useEffect} from "react";
import Menu from "./Menu"
import { useGlobalStore } from "../store/globalContext";
import Toast from '../component/Toast'
import { ToastContainer, toast as toast2, TypeOptions } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';


import {useRecoilState} from "recoil";
import {notificationsObjectRecoilState, notificationsRecoilState} from "../store/atoms";

let socket
export default function Content({ children }) {
  //มีการเรียกใช้ useGlobalStore Component ที่มีกำหนดค่า ContextProvider ประกอบด้วย reducer และ state ของมัน
    const [{ subscribe, toast }, dispatch] = useGlobalStore()
    const [notiInfo, setNotiInfo] = useRecoilState(notificationsObjectRecoilState)
    const addNotifications  = (message) => {
        //console.log("addNotifications message : ", message)
        if (message.text === ""){
            return
        }
        toast2(message.text,{
            type:message.type,
            theme: "light",
            style:{width: 325, height:"auto"},
            position:"top-right"
        })
    }

      useEffect(()=>{
          addNotifications(notiInfo)
      },[notiInfo])

      useEffect(()=>{
          const {message, type} = toast
          if (type === "danger"){
              addNotifications({text: message, type: "error"})
          }else{
              addNotifications({text: message, type: type})
          }

      },[toast])


  return (
    // <Router>
    <>
      <Menu />
      <div className="content-wrapper">
        {/*<Toast show={toast.show} type={toast.type} title={toast.title} message={toast.message} dispatch={dispatch} />*/}
        <ToastContainer position="top-center" newestOnTop limit={5} autoClose={10000} pauseOnFocusLoss={false}/>
        {children}
      </div>
      {/* </Router> */}
    </>
  );
}