import React, {
  useReducer,
  useContext,
  createContext,
} from 'react'
import localStorage from './localStorage'
import {useRecoilState, useSetRecoilState} from "recoil";
import {notificationsObjectRecoilState} from "./atoms";

// ประกาศ Global state ชื่อ stateContext
const StateContext = createContext()

//สร้าง Reducer รับ handler มา มีสองค่าคือ 
//1. state สำหรับจัดการ state กลาง
//2. action สำหรับจัดการ action เรียก API หรือ WS ต่างๆ
const createReducer = (handlers) => (state, action) => {
  //console.log("global reducer: ", handlers);
  if (!handlers.hasOwnProperty(action.type)) {
    console.log(`reducer missing type ${action.type}, please update at global.js`)
    return state
  }
  return handlers[action.type](state, action)
}


//สร้าง reducer ที่มาจาก Reducer ที่มี handler ประเภทๆต่างๆ ตามหน้าจอ
const reducer = createReducer({
  health_check: (state, { type, value }) => {
    //console.log("health_check data", value);

    return {
      ...state,
      health_check: value
    }
  },
  login_profile: (state, { type, value }) => {
    //console.log("login_profile data", value);

    return {
      ...state,
      login_profile: value
    }
  },
  dashboard_customer: (state, { type, value }) => {
    const { message } = value
    //console.log("dashboard_customer message", message);
    //console.log("dashboard_customer data", data);
    if (message !== 'success') {
      return {
        ...state,
        toast: {
          show: true,
          type: 'danger',
          title: `ค้นหาข้อมูลลูกค้า`,
          message: `ขออภัยระบบไม่สามารถทำรายการได้ในขณะนี้`,
        }
      }
    }

    return {
      ...state,
      dashboard_customers: value.data
    }
  },
  register_customer: (state, { response }) => {
    console.log("response: type", response)
    // console.log("response: value", value)
    const { message, data } = response

    // if (data !== undefined){
    return {
      ...state,
      toast: {
        show: true,
        type: message ? 'success' : 'danger',
        title: message ? `สมัครลูกค้าใหม่ ${data.user_id}` : `สมัครลูกค้าใหม่ไม่สำเร็จ`,
        message,
      }
    }
    // }

  },
  customer_search: (state, { type, value }) => {
    const { message } = value
    console.log("customer_search", value);
    if (message !== 'success') {
      return {
        ...state,
        toast: {
          show: true,
          type: 'danger',
          title: `ค้นหาข้อมูลลูกค้า`,
          message: `ขออภัยระบบไม่สามารถทำรายการได้ในขณะนี้`,
        }
      }
    }

    return {
      ...state,
      customers: value.data
    }
  },

  customer_register: (state, { type, value }) => {
    const { message, data } = value
    console.log("customer_register", value);
    if (message !== 'success') {
      return {
        ...state,
        status: 'failed',
        toast: {
          show: true,
          type: 'danger',
          title: `สมัครลูกค้าใหม่`,
          message: message,
        }
      }
    }

    return {
      ...state,
      status: 'success',
      toast: {
        show: true,
        type: 'success',
        title: `สมัครลูกค้าใหม่`,
        message: `สมัครลูกค้าใหม่ ${data.user_id} คุณ ${data.first_name} ${data.last_name} สำเร็จ `,
      }
    }
  },
  customer_update: (state, { type, value }) => {
    const { message, data } = value
    console.log("customer_update", value);
    if (message !== 'success') {
      return {
        ...state,
        status: 'failed',
        toast: {
          show: true,
          type: 'danger',
          title: `อัพเดทข้อมูลลูกค้า`,
          message: message,
        }
      }
    }

    return {
      ...state,
      status: 'success',
      toast: {
        show: true,
        type: 'success',
        title: `อัพเดทข้อมูลลูกค้า`,
        message: `อัพเดทข้อมูล ${data.user_id} ${data.first_name} ${data.last_name} สำเร็จ`,
      }
    }
  },
  clear_status: (state) => {
    return {
      ...state,
      status: null,
    }
  },
  bank_search: (state, { type, value }) => {
    const { message, data } = value
    //console.log("bank_search", value);
    if (message !== 'success') {
      return {
        ...state,
        toast: {
          show: true,
          type: 'danger',
          title: `ค้นหาข้อมูลลูกค้า`,
          message: `ขออภัยระบบไม่สามารถทำรายการได้ในขณะนี้`,
        }
      }
    }

    console.log("bank_search_data : ", data);
    return {
      ...state,
      banks: data
    }
    //   const subscribeTopicByScreen = {
    //     method: "bank_search",
    //     keyword: "deposit"
    //   }

    //   ws.send(JSON.stringify(subscribeTopicByScreen));
  },
  bank_update: (state, { type, value }) => {
    const { message, data } = value
    //console.log("bank_search", value);
    if (message !== 'success') {
      return {
        ...state,
        toast: {
          show: true,
          type: 'danger',
          title: `อัพเดทข้อมูลธนาคาร`,
          message: `ขออภัยระบบไม่สามารถทำรายการได้ในขณะนี้`,
        }
      }
    }

    console.log("bank_update_data : ", data);
    return {
      ...state,
      toast: {
        show: true,
        type: 'success',
        title: `อัพเดทข้อมูลธนาคาร`,
        message: `อัพเดทข้อมูลธนาคาร สำเร็จ`,
      }
    }
  },
  dropdown_bank: (state, { type, value }) => {
    const { message, data } = value
    //console.log("bank_search", value);
    if (message !== 'success') {
      return {
        ...state,
        toast: {
          show: true,
          type: 'danger',
          title: `ข้อมูล list ธนาคาร`,
          message: `ขออภัยไม่พบข้อมูล list ธนาคาร`,
        }
      }
    }

    console.log("bank_search_data : ", data);
    return {
      ...state,
      dropdown_bank: data
    }
    //   const subscribeTopicByScreen = {
    //     method: "bank_search",
    //     keyword: "deposit"
    //   }

    //   ws.send(JSON.stringify(subscribeTopicByScreen));
  },
  user_search: (state, { type, value }) => {
    const { message, data } = value
    //console.log("bank_search", value);
    if (message !== 'success') {
      return {
        ...state,
        toast: {
          show: true,
          type: 'danger',
          title: `ค้นหาข้อมูล CC User`,
          message: `ขออภัยระบบไม่สามารถทำรายการได้ในขณะนี้`,
        }
      }
    }

    console.log("bank_search_data : ", data);
    return {
      ...state,
      users: data
    }
    //   const subscribeTopicByScreen = {
    //     method: "bank_search",
    //     keyword: "deposit"
    //   }

    //   ws.send(JSON.stringify(subscribeTopicByScreen));
  },
  user_create: (state, { value }) => {
    const { message, data } = value
    console.log("user_create", value);
    if (message !== 'success') {
      return {
        ...state,
        toast: {
          show: true,
          type: 'danger',
          title: `สร้าง CC Users`,
          message: `ขออภัยระบบไม่สามารถทำรายการได้ในขณะนี้`,
        }
      }
    }

    console.log("user_create", data);
    return {
      ...state,
      toast: {
        show: true,
        type: 'success',
        title: `สร้าง CC Users`,
        message: `สร้าง CC Users สำเร็จ`,
      }
    }
  },
  user_update: (state, { value }) => {
    const { message, data } = value
    console.log("user_update_data", value);
    if (message !== 'success') {
      return {
        ...state,
        toast: {
          show: true,
          type: 'danger',
          title: `แก้ไข CC Users`,
          message: `ขออภัยระบบไม่สามารถทำรายการได้ในขณะนี้`,
        }
      }
    }

    //console.log("user_update_data", data);
    return {
      ...state,
      toast: {
        show: true,
        type: 'success',
        title: `แก้ไช CC Users`,
        message: `แก้ไข CC Users ${data.user_id} สำเร็จ`,
      }
    }
  },
  dashboard_work_order_deposit: (state, { value }) => {
    const { message } = value
    console.log("dashboard_work_order_deposit", message);

    if (message !== 'success') {
      return {
        ...state,
        toast: {
          show: true,
          type: 'danger',
          title: `ค้นหาข้อมูลลูกค้า`,
          message: `ขออภัยระบบไม่สามารถทำรายการได้ในขณะนี้`,
        }
      }
    }
    return {
      ...state,
      dashboard_work_order_deposit: value.data
    }
  },
  dashboard_work_order_withdraw: (state, { value }) => {
    const { message } = value
    console.log("dashboard_work_order_withdraw", message);

    if (message !== 'success') {
      return {
        ...state,
        toast: {
          show: true,
          type: 'danger',
          title: `ค้นหาข้อมูลลูกค้า`,
          message: `ขออภัยระบบไม่สามารถทำรายการได้ในขณะนี้`,
        }
      }
    }
    // console.log()
    return {
      ...state,
      dashboard_work_order_withdraw: value.data
    }
  },
  dashboard_work_order_sweep_withdraw: (state, { value }) => {
    const { message } = value
    console.log("dashboard_work_order_sweep_withdraw", message);

    if (message !== 'success') {
      return {
        ...state,
        toast: {
          show: true,
          type: 'danger',
          title: `ค้นหาข้อมูลลูกค้า`,
          message: `ขออภัยระบบไม่สามารถทำรายการได้ในขณะนี้`,
        }
      }
    }
    // console.log()
    return {
      ...state,
      dashboard_work_order_sweep_wihdraw: value.data
    }
  },
  dashboard_work_order_sweep_holding: (state, { value }) => {
    const { message } = value
    console.log("dashboard_work_order_sweep_holding", message);

    if (message !== 'success') {
      return {
        ...state,
        toast: {
          show: true,
          type: 'danger',
          title: `ค้นหาข้อมูลลูกค้า`,
          message: `ขออภัยระบบไม่สามารถทำรายการได้ในขณะนี้`,
        }
      }
    }
    // console.log()
    return {
      ...state,
      dashboard_work_order_sweep_holding: value.data
    }
  },
  dropdown_bank_2: (state, { value }) => {
    const { message } = value
    console.log("dropdown_bank", value);
    if (message !== 'success') {
      return {
        ...state,
        toast: {
          show: true,
          type: 'danger',
          title: `ค้นหาข้อมูลลูกค้า`,
          message: `ขออภัยระบบไม่สามารถทำรายการได้ในขณะนี้`,
        }
      }
    }
    // console.log()
    return {
      ...state,
      dropdown_bank: value.data
    }
  },

  work_order_detail: (state, { value }) => {
    const { message } = value
    //console.log("work_order_detail change", value);
    if (message !== 'success') {
      return {
        ...state,
        toast: {
          show: true,
          type: 'danger',
          title: `ค้นหาข้อมูลลูกค้า`,
          message: `ขออภัยระบบไม่สามารถทำรายการได้ในขณะนี้`,
        }
      }
    }
    // console.log()
    return {
      ...state,
      work_order_detail: value.data
    }
  },

  dashboard_summary: (state, { value }) => {
    const { message } = value
    console.log("dashboard_summary", value);

    if (message !== 'success') {
      return {
        ...state,
        toast: {
          show: true,
          type: 'danger',
          title: `ค้นหาข้อมูลลูกค้า`,
          message: `ขออภัยระบบไม่สามารถทำรายการได้ในขณะนี้`,
        }
      }
    }
    // console.log()
    return {
      ...state,
      dashboard_summary: value.data
    }
  },
  dashboard_work_order_bonus_lost: (state, { type, value }) => {
    const { message } = value
    console.log("dashboard_work_order_bonus_lost", message);

    if (message !== 'success') {
      return {
        ...state,
        toast: {
          show: true,
          type: 'danger',
          title: `ค้นหาข้อมูลลูกค้า`,
          message: `ขออภัยระบบไม่สามารถทำรายการได้ในขณะนี้`,
        }
      }
    }

    return {
      ...state,
      dashboard_work_order_bonus_lost: value.data
    }
  },
  dashboard_work_order_bonus_manual: (state, { type, value }) => {
    const { message } = value
    console.log("dashboard_work_order_bonus_manual", message);

    if (message !== 'success') {
      return {
        ...state,
        toast: {
          show: true,
          type: 'danger',
          title: `ค้นหาข้อมูลลูกค้า`,
          message: `ขออภัยระบบไม่สามารถทำรายการได้ในขณะนี้`,
        }
      }
    }

    return {
      ...state,
      dashboard_work_order_bonus_manual: value.data
    }
  },
  dashboard_work_order_true_wallet: (state, { value }) => {
    const { message } = value
    console.log("dashboard_work_order_true_wallet", message);

    if (message !== 'success') {
      return {
        ...state,
        toast: {
          show: true,
          type: 'danger',
          title: `ค้นหาข้อมูลลูกค้า`,
          message: `ขออภัยระบบไม่สามารถทำรายการได้ในขณะนี้`,
        }
      }
    }
    return {
      ...state,
      dashboard_work_order_true_wallet: value.data
    }
  },

  SOCKET_SEND: (state, { value }) => {
    // console.log("global subscribe :", value);
    return {
      ...state,
      subscribe: value
    }
  },

  SOCKET_DISCONNECT: (state, { value }) => {
    // console.log("global subscribe :", null);
    return {
      ...state,
      subscribe: null
    }
  },

  // TOAST: (state, { value }) => {
  //   console.log("TOAST DISPATCH STATE: ", state.toast)
  //   console.log("TOAST DISPATCH VALUE: ", value)
  //   return {
  //     ...state,
  //     toast: {
  //       show: value
  //     }
  //   }
  // },
  TOAST2: (state, { value }) => {
    return {
      ...state,
      toast: {
        show: value.show,
        type: value.type,
        title: value.title,
        message: value.message,
      }
    }
  },
  reconnect_socket: (state, { value }) => {
    //console.log("bank_search", value);
    return {
      ...state,
      reconnect_socket: value
    }
  },
})

const initState = {
  subscribe: null,
  topic: null,
  toast: {
    show: false,
    type: '',
    title: '',
    message: '',
  },
  login_profile: localStorage.getAllAccess(),
  status: null,
  reconnect_socket: true
}

export const GlobalProvider = ({ children }) => {
  return (
    <StateContext.Provider value={useReducer(reducer, initState)}>
      {children}
    </StateContext.Provider>
  )
}

export const useGlobalStore = () => useContext(StateContext)