import React, {useState, useEffect, useRef, useMemo} from 'react'
import {Col, Button, Form, Fade, Card, Spinner, Alert, InputGroup, Pagination} from 'react-bootstrap'
import ModalSearchCustomer from './ModalSearchCustomer'
import { useForm } from 'react-hook-form'
import TableSearchCustomer from './TableSearchCustomer'
import { useGlobalStore } from '../../store/globalContext'
import api from "../../api/customer";

const searchConditions = [
    {label:"ยูสเซอร์(user id)", value:"user_id"},
    {label:"ชื่อ - นามสกุล", value:"name"},
    {label:"เบอร์โทรศัพท์", value:"mobile_no"},
    {label:"หมายเลขบัญชี", value:"bank_account_no"},
    {label:"id", value:"id"},
]

const RegisterNewComponent = ({validated, handleSubmit, onSubmit, modalError, modalErrorMessage, checkAgentType, agentOptions, manualUserIdFlag, agentIDOptions, watch, bankOptions, channelOptions, errors, register})=>{

    return(
        <Card className="card-info">
            <Card.Header><h5>สมัครลูกค้าใหม่</h5></Card.Header>
            <Card.Body className="row">
                <Form noValidate validated={validated} onSubmit={handleSubmit(onSubmit)} className="col-md-12">
                    <Form.Row>
                        <Form.Group as={Col} md="12" lg="12">
                            <Alert variant="danger" show={modalError}>
                                {modalErrorMessage}
                            </Alert>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row >
                        <Form.Group as={Col} md="3" lg="3" controlId="validationFormik01">
                            <Form.Label>เลือก Agent</Form.Label>
                            <Form.Control as="select"
                                          required
                                          name="agent_name"
                                          defaultValue=""
                                          isInvalid={!!errors.agent_name}
                                          custom
                                          ref={register({ required: true })}
                                          onChange={e => checkAgentType(e.target.value)}
                            >
                                <option value="">Please Select</option>
                                {
                                    agentOptions.map(({ innerText, value }, index) =>
                                        <option
                                            key={index}
                                            value={value}
                                        >
                                            {innerText}
                                        </option>
                                    )
                                }
                            </Form.Control>
                            <Form.Control.Feedback type="invalid" tooltip>
                                กรุณาเลือก Agent
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="3" lg="3" controlId="validationFormik14">
                            <Form.Label>User ID (Manual)</Form.Label>
                            <Form.Control
                                type="text"
                                name="user_id"
                                placeholder="ufyuxxxxx/ahagxxxxx"
                                isInvalid={!!errors.user_id}
                                readOnly={manualUserIdFlag}
                                ref={register({ required: !manualUserIdFlag })}
                            />
                            <Form.Control.Feedback type="invalid" tooltip>
                                กรุณาระบุ UserID
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="3" lg="3" controlId="validationFormik15">
                            <Form.Label>เลือก Agent ที่ต้องการ</Form.Label>
                            <Form.Control as="select"
                                          required
                                          name="agent_id"
                                          defaultValue=""
                                          isInvalid={!!errors.agent_id}
                                          custom
                                          ref={register({ required: false })}
                            >
                                <option value="">Please Select</option>
                                {
                                    agentIDOptions.map(({ agentName, agentID }, index) =>
                                        <option
                                            key={index}
                                            value={agentID}
                                        >
                                            {agentName}
                                        </option>
                                    )
                                }
                            </Form.Control>
                            <Form.Control.Feedback type="invalid" tooltip>
                                กรุณาเลือก Agent
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} md="3" lg="3" controlId="validationFormik02">
                            <Form.Label>ชื่อ</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                name="first_name"
                                placeholder="ยูฟ่า"
                                isInvalid={!!errors.first_name}
                                ref={register({ required: true })}
                            />
                            <Form.Control.Feedback type="invalid" tooltip>
                                กรุณากรอกชื่อ
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="3" lg="3" controlId="validationFormik03">
                            <Form.Label>นามสกุล</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                name="last_name"
                                placeholder="ฟีเวอร์"
                                isInvalid={!!errors.last_name}
                                // isValid={touched.lastName && !errors.lastName}
                                ref={register({ required: true })}
                            />
                            <Form.Control.Feedback type="invalid" tooltip>
                                กรุณากรอกนามสกุล
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="3" lg="3" controlId="validationFormik04">
                            <Form.Label>เบอร์โทรศัพท์</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                placeholder="eg. 081xxxxxxx"
                                name="mobile_no"
                                isInvalid={!!errors.mobile_no}
                                ref={register({
                                    required: true,
                                    pattern: /^\d{10}$/
                                })}
                            />

                            <Form.Control.Feedback type="invalid" tooltip>
                                กรุณาระบุเบอร์โทรศัพท์
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="3" lg="3" controlId="validationFormik05">
                            <Form.Label>LineID</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                placeholder="eg. @feverline"
                                name="line_id"
                                isInvalid={!!errors.line_id}
                                ref={register({ required: true })}
                            />
                            <Form.Control.Feedback type="invalid" tooltip>
                                กรุณากรอก LineID
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} md="3" lg="3" controlId="validationFormik06">
                            <Form.Label>เลือกธนาคารหลัก</Form.Label>
                            <Form.Control as="select"
                                          required
                                          name="main_bank_account_name"
                                          defaultValue=""
                                          isInvalid={!!errors.main_bank_account_name}
                                          custom
                                          ref={register({ required: true })}
                            >
                                <option value="">Please Select</option>
                                {
                                    bankOptions.map(({ innerText, value }, index) =>
                                        <option
                                            key={index}
                                            value={value}
                                        >
                                            {innerText}
                                        </option>
                                    )
                                }
                            </Form.Control>
                            <Form.Control.Feedback type="invalid" tooltip>
                                กรุณาเลือกธนาคารหลัก
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="3" lg="3" controlId="validationFormik07">
                            <Form.Label>เลขที่บัญชีธนาคารหลัก</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                name="main_bank_account_no"
                                placeholder="eg. 089XXXXXXX"
                                ref={register({
                                    required: true,
                                    pattern: /^\d{10,}$/
                                })}
                                isInvalid={!!errors.main_bank_account_no}
                            />
                            <Form.Control.Feedback type="invalid" tooltip>
                                กรุณากรอกเลขที่บัญชีหลัก
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} md="3" lg="3" controlId="validationFormik08">
                            <Form.Label>เลือกธนาคารสำรอง 1</Form.Label>
                            <Form.Control as="select"
                                          name="sub1_bank_account_name"
                                          ref={register}
                                          custom
                            >
                                <option value="">Please Select</option>
                                {
                                    bankOptions.filter((bank) => bank.value !== watch("main_bank_account_name")).map(({ innerText, value }, index) =>
                                        <option
                                            key={index}
                                            value={value}
                                        >
                                            {innerText}
                                        </option>
                                    )
                                }
                            </Form.Control>
                        </Form.Group>
                        <Form.Group as={Col} md="3" lg="3" controlId="validationFormik09">
                            <Form.Label>เลขที่บัญชีธนาคารสำรอง 1</Form.Label>
                            <Form.Control
                                type="text"
                                name="sub1_bank_account_no"
                                placeholder="eg. 089XXXXXXX"
                                ref={register({
                                    pattern: /^\d{10,}$/,
                                    required: watch("sub1_bank_account_name") !== "",
                                })}
                                isInvalid={!!errors.sub1_bank_account_no}
                            />
                            <Form.Control.Feedback type="invalid" tooltip>
                                กรุณากรอกเลขที่บัญชีสำรอง 1
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="3" lg="3" controlId="validationFormik10">
                            <Form.Label>เลือกธนาคารสำรอง 2</Form.Label>
                            <Form.Control as="select"
                                          name="sub2_bank_account_name"
                                          custom
                                          ref={register}
                            >
                                <option value="">Please Select</option>
                                {
                                    bankOptions.filter((bank) => (bank.value !== watch("main_bank_account_name") && bank.value !== watch("sub1_bank_account_name"))).map(({ innerText, value }, index) =>
                                        <option
                                            key={index}
                                            value={value}
                                        >
                                            {innerText}
                                        </option>
                                    )
                                }
                            </Form.Control>
                        </Form.Group>
                        <Form.Group as={Col} md="3" lg="3" controlId="validationFormik11">
                            <Form.Label>เลขที่บัญชีธนาคารสำรอง 2</Form.Label>
                            <Form.Control
                                type="text"
                                name="sub2_bank_account_no"
                                placeholder="eg. 089XXXXXXX"
                                ref={register({
                                    pattern: /^\d{10,}$/,
                                    required: watch("sub2_bank_account_name") !== "",
                                })}
                                isInvalid={!!errors.sub2_bank_account_no}
                            />
                            <Form.Control.Feedback type="invalid" tooltip>
                                กรุณากรอกเลขที่บัญชีสำรอง 2
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} md="3" lg="3" controlId="validationFormik12">
                            <Form.Label>ช่องทางการสมัคร</Form.Label>
                            <Form.Control as="select"
                                          required
                                          name="channel_select"
                                          custom
                                          defaultValue=""
                                          ref={register({ required: true })}
                                          isInvalid={!!errors.channel_select}
                            >
                                <option value="">Please Select</option>
                                {
                                    channelOptions.map(({ innerText, value }, index) =>
                                        <option
                                            key={index}
                                            value={value}
                                        >
                                            {innerText}
                                        </option>
                                    )
                                }
                            </Form.Control>
                            <Form.Control.Feedback type="invalid" tooltip>
                                กรุณาระบุช่องทางการสมัคร
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="3" lg="3" controlId="validationFormik13">
                            <Form.Label>Reference UserID</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                name="reference_user_id"
                                defaultValue=""
                                placeholder="eg. ufyu888xxxxx"
                                ref={register}
                            />
                        </Form.Group>

                    </Form.Row>
                    <Button type="submit" className="btn-success">สมัคร</Button>
                </Form>
                {/* </div>
                            </div> */}
            </Card.Body>
        </Card>
    )
}


export default function RegisterCustomer() {
    const [{ dashboard_customers, login_profile }, dispatch] = useGlobalStore();
    const [agentIDOptions, setAgentIDOption] = useState([])
    const [bankOptions, setBankOptions] = useState([]);

    const channelOptions = [
        { innerText: "Google", value: "google" },
        { innerText: "Facebook", value: "facebook" },
        { innerText: "Twitter", value: "twitter" },
        { innerText: "Youtube", value: "youtube" },
        { innerText: "TikTok", value: "tiktok" },
        { innerText: "เพื่อนแนะนำ", value: "friends" }
    ]

    const [validated, setValidated] = useState(false);
    const { register, handleSubmit, watch, errors, reset, setValue, getValues } = useForm({
        defaultValues:{currentPage:1},
        criteriaMode: "all"
    });

    const [searchData, setSearchData] = useState([]);

    const [modalError, setModalError] = useState(false)
    const [modalErrorMessage, setModalErrorMessage] = useState("")
    const [manualUserIdFlag, setManualUserIdFlag] = useState(true)
    const [dictLastIndices, setDictLastIndices] = useState({1: ""})
    const limitPage = 10
    const [searchButton, setSearchButton] = useState(false)
    const [errorAlertModal, setErrorAlertModal] = useState("")

    const [searchPlaceholder, setSearchPlaceHolder] = useState("ยูสเซอร์ไอดี/ชื่อ/นามสกุล/เบอร์โทรศัพท์/เลขที่บัญชี")
    const watchFieldSearch = watch("field")
    const watchCurrentPage = watch("currentPage")

    if (process.env.REACT_APP_GCLUB_REGISTER_FLAG === "disable"){
        agentOptions = [
            { innerText: "AUTO UFA", value: "ufa_auto" },
            { innerText: "Agent UFA", value: "ufa" },
        ]
    }

    const onSubmit = async registerData => {
        // console.log("registerData :", registerData)
        // return
        setModalError(false);
        let registerDataWithMethod = {
            ...registerData,
            status: "normal",
            // method: "customer_register",
            bank_accounts: [
                {
                    "bank_short_name": registerData.main_bank_account_name,
                    "bank_account_name": bankOptions.find(({ value }) => value === registerData.main_bank_account_name)?.innerText,
                    "bank_account_no": registerData.main_bank_account_no,
                    "bank_code": bankOptions.find(({ value }) => value === registerData.main_bank_account_name)?.code,
                    "is_main_bank_account": true,
                }
            ],
            created_by: login_profile.name,
            updated_by: login_profile.name
        }

        if (registerData.sub1_bank_account_name !== "") {
            registerDataWithMethod.bank_accounts.push(
                {
                    "bank_short_name": registerData.sub1_bank_account_name,
                    "bank_account_name": bankOptions.find(({ value }) => value === registerData.sub1_bank_account_name)?.innerText,
                    "bank_account_no": registerData.sub1_bank_account_no,
                    "bank_code": bankOptions.find(({ value }) => value === registerData.sub1_bank_account_name)?.code,
                    "is_main_bank_account": false,
                })
        }

        if (registerData.sub2_bank_account_no !== "") {
            registerDataWithMethod.bank_accounts.push(
                {
                    "bank_short_name": registerData.sub2_bank_account_name,
                    "bank_account_name": bankOptions.find(({ value }) => value === registerData.sub2_bank_account_name)?.innerText,
                    "bank_account_no": registerData.sub2_bank_account_no,
                    "bank_code": bankOptions.find(({ value }) => value === registerData.sub2_bank_account_name)?.code,
                    "is_main_bank_account": false,
                }
            )
        }

        try {
            const response = await api.CREATE_CUSTOMER(registerDataWithMethod);

            const { data } = response.data
            //console.log("create customer data: ", data)

            if (data == null) {
                return
            }
            reset({ register });
        } catch (error) {
            //console.log("error register new customer:", error.response.data)
            const { message } = error.response.data

            switch (message){
                case message === "duplicate customer by name" :
                    setModalErrorMessage("ขออภัย ชื่อ-นามสกุลซ้ำ กรุณาตรวจสอบ");
                    break
                case message === "duplicate customer by mobile number":
                    setModalErrorMessage("ขออภัย เบอร์มือถือซ้ำ กรุณาตรวจสอบ");
                    break
                case message === "duplicate customer by bank":
                    setModalErrorMessage("ขออภัย เลขที่บัญชีซ้ำ กรุณาตรวจสอบ");
                    break
                case message === "duplicate customer by user id":
                    setModalErrorMessage("ขออภัย user id ซ้ำในระบบ กรุณาตรวจสอบ");
                    break
                case message === "blacklist customer":
                    setModalErrorMessage("ขออภัย user ติด blacklist กรุณาตรวจสอบ");
                    break
                default:
                    if (!message){
                        setModalErrorMessage("ขออภัยทำรายการไม่สำเร็จ กรุณาติดต่อ admin");
                    }else{
                        setModalErrorMessage(message);
                    }
            }

            setModalError(true)
        }
        void keySearchInput("", "",true)
    }


    const getAgentIDListByAgentName = async (value) => {
        try {
            const response = await api.GET_AGENT_LIST();
            if (response.status !== 200) {
                console.log("get agent list failed");
                return
            }

            const { data } = response.data
            //console.log("agent list data: ", data)
            let dropdownAgentList = []
            data.forEach(key => {
                if (value === "ufa" || value === "ufa_auto"){
                    dropdownAgentList.push({ agentName: key.agent_name, agentID: key.ufa_agent_id })
                }else{
                    dropdownAgentList.push({ agentName: key.agent_name, agentID: key.gclub_agent_id })
                }

            })
            //console.log("bank options data: ", data)
            setAgentIDOption(dropdownAgentList);
            //console.log("dropdownAgentList: ", dropdownAgentList);
        } catch (error) {
            console.log('get agent id list error : ', error)
        }
    }

    useEffect(() => {
        //console.log("register customer screen before dispatch");
        try {
            const subscribeScreen = { method: "subscribe", topic: "dashboard_customer" }
            dispatch({
                type: "SOCKET_SEND",
                value: subscribeScreen
            });
        } catch (error) {
            console.log("error :", error);
            dispatch({
                type: "TOAST2",
                value: {
                    show: true,
                    type: 'danger',
                    title: `ระบบมีปัญหา`,
                    message: `ขออภัยระบบมีปัญหา กรุณาติดต่อ admin`,
                }
            });
        }
        const getBankList = async () => {
            try {
                const response = await api.GET_THAI_BANK_API();
                // console.log("get bank list response", { ...response });
                //console.log(response.data.data);
                if (response.status !== 200) {
                    console.log("get bank list failed");
                    return
                }
                const { data } = response.data
                // console.log("bank_list_data: ", data)
                let dropdownBankList = []
                data.forEach(key => {
                    dropdownBankList.push({ innerText: key.text, value: key.value, code: key.code })
                })
                //console.log("bank options data: ", data)
                setBankOptions(dropdownBankList);
                //console.log(response.data.data);
            } catch (error) {
                console.log("get bank list error : ", error);
            }
        }

        void getBankList();
    }, [])



    const keySearchInput = async (value, field ,firstTimeFlag) => {

        // console.log("search input: ", value, field, firstTimeFlag)
        // console.log("currentPage: ", getValues("currentPage"))
        // console.log("verified: ", getValues("verified"))

        if (firstTimeFlag === false && getValues("verified") === false){
            if (value.length === 0 && value.length < 2) {
                setSearchButton(false)
                return
            }
        }

        setSearchButton(true)

        let searchData
        if (!getValues("verified")) {
            searchData = {
              // method: "customer_search",
              field: field,
              keyword: value,
              limit: limitPage,
              last_element_id: dictLastIndices[getValues("currentPage")-1]
            }
        } else {
            searchData = {
                // method: "customer_search",
                field: field,
                keyword: value,
                verified: false,
                limit: limitPage,
                last_element_id: dictLastIndices[getValues("currentPage")-1]
            }
        }

        const response = await api.SEARCH_CUSTOMER(searchData);
        if (response.status !== 200) {
            //console.log("search customer failed");
            setSearchButton(false)
            return
        }
        const { data } = response.data
        //console.log("search customer data: ", data)

        if (!data) {
            setSearchButton(false)
            return
        }

        let searchDataList = [];

        data.forEach(element => {
            const agents = element.agents
            let dataBank = {}
            if (agents === undefined || agents === null){

                const customerBankList = element.bank_accounts
                customerBankList.map((ele, index) => {

                    let bankObj
                    if (index === 0 || ele.is_main_bank_account === true) {
                        bankObj = {
                            main_bank_account_name: ele.bank_account_name,
                            main_bank_account_no: ele.bank_account_no,
                            main_bank_short_name: ele.bank_short_name
                        }
                        dataBank = ({ ...dataBank, ...bankObj })
                    }

                    if (index === 1) {
                        bankObj = {
                            sub1_bank_account_name: ele.bank_account_name,
                            sub1_bank_account_no: ele.bank_account_no,
                            sub1_bank_short_name: ele.bank_short_name
                        }
                        dataBank = ({ ...dataBank, ...bankObj })
                    }

                    if (index > 1) {
                        bankObj = {
                            sub2_bank_account_name: ele.bank_account_name,
                            sub2_bank_account_no: ele.bank_account_no,
                            sub2_bank_short_name: ele.bank_short_name
                        }
                        dataBank = ({ ...dataBank, ...bankObj })
                    }
                    //console.log("data bank :", dataBank)
                })

                const emptyAgents = {
                    agents: []
                }

                searchDataList.push({ ...element, ...emptyAgents, ...dataBank })
            }else if (agents.length === 0){
                const customerBankList = element.bank_accounts
                customerBankList.map((ele, index) => {

                    let bankObj
                    if (index === 0 || ele.is_main_bank_account === true) {
                        bankObj = {
                            main_bank_account_name: ele.bank_account_name,
                            main_bank_account_no: ele.bank_account_no,
                            main_bank_short_name: ele.bank_short_name
                        }
                        dataBank = ({ ...dataBank, ...bankObj })
                    }

                    if (index === 1) {
                        bankObj = {
                            sub1_bank_account_name: ele.bank_account_name,
                            sub1_bank_account_no: ele.bank_account_no,
                            sub1_bank_short_name: ele.bank_short_name
                        }
                        dataBank = ({ ...dataBank, ...bankObj })
                    }

                    if (index > 1) {
                        bankObj = {
                            sub2_bank_account_name: ele.bank_account_name,
                            sub2_bank_account_no: ele.bank_account_no,
                            sub2_bank_short_name: ele.bank_short_name
                        }
                        dataBank = ({ ...dataBank, ...bankObj })
                    }
                    //console.log("data bank :", dataBank)
                })

                searchDataList.push({ ...element, ...dataBank })
            } else {
                let trueObj
                agents.forEach(el =>{
                    const customerBankList = element.bank_accounts
                    //console.log("customerBankList: ", customerBankList)
                    //console.log("deposit_inquiry_bank_account_no : ", el.deposit_inquiry_bank_account_no)
                    element.username = el.username
                    element.agent_name = el.agent_name
                    element.user_id = el.username
                    element.created_at = el.created_at
                    element.new_customer = el.new_customer
                    element.ref_user_id = el.ref_user_id

                    customerBankList.map((ele, index) => {

                        let bankObj

                        if (index === 0 || ele.is_main_bank_account === true) {
                            bankObj = {
                                main_bank_account_name: ele.bank_account_name,
                                main_bank_account_no: ele.bank_account_no,
                                main_bank_short_name: ele.bank_short_name,
                                deposit_inquiry_bank_account_no: el.deposit_inquiry_bank_account_no ? el.deposit_inquiry_bank_account_no : "-",
                                deposit_inquiry_bank_account_name: el.deposit_inquiry_bank_account_name ? el.deposit_inquiry_bank_account_name : "รอแจกบัญชี",
                                deposit_inquiry_bank_name: el.deposit_inquiry_bank_name ? el.deposit_inquiry_bank_name : "-",
                                deposit_inquiry_bank_short_name: el.deposit_inquiry_bank_short_name ? el.deposit_inquiry_bank_short_name : "not_found",
                                deposit_inquiry_bank_code: el.deposit_inquiry_bank_code ? el.deposit_inquiry_bank_code : "-"
                            }
                            dataBank = ({ ...dataBank, ...bankObj })
                        }

                        if (index === 1) {
                            bankObj = {
                                sub1_bank_account_name: ele.bank_account_name,
                                sub1_bank_account_no: ele.bank_account_no,
                                sub1_bank_short_name: ele.bank_short_name,
                                deposit_inquiry_bank_account_no: el.deposit_inquiry_bank_account_no ? el.deposit_inquiry_bank_account_no : "-",
                                deposit_inquiry_bank_account_name: el.deposit_inquiry_bank_account_name ? el.deposit_inquiry_bank_account_name : "รอแจกบัญชี",
                                deposit_inquiry_bank_name: el.deposit_inquiry_bank_name ? el.deposit_inquiry_bank_name : "-",
                                deposit_inquiry_bank_short_name: el.deposit_inquiry_bank_short_name ? el.deposit_inquiry_bank_short_name : "not_found",
                                deposit_inquiry_bank_code: el.deposit_inquiry_bank_code ? el.deposit_inquiry_bank_code : "-"
                            }
                            dataBank = ({ ...dataBank, ...bankObj })
                        }

                        if (index > 1) {
                            bankObj = {
                                sub2_bank_account_name: ele.bank_account_name,
                                sub2_bank_account_no: ele.bank_account_no,
                                sub2_bank_short_name: ele.bank_short_name,
                                deposit_inquiry_bank_account_no: el.deposit_inquiry_bank_account_no ? el.deposit_inquiry_bank_account_no : "-",
                                deposit_inquiry_bank_account_name: el.deposit_inquiry_bank_account_name ? el.deposit_inquiry_bank_account_name : "รอแจกบัญชี",
                                deposit_inquiry_bank_name: el.deposit_inquiry_bank_name ? el.deposit_inquiry_bank_name : "-",
                                deposit_inquiry_bank_short_name: el.deposit_inquiry_bank_short_name ? el.deposit_inquiry_bank_short_name : "not_found",
                                deposit_inquiry_bank_code: el.deposit_inquiry_bank_code ? el.deposit_inquiry_bank_code : "-"
                            }
                            dataBank = ({ ...dataBank, ...bankObj })
                        }

                        if(ele.bank_code === "TMN"){
                            trueObj = {
                                true_wallet: ele.bank_account_no
                            }
                        }
                    })

                    searchDataList.push({ ...element, ...dataBank, ...trueObj })
                })
            }
        })

        setSearchData(searchDataList);
        setSearchButton(false)

        if (searchDataList) {
            const newIndex = {...dictLastIndices, [getValues("currentPage")]:searchDataList[searchDataList.length - 1].id}
            setDictLastIndices(newIndex)
        }
    }

    const [modalInfo, setModalInfo] = useState({})
    const [isOpenModal, setisOpenModal] = useState(false)

    const openCustomerModal = (customerData, flag) => {
        setModalInfo(customerData);
        setisOpenModal(flag);
    }
    const handleCloseModal = () => {
        setisOpenModal(false)
    }

    const handleEditCustomerInfo = async ({ updateCustomer }) => {
        //console.log("handleEditCustomerInfo: ", updateCustomer)
        //return
        let customerData = {
            ...updateCustomer,
            method: "customer_update",
            bank_accounts: [
                {
                    "bank_short_name": updateCustomer.main_bank_short_name,
                    "bank_account_name": bankOptions.find(({ value }) => value === updateCustomer.main_bank_short_name)?.innerText,
                    "bank_code": bankOptions.find(({ value }) => value === updateCustomer.main_bank_short_name)?.code,
                    "bank_account_no": updateCustomer.main_bank_account_no,
                    "is_main_bank_account": true,
                }
            ]
        }

        if (updateCustomer.sub1_bank_short_name !== "" && updateCustomer.sub1_bank_short_name !== undefined) {
            customerData.bank_accounts.push(
                {
                    "bank_short_name": customerData.sub1_bank_short_name,
                    "bank_account_name": bankOptions.find(({ value }) => value === updateCustomer.sub1_bank_short_name)?.innerText,
                    "bank_account_no": customerData.sub1_bank_account_no,
                    "bank_code": bankOptions.find(({ value }) => value === updateCustomer.sub1_bank_short_name)?.code,
                    "is_main_bank_account": false,
                })
        }

        if (updateCustomer.sub2_bank_short_name !== "" && updateCustomer.sub2_bank_short_name !== undefined) {
            customerData.bank_accounts.push(
                {
                    "bank_short_name": customerData.sub2_bank_short_name,
                    "bank_account_name": bankOptions.find(({ value }) => value === updateCustomer.sub2_bank_short_name)?.innerText,
                    "bank_code": bankOptions.find(({ value }) => value === updateCustomer.sub2_bank_short_name)?.code,
                    "bank_account_no": customerData.sub2_bank_account_no,
                    "is_main_bank_account": false,
                }
            )
        }

        Object.keys(updateCustomer).forEach(
            key => {
                if (!updateCustomer[key]) {
                    delete updateCustomer[key]
                }
            })


        //console.log("update customer profile : ", customerData);
        try {
            const response = await api.UPDATE_CUSTOMER(customerData);
            const {data} = response.data
            // console.log("update customer data: ", data)
            setisOpenModal(false);
            void keySearchInput(customerData.user_id, "user_id",true);

        }catch (e) {
            console.log("error call api: ", e)
            setErrorAlertModal("ขออภัยไม่สามารถแก้ไขข้อมูลได้ กรุณาตรวจสอบข้อมูลลูกค้าเพิ่มเติม")
        }
    }

    const checkAgentType = (value) =>{
        //console.log("check type value ", value)
        if (value === "ufa" || value === "gclub"){
            setManualUserIdFlag(false)
        }else{
            setManualUserIdFlag(true)
        }
        void getAgentIDListByAgentName(value)
        setValue("user_id","")
    }

    const handlePrevPage = () => {
        if (watchCurrentPage !== 1) {
            // setCurrentPage(currentPage - 1)
            setValue("currentPage", getValues("currentPage")-1)
            void keySearchInput(getValues("search_customer"), getValues("field"), true)
        }
    }

    const handleNextPage = () => {
        if (!searchData) {
            return
        }
        //
        const setId = [...new Set(searchData.map(e => e.id))]
        if (setId.length === limitPage) {
            setValue("currentPage",getValues("currentPage") +1)
        }
        void keySearchInput(getValues("search_customer"), getValues("field"), true)
    }

    const resetSearchKeyword = ()=>{
        // handleSearchCondition("")
        setValue("field","")
        setValue("search_customer", "")
        setValue("currentPage", 1)
        setValue("verified", false)
        // setCurrentPage(1)
        void keySearchInput("", "", true)
    }

    useEffect(() => {
        void keySearchInput(getValues("search_customer"), "",true)
        void getAgentList()
    }, [])


    const [agentOptions, setAgentOptions] = useState([
        { innerText: "AUTO UFA", value: "ufa_auto" },
        { innerText: "AUTO GCLUB", value: "gclub_auto" },
        { innerText: "Agent UFA", value: "ufa" },
        { innerText: "Agent GCLUB", value: "gclub" }
    ]);
    const getAgentList = async () =>{
        try {
            const response = await api.LIST_AGENTS(searchData);
            const {data} = response.data

            // console.log("get agent list data: ", data)

            let agentMap = []
            data.map((d)=>{
                agentMap.push({innerText:d.text, value: d.value})
            })

            setAgentOptions(agentMap)

        } catch (error){
            console.log("get agent list data error: ", error)
        }
    }

    return (
        <div className="pt-4 pr-4 pl-4">
            <ModalSearchCustomer
                isOpenModal={isOpenModal}
                customerData={modalInfo}
                onClose={handleCloseModal}
                onSave={handleEditCustomerInfo}
                callBackSearch={() => keySearchInput(getValues("search_customer"), watchFieldSearch,false)}
                alertError={errorAlertModal}
            />
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 pt-3">
                            <Card className="card-primary">
                                <Card.Header><h5>ค้นหาลูกค้า</h5></Card.Header>
                                <Card.Body className="row">
                                    <Form.Row className="col-md-12">
                                        <Form.Group className="col-md-2">
                                            <Form.Label>ค้นหาลูกค้า</Form.Label>
                                                    <Form.Control
                                                        ref={register}
                                                        as={"select"}
                                                        name={"field"}
                                                        custom
                                                    >
                                                        <option value="">เลือกเงื่อนไขการค้นหา</option>
                                                        {
                                                            searchConditions?.map(({ label, value }, index) =>
                                                                <option
                                                                    key={value}
                                                                    value={value}
                                                                >
                                                                    {label}
                                                                </option>
                                                            )
                                                        }
                                                    </Form.Control>
                                        </Form.Group>

                                        <Form.Group className="col-md-6">
                                            <Form.Label>พิมพ์ค้นหาลูกค้า</Form.Label>
                                            <Form.Control
                                                as="input"
                                                ref={register}
                                                name="search_customer"
                                                placeholder={searchPlaceholder}
                                                defaultValue={""}
                                                disabled={watchFieldSearch === ""}
                                            >
                                            </Form.Control>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row className="col-md-12">
                                        <Form.Group as={Col} md="4" lg="4" controlId="validation01">
                                            <InputGroup>
                                                <Form.Check
                                                  ref={register}
                                                  type="switch"
                                                  name="verified"
                                                  label="ค้นหาบัญชีรอการยืนยัน"
                                                />
                                                <Form.Control.Feedback type="invalid" tooltip>
                                                    กรุณาเลือกสถานะการยืนยันบัญชี
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row className="col-md-12">
                                        <Col>
                                        <Button style={{marginBottom: "10px"}}
                                                onClick={() => keySearchInput(getValues("search_customer"), watchFieldSearch,false)} disabled={searchButton}>
                                            {
                                                (searchButton)?
                                                    (<Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />):(<>ค้นหา</>)
                                            }

                                        </Button>
                                        {" "}
                                        <Button variant={"danger"} style={{marginBottom: "10px"}}
                                                onClick={() => resetSearchKeyword()} disabled={searchButton}>
                                            <>ล้างข้อมูล</>
                                        </Button>
                                        </Col>
                                        <Pagination className={"ml-auto"}>
                                            <Pagination.Prev onClick={handlePrevPage}/>
                                            <Pagination.Item active={true}>
                                                <span {...register('currentPage', {valueAsNumber: true})} >{watchCurrentPage}</span>
                                            </Pagination.Item>
                                            <Pagination.Next onClick={handleNextPage}/>
                                        </Pagination>
                                    </Form.Row>
                                    <Form.Row className="col-md-12">
                                        <Form.Group className="col-md-12">
                                            {/*<Fade in={true}>*/}
                                                <TableSearchCustomer customerSearchList={searchData} editCustomerData={openCustomerModal} />
                                            {/*</Fade>*/}
                                        </Form.Group>
                                    </Form.Row>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="col-md-6">
                        </div>
                    </div>
                </div>
            </section>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <RegisterNewComponent validated={validated} handleSubmit={handleSubmit} onSubmit={onSubmit} modalError={modalError} modalErrorMessage={modalErrorMessage} checkAgentType={checkAgentType}
                                                  agentOptions={agentOptions} manualUserIdFlag={manualUserIdFlag} agentIDOptions={agentIDOptions} watch={watch} bankOptions={bankOptions} channelOptions={channelOptions}
                                                  errors={errors} register={register}/>

                        </div>
                        <div className="col-md-6">
                        </div>
                    </div>
                </div>
            </section>
        </div >
    )
}
