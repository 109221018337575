import React, { useState, useEffect } from 'react'
import {
  Button,
  Card,
  Col,
  Fade,
  Form,
  FormControl,
  FormGroup,
  InputGroup,
  Pagination,
  Row,
  Spinner, Table
} from "react-bootstrap";
import { number_format } from "../utils/Number";
import TableSearchCustomer from "./TableSearchCustomer";
import api from "../../api/customer";
import ModalSearchTransactionCustomer from "./ModalSearchTransactionsCustomer";
import api_workorder from "../../api/workorder";
import moment from "moment";
import {register} from "../../serviceWorker";
import {useForm} from "react-hook-form";
import GlobalTable from "./GlobalTable";
import {notificationsRecoilState, notificationsObjectRecoilState} from '../../store/atoms'
import {useRecoilState} from "recoil";
import CheckCustomerQRPayment from "./CheckCustomerQRPayment";
import api_agent from "../../api/agents";



const CheckTurnOverPanel = () => {

  const {register, handleSubmit, errors, setValue, getValues, formState, watch, reset} = useForm({
    defaultValues:{
      turn_userid:'',
      type: '',
      agent_type:'ufa',
      start_date: moment(new Date()).format("YYYY-MM-DD"),
      start_time: "00:00",
      end_date: moment(new Date()).format("YYYY-MM-DD"),
      end_time: "23:59",
    }
  })

  const [isLoading, setIsLoading] = useState(false)
  const watchData = watch()

  const [ turnoverLists, setTurnoverLists ] = useState([])
  const [ amountSummary, setAmountSummary ] = useState({ win: 0.0, draw: 0.0, lose: 0.0, cancel: 0.0, total: 0.0 })
  const [ countSummary, setCountSummary ] = useState({ win: 0.0, draw: 0.0, lose: 0.0, cancel: 0.0, total: 0.0 })
  const [playSummary, setPlaySummary] = useState({totalRec: 0, betAmt:0.0, winAmt:0.0, comAmt:0.0, validAmt:0.0})

  const [notificationObject, setNotificationObject]= useRecoilState(notificationsObjectRecoilState)
  const [activeAgent, setActiveAgent] = useState([]);

  const getActiveAgent = async()=>{
    try {
      const response = await api_agent.GET_ACTIVE_AGENT();

      const { data } = response.data;
      console.log("active agent data: ", data)
      setActiveAgent(data)
    } catch (error) {
      console.log("get active agents error: ", error.message);
    }
  }

  const getAgentIndex = (agent)=>{
    return activeAgent.findIndex(e=> e === agent)
  }

  const submitTurnover = async (data)=>{
    // console.log("submitTurnover: ", data)
    setIsLoading(true)
    if(data.agent_type === "gclub"){
      data = {...data, user_id:data.turn_userid}
      delete data['start_date'];
      delete data['start_time'];
      delete data['end_date'];
      delete data['end_time'];
      //console.log("data GCLUB", data)
    }else{
      data = {
        ...data,
        user_id:data.turn_userid,
        start_time: moment(data.start_date + " " + data.start_time).utcOffset("+0700").format(),
        end_time:moment(data.end_date + " " + data.end_time).utcOffset("+0700").format()
      }
      //console.log("data UFA", data)
    }

      await api.TURNOVER_CUSTOMER(data).then(response => {

        const { amount_summary, count_summary, turnovers, bet_result, play_summary } = response.data.data

        if(data.agent_type === "ufa"){
          setAmountSummary(amount_summary)
          setCountSummary(count_summary)
          setPlaySummary(play_summary)

          if(turnovers === null){
            return
          }

          const dataConvertTransformed = turnovers.map(({
            trans_date,
            ref,
            description,
            bet_amount,
            result_description,
            result_amount,
          }, index) =>(
            {
              ลำดับ: index + 1,
                  วันที่และเวลา: moment(`${trans_date}`).format("DD/MM/YYYY HH:mm:ss"),
                เลขที่อ้างอิง:`${ref}`,
                // ชื่อ: `${name} `,
                ประเภท: `${description} `,
                จำนวนเดิมพัน: `${bet_amount} `,
                // รายละเอียดเดิมพันในเกม: `${bet_on} `,
                ผลเกม: `${result_description} `,
                รายละเอียดเกม: `${result_amount} `
          }))
          setTurnoverLists(dataConvertTransformed)
        }else{

          if(bet_result === null){
            return
          }

          const dataConvertTransformed = bet_result.map(({
                                                           time,
                                                           member_account,
                                                           name,
                                                           currency,
                                                           type,
                                                           content,
                                                           bet_amount,
                                                           actual_betting_qty,
                                                           win_lose,
                                                           commission,
                                                           jackpot,
                                                           member_result
                                                         }, index) => (
              {
                ลำดับ: index + 1,
                เวลา: `${time}`,
                ชื่อบัญชีสมาชิก:`${member_account}`.toLowerCase(),
                ชื่อ: `${name} `,
                // สกุลเงิน: `${currency} `,
                ประเภท: `${type} `,
                รายละเอียด: `${content} `,
                จำนวนเงินที่วางเดิมพัน: `${bet_amount} `,
                ยอดเดิมพันทั้งสิ้น: `${actual_betting_qty} `,
                แพ้ชนะ: `${win_lose} `,
                ค่าคอมมิชชั่น: `${commission} `,
                แจ๊คพอต: `${jackpot} `,
                ผลสรุปของสมาชิก: `${member_result} `
              }))
          setTurnoverLists(dataConvertTransformed)
        }

      }).catch(error => {
        console.log("error customer history:", error)
        setNotificationObject({
          text: `ขออภัยไม่พบข้อมูล หรือ กรุณาเว้นเวลาสักครู่ก่อนทำรายการ`, type: "error", icon:<i className={`fas fa-times-circle bg-danger`}/>
        })
      }).finally(()=>{
        setIsLoading(false)
      })

  }

  const resetAllValue = () =>{
    setTurnoverLists([])
    setAmountSummary({ win: 0.0, draw: 0.0, lose: 0.0, cancel: 0.0, total: 0.0 })
    setCountSummary({ win: 0.0, draw: 0.0, lose: 0.0, cancel: 0.0, total: 0.0 })
    setPlaySummary({totalRec: 0, betAmt:0.0, winAmt:0.0, comAmt:0.0, validAmt:0.0})
    reset()
    setIsLoading(false)
  }

  useEffect(()=>{
    void getActiveAgent()
  },[])

  return(
      <>
      <Card className="card-success">
        <Card.Header><h5>ตรวจสอบ Turnover ลูกค้า</h5></Card.Header>
        <Card.Body>
          <Form noValidate onSubmit={ handleSubmit(submitTurnover) }>
            <Form.Row className="col-md-12 gap-4">
              <Form.Group>
                <Form.Label>กรุณาเลือก Agent ที่ต้องการค้นหา</Form.Label>
                <div className={"flex-1 gap-2 justify-content-start"}>
                  {
                    (getAgentIndex("ufa") !== -1) && (
                          <Form.Check type="radio"
                                      id="agent_type"
                                      name="agent_type"
                                      ref={register({ required: true } )}
                                      isInvalid={errors.agent_type}
                                      required
                                      custom
                                      value={"ufa"}
                                      label={<b>UFA</b>}
                          />
                      )
                  }

                  {
                    (getAgentIndex("gclub") !== -1) && (
                          <Form.Check type="radio"
                                      id="agent_type2"
                                      name="agent_type"
                                      ref={register({ required: true } )}
                                      isInvalid={errors.agent_type}
                                      required
                                      custom
                                      value={"gclub"}
                                      label={<b>GCLUB</b>}
                          />
                      )
                  }
                </div>
                <Form.Control.Feedback type="invalid" tooltip>
                  กรุณาเลือก Agent
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group md="2" lg="2" controlId="validation32" hidden={watchData.agent_type === 'ufa'}>
                <Form.Label>กรุณาเลือกช่วงเวลาที่ต้องการ</Form.Label>
                <InputGroup>
                  <InputGroup.Prepend >
                    <InputGroup.Text>ช่วงเวลา</InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                      as="select"
                      name="type"
                      required
                      ref={register({ required: watchData.agent_type === 'gclub' })}
                      isInvalid={errors.type}
                      custom
                      defaultValue={""}
                  >
                    <option value="">Please Select</option>
                    <option value="today">วันนี้</option>
                    <option value="yesterday">เมื่อวาน</option>
                    <option value="this_week">สัปดาห์นี้</option>
                    <option value="last_week">สัปดาห์ที่แล้ว</option>
                    <option value="this_month">เดือนนี้</option>
                    <option value="last_month">เดือนที่แล้ว</option>
                    <option value="two_months_ago">สองเดือนก่อน</option>
                  </Form.Control>
                  <Form.Control.Feedback type="invalid" tooltip>
                    กรุณาเลือกวันที่เริ่ม
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Form.Group className="col-md-6">
                <Form.Label>พิมพ์ค้นหาลูกค้า</Form.Label>
                <Form.Control as="input"
                              id="turnover_userid"
                              name="turn_userid"
                              placeholder="eg. ufyu88xxxx/ahag2xxx, ชื่อ - นามสกุล, เบอร์โทรศัพท์, เลขที่บัญชี"
                              ref={register({ required: true } )}
                              isInvalid={errors.turn_userid}
                              required
                >
                </Form.Control>
                <Form.Control.Feedback type="invalid" tooltip>
                  กรุณาระบุ User ID
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            <Form.Row className="col-md-12 gap-4" hidden={watchData.agent_type === "gclub"}>
              <Form.Group as={ Col } controlId="validation11">
                <Form.Label>เลือกวันที่เริ่ม</Form.Label>
                <InputGroup>
                  <InputGroup.Prepend >
                    <InputGroup.Text>วันที่เริ่ม</InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                      type="date"
                      name="start_date"
                      placeholder="2020-09-10"
                      required
                      ref={register({ required: true })}
                      isInvalid={errors.start_date}
                  />
                  <Form.Control.Feedback type="invalid" tooltip>
                    กรุณาเลือกวันที่เริ่ม
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Form.Group as={ Col } md="3" lg="3" controlId="validation12">
                <Form.Label>กรุณาเลือกเวลาที่เริ่ม</Form.Label>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text>เวลาที่เริ่ม</InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                      type="time"
                      name="start_time"
                      placeholder="2020-09-10"
                      required
                      ref={register({ required: true })}
                      isInvalid={errors.start_time}
                  />
                  <Form.Control.Feedback type="invalid" tooltip>
                    กรุณาเลือกเวลาที่เริ่ม
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Form.Group as={ Col } md="3" lg="3" controlId="validation31">
                <Form.Label>เลือกวันที่สิ้นสุด</Form.Label>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text>วันที่สิ้นสุด</InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                      type="date"
                      name="end_date"
                      placeholder="2020-09-10"
                      required
                      ref={register({ required: true })}
                      isInvalid={errors.end_date}
                  />
                  <Form.Control.Feedback type="invalid" tooltip>
                    กรุณาเลือกวันที่ต้องการค้นหา
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Form.Group as={ Col } md="3" lg="3" controlId="validation32">
                <Form.Label>กรุณาเลือกเวลาที่สิ้นสุด</Form.Label>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text>เวลาที่สิ้นสุด</InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                      type="time"
                      name="end_time"
                      placeholder="2020-09-10"
                      required
                      ref={register({ required: true })}
                      isInvalid={errors.end_time}
                  />
                  <Form.Control.Feedback type="invalid" tooltip>
                    กรุณาเลือกวันที่ต้องการค้นหา
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </Form.Row>
            <Form.Row className="col-md-12">
              <Form.Group as={ Col } className="col-md-6" controlId="validation41">
                <FormGroup className={"justify-content-center"}>
                            <Button variant="danger" type={"submit"} disabled={ isLoading }>

                              {
                                  (isLoading) &&
                                  <Spinner
                                      as={ "span" }
                                      animation="border"
                                      variant={ "light" }
                                      size={ "sm" }/>
                              }
                              ค้นหา TurnOver
                            </Button>
                              {' '}
                  <Button variant="primary" onClick={resetAllValue}>ล้างค่า</Button>
                </FormGroup>
              </Form.Group>
            </Form.Row>
            <Form.Label hidden={watchData.agent_type === "gclub"}>จำนวนครั้งที่เดิมพัน { playSummary.totalRec }</Form.Label>
            <Form.Row className="col-lg-12" hidden={watchData.agent_type === "gclub"}>
              <Col lg={ 3 }>
                            <div className="info-box">
                  <span className={ `info-box-icon fas bg-gradient-green` }>
                      <i className={ "fas fa-money-bill-alt" }/>
                  </span>
                              <div className="info-box-content">
                                <span className="info-box-number">จำนวนเงินเดิมพัน { number_format(playSummary.betAmt, 2) } บาท</span>
                              </div>
                            </div>
                          </Col>
                          <Col lg={ 3 }>
                            <div className="info-box">
                  <span className={ `info-box-icon fas bg-gradient-blue` }>
                      <i className={ "fas fa-th-list" }/>
                  </span>
                              <div className="info-box-content">
                                <span className="info-box-number">แพ้ชนะ { number_format(playSummary.winAmt, 2) } บาท</span>
                              </div>
                            </div>
                          </Col>
                          <Col lg={ 3 }>
                            <div className="info-box">
                  <span className={ `info-box-icon fas bg-gradient-danger` }>
                      <i className={ "fas fa-hand-holding-usd" }/>
                  </span>
                              <div className="info-box-content">
                                <span className="info-box-number">ค่าคอมมิชชั่น { number_format(playSummary.comAmt, 2) } บาท</span>
                              </div>
                            </div>
                          </Col>
                          <Col lg={ 3 }>
                            <div className="info-box">
                  <span className={ `info-box-icon fas bg-gradient-gray-dark` }>
                      <i className={ "fas fa-coins" }/>
                  </span>
                              <div className="info-box-content">
                                <span className="info-box-number">จำนวนเงินถูกต้อง { number_format(playSummary.validAmt,2) } บาท</span>
                              </div>
                            </div>
              </Col>
            </Form.Row>
            <Form.Label hidden={watchData.agent_type === "gclub" || turnoverLists.length === 0}>จำนวนเงินรวม { amountSummary && number_format(amountSummary.total, 2) } บาท</Form.Label>
            <Form.Row className="col-lg-12" hidden={watchData.agent_type === "gclub" || turnoverLists.length === 0}>
              <Col lg={ 3 }>
                <div className="info-box">
                  <span className={ `info-box-icon fas bg-gradient-green` }>
                      <i className={ "fas fa-thumbs-up" }/>
                  </span>
                  <div className="info-box-content">
                    <span className="info-box-number">ชนะ {amountSummary && number_format(amountSummary.win, 2) } บาท</span>
                  </div>
                </div>
              </Col>
              <Col lg={ 3 }>
                <div className="info-box">
                  <span className={ `info-box-icon fas bg-gradient-blue` }>
                      <i className={ "fas fa-handshake" }/>
                  </span>
                  <div className="info-box-content">
                    <span className="info-box-number">เสมอ {amountSummary && number_format(amountSummary.draw, 2) } บาท</span>
                  </div>
                </div>
              </Col>
              <Col lg={ 3 }>
                <div className="info-box">
                  <span className={ `info-box-icon fas bg-gradient-danger` }>
                      <i className={ "fas fa-thumbs-down" }/>
                  </span>
                  <div className="info-box-content">
                    <span className="info-box-number">แพ้ {amountSummary && number_format(amountSummary.lose, 2) } บาท</span>
                  </div>
                </div>
              </Col>
              <Col lg={ 3 }>
                <div className="info-box">
                  <span className={ `info-box-icon fas bg-gradient-gray-dark` }>
                      <i className={ "fas fa-ban" }/>
                  </span>
                  <div className="info-box-content">
                    <span className="info-box-number">ยกเลิก {amountSummary && number_format(amountSummary.cancel,2) } บาท</span>
                  </div>
                </div>
              </Col>
            </Form.Row>
            <Form.Label hidden={watchData.agent_type === "gclub" || turnoverLists.length === 0}>รายละเอียดแพ้ชนะรวม {countSummary && countSummary.total } ครั้ง</Form.Label>
            <Form.Row className="col-lg-12" hidden={watchData.agent_type === "gclub" || turnoverLists.length === 0}>
              <Col lg={ 3 }>
                <div className="info-box">
                  <span className={ `info-box-icon fas bg-gradient-success` }>
                      <i className={ "fas fa-hand-point-up" }/>
                  </span>
                  <div className="info-box-content">
                    <span className="info-box-number">ชนะ {countSummary && countSummary.win } ครั้ง</span>
                  </div>
                </div>
              </Col>
              <Col lg={ 3 }>
                <div className="info-box">
                <span className={ `info-box-icon fas bg-gradient-primary` }>
                    <i className={ "fas fa-hands-helping" }/>
                </span>
                  <div className="info-box-content">
                    <span className="info-box-number">เสมอ {countSummary && countSummary.draw } ครั้ง</span>
                  </div>
                </div>
              </Col>
              <Col lg={ 3 }>
                <div className="info-box">
              <span className={ `info-box-icon fas bg-gradient-danger` }>
                  <i className={ "fas fa-hand-point-down" }/>
              </span>
                  <div className="info-box-content">
                    <span className="info-box-number">แพ้ {countSummary && countSummary.lose } ครั้ง</span>
                  </div>
                </div>
              </Col>
              <Col lg={ 3 }>
                <div className="info-box">
              <span className={ `info-box-icon fas bg-gradient-gray-dark` }>
                  <i className={ "fas fa-hand-point-right" }/>
              </span>
                  <div className="info-box-content">
                    <span className="info-box-number">ยกเลิก {countSummary && countSummary.cancel } ครั้ง</span>
                  </div>
                </div>
              </Col>
            </Form.Row>
            <Form.Row className="col-md-12">
              <Form.Group className="col-md-12">
                <div hidden={!turnoverLists.length}>
                  <GlobalTable tableData={turnoverLists} pageSizing={100}/>
                </div>
              </Form.Group>
            </Form.Row>
          </Form>
        </Card.Body>
      </Card>
      </>
  )
}


export default function CheckCustomerTransactions() {
  const [ searchData, setSearchData ] = useState([]);
  const [ searchKeyword, setSearchKeyword ] = useState("")
  const [ currentPage, setCurrentPage ] = useState(1)

  const [ showModal, setShowModal ] = useState(false)
  const [ customerData, setCustomerData ] = useState({ header: "ข้อมูลธุรกรรม", transactions: [] })
  const [ isLoading, setIsLoading ] = useState(false)
  const [ dictLastIndices, setDictLastIndices ] = useState({ 1: "" })
  const limitPage = 10

  const [ turnoverUserId, setTurnoverUserId ] = useState("")
  const [ startDate, setStartDate ] = useState(moment(new Date()).format("YYYY-MM-DD"))
  const [ startTime, setStartTime ] = useState("00:00")
  const [ endDate, setEndDate ] = useState(moment(new Date()).format("YYYY-MM-DD"))
  const [ endTime, setEndTime ] = useState("23:59")
  const [ turnoverLists, setTurnoverLists ] = useState([])
  const [ amountSummary, setAmountSummary ] = useState({ win: 0.0, draw: 0.0, lose: 0.0, cancel: 0.0, total: 0.0 })
  const [ countSummary, setCountSummary ] = useState({ win: 0.0, draw: 0.0, lose: 0.0, cancel: 0.0, total: 0.0 })

  const [ isLoadingTurnover, setIsLoadingTurnover ] = useState(false)

  const handleKeyword = (value) => {
    setCurrentPage(1)
    setSearchKeyword(value)
  }

  const clearData = () => {
    setSearchKeyword("")
    setSearchData([])
  }

  const searchButton = async (value) => {

    if (value.length !== 0 && value.length < 2) {
      return
    }
    setIsLoading(true)
    const searchData = {
      // method: "customer_search",
      keyword: value,
      limit: limitPage,
      last_element_id: dictLastIndices[currentPage]
    }

    await api.SEARCH_CUSTOMER(searchData).then(response => {
      const { data } = response.data
      if (!data) {
        setIsLoading(false)
        return
      }

      let searchDataList = [];

      data.forEach(element => {
        const agents = element.agents
        let dataBank = {}
        if (agents === undefined || agents === null) {

          const customerBankList = element.bank_accounts
          customerBankList.map((ele, index) => {

            let bankObj
            if (index === 0 || ele.is_main_bank_account === true) {
              bankObj = {
                main_bank_account_name: ele.bank_account_name,
                main_bank_account_no: ele.bank_account_no,
                main_bank_short_name: ele.bank_short_name
              }
              dataBank = ({ ...dataBank, ...bankObj })
            }

            if (index === 1) {
              bankObj = {
                sub1_bank_account_name: ele.bank_account_name,
                sub1_bank_account_no: ele.bank_account_no,
                sub1_bank_short_name: ele.bank_short_name
              }
              dataBank = ({ ...dataBank, ...bankObj })
            }

            if (index > 1) {
              bankObj = {
                sub2_bank_account_name: ele.bank_account_name,
                sub2_bank_account_no: ele.bank_account_no,
                sub2_bank_short_name: ele.bank_short_name
              }
              dataBank = ({ ...dataBank, ...bankObj })
            }
            //console.log("data bank :", dataBank)
          })

          const emptyAgents = {
            agents: []
          }

          searchDataList.push({ ...element, ...emptyAgents, ...dataBank })
        } else if (agents.length === 0) {
          const customerBankList = element.bank_accounts
          customerBankList.map((ele, index) => {

            let bankObj
            if (index === 0 || ele.is_main_bank_account === true) {
              bankObj = {
                main_bank_account_name: ele.bank_account_name,
                main_bank_account_no: ele.bank_account_no,
                main_bank_short_name: ele.bank_short_name
              }
              dataBank = ({ ...dataBank, ...bankObj })
            }

            if (index === 1) {
              bankObj = {
                sub1_bank_account_name: ele.bank_account_name,
                sub1_bank_account_no: ele.bank_account_no,
                sub1_bank_short_name: ele.bank_short_name
              }
              dataBank = ({ ...dataBank, ...bankObj })
            }

            if (index > 1) {
              bankObj = {
                sub2_bank_account_name: ele.bank_account_name,
                sub2_bank_account_no: ele.bank_account_no,
                sub2_bank_short_name: ele.bank_short_name
              }
              dataBank = ({ ...dataBank, ...bankObj })
            }
            //console.log("data bank :", dataBank)
          })

          searchDataList.push({ ...element, ...dataBank })
        } else {
          agents.forEach(el => {
            const customerBankList = element.bank_accounts
            element.username = el.username
            element.agent_name = el.agent_name
            element.user_id = el.username
            element.created_at = el.created_at
            element.new_customer = el.new_customer

            customerBankList.map((ele, index) => {

              let bankObj
              if (index === 0 || ele.is_main_bank_account === true) {
                bankObj = {
                  main_bank_account_name: ele.bank_account_name,
                  main_bank_account_no: ele.bank_account_no,
                  main_bank_short_name: ele.bank_short_name
                }
                dataBank = ({ ...dataBank, ...bankObj })
              }

              if (index === 1) {
                bankObj = {
                  sub1_bank_account_name: ele.bank_account_name,
                  sub1_bank_account_no: ele.bank_account_no,
                  sub1_bank_short_name: ele.bank_short_name
                }
                dataBank = ({ ...dataBank, ...bankObj })
              }

              if (index > 1) {
                bankObj = {
                  sub2_bank_account_name: ele.bank_account_name,
                  sub2_bank_account_no: ele.bank_account_no,
                  sub2_bank_short_name: ele.bank_short_name
                }
                dataBank = ({ ...dataBank, ...bankObj })
              }
              //console.log("data bank :", dataBank)
            })

            searchDataList.push({ ...element, ...dataBank })
          })
        }
      })
      setSearchData(searchDataList);
      if (searchDataList) {
        const newIndex = { ...dictLastIndices, [currentPage + 1]: searchDataList[searchDataList.length - 1].id }
        setDictLastIndices(newIndex)
      }
    }).catch(error => {
      console.log("error search customer transaction :", error)
    })
    setIsLoading(false)
  }

  const getCustomerHistory = async (props) => {
    //console.log("getCustomerHistory props", props)
    const { user_id, first_name, last_name } = props
    //setCustomerHistoryTable([])
    const userID = user_id
    //console.log("user_id : ", userID)
    if (userID === "" || userID === undefined) {
      return
    }

    const request = {
      user_id: userID,
    }

    await api_workorder.CUSTOMER_HISTORY(request).then(response => {
      //console.log('customer history data: ', response.data.data)
      setCustomerData({
        header: `ข้อมูลธุรกรรม ${ user_id } ${ first_name } ${ last_name }`,
        transactions: response.data.data
      })
      setShowModal(true)
    }).catch(error => {
      //console.log("error customer history:", error)
    })
  }
  const [ validated, setValidated ] = useState(false);

  const submitTurnover = async (event, data) => {
    // const form = event.currentTarget;
    // if (form.checkValidity() === false) {
    //   event.preventDefault();
    //   event.stopPropagation();
    //   setValidated(true);
    //   return
    // }
    // event.preventDefault();
    console.log("handleSubmitTurnover: ", event.data)
    // searchTurnover()
  };

  // const searchTurnover = async () => {
  //   // console.log("turnoverStartTime moment convert", moment(startDate + " " + startTime).utcOffset("+0700").format())
  //   // console.log("turnoverEndTime moment convert", moment(endDate + " " + endTime).utcOffset("+0700").format())
  //   const startTimestamp = moment(startDate + " " + startTime).utcOffset("+0700").format()
  //   const endTimestamp = moment(endDate + " " + endTime).utcOffset("+0700").format()
  //
  //   setIsLoadingTurnover(true)
  //   const request = {
  //     user_id: turnoverUserId,
  //     start_time: startTimestamp,
  //     end_time: endTimestamp
  //   }
  //
  //   await api.TURNOVER_CUSTOMER(request).then(response => {
  //     // console.log('customer turnover data: ', response.data.data)
  //     const { amount_summary, count_summary, turnovers } = response.data.data
  //     // console.log('customer turnover amount_summary: ', amount_summary)
  //     // console.log('customer turnover count_summary: ', count_summary)
  //     // console.log('customer turnover turnovers: ', turnovers)
  //     setAmountSummary(amount_summary)
  //     setCountSummary(count_summary)
  //     setTurnoverLists(turnovers)
  //   }).catch(error => {
  //     console.log("error customer history:", error)
  //   })
  //   setIsLoadingTurnover(false)
  // }

  const handlePrevPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1)
    }
  }

  const handleNextPage = () => {
    if (!searchData) {
      return
    }

    const setId = [ ...new Set(searchData.map(e => e.id)) ]
    if (setId.length === limitPage) {
      setCurrentPage(currentPage + 1)
    }
  }

  useEffect(() => {
    void searchButton(searchKeyword)
  }, [ currentPage ])
  const resetSearchTurnover = () => {
    setTurnoverUserId("")
    setStartDate(moment(new Date()).format("YYYY-MM-DD"))
    setStartTime("00:00")
    setEndDate(moment(new Date()).format("YYYY-MM-DD"))
    setEndTime("23:59")
    setTurnoverLists([])
    setAmountSummary({ win: 0.0, draw: 0.0, lose: 0.0, cancel: 0.0, total: 0.0 })
    setCountSummary({ win: 0.0, draw: 0.0, lose: 0.0, cancel: 0.0, total: 0.0 })
  }

  return (
    <div className="pt-4 pl-4 pl-4 pr-4">
      <ModalSearchTransactionCustomer customerData={ customerData } showModal={ showModal }
                                      onClose={ () => setShowModal(false) }/>
      <Card className="card-primary">
        <Card.Header><h5>ค้นหารายการธุรกรรมลูกค้า</h5></Card.Header>
        <Card.Body>
          <Form>
            <Form.Row className="col-md-12">
              <Form.Group className="col-md-12">
                <Form.Label>พิมพ์ค้นหาลูกค้า</Form.Label>
                <Form.Control as="input"
                              id="search_customer"
                              name="search_customer"
                              placeholder="eg. ufyu88xxxx/ahag2xxx, ชื่อ - นามสกุล, เบอร์โทรศัพท์, เลขที่บัญชี"
                              value={ searchKeyword }
                              onChange={ (e) => handleKeyword(e.target.value) }
                >
                </Form.Control>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={ Col } controlId="validation01">
                {
                  (isLoading) ?
                    (
                      <Button variant="primary" disabled={ isLoading }>
                        <Spinner
                          as="span"
                          animation="grow"
                          size="sm"

                        />ค้นหา</Button>
                    ) : (<Button variant="primary" onClick={ () => searchButton(searchKeyword) }>
                        ค้นหา</Button>
                    )
                }
                { ' ' }<Button variant="secondary" onClick={ () => clearData() }>ล้างค่า</Button>
              </Form.Group>
              <Pagination className={ "ml-auto" }>
                <Pagination.Prev onClick={ handlePrevPage }/>
                <Pagination.Item active={ true }>
                  { currentPage }
                </Pagination.Item>
                <Pagination.Next onClick={ handleNextPage }/>
              </Pagination>
            </Form.Row>
            <Form.Row className="col-md-12">
              <Form.Group className="col-md-12">
                        <Fade in={ true }>
                  <TableSearchCustomer customerSearchList={ searchData } editCustomerData={ getCustomerHistory }/>
                        </Fade>
                      </Form.Group>
                    </Form.Row>
          </Form>
        </Card.Body>
      </Card>
      <CheckTurnOverPanel/>
      <CheckCustomerQRPayment/>
    </div>
  )

}