import React, {useEffect, useState} from 'react'
import {Card, Col, Fade, Form, Row, Spinner} from 'react-bootstrap'
import api from "../../api/invoice";
import TableInvoiceSummaryHistory from "./TableInvoiceSummaryHistory";
import ModalDetail from "./ModalDetail";
import {InfoBoxBGStatsCard} from "../dashboard/StatCard";


export default function InvoiceSummary() {
    const [loadingButton, setLoadingButton] = useState(false);
    const [invoiceSummaryHistory, setInvoiceSummaryHistory] = useState([]);

    const [modalInfo, setModalInfo] = useState({});
    const [isOpenModal, setIsOpenModal] = useState(false);

    const openModal = (data, flag) => {
        setModalInfo(data);
        setIsOpenModal(flag);
    }

    const handleCloseModal = () => {
        setIsOpenModal(false)
    }

    const getInvoiceSummaryHistory = async () => {
        setLoadingButton(true);
        try {
            const response = await api.INVOICE_SUMMARY_HISTORY();
            // console.log(response.data.data);
            setInvoiceSummaryHistory(response.data.data.invoices.reverse())
        } catch (e) {
            console.log(e);
        } finally {
            setLoadingButton(false);
        }
    };

    useEffect(() => {
        void getInvoiceSummaryHistory();
    }, [])

    return (
        <div className="pt-4 pr-4 pl-4">
            <ModalDetail
                isOpenModal={isOpenModal}
                data={modalInfo}
                onClose={handleCloseModal}
            />
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 pt-3">
                            <Card className="card-primary">
                                <Card.Header><h5>สรุปบิลค่าใช้จ่าย</h5></Card.Header>
                                <Card.Body className="row">
                                    {
                                        (loadingButton) ?
                                            (
                                                <>
                                                    กำลังโหลดข้อมูล กรุณารอสักครู่...
                                                    <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                        className="ml-2"
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    <Form.Row className="col-md-12">
                                                        <Form.Group className="col-md-12">
                                                            <Fade in={true}>
                                                                <TableInvoiceSummaryHistory data={invoiceSummaryHistory}
                                                                                            editData={openModal}/>
                                                            </Fade>
                                                        </Form.Group>
                                                    </Form.Row>
                                                </>
                                            )
                                    }
                                </Card.Body>
                            </Card>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
};