import {Accordion, Button, Card, Table} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {LogoWithAccountNo2} from "../banks_logo";

const bankTierOptions = {
    lure:"บัญชีล่อ",
    normal:"ทั่วไป",
    vip: "VIP"
}

const getThaiBankType = (bankType, isLure) => {
    switch (bankType) {
        case "deposit":
            return isLure ? "ฝากล่อ" : "ฝาก";
        case "withdraw":
            return isLure ? "ถอนล่อ" : "ถอน";
        case "holding":
            return "พัก";
        case "sweepout":
            return "โยกออก";
        default:
            return "";
    }
};
export default function ShowBankListComponent(props){

    const {BankList, reloadBankList, page} = props

    const [BankListData, setBankListData] = useState([])


    const reloadBank =()=>{
        setBankListData([])
        setTimeout(()=>reloadBankList(),200)

    }

    useEffect(() => {
        // console.log("BankList: ", BankList)
        setBankListData(BankList)
    }, [BankList]);

    return(
        <div className={page === "summary"? "pt-2 pl-2 pr-2":"pt-4 pr-4 pl-4"}>
            <Accordion>
                <Card className={"card-gray-dark"}  >
                    <Card.Header>
                        รายชื่อธนาคาร {" "}
                        <Button variant={"outline-light"} style={{borderRadius: "50px"}} size={"sm"} onClick={reloadBank}><i className="fa fa-sync-alt"/></Button>
                        <Accordion.Toggle as={Button} variant="link" eventKey="0" style={{ "cursor": "pointer", borderRadius: "50px" }} >
                            <Button variant={"outline-light"} style={{borderRadius: "50px"}} size={"sm"}>
                                <i className="fa fa-arrow-down"/>
                            </Button>
                        </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey={"0"}>
                        <Card.Body className={"justify-content-center text-center"}>
                            {BankList ? (
                                <div className={"flex justify-content-center"}>
                                    <Table striped bordered hover style={{height:"fit-content"}} className={"text-center"}>
                                        <thead>
                                        <tr className={"text-center bg-success"}><th colSpan={9}>บัญชีเปิดแจกลูกค้า</th></tr>
                                        <tr>
                                            {/*<th>สถานะ</th>*/}
                                            <th>แสดงหน้าลูกค้า</th>
                                            <th>ชื่อบัญชี</th>
                                            <th>ชื่อแสดงหน้าลูกค้า</th>
                                            <th>ประเภทบัญชี</th>
                                            <th>ระดับบัญชี</th>
                                            {/*<th>อัพเดทเมื่อ</th>*/}
                                            {/*<th>อัพเดทโดย</th>*/}
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            (BankListData.length >0) ? (
                                                BankListData.filter((BankListData)=>{
                                                    return (BankListData.enable === true && BankListData.obsolete === false && BankListData.lock === false && BankListData.show_customer === true)
                                                }).map(({ bank_account_name, bank_account_no, bank_name, bank_short_name, bank_type, deposit_tier, enable, is_lure, updated_at, updated_by, show_customer }, index) => (
                                                    <tr key={bank_account_no} className={enable === true ?"table-success":"table-danger"}>
                                                        {/*<td>{enable ? "เปิด":"ปิด"}</td>*/}
                                                        <td>{show_customer ? "เปิดแจก":"ปิดแจก"}</td>
                                                        <td>
                                                            <LogoWithAccountNo2
                                                                bankShortName={bank_short_name}
                                                                bankFullName={bank_name}
                                                                bankName={bank_name}
                                                                accountNo={bank_account_no}
                                                                workOrderType={"deposit"}
                                                                workOrderSubType={""}
                                                                column={"1"}
                                                            />
                                                        </td>
                                                        <td>{bank_account_name}</td>
                                                        <td>{getThaiBankType(bank_type, is_lure)}</td>
                                                        <td>{bankTierOptions[deposit_tier]}</td>
                                                        {/*<td>{moment(updated_at).format("DD/MM/YYYY HH:mm:ss")}</td>*/}
                                                        {/*<td>{updated_by}</td>*/}
                                                    </tr>
                                                ))):(<tr key={"9999"}>
                                                <td colSpan="9">
                                                    -
                                                </td>
                                            </tr>)
                                        }
                                        </tbody>
                                    </Table>
                                    <div className={"px-2"}/>
                                    <Table striped bordered hover style={{height:"fit-content"}}>
                                        <thead>
                                        <tr className={"text-center bg-danger"}><th colSpan={9}>บัญชีปิดแจกลูกค้า</th></tr>
                                        <tr>
                                            {/*<th>สถานะ</th>*/}
                                            <th>แสดงหน้าลูกค้า</th>
                                            <th>ชื่อบัญชี</th>
                                            <th>ชื่อแสดงหน้าลูกค้า</th>
                                            <th>ประเภทบัญชี</th>
                                            <th>ระดับบัญชี</th>
                                            {/*<th>อัพเดทเมื่อ</th>*/}
                                            {/*<th>อัพเดทโดย</th>*/}
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            (BankListData.length >0) ? (
                                                BankListData.filter((BankListData)=>{
                                                    return (BankListData.enable === true && BankListData.obsolete === false && BankListData.lock === false && BankListData.show_customer === false)
                                                }).map(({ bank_account_name, bank_account_no, bank_name, bank_short_name, bank_type, deposit_tier, enable, is_lure, updated_at, updated_by, show_customer }, index) => (
                                                    <tr key={bank_account_no} className={"table-danger"}>
                                                        {/*<td>{enable ? "เปิด":"ปิด"}</td>*/}
                                                        <td>{show_customer ? "เปิดแจก":"ปิดแจก"}</td>
                                                        <td>
                                                            <LogoWithAccountNo2
                                                                bankShortName={bank_short_name}
                                                                bankFullName={bank_name}
                                                                bankName={bank_name}
                                                                accountNo={bank_account_no}
                                                                workOrderType={"deposit"}
                                                                workOrderSubType={""}
                                                                column={"1"}
                                                            />
                                                        </td>
                                                        <td>{bank_account_name}</td>
                                                        <td>{getThaiBankType(bank_type, is_lure)}</td>
                                                        <td>{bankTierOptions[deposit_tier]}</td>
                                                        {/*<td>{moment(updated_at).format("DD/MM/YYYY HH:mm:ss")}</td>*/}
                                                        {/*<td>{updated_by}</td>*/}
                                                    </tr>
                                                ))):(<tr key={"9999"}>
                                                <td colSpan="9">
                                                    -
                                                </td>
                                            </tr>)
                                        }
                                        </tbody>
                                    </Table>
                                </div>
                            ):"ไม่พบข้อมูล"}
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </div>
    )
}