import React, {useEffect, useLayoutEffect, useState} from "react";
import {useAsyncDebounce, useGlobalFilter, useTable, usePagination} from "react-table";
import {Button, DropdownButton, Form, FormLabel, InputGroup, Table} from "react-bootstrap";
import DropdownItem from "react-bootstrap/DropdownItem";

export default function GlobalTable (props){
    // console.log("inside global table")
    const {tableData, pageSizing} = props
    const [cols, setCols] = useState([])
    const [defaultPageSize, setDefaultPageSize] = useState(10)

    const columns = React.useMemo(
        () => cols,
        [cols]
    )

    const data = React.useMemo(
        () => {
            const result = [...tableData]
            return result
        },
        [tableData]
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        state,
        gotoPage,
        nextPage,
        previousPage,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        preGlobalFilteredRows,
        setGlobalFilter,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable( { columns, data, initialState: { pageIndex: 0}}, useGlobalFilter, usePagination )

    useEffect(()=>{

        if (tableData.length === 0){
            return
        }
        let colArr = []
        //console.log("Table Data : ", ...tableData)
        for (const [key, value] of Object.entries(...tableData)) {
             //console.log(` KEY/ VALUE : ${key}: ${value}`);
            colArr.push({
                Header: key,
                accessor: key
            })
        }
        setCols(colArr)
        // setPageSize(5)
        return (()=>{
            setCols([])
        })
    },[tableData])

    useEffect(()=>{
        //console.log("pageSizing : ",pageSizing)
        if (typeof pageSizing === "number"){
            //console.log("pageSize in if : ", pageSizing)
            setPageSize(Number(pageSizing))
        }
    },[pageSizing])

    const GlobalFilter = ( { preGlobalFilteredRows, globalFilter, setGlobalFilter } ) => {
        const count = preGlobalFilteredRows.length
        const [ value, setValue ] = useState( globalFilter )
        const onChange = useAsyncDebounce( value => {
            setGlobalFilter( value || undefined )
        }, 200 )

        return (
            <Form>
                <Form.Group className="mb-0" controlId="exampleForm.ControlInput1">
                    <Form.Control type="text"
                                  placeholder={`พบทั้งหมด ${count} รายการ`}
                                  value={value || ""}
                                  autoFocus={true}
                                  onChange={e => {
                                      setValue( e.target.value );
                                      onChange( e.target.value );
                                  }}
                    />
                </Form.Group>
            </Form>
        )
    }

    return(
        <>
        <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
        />
        <Table {...getTableProps()}  className={"text-center"}>
            <thead>
            <tr className={"text-center"}>
                <th colSpan={columns.length}>
                        <div className="pagination d-inline-flex align-items-center">
                            <div>
                                <Button variant={"outline-primary"} onClick={() => previousPage()} disabled={!canPreviousPage}>
                                    <i className={"fa fa-angle-left"}/>
                                </Button>{' '}
                                <strong>
                                    <Button variant={"primary"} disabled={true}>{pageIndex + 1} / {pageOptions.length}</Button>
                                </strong>{' '}
                                <Button variant={"outline-primary"} onClick={() => nextPage()} disabled={!canNextPage}>
                                    <i className={"fa fa-angle-right"} />
                                </Button>{' '}
                            </div>
                            <div>
                                    <Form.Control
                                        as="select"
                                        custom
                                        className={"ml-2"}
                                        value={pageSize}
                                        onChange={e => {
                                            setPageSize(Number(e.target.value))
                                        }}
                                    >
                                        {[10, 25, 50, 100].map(pageSize => (
                                            <option key={pageSize} value={pageSize}>
                                                แสดงผล {pageSize}
                                            </option>
                                        ))}
                                    </Form.Control>
                            </div>
                        </div>
                </th>
            </tr>
            {// Loop over the header rows
                headerGroups.map( headerGroup => (
                    // Apply the header row props
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {// Loop over the headers in each row
                            headerGroup.headers.map( column => (
                                // Apply the header cell props
                                <th {...column.getHeaderProps()}>
                                    {// Render the header
                                        column.render( 'Header' )}
                                </th>
                            ) )}
                    </tr>
                ) )}
            </thead>
            {/* Apply the table body props */}
            <tbody {...getTableBodyProps()} className={"text-left"}>
            {// Loop over the table rows

                page.map((row, i) => {
                    // Prepare the row for display
                    prepareRow( row )
                    return (
                        // Apply the row props
                        <tr {...row.getRowProps()}>
                            {// Loop over the rows cells
                                row.cells.map( cell => {
                                    // Apply the cell props
                                    return (
                                        <td {...cell.getCellProps()}>
                                            {// Render the cell contents
                                                cell.render( 'Cell' )}
                                        </td>
                                    )
                                } )}
                        </tr>
                    )
                } )}
            </tbody>
            <thead className={"text-center"}>
            <tr>
                <th colSpan={columns.length}>
                    <div className="pagination d-inline-flex align-items-center">
                        <div>
                            <Button variant={"outline-primary"} onClick={() => previousPage()} disabled={!canPreviousPage}>
                                <i className={"fa fa-angle-left"}/>
                            </Button>{' '}
                            <strong>
                                <Button variant={"primary"} disabled={true}>{pageIndex + 1} / {pageOptions.length}</Button>
                            </strong>{' '}
                            <Button variant={"outline-primary"} onClick={() => nextPage()} disabled={!canNextPage}>
                                <i className={"fa fa-angle-right"}/>
                            </Button>{' '}
                        </div>
                        <div>
                                <Form.Control
                                    as="select"
                                    custom
                                    className={"ml-2"}
                                    value={pageSize}
                                    onChange={e => {
                                        setPageSize(Number(e.target.value))
                                    }}
                                >
                                    {[10, 25, 50, 100].map(pageSize => (
                                        <option key={pageSize} value={pageSize}>
                                            แสดงผล {pageSize}
                                        </option>
                                    ))}
                                </Form.Control>
                        </div>
                    </div>
                </th>
            </tr>
            </thead>
        </Table>
        </>
    )

}