import axios from '../client/axios'
import config from '../config/index'

export default {
    SEARCH_LUCKYDRAW: ( data ) => {
        return axios( {
            //headers: {},
            method: 'POST',
            url: `${config.SEARCH_LUCKYDRAW}`,
            data: data,
        } )
    },
    CREATE_LUCKYDRAW: ( data ) => {
        return axios( {
            //headers: {},
            method: 'POST',
            url: `${config.CREATE_LUCKYDRAW}`,
            data: data,
        } )
    },
    UPDATE_LUCKYDRAW: ( data ) => {
        return axios( {
            //headers: {},
            method: 'POST',
            url: `${config.UPDATE_LUCKYDRAW}`,
            data: data,
        } )
    },
    DELETE_LUCKYDRAW: ( data ) => {
        return axios( {
            //headers: {},
            method: 'POST',
            url: `${config.DELETE_LUCKYDRAW}`,
            data: data,
        } )
    },
    SEARCH_LUCKYDRAW_STATS: ( data ) => {
        return axios( {
            method: 'POST',
            url: `${config.SEARCH_LUCKYDRAW_STATS}`,
            data: data,
        } )
    }
}