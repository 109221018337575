import { Button, Col, Form, Modal, } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import api from "../../api/agents";
import { useGlobalStore } from "../../store/globalContext";

export default (props) => {
  const { login_profile } = useGlobalStore()[ 0 ];
  const { data, showModal, closeModal } = props
  const [ lineID, setLineID ] = useState(data.line_id)
  const [ moneySite, setMoneySite ] = useState(data.money_site_name)
  const [ url, setUrl ] = useState(data.url)
  const [ customerMassage, setCustomerMessage ] = useState(data.customer_message)
  const [ password, setPassword ] = useState(data.password)
  const [ passwordShown, setPasswordShown ] = useState(false);
  const [ blockTransfer, setBlockTransfer ] = useState(data.block_debit_credit)

  const handleCloseModal = () => {
    closeModal()
  }

  const handleSubmit = async () => {
    try {
      const request = {
        "id": data.id,
        "password": password,
        "money_sites": [ {
          "id": data.money_site_id,
          "customer_message": customerMassage,
          "line_id": lineID,
          "url": url,
          "name": moneySite,
        } ],
        "block_debit_credit": blockTransfer,
      }
      // console.log(request)
      const response = await api.UPDATE_AGENT(request)
      const { success } = response.data
      //console.log("call update agent success ", success)
      closeModal()
    } catch (error) {
      console.log("call update agents error ", error)
    }
  }

  const templateUsername = "${username}"
  const templatePassword = "${password}"

  const togglePasswordVisibility = () => {
    setPasswordShown(!passwordShown);
  };

  useEffect(() => {
    setLineID(data.line_id)
    setCustomerMessage(data.customer_message)
    setMoneySite(data.money_site_name)
    setUrl(data.url)
    setPassword(data.password)
    setBlockTransfer(data.block_debit_credit)
  }, [ data ])

  return (
    <Modal show={ showModal } onHide={ handleCloseModal } size="xl">
      <Form>
        <Modal.Header>
          <Modal.Title>จัดการ Agent</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Row>
            <Form.Group as={ Col } md="4" controlId="validationCustom01">
              <Form.Label>ชื่อ</Form.Label>
              <Form.Control
                type="text"
                name="agent_login_name"
                placeholder="AgentID"
                value={ data.agent_login_name }
                readOnly
              />
              <Form.Control.Feedback type="invalid" tooltip>กรุณาระบุชื่อ</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={ Col } md="4" controlId="validationCustom02">
              <Form.Label>AgentID</Form.Label>
              <Form.Control
                type="text"
                name="agent_id"
                placeholder="AgentID"
                value={ data.agent_id }
                readOnly
              />
              <Form.Control.Feedback type="invalid" tooltip>กรุณาระบุ AgentID</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={ Col } md="4" controlId="validationCustom03">
              <Form.Label>ชื่อ Agent</Form.Label>
              <Form.Control
                type="text"
                name="agent_name"
                placeholder="ชื่อ Agent"
                value={ data.agent_name }
                readOnly
              />
              <Form.Control.Feedback type="invalid" tooltip>กรุณาระบุชื่อ Agent</Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={ Col } md="4" controlId="validationCustom04">
              <Form.Label>Username</Form.Label>
              <Form.Control
                type="text"
                name="username"
                placeholder="Username"
                value={ data.username }
                readOnly
              />
              <Form.Control.Feedback type="invalid" tooltip>กรุณาระบุ Username</Form.Control.Feedback>
            </Form.Group>
            { login_profile.role === "super_admin" &&
              <Form.Group as={ Col } md="4" controlId="validationCustom10">
                <Form.Label>
                  Password{' '}
                  <i onClick={ togglePasswordVisibility } className="fas fa-eye"/>
                </Form.Label>
                <Form.Control
                    type={ passwordShown ? "text" : "password" }
                    name="password"
                    placeholder="Password"
                    defaultValue={ data.password }
                    onChange={ (e) => setPassword(e.target.value) }
                />
                <Form.Control.Feedback type="invalid" tooltip>กรุณาระบุ Password</Form.Control.Feedback>
              </Form.Group>
            }
            <Form.Group as={ Col } md="4" controlId="validationCustom06">
              <Form.Label>Line ID</Form.Label>
              <Form.Control
                type="text"
                name="line_id"
                placeholder="Line ID"
                defaultValue={ data.line_id }
                onChange={ (e) => setLineID(e.target.value) }
              />
              <Form.Control.Feedback type="invalid" tooltip>กรุณาระบุ Line ID</Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={ Col } md="6" controlId="validationCustom05">
              <Form.Label>Money Site ID</Form.Label>
              <Form.Control
                type="text"
                name="money_site_id"
                placeholder="Money Site"
                value={ data.money_site_id }
                readOnly
              />
              <Form.Control.Feedback type="invalid" tooltip>กรุณาระบุ Money Site</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={ Col } md="6" controlId="validationMoneySite">
              <Form.Label>ชื่อ Money Site</Form.Label>
              <Form.Control
                type="text"
                name="money_site_name"
                placeholder="Money Site"
                defaultValue={ data.money_site_name }
                readOnly
              />
              <Form.Control.Feedback type="invalid" tooltip>กรุณาระบุ Money Site</Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={ Col } md="12" controlId="validationCustom07">
              <Form.Label>URL</Form.Label>
              <Form.Control
                type="text"
                name="url"
                placeholder="URL"
                defaultValue={ data.url }
                onChange={ (e) => {
                  setUrl(e.target.value)
                } }
              />
              <Form.Control.Feedback type="invalid" tooltip>กรุณาระบุ URL</Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={ Col } md="6" controlId="validationOpenAgent">
              <Form.Check
                label="ห้ามฝาก ห้ามถอน"
                type="switch"
                name="block_transfer"
                checked={blockTransfer}
                onChange={ () => {
                  setBlockTransfer(!blockTransfer)
                } }
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={ Col } md="12" controlId="validationCustom08">
              <Form.Label>ข้อความสำหรับลูกค้า</Form.Label>
              <h1>
                สำหรับ Username สามารถใช้ { templateUsername } Password สามารถใช้ { templatePassword }
              </h1>
              <Form.Control
                rows="15"
                as="textarea"
                name="customer_message"
                placeholder="ข้อความสำหรับลูกค้า"
                defaultValue={ data.customer_message }
                onChange={ (e) => {
                  setCustomerMessage(e.target.value)
                } }
              />
              <Form.Control.Feedback type="invalid" tooltip>กรุณาระบุข้อความสำหรับลูกค้า</Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={ handleSubmit }>บันทึกข้อมูล</Button>
          <Button variant="secondary" onClick={ handleCloseModal }>ปิด</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}