import React, { useState, useEffect } from 'react'
import { useGlobalStore } from '../../store/globalContext'
import WorkOrderDetails from './WorkOrderDetails'
import { Card, Accordion, Button, Col, Form, InputGroup } from 'react-bootstrap';
import { useForm } from 'react-hook-form'
import SweepWorkOrder from './SweepWorkOrder'
import api_bank from "../../api/managebank";
import api_workorder from "../../api/workorder";

export default function MoveHoldingWorkOrder() {
  const [{ dashboard_work_order_sweep_holding, login_profile }, dispatch] = useGlobalStore();
  const [validated, setValidated] = useState(false);
  const { register, handleSubmit, watch, errors, setValue, reset } = useForm({
    defaultValues: { from_bank_name: "", to_bank_name: "" },
    criteriaMode: "all"
  });

  const [withdrawBank, setWithdrawBank] = useState([]);
  const [holdingBank, setHoldingBank] = useState([]);

  const [workOrderDetailsData, setWorkOrderDetailsData] = useState(
    {
      work_order_type: "sweep",
      work_order_sub_type: "holding",
      cardHeader: "สรุปข้อมูลใบงานถอนไปพัก",
      total_work_order_today: 0,
      total_work_order_all: 0,
      total_sweep_withdraw_amount_today: 0,
      total_amount_today: 0
    });

  const [ModalConfirmData, setModalConfirmData] = useState({});
  const [modalInfoData, setModalInfoData] = useState({});
  const [isOpenModal, setisOpenModal] = useState(false)
  const [forceSuccess, setForceSuccess] = useState(false)

  useEffect(() => {
    //console.log("register customer screen before dispatch");
    try {
      // getDepostBankList();
      // getWithdrawBankList();
      dispatch({
        type: "SOCKET_SEND",
        value: { method: "subscribe", topic: "dashboard_work_order_sweep_holding" }
      });
    } catch (error) {
      console.log("error :", error);
      dispatch({
        type: "TOAST",
        value: {
          show: true,
          type: 'danger',
          title: `ระบบมีปัญหา`,
          message: `ขออภัยระบบมีปัญหา กรุณาติดต่อ admin`,
        }
      });
    }
  }, [])


  const getAllBankList = async () => {

    const responseDeposit = await api_bank.BANK_SEARCH({ keyword: "" })
    if (responseDeposit.status !== 200) {
      console.log(`search deposit failed`, responseDeposit.message);
      return
    }

    const { data } = responseDeposit.data
    if (data !== undefined && data !== null) {
      //console.log(`search deposit bank data`, data);

      const dataEnable = data.filter((d)=>{
        return (d.enable === true || d.bank_type === "sweepout")
      })
      // console.log(`search all bank data filter `, dataEnable);

      const dataWithdraw = dataEnable.filter((d)=>{
        return (d.bank_type === "withdraw" || d.bank_type === "sweepout")
      })
      setWithdrawBank(dataWithdraw);

      const dataHolding = dataEnable.filter((d)=>{
        return d.bank_type === "holding"
      })
      setHoldingBank(dataHolding);
    }
  }
  const getWithdrawBankList = async () => {

    const responseDeposit = await api_bank.BANK_SEARCH_TYPE({ keyword: "withdraw+sweepout+vvip" })
    if (responseDeposit.status !== 200) {
      console.log(`search deposit failed`, responseDeposit.message);
      return
    }

    const { data } = responseDeposit.data

    if (data !== undefined && data !== null) {
      const dataEnable = data.filter((d)=>{
        return d.enable === true
      })
      setWithdrawBank(dataEnable);
    }
  }

  const getHoldingBankList = async () => {
    const responseWithdraw = await api_bank.BANK_SEARCH_TYPE({ keyword: "holding" })
    if (responseWithdraw.status !== 200) {
      console.log(`search withdraw bank_list failed`, responseWithdraw.message);
      return
    }

    const { data } = responseWithdraw.data

    if (data !== undefined && data !== null) {
      const dataEnable = data.filter((d)=>{
        return d.enable === true
      })
      setHoldingBank(dataEnable);
    }
  }

  const setWithdrawBankNo = (value) => {
   //console.log("set from bank account no: ", value);
    const selectWithdrawBank = withdrawBank.filter(withdrawBank => withdrawBank.bank_account_no === value).pop();
    //console.log("selectwithdrawBank: ", selectWithdrawBank);
    //setValue("from_bank_name", selectDepositBank.bank_name);
    setValue("from_bank_account_no", selectWithdrawBank.bank_account_no);
    setValue("from_bank_short_name", selectWithdrawBank.bank_short_name);
  }

  const setHoldingBankNo = (value) => {
    //console.log("set to bank account no: ", value);
    const selectHoldingBank = holdingBank.filter(holdingBank => holdingBank.bank_account_no === value).pop();
    //setValue("to_bank_name", selectWithdrawBank.bank_name);
    setValue("to_bank_account_no", selectHoldingBank.bank_account_no);
    setValue("to_bank_short_name", selectHoldingBank.bank_short_name);
  }

  useEffect(() => {
    //console.log("dashboard_work_order_sweep_wihdraw page: ", dashboard_work_order_sweep_holding);
    void getWithdrawBankList();
    void getHoldingBankList();
    //void getAllBankList()
    if (dashboard_work_order_sweep_holding === undefined) {
      return
    }

    setWorkOrderDetailsData(
      {
        ...workOrderDetailsData,
        total_work_order_all: dashboard_work_order_sweep_holding.total_work_order_all,
        total_work_order_today: dashboard_work_order_sweep_holding.total_work_order_today,
        total_amount_today: dashboard_work_order_sweep_holding.total_amount_today
      }
    )
  }, [dashboard_work_order_sweep_holding])


  const confirmSweepWithdraw = async (workOrderData) => {
    // console.log("workOrderData: ", workOrderData)
    let work_order_sweep_withdraw_request = {
      ...workOrderData,
      work_order_type: "sweep",
    }

    if (forceSuccess){
      work_order_sweep_withdraw_request = {
        ...workOrderData,
        work_order_type: "sweep",
        channel: "manual",
        status: "success",
        comment:  "(เติมมือ) " + workOrderData.comment
      }
    }

    //console.log("work_order_sweep_withdraw_request :", work_order_sweep_withdraw_request)
    try{
      const response = await api_workorder.WORK_ORDER_CREATE(work_order_sweep_withdraw_request)

      setTimeout(() => {
        dispatch({
          type: "TOAST2",
          value: {
            show: true,
            type: 'success',
            title: `โยกเงิน`,
            message: `ทำการโยกเงิน จำนวนเงิน ${ workOrderData.amount } บาท สำเร็จ`,
          }
        })
      }, 2000)

      const { data } = response.data
      // console.log("data: ", data)
    }catch (error){
      // console.log("error: ", error.response)
      setTimeout(() => {
        dispatch({
          type: "TOAST2",
          value: {
            show: true,
            type: 'danger',
            title: `ทำการโยกเงิน ${ workOrderData.amount } บาทไม่สำเร็จ`,
            message: `${  error.response.data.message } บาท`,
          }
        })
      }, 2000)
    }finally {
      reset(register)
    }

    //const depositBankList = dataDeposit.filter(dataDeposit => dataDeposit.bank_type === "deposit");

    // console.log("search sweep withdraw bank response data: ", data)
    // dispatch({
    //   type: "TOAST2",
    //   value: {
    //     show: true,
    //     type: 'success',
    //     title: `โยกเงิน`,
    //     message: `ทำการโยกเงินจำนวน ${workOrderData.amount} สำเร็จ`,
    //   }
    // });
    // setisOpenModal(false);
  }

  const closeConfirmModal = () => {
    setisOpenModal(false)
  }

  const onSubmit = workOrderData => {
    // console.log('test sweep', workOrderData);
    const withdraw = withdrawBank.filter(customerBankOptions => customerBankOptions.bank_account_no === workOrderData.from_bank_name).pop()
    const holding = holdingBank.filter(holdingBank => holdingBank.bank_account_no === workOrderData.to_bank_name).pop()

    // console.log("bankCustomer selected :", withdraw)
    // console.log("bankWorkOrder selected :", holding)
    const orderWithBankCode = {
      ...workOrderData,
      "to_bank_code": holding.bank_code
    };


    // console.log("SUBMIT workOrderData: ", orderWithBankCode);
    const data = {
      headerText: "ยืนยันการโยกเงิน ถอน - พัก",
      fromAccountName: withdraw.bank_name,
      fromAccountNo: workOrderData.from_bank_account_no,
      amount: workOrderData.amount,
      toAccountName: holding.bank_name,
      toAccountNo: workOrderData.to_bank_account_no,
      created_by: login_profile.name,
      updated_by: login_profile.name,
      confirmButton: confirmSweepWithdraw,
      closeButton: closeConfirmModal
    }
    setModalConfirmData(orderWithBankCode);
    setModalInfoData(data);
    setisOpenModal(true);
  }

  return (
    <>
      {/* <div>
              <WorkOrder workOrderTitleHeader="ค้นหาใบงานถอนไปพัก" workOrderTitleBody="พิมพ์เพื่อค้นหาใบงานถอนไปพัก" />
          </div> */}
      <div>
        <SweepWorkOrder isOpenModal={isOpenModal} modalData={modalInfoData} confirmData={ModalConfirmData} />
      </div>
      <div >
        <div className="col-md-12 col-lg-12 pt-4 pr-4 pl-4">
          <Accordion defaultActiveKey="0">
            <Card className="card-warning">
              <Card.Header className="card-warning">
                เพิ่มใบงานถอนไปพัก
            <Accordion.Toggle as={Button} variant="link" eventKey="0">
              <Button variant={"outline-dark"} style={{borderRadius: "20px"}} size={"sm"}><i className="fas fa-chevron-down"/></Button>
                  </Accordion.Toggle>
                
              </Card.Header>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <Form noValidate validated={validated} onSubmit={handleSubmit(onSubmit)}>
                    <Form.Row>
                      <Form.Group as={Col} md="3" lg="3" controlId="validation01">
                        <Form.Label>เลือกธนาคารถอน</Form.Label>
                        <InputGroup>
                          <Form.Control
                            as="select"
                            name="from_bank_name"
                            custom
                            required
                            isInvalid={!!errors.from_bank_name}
                            ref={register({ required: true })}
                            onChange={(e) => setWithdrawBankNo(e.target.value)}
                          >
                            <option value="">Please Select</option>
                            {
                              withdrawBank?.map(({ bank_name, bank_account_no }, index) =>
                                <option
                                  key={bank_account_no}
                                  value={bank_account_no}
                                >
                                  {bank_name}
                                </option>
                              )
                            }
                          </Form.Control>
                          <Form.Control.Feedback type="invalid" tooltip>
                            กรุณาระบุธนาคารถอน
                                                    </Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>
                      <Form.Group as={Col} md="3" lg="3" controlId="validation02">
                        <Form.Label>เลขที่บัญชีธนาคารถอน</Form.Label>
                        <InputGroup>
                          <Form.Control
                            as="input"
                            name="from_bank_account_no"
                            placeholder="-"
                            required
                            isInvalid={!!errors.from_bank_account_no}
                            ref={register({ required: true })}
                            readOnly={true}
                          >
                          </Form.Control>
                          <Form.Control.Feedback type="invalid" tooltip>
                            กรุณาระบุเลขบัญชีธนาคารถอน
                          </Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>
                      <Form.Group as={Col} md="3" lg="3" controlId="validation03">
                        <Form.Label>เลือกธนาคารพัก</Form.Label>
                        <InputGroup>
                          <Form.Control
                            as="select"
                            name="to_bank_name"
                            custom
                            required
                            isInvalid={!!errors.to_bank_name}
                            ref={register({ required: true })}
                            onChange={(e) => setHoldingBankNo(e.target.value)}
                          >
                            <option value="">Please Select</option>
                            {
                              holdingBank?.map(({ bank_name, bank_account_no }, index) =>
                                <option
                                  key={bank_account_no}
                                  value={bank_account_no}
                                >
                                  {bank_name}
                                </option>
                              )
                            }
                          </Form.Control>
                          <Form.Control.Feedback type="invalid" tooltip>
                            กรุณาระบุธนาคารพัก
                                                    </Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>
                      <Form.Group as={Col} md="3" lg="3" controlId="validation04">
                        <Form.Label>เลขที่บัญชีธนาคารพัก</Form.Label>
                        <InputGroup>
                          <Form.Control
                            as="input"
                            name="to_bank_account_no"
                            placeholder="-"
                            required
                            isInvalid={!!errors.to_bank_account_no}
                            ref={register({ required: true })}
                            onChange={(e) => alert(e.target.value)}
                            readOnly={true}
                          >
                          </Form.Control>
                          <Form.Control.Feedback type="invalid" tooltip>
                            กรุณาระบุเลขบัญชีธนาคารพัก
                          </Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group as={Col} md="3" lg="3" controlId="validation05">
                        <Form.Label>จำนวนเงิน</Form.Label>
                        <Form.Control
                          required
                          type="number"
                          step={1}
                          name="amount"
                          placeholder="100/200.50"
                          isInvalid={!!errors.amount}
                          ref={register({ required: true })}
                        />

                        <Form.Control.Feedback type="invalid" tooltip>
                          กรุณาระบุ จำนวนเงิน
                                                    </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group as={Col} md="9" lg="9" controlId="validation06">
                        <Form.Label>หมายเหตุ</Form.Label>
                        <Form.Control
                          type="text"
                          name="comment"
                          placeholder="โยกเมื่อเวลา 19:00"
                          isInvalid={!!errors.comment}
                          ref={register}
                        />

                        <Form.Control.Feedback type="invalid" tooltip>
                          กรุณาระบุ จำนวนเงิน
                                                    </Form.Control.Feedback>
                      </Form.Group>
                    </Form.Row>
                    <Button variant="success" type="submit" onClick={()=>setForceSuccess(false)}>เพิ่มใบงานถอนไปพัก</Button>{' '}
                    <Button variant="primary" type="submit" onClick={()=>setForceSuccess(true)}>เพิ่มใบงานถอนไปพักเติมมือ</Button>
                  </Form>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </div>
      </div>
      <div>
        <WorkOrderDetails data={workOrderDetailsData} />
      </div>
    </>
  )
}
