import React, {useEffect, useState} from 'react'
import {Button, Col, Form, Modal, Spinner} from 'react-bootstrap'
import {useForm} from "react-hook-form";
import apiBank from "../../api/managebank"
import {useGlobalStore} from "../../store/globalContext";


export default function ConfirmLockBank(props) {
    const [{login_profile}, dispatch] = useGlobalStore();
    const {data, isShow, onClose, callBack} = props;
    const {id, bank_name, bank_account_name, bank_account_no} = data;
    const [isLoading, setLoading] = useState(false);

    const [action, setAction] = useState("");
    useEffect(() => {
        if (data.action === "lock") {
            if (data.lock) {
                setAction("ล็อคบัญชี");
            } else {
                setAction("ปลดล็อคบัญชี");
            }
        } else if (data.action === "obsolete") {
            if (data.obsolete) {
                setAction("เตรียมยกเลิกใช้บัญชี");
            } else {
                setAction("ปลดเตรียมยกเลิกใช้บัญชี");
            }
        }
    });

    const [validated, setValidated] = useState(false);
    const {
        register: registerLockBank,
        handleSubmit: handleSubmitLockBank,
        watch: watchLockBank,
        errors: errorsLockBank,
        setValue: setValueLockBank,
        getValues: getValuesLockBank,
        setError: setErrorLockBank
    } = useForm({});

    const validateConfirmBankAccountNo = (value) => {
        if (value === "") {
            setErrorLockBank("confirm_bank_account_no", {"message": "กรุณาระบุเลขที่บัญชี"});
            return
        }
        if (value !== bank_account_no) {
            console.log(value)
            setErrorLockBank("confirm_bank_account_no", {"message": "เลขบัญชีไม่ตรงกัน"});
        }
    };

    const onSubmit = async () => {
        setLoading(true);

        const request = {
            ...data, updated_by: login_profile.name,
        };

        console.log(request);

        try {
            await apiBank.BANK_UPDATE(request);
            callBack();
            setLoading(false);

            dispatch({
                type: "TOAST2",
                value: {
                    show: true,
                    type: 'success',
                    title: action,
                    message: `${action} ${bank_account_no} สำเร็จ`,
                }
            });
        } catch (error) {
            console.log(error);
            setLoading(false);
            onClose();
            dispatch({
                type: "TOAST2",
                value: {
                    show: true,
                    type: 'danger',
                    title: action,
                    message: `${action} ${bank_account_no} ไม่สำเร็จ กรุณาติดต่อ admin`,
                }
            });
        }
    };


    return (
        <div>
            <Modal show={isShow} onHide={onClose} size="lg" centered={true}>
                <Form noValidate validated={validated} onSubmit={handleSubmitLockBank(onSubmit)}>
                    <Modal.Header closeButton>
                        <Modal.Title>{action}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Row>
                            <Form.Group as={Col} md="4" lg="4">
                                <Form.Label>ชื่อธนาคาร</Form.Label>
                                <Form.Control type="text" value={bank_name} readOnly={true}/>
                            </Form.Group>
                            <Form.Group as={Col} md="4" lg="4">
                                <Form.Label>ชื่อบัญชี</Form.Label>
                                <Form.Control type="text" value={bank_account_name} readOnly={true}/>
                            </Form.Group>
                            <Form.Group as={Col} md="4" lg="4">
                                <Form.Label>เลขที่บัญชี</Form.Label>
                                <Form.Control type="text" value={bank_account_no} readOnly={true}/>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} md="8" lg="8">
                                <Form.Label>ระบุเลขที่บัญชีที่ต้องการ{action} (ต้องตรงกับเลขบัญชีด้านบน) *</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    name="confirm_bank_account_no"
                                    placeholder={bank_account_no}
                                    isInvalid={!!errorsLockBank.confirm_bank_account_no && (getValuesLockBank("confirm_bank_account_no") !== bank_account_no)}
                                    ref={registerLockBank({
                                        required: true,
                                        validate: value => value === bank_account_no
                                    })}
                                />
                                <Form.Control.Feedback type="invalid" tooltip>
                                    {errorsLockBank.confirm_bank_account_no?.message ? errorsLockBank.confirm_bank_account_no?.message : 'กรุณาตรวจสอบเลขที่บัญชี'}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" type="submit" disabled={isLoading}>
                            {isLoading ?
                                (
                                    <>
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        /> กำลังดำเนินการ</>) : `ยืนยัน ${action}`}
                        </Button>
                        <Button variant="secondary" onClick={onClose}>
                            ยกเลิก
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </div>
    )
}
