import {Button, Card, Col, Form} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import api from "../../api/managebank";
import {useForm} from "react-hook-form";
import moment from "moment/moment";
import {useGlobalStore} from "../../store/globalContext";



const dropdownEnable = [
    {innerText:"เปิดใช้งาน", value: "true"},
    {innerText:"ปิดใช้งาน", value: "false"}
]
export default function ManageCorePayConfig() {

    const { register, handleSubmit, setValue, getValues, trigger, watch, formState:{isDirty, dirtyFields},reset } = useForm({
        shouldUnregister: false
    });

    const [{ subscribe, banks, status, login_profile }, dispatch] =
        useGlobalStore();

    const getCorePayConfigFromAPI = async ()=>{
        try {
            const response =  await api.GET_COREPAY_CONFIG()
            const {data} = response.data

            // console.log("get corepay config response data : ", data)

            // setValue("vizpay_api_key", data.vizpay_api_key)
            // setValue("vizpay_secret_key", data.vizpay_secret_key)
            // setValue("vizpay_callback", data.vizpay_callback)
            setValue("updated_at", moment(data.updated_at).format("DD/MM/YYYY HH:mm"))
            setValue("enable", data.enable)
            // setValue("force_qr_customer", data.force_qr_customer.enable)
            setValue("start_time", data.start_time)
            setValue("end_time", data.end_time)
            // setValue("message", data.message)
            // setValue("minimum_deposit_count", data.force_qr_customer.minimum_deposit_count)
            // setValue("validate_keys", "false")
        }catch (e) {
            console.log("get corepay config error: ", e, e.response.data)
        }finally {

        }
    }

    const submitCorePayConfig = async (submitValue)=>{

        let updateObject= {}

        Object.keys(dirtyFields).map((e)=>{
            // updateObject[e] = getValues(e)
            // if (e === "vizpay_api_key"){
            //     updateObject["vizpay_secret_key"] = getValues("vizpay_secret_key")
            // }
            //
            // if (e === "vizpay_secret_key"){
            //     updateObject["vizpay_api_key"] = getValues("vizpay_api_key")
            // }
        })

        // console.log("update object: ", updateObject)
        // delete submitValue.enable;
        // delete submitValue.start_time;
        // delete submitValue.end_time;
        // delete submitValue.force_qr_customer;
        // console.log("watchQRNewUser: ", watchQRNewUser)
        try {

            const response =  await api.QR_TRANSFER_CONFIG(updateObject)
            // console.log("get vizpay config response data : ", response)
            dispatch({
                type: "TOAST2",
                value: {
                    show: true,
                    type: "success",
                    title: `แก้ไขข้อมูล COREPAY`,
                    message: `แก้ไขข้อมูล COREPAY สำเร็จ`,
                },
            });
        }catch (e) {
            console.log("get corepay config error: ", e, e.response.data)
            dispatch({
                type: "TOAST2",
                value: {
                    show: true,
                    type: "error",
                    title: `แก้ไขข้อมูล COREPAY ไม่สำเร็จ`,
                    message: `แก้ไขข้อมูล COREPAY ไม่สำเร็จ ${e.response.data.message}`,
                },
            });
        }finally {
            reset(getValues())
        }
    }

    const updateEnableCorePayStatus = async (checked)=>{
        console.log("updateEnableCorePayStatus checked: ", checked)

        try {
            const response =  await api.UPDATE_COREPAY_CONFIG({"enable": checked})
            // console.log("get vizpay config response data : ", response)
            dispatch({
                type: "TOAST2",
                value: {
                    show: true,
                    type: "success",
                    title: `แก้ไขสถานะเปิด/ปิด ใช้งาน COREPAY`,
                    message: `แก้ไขสถานะเปิด/ปิด ใช้งาน COREPAY สำเร็จ`,
                },
            });
        }catch (e) {
            console.log("get corepay config error: ", e, e.response.data)
            dispatch({
                type: "TOAST2",
                value: {
                    show: true,
                    type: "error",
                    title: `แก้ไขสถานะเปิด/ปิด ใช้งาน COREPAY`,
                    message: `แก้ไขสถานะเปิด/ปิด ใช้งาน COREPAY ไม่สำเร็จ ${e.response.data.message}`,
                },
            });
        }finally {
            reset(getValues())
        }
    }

    const updateForceQRViz = async (checked)=>{
        try {
            const response =  await api.QR_TRANSFER_CONFIG({"force_qr_customer":{"enable": checked}})
            // console.log("get vizpay config response data : ", response)
            dispatch({
                type: "TOAST2",
                value: {
                    show: true,
                    type: "success",
                    title: `แก้ไขสถานะลูกค้าใหม่บังคับฝาก COREPAY`,
                    message: `แก้ไขสถานะลูกค้าใหม่บังคับฝาก COREPAY สำเร็จ`,
                },
            });
        }catch (e) {
            console.log("get vizpay config error: ", e, e.response.data)
            dispatch({
                type: "TOAST2",
                value: {
                    show: true,
                    type: "error",
                    title: `แก้ไขสถานะลูกค้าใหม่บังคับฝาก COREPAY`,
                    message: `แก้ไขสถานะลูกค้าใหม่บังคับฝาก COREPAY ไม่สำเร็จ ${e.response.data.message}`,
                },
            });
        }finally {
            reset(getValues())
        }
    }

    const validateVizKeys = async () =>{
        // console.log("getValues() vizpay_api_key: ", getValues("vizpay_api_key"))
        // console.log("getValues() vizpay_api_key: ", getValues("vizpay_secret_key"))
        try {
            const response =  await api.VALIDATE_VIZ_KEYS({
                "api_key": getValues("vizpay_api_key"),
                "secret_key":getValues("vizpay_secret_key")
            })
            console.log("get vizpay config response data : ", response)
            setValue("validate_keys", response.data.success)
            dispatch({
                type: "TOAST2",
                value: {
                    show: true,
                    type: "success",
                    title: `ตรวจสอบสถานะ API/SECRET KEYS`,
                    message: `ตรวจสอบสถานะ API/SECRET KEYS สำเร็จ`,
                },
            });
        }catch (e) {
            console.log("get vizpay config error: ", e, e.response.data)
            dispatch({
                type: "TOAST2",
                value: {
                    show: true,
                    type: "error",
                    title: `ตรวจสอบสถานะ API/SECRET KEYS ไม่สำเร็จ`,
                    message: `ตรวจสอบสถานะ API/SECRET KEYS  ${e.response.data.message} กรุณาตรวจสอบ`,
                },
            });
        }finally {
            console.log("validate keys result: ", getValues("validate_keys"))
        }
    }


    useEffect( ()=>{
        void getCorePayConfigFromAPI();
    },[])

    const watchEnable = watch("enable")
    const watchQRNewUser = watch("force_qr_customer")
    // const watchValidateKeys = watch("validate_keys")

    return(
        <Card className={"card-secondary"}>
            <Card.Header><i className={"fas fa-qrcode pr-2"}/> จัดการข้อมูลแจ้งลูกค้าของ COREPAY </Card.Header>
            <Card.Body>
                <Form noValidate onSubmit={handleSubmit(submitCorePayConfig)} id={"corepay"}>
                    {/*<Form.Row>*/}
                    {/*    <Col className={""}>*/}
                    {/*        <Form.Label className={"flex text-red my-auto"}>*/}
                    {/*            1. กรุณาตรวจสอบค่า API-KEY และ SECRET-KEY ให้ถูกต้อง*/}
                    {/*        </Form.Label>*/}
                    {/*        <Form.Label className={"flex text-red my-auto"}>*/}
                    {/*            2. ค่า API-KEY และ SECRET-KEY มีอายุ 90 วัน กรุณาเปลี่ยนก่อนหมดอายุทุกครั้ง*/}
                    {/*        </Form.Label>*/}
                    {/*        <Form.Label className={"flex text-red my-auto"}>*/}
                    {/*            3. กรุณาทดสอบ API-KEY และ SECRET-KEY ให้ผ่านก่อนการตั้งค่าทุกครั้ง*/}
                    {/*        </Form.Label>*/}

                    {/*    </Col>*/}
                    {/*</Form.Row>*/}
                    {/*<Form.Row className={"flex my-2"}>*/}
                    {/*    <Col className={"col-xs-12 col-md-6"}>*/}
                    {/*        <Form.Label>กรุณากรอก API-KEY<i className={`fas fa-check-circle pl-2 pr-2 ${getValues("validate_keys") === true && "text-green"}`}/></Form.Label>*/}
                    {/*        <Form.Label className={`${getValues("validate_keys") === true && "text-green"}`}>(กรุณาทดสอบให้เป็นสีเขียวก่อนบันทึกทุกครั้ง)</Form.Label>*/}
                    {/*        <Form.Control id="vizpay_api_key" name="vizpay_api_key" ref={register}/>*/}
                    {/*    </Col>*/}
                    {/*    <Col className={"col-xs-12 col-md-6"}>*/}
                    {/*        <Form.Label>กรุณากรอก SECRET-KEY<i className={`fas fa-check-circle pl-2 pr-2 ${getValues("validate_keys") === true && "text-green"}` }/></Form.Label>*/}
                    {/*        <Form.Label className={`${getValues("validate_keys") === true && "text-green"}`}>(กรุณาทดสอบให้เป็นสีเขียวก่อนบันทึกทุกครั้ง)</Form.Label>*/}
                    {/*        <Form.Control id="vizpay_secret_key" name="vizpay_secret_key" ref={register}/>*/}
                    {/*    </Col>*/}
                    {/*</Form.Row>*/}
                    <Form.Row className={"flex my-2"}>
                        {/*<Col className={"col-6"}>*/}
                        {/*    <Form.Label>Callback-URL</Form.Label>*/}
                        {/*    <Form.Control id="vizpay_callback" name={"vizpay_callback"} ref={register} readOnly/>*/}
                        {/*</Col>*/}
                        <Col className={"col-xs-12 col-md-6"}>
                            <Form.Label>สถานะใช้งาน COREPAY</Form.Label>
                            <Form.Switch
                                custom
                                id="enable_corepay"
                                name="enable"
                                label={watchEnable === true ? "เปิดใช้งาน COREPAY":"ปิด COREPAY"}
                                onChange={(e)=>updateEnableCorePayStatus(e.target.checked)}
                                ref={register}
                            />
                        </Col>
                        <Col className={"col-6"}>
                            <Form.Label>แก้ไขล่าสุดเมื่อ</Form.Label>
                            <Form.Control id="corepay_updated_at" name={"updated_at"}  type="datatime" ref={register} readOnly defaultValue={moment(new Date()).format("DD/MM/YYYY HH:mm")}/>
                        </Col>
                    </Form.Row>
                    {/*<Form.Row className={"flex my-2"}>*/}
                    {/*    <Col className={"flex col-6"}>*/}
                    {/*        <Button className={"bg-gradient-olive mr-2"} type={"button"} onClick={()=>validateVizKeys()}>ทดสอบ API-KEY/SECRET-KEY</Button>*/}
                    {/*        /!*</Col>*!/*/}
                    {/*        /!*<Col className={"col-6 mx-auto"} hidden={getValues("validate_keys") !== "true"}>*!/*/}
                    {/*        <Button className={"bg-gray-dark"} type={"submit"} disabled={isDirty === false} hidden={getValues("validate_keys") !== true}>บันทึก</Button>*/}
                    {/*    </Col>*/}
                    {/*</Form.Row>*/}
                    <Form.Row className={"flex"}>
                        {/*<Col className={"col-xs-12 col-md-6"}>*/}
                        {/*    <Form.Label>สถานะใช้งาน COREPAY</Form.Label>*/}
                        {/*    <Form.Switch*/}
                        {/*        custom*/}
                        {/*        id="enable"*/}
                        {/*        name="enable"*/}
                        {/*        label={watchEnable === true ? "เปิดใช้งาน COREPAY":"ปิด COREPAY"}*/}
                        {/*        onChange={(e)=>updateEnableVizStatus(e.target.checked)}*/}
                        {/*        ref={register}*/}
                        {/*    />*/}
                        {/*</Col>*/}
                        {/*<Col className={"col-xs-12 col-md-6"} hidden={watchEnable === false}>*/}
                        {/*    <Form.Label>แจก QR VIZ ให้ลูกค้าใหม่ฝากเท่านั้น</Form.Label>*/}
                        {/*    <Form.Switch*/}
                        {/*        // type="switch"*/}
                        {/*        custom*/}
                        {/*        id="force_qr_customer"*/}
                        {/*        name="force_qr_customer"*/}
                        {/*        label={watchQRNewUser === true ? "เปิดแจกลูกค้าใหม่":"ปิด"}*/}
                        {/*        ref={register}*/}
                        {/*        onChange={(e)=>updateForceQRViz(e.target.checked)}*/}
                        {/*    />*/}
                        {/*</Col>*/}
                    </Form.Row>
                    {/*<Form.Row className={"pt-2"} hidden={watchEnable === false}>*/}
                    {/*    <Col className="col-xs-12 col-md-6">*/}
                    {/*        <Form.Label>เวลาปิดใช้งาน VIZ (เริ่มต้น)</Form.Label>*/}
                    {/*        <Form.Control readOnly ref={register} as="input" type="time" name="start_time" defaultValue={moment("22:50", 'HH:mm').format( 'HH:mm' )}></Form.Control>*/}
                    {/*    </Col>*/}
                    {/*    <Col className="col-xs-12 col-md-6">*/}
                    {/*        <Form.Label>เวลาปิดใช้งาน VIZ (สิ้นสุด)</Form.Label>*/}
                    {/*        <Form.Control readOnly ref={register} as="input" type="time" name="end_time" defaultValue={moment("02:00", 'HH:mm').format( 'HH:mm' )}></Form.Control>*/}
                    {/*    </Col>*/}
                    {/*</Form.Row>*/}
                    {/*<Form.Row className={"pt-2"} hidden={watchEnable === false}>*/}
                    {/*    <Col className="col-xs-12 col-md-6">*/}
                    {/*        <Form.Label>ลูกค้าใหม่บังคับฝาก VIZ (จำนวนครั้ง)</Form.Label>*/}
                    {/*        <Form.Control ref={register({valueAsNumber:true})} type="number" id="minimum_deposit_count" name="minimum_deposit_count" defaultValue={10}/>*/}
                    {/*    </Col>*/}
                    {/*    <Col className="col-xs-12 col-md-6">*/}
                    {/*        <Form.Label>ลูกค้าใหม่บังคับฝาก VIZ (จำนวนเงินรวม)</Form.Label>*/}
                    {/*        <Form.Control ref={register} type="number" id="minimum_deposit_amount" name="minimum_deposit_amount" defaultValue={100}></Form.Control>*/}
                    {/*    </Col>*/}
                    {/*</Form.Row>*/}
                    {/*<Form.Row className={"pt-2"} hidden={watchEnable === false}>*/}
                    {/*    <Col className="col-xs-12 col-md-12">*/}
                    {/*        <Form.Label>ข้อความแจ้งลูกค้า</Form.Label>*/}
                    {/*        <Form.Control ref={register} as="textarea" rows={14} id="corepay_message" name="message" defaultValue={""}></Form.Control>*/}
                    {/*    </Col>*/}
                    {/*</Form.Row>*/}
                    {/*<Form.Row className={"pt-2"}>*/}
                    {/*    <Button type={"submit"} disabled={isDirty === false}>ตั้งค่าจัดการ COREPAY</Button>*/}
                    {/*</Form.Row>*/}
                </Form>
            </Card.Body>
        </Card>
    )

}