import React, { useEffect } from 'react';
import './App.scss';
import Header from "./component/Header";
import Content from "./component/Content";
import Footer from "./component/Footer";
import { BrowserRouter as Router, Redirect, Route, Switch, } from "react-router-dom";
import NewsUploads from "./component/news_upload";
import QRSession from "./component/qr_session";
import Login from "./pages/login/LoginPage";
import DashBoard from "./component/dashboard/DashBoard";
import DashboardAffiliate from "./component/dashboard/DashboardAffiliate"
import RegisterCustomer from "./component/manage_customer/RegisterCustomer";
import ManageBanks from "./component/manage_admin/ManageBanks"
import ManageCCUsers from "./component/manage_admin/ManageCCUsers"
import config from "./config"
import { useGlobalStore } from "./store/globalContext";
import DepositWorkOrder from './component/work_order/DepositWorkOrder';
import WithdrawWorkOrder from './component/work_order/WithdrawWorkOrder'
import MoveWithdrawWorkOrder from './component/work_order/MoveWithdrawWorkOrder'
import MoveHoldingWorkOrder from './component/work_order/MoveHoldingWorkOrder'
import DepositSummaryWorkOrder from './component/work_order/DepositSummaryWorkOrder';
import WithdrawSummaryWorkOrder from './component/work_order/WithdrawSummaryWorkOrder';
import TrueWalletSummaryWorkOrder from './component/work_order/TrueWalletSummaryWorkOrder';
import SweepSummaryWorkOrder from './component/work_order/SweepSummaryWorkOrder';
import WarningUsers from './component/manage_customer/WarningUsers';
import CheckCustomerTransactions from './component/manage_customer/CheckCustomerTransaction';
import ManageLuckyDraw from './component/manage_admin/ManageLuckyDraw';
import ManageSlipAllTeam from "./component/manage_admin/ManageSlipAllTeam";
import { setAxiosHeaderAuthorization } from '../src/client/axios';
import localStorage from "../src/store/localStorage";
import {
    route_config,
    route_config_fever, route_config_lucky,
    route_config_pgadmin, route_multiple_config, route_simple_config,
} from './route_config'
import LogOutPage from './pages/login/LogOutPage';
import RefundCustomers from './component/manage_admin/RefundCustomers'
import RefundCustomersAccept from './component/manage_admin/RefundCustomersAccept'
import SMSReader from "./component/sms/SMSReader";
import BonusCustomers from './component/manage_admin/BonusCustomers'
import TrueWalletWorkOrder from './component/work_order/TrueWalletWorkOrder'
import RefundSocial from './component/manage_admin/RefundSocial'
import ManageAgent from "./component/manage_agent";
import Invoice from './component/invoice';
import InvoiceSummary from './component/invoice_summary';
import ManageProspectCustomer from './component/manage_admin/ManageProspectCustomer';
import ManageABNormalStatement from './component/manage_admin/ManageABNormalStatement';
import AOS from 'aos';
import styled from 'styled-components';
import ManageTopupCreditGclub from "./component/manage_admin/ManageTopupCreditGclub";
import ManageBankStatement from "./component/manage_admin/ManageBankStatement";
import ManageBanksDetail from "./component/manage_admin/ManageBanksDetail";
import {useRecoilState} from "recoil";
import {reconnectSocketObjectRecoilState} from "./store/atoms";
import ManagePromotion from "./component/manage_promotion";
import MonitoringBankAccount from "./component/manage_admin/MonitoringBankAccount";
import MonitorBankAccount from "./component/manage_admin/MonitoringBankAccount";
import AffiliateCustomers from "./component/manage_admin/AffiliateCustomers";
import ManageCustomerGameCash from "./component/manage_admin/ManageCustomerGameCash";

const MainLayout = styled.div`
  position: relative;
`

AOS.init()
let socket

const routeAgent = () => {
    // const name = localStorage.getAccessName()
    return route_multiple_config
}

const hasPermission = ( path ) => {

    const role = localStorage.getAccessRole()
    const route = routeAgent()
    // console.log("hasPermission route : ", route)
    // console.log("hasPermission path : ", path)
    // console.log("hasPermission role : ", role)
    // console.log("route.menus : ", route.menus)
    // console.log("process.env.REACT_APP_LOGIN_AGENT_NAME: ", process.env.REACT_APP_LOGIN_AGENT_NAME)
    return route.menus.map( menu => {
                menu?.sub_menu_list?.some(
                    subMenu => subMenu.path === path
                )
            // console.log("menu: ", menu.sub_menu_list)
            return true
    } )

}

const PrivateRoute = ( { component: Component, path, ...rest } ) => {

    return (
        // Show the component only when the user is logged in
        // Otherwise, redirect the user to /signin page
        <Route path={path} {...rest} render={props => (
            localStorage.getAccessToken()
                ? hasPermission( path )
                ? localStorage.getRedirect()
                    ? <Component {...props} />
                    : RedirectRole()
                : <Redirect to="/registeruser"/>
                : <Redirect to="/login"/>
        )}/>
    );
};

const RedirectRole = () => {
    localStorage.setRedirect( true )
    const role = localStorage.getAccessRole()
    // console.log("role: ", role)
    switch ( role ) {
        case "normal" :
            return <Redirect to="/deposit_workorder"/>
        case "admin" :
            return <Redirect to="/deposit_workorder"/>
        case "super_admin" :
            return <Redirect to="/dashboard"/>
        case "agent":
            return <Redirect to="/dashboard"/>
        default:
            return <Redirect to="/deposit_workorder"/>
    }
}


function App () {
    //มีการเรียกใช้ useGlobalStore Component ที่มีกำหนดค่า ContextProvider ประกอบด้วย reducer และ state ของมัน

    const [ { subscribe, toast, message_api, reconnect_socket }, dispatch ] = useGlobalStore()
    const [reconnectSocketObject, setReconnectSocketObject] = useRecoilState(reconnectSocketObjectRecoilState);

    //สร้าง useEffect#1 ของ web socket init first time ครั้งแรก เพื่อเอาไว้รับค่า ที่ส่งกลับมาจาก server
    //const [reconnectSocket, setReconnectSocket] = useState(moment().format("DD_MM_YYYY_HH_mm_ss"))
    setAxiosHeaderAuthorization( localStorage.getAccessToken() )

    useEffect( () => {
        //console.log("XXXXXXX reconnectSocket: ", reconnectSocket)
        if ( !localStorage.getAccessToken() ) {
            return
        }

        console.log( "reconnect_socket", )
        if ( !reconnect_socket ) return


        socket = new WebSocket( config.WEBSOCKET_LOCAL )

        socket.onopen = () => {
            dispatch( {
                type: "reconnect_socket",
                value: false
            } )
            setInterval( () => {
                //console.log("send healthcheck: ", subscribe)
                const healthCheck = {
                    method: "health_check"
                }
                socket.send( JSON.stringify( healthCheck ) )
            }, 30000 );
        }


        socket.onmessage = ( wsmessage ) => {
            //console.log("wsmessage :", wsmessage)
            console.log("ws global on message :", JSON.parse(wsmessage.data))
            const { method, message, data } = JSON.parse( wsmessage.data );
            let method_replace = method


            if (method.includes(`${process.env.REACT_APP_LOGIN_AGENT_NAME}_`)){
                method_replace = method.replace(`${process.env.REACT_APP_LOGIN_AGENT_NAME}_`,"")
            }

            dispatch( {
                type: method_replace,
                value: {
                    message,
                    data
                },
            } )
        }

        socket.onerror = () => {
            console.log( "connections error: " );
            setReconnectSocketObject({show: true});
            // setInterval(()=>{
            //     dispatch( {
            //         type: "reconnect_socket",
            //         value: true
            //     } )
            // },10000)
        }
        socket.onclose = () => {
            console.log( "connections onclose: " );
            setReconnectSocketObject({show: true});
            // setInterval(()=>{
            //     dispatch( {
            //         type: "reconnect_socket",
            //         value: true
            //     } )
            // },10000)
        }
    }, [ reconnect_socket ] )

    useEffect( () => {
        //console.log("reconnect_socket: ", reconnect_socket)
        // console.log( "send subscribe: ", subscribe )
        if (socket === undefined){
            console.log("subscribe : ", subscribe, "dispatch reconnect socket" )
            // setReconnectSocketObject({show: true});
            // dispatch( {
            //     type: "reconnect_socket",
            //     value: true
            // } )
            return
        }

        if ( reconnect_socket === false && subscribe !== null ) {

            let topic = subscribe.topic
            if(process.env.REACT_APP_MQ_BASE !== "local"){
                console.log("subscribe topic not local: ", process.env.REACT_APP_MQ_BASE)
                topic = `${process.env.REACT_APP_LOGIN_AGENT_NAME}_${subscribe.topic}`
            }

            const subscriber = {
                ...subscribe,
                topic: topic
            }
            console.log( "send subscriber: ", subscriber )
                socket.send( JSON.stringify( subscriber) );
        }
    }, [reconnect_socket, subscribe])

    return (
        <Router>
            <Switch>
                <Route path='/unauthorized' exact>
                    Unauthorized
                </Route>
                {/* <Route path="">
          <Login />
        </Route> */}
                <Route path="/login" exact>
                    <Login/>
                </Route>
                <Route path="/" component={Login} exact/>
                <MainLayout className="wrapper">
                    <Header/>
                    {/* <Menu /> */}
                    <Content>
                        {/* <Route path="/registeruser">
              <RegisterCustomer />
            </Route> */}
                        <PrivateRoute component={RegisterCustomer} path="/registeruser" exact/>
                        {
                            (process.env.REACT_APP_LOGIN_AGENT_NAME === "ufa8888")?
                                (<PrivateRoute component={DashboardAffiliate} path="/dashboard" exact/>):
                                (<PrivateRoute component={DashBoard} path="/dashboard" exact/>)
                        }
                        <PrivateRoute component={DepositWorkOrder} path="/deposit_workorder" exact/>
                        <PrivateRoute component={WithdrawWorkOrder} path="/withdraw_workorder" exact/>
                        <PrivateRoute component={WarningUsers} path="/warning" exact/>
                        <PrivateRoute component={DepositSummaryWorkOrder} path="/deposit_workorder_summary" exact/>
                        <PrivateRoute component={WithdrawSummaryWorkOrder} path="/withdraw_workorder_summary" exact/>
                        <PrivateRoute component={SweepSummaryWorkOrder} path="/sweep_workorder_summary" exact/>
                        <PrivateRoute component={MoveWithdrawWorkOrder} path="/movewithdraw_workorder" exact/>
                        <PrivateRoute component={MoveHoldingWorkOrder} path="/moveholding_workorder" exact/>
                        <PrivateRoute component={ManageBanks} path="/manage_banks" exact/>
                        <PrivateRoute component={ManageCCUsers} path="/manage_cc_users" exact/>
                        <PrivateRoute component={ManageAgent} path="/manage_agent" exact/>
                        <PrivateRoute component={RefundCustomers} path="/refund_customers" exact/>
                        <PrivateRoute component={RefundCustomersAccept} path="/refund_customers_accept" exact/>
                        <PrivateRoute component={BonusCustomers} path="/bonus_customers" exact/>
                        <PrivateRoute component={TrueWalletWorkOrder} path="/true_wallet_workorder" exact/>
                        <PrivateRoute component={TrueWalletSummaryWorkOrder} path="/true_wallet_summary_workorder"
                                      exact/>
                        <PrivateRoute component={RefundSocial} path="/refund_social" exact/>
                        <PrivateRoute component={SMSReader} path="/sms_reader" exact/>
                        <PrivateRoute component={Invoice} path="/invoice" exact/>
                        <PrivateRoute component={InvoiceSummary} path="/invoice_summary" exact/>
                        <PrivateRoute component={CheckCustomerTransactions} path={"/checktransactions"} exact/>
                        <PrivateRoute component={NewsUploads} path={"/news_upload"} exact/>
                        <PrivateRoute component={QRSession} path={"/qr_session"} exact/>
                        <PrivateRoute component={ManageProspectCustomer} path={"/manage_prospect_customer"} exact/>
                        <PrivateRoute component={ManageLuckyDraw} path={"/manage_luckydraw"} exact/>
                        <PrivateRoute component={ManageSlipAllTeam} path={"/manage_slip_allteam"} exact/>
                        <PrivateRoute component={ManageABNormalStatement} path={"/manage_abnormal_statement"} exact/>
                        <PrivateRoute component={ManageTopupCreditGclub} path={"/manage_topup_credit_gclub"} exact/>
                        <PrivateRoute component={ManageBankStatement} path={"/manage_bank_statements"} exact/>
                        <PrivateRoute component={ManageBanksDetail} path={"/manage_banks_detail"} exact/>
                        <PrivateRoute component={ManagePromotion} path={"/manage_promotion"} exact/>
                        <PrivateRoute component={MonitorBankAccount} path={"/monitor_bank_account"} exact/>
                        <PrivateRoute component={ManageCustomerGameCash} path={"/manage_customer_game_cash"} exact/>
                        {
                            (process.env.REACT_APP_LOGIN_AGENT_NAME === "ufa8888") &&
                                <PrivateRoute component={AffiliateCustomers} path={"/affiliate_customers"} exact/>

                        }
                        <Route path="/logout" exact>
                            <LogOutPage/>
                        </Route>
                    </Content>
                    <Footer/>
                </MainLayout>
                {/* <Route path="*" component={Login} /> */}
            </Switch>
        </Router>
    );
}

export default App;
