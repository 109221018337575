import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import localStorage from "../store/localStorage";
import config from "../config";
import {Button, Modal, OverlayTrigger, Tooltip} from "react-bootstrap";
import api from "../api/managebank";
import moment from "moment";
import GlobalTable from "./manage_customer/GlobalTable";
import {useRecoilState} from "recoil";
import {notificationsObjectRecoilState, reconnectSocketObjectRecoilState} from "../store/atoms";
import {useGlobalStore} from "../store/globalContext";


let socket2

export function NotificationModal(modalNotifications) {
    return (
        <Modal size={modalNotifications.size}
               show={modalNotifications.show}
               scrollable={true}
               onHide={() => modalNotifications.onClose()}
               centered={true}
        >
            <Modal.Header closeButton>
                <Modal.Title>{modalNotifications.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{modalNotifications.children}</Modal.Body>
            <Modal.Footer>
                {
                    (modalNotifications.footer) ? (modalNotifications.footer) : (<></>)
                }
                <Button variant="danger" onClick={() => modalNotifications.onClose()}>
                    ปิด
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default function Header() {
    const history = useHistory()
    const [{login_profile}, dispatch] = useGlobalStore()
    const [totalPendingOrder, setTotalPendingOrder] = useState(0)
    const [waitingBankLogin, setWaitingBankLogin] = useState(0)
    const [abnormalStatements, setAbnormalStatements] = useState(false)
    const [modalNotifications, setModalNotifications] = useState({
        size: "lg",
        title: "Notification Center",
        show: false,
        onClose: () => setModalNotifications({...modalNotifications, show: false}),
        children: <div>ไม่พบข้อมูล</div>
    })
    const [notificationObject, setNotificationObject] = useRecoilState(notificationsObjectRecoilState)

    const handleReconnect = () => {
        console.log("reconnect socket");
        dispatch({
            type: "reconnect_socket",
            value: true,
        });
        setReconnectSocketObject({show: false});
        window.location.reload();
    };

    const [modalReconnectSocket, setModalReconnectSocket] = useState({
        size: "lg",
        title: "เชื่อมต่อระบบใหม่",
        show: false,
        onClose: handleReconnect,
        children: <div>ระบบตัดการเชื่อมต่อ เนื่องจากไม่ได้ใช้งานในระยะเวลาที่กำหนด กรุณากดปุ่มเพื่อเชื่อมต่อใหม่</div>,
        footer: <div>
            <Button onClick={handleReconnect}><i className="fas fa-sync mr-2"/>เชื่อมต่อ</Button>
        </div>,
    })

    const [reconnectSocketObject, setReconnectSocketObject] = useRecoilState(reconnectSocketObjectRecoilState);

    const tooltips = (props) => {
        // console.log("tooltips tooltips")
        return (
            <Tooltip id={props.id}>
                <strong>{props.text}</strong>
                {props.children}
            </Tooltip>
        )
    }

    useEffect(() => {
        setModalReconnectSocket({...modalReconnectSocket, show: reconnectSocketObject.show});
    }, [reconnectSocketObject]);

    useEffect(() => {
        // console.log("Header.js navbarRecoilState : ", navbarRecoilState)
        if (!localStorage.getAccessToken()) {
            return
        }
        //
        // console.log("reconnect_socket",)
        //
        socket2 = new WebSocket(config.WEBSOCKET_LOCAL)

        socket2.onopen = () => {
            console.log("open new socket navbar notification")
            // setInterval(() => {

            let topic = "navbar_notifications"
            if(process.env.REACT_APP_MQ_BASE !== "local"){
                console.log("navbar_notifications not local: ", process.env.REACT_APP_MQ_BASE)
                topic = `${process.env.REACT_APP_LOGIN_AGENT_NAME}_navbar_notifications`
            }

            const navbarRequest = {
              method: "subscribe",
              topic: topic,
            }
            socket2.send(JSON.stringify(navbarRequest))
            // },30000);
        }


        socket2.onmessage = (event) => {
            console.log("ws navbar onmessage :", JSON.parse(event.data))
            const {data} = JSON.parse(event.data);
            if (data !== null && data !== undefined) {
                const {total_pending, total_bank_login_pending, statement_error_notifications, abnormal_statements} = data
                console.log("abnormal_statements: ", abnormal_statements)

                if (abnormal_statements) {
                    setAbnormalStatements(true)
                }

                if (total_pending !== -1) {
                    setTotalPendingOrder(total_pending)
                }

                if (total_bank_login_pending !== -1) {
                    setWaitingBankLogin(total_bank_login_pending)
                }

                if (statement_error_notifications !== null && statement_error_notifications !== undefined && login_profile.role !== "agent") {
                    setNotificationObject({
                        text: `พบปัญหา ธนาคาร 
                        ${statement_error_notifications.bank_name} 
                        ${statement_error_notifications.bank_account_no} 
                        ${statement_error_notifications.error_message}`,
                        type: "error"
                    })

                }
            }
        }

        socket2.onerror = () => {
            console.log("connections error: ");
        }

        socket2.onclose = () => {
            console.log("connections onclose: ");
        }
    }, [])


    const getBankingErrorlog = async () => {

        try {
            const response = await api.BANK_STATEMENTS_ERROR(
                {
                    bank_account_no: "",
                    limit:100
                }
            );
            const {data} = response.data
            //console.log("bank statements error : ", data)
            // const data2 = null
            if (data === null) {
                setNotificationObject({
                    text: `ไม่พบข้อมูลปัญหาธนาคารในขณะนี้`, type: "error"
                })
                return
            }

            let dataConvert = []
            data.map((element) => {
                delete element.id
                delete element.customer_id

                element.created_at = moment(element.created_at).format("DD/MM/YYYY HH:mm:ss")
                dataConvert.push(element)
            })
            const dataConvertTransformed = dataConvert.map(({
                                                                bank_name,
                                                                bank_account_no,
                                                                bank_short_name,
                                                                bank_type,
                                                                created_at,
                                                                error_message
                                                            }, index) => (
                {
                    "#": index + 1,
                    ชื่อธนาคาร: `${bank_name}`,
                    เลขที่บัญชี: `${bank_short_name} - ${bank_account_no}`,
                    // ข้อมูลธนาคาร:
                    //     <LogoWithAccountNoSimple
                    //     bankShortName={bank_short_name}
                    //     bankFullName={bank_name}
                    //     bankName={bank_name}
                    //     accountNo={bank_account_no}
                    //     workOrderType={bank_type}
                    // />,
                    ช่วงเวลาที่มีปัญหา: created_at,
                    ปัญหา: error_message,
                }
            ));

            // console.log("dataConvertTransformed :", dataConvertTransformed )

            setModalNotifications(
                {
                    size: "xl",
                    title: "ปัญหาข้อมูลธนาคาร",
                    show: true,
                    onClose: () => setModalNotifications({...modalNotifications, show: false}),
                    children:
                        <GlobalTable tableData={dataConvertTransformed} pageSizing={50}/>
                })
        } catch (error) {
            console.log("inquiry customer logs error : ", error);
        }
    }


    return (
        <>
            <NotificationModal {...modalNotifications} >
                {modalNotifications.children}
            </NotificationModal>
            <NotificationModal {...modalReconnectSocket} >
                {modalReconnectSocket.children}
            </NotificationModal>
            <nav className="main-header navbar navbar-expand navbar-white navbar-light">
                <ul className="navbar-nav">
                    <li className="nav-item">
                        <a className="nav-link" data-widget="pushmenu" href="#" role="button"><i
                            className="fas fa-bars"/></a>
                    </li>
                    <li className="nav-item align-self-center">
                        <div className="">
              <span className="font-weight-bold text-lg">
                {process.env.REACT_APP_BACKOFFICE_NAME || "UFA FEVER V2"}
              </span>
                        </div>
                    </li>
                </ul>
                {/*TODO Delete Later*/}
                {/*<ul className="navbar-nav mx-auto">*/}
                {/*    <span className="font-weight-bold text-base text-red-600">*/}
                {/*        บัญชีธนาคารกสิกรไทยในรูปแบบ Mobile Banking ไม่สามารถเข้าใช้งาน KPlus ได้ หากเข้าใช้งาน KPlus จะทำให้บัญชีในระบบออโต้ไม่สามารถทำงานได้*/}
                {/*    </span>*/}
                {/*</ul>*/}
                <ul className="navbar-nav ml-auto">
                    <li className="nav-item dropdown">
                        <div style={{display: "flex", flexDirection: "row"}}>
                            {
                                abnormalStatements ?
                                    <OverlayTrigger
                                        defaultShow={false}
                                        key={`notification-tips-${moment().format("YYYY-MM-DD-HH-MM-SS")}`}
                                        placement={"bottom"}
                                        overlay={
                                            <Tooltip id={`tooltip-notification-tips`}>
                                                <strong>ตรวจสอบรายการผิดปกติ</strong>
                                            </Tooltip>
                                        }
                                    >
                                        <a className="nav-link" role="button">
                                            <i
                                                className="fas fa-exclamation-triangle text-red"
                                                onClick={() => history.push('/manage_abnormal_statement')}/>
                                            <span
                                                className="badge badge-danger navbar-badge">{1}</span>
                                        </a>
                                    </OverlayTrigger>:
                                    <></>
                            }
                            <div>
                                <OverlayTrigger
                                    defaultShow={false}
                                    key={"banking-error-notifications"}
                                    placement={"bottom"}
                                    overlay={tooltips({
                                        id: 'tooltip-news-banking-status',
                                        text: 'สถานะการดึงข้อมูลบัญชีธนาคาร'
                                    })}
                                >
                                    {/*<a className="nav-link" role="button" onClick={()=>setModalNotifications({...modalNotifications, show: true})}>*/}
                                    <a className="nav-link" role="button" onClick={() => getBankingErrorlog()}>
                                        <i className="fas fa-network-wired"/>
                                        {
                                            (notificationObject.text.includes("พบปัญหา ธนาคาร")) ?
                                                <span className="badge badge-danger navbar-badge">*</span> : <></>
                                        }

                                    </a>
                                </OverlayTrigger>
                            </div>
                            <OverlayTrigger
                                defaultShow={false}
                                key={"latest-news-tip"}
                                placement={"bottom"}
                                overlay={
                                    tooltips({id: 'tooltip-latest-news-tips', text: 'ข่าวสารล่าสุด'})
                                }
                            >
                                <a className="nav-link" role="button">
                                    <i
                                        className="fas fa-tasks"
                                        // onClick={ () => window.open('https://main.dugsg4t7q5xtb.amplifyapp.com', '_blank').focus() }
                                        onClick={() => setModalNotifications({
                                            ...modalNotifications,
                                            size: 'xl',
                                            title: "ข่าวสารล่าสุด",
                                            show: true,
                                            children: (<iframe src="https://main.dugsg4t7q5xtb.amplifyapp.com"
                                                               sandbox="allow-scripts allow-popups allow-same-origin"
                                                               width={1120} height={600}/>),
                                            onClose: () => setModalNotifications({...modalNotifications, show: false})
                                        })}
                                    />
                                </a>
                            </OverlayTrigger>
                            <OverlayTrigger
                                defaultShow={false}
                                key={"bank-login-tip"}
                                placement={"bottom"}
                                overlay={
                                    tooltips({id: 'tooltip-bank-login-tips', text: 'ธนาคารรอการ Login ผ่าน QR'})
                                }
                            >
                                <a className="nav-link" role="button">
                                    <i
                                        className="fas fa-qrcode"
                                        onClick={() => history.push('/qr_session')}/>
                                    {
                                        (waitingBankLogin > 0) ? (<span
                                            className="badge badge-danger navbar-badge">{waitingBankLogin}</span>) : <></>
                                    }

                                </a>
                            </OverlayTrigger>
                            <OverlayTrigger
                                defaultShow={false}
                                key={"notification-tips"}
                                placement={"bottom"}
                                overlay={
                                    <Tooltip id={`tooltip-notification-tips`}>
                                        <strong>แจ้งเตือน</strong>
                                    </Tooltip>
                                }
                            >
                                <a className="nav-link" role="button">
                                    <i
                                        className="far fa-bell"
                                        onClick={() => history.push('/withdraw_workorder')}/>
                                    {
                                        (totalPendingOrder > 0) ? (<span
                                            className="badge badge-danger navbar-badge">{totalPendingOrder}</span>) : <></>
                                    }

                                </a>
                            </OverlayTrigger>
                            <OverlayTrigger
                                key={"sign-out-tips"}
                                placement={"bottom"}
                                overlay={
                                    tooltips({id: 'tooltip-sign-out-tips', text: 'ออกจากระบบ'})
                                }
                            >
                                <a className="nav-link" role="button" onClick={() => history.push('/logout')}>
                                    <i className="fas fa-sign-out-alt"/>
                                    {' '} <b>ออกจากระบบ</b>
                                </a>
                            </OverlayTrigger>
                        </div>
                    </li>
                </ul>
            </nav>
        </>
    );
}