import React, {useState} from "react";
import {Button, Modal, Spinner} from "react-bootstrap";
import api_workorder from "../../api/workorder";
import { useGlobalStore } from "../../store/globalContext";

export function CustomerRetryModal(props) {
  const { show, data, handleClose } = props
  const [ retryButton, setRetryButton ] = useState(false);

  const handleRetry = async () => {
    setRetryButton(true);
    try {
      const request = {
        id: data.work_order_id,
      }
      const response = await api_workorder.WORK_ORDER_RETRY(request)
      console.log(response.data)
    } catch (e) {
      console.log(`retry work order failed`, e.message);
    } finally {
      setTimeout(() => {
        setRetryButton(false)
        handleClose()
      }, 1000)
    }
  }

  return (
    <Modal
      show={ show }
      onHide={ handleClose }
      backdrop="static"
      keyboard={ false }
    >
      <Modal.Header closeButton>
        <Modal.Title>จัดการคืนยอดเสียรับเอง</Modal.Title>
      </Modal.Header>
      <Modal.Footer>
        <Button variant="primary" onClick={ handleRetry } disabled={retryButton}>
          {
            (retryButton) ?
                (<Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                />) : (<>ทำซ้ำ</>)
          }
        </Button>
        <Button variant="secondary" onClick={ () => handleClose(false) }>
          ปิด
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export function CustomerSocialRetryModal(props) {
  const { show, data, handleClose } = props
  const { login_profile } = useGlobalStore()[0];
  const [ retryButton, setRetryButton ] = useState(false);

  const handleRetry = async () => {
    setRetryButton(true);
    try {
      const request = {
        token: login_profile.token,
        request: {
          id: data.id
        }
      }
      const response = await api_workorder.BONUS_SOCIAL_RETRY(request)
      console.log(response.data)
    } catch (e) {
      console.log(`retry work order failed`, e.message);
    } finally {
      setTimeout(() => {
        setRetryButton(false)
        handleClose()
      }, 1000)
    }

  }

  return (
    <Modal
      show={ show }
      onHide={ handleClose }
      backdrop="static"
      keyboard={ false }
    >
      <Modal.Header closeButton>
        <Modal.Title>จัดการคืนยอดเสียรับเอง</Modal.Title>
      </Modal.Header>
      <Modal.Footer>
        <Button variant="primary" onClick={ handleRetry } disabled={retryButton}>
          {
            (retryButton) ?
                (<Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                />) : (<>ทำซ้ำ</>)
          }
        </Button>
        <Button variant="secondary" onClick={ () => handleClose(false) }>
          ปิด
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
