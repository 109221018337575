import React, {useEffect} from "react";
import styled from 'styled-components'
import MenuComponent from "./MenuComponent";
import { useGlobalStore } from '../store/globalContext'

const SidebarWrapper = styled.div`
    
`
const Sidebar = styled.div`
    
`

export default function Menu() {
    const [{login_profile}] = useGlobalStore();

    useEffect(() => {
        const trees = window.$('[data-widget="treeview"]');
        trees.Treeview('init');
    }, []);

    return (
            <aside className="main-sidebar sidebar-dark-primary elevation-4">
                <Sidebar className="sidebar">
                    <div className="flex user-panel pt-2 pb-2 align-items-center">
                        <div className={"info"}>
                            <img src={process.env.REACT_APP_ICON_IMAGE} className="img-size-64 d-block" alt="User Image" />
                        </div>
                        <div className={"info"}>
                            <a href="#" className="d-block"><p className="text-white text-bold text-lg">{login_profile.name}</p></a>
                        </div>
                    </div>
                    <nav className="mt-2">
                        <MenuComponent/>
                    </nav>
                </Sidebar>
            </aside>
    );
}