import React, {useState} from "react";
import api from "../../api/invoice";
import {Badge, Button, Card, Fade, Form, FormControl, Spinner, Table} from "react-bootstrap";
import {useForm} from "react-hook-form";
import moment from "moment";
import {number_format} from "../utils/Number";

export default function ManageSlipAllTeam() {
    const [data, setData] = useState([])
    const [isLoading, setLoading] = useState(false);

    const {register, handleSubmit} = useForm({
        defaultValues: {
            start: moment(new Date()).format("YYYY-MM"),
        },
        criteriaMode: "all"
    });

    const onSubmit = async (searchData) => {
        setData([])
        setLoading(true);
        const req = {
            start: searchData.start
        }

        try {
            const response = await api.INVOICE_GET_ALL_SLIP(req);
            setData(response.data.data)
            setLoading(false);
        } catch (err) {
            console.log(err)
            setLoading(false);
        }
    }

    return (
        <section className="content pt-4 pr-4 pl-4">
            <div>
                <Card className="card-primary">
                    <Card.Header>ค้นหาสลิป All Team</Card.Header>
                    <Card.Body>
                        <Form noValidate onSubmit={handleSubmit(onSubmit)} className="col-md-12">
                            <Form.Row className="col-md-12">
                                <Form.Group className="col-md-6">
                                    <Form.Label>เลือกเดือนที่ต้องการค้นหา</Form.Label>
                                    <div className='flex'>
                                        <FormControl
                                            type="month"
                                            placeholder="2021-10"
                                            ref={register}
                                            name="start"
                                        />
                                        <Button className='ml-4' variant='primary' type="submit"
                                                disabled={isLoading}>
                                            {
                                                isLoading ?
                                                    (
                                                        <>
                                                            <Spinner
                                                                // as="span"
                                                                animation="border"
                                                                size="sm"
                                                                role="status"
                                                                aria-hidden="true"
                                                            /> กำลังค้นหา</>) : 'ค้นหา'}
                                        </Button>
                                    </div>
                                </Form.Group>
                            </Form.Row>
                        </Form>
                    </Card.Body>
                </Card>
                {
                    data.length !== 0 &&
                    <Fade in={true}>
                        <Table responsive hover size="md" className="text-center">
                            <thead className="text-center">
                            <tr>
                                <th>#</th>
                                <th>url</th>
                                <th>รวมรายการทั้งหมด</th>
                                <th>ราคา (บาท)</th>
                                <th>สถานะการอัพโหลดสลิป</th>
                                <th>รูปสลิป</th>
                            </tr>
                            </thead>
                            <tbody className="text-center">
                            {
                                data.map(({
                                              id,
                                              url,
                                              invoice,
                                              slip,
                                          }, index) =>
                                    (invoice.data ?
                                            <tr key={index + 1} className="" objectId={id}>
                                                <td><h6>{index + 1}</h6></td>
                                                <td><h6>{url}</h6></td>
                                                <td><h6>{number_format(invoice.data.total.unit, 0, '', ',')}</h6></td>
                                                <td>
                                                    <h6>{number_format(invoice.data.summary.total_price, 2, '.', ',')}</h6>
                                                </td>
                                                {
                                                    slip.data?.length > 0 ?
                                                        <>
                                                            <td><h6><Badge variant="success">{"สำเร็จ"}</Badge>{' '}
                                                            </h6>
                                                            </td>
                                                            <td align="center" key={index}>
                                                                {
                                                                    slip.data?.map((data, index) => {
                                                                        return (
                                                                            <img key={index} src={data?.slip_image_url}
                                                                                 alt={"slip"}
                                                                                 height={300} width={200}
                                                                                 className="mx-auto mb-4"/>
                                                                        )
                                                                    })
                                                                }
                                                            </td>
                                                        </>
                                                        :
                                                        <>
                                                            <td><h6><Badge
                                                                variant="warning">{"ยังไม่ได้ upload"}</Badge>{' '}</h6>
                                                            </td>
                                                            <td><h6>ไม่มีรูปภาพ</h6></td>
                                                        </>
                                                }
                                            </tr> : <></>
                                    )
                                )
                            }
                            </tbody>
                        </Table>
                    </Fade>
                }
            </div>
        </section>
    )
}