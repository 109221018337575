import axios from '../client/axios'
import config from '../config/index'

// //console.log('config ', config);
export default {
  SEARCH_CC_USER: ({keyword}) => {
    //console.log("search cc user api: ", keyword)
    return axios({
      //headers: {},
      method: 'POST',
      url: `${config.SEARCH_CC_USER}`,
      data:{keyword:keyword}
    })
  },
  CREATE_CC_USER: (keyword) => {
    //console.log("create cc user api: ", keyword)
    return axios({
      //headers: {},
      method: 'POST',
      url: `${config.CREATE_CC_USER}`,
      data:keyword
    })
  },
  UPDATE_CC_USER: (keyword) => {
    //console.log("update cc user api: ", keyword)
    return axios({
      //headers: {},
      method: 'POST',
      url: `${config.UPDATE_CC_USER}`,
      data:keyword
    })
  },
  RESET_TWO_FACTOR: (request) => {
    return axios({
      method: 'POST',
      url: `${config.RESET_TWO_FACTOR}`,
      data: request,
    })
  },
  DELETE_CC_USER: (request) => {
    return axios({
      method: 'POST',
      url: `${config.DELETE_CC_USER}`,
      data: request,
    })
  }
}