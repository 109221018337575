import React, {useEffect, useState} from 'react';
import {Button, Col, Form, FormLabel, InputGroup, Row, Spinner, Table} from "react-bootstrap";
import moment from "moment/moment";
import {useForm} from "react-hook-form";
import api from "../../api/dashboard";

export default function DepositWithdrawTableByChannels() {

    const ed = new Date();
    const std = new Date().setDate(ed.getDate() - 15);
    const [startDate, setStartDate] = useState(moment(std).format("YYYY-MM-DD"));
    const [endDate, setEndDate] = useState(moment(ed).format("YYYY-MM-DD"));
    const [gatewayStats, setGatewayStats] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const {
        register:registerDepositWithdrawTableByChannelsForm,
        handleSubmit:handleSubmitRegisterDepositWithdrawTableByChannelsForms,
    getValues:getValuesDepositWithdrawTableByChannelsForms} = useForm()

    const onSubmitRegisterDepositWithdrawTableByChannelsForm = (formData)=>{
        void getGatewayStats(formData.start_date, formData.end_date)
    }

    const getGatewayStats = async(startDate, endDate) =>{

        const request = {
            start: startDate,
            end: endDate,
        };

        try {
            setIsLoading(true)
            const response = await api.DASHBOARD_GATEWAY_STATS(request);
            const { data } = response.data;
            setGatewayStats(data)
        } catch (error) {
            console.log("get gateway stats error: ", error.message);
        }finally {
            setIsLoading(false)
        }
    }


    return (
        <Form noValidate onSubmit={handleSubmitRegisterDepositWithdrawTableByChannelsForms(onSubmitRegisterDepositWithdrawTableByChannelsForm)}>
            <Form.Row>
                <Form.Group as={Col} controlId="ChannelForm">
                    <Form.Label>กรุณาระบุวันที่ต้องการค้นหา</Form.Label>
                    <InputGroup className="flex col-12">
                        <InputGroup.Prepend>
                            <InputGroup.Text>วันเริ่มต้น</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control type="date"
                                      name={"start_date"}
                                      className={"mr-2"}
                                      placeholder="2020-09-10"
                                      defaultValue={startDate}
                                      ref={registerDepositWithdrawTableByChannelsForm}
                        />
                        <InputGroup.Prepend>
                            <InputGroup.Text>วันสิ้นสุด</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control type="date" name={"end_date"}
                                      className={"mr-2"}
                                      placeholder="2020-10-10"
                                      defaultValue={endDate}
                                      ref={registerDepositWithdrawTableByChannelsForm}
                        />
                        <InputGroup.Append>
                            {
                                (isLoading === false) ? (<Button type={"submit"}>ค้นหา</Button>):(<Spinner animation="border" variant="primary" />)
                            }
                        </InputGroup.Append>
                    </InputGroup>
                </Form.Group>
            </Form.Row>
            <Row>
                <Col className={"flex justify-content-center"}>
                    <FormLabel className={"text-center"}>
                        ข้อมูลของวันที่ {moment(getValuesDepositWithdrawTableByChannelsForms("start_date")).format("DD/MM/YYYY")} - {moment(getValuesDepositWithdrawTableByChannelsForms("end_date")).format("DD/MM/YYYY")}
                    </FormLabel>
                </Col>
            </Row>

            <div className={"flex"}>
            <Table responsive
                   hover
                   size="md"
                   className="col-12 text-center mt-3">
                <thead>
                <tr data-aos="fade-down">
                    <th>#</th>
                    <th>ช่องทางการฝาก</th>
                    {/*<th>Channel</th>*/}
                    <th>จำนวนรายการทั้งหมด</th>
                    <th>จำนวนเงินรวมทั้งหมด</th>
                </tr>
                </thead>
                {
                    isLoading === true &&
                    <tbody>
                    <tr><td colSpan={5}><Spinner animation="border" variant="primary" /></td></tr>
                    </tbody>
                }
                {
                    isLoading === false &&
                    <tbody>
                    {
                        gatewayStats?.filter(m => m.value.includes("ฝาก") === true).map((gw, index) => {
                            return (<tr className={`table-success ${gw.value.includes("ทุกช่องทาง")?("text-bold"):("")}`}>
                                <td>{index + 1}</td>
                                <td>{gw.value}</td>
                                {/*<td>{gw.channel.toUpperCase()}</td>*/}
                                <td>{gw.total_count}</td>
                                <td>{gw.total_amount}</td>
                            </tr>)
                        })
                    }
                    </tbody>
                }
                {
                    (isLoading === false && gatewayStats.length === 0) &&  <tbody><tr><td colSpan={4}>No data</td></tr></tbody>
                }
            </Table>
            <Table responsive
                   hover
                   size="md"
                   className="col-12 text-center mt-3">
                <thead>
                <tr data-aos="fade-down">
                    <th>#</th>
                    <th>ช่องทางการถอน</th>
                    {/*<th>Channel</th>*/}
                    <th>จำนวนรายการทั้งหมด</th>
                    <th>จำนวนเงินรวมทั้งหมด</th>
                </tr>
                </thead>
                {
                    isLoading === true &&
                    <tbody>
                        <tr><td colSpan={5}><Spinner animation="border" variant="primary" /></td></tr>
                    </tbody>
                }
                {
                    isLoading === false &&
                    <tbody>
                    {
                        gatewayStats?.filter(m => m.value.includes("ถอน") === true).map((gw, index) => {
                            return (<tr className={`table-danger ${gw.value.includes("ทุกช่องทาง")?("text-bold"):("")}`}>
                                <td>{index + 1}</td>
                                <td>{gw.value}</td>
                                {/*<td>{gw.channel.toUpperCase()}</td>*/}
                                <td>{gw.total_count}</td>
                                <td>{gw.total_amount}</td>
                            </tr>)
                        })
                    }
                    </tbody>
                }
                {
                    (isLoading === false && gatewayStats.length === 0) &&  <tbody><tr><td colSpan={4}>No data</td></tr></tbody>
                }
            </Table>
            </div>
        </Form>
    );
}