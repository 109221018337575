import axios from 'axios'
import localStorage from '../store/localStorage'

const cancelTokenSource = axios.CancelToken.source();
let client = axios.create({cancelToken: cancelTokenSource.token})

export const setAxiosHeaderAuthorization = ( token ) => {
    if ( token ) {
        //console.log("set axios token :", token)
        client.defaults.headers.common[ 'Authorization' ] = `Bearer ${token}`
    } else {
        //console.log("delete axios token :", token)
        delete client.defaults.headers.common[ 'Authorization' ]
    }
    return client
}

client.interceptors.response.use(
    async( response ) => {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response
    },
    async( error ) => {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        // console.log( error )
        const originalRequest = error.config
        const statusCode = error.response && error.response.status

        if ( statusCode === 401 ) {
            if ( window.confirm( "Session ของคุณหมดอายุ กรุณา login ใหม่" ) ) {
                // console.log( "remove token" )
                localStorage.removeAllAccess()
                cancelTokenSource.cancel()
                window.location.replace("/login")
            }
        }
        return Promise.reject( error )
    }
)
export default client