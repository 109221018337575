const getConfig = () => {
    switch (process.env.REACT_APP_ENV) {
      case 'local':
        return require('./local').default
        break;
      // case 'sit':
      //   return require('./sit').default
      //   break;
      case 'uat':
        return require('./uat').default
        break;
      // case 'prod':
      //   return require('./prod').default
      //   break;
      default:
        return require('./local').default
        break;
    }
  }
  
  export default getConfig()