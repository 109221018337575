import React, {useState, useEffect, useRef} from 'react'
import { useGlobalStore } from '../../store/globalContext'
import WorkOrderDetails from './WorkOrderDetails'
import { Card, Accordion, Button, Col, Row, Form, InputGroup, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form'
import api from "../../api/customer";
import api_bank from "../../api/managebank";
import api_workorder from "../../api/workorder";
import api_dashboard from "../../api/dashboard"
import { AsyncTypeahead } from 'react-bootstrap-typeahead'
import ModalConfirmWorkOrder from './ModalConfirmWorkOrder'
import {LogoWithAccountNoSimple} from "../banks_logo";
import {InfoBoxBGStatsCard} from "../dashboard/StatCard";
import ShowBankListComponent from "./ShowBankListComponent";

const searchConditions = [
    {label:"ยูสเซอร์(user id)", value:"user_id"},
    {label:"ชื่อ - นามสกุล", value:"name"},
    {label:"เบอร์โทรศัพท์", value:"mobile_no"},
    {label:"หมายเลขบัญชี", value:"bank_account_no"},
    // {label:"หมายเลขอ้างอิงธนาคาร", value:"mapping_bank"}
]

export default function WithdrawWorkOrder() {
    const [{ dashboard_work_order_withdraw, login_profile }, dispatch] = useGlobalStore();
    const [validated] = useState(false);
    const [isLoading] = useState(false);
    const { register, handleSubmit, errors, setValue, reset } = useForm({
        defaultValues: {},
        criteriaMode: "all"
    });
    const [workOrderDetailsData, setWorkOrderDetailsData] = useState(
        {
            work_order_type: "withdraw",
            cardHeader: "สรุปข้อมูลใบงานถอน",
            total_work_order_today: 0,
            total_work_order_all: 0,
            total_sweep_withdraw_amount_today: 0,
            total_amount_today: 0
        });

    const [withDrawBank, setWithDrawBank] = useState([]);
    const [customerBankOptions, setCustomerBankOptions] = useState([]);
    const [customerSearchList, setCustomerSeachList] = useState([])

    const [confirmModal, setConfirmModal] = useState(false)
    const [confirmModalData, setConfirmModalData] = useState(
        {
            user_id: "",
            first_name: "",
            last_name: "",
            amount: "",
        })
    const [confirmRequestData, setConfirmRequestData] = useState({})
    const [modalData, setModalData] = useState({ header: "ยืนยันการแก้ไข", headerClassName: "btn-success", bodyTitle: "", placeholder: "ข้อมูลเครดิตถูกต้อง" })
    const [modalConfirmShow, setModalConfirmShow] = useState(false);
    const [handManual, setHandManual] = useState(false);
    const typeAheadRef = useRef()
    const [comment, setComment] = useState("")

    const [searchConditionSelected, setSearchConditionSelected] = useState("")
    const [searchPlaceholder, setSearchPlaceHolder] = useState("ยูสเซอร์ไอดี/ชื่อ/นามสกุล/เบอร์โทรศัพท์/เลขที่บัญชี")
    const [dashboardWarningDetails, setDashboardWarningDetails] = useState([])

    useEffect(() => {
        //console.log("register customer screen before dispatch");
        try {
            getDashboardWarning();
            getWithdrawBankList();
            dispatch({
                type: "SOCKET_SEND",
                value: { method: "subscribe", topic: "dashboard_work_order_withdraw" }
            });
        } catch (error) {
            //console.log("error :", error);
            dispatch({
                type: "TOAST",
                value: {
                    show: true,
                    type: 'danger',
                    title: `ระบบมีปัญหา`,
                    message: `ขออภัยระบบมีปัญหา กรุณาติดต่อ admin`,
                }
            });
        }

    }, [])

    useEffect(() => {
        //console.log("dashboard_work_order_withdraw page: ", dashboard_work_order_withdraw);
        if (dashboard_work_order_withdraw === undefined) {
            return
        }
        setWorkOrderDetailsData(
            {
                ...workOrderDetailsData,
                total_work_order_all: dashboard_work_order_withdraw.total_work_order_all,
                total_work_order_today: dashboard_work_order_withdraw.total_work_order_today,
                total_amount_today: dashboard_work_order_withdraw.total_amount_today,
                current_amount: dashboard_work_order_withdraw.current_amount,
                bank_balance: dashboard_work_order_withdraw.bank_balance,
            }
        )
        // console.log("workOrderDetailsData =======> ", workOrderDetailsData)
    }, [dashboard_work_order_withdraw])

    const getDashboardWarning = async () => {
        await api_dashboard.DASHBOARD_WARNING()
            .then(response => {
                //console.log("dashboard warning response: ", response.data.data)
                const {messages} = response.data.data
                // setDashboardWarning(messages.length)
                // console.log("dashboardWarning :", dashboardWarning)
                // messages.map( ( msg ) => {
                //
                //     console.log( "msg :", msg )
                // } )
                const msgFilter = messages.filter((msg) => {
                    return msg.label !== "แจ้งชำระค่าบริการ"
                })
                setDashboardWarningDetails(msgFilter)
            })
            .catch(error => {
                console.log("get dashboard warning error: ", error.message)
            })
    }

    const getWithdrawBankList = async () => {

        const response = await api_bank.BANK_SEARCH_TYPE({ keyword: "withdraw+vvip" })
        if (response.status !== 200) {
            console.log(`search bank_list by user id failed`, response.message);
            return
        }

        const { data } = response.data
        //console.log("search withdraw bank response data: ", data);
        setWithDrawBank(data);

    }

    const confirmSubmit = async (work_order_withdraw_request) => {
        //console.log("create work_order_withdraw_request : ", work_order_withdraw_request)


        try {
            const response = await api_workorder.WORK_ORDER_CREATE(work_order_withdraw_request)
            setTimeout(() => {
                dispatch({
                    type: "TOAST2",
                    value: {
                        show: true,
                        type: 'success',
                        title: `สร้างใบงาน Manual`,
                        message: `สร้างใบงาน UserID ${work_order_withdraw_request.user_id} สำเร็จ`,
                    }
                })
            }, 2000)
        }catch (error){
            setTimeout(() => {
                dispatch({
                    type: "TOAST2",
                    value: {
                        show: true,
                        type: 'danger',
                        title: `ทำการโยกเงิน ${ work_order_withdraw_request.amount } บาทไม่สำเร็จ`,
                        message: `${  error.response.data.message } บาท`,
                    }
                })
            }, 2000)
        }finally {
            setConfirmRequestData({})
            setConfirmModalData({})
            setConfirmModal(false)
            typeAheadRef.current.clear()
            reset(register)
        }

    }

    const onSubmit = async workOrderData => {

        const bankCustomer = customerBankOptions.filter(customerBankOptions => customerBankOptions.bank_account_no === workOrderData.from_bank_name).pop()
        const bankWorkOrder = withDrawBank.filter(withDrawBank => withDrawBank.bank_account_no === workOrderData.to_bank_name).pop()
        let work_order_withdraw_request
        if (handManual === true) {
            work_order_withdraw_request = {
                ...workOrderData,
                status: "success",
                credit_status: "success",
                work_order_type: "withdraw",
                from_bank_name: bankWorkOrder.bank_name,
                from_bank_account_no: bankWorkOrder.bank_account_no,
                from_bank_short_name: bankWorkOrder.bank_short_name,
                to_bank_name: bankCustomer.bank_account_name,
                to_bank_code: bankCustomer.bank_code,
                to_bank_account_no: bankCustomer.bank_account_no,
                to_bank_short_name: bankCustomer.bank_short_name,
                channel: "manual",
                created_by: login_profile.name,
                updated_by: login_profile.name
            }
        } else {
            work_order_withdraw_request = {
                ...workOrderData,
                work_order_type: "withdraw",
                from_bank_name: bankWorkOrder.bank_name,
                from_bank_account_no: bankWorkOrder.bank_account_no,
                from_bank_short_name: bankWorkOrder.bank_short_name,
                to_bank_name: bankCustomer.bank_account_name,
                to_bank_code: bankCustomer.bank_code,
                to_bank_account_no: bankCustomer.bank_account_no,
                to_bank_short_name: bankCustomer.bank_short_name,
                channel: "manual",
                created_by: login_profile.name,
                updated_by: login_profile.name
            }
        }


        setConfirmRequestData(work_order_withdraw_request)
        setConfirmModalData({
            work_order_label_type: "ถอน",
            user_id: workOrderData.user_id,
            first_name: workOrderData.first_name,
            last_name: workOrderData.last_name,
            amount: workOrderData.amount,
        })

        setConfirmModal(true)
    }

    const handleSearchDataFromUserID = async (userIdForSearch) => {

        if (searchConditionSelected === "") {
            return
        }

        const response = await api.SEARCH_CUSTOMER({
            field: searchConditionSelected,
            keyword: userIdForSearch
        })
        // console.log("search customer by user id response: ", response);
        if (response.status !== 200) {
            console.log(`search customer by user id failed`, response.message);
            return
        }

        const { data } = response.data
        if (data === null) {
            setCustomerSeachList([]);
            return
        }
        //console.log("search customer by user id response data: ", data);

        let dataArr = []
        data.map(e => {
            // console.log("element data search: ", e)
            if (e.agents.length === 2){
                const agentsArr = e.agents
                agentsArr.map(ex =>{
                    //console.log("agents elements : ", ex)
                    let dTemp = {...e, user_id : ex.username}
                    // dTemp.user_id = ex.username
                    dataArr.push(dTemp)
                }) 
                
            }else{
                dataArr.push(e)
            }
        })
        //console.log("element dataArr: ", dataArr)
        setCustomerSeachList(dataArr);
    }

    const handleSetDataFromUserID = (value) => {
        if (value.length === 0) {
            typeAheadRef.current.clear()
            return
        }
        //console.log("handleSetDataFromUserID : ", ...value)
        const result = value[0]
        //const result = value.filter(value => value.user_id === value).pop()
        const { user_id, first_name, last_name, mobile_no, bank_accounts } = result
        //console.log("handleSetDataFromUserID : ", user_id, first_name, last_name, mobile_no, bank_accounts)
        setValue("user_id", user_id)
        setValue("first_name", first_name)
        setValue("last_name", last_name)
        //console.log("bank_accounts : ", bank_accounts)
        let customerBank = []
        bank_accounts.map(({ bank_short_name, bank_account_name, bank_account_no, bank_code }, index) => {
            //console.log("in loop", bank_short_name, bank_account_name, bank_account_no, bank_code)
            // customerBank.push({ bank_short_name: bank_short_name, bank_account_name: bank_account_name,
            //     bank_account_no: bank_account_no, bank_code: bank_code })
            if (bank_accounts.length === 1) {
                customerBank.push({ bank_short_name: bank_short_name, bank_account_name: bank_account_name, bank_account_no: bank_account_no, bank_code: bank_code, selected: true })
                setValue("from_bank_account_no", customerBank[0].bank_account_no)
            } else {
                customerBank.push({ bank_short_name: bank_short_name, bank_account_name: bank_account_name, bank_account_no: bank_account_no, bank_code: bank_code, selected: false })
            }
        })

        setCustomerBankOptions(customerBank);
        typeAheadRef.current.clear()
    }

    const handleSetBankData = (value) => {
        //console.log("handleSetBankData : ", value)
        setValue("from_bank_account_no", value)
    }

    const handleSetWithDrawBankData = (value) => {
        //console.log("handleSetWithDrawBankData : ", withDrawBank)
        setValue("to_bank_account_no", value)
        const wts = withDrawBank.filter(e => e.bank_account_no === value).pop()
        const balance = wts.balance
        if (balance >= 0) {
            setValue("balance", numberWithCommas(balance))
        } else {
            setValue("balance", "N/A")
        }
    }

    const enableModalByMode = (props) => {
        //console.log("props:", props)
        setModalData(props)
        setModalConfirmShow(true)
        setComment("")
    }

    const approveWorkOrderByType = async (props) => {
        //console.log("approve work order data: ", props)

        try {
            let response = null
            setModalConfirmShow(false)
            switch (props.api_type) {
                case "approve":
                    response = await api_workorder.APPROVE_WORKORDER(props)
                    break
                case "approve_manual":
                    response = await api_workorder.APPROVE_MANUAL_WORKORDER(props)
                    break
                default:
                    response = await api_workorder.REJECT_WORKORDER(props)
                    break
            }

            if (response.status !== 200) {
                //console.log(`create work_order_deposit_failed`, response.message);
                return
            }
            //console.log("getWorkOrderListByType response.data: ", response.data)
            if (response.data.data === null || response.data.data === undefined) {
                return
            }
            //console.log(response.data.data)
        } catch (error) {
            //console.log("error approve work order : ", error?.response?.status)
            setModalConfirmShow(false)
        }
        setModalConfirmShow(false)
        setComment("")
    }

     const numberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const handleSearchCondition = (value) =>{
        // console.log("handleSearchCondition value : ", value)
        if (value === ""){
            setValue("field", "")
            setSearchConditionSelected("")
            setSearchPlaceHolder("ยูสเซอร์ไอดี/ชื่อ/นามสกุล/เบอร์โทรศัพท์/เลขที่บัญชี/หมายเลขอ้างอิงธนาคาร")
            reset(register)
        }else{
            setSearchConditionSelected(value)
            const labelFilter = searchConditions.find(e=>
                value === e.value
            )

            // console.log("handleSearchCondition labels : ", labelFilter.label)
            setSearchPlaceHolder(`กรุณาค้นหาข้อมูลด้วย ${labelFilter.label}`)
        }
        typeAheadRef.current.clear()
    }

    return (
        <>
            {/* <div>
                <WorkOrder workOrderTitleHeader="ค้นหาใบถอน" workOrderTitleBody="พิมพ์เพื่อค้นหาใบงานถอน" />
            </div> */}
            <ModalConfirmWorkOrder openModal={confirmModal} data={confirmModalData} onConfirm={confirmSubmit} onClose={() => setConfirmModal(false)} workOrderData={confirmRequestData} />
            <Modal
                {...modalData}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={modalConfirmShow}
            >
                <Modal.Header className={modalData.headerclassName} closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {modalData.header}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>{modalData.bodyTitle}</Form.Label>
                            <Form.Control type="text" placeholder={modalData.placeholder} onChange={(e) => setComment(e.target.value)} />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button className={modalData.headerclassName} onClick={() => approveWorkOrderByType({ api_type: modalData.api_type, id: modalData.id, comment: comment })}>ยืนยัน</Button>
                    <Button className="secondary" onClick={() => setModalConfirmShow(false)}>Close</Button>
                </Modal.Footer>
            </Modal>
            <div className="col-md-12 pt-4 pr-4 pl-4">
                <Accordion eventKey="0">
                    <Card className="card-warning">
                        <Card.Header >
                            เพิ่มใบงานถอนเติมมือ (Manual)
                            <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                    <Button variant={"outline-dark"} style={{borderRadius: "50px"}} size={"sm"}><i className={`fas fa-plus`}/></Button>
                            </Accordion.Toggle>

                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                <Form noValidate validated={validated} onSubmit={handleSubmit(onSubmit)}>
                                    <Form.Row>
                                        <Form.Group as={Col} md="6" lg="6" controlId="validation001">
                                            <Form.Label>ค้นหาลูกค้า</Form.Label>
                                            <Row>
                                                <Col md="4" lg="4">
                                                    <Form.Control
                                                        ref={register}
                                                        as={"select"}
                                                        name={"field"}
                                                        custom
                                                        onChange={(e)=>handleSearchCondition(e.currentTarget.value)}
                                                        selected={searchConditionSelected}
                                                    >
                                                        <option value="">เลือกเงื่อนไขการค้นหา</option>
                                                        {
                                                            searchConditions?.map(({ label, value }, index) =>
                                                                <option
                                                                    key={value}
                                                                    value={value}
                                                                >
                                                                    {label}
                                                                </option>
                                                            )
                                                        }
                                                    </Form.Control>
                                                </Col>
                                                <Col md="8" lg="8">
                                                    <AsyncTypeahead size="md"
                                                        clearButton
                                                        delay={1500}
                                                        className={"inline"}
                                                        id="search_customer"
                                                        isLoading={isLoading}
                                                        // labelKey={(option) => `${option.user_id} ${option.first_name} ${option.last_name} ${option.mobile_no} ${JSON.stringify(option.bank_accounts)}`}
                                                        labelKey={
                                                            (option) => `${option.user_id} ${option.first_name} ${option.last_name} ${option.mobile_no} ${JSON.stringify(option.bank_accounts.map((e)=>{
                                                                return { เลขที่บัญชี: e.bank_account_no, value: e.Value }
                                                            }))}`
                                                        }
                                                        minLength={5}
                                                        onSearch={handleSearchDataFromUserID}
                                                        options={customerSearchList}
                                                        placeholder={searchPlaceholder}
                                                        searchText="กำลังค้นหาลูกค้า"
                                                        disabled={searchConditionSelected === ""}
                                                        onChange={handleSetDataFromUserID}
                                                        ref={typeAheadRef}
                                                                    renderMenuItemChildren={(option) => (
                                                                        <div className={"flex hover:bg-blue-200"}>
                                                                            <div style={{"width":"55%"}}>
                                                                                <p>
                                                                                    <b className={"text-primary"}>{option.user_id}</b>
                                                                                </p>
                                                                                <p>
                                                                                    <b>{option.first_name} - {option.last_name}</b>
                                                                                    {' | '}
                                                                                    <b className={"text-success"}>{option.mobile_no}</b>
                                                                                </p>
                                                                            </div>
                                                                            <div className={"flex-row"}>
                                                                                {
                                                                                    option.bank_accounts.map((bank) => {
                                                                                        return(
                                                                                            <p>
                                                                                                <LogoWithAccountNoSimple
                                                                                                    bankShortName={ bank.bank_short_name }
                                                                                                    bankFullName={bank.bank_account_name}
                                                                                                    bankName={ bank.bank_account_name }
                                                                                                    accountNo={ bank.bank_account_no }
                                                                                                />
                                                                                            </p>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                    />
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} md="2" lg="2" controlId="validation01">
                                            <Form.Label>UserID</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                name="user_id"
                                                placeholder="ufyuxxxxx/ahagxxxx"
                                                isInvalid={!!errors.first_name}
                                                ref={register({ required: true })}
                                                readOnly={true}
                                            />

                                            <Form.Control.Feedback type="invalid" tooltip>
                                                กรุณาระบุ ชื่อ
                                                    </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="2" lg="2" controlId="validation02">
                                            <Form.Label>ชื่อ</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                name="first_name"
                                                placeholder="ชื่อ"
                                                isInvalid={!!errors.first_name}
                                                ref={register({ required: true })}
                                                readOnly={true}
                                            />

                                            <Form.Control.Feedback type="invalid" tooltip>
                                                กรุณาระบุ ชื่อ
                                                    </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="2" lg="2" controlId="validation03">
                                            <Form.Label>นามสกุล</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                name="last_name"
                                                placeholder="นามสกุล"
                                                isInvalid={!!errors.last_name}
                                                ref={register({ required: true })}
                                                readOnly={true}
                                            />

                                            <Form.Control.Feedback type="invalid" tooltip>
                                                กรุณาระบุนามสกุล
                                                    </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="2" lg="2" controlId="validation04">
                                            <Form.Label>บัญชีธนาคารลูกค้า</Form.Label>
                                            <Form.Control
                                                required
                                                as="select"
                                                custom
                                                name="from_bank_name"
                                                placeholder="ufyuxxxx/ahagxxxx"
                                                isInvalid={!!errors.from_bank_name}
                                                ref={register({ required: true })}
                                                onChange={(e) => handleSetBankData(e.target.value)}
                                            >
                                                <option value="">Please Select</option>
                                                {
                                                    customerBankOptions.map(({ bank_short_name, bank_account_name, bank_account_no, selected }, index) =>
                                                        <option
                                                            key={index}
                                                            value={bank_account_no}
                                                            selected={selected}
                                                        // onChange={handleSetDataFromUserID({ user_id, first_name, last_name, mobile_no, bank_accounts })}
                                                        >
                                                            {bank_account_name}
                                                        </option>
                                                    )
                                                }
                                            </Form.Control>

                                            <Form.Control.Feedback type="invalid" tooltip>
                                                กรุณาระบุบัญชีธนาคารลูกค้า
                                                    </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="2" lg="2" controlId="validation05">
                                            <Form.Label>เลขที่บัญชีธนาคารลูกค้า</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                name="from_bank_account_no"
                                                placeholder="-"
                                                isInvalid={!!errors.from_bank_account_no}
                                                ref={register({ required: true })}
                                                readOnly={true}
                                            />

                                            <Form.Control.Feedback type="invalid" tooltip>
                                                กรุณาระบุเลขที่บัญชีธนาคารลูกค้า
                                                    </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} md="2" lg="2" controlId="validation06">
                                            <Form.Label>เลือกธนาคารถอน</Form.Label>
                                            <InputGroup>
                                                <Form.Control
                                                    as="select"
                                                    name="to_bank_name"
                                                    custom
                                                    required
                                                    isInvalid={!!errors.to_bank_name}
                                                    ref={register({ required: true })}
                                                    onChange={(e) => handleSetWithDrawBankData(e.target.value)}
                                                >
                                                    <option value="">Please Select</option>
                                                    {
                                                        withDrawBank?.map(({ bank_name, bank_account_no }, index) =>
                                                            <option
                                                                key={bank_account_no}
                                                                value={bank_account_no}
                                                            >
                                                                {bank_name}
                                                            </option>
                                                        )
                                                    }
                                                </Form.Control>
                                                <Form.Control.Feedback type="invalid" tooltip>
                                                    กรุณาระบุธนาคารถอน
                                                    </Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>
                                        <Form.Group as={Col} md="2" lg="2" controlId="validation07">
                                            <Form.Label>เลขที่บัญชีธนาคารถอน</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                name="to_bank_account_no"
                                                placeholder="-"
                                                isInvalid={!!errors.to_bank_account_no}
                                                ref={register({ required: true })}
                                                disabled
                                            />

                                            <Form.Control.Feedback type="invalid" tooltip>
                                                กรุณาระบุเลขที่บัญชีธนาคารถอน
                                                    </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="2" lg="2">
                                            <Form.Label className={"text-danger"}>จำนวนเงินคงเหลือของบัญชีถอน</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="balance"
                                                placeholder="-"
                                                readOnly={true}
                                                ref={register}
                                                className={"text-danger"}
                                            />

                                            <Form.Control.Feedback type="invalid" tooltip>
                                                กรุณาระบุ จำนวนเงิน
                                                    </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="2" lg="2" controlId="validation08">
                                            <Form.Label>จำนวนเงิน</Form.Label>
                                            <Form.Control
                                                required
                                                type="number"
                                                step={1}
                                                name="amount"
                                                placeholder="100/200.50"
                                                isInvalid={!!errors.amount}
                                                ref={register({ required: true })}
                                            />

                                            <Form.Control.Feedback type="invalid" tooltip>
                                                กรุณาระบุ จำนวนเงิน
                                                    </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="4" lg="4" controlId="validation09">
                                            <Form.Label>หมายเหตุ</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="comment"
                                                placeholder="อ่าน statement ไม่เจอ/ธนาคารมีปัญหา"
                                                isInvalid={!!errors.comment}
                                                ref={register}
                                            />

                                            <Form.Control.Feedback type="invalid" tooltip>
                                                กรุณาระบุ จำนวนเงิน
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    <Button variant="danger" type="submit" onClick={()=>setHandManual(false)}>เพิ่มใบงานถอนเติมมือ</Button>{' '}
                                    <Button variant="primary" type="submit" onClick={()=>setHandManual(true)}>เพิ่มใบงานถอนมือแล้ว</Button>
                                </Form>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            </div>
            {
                (dashboardWarningDetails.length > 0) &&
                <div className="col-md-12 pt-4 pr-4 pl-4">
                    <Accordion defaultActiveKey="0">
                        <Card className="card-danger">
                            <Accordion.Toggle as={Card.Header} eventKey="0" className="p-4">
                                <b>แจ้งเตือน</b>{' '}
                                <Button variant={"outline-light"} style={{borderRadius: "50px"}} size={"sm"}><i className={`fas fa-exclamation-circle`}/></Button>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                <Row lg={6}>
                                    {dashboardWarningDetails.map((msg, index) => {
                                        return (<Col lg={6}>
                                                    <InfoBoxBGStatsCard
                                                    icon={"fas fa-people-arrows"}
                                                    value={msg.label}
                                                    label={msg.value}
                                                    type={"bg-danger"}
                                                    />
                                        </Col>)
                                    })}
                                </Row>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                </div>
            }

            <div>
                <ShowBankListComponent BankList={withDrawBank} reloadBankList={getWithdrawBankList}/>
                <WorkOrderDetails data={workOrderDetailsData} ConfirmActionModal={null}
                                  enableModalByMode={enableModalByMode} modalConfirmShow={modalConfirmShow}
                                  setModalConfirmShow={setModalConfirmShow} selectFromBank={withDrawBank}/>
            </div>
        </>
    )
}
