import React, {useEffect, useMemo, useState} from 'react'
import { Badge, Button, Card, Fade, OverlayTrigger, Spinner, Table, Tooltip, Form } from 'react-bootstrap'
import api from "../../api/managebank";
import ModalRegisterBanks from './ModalRegisterBanks'
import moment from 'moment'
import { LogoWithAccountNo } from '../banks_logo/index'
import GlobalTable from "../manage_customer/GlobalTable";
import {number_format} from "../utils/Number";


const ModalRegisterBankComponent = (props) =>{

  return(
      <ModalRegisterBanks
          action={ props.action }
          modalHeader={ props.modalHeader }
          buttonSubmit={ props.buttonSubmit }
          officialBank={props.officialBank}
          data={ props.data }
          show={props.show}

          onClose={props.onClose}
          afterCloseModal={() => props.afterCloseModal()}
      />
  )
}

const getThaiBankType = (bankType, isLure) => {
  switch (bankType) {
    case "deposit":
      return isLure ? "ฝากล่อ" : "ฝาก";
    case "withdraw":
      return isLure ? "ถอนล่อ" : "ถอน";
    case "holding":
      return "พัก";
    case "sweepout":
      return "โยกออก";
    case "vvip":
      return "VVIP";
    default:
      return "";
  }
};

const numberWithCommas = (x) => {
  if (x <= 0 || x === undefined) {
    return "N/A"
  }

  // let parts = x.toString().split(".");
  // parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  // return parts.join(".");

  return number_format(x,2)
}

const colorTable = (bank_type, enable, lock, obsolete) => {
  if (enable === false) {
    if (lock) {
      return "bg-orange-600"
    }

    if (obsolete) {
      return "bg-orange-600 strikethrough"
    }

    return "table-secondary"
  }

  if (bank_type === "deposit") {
    return "table-success"
  }
  if (bank_type === "withdraw") {
    return "table-danger"
  }
  if (bank_type === "holding") {
    return "table-warning"
  }
}


export default function BankCardComponent(props) {
  //console.log("depositBankList", props);
  const { cardInfo, cardHeaderColor, afterClose, fetch, disableFetch, officialBank, onClose } = props;

  const [showBankModal, setShowBankModal] = useState(false)

  const toggleShowBankModal = (show) =>{
    setShowBankModal(show)
  }

  const [ updateModalPropsInfo, setUpdateModalPropsInfo ] = useState({
    action: "",
    modalHeader: "",
    buttonSubmit: "",
    data: {},
    afterCloseModal:afterClose,
    officialBank: officialBank,
    onClose: ()=>
      toggleShowBankModal(false)
  })

  // const [ updateModal, setUpdateModal ] = useState(false)
  const [ bankInfo, setBankInfo ] = useState([])

  const openCreateModal = (props) => {
    // console.log("openCreateModal props", props.data);
    const createBankInfo = {
      action: "create",
      modalHeader: `เพิ่มข้อมูลธนาคาร`,
      buttonSubmit: "เพิ่ม",
      data: { ...props } ,
      afterCloseModal: afterClose,
      officialBank: officialBank,
      onClose: ()=>toggleShowBankModal(false),
    }
    setUpdateModalPropsInfo(createBankInfo)
    setShowBankModal(true)
  }

  const openUpdateModal = (props) => {
    // console.log("openUpdateModal props: ", props);
    const editBankInfo =
        {
          action: "update",
          modalHeader: `แก้ไขข้อมูลธนาคาร ${ props.bank_name }`,
          buttonSubmit: "แก้ไขข้อมูล",
          data: { ...props },
          show: true,
          afterCloseModal:afterClose,
          officialBank: officialBank,
          onClose: ()=>toggleShowBankModal(false),
        }
    setUpdateModalPropsInfo(editBankInfo)
    setShowBankModal(true)
  }


  useEffect(() => {
    setBankInfo(cardInfo.cardBody)
  }, [ cardInfo ])


  return (
      <>
        {/*<ModalRegisterBanks*/}
        {/*  action={ updateModalPropsInfo.action }*/}
        {/*  modalHeader={ updateModalPropsInfo.modalHeader }*/}
        {/*  buttonSubmit={ updateModalPropsInfo.buttonSubmit }*/}
        {/*  data={ updateModalPropsInfo.data }*/}
        {/*  show={ updateModal }*/}
        {/*  openUpdateModal={ openUpdateModal }*/}
        {/*  closeUpdateModal={ closeUpdateModal }*/}
        {/*  afterClosePopup={ afterClose }/>*/}

        <ModalRegisterBankComponent {...updateModalPropsInfo} show={showBankModal}/>
        <Card className={ `${ cardHeaderColor }` }>
          <Card.Header>{ cardInfo.cardHeader } </Card.Header>
          <Card.Body>
            <Table striped bordered hover className="text-center">
              <thead>
              <tr>
                <th className={"text-sm"}>#</th>
                <th className={"text-sm"}>ชื่อธนาคาร</th>
                <th className={"text-sm"}>เลขที่บัญชี</th>
                <th className={"text-sm"}>ประเภทธนาคาร</th>
                <th className={"text-sm"}>เปิดใช้งาน</th>
                <th className={"text-sm"}>แสดงลูกค้า</th>
                <th className={"text-sm"}>จำนวนรายการฝาก/เดือน<p/>{' '}
                  <Button onClick={ fetch } variant={ "outline-primary" } size={ "sm" }
                          disabled={ disableFetch }
                          style={ { borderRadius: '50px' } }>
                    {
                      disableFetch ? <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                      /> : <i className="fas fa-sync-alt"/>
                    }
                  </Button></th>
                <th className={"text-sm"}>จำนวนรายการถอน/เดือน<p/>{' '}
                  <Button onClick={ fetch } variant={ "outline-danger" } size={ "sm" }
                          disabled={ disableFetch }
                          style={ { borderRadius: '50px' } }>
                    {
                      disableFetch ? <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                      /> : <i className="fas fa-sync-alt"/>
                    }
                  </Button>
                </th>
                <th className={"text-sm"}>ยอดเงินเดินประจำวัน<p/>{' '}
                  <Button onClick={ fetch } variant={ "outline-success" } size={ "sm" }
                          disabled={ disableFetch }
                          style={ { borderRadius: '50px' } }>
                    {
                      disableFetch ? <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                      /> : <i className="fas fa-sync-alt"/>
                    }
                  </Button>
                </th>
                <th className={"text-sm"}>
                  ยอดเงินคงเหลือล่าสุด
                </th>
                <th className={"text-sm"}>ยอดเงินเข้าประจำวัน</th>
                <th className={"text-sm"}>ยอดเงินออกประจำวัน</th>
                <th className={"text-sm"}>อัพเดทล่าสุด</th>
                <th className={"text-sm"}>อัพเดทโดย</th>
              </tr>
              </thead>
              {/*<Fade in={ true } appear={ true }>*/}
              <tbody>
              {
                cardInfo.cardBody.map(({
                                         id, bank_name,
                                         bank_account_no,
                                         bank_account_name,
                                         bank_type,
                                         bank_short_name,
                                         username,
                                         password,
                                         comment,
                                         auto_threads,
                                         auto_port,
                                         created_at,
                                         created_by,
                                         updated_at,
                                         updated_by,
                                         auto_approve,
                                         is_lure,
                                         enable,
                                         show_customer,
                                         balance,
                                         deposit_tier,
                                         monthly_deposit_count,
                                         monthly_withdraw_count,
                                         total_sum,
                                         total_in,
                                         total_out,
                                         crawler_type,
                                         mobile_sms,
                                         pin,
                                         card_id,
                                         date_of_birth,
                                         login_with_qr,
                                         read_statement_at,
                                         device_id,
                                         api_refresh,
                                         lock,
                                         obsolete,
                                         agent_name,
                                         api_login_token,
                                         wallet_tmn_id,
                                         tmnone_key_id,
                                         kplus_data_raw,
                                         kplus_data,
                                         scb_bulk_transfer,
                                         user_ids
                                       }, index) =>

                    <tr key={ id } className={ colorTable(bank_type, enable, lock, obsolete) } style={{"cursor":"pointer"}} onClick={ () =>
                        // openUpdateModal({ id, bank_name, bank_account_no, bank_type, bank_short_name, created_at, created_by })}>
                        openUpdateModal(
                            {
                              id, bank_name,
                              bank_account_no,
                              bank_account_name,
                              bank_type,
                              bank_short_name,
                              username,
                              password,
                              comment,
                              auto_threads,
                              auto_port,
                              created_at,
                              created_by,
                              updated_at,
                              updated_by,
                              auto_approve,
                              is_lure,
                              enable,
                              deposit_tier,
                              show_customer,
                              crawler_type,
                              mobile_sms,
                              pin,
                              card_id,
                              date_of_birth,
                              login_with_qr,
                              device_id,
                              api_refresh,
                              lock,
                              obsolete,
                              agent_name,
                              api_login_token,
                              wallet_tmn_id,
                              tmnone_key_id,
                              kplus_data_raw,
                              kplus_data,
                              scb_bulk_transfer,
                              user_ids
                            }) }>
                      <td>{ index + 1 }</td>
                      <td>{ bank_name }</td>
                      <td>
                        <LogoWithAccountNo
                            bankShortName={ bank_short_name }
                            bankName={ bank_name }
                            accountNo={ bank_account_no }
                        /></td>
                      <td>{ getThaiBankType(bank_type, is_lure) }</td>
                      { enable === true
                          ? (<td>
                            <OverlayTrigger
                                placement='top'
                                overlay={
                                  read_statement_at ?
                                      <Tooltip id={ `tooltip-status` }>
                                        { moment(read_statement_at).format("DD/MM/YYYY HH:mm:ss") }
                                      </Tooltip> : <div>
                                      </div>
                                }
                            >
                              <Badge variant="success">เปิดใช้งาน</Badge>
                            </OverlayTrigger>{ ' ' }</td>)
                          : (<td><Badge variant="danger">ปิดใช้งาน</Badge>{ ' ' }</td>)
                      }
                      { show_customer === true
                          ? (<td><Badge variant="success">เปิดแจก</Badge>{ ' ' }</td>)
                          : (<td><Badge variant="danger">ปิดแจก</Badge>{ ' ' }</td>)
                      }
                      { enable === true
                          ? (<td className="text-success">
                            <b>{ numberWithCommas(monthly_deposit_count) }</b></td>)
                          : (<td>{ numberWithCommas(monthly_deposit_count) }</td>)
                      }
                      { enable === true
                          ? (<td className="text-danger">
                            <b>{ numberWithCommas(monthly_withdraw_count) }</b></td>)
                          : (<td>{ numberWithCommas(monthly_withdraw_count) }</td>)
                      }
                      { enable === true
                          ? (<td className="text-danger"><b>{ numberWithCommas(total_sum) }</b>
                          </td>)
                          : (<td>{ numberWithCommas(total_sum) }</td>)
                      }
                      { enable === true
                          ? (<td className="text-primary"><b>{ numberWithCommas(balance) }</b></td>)
                          : (<td>{ numberWithCommas(balance) }</td>)
                      }
                      { enable === true
                          ? (<td className="text-success"><b>{ numberWithCommas(total_in) }</b></td>)
                          : (<td>{ numberWithCommas(balance) }</td>)
                      }
                      { enable === true
                          ? (<td className="text-danger"><b>{ numberWithCommas(total_out) }</b></td>)
                          : (<td>{ numberWithCommas(balance) }</td>)
                      }

                      {/*<td>{moment(created_at).format('DD/MM/YYYY')}</td>*/ }
                      {/*<td>{created_by}</td>*/ }
                      <td>{ moment(updated_at).format('DD/MM/YYYY HH:mm') }</td>
                      <td>{ updated_by }</td>
                    </tr>
                )
              }
              </tbody>
              {/*</Fade>*/}
            </Table>
          </Card.Body>
          <Card.Footer>
            { cardInfo.cardFooter ? cardInfo.cardFooter : "" }
            <Button onClick={openCreateModal}>เพิ่มบัญชี</Button>
            {/* <Button onClick={() => onError({ alertHeading: "TEST1", content: "TEST2" })}>TestError</Button>
          <Button onClick={closeUpdateModal}>Close</Button> */ }
          </Card.Footer>
        </Card>
      </>
  )
}
