import React, { useState, useEffect } from 'react'
import {
  Button,
  Col,
  Form,
  Modal,
  Table,
  Alert,
  Badge,
  OverlayTrigger,
  Tooltip,
  Spinner,
  FormControl, FormLabel
} from 'react-bootstrap';
import api_workorder from "../../api/workorder";
import moment from "moment";
import {LogoWithAccountNo} from "../banks_logo";
import {useForm} from "react-hook-form";

export function ModalConfirmForceMappingSuccess(props){
  const {showModal, onClose, onConfirm} = props
  const [validated, setValidated] = useState(false);
  const [show, setShow] = useState(false);


  const { register, handleSubmit, errors, setError} = useForm({
    defaultValues: {
      force_mapping:""
    },
    criteriaMode: "all"
  });

  const handleForceMappingAccept = (data) =>{
    console.log("data: ", data)
    if (data.force_mapping !== "ยืนยัน"){
      setError("force_mapping", {
        type: "manual",
        message: "Dont Forget Your Username Should Be Cool!",
      });
      return
    }

    onConfirm("success")
    // onClose()
  }

  return (
        <Modal show={showModal} onHide={onClose} size={"lg"}>
          <Form noValidate validated={validated} onSubmit={handleSubmit(handleForceMappingAccept)}>
          <Modal.Header>
            <Modal.Title>**กรุณายืนยันการเพิ่มใบงานฝากแบบไม่จับคู่</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Row>
            <h1><b style={{color:'red'}}>การเพิ่มใบงานนี้ใช้ในกรณีที่ทางระบบไม่สามารถอ่านรายการย้อนหลังได้ ซึ่งหลังจากเพิ่มไปในระบบแล้ว ระบบจะมีการติดตามตรวจสอบภายหลัง
            </b>
            </h1>
            <br/>
            <br/>

              <Form.Group as={Col} md="12" lg="12">
                <Form.Label> กรุณาพิมพ์ "ยืนยัน" และกดตกลงเพื่อทำรายการ</Form.Label>
                <FormControl
                    required
                    type="text"
                    name={"force_mapping"}
                    placeholder="ยืนยัน"
                    isInvalid={errors.force_mapping}
                    ref={register({ required: true })}
                />
                <Form.Control.Feedback type="invalid" tooltip>
                  กรุณายืนยันการทำรายการ
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" type={"submit"}>
              ตกลง
            </Button>
            <Button variant="secondary" onClick={onClose}>
              ยกเลิก
            </Button>
          </Modal.Footer>
          </Form>
        </Modal>
  )
}

export default function ModalConfirmWorkOrder(props) {
  const { data, onConfirm, onClose, openModal, workOrderData } = props
  const [manualDataList, setManualDataList] = useState([])
  const [manualApiError, setManualApiError] = useState(false)

  const renderSwitch = (props) => {
    const {credit_status, credit_reason} = props
    switch (credit_status) {
      case "success":
        return <td className={ "text-md-center" }><h5><Badge variant="success" >{"สำเร็จ"}</Badge> </h5></td>;
      case "failed":
        return <td className={ "text-md-center" }>
          <h5>
            <OverlayTrigger
                placement='top'
                overlay={
                  credit_reason ?
                      <Tooltip id={`tooltip-status`}>
                        {credit_reason}
                      </Tooltip> : <div>
                      </div>
                }
            >
              <Badge variant="danger">{"ไม่สำเร็จ"}</Badge>
            </OverlayTrigger>
          </h5>
        </td>
      case "deleted":
        return <td className={ "text-md-center" }><h5><Badge variant="warning">{"ยกเลิก"}</Badge> </h5></td>;
      default:
        return <td className={ "text-md-center" }><Spinner animation="grow" variant="info"/></td>;
    }
  }

  const confirmButton = (workOrderData) => {
    onConfirm(workOrderData);
    onClose();
  }

  const checkManualTransactions = async ()=>{
    setManualApiError(false)
    const request = {
      "work_order_type": "deposit",
      "channel": "manual",
      "mapping_status": "failed",
      "customer_id": "",
      "user_id":workOrderData.user_id,
      "limit": 10
    }
    await api_workorder.WORK_ORDER_SEARCH_TYPE(request)
        .then(response =>{
            console.log("response from api : ", response.data.data)
          setManualDataList(response.data.data)
        })
        .catch(error =>{
          setManualApiError(true)
          //console.log("reset password error :", error)
        })
  }

  useEffect(()=>{
    //console.log("workOrderData :", workOrderData)
    if (workOrderData.work_order_type === "deposit"){
      checkManualTransactions()
    }
  },[openModal])



  return (
    <div>
      <Modal show={openModal} onHide={onClose} size={"lg"}>
        <Modal.Header className="danger">
          <Modal.Title>ยืนยันเพิ่มใบงาน{data.work_order_label_type} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/*ยืนยันการเพิ่มใบงานของ {data.work_order_label_type}*/}
          {/*<br />*/}
          {/*UserID : {data.user_id || "-"}*/}
          {/*<br />*/}
          {/*ชื่อ - นามสกุล : {data.first_name || "-"}   {data.last_name || "-"}*/}
          {/*<br />*/}
          {/*จำนวน : {data.amount || "-"}*/}
          <Alert show={manualApiError} variant="danger">
            ไม่สามารถค้นหารายการ manual ได้ กรุณาติดต่อ Admin
          </Alert>
          <Form>
            <Form.Group>
              <Form.Row>
                <Col>ประเภทใบงาน : <b>{data.work_order_label_type}</b></Col>
                <Col>UserID : <b>{data.user_id || "-"}</b></Col>
              </Form.Row>
              <Form.Row>
                <Col>ชื่อ - นามสกุล : <b>{data.first_name || "-"} {data.last_name || "-"}</b></Col>
                <Col>จำนวน : <b>{data.amount || "-"} บาท</b></Col>
              </Form.Row>
            </Form.Group>
            {
              (workOrderData.work_order_type === "deposit")? (
              <Form.Group>
              <Form.Label>รายการลงมือล่าสุด (ที่ยังไม่ได้จับคู่)</Form.Label>
              <Form.Row>
              <Table striped bordered hover responsive={ true }>
              <thead>
                <tr>
                  <th className={ "text-md-center" }>วันที่/เวลา</th>
                  <th className={ "text-md-center" }>บัญชีโอน</th>
                  <th className={ "text-md-center" }>บัญชีรับ</th>
                  <th className={ "text-md-center" }>จำนวนเงิน</th>
                  <th className={ "text-md-center" }>สถานะ Credit</th>
                  <th className={ "text-md-center" }>หมายเหตุ</th>
                </tr>
              </thead>
              <tbody>
            {
              manualDataList?.map((
            {
              id,
              created_at,
              from_bank_short_name,
              from_bank_name,
              from_bank_account_no,
              work_order_type,
              work_order_sub_type,
              to_bank_short_name,
              to_bank_name,
              to_bank_code,
              to_bank_account_no,
              amount,
              credit_status,
              comment
            }, index) =>

              <tr key={id} >
            <td className={ "text-md-center" }>{moment(created_at).format("DD/MM/YYYY HH:mm:ss") }</td>
              <td>
                <LogoWithAccountNo
                bankShortName={ from_bank_short_name }
                bankName={ from_bank_name }
                accountNo={ from_bank_account_no }
                workOrderType={ work_order_type }
                workOrderSubType={ work_order_sub_type }
                column={ "1" }
                />
              </td>
              <td>
                <LogoWithAccountNo
                bankShortName={ to_bank_short_name }
                bankName={ to_bank_name }
                accountNo={ to_bank_account_no }
                workOrderType={ work_order_type }
                workOrderSubType={ work_order_sub_type }
                column={"1"}
                />
              </td>
              <td className={ "text-md-center" }>{amount} บาท</td>
            {renderSwitch({credit_status:credit_status, credit_reason: null})}
              <td className={ "text-md-center" }>{comment}</td>
              </tr>
              )
            }
              </tbody>
              </Table>
              </Form.Row>
              </Form.Group>
              ):<></>
            }
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={() => confirmButton(workOrderData)}>
            ยืนยัน
          </Button>
          <Button variant="danger" onClick={() => onClose()}>
            ยกเลิก
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
