import React, { useState } from 'react'
import { Button, Col, Form, Modal, Spinner } from 'react-bootstrap'
import api_workorder from "../../api/workorder"
import { useGlobalStore } from '../../store/globalContext'

export default function ConfirmButtonMapping ( props ) {
  const [ { subscribe, dashboard_work_order_deposit, customers, status, login_profile }, dispatch ] = useGlobalStore();
  const { data, isShow, onClose, callBack } = props
  const [ show, setShow ] = useState( isShow );
  const { id, user_id, first_name, last_name, amount, variant, button_name, mapping_id } = data
  const [ isLoading, setLoading ] = useState( false );

  const buttonMapping = async() => {
    setLoading( true )

    const request = {
      id: id,
      mapping_id: mapping_id,
    }
    try {
      //console.log("keyword: ", keyword)
      const response = await api_workorder.WORK_ORDER_MANUAL_MAPPING(request)

      //console.log("response data :", response.data)

      if (response.status !== 200) {
        //console.log(`retry work order failed`, response.message);
        return
      }
      dispatch({
        type: "TOAST2",
        value: {
          show: true,
          type: 'success',
          title: `Mapping ใบงาน`,
          message: `Mapping ใบงาน สำเร็จ`,
        }
      });
      callBack();
      setLoading( false );
    } catch (error) {
      //console.log("error call deposit approve :", error)
      dispatch({
        type: "TOAST2",
        value: {
          show: true,
          type: 'danger',
          title: `Mapping ใบงาน`,
          message: `Mapping ใบงาน ไม่สำเร็จ กรุณาตรวจสอบ`,
        }
      });
      callBack();
      setLoading( false );
    }

    callBack();
    setLoading( false );
  }

  return (
    <div>
      <Modal show={isShow} onHide={onClose} size="lg" centered={true}>
        <Modal.Header closeButton>
          <Modal.Title>ยืนยัน{button_name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Row>
              <Form.Group as={Col} md="3" lg="3">
                <Form.Label>UserID</Form.Label>
                <Form.Control type="text" value={user_id} readOnly={true}/>
              </Form.Group>
              <Form.Group as={Col} md="3" lg="3">
                <Form.Label>ชื่อ</Form.Label>
                <Form.Control type="text" value={first_name} readOnly={true}/>
              </Form.Group>
              <Form.Group as={Col} md="3" lg="3">
                <Form.Label>นามสกุล</Form.Label>
                <Form.Control type="text" value={last_name} readOnly={true}/>
              </Form.Group>
              <Form.Group as={Col} md="3" lg="3">
                <Form.Label>จำนวนเงิน</Form.Label>
                <Form.Control type="text" value={amount} readOnly={true}/>
              </Form.Group>
            </Form.Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant={variant}
            disabled={isLoading}
            onClick={buttonMapping}
          >
            {isLoading ?
              (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  /> กำลังดำเนินการ</> ) : `ยืนยัน${button_name}`}
          </Button>
          <Button variant="secondary" onClick={onClose}>
            ยกเลิก
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
