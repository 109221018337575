import React, { useState, useEffect } from 'react'
import WorkOrder from './WorkOrder'
import { useGlobalStore } from '../../store/globalContext'
import moment from 'moment'
import api_workorder from "../../api/workorder";

export default function DepositSummaryWorkOrder() {
  const [ { dashboard_work_order_deposit }, dispatch ] = useGlobalStore();
  const [ workOrderOverall, setWorkOrderOverall ] = useState([])

  useEffect(() => {
    try {
      dispatch({
        type: "SOCKET_SEND",
        value: { method: "subscribe", topic: "dashboard_work_order_deposit" }
      });
      //console.log("dispatch from deposit summary");
    } catch (error) {
      console.log("error :", error);
      dispatch({
        type: "TOAST",
        value: {
          show: true,
          type: 'danger',
          title: `ระบบมีปัญหา`,
          message: `ขออภัยระบบมีปัญหา กรุณาติดต่อ admin`,
        }
      });
    }
  }, [])

//   const getWorkOrderListByType = async () => {
//     const keyword = { date: moment().format("YYYY-MM-DD"), work_order_type: "deposit", work_order_sub_type: "" }
//     // console.log("getWorkOrderListByType keyword: ", keyword)
//     const response = await api_workorder.WORK_ORDER_SEARCH_TYPE(keyword)
//
//     if (response.status !== 200) {
//         console.log(`get workorder deposit failed`, response.message);
//         return
//     }
//     //console.log("getWorkOrderListByType response.data: ", response.data)
//     if (response.data.data === null || response.data.data === undefined) {
//         return
//     }
//
//     const initData = response.data.data
//     let initDataList = []
//     //console.log("getWorkOrderListByType initData: ", initData)
//     initData.map(({ id, created_at, user_id, first_name, last_name, from_bank_name, from_bank_account_no, to_bank_name, to_bank_account_no, amount, status, updated_at, comment, before_balance, after_balance }, index) => {
//         let newData = {
//             id: id,
//             created_at: created_at,
//             user_id: user_id,
//             first_name: first_name,
//             last_name: last_name,
//             from_bank_name: from_bank_name,
//             from_bank_account_no: from_bank_account_no,
//             to_bank_name: to_bank_name,
//             to_bank_account_no: to_bank_account_no,
//             amount: amount,
//             status: status,
//             updated_at: updated_at,
//             comment: comment,
//             before_balance: before_balance,
//             after_balance: after_balance
//         }
//         initDataList.push(newData)
//     })
//
//     setWorkOrderOverall(initData)
// }
//
// useEffect(() => {
//     getWorkOrderListByType();
// }, [])


  return (
    <div className="pt-4 pr-4 pl-4">
      <WorkOrder workOrderTitleHeader="ค้นหาใบงานฝาก" workOrderTitleBody="พิมพ์เพื่อค้นหาใบงานฝาก"
                 workOrderType="deposit" showSelectedBank={ true }/>
    </div>
  )
}
