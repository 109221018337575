import React, { useState } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { useHistory } from "react-router-dom";
import localStorage from "../../store/localStorage"
import  {setAxiosHeaderAuthorization} from '../../client/axios'
import {useGlobalStore} from "../../store/globalContext";

export default function LogOutPage() {
  const [{login_profile }, dispatch] = useGlobalStore();
  const history = useHistory();
  const [show, setShow] = useState(true)

  const closeButton = () => {
    setShow(false);
    history.push("/registeruser");
  }

  const confirmLogoutButton = () =>{
    localStorage.removeAllAccess();
    setAxiosHeaderAuthorization(null);
    dispatch({
      type: "SOCKET_DISCONNECT",
      value: {method: "subscribe", topic: null}
    });

    setShow(false);
    history.push("/login");
  }

  return (
    <div>
      <Modal show={show}>
        <Modal.Header>
          <Modal.Title>ออกจากระบบ</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p><h3>ยืนยันออกจากระบบ?</h3></p>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={confirmLogoutButton}>ใช่</Button>
          <Button variant="secondary" onClick={closeButton}>ปิด</Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
