import React, {useEffect, useState} from 'react';
import {Button, Modal, Spinner} from "react-bootstrap";
import moment from "moment";
import api_bank from "../../api/managebank";
import {number_format} from "../utils/Number";

export default function ModalStatementDetail(props) {
    const {data, isOpenModal, onClose} = props;
    const {
        bank_account_no,
        transactionUxDate,
        sourceSystemId,
        transactionNumber,
        rqUid,
        amount,
        transactionType,
        transactionDescription,
    } = data;

    const [statementDetail, setStatementDetail] = useState({});
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);

    const searchStatementDetail = async () => {
        const request = {
            bank_account_no: bank_account_no,
            transaction_ux_date: transactionUxDate,
            source_system_id: sourceSystemId,
            transaction_number: transactionNumber.toString(),
            request_uid: rqUid,
        };
        setLoading(true);

        try {
            const response = await api_bank.BANK_STATEMENTS_DETAILS(request);
            const {data} = response.data;
            setStatementDetail(data);
        } catch (e) {
            console.log(e);
            setError(true);
        } finally {
            setLoading(false);
        }
    };

    const handleClose = () => {
        setError(false);
        onClose();
    }

    useEffect(() => {
        if (rqUid) {
            searchStatementDetail();
        }
    }, [rqUid]);

    return (<>
        <Modal show={isOpenModal} onHide={handleClose} size="xl">
            <Modal.Header closeButton>
                <Modal.Title className={"font-bold"}>รายละเอียดการโอน</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {error ? <p className={"text-red-700 font-bold"}>ไม่สามารถทำรายการเรียกดูข้อมูลได้
                    กรุณาลองเรียกดูใหม่ในภายหลัง</p> : loading ? <>
                    กำลังค้นหาข้อมูล
                    <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />
                </> : <>
                    <p><span className={"text-red-700 font-bold"}>เลขที่รายการ</span> - {rqUid}</p>
                    <p><span
                        className={"text-red-700 font-bold"}>วันเวลาที่ทำรายการ</span> - {moment.unix(transactionUxDate).format('DD/MM/YYYY HH:mm:ss')}
                    </p>
                    <p><span className={"text-red-700 font-bold"}>เลขที่บัญชี</span> - {bank_account_no}</p>
                    <p><span
                        className={"text-red-700 font-bold"}>จำนวนเงิน</span> - {number_format(amount, 2)} บาท
                    </p>
                    <p><span
                        className={"text-red-700 font-bold"}>หมายเหตุ</span> - {transactionDescription}
                    </p>
                    {transactionType === "DR" ? <>
                        <p><span
                            className={"text-red-700 font-bold"}>ชื่อบัญชีที่โอนไป</span> - {statementDetail.toAccountName}
                        </p>
                        <p><span
                            className={"text-red-700 font-bold"}>เลขที่บัญชีที่โอนไป</span> - {statementDetail.toAccountNo}
                        </p>
                        <p><span
                            className={"text-red-700 font-bold"}>ธนาคารที่โอนไป</span> - {statementDetail.toBankName}
                        </p>
                    </> : <>
                        <p><span
                            className={"text-red-700 font-bold"}>ชื่อบัญชีที่ฝากมา</span> - {statementDetail.fromAccountName}
                        </p>
                        <p><span
                            className={"text-red-700 font-bold"}>เลขที่บัญชีที่ฝากมา</span> - {statementDetail.fromAccountNo}
                        </p>
                        <p><span
                            className={"text-red-700 font-bold"}>ธนาคารที่ฝากมา</span> - {statementDetail.fromBankName}
                        </p>
                    </>}
                </>}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>ปิด</Button>
            </Modal.Footer>
        </Modal>
    </>)
}