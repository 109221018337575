import React, {useState} from 'react'
import {Alert, Button, Col, Container, Form, Image, InputGroup, Modal} from "react-bootstrap";
import {useForm} from "react-hook-form";
import api from "../../api/login";
import localStorage from "../../store/localStorage";
import {setAxiosHeaderAuthorization} from "../../client/axios";
import {useHistory} from "react-router-dom";
import {useGlobalStore} from "../../store/globalContext";

export default function Login2FactorWithQR(props) {
    const dispatch = useGlobalStore()[1];
    const {show, onHide, qrCode, username, loginData} = props
    const history  = useHistory();
    const { register, handleSubmit, errors} = useForm({
        defaultValues: { token: ""},
        criteriaMode: "all"
    });
    const [validated, setValidated] = useState(false);
    const [errorLogin, setErrorLogin] = useState({errorShow:false, errorMessage:""})


    const onSubmit = async (data) => {
        setValidated(true)
        //console.log(data)

        try {
            const response = await api.CONFIRM_QR_AUTH(data);

            if (response.status !== 200) {
                localStorage.removeAllAccess()
                setErrorLogin({errorShow:true, errorMessage:" OTP ไม่ถูกต้อง"});
                return
            }
            //console.log("loginData : ", loginData)
            localStorage.setAllAccess({...loginData});
            localStorage.setUsername(username)
            setAxiosHeaderAuthorization(loginData.token)
            dispatch({
                type: "login_profile",
                value: loginData
            });
            history.push("/registeruser");

        } catch (error) {
            console.log("login failed : ", error);
            setErrorLogin({errorShow:true, errorMessage:"ไม่สามารถเข้าสู่ระบบได้ กรุณาติดต่อ admin"});
        }
    }

    return(
        <Modal
            show={show}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Form noValidate validated={validated} onSubmit={handleSubmit(onSubmit)}>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        กรุณายืนยันตัวตน
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body className="show-grid">
                    <Container>
                        <Form.Row>
                            <Col>
                                <Alert variant={"danger"} show={errorLogin.errorShow}>
                                    {errorLogin.errorMessage}
                                </Alert>
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col>
                            <Image style={{marginLeft:'auto', marginRight:'auto'}} src={qrCode} height={250} width={250}/>
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} >
                                <InputGroup className="mb-1 col-12" size="lg">
                                    <InputGroup.Prepend>
                                        <InputGroup.Text id="inputGroup-sizing-default2"><i className="fas fa-user-shield mr-2" /></InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control
                                        as={"input"}
                                        required
                                        type={"text"}
                                        name="username"
                                        isInvalid={!!errors.username}
                                        ref={register({ required: true})}
                                        readOnly={true}
                                        value={username}
                                    />
                                    <Form.Control.Feedback type="invalid" tooltip>
                                        กรุณาระบุ Username
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                        <Form.Group as={Col} controlId="formLoginNoOTP2">
                            <InputGroup className="mb-1 col-12" size="lg">
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="inputGroup-sizing-default"><i className="fab fa-google mr-2" /></InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                    as={"input"}
                                    required
                                    type={"text"}
                                    name="token"
                                    placeholder="OTP"
                                    maxLength={6}
                                    isInvalid={!!errors.token}
                                    ref={register({ required: true})}
                                />
                                <Form.Control.Feedback type="invalid" tooltip>
                                    กรุณาระบุ OTP
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                        </Form.Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={"success"} type="submit" >ยืนยัน</Button>
                    <Button variant={"secondary"} onClick={() => onHide(false)}>ปิด</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )

}