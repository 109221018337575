import React, {useEffect, useState} from "react";
import {Button, Card, Col, Form, InputGroup, Spinner} from "react-bootstrap";
import {useFieldArray, useForm} from "react-hook-form";
import api_config from "../../api/config";
import {useGlobalStore} from "../../store/globalContext";

export default function ManagePromotion() {

    const [{}, dispatch] = useGlobalStore();
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingBonusList, setIsLoadingBonusList] = useState(false);
    const {register, handleSubmit, setValue, errors} = useForm({});

    const {
        register: registerBonusList, handleSubmit: handleSubmitBonusList, setValue: setValueBonusList, control
    } = useForm({
        defaultValues: {
            bonus_new_customer_amount_list: [{bonus_amount: '1', deposit_amount: '1'}],
        },
    });

    const {fields, append, remove} = useFieldArray({
        control, name: "bonus_new_customer_amount_list"
    });

    const searchEnableBonusNewCustomer = async () => {
        try {
            const request = {
                "name": "bonus_new_customer",
            };
            const response = await api_config.SEARCH_CONFIG(request);

            setValue("enable_bonus_new_customer", response.data.data?.enable_bonus_new_customer);
        } catch (e) {
            console.log(e);
        }
    };

    const updateEnableBonusNewCustomer = async (data) => {
        setIsLoading(true);

        const request = {
            ...data, "name": "bonus_new_customer",
        };

        try {
            await api_config.UPDATE_CONFIG(request);

            dispatch({
                type: "TOAST2", value: {
                    show: true,
                    type: 'success',
                    title: 'แก้ไข เปิด/ปิด โปรโมชั่นลูกค้าใหม่',
                    message: `แก้ไข เปิด/ปิด โปรโมชั่นลูกค้าใหม่ สำเร็จ`,
                },
            });

        } catch (error) {
            console.log(error);
            dispatch({
                type: "TOAST2", value: {
                    show: true,
                    type: 'danger',
                    title: 'แก้ไข เปิด/ปิด โปรโมชั่นลูกค้าใหม่',
                    message: `แก้ไข เปิด/ปิด โปรโมชั่นลูกค้าใหม่ ไม่สำเร็จ กรุณาติดต่อ admin`,
                }
            });
        } finally {
            setTimeout(() => setIsLoading(false), 1000);
        }
    };

    const searchBonusNewCustomerList = async () => {
        try {
            const request = {
                "name": "bonus_new_customer_amount_list",
            };
            const response = await api_config.SEARCH_CONFIG(request);
            setValueBonusList("bonus_new_customer_amount_list", response.data.data?.bonus_amount_list);
        } catch (e) {
            console.log(e);
        }
    };

    const updateBonusNewCustomerList = async (data) => {
        setIsLoadingBonusList(true);

        const request = {
            "name": "bonus_new_customer_amount_list", "bonus_amount_list": data?.bonus_new_customer_amount_list,
        };

        try {
            api_config.UPDATE_CONFIG(request);

            dispatch({
                type: "TOAST2", value: {
                    show: true,
                    type: 'success',
                    title: 'แก้ไข จำนวนเงิน โปรโมชั่นลูกค้าใหม่',
                    message: `แก้ไข จำนวนเงิน โปรโมชั่นลูกค้าใหม่ สำเร็จ`,
                },
            });

        } catch (error) {
            console.log(error);
            dispatch({
                type: "TOAST2", value: {
                    show: true,
                    type: 'danger',
                    title: 'แก้ไข จำนวนเงิน โปรโมชั่นลูกค้าใหม่',
                    message: `แก้ไข จำนวนเงิน โปรโมชั่นลูกค้าใหม่ ไม่สำเร็จ กรุณาติดต่อ admin`,
                }
            });
        } finally {
            setTimeout(() => setIsLoadingBonusList(false), 1000);
        }
    };

    useEffect(() => {
        searchEnableBonusNewCustomer();
        searchBonusNewCustomerList();
    }, []);

    return (<section className="content pt-4 pr-4 pl-4">
        <div className="container-fluid">
            <div>
                <Card className="card-primary">
                    <Card.Header>เปิด/ปิด โปรโมชั่นลูกค้าใหม่</Card.Header>
                    <Card.Body>
                        <Form onSubmit={handleSubmit(updateEnableBonusNewCustomer)}>
                            <Form.Row>
                                <Form.Group as={Col} md="3" lg="3" controlId="validation001">
                                    <InputGroup>
                                        <Form.Check
                                            type="switch"
                                            name="enable_bonus_new_customer"
                                            custom
                                            label="เปิด/ปิด"
                                            isInvalid={!!errors.enable_bonus_new_customer}
                                            ref={register}
                                        />
                                        <Form.Control.Feedback type="invalid" tooltip>
                                            กรุณาเลือกสถานะเปิด/ปิด
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Button variant="success" type="submit" disabled={isLoading}>
                                    {isLoading ? (<>
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        /> กำลังดำเนินการ</>) : `บันทึกการแก้ไข`}
                                </Button>
                            </Form.Row>
                        </Form>
                    </Card.Body>
                </Card>
                <Card className="card-warning">
                    <Card.Header>ตั้งค่าจำนวนเงินโปรโมชั่นลูกค้าใหม่</Card.Header>
                    <Card.Body>
                        <Form onSubmit={handleSubmitBonusList(updateBonusNewCustomerList)}>
                            {fields.map((item, index) => {
                                return (
                                    <Form.Row key={item.id}>
                                        <Form.Group as={Col} md="2" lg="2">
                                            <Form.Label>จำนวนเงินฝาก (บาท)</Form.Label>
                                            <Form.Control
                                                name={`bonus_new_customer_amount_list.${index}.deposit_amount`}
                                                type="number"
                                                defaultValue={`${item?.deposit_amount}`}
                                                ref={registerBonusList()}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md="2" lg="2">
                                            <Form.Label>จำนวนเงินโบนัส (บาท)</Form.Label>
                                            <Form.Control
                                                name={`bonus_new_customer_amount_list.${index}.bonus_amount`}
                                                type="number"
                                                defaultValue={`${item?.bonus_amount}`}
                                                ref={registerBonusList()}
                                            />
                                        </Form.Group>
                                        <Button
                                            variant="secondary"
                                            className="ml-2 mr-1 mt-8 h-12"
                                            onClick={() => remove(index)}>ลบ</Button>
                                    </Form.Row>
                                );
                            })}
                            <Form.Row>
                                <Button
                                    className="mt-4"
                                    onClick={() => {
                                        append({amount: '1', deposit_amount: '1'});
                                    }}
                                >เพิ่ม</Button>
                            </Form.Row>
                            <Form.Row>
                                <Button className={"mt-4"} variant="success" type="submit" disabled={isLoadingBonusList}>
                                    {isLoadingBonusList ? (<>
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        /> กำลังดำเนินการ</>) : `บันทึกการแก้ไข`}
                                </Button>
                            </Form.Row>
                        </Form>
                    </Card.Body>
                </Card>
            </div>
        </div>
    </section>)
}