import moment from "moment";
import React, { useEffect, useState } from 'react'
import { Button, Card, Modal, Table } from "react-bootstrap";
import api from "../../api/managebank";
import { useGlobalStore } from "../../store/globalContext";

export default function QRSession() {
  const dispatch = useGlobalStore()[1];
  const [ waitingBank, setWaitingBank ] = useState([])
  const [ showModal, setShowModal ] = useState(false)
  const [ manualLoginParams, setManualLoginParam ] = useState({
    "id": "",
    "qr_base64_login": "",
    "bank_name": "",
  })

  const qrLoginStyle = (qrLogin) => {
    return qrLogin ? { background: "#f66868" } : {}
  }

  const getWaitingBank = async () => {
    try {
      const response = await api.BANK_LIST_MANUAL_LOGIN()
      const { data } = response.data
      console.log("get bank list manual login")
      console.log(data)
      let waitingBankData = []
      data.forEach((bank => {
          const waiting = {
            "id": bank.id,
            "bank_name": bank.bank_name,
            "bank_account_no": bank.bank_account_no,
            "qr_base64_login": bank.qr_base64_login,
            "qr_created_at": bank.qr_created_at,
            "read_statement_at": bank.read_statement_at,
          }
          waitingBankData.push(waiting)
        })
      )
      setWaitingBank(waitingBankData)
    } catch (error) {
      console.log("get waiting banks error ", error.response)
    }
  }

  const handleOpenModal = (index) => {
    setShowModal(true)
    setManualLoginParam({
      "id": waitingBank[index].id,
      "qr_base64_login": waitingBank[index].qr_base64_login,
      "bank_name": waitingBank[index].bank_name,
    })
  }

  const handleCloseModal = () => {
    setShowModal(false)
    getWaitingBank()
  }

  const handleManualLogin = async () => {
    try {
      const response = await api.BANK_SUBMIT_MANUAL_LOGIN(manualLoginParams)
      const { data } = response.data
      console.log("submit manual login", data)
      dispatch({
        type: "TOAST2",
        value: {
          show: true,
          type: 'success',
          title: `Update QR Login`,
          message: `บัญชีสามารถใช้ได้อีกครั้ง กรุณารอสักครู่`,
        }
      });
    } catch (error) {
      console.log("get waiting banks error ", error.response)
      dispatch({
        type: "TOAST2",
        value: {
          show: true,
          type: 'danger',
          title: `Update QR Login`,
          message: `ยืนยันการ Login ไม่สำเร็จ กรุณาลองอีกครั้ง`,
        }
      });
    }
    handleCloseModal()
    getWaitingBank()
  }

  useEffect(() => {
    getWaitingBank()
  }, [])

  return (
    <section className="content pt-4 pr-4 pl-4">
      <div className="container-fluid">
        <div>
          <Modal show={ showModal }>
            <Modal.Header>
              <Modal.Title>จัดการ Login ธนาคาร</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div
                style={ {
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center"
                } }>
                {
                  manualLoginParams.qr_base64_login ?
                    <>
                      <img src={ manualLoginParams.qr_base64_login }
                           alt={ manualLoginParams.bank_name } height={ 300 }
                           width={ 200 }/>
                      แสกน QR ที่ใช้ในการ Login เรียบร้อยแล้วใช่หรือไม่
                    </>
                    :
                    <h6>ไม่มี QR ที่ต้อง Login</h6>
                }
              </div>
            </Modal.Body>
            <Modal.Footer>
              {
                manualLoginParams.qr_base64_login &&
                <Button variant="success" onClick={ handleManualLogin }>ใช่</Button>
              }
              <Button variant="secondary" onClick={ handleCloseModal }>ปิด</Button>
            </Modal.Footer>
          </Modal>
          <Card className="card-primary">
            <Card.Header>ธนาคารรอการ Login ผ่าน QR</Card.Header>
            <Card.Body>
              <h6 style={ {
                color: "red",
                fontSize: 30,
                marginBottom: 10
              } }>กรุณาคลิกที่รายการเพื่อทำการยืนยันว่าแสดงเรียบร้อยแล้ว</h6>
              <React.Fragment>
                <Table responsive hover size="md">
                  <thead className="text-center">
                  <tr>
                    <th>ชื่อบัญชี</th>
                    <th>เลขที่บัญชี</th>
                    <th>เวลาที่ QR หมดอายุ</th>
                    <th>เวลาที่โปรแกรมทำงานล่าสุด</th>
                  </tr>
                  </thead>
                  <tbody className="text-center">
                  {
                    waitingBank?.map(({
                                        id,
                                        bank_name,
                                        bank_account_no,
                                        qr_created_at,
                                        qr_base64_login,
                                        read_statement_at,
                                      }, index) =>
                      <tr key={ index } style={ qrLoginStyle() } onClick={ () => handleOpenModal(index) }>
                        <td><h6>{ bank_name }</h6></td>
                        <td><h6>{ bank_account_no }</h6></td>
                        <td><h6>{ moment(qr_created_at).add(4, "minutes").format("DD/MM/YYYY HH:mm:ss") }</h6></td>
                        <td><h6>{ moment(read_statement_at).format("DD/MM/YYYY HH:mm:ss") }</h6></td>
                      </tr>
                    )
                  }
                  </tbody>
                </Table>
              </React.Fragment>
            </Card.Body>
          </Card>
        </div>
      </div>
    </section>
  )
}