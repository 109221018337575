import React, {useState} from 'react'
import { Button, Row, InputGroup, Form, Alert } from 'react-bootstrap'
import './index.scss'
import { useForm } from "react-hook-form";
import api from "../../api/login";
import  {setAxiosHeaderAuthorization} from '../../client/axios'
import localStorage from "../../store/localStorage"
import { useHistory } from "react-router-dom";
import { useGlobalStore } from '../../store/globalContext'
import Login2Factor from '../login/Login2Factor'
import Login2FactorWithQR from "./Login2FactorWithQR";

export default function Login() {
  const dispatch = useGlobalStore()[1];
  const history  = useHistory();
  const [show, setShow] = useState(false);
  const [errorLogin, setErrorLogin] = useState("")
  const [modalLoginShow, setModalLoginShow] = useState(false)
  const [modalValidateQRShow, setModalValidateQRShow] = useState(false)
  const [qrData, setQrData]  = useState("")
  const [twoFactorAuth, setTwoFactorAuth] = useState(false)
  const [username, setUsername] = useState("")
  const [userLoginData, setUserLoginData] = useState()

  const { register, handleSubmit, errors } = useForm({
    defaultValues: { username: "", password: "", partner: process.env.REACT_APP_IMAGE_LOGO },
    criteriaMode: "all"
  });
  // const [validated, setValidated] = useState(false);
  const onSubmit = async (data) => {

    // console.log("onSubmit : ", data);
    localStorage.setRedirect(false)
    setErrorLogin("");
    setShow(false);
    setUsername(data.username)
    // console.log("process.env.REACT_APP_LOGIN_FACTOR_AUTH:", process.env.REACT_APP_LOGIN_FACTOR_AUTH)
    if (process.env.REACT_APP_LOGIN_FACTOR_AUTH === "enable"){
      checkUser({username: data.username})
    }else{
      const login_request = {
        username: data.username,
        password: data.password,
        partner: process.env.REACT_APP_IMAGE_LOGO
      }

      try {
        const response = await api.LOGIN_API(login_request);
        // console.log({ ...response });
        // console.log(response.data.data);
        if (response.status !== 200) {
          //console.log("login failed : ", response);
          setErrorLogin("ไม่สามารถเข้าสู่ระบบได้ กรุณาตรวจสอบ Username & Password");
          setShow(true);
          return
        }
        const {data} = response.data
        setUserLoginData(data)
        // localStorage.setAllAccess({ ...data });
        // setAxiosHeaderAuthorization(data.token)
        dispatch({
          type: "login_profile",
          value: data
        });
        // console.log("REACT_APP_LOGIN_FACTOR_AUTH", process.env.REACT_APP_LOGIN_FACTOR_AUTH)
        if (process.env.REACT_APP_LOGIN_FACTOR_AUTH === "enable"){
          if(!getQRAuthen()){
            setErrorLogin("ไม่สามารถทำการ generate QR ได้ กรุณาติดต่อ admin");
            setShow(true);
          }
          setModalLoginShow(true)
        }else{
          localStorage.setAllAccess({ ...userLoginData });
          localStorage.setUsername(username)
          setAxiosHeaderAuthorization(userLoginData.token)
          history.push("/registeruser");
        }

      } catch (error) {
        console.log("login failed : ", error);
        setErrorLogin("ไม่สามารถเข้าสู่ระบบได้ กรุณาติดต่อ admin");
        setShow(true);
      }
    }
  }

  const checkUser = async (props) =>{
    const {username} = props
    // console.log("username", username)
    setUsername(username)

    try {
      const response = await api.LOGIN_CHECK(props);
      // console.log({ ...response });
      // console.log(response.data.data);
      if (response.status !== 200) {
        setErrorLogin("ไม่สามารถเข้าสู่ระบบได้ กรุณาตรวจสอบ Username & Password");
        setShow(true);
        return
      }
      //console.log("login check success : ", response);
      const data = response.data.data
      //console.log("setTwoFactorAuth : ", data);
      setTwoFactorAuth(data.enabled)
      setModalLoginShow(true)
    } catch (error) {
      //console.log("login failed : ", error);
      setErrorLogin("ไม่สามารถเข้าสู่ระบบได้ กรุณาติดต่อ admin");
      setShow(true);
    }
  }


  const getQRAuthen = async () =>{
    try {
      const response = await api.GET_QR_AUTH();
      // console.log({ ...response });
      // console.log(response.data.data);
      if (response.status !== 200) {
        console.log("login failed : ", response);
        return false
      }
      console.log("login success");
      const data = response.data.data
      setQrData(`data:image/jpeg;base64,${data.qr}`)
      return true
    } catch (error) {
      console.log("login failed : ", error);
      return false
    }
  }

  const showQRModal = ()=>{
    getQRAuthen()
    setModalValidateQRShow(true)
  }
  return (
    <div className='login-container'>
      <Login2Factor
          show={modalLoginShow}
          onHide={() => setModalLoginShow(false)}
          authFlag={twoFactorAuth}
          username={username}
          setUserLoginData={setUserLoginData}
          onQrScreen={()=>showQRModal()}
      />
      <Login2FactorWithQR
          show={modalValidateQRShow}
          onHide={() => setModalValidateQRShow(false)}
          qrCode={qrData}
          username={username}
          loginData={userLoginData}
      />
      <Row>
        <div className="login-section left col-12">
          <img src={process.env.REACT_APP_BANNER_IMAGE} alt='logo' style={{width: 400}} />
        </div>
        <div className="login-section right col-12">
          
          <Form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Alert variant="danger" onClose={() => setShow(false)} dismissible show={show}>
        <Alert.Heading>ขออภัย</Alert.Heading>
        <p>
          {errorLogin}
        </p>
      </Alert>
            <Form.Group mb="3" size="lg" controlId="validationFormik01">
              <InputGroup className="mb-3 col-12" size="lg">
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroup-sizing-default"><i className="fas fa-user-shield mr-2" /></InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  type="text"
                  name="username"
                  placeholder="Username"
                  isInvalid={!!errors.username}
                  ref={register({ required: true })}
                />

                <Form.Control.Feedback type="invalid" tooltip>
                  กรุณาระบุ Username
              </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
              {
                (process.env.REACT_APP_LOGIN_FACTOR_AUTH === "disable")?
                (
                    <>
                      <Form.Group mb="3" size="lg" controlId="validationFormik02">
                        <InputGroup className="mb-3  col-12" size="lg">
                          <InputGroup.Prepend>
                            <InputGroup.Text id="inputGroup-sizing-default"><i className="fas fa-key mr-2" /></InputGroup.Text>
                          </InputGroup.Prepend>
                          <Form.Control
                              type="password"
                              name="password"
                              placeholder="Password"
                              isInvalid={!!errors.password}
                              ref={register({ required: true })}
                          />

                          <Form.Control.Feedback type="invalid" tooltip>
                            กรุณาระบุ Password
                          </Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>
                    </>
                ):(<></>)
              }

            <div className="row">
              <div className="col-8">
              </div>
            </div>
            <Button type="submit" className="btn btn-block btn-primary" size="lg"> <i className="fas fa-lock-open mr-2" /> Login</Button>
            <Button className="btn btn-block btn-secondary" size="lg"> <i className="fas fa-user-cog mr-2" /> ลืมรหัสผ่าน</Button>
          </Form>
        </div>
      </Row>
    </div>
  )
}
