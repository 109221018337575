import React, { useState } from 'react'
import {Modal, Button, Form, Col, Spinner} from 'react-bootstrap'
import api_workorder from "../../api/workorder"
import { useGlobalStore } from '../../store/globalContext'

export default function ConfirmDeleteWorkOrder(props) {
  const [{ subscribe, dashboard_work_order_deposit, customers, status }, dispatch] = useGlobalStore();
  const { data, isShow, onClose, callBack } = props
  const [show, setShow] = useState(isShow);
  const { id, user_id, first_name, last_name, amount } = data
  const [isLoading, setLoading] = useState(false);

  const deleteWorkOrder = async()=>{
    setLoading(true)
    const work_order_delete_request = {
        id: id,
        removed_by: "pipe_test"
    }
    console.log("update work_order_deposit_request : ", work_order_delete_request)
    const response = await api_workorder.WORK_ORDER_DELETE(work_order_delete_request)

    if (response.status !== 200) {
      console.log(`create work_order_deposit_failed`, response.message);
      setLoading(false)
      return
    }

    dispatch({
      type: "TOAST2",
      value: {
        show: true,
        type: 'success',
        title: `ยกเลิกใบงาน`,
        message: `ยกเลิกใบงานใบงาน UserID ${user_id} สำเร็จ`,
      }
    });
    callBack();
    setLoading(false);
  }


  return (
    <div>
      <Modal show={isShow} onHide={onClose} size="lg" centered={true}>
        <Modal.Header closeButton>
          <Modal.Title>ลบใบงาน</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Row>
              <Form.Group as={Col} md="3" lg="3">
                <Form.Label>UserID</Form.Label>
                <Form.Control type="text" value={user_id} readOnly={true}/>
              </Form.Group>
              <Form.Group as={Col} md="3" lg="3">
                <Form.Label>ชื่อ</Form.Label>
                <Form.Control type="text" value={first_name} readOnly={true}/>
              </Form.Group>
              <Form.Group as={Col} md="3" lg="3">
                <Form.Label>นามสกุล</Form.Label>
                <Form.Control type="text" value={last_name} readOnly={true}/>
              </Form.Group>
              <Form.Group as={Col} md="3" lg="3">
                <Form.Label>จำนวนเงิน</Form.Label>
                <Form.Control type="text" value={amount} readOnly={true}/>
              </Form.Group>
            </Form.Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={deleteWorkOrder} disabled={isLoading}>
            {isLoading ?
                (
                    <>
                      <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                      /> กำลังดำเนินการ</>)  : 'ยืนยันลบใบงาน'}
          </Button>
          <Button variant="secondary" onClick={onClose}>
            ยกเลิก
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
