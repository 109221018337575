import React, { useEffect, useState } from 'react'
import { Button, Card, Fade, Form, Pagination, Spinner } from 'react-bootstrap'
import api from '../../api/customer';
import TableSearchProspectCustomer from './TableSearchProspectCustomer';
import ModalSearchProspectCustomer from "./ModalSearchProspectCustomer";

export default function ManageProspectCustomer () {
  const [ searchData, setSearchData ] = useState( [] );
  const [ searchKeyword, setSearchKeyword ] = useState( "" );
  const [ currentPage, setCurrentPage ] = useState( 1 );
  const [ dictLastIndices, setDictLastIndices ] = useState( { 1: "" } );
  const [ searchButton, setSearchButton ] = useState( false );
  const limitPage = 10;

  const [ modalInfo, setModalInfo ] = useState( {} );
  const [ isOpenModal, setIsOpenModal ] = useState( false );
  const [ errorAlertModal, setErrorAlertModal ] = useState( "" );

  const openCustomerModal = ( customerData, flag ) => {
    setModalInfo( customerData );
    setIsOpenModal( flag );
  }

  const handleCloseModal = () => {
    setIsOpenModal( false )
  }

  const handleKeyword = ( value ) => {
    setCurrentPage( 1 )
    setSearchKeyword( value )
  };

  const handlePrevPage = () => {
    if ( currentPage !== 1 ) {
      setCurrentPage( currentPage - 1 )
    }
  }

  const handleNextPage = () => {
    if ( !searchData ) {
      return
    }

    const setId = [ ...new Set( searchData.map( e => e.id ) ) ]
    if ( setId.length === limitPage ) {
      setCurrentPage( currentPage + 1 )
    }
  }

  const keySearchInput = async( value ) => {
    if ( value.length !== 0 && value.length < 2 ) {
      setSearchButton( false )
      return
    }

    setSearchButton( true )

    const searchData = {
      keyword: value,
      limit: limitPage,
      last_element_id: dictLastIndices[ currentPage ]
    }

    const response = await api.SEARCH_PROSPECT( searchData );
    if ( response.status !== 200 ) {
      //console.log("search customer failed");
      setSearchButton( false )
      return
    }

    const { data } = response.data
    console.log( "search customer data: ", data )

    setSearchData( data );
    setSearchButton( false )

    if ( data ) {
      const newIndex = { ...dictLastIndices, [ currentPage + 1 ]: data[ data.length - 1 ].id }
      setDictLastIndices( newIndex )
    }
  };

  const handleEditProspectInfo = async( { updateProspect } ) => {
    try {
      const request = {
        id: updateProspect.id,
        remark: updateProspect.remark,
        done_contact: updateProspect.done_contact,
      };

      await api.UPDATE_PROSPECT( request );

      setIsOpenModal( false );
      keySearchInput( updateProspect.mobile_no );
    } catch ( e ) {
      console.log( "error call api: ", e )
      setErrorAlertModal( "ขออภัยไม่สามารถแก้ไขข้อมูลได้ กรุณาตรวจสอบข้อมูลลูกค้าเพิ่มเติม" )
    }
  }

  useEffect( () => {
    keySearchInput( searchKeyword );
  }, [ currentPage ] )

  return (
    <div className="pt-4 pr-4 pl-4">
      <ModalSearchProspectCustomer
        isOpenModal={isOpenModal}
        customerData={modalInfo}
        onClose={handleCloseModal}
        onSave={handleEditProspectInfo}
        callBackSearch={() => keySearchInput( searchKeyword )}
        alertError={errorAlertModal}
      />
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 pt-3">
              <Card className="card-primary">
                <Card.Header><h5>ค้นหาลูกค้าที่ยังไม่ได้สมัคร</h5></Card.Header>
                <Card.Body className="row">
                  <Form.Row className="col-md-12">
                    <Form.Group className="col-md-6">
                      <Form.Label>พิมพ์ค้นหาลูกค้า</Form.Label>
                      <Form.Control as="input"
                                    name="search_customer"
                                    placeholder="eg. ชื่อ - นามสกุล, เบอร์โทรศัพท์"
                                    defaultValue=""
                                    onChange={( e ) => handleKeyword( e.target.value )}
                      >
                      </Form.Control>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row className="col-md-12">
                    <Button style={{ marginBottom: "10px" }}
                            onClick={() => keySearchInput( searchKeyword )} disabled={searchButton}>
                      {
                        ( searchButton ) ?
                          ( <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          /> ) : ( <>ค้นหา</> )
                      }
                    </Button>
                    <Pagination className={"ml-auto"}>
                      <Pagination.Prev onClick={handlePrevPage}/>
                      <Pagination.Item active={true}>
                        {currentPage}
                      </Pagination.Item>
                      <Pagination.Next onClick={handleNextPage}/>
                    </Pagination>
                  </Form.Row>
                  <Form.Row className="col-md-12">
                    <Form.Group className="col-md-12">
                      <Fade in={true}>
                        <TableSearchProspectCustomer customerSearchList={searchData}
                                                     editCustomerData={openCustomerModal}/>
                      </Fade>
                    </Form.Group>
                  </Form.Row>
                </Card.Body>
              </Card>
            </div>
            <div className="col-md-6">
            </div>
          </div>
        </div>
      </section>
    </div>
  )
};