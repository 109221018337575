import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Col, Form, InputGroup } from 'react-bootstrap';
import LuckyDrawForm from "./LuckyDrawForm";

export default function EditLuckyDraw ( { data, onSubmitForm, onClose, alertError } ) {
    const {
        id,
    } = data;
    
    const [ validated, setValidated ] = useState( false );
    const { register, handleSubmit, watch, errors, setValue, getValues, setError, reset, control } = useForm(
        {
            defaultValues: { ...data }, criteriaMode: "all"
        }
    );

    const [ buttonIsLoading, setButtonIsLoading ] = useState( false );
    const [ disableButton, setDisableButton ] = useState( false );

    const onSubmit = editData => {
        setButtonIsLoading( true );

        let luckyDrawOptions = [];
        for ( let i = 0; i < editData.lucky_draw_options.length; i++ ) {
            let luckyDrawOption = {};
            for ( let key in editData.lucky_draw_options[ i ] ) {
                let value = editData.lucky_draw_options[ i ][ key ];
                let newValue;
                if ( key === "quota" || key === "deposit_back_date" || key === "minimum_deposit_amount" ) {
                    newValue = parseFloat( value );
                } else if ( key === "user_ids" ) {
                    if ( value === "" ) {
                        newValue = [];
                    } else {
                        newValue = value.split( "," );
                    }
                } else {
                    newValue = value;
                }
                luckyDrawOption[ key ] = newValue;
            }
            if ( luckyDrawOption.minimum_deposit_amount === 0 ) {
                delete luckyDrawOption.deposit_back_date
            }
            luckyDrawOptions.push( luckyDrawOption );
        }

        onSubmitForm(
            {
                updateLuckyDraw: {
                    ...editData,
                    lucky_draw_options: luckyDrawOptions,
                    id: id,
                }
            }
        );
    };

    useEffect( () => {
        if ( alertError !== "" ) {
            setButtonIsLoading( false )
        }

    }, [ alertError ] );

    return (
        <Form noValidate validated={validated} onSubmit={handleSubmit( onSubmit )}>
            <LuckyDrawForm register={register} errors={errors} control={control} watch={watch}/>
            <Form.Row>
                <Form.Group as={Col} md="4" lg="4" controlId="validation23">
                    <InputGroup>
                        <Form.Check
                            type="switch"
                            name="active"
                            custom
                            label="เปิด/ปิด เกมชิงโชค"
                            ref={register}
                        />
                        <Form.Control.Feedback type="invalid" tooltip>
                            กรุณาเลือกสถานะเปิด/ปิด
                        </Form.Control.Feedback>
                    </InputGroup>
                </Form.Group>
            </Form.Row>
            {buttonIsLoading
                ? ( <Button>
                    <i className="fas fa-circle-notch fa-spin"/> ระบบกำลังดำเนินการ
                </Button> )
                : <>
                    {'  '}<Button type="submit" variant="primary" disabled={disableButton}>บันทึกการแก้ไข</Button>
                    {'  '}<Button variant="secondary" onClick={onClose}>ปิด</Button>
                </>
            }
        </Form>
    )
}