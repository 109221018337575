import React, { useEffect, useLayoutEffect, useState } from "react";
import {
  Badge,
  Button,
  Card,
  Col,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  InputGroup,
  Modal,
  OverlayTrigger,
  Pagination,
  Spinner,
  Table,
  Tooltip,
} from "react-bootstrap";
import { useGlobalStore } from "../../store/globalContext";
import NumberFormat from "react-number-format";
import { useForm } from "react-hook-form";
import api_bank from "../../api/managebank";
import api_work_order from "../../api/workorder";
import api_workorder from "../../api/workorder";
import moment from "moment";
import WorkOrderModal from "./WorkOrderModal";
import { LogoWithAccountNo2 } from "../banks_logo";
import ModalCommentTracking from "./ModalCommentTracking";
import localStorage from "../../store/localStorage";
import ShowBankListComponent from "./ShowBankListComponent";

const username = localStorage.getUsername();

export default function WorkOrder(props) {
  const [searchButton, setSearchButton] = useState(false);
  const [{ work_order_detail }, dispatch] = useGlobalStore();
  const [validated, setValidated] = useState(false);
  const { register, handleSubmit, errors, getValues, setValue } = useForm({
    defaultValues: {},
    criteriaMode: "all",
  });
  const {
    workOrderTitleHeader,
    workOrderTitleBody,
    workOrderType,
    workOrderSubType,
    showSelectedBank,
  } = props;
  const [selectBank, setSelectBank] = useState("");

  const [bank, setBank] = useState([]);
  const [allBank, setAllBank] = useState([])
  const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [workOrderDetailsData, setWorkOrderDetailsData] = useState([]);
  const [limitPage, setLimitPage] = useState(100);
  const [keyword, setKeyword] = useState("");
  // const [totalWorkOrder, setTotalWorkOrder] = useState(0)
  // const [bankAmount, setBankAmount] = useState(0)
  const [manualOrder, setManualOrder] = useState(false);

  const limitPageList = [
    { text: "100", value: 100 },
    { text: "200", value: 200 },
    {
      text: "500",
      value: 500,
    },
    { text: "1000", value: 1000 },
  ];
  const [modalData, setModalData] = useState({
    header: "ยืนยันการแก้ไข",
    headerClassName: "btn-success",
    bodyTitle: "",
    placeholder: "ข้อมูลเครดิตถูกต้อง",
  });
  const [modalShow, setModalShow] = useState(false);
  //const [workOrderUpdateData, setWorkOrderUpdateData] = useState([])
  // const [workOrderOverallData, setWorkOrderOverallData] = useState([])

  const [currentPage, setCurrentPage] = useState(1);
  const [dictLastIndices, setDictLastIndices] = useState({ 1: "" });
  const [comment, setComment] = useState("");
  const [pauseWs, setPauseWs] = useState(false)

  const getBankList = async () => {
    const response = await api_bank.BANK_SEARCH_TYPE({
      keyword: `${workOrderType}+vvip`,
    });
    if (response.status !== 200) {
      console.log(`search bank_list by user id failed`, response.message);
      return;
    }

    const { data } = response.data;
    //console.log("search deposit bank response data: ", data);
    setAllBank(data.filter((bank) => bank.obsolete === false && bank.lock === false))
    setBank(data.filter((bank) => bank.enable));
  };

  // console.log('work order summary', work_order_detail)
  useLayoutEffect(() => {
    //console.log("work_order_detail update: ", work_order_detail);
    // NOT UPDATE WHEN DATA IS NULL
    if(pauseWs){
      return;
    }

    if (work_order_detail === undefined || work_order_detail === null) {
      return;
    }

    if (moment(work_order_detail.updated_at) >= moment(endDate).endOf("day")) {
      return;
    }

    if (!workOrderDetailsData) {
      return;
    }

    const {
      id,
      customer_id,
      created_at,
      user_id,
      first_name,
      last_name,
      from_bank_short_name,
      from_bank_name,
      from_bank_account_no,
      to_bank_short_name,
      to_bank_name,
      to_bank_account_no,
      amount,
      status,
      updated_at,
      comment,
      comment_tracking,
      before_balance,
      after_balance,
      credit_status,
      user_ids,
      updated_by,
      display_status,
      to_bank_account_name,
      mapping_manual_success,
      work_order_type,
      qr_ref,
    } = work_order_detail;

    // UPDATE WHEN DATA MATCH CONDITION
    if (keyword && workOrderDetailsData[0].customer_id !== customer_id) {
      return;
    }

    if (workOrderType === "deposit") {
      if (selectBank && to_bank_account_no !== selectBank) {
        return;
      }
    } else {
      if (selectBank && from_bank_account_no !== selectBank) {
        return;
      }
    }

    const newData = {
      id: id,
      customer_id: customer_id,
      created_at: created_at,
      user_id: user_id,
      first_name: first_name,
      last_name: last_name,
      from_bank_short_name: from_bank_short_name,
      from_bank_name: from_bank_name,
      from_bank_account_no: from_bank_account_no,
      to_bank_short_name: to_bank_short_name,
      to_bank_name: to_bank_name,
      to_bank_account_no: to_bank_account_no,
      amount: amount,
      status: status,
      updated_at: updated_at,
      comment: comment,
      comment_tracking: comment_tracking,
      before_balance: before_balance,
      after_balance: after_balance,
      credit_status: credit_status,
      user_ids: user_ids,
      updated_by: updated_by,
      display_status: display_status,
      to_bank_account_name: to_bank_account_name,
      mapping_manual_success: mapping_manual_success,
      work_order_type: work_order_type,
      qr_ref: qr_ref,
    };

    if (work_order_detail.state === "insert") {
      const elementsIndex = workOrderDetailsData.findIndex(
        (work_order_detail) => work_order_detail.id === id
      );
      if (elementsIndex === -1) {
        setWorkOrderDetailsData([newData, ...workOrderDetailsData]);
        return;
      }
    }
    if (
      work_order_detail.state === "update" ||
      work_order_detail.state === "deleted"
    ) {
      const elementsIndex = workOrderDetailsData.findIndex(
        (work_order_detail) => work_order_detail.id === id
      );
      let newArray = [...workOrderDetailsData];
      newArray[elementsIndex] = work_order_detail;
      setWorkOrderDetailsData(newArray);
    }
  }, [work_order_detail]);

  useEffect(() => {
    getBankList();
  }, []);

  const onSubmit = async (workOrderData) => {
    //console.log("submit data: ", workOrderData);
    setPauseWs(true)
    setSearchButton(true);

    const work_order_search_data = {
      keyword: keyword,
      bank_account_no: workOrderData.bank_account_no,
      work_order_type: workOrderType,
      work_order_sub_type: workOrderSubType,
      start_date: workOrderData.start_date,
      end_date: workOrderData.end_date,
      last_element_id: "",
      limit: limitPage,
      channel: manualOrder ? "manual" : "",
    };

    //console.log(work_order_search_data)

    const response = await api_work_order.WORK_ORDER_SEARCH(
      work_order_search_data
    );

    if (response.status !== 200) {
      //console.log(`search bank_list by user id failed`, response.message);
      setSearchButton(false);
      dispatch({
        type: "TOAST2",
        value: {
          show: true,
          type: "danger",
          title: `ค้นหาใบงาน`,
          message: `ขออภัยระบบไม่สามารถดำเนินการได้ในขณะนี้`,
        },
      });
      return;
    }

    const { data } = response.data;
    //console.log("search work order response work_orders length: ", data.work_orders);
    setCurrentPage(1);
    // setTotalWorkOrder(data.total_work_orders);
    // setBankAmount(data.bank_amount);
    setTimeout(() => {
      setSearchButton(false);
    }, 1000);
    setWorkOrderDetailsData(data.work_orders);
  };

  const renderSwitch = (props) => {
    //console.log("renderSwitch props:", props)
    const [status, id, reason, dataTable] = props;
    const credit_status = dataTable.credit_status;
    const display_status = dataTable.display_status;
    //console.log("display_status :", display_status)

    switch (status) {
      case "success":
        return (
          <td onClick={() => showModalWithData(dataTable)}>
            <h5>
              <Badge variant="success">{"สำเร็จ"}</Badge>
            </h5>
          </td>
        );
      case "failed":
        return (
          <td onClick={() => showModalWithData(dataTable)}>
            <h5>
              <OverlayTrigger
                placement="top"
                overlay={
                  reason ? (
                    <Tooltip id={`tooltip-status`}>{reason}</Tooltip>
                  ) : (
                    <div></div>
                  )
                }
              >
                <Badge variant="danger">{"ไม่สำเร็จ"}</Badge>
              </OverlayTrigger>
            </h5>
          </td>
        );
      //return <td onClick={() => showModalWithData(dataTable)}><h5 title={reason}><Badge variant="danger">{"ไม่สำเร็จ"}</Badge> </h5></td>;
      case "deleted":
        return (
          <td onClick={() => showModalWithData(dataTable)}>
            <h5>
              <Badge variant="warning">{"ยกเลิก"}</Badge>
            </h5>
          </td>
        );
      case "mapping":
        return (
          <td onClick={() => showModalWithData(dataTable)}>
            <h5>
              <Badge variant="primary">{"กรุณาจับคู่รายการ"}</Badge>
            </h5>
          </td>
        );
      case "pending":
        if (credit_status === "unknown") {
          return (
            <td onClick={() => showModalWithData(dataTable)}>
              <Badge variant="dark">กรุณาตรวจสอบรายการ</Badge>
            </td>
          );
        }
        if (credit_status === "failed") {
          return (
            <td onClick={() => showModalWithData(dataTable)}>
              <Badge variant="dark">กรุณาตรวจสอบรายการ</Badge>
            </td>
          );
        }
        if (workOrderType === "withdraw") {
          //     return (
          //         <td style={{minWidth:"150px"}} >
          //     <Button className="btn-success" size="sm" onClick={() => enableModalByMode({ header: "ยืนยันรายการอนุมัติ", headerclassName: "btn-success", bodyTitle: "ยืนยันการอนุมติ", placeholder: "eg. ข้อมูลเครดิตถูกต้อง", api_type: "approve", id: id })} disabled={display_status === "reserve"}><i className={`nav-icon fas fa-check`}></i></Button>
          //         {' '}
          //         <Button className="btn-warning" size="sm" onClick={() => enableModalByMode({ header: "ยืนยันรายการแก้ไข", headerclassName: "btn-warning", bodyTitle: "ยืนยันการทำ Manual", placeholder: "eg. บันทึกข้อมูลในระบบไปแล้ว", api_type: "approve_manual", id: id })} disabled={display_status === "reserve"}><i className={`nav-icon fas fa-cog`}></i></Button>
          //         {' '}
          //         <Button className="btn-danger" size="sm" onClick={() => enableModalByMode({ header: "ยืนยันรายการยกเลิก", headerclassName: "btn-danger", bodyTitle: "ยืนยันการยกเลิก", placeholder: "eg. ยกเลิกเนื่องจากไม่มีการทำธุรกรรม", api_type: "reject", id: id })} disabled={display_status === "reserve"}><i className={`nav-icon fas fa-ban`}></i></Button>
          // </td>
          //     )
          return (
            <td onClick={() => showModalWithData(dataTable)}>
              <Badge variant="info">
                <p>กรุณาตรวจสอบ</p>
                <p>และยืนยันรายการ</p>
              </Badge>
            </td>
          );
        } else {
          return (
            <td onClick={() => showModalWithData(dataTable)}>
              <Badge variant="warning">{"เลือกเอเย่นต์"}</Badge>
            </td>
          );
        }
      case "unknown":
        return (
          <td onClick={() => showModalWithData(dataTable)}>
            <Badge variant="dark">กรุณาตรวจสอบรายการ</Badge>
          </td>
        );
      default:
        return (
          <td onClick={() => showModalWithData(dataTable)}>
            <Spinner animation="grow" variant="info" />
          </td>
        );
    }
  };

  const approveWorkOrderByType = async (props) => {
    //console.log("approve work order data: ", props)
    const apiType = props.api_type;

    try {
      let response = null;
      setModalShow(false);
      setShowWorkOrderModal(false);

      switch (apiType) {
        case "approve":
          // console.log("approve_case")
          // return
          response = await api_work_order.APPROVE_WORKORDER(props);
          break;
        case "approve_manual":
          // console.log("approve_manual_case")
          // return
          response = await api_work_order.APPROVE_MANUAL_WORKORDER(props);
          break;
        default:
          // console.log("reject_case")
          // return
          response = await api_work_order.REJECT_WORKORDER(props);
          break;
      }
      setComment("");
      if (response.status !== 200) {
        //console.log(`create work_order_deposit_failed`, response.message);
        return;
      }
      //console.log("getWorkOrderListByType response.data: ", response.data)
      if (response.data.data === null || response.data.data === undefined) {
      }
      //console.log(response.data.data)
    } catch (error) {
      console.log("error approve work order : ", error?.response?.status);
    }
  };

  const [editModalData, setEditModalData] = useState();
  const [showWorkOrderModal, setShowWorkOrderModal] = useState(false);
  const showModalWithData = (props) => {
    const { id, display_status, display_reserved_by } = props;
    if (display_status === "reserve" && display_reserved_by !== username) {
      return;
    }
    updateReserveWorkOrder(id, "reserve");
    setEditModalData(props);
    setShowWorkOrderModal(true);
  };

  const [showCommentTrackingModal, setShowCommentTrackingModal] =
    useState(false);
  const [commentTracking, setCommentTracking] = useState({
    comment_tracking: [],
    user_id: "",
    first_name: "",
    last_name: "",
  });

  const handleCommentTracking = (
    commentTracking,
    userId,
    firstName,
    lastName
  ) => {
    if (!commentTracking) {
      const commentTrackingDummy = [
        {
          updated_at: "",
          updated_by: "",
          comment: comment,
        },
      ];

      setCommentTracking({
        comment_tracking: commentTrackingDummy,
        user_id: userId,
        first_name: firstName,
        last_name: lastName,
      });
    } else {
      setCommentTracking({
        comment_tracking: commentTracking,
        user_id: userId,
        first_name: firstName,
        last_name: lastName,
      });
    }

    setShowCommentTrackingModal(true);
  };

  const handleCloseCommentTracking = () => {
    setShowCommentTrackingModal(false);
    setTimeout(() => {
      setCommentTracking({
        comment_tracking: [],
      });
    }, 200);
  };

  const changeBankWithSearch = (value, submitForm) => {
    setSelectBank(value);
    setCurrentPage(1);
    // submitForm();
  };

  const handlePrevPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (!workOrderDetailsData) {
      return;
    }

    if (workOrderDetailsData.length >= limitPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const trColorReserve = (
    user_id,
    created_by,
    mapping_manual_success,
    work_order_type
  ) => {
    //console.log("trColorReserve :", display_status, user_id, created_by)
    if (user_id === created_by) {
      return "table-info";
    }

    if (work_order_type === "deposit" && mapping_manual_success) {
      return "table-secondary";
    }
    return "";
  };

  const fetchData = async () => {
    const work_order_search_data = {
      keyword: keyword,
      bank_account_no: selectBank,
      work_order_type: workOrderType,
      work_order_sub_type: workOrderSubType,
      start_date: moment(getValues("start_date")).format("YYYY-MM-DD"),
      end_date: moment(getValues("end_date")).format("YYYY-MM-DD"),
      last_element_id: dictLastIndices[currentPage],
      limit: limitPage,
      channel: manualOrder ? "manual" : "",
    };

    const response = await api_work_order.WORK_ORDER_SEARCH(
      work_order_search_data
    );
    //console.log(work_order_search_data)

    if (response.status !== 200) {
      //console.log(`search bank_list by user id failed`, response.message);
      dispatch({
        type: "TOAST2",
        value: {
          show: true,
          type: "danger",
          title: `ค้นหาใบงาน`,
          message: `ขออภัยระบบไม่สามารถดำเนินการได้ในขณะนี้`,
        },
      });
      return;
    }

    const { data } = response.data;
    //console.log("search work order response work_orders length: ", data.work_orders);
    // setTotalWorkOrder(data.total_work_orders);
    // setBankAmount(data.bank_amount);
    setWorkOrderDetailsData(data.work_orders);

    if (data.work_orders) {
      const newIndex = {
        ...dictLastIndices,
        [currentPage + 1]: data.work_orders[data.work_orders.length - 1].id,
      };
      setDictLastIndices(newIndex);
    }
  };

  useEffect(() => {
    //console.log("reloadreloadreloadreloadreload",reload)
    // console.log(dictLastIndices)
    // console.log(currentPage, keyword, selectBank, workOrderType, startDate, endDate, limitPage)
    fetchData();
  }, [
    currentPage,
    selectBank,
    workOrderType,
    // startDate,
    // endDate,
    limitPage,
    manualOrder,
  ]);

  // useEffect( () => {
  //     console.log("SET RELOAD TABLE INSIDE")
  //     fetchData()
  // },[reload])

  const updateReserveWorkOrder = async (id, status) => {
    const request = {
      id: id,
      display_status: status,
    };

    const response = await api_workorder.WORK_ORDER_UPDATE_NO_RETRY(request);

    if (response.status !== 200) {
      console.log(`create work_order_deposit_failed`, response.message);
    }
  };

  const closeModalWithData = (id, status) => {
    //console.log("MODALDATA ID: ", id)
    updateReserveWorkOrder(id, "");
    setShowWorkOrderModal(false);
    fetchData();
  };

  const handleFontColorUserID = (customerComment) => {
    if (customerComment) {
      return { color: "red" };
    } else {
      return {};
    }
  };

  const handleManualOrderSearch = () => {
    setCurrentPage(1);
    setManualOrder(!manualOrder);
  };

  const unpauseWs = () =>{
    setPauseWs(false)
    setValue("start_date",moment().format("YYYY-MM-DD"))
    setValue("end_date",moment().format("YYYY-MM-DD"))
    setCurrentPage(1);
    void fetchData()
  }

  return (
    <>
      <div>
        <WorkOrderModal
          action="edit"
          workOrderType={workOrderType}
          modalHeader="แก้ไขใบงาน"
          buttonSubmit="แก้ไขข้อมูลใบงาน"
          data={editModalData}
          show={showWorkOrderModal}
          // selectFromBank=
          closeUpdateModal={() => closeModalWithData(editModalData.id)}
        />
      </div>
      <div>
        <Modal
          {...modalData}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={modalShow}
        >
          <Modal.Header className={modalData.headerclassName} closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {modalData.header}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>{modalData.bodyTitle}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={modalData.placeholder}
                  onChange={(e) => setComment(e.target.value)}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className={modalData.headerclassName}
              onClick={() =>
                approveWorkOrderByType({
                  api_type: modalData.api_type,
                  id: modalData.id,
                })
              }
            >
              ยืนยัน
            </Button>
            <Button className="secondary" onClick={() => setModalShow(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <div>
        <ModalCommentTracking
          showModal={showCommentTrackingModal}
          data={commentTracking}
          onClose={handleCloseCommentTracking}
        />
      </div>
      <ShowBankListComponent BankList={allBank} reloadBankList={getBankList} page={"summary"}/>
      <div className="col-md-12">
        <Card className="card-primary">
          {/* <Card.Header className="card card-success">ค้นหาใบงานฝาก</Card.Header> */}
          <Card.Header className="card card-success">
            {workOrderTitleHeader}
          </Card.Header>
          <Card.Body>
            <Form
              noValidate
              validated={validated}
              onSubmit={handleSubmit(onSubmit)}
            >
              <Form.Row>
                <FormGroup as={Col} md="12" lg="12">
                  <FormLabel>{workOrderTitleBody}</FormLabel>
                  <Form.Control
                    required
                    type="text"
                    name="keyword"
                    placeholder="eg. ufyuxxxx/ahagxxxx / 20200911 / 09:09 / ชื่อธนาคาร"
                    onKeyUp={(e) => {
                      setKeyword(e.target.value);
                      setCurrentPage(1);
                    }}
                  ></Form.Control>
                  {/* <input type="" name="email" className="form-control"
                                id="exampleInputEmail1" placeholder="eg. ufyu88xxxx/ahag2xxx, ชื่อ - นามสกุล, เบอร์โทรศัพท์, เลขที่บัญชี"
                            /> */}
                </FormGroup>
              </Form.Row>
              <Form.Row>
                {showSelectedBank && (
                  <Form.Group as={Col} md="3" lg="3" controlId="validation01">
                    <Form.Label>เลือกธนาคาร</Form.Label>
                    <InputGroup>
                      <Form.Control
                        as="select"
                        name="bank_account_no"
                        custom
                        isInvalid={!!errors.bank_name}
                        ref={register}
                        onChange={(e) =>
                          changeBankWithSearch(
                            e.target.value,
                            handleSubmit(onSubmit)
                          )
                        }
                      >
                        <option value="">Please Select</option>
                        {bank?.map(({ bank_name, bank_account_no }, index) => (
                          <option key={bank_account_no} value={bank_account_no}>
                            {bank_name}
                          </option>
                        ))}
                      </Form.Control>
                      <Form.Control.Feedback type="invalid" tooltip>
                        กรุณาเลือกธนาคารที่ต้องการค้นหา
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                )}
                <Form.Group as={Col} md="3" lg="3" controlId="validation02">
                  <Form.Label>เลือกวันที่ต้องการค้นหาใบงาน</Form.Label>
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text>Start-Date</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      type="date"
                      name="start_date"
                      placeholder="2020-09-10"
                      required
                      isInvalid={!!errors.start_date}
                      // onChange={(e) => {
                      //   //console.log("change start date ")
                      //   if (moment(e.target.value) >= moment(endDate)) {
                      //     setEndDate(
                      //       moment(e.target.value).format("YYYY-MM-DD")
                      //     );
                      //   }
                      //   setStartDate(
                      //     moment(e.target.value).format("YYYY-MM-DD")
                      //   );
                      //   setCurrentPage(1);
                      // }}
                      defaultValue={startDate}
                      // value={startDate}
                      ref={register({ required: true })}
                    />
                    <Form.Control.Feedback type="invalid" tooltip>
                      กรุณาเลือกวันที่ต้องการค้นหา
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
                <Form.Group as={Col} md="3" lg="3" controlId="validation04">
                  <Form.Label>เลือกวันที่ต้องการค้นหาใบงาน</Form.Label>
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text>End-Date</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      type="date"
                      name="end_date"
                      placeholder="2020-09-10"
                      required
                      isInvalid={!!errors.end_date}
                      // onChange={(e) => {
                      //   //console.log("change end date ")
                      //   if (moment(e.target.value) <= moment(startDate)) {
                      //     setStartDate(
                      //       moment(e.target.value).format("YYYY-MM-DD")
                      //     );
                      //   }
                      //   setEndDate(moment(e.target.value).format("YYYY-MM-DD"));
                      //   setCurrentPage(1);
                      // }}
                      defaultValue={endDate}
                      // value={endDate}
                      ref={register({ required: true })}
                    />
                    <Form.Control.Feedback type="invalid" tooltip>
                      กรุณาเลือกวันที่ต้องการค้นหา
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
                <Form.Group as={Col} md="2" lg="2" controlId="validation03">
                  <Form.Label>จำนวนแสดงผลต่อหน้า</Form.Label>
                  <InputGroup>
                    <Form.Control
                      as="select"
                      name="limit"
                      custom
                      required
                      defaultValue={limitPage}
                      // isInvalid={!!errors.to_bank_name}
                      // ref={register({ required: true })}
                      onChange={(e) => {
                        setLimitPage(parseInt(e.target.value));
                        setCurrentPage(1);
                      }}
                    >
                      <option value="">Please Select</option>
                      {limitPageList.map(({ text, value }, index) => (
                        <option key={text} value={value}>
                          {text}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid" tooltip>
                      กรุณาระบุธนาคาร
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </Form.Row>
              <Form.Row className="col-md-12">
                <Form.Group as={Col} md="4" lg="4" controlId="validation112">
                  <InputGroup>
                    <Form.Check
                      type="switch"
                      name="manual_order"
                      label="ค้นหารายการทำมือ"
                      onChange={(e) =>
                        handleManualOrderSearch(e.target.checked)
                      }
                    />
                  </InputGroup>
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <FormGroup as={Col} md="2" lg="10">
                  <Button type="submit" disabled={searchButton}>
                    {searchButton ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : (
                      <>ค้นหา</>
                    )}
                  </Button>
                  {" "}
                  <Button type="button" disabled={searchButton} variant={"danger"} onClick={()=>unpauseWs()}>
                    ล้างค่า
                  </Button>
                </FormGroup>
                <Pagination className={"ml-auto"}>
                  {/*<Pagination.First/>*/}
                  <Pagination.Prev onClick={handlePrevPage} />
                  <Pagination.Item active={true}>{currentPage}</Pagination.Item>
                  <Pagination.Next onClick={handleNextPage} />
                  {/*<Pagination.Last onClick={setCurrentPage(currentPage + 1)}/>*/}
                </Pagination>
              </Form.Row>
              <Table hover responsive className="table-valign-middle">
                <thead className="text-center">
                  <tr>
                    <th style={{ width: "1%" }}>#</th>
                    <th style={{ width: "5%" }}>วันที่/เวลา</th>
                    <th>UserID</th>
                    {/*<th>ชื่อ-นามสกุล</th>*/}
                    <th>บัญชีโอน</th>
                    <th>บัญชีรับ</th>
                    <th>จำนวนเงิน</th>
                    <th style={{ width: "2%" }}>สถานะ</th>
                    <th style={{ width: "2%" }}>สถานะ credit</th>
                    <th style={{ width: "4%" }}>Credit ก่อนหน้า</th>
                    <th style={{ width: "4%" }}>Credit คงเหลือ</th>
                    <th>ผู้ทำรายการ</th>
                    <th>อัพเดทล่าสุด</th>
                    <th style={{ width: "12%" }}>หมายเหตุ</th>
                    <th>สถานะใบงาน</th>
                  </tr>
                </thead>
                <tbody className="text-center justify-content-start">
                  {workOrderDetailsData?.map(
                    (
                      {
                        id,
                        created_at,
                        user_id,
                        first_name,
                        last_name,
                        from_bank_short_name,
                        from_bank_name,
                        from_bank_account_no,
                        to_bank_short_name,
                        to_bank_name,
                        to_bank_account_no,
                        amount,
                        status,
                        credit_status,
                        updated_at,
                        before_balance,
                        after_balance,
                        created_by,
                        updated_by,
                        comment,
                        comment_tracking,
                        credit_reason,
                        display_status,
                        display_reserved_by,
                        customer_comment,
                        to_bank_account_name,
                        mapping_manual_success,
                        work_order_type,
                        work_order_sub_type,
                        qr_ref,
                      },
                      index
                    ) => (
                      <tr
                        key={id + created_at}
                        className={trColorReserve(
                          user_id,
                          created_by,
                          mapping_manual_success,
                          work_order_type
                        )}
                        style={{ cursor: "pointer" }}
                      >
                        <td
                          onClick={() =>
                            showModalWithData(workOrderDetailsData[index])
                          }
                        >
                          {(currentPage - 1) * limitPage + index + 1}
                        </td>
                        <td
                          onClick={() =>
                            showModalWithData(workOrderDetailsData[index])
                          }
                        >
                          <p>{moment(created_at).format("DD/MM/YYYY HH:mm")}</p>
                        </td>
                        {credit_status === "pending" ? (
                          <td
                            onClick={() =>
                              showModalWithData(workOrderDetailsData[index])
                            }
                          >
                            -
                          </td>
                        ) : (
                          <td
                            style={handleFontColorUserID(customer_comment)}
                            onClick={() =>
                              showModalWithData(workOrderDetailsData[index])
                            }
                          >
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                customer_comment ? (
                                  <Tooltip id={`tooltip-status`}>
                                    {customer_comment}
                                  </Tooltip>
                                ) : (
                                  <div></div>
                                )
                              }
                            >
                              <h5 className="text-bold">{user_id || "-"}</h5>
                            </OverlayTrigger>
                            <div style={{ "justify-content": "start" }}>
                              <p>
                                {first_name || "-"} {last_name}
                              </p>
                            </div>
                          </td>
                        )}
                        {/*<td*/}
                        {/*  onClick={ () => showModalWithData(workOrderDetailsData[index]) }>{ first_name || "-" } { last_name }</td>*/}
                        <td
                          onClick={() =>
                            showModalWithData(workOrderDetailsData[index])
                          }
                        >
                          <div
                            style={{
                              "justify-content": "start",
                              display: "inline",
                              width: "15%",
                            }}
                          >
                            <LogoWithAccountNo2
                              bankShortName={from_bank_short_name}
                              bankFullName={from_bank_name}
                              bankName={from_bank_name}
                              accountNo={from_bank_account_no}
                              workOrderType={work_order_type}
                              workOrderSubType={work_order_sub_type}
                              column={"1"}
                            />
                          </div>
                        </td>
                        <td
                          onClick={() =>
                            showModalWithData(workOrderDetailsData[index])
                          }
                        >
                          <div
                            style={{
                              "justify-content": "start",
                              display: "inline",
                              width: "15%",
                            }}
                          >
                            <LogoWithAccountNo2
                              bankShortName={to_bank_short_name}
                              bankFullName={to_bank_name}
                              bankName={to_bank_account_name}
                              accountNo={to_bank_account_no}
                              workOrderType={work_order_type}
                              workOrderSubType={work_order_sub_type}
                              column={"2"}
                            />
                          </div>
                        </td>
                        <td
                          onClick={() =>
                            showModalWithData(workOrderDetailsData[index])
                          }
                        >
                          <NumberFormat
                            thousandSeparator={true}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            value={amount}
                            displayType="text"
                            className="text-bold"
                          />
                        </td>
                        {renderSwitch([
                          status,
                          id,
                          credit_reason,
                          workOrderDetailsData[index],
                        ])}
                        {renderSwitch([
                          credit_status,
                          id,
                          credit_reason,
                          workOrderDetailsData[index],
                        ])}
                        {/* <td>{status}</td> */}
                        <td
                          onClick={() =>
                            showModalWithData(workOrderDetailsData[index])
                          }
                        >
                          <NumberFormat
                            thousandSeparator={true}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            value={before_balance}
                            displayType="text"
                          />
                        </td>
                        <td
                          onClick={() =>
                            showModalWithData(workOrderDetailsData[index])
                          }
                        >
                          <NumberFormat
                            thousandSeparator={true}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            value={after_balance}
                            displayType="text"
                          />
                        </td>
                        <td
                          onClick={() =>
                            showModalWithData(workOrderDetailsData[index])
                          }
                        >
                          {updated_by === "system_auto" ? (
                            <i
                              className={`nav-icon fas fa-laptop-code fa-2x`}
                            />
                          ) : (
                            updated_by
                          )}{" "}
                        </td>
                        <td
                          onClick={() =>
                            showModalWithData(workOrderDetailsData[index])
                          }
                        >
                          {moment(updated_at).format("DD/MM/YYYY HH:mm")}
                        </td>
                        <td
                          onClick={() =>
                            handleCommentTracking(
                              comment_tracking,
                              user_id,
                              first_name,
                              last_name
                            )
                          }
                        >
                          {work_order_type === "deposit" &&
                            comment_tracking &&
                            comment_tracking.length > 0 && (
                              <p className={"text-sm"}>
                                <b>{comment_tracking[0].comment}</b>
                              </p>
                            )}
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              comment_tracking ? (
                                <Tooltip id={`tooltip-comment-tracking`}>
                                  {
                                    comment_tracking[
                                      comment_tracking.length - 1
                                    ].comment
                                  }
                                </Tooltip>
                              ) : (
                                <Tooltip id={`tooltip-comment-tracking2`}>
                                  {"-"}
                                </Tooltip>
                              )
                            }
                          >
                            <p
                              className={`text-sm ${
                                status === "success" &&
                                credit_status === "success"
                                  ? "text-primary"
                                  : "text-danger"
                              }`}
                            >
                              <i className="fas fa-exclamation-triangle mr-1" />
                              <b>ดูเพิ่มเติม</b>
                            </p>
                          </OverlayTrigger>
                        </td>
                        <td>
                          {display_status === "reserve"
                            ? display_reserved_by
                            : "ว่าง"}
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </Table>
              <Form.Row>
                <Pagination className={"ml-auto"}>
                  {/*<Pagination.First/>*/}
                  <Pagination.Prev onClick={handlePrevPage} />
                  <Pagination.Item active={true}>{currentPage}</Pagination.Item>
                  <Pagination.Next onClick={handleNextPage} />
                  {/*<Pagination.Last onClick={setCurrentPage(currentPage + 1)}/>*/}
                </Pagination>
              </Form.Row>
            </Form>
          </Card.Body>
        </Card>
      </div>
    </>
  );
}
