import axios from '../client/axios'
import config from '../config/index'

export default {
  SMS_READER: (keyword) => {
    //console.log("request sms reader: ", keyword)
    return axios({
      //headers: {},
      method: 'POST',
      url: `${config.SMS_READER}`,
      data: keyword
    })
  }
}