import React, { useEffect, useLayoutEffect, useState } from "react";
import {
  Badge,
  Button,
  Card,
  OverlayTrigger,
  Pagination,
  Spinner,
  Table,
  Tooltip,
} from "react-bootstrap";
import NumberFormat from "react-number-format";
import WorkOrderModal from "./WorkOrderModal";
import { useGlobalStore } from "../../store/globalContext";
import { LogoWithAccountNo2 } from "../banks_logo/index";
import moment from "moment";
import api_workorder from "../../api/workorder";
import ModalCommentTracking from "./ModalCommentTracking";
import localStorage from "../../store/localStorage";

const username = localStorage.getUsername();

export default function WorkOrderDetails({
  data,
  ConfirmActionModal,
  enableModalByMode,
  modalConfirmShow,
  setModalConfirmShow,
  selectFromBank,
}) {
  const [{ work_order_detail, status }, dispatch] = useGlobalStore();
  const {
    cardHeader,
    total_work_order_today,
    total_amount_today,
    current_amount,
    bank_balance,
    work_order_type,
    work_order_sub_type,
  } = { ...data };
  const [showWorkOrderModal, setShowWorkOrderModal] = useState(false);
  const [dataTable, setDataTable] = useState([]);

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const limitPage = 30;
  const [dictLastIndices, setDictLastIndices] = useState({ 1: "" });

  const handlePrevPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (!dataTable) {
      return;
    }

    if (dataTable.length === limitPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  // Comment Tracking
  const [showCommentTrackingModal, setShowCommentTrackingModal] =
    useState(false);
  const [commentTracking, setCommentTracking] = useState({
    comment_tracking: [],
    user_id: "",
    first_name: "",
    last_name: "",
  });

  const handleCommentTracking = (
    commentTracking,
    userId,
    firstName,
    lastName,
    comment
  ) => {
    if (!commentTracking) {
      const commentTrackingDummy = [
        {
          updated_at: "",
          updated_by: "",
          comment: comment,
        },
      ];

      setCommentTracking({
        comment_tracking: commentTrackingDummy,
        user_id: userId,
        first_name: firstName,
        last_name: lastName,
      });
    } else {
      setCommentTracking({
        comment_tracking: commentTracking,
        user_id: userId,
        first_name: firstName,
        last_name: lastName,
      });
    }

    setShowCommentTrackingModal(true);
  };

  const handleCloseCommentTracking = () => {
    setShowCommentTrackingModal(false);
    setTimeout(() => {
      setCommentTracking({
        comment_tracking: [],
      });
    }, 200);
  };

  const getWorkOrderListByType = async (action) => {
    const keyword = {
      date: moment().format("YYYY-MM-DD"),
      work_order_type: work_order_type,
      work_order_sub_type: work_order_sub_type,
    };
    // console.log("getWorkOrderListByType keyword: ", keyword)

    let response;

    if (work_order_type === "bonus") {
      if (work_order_sub_type === "lost") {
        if (action === "reload") {
          //console.log( "reload" )
          setCurrentPage(1);
          const newIndex = { ...dictLastIndices, 1: "" };
          setDictLastIndices(newIndex);
        }

        const keywordBonus = {
          date: moment().format("YYYY-MM-DD"),
          work_order_type: work_order_type,
          work_order_sub_type: work_order_sub_type,
          limit: limitPage,
          last_element_id: dictLastIndices[currentPage],
        };

        //console.log( keywordBonus )
        response = await api_workorder.WORK_ORDER_SEARCH_TYPE(keywordBonus);
      } else {
        const keyword2000Limit = {
          date: moment().format("YYYY-MM-DD"),
          work_order_type: work_order_type,
          work_order_sub_type: work_order_sub_type,
          limit: 3000,
        };
        response = await api_workorder.WORK_ORDER_SEARCH_TYPE(keyword2000Limit);
      }
    } else {
      response = await api_workorder.WORK_ORDER_SEARCH_INCOMPLETE(keyword);
    }

    if (response.status !== 200) {
      console.log(`create work_order_deposit_failed`, response.message);
      return;
    }
    // console.log("getWorkOrderListByType response.data: ", response.data)
    if (response.data.data === null || response.data.data === undefined) {
      setDataTable([]);
      return;
    }

    const initData = response.data.data;
    //console.log( initData )
    if (initData) {
      const newIndex = {
        ...dictLastIndices,
        [currentPage + 1]: initData[initData.length - 1].id,
      };
      setDictLastIndices(newIndex);
    }
    let initDataList = [];
    //console.log("getWorkOrderListByType initData: ", initData)
    initData.map(
      (
        {
          id,
          channel,
          customer_id,
          created_at,
          user_id,
          first_name,
          last_name,
          from_bank_short_name,
          from_bank_name,
          from_bank_account_no,
          to_bank_short_name,
          to_bank_name,
          to_bank_account_no,
          amount,
          status,
          updated_at,
          comment,
          before_balance,
          after_balance,
          updated_by,
          work_order_type,
          qr_ref,
        },
        index
      ) => {
        let newData = {
          id: id,
          customer_id: customer_id,
          created_at: created_at,
          user_id: user_id,
          first_name: first_name,
          last_name: last_name,
          from_bank_short_name: from_bank_short_name,
          from_bank_name: from_bank_name,
          from_bank_account_no: from_bank_account_no,
          to_bank_short_name: to_bank_short_name,
          to_bank_name: to_bank_name,
          to_bank_account_no: to_bank_account_no,
          amount: amount,
          status: status,
          updated_at: updated_at,
          updated_by: updated_by,
          comment: comment,
          before_balance: before_balance,
          after_balance: after_balance,
          work_order_type: work_order_type,
          qr_ref: qr_ref,
        };
        initDataList.push(newData);
      }
    );

    setDataTable(initData);
    //console.log(dataTable);
  };

  useEffect(() => {
    getWorkOrderListByType();
  }, [currentPage]);

  useLayoutEffect(() => {
    //console.log("work_order_detail update: ", work_order_detail);
    if (work_order_detail === undefined) {
      return;
    }
    //console.log( "work_order_detail ========> ", work_order_detail )
    const {
      id,
      customer_id,
      created_at,
      user_id,
      first_name,
      last_name,
      from_bank_short_name,
      from_bank_name,
      from_bank_account_no,
      to_bank_short_name,
      to_bank_name,
      to_bank_account_no,
      amount,
      status,
      updated_at,
      comment,
      comment_tracking,
      before_balance,
      after_balance,
      credit_status,
      user_ids,
      updated_by,
      display_status,
      to_bank_account_name,
      mapping_manual_success,
      work_order_type,
      qr_ref,
    } = work_order_detail;
    const newData = {
      id: id,
      customer_id: customer_id,
      created_at: created_at,
      user_id: user_id,
      first_name: first_name,
      last_name: last_name,
      from_bank_short_name: from_bank_short_name,
      from_bank_name: from_bank_name,
      from_bank_account_no: from_bank_account_no,
      to_bank_short_name: to_bank_short_name,
      to_bank_name: to_bank_name,
      to_bank_account_no: to_bank_account_no,
      amount: amount,
      status: status,
      updated_at: updated_at,
      comment: comment,
      comment_tracking: comment_tracking,
      before_balance: before_balance,
      after_balance: after_balance,
      credit_status: credit_status,
      user_ids: user_ids,
      updated_by: updated_by,
      display_status: display_status,
      to_bank_account_name: to_bank_account_name,
      mapping_manual_success: mapping_manual_success,
      work_order_type: work_order_type,
      qr_ref: qr_ref,
    };
    if (work_order_detail.state === "insert") {
      if (work_order_type === "bonus" && work_order_sub_type === "lost") {
        if (dataTable.length === limitPage) {
          dataTable.pop();
        }
        setDataTable([newData, ...dataTable]);
        setCurrentPage(1);
        const newIndex = {
          ...dictLastIndices,
          [currentPage + 1]: dataTable[dataTable.length - 1].id,
        };
        setDictLastIndices(newIndex);
      } else {
        setDataTable([newData, ...dataTable]);
      }
      return;
    }
    if (
      work_order_detail.state === "update" ||
      work_order_detail.state === "deleted"
    ) {
      const elementsIndex = dataTable.findIndex(
        (work_order_detail) => work_order_detail.id === id
      );
      let newArray = [...dataTable];
      newArray[elementsIndex] = work_order_detail;
      setDataTable(newArray);

      // if (credit_status === "success"){
      //     setTimeout(async ()=>deleteSuccessByInterval(), 3000)
      //     let removeList = [...removeArray]
      //     removeList.push(work_order_detail.id)
      //     setRemoveArray(removeList)
      // }
    }
  }, [work_order_detail]);

  const renderSwitch = (props) => {
    //console.log("renderSwitch props:", props)
    const [status, id, reason, dataTable] = props;
    const credit_status = dataTable.credit_status;
    const display_status = dataTable.display_status;
    //console.log("display_status :", display_status)

    switch (status) {
      case "success":
        return (
          <td onClick={() => showModalWithData(dataTable)}>
            <h5>
              <Badge variant="success">{"สำเร็จ"}</Badge>
            </h5>
          </td>
        );
      case "failed":
        return (
          <td onClick={() => showModalWithData(dataTable)}>
            <h5>
              <OverlayTrigger
                placement="top"
                overlay={
                  reason ? (
                    <Tooltip id={`tooltip-status`}>{reason}</Tooltip>
                  ) : (
                    <div></div>
                  )
                }
              >
                <Badge variant="danger">{"ไม่สำเร็จ"}</Badge>
              </OverlayTrigger>
            </h5>
          </td>
        );
      //return <td onClick={() => showModalWithData(dataTable)}><h5 title={reason}><Badge variant="danger">{"ไม่สำเร็จ"}</Badge> </h5></td>;
      case "deleted":
        return (
          <td onClick={() => showModalWithData(dataTable)}>
            <h5>
              <Badge variant="warning">{"ยกเลิก"}</Badge>
            </h5>
          </td>
        );
      case "mapping":
        return (
          <td onClick={() => showModalWithData(dataTable)}>
            <h5>
              <Badge variant="primary">{"กรุณาจับคู่รายการ"}</Badge>
            </h5>
          </td>
        );
      case "pending":
        // console.log("work_order_type :", work_order_type, "status: ", status)
        if (work_order_type === "withdraw") {
          if (credit_status === "unknown") {
            return (
              <td onClick={() => showModalWithData(dataTable)}>
                <Badge variant="dark">กรุณาตรวจสอบรายการ</Badge>
              </td>
            );
          }
          if (credit_status === "failed") {
            return (
              <td onClick={() => showModalWithData(dataTable)}>
                <Badge variant="dark">กรุณาตรวจสอบรายการ</Badge>
              </td>
            );
          }

          return (
            <td onClick={() => showModalWithData(dataTable)}>
              <Badge variant="info">
                <p>กรุณาตรวจสอบ</p>
                <p>และยืนยันรายการ</p>
              </Badge>
            </td>
          );

          // return <td style={{minWidth: "150px"}}>
          //   <Button className="btn-success" size="sm" onClick={() => enableModalByMode({
          //     header: "ยืนยันรายการอนุมัติ",
          //     headerclassName: "btn-success",
          //     bodyTitle: "ยืนยันการอนุมติ",
          //     placeholder: "eg. ข้อมูลเครดิตถูกต้อง",
          //     api_type: "approve",
          //     id: id
          //   })} disabled={display_status === "reserve"}><i className={`nav-icon fas fa-check`}/></Button>
          //   {' '}
          //   <Button className="btn-warning" size="sm" onClick={() => enableModalByMode({
          //     header: "ยืนยันรายการแก้ไข",
          //     headerclassName: "btn-warning",
          //     bodyTitle: "ยืนยันการทำ Manual",
          //     placeholder: "eg. บันทึกข้อมูลในระบบไปแล้ว",
          //     api_type: "approve_manual",
          //     id: id
          //   })} disabled={display_status === "reserve"}><i className={`nav-icon fas fa-cog`}/></Button>
          //   {' '}
          //   <Button className="btn-danger" size="sm" onClick={() => enableModalByMode({
          //     header: "ยืนยันรายการยกเลิก",
          //     headerclassName: "btn-danger",
          //     bodyTitle: "ยืนยันการยกเลิก",
          //     placeholder: "eg. ยกเลิกเนื่องจากไม่มีการทำธุรกรรม",
          //     api_type: "reject",
          //     id: id
          //   })} disabled={display_status === "reserve"}><i className={`nav-icon fas fa-ban`}/></Button>
          // </td>
        } else {
          return (
            <td onClick={() => showModalWithData(dataTable)}>
              <Badge variant="warning">{"เลือกเอเย่นต์"}</Badge>
            </td>
          );
        }
      case "unknown":
        return (
          <td onClick={() => showModalWithData(dataTable)}>
            <Badge variant="dark">กรุณาตรวจสอบรายการ</Badge>
          </td>
        );
      default:
        return (
          <td onClick={() => showModalWithData(dataTable)}>
            <Spinner animation="grow" variant="info" />
          </td>
        );
    }
  };

  const [editModalData, setEditModalData] = useState();
  const showModalWithData = (props) => {
    const { id, display_status, display_reserved_by } = props;
    //console.log("username: ", username)
    // console.log("showModalWithData: ", props)
    //console.log("display_reserved_by: ", display_reserved_by)
    if (display_status === "reserve" && display_reserved_by !== username) {
      return;
    }
    updateReserveWorkOrder(id, "reserve");
    setEditModalData(props);
    setShowWorkOrderModal(true);
  };

  const handleFontColorUserID = (customerComment) => {
    if (customerComment) {
      return { color: "red" };
    } else {
      return {};
    }
  };

  const handleUserIDPending = (
    index,
    userId,
    firstName,
    lastName,
    creditStatus,
    customerComment
  ) => {
    if (creditStatus === "pending") {
      return (
        <>
          <td onClick={() => showModalWithData(dataTable[index])}>
            <div>-</div>
            <div>
              <p>
                {firstName || "-"} {lastName || "-"}
              </p>
            </div>
          </td>
          {/*<td onClick={ () => showModalWithData(dataTable[index]) }>{ firstName || "-" } { lastName || "-" }</td>*/}
        </>
      );
    } else {
      return (
        <>
          <td
            style={handleFontColorUserID(customerComment)}
            onClick={() => showModalWithData(dataTable[index])}
          >
            <div>
              <OverlayTrigger
                placement="top"
                overlay={
                  customerComment ? (
                    <Tooltip id={`tooltip-status`}>{customerComment}</Tooltip>
                  ) : (
                    <div></div>
                  )
                }
              >
                <h5 className="text-bold">{userId || "-"}</h5>
              </OverlayTrigger>
            </div>
            <div>
              <p>
                {firstName || "-"} {lastName || "-"}
              </p>
            </div>
          </td>
          {/*<td onClick={ () => showModalWithData(dataTable[index]) }>{ firstName || "-" } { lastName || "-" }</td>*/}
        </>
      );
    }
  };

  const styleBankLogoWorkOrderType = (accountType) => {
    if (
      (accountType === "sender" && work_order_type === "withdraw") ||
      (accountType === "receiver" && work_order_type === "deposit")
    ) {
      return { minWidth: "215px" };
    } else {
      return {};
    }
  };

  const trColorReserve = (
    display_status,
    user_id,
    created_by,
    mapping_manual_success,
    work_order_type
  ) => {
    //console.log("trColorReserve :", display_status, user_id, created_by)
    if (user_id === created_by) {
      return "table-info";
    }

    if (display_status === "reserve") {
      return "table-warning";
    }

    if (work_order_type === "deposit" && mapping_manual_success) {
      return "table-secondary";
    }
    return "";
  };

  const updateReserveWorkOrder = async (id, status) => {
    const request = {
      id: id,
      display_status: status,
    };

    const response = await api_workorder.WORK_ORDER_UPDATE_NO_RETRY(request);

    if (response.status !== 200) {
      console.log(`create work_order_deposit_failed`, response.message);
    }
  };

  const closeModalWithData = (id, status) => {
    updateReserveWorkOrder(id, "");
    setShowWorkOrderModal(false);
  };

  return (
    <>
      <div>
        <WorkOrderModal
          action="edit"
          workOrderType={work_order_type}
          modalHeader="แก้ไขใบงาน"
          buttonSubmit="แก้ไขข้อมูลใบงาน"
          data={editModalData}
          show={showWorkOrderModal}
          selectFromBank={selectFromBank}
          closeUpdateModal={() => closeModalWithData(editModalData.id, "")}
        />
      </div>
      <div>
        {ConfirmActionModal && (
          <ConfirmActionModal
            show={modalConfirmShow}
            onHide={() => setModalConfirmShow(false)}
          />
        )}
      </div>
      <div>
        <ModalCommentTracking
          showModal={showCommentTrackingModal}
          data={commentTracking}
          onClose={handleCloseCommentTracking}
        />
      </div>
      <div className="col-md-12 col-lg-12 pt-4 pr-4 pl-4">
        <Card className="card-info">
          <Card.Header>
            {cardHeader}{" "}
            <Button
              variant={"outline-light"}
              size={"sm"}
              style={{ borderRadius: "50px" }}
              onClick={() => getWorkOrderListByType("reload")}
            >
              <i className="fas fa-sync-alt" />
            </Button>
          </Card.Header>
          {work_order_type === "bonus" && work_order_sub_type === "lost" ? (
            <Pagination className={"ml-auto mt-3"}>
              <Pagination.Prev onClick={handlePrevPage} />
              <Pagination.Item active={true}>{currentPage}</Pagination.Item>
              <Pagination.Next onClick={handleNextPage} />
            </Pagination>
          ) : (
            <></>
          )}
          <Card.Body className="row ">
            <Table hover responsive className="table-valign-middle">
              <thead className="text-center justify-content-between">
                <tr>
                  <th style={{ width: "1%" }}>#</th>
                  <th style={{ width: "5%" }}>วันที่/เวลา</th>
                  {/* <th>เวลา</th> */}
                  {work_order_type !== "sweep" ? (
                    <>
                      <th>UserID</th>
                      {/*<th>ชื่อ-นามสกุล</th>*/}
                    </>
                  ) : (
                    <> </>
                  )}
                  <th>บัญชีโอน</th>
                  <th>บัญชีรับ</th>
                  <th>จำนวนเงิน</th>
                  <th style={{ width: "2%" }}>สถานะ</th>
                  {work_order_type !== "sweep" ? (
                    <>
                      <th style={{ width: "2%" }}>สถานะ credit</th>
                      <th style={{ width: "4%" }}>Credit ก่อนหน้า</th>
                      <th style={{ width: "4%" }}>Credit ล่าสุด</th>
                    </>
                  ) : (
                    <> </>
                  )}
                  <th>ผู้ทำรายการ</th>
                  <th style={{ width: "12%" }}>หมายเหตุ</th>
                  <th>สถานะใบงาน</th>
                </tr>
              </thead>
              <tbody className="text-center justify-content-start">
                {dataTable?.map(
                  (
                    {
                      id,
                      customer_id,
                      created_at,
                      user_id,
                      first_name,
                      last_name,
                      from_bank_short_name,
                      from_bank_name,
                      from_bank_account_no,
                      to_bank_short_name,
                      to_bank_name,
                      to_bank_account_no,
                      amount,
                      status,
                      credit_status,
                      updated_at,
                      updated_by,
                      comment,
                      comment_tracking,
                      status_reason,
                      credit_reason,
                      before_balance,
                      after_balance,
                      display_status,
                      created_by,
                      display_reserved_by,
                      customer_comment,
                      to_bank_account_name,
                      mapping_manual_success,
                      work_order_type,
                    },
                    index
                  ) => (
                    <tr
                      key={id + created_at}
                      className={trColorReserve(
                        display_status,
                        user_id,
                        created_by,
                        mapping_manual_success,
                        work_order_type
                      )}
                      style={{ cursor: "pointer" }}
                    >
                      <td onClick={() => showModalWithData(dataTable[index])}>
                        {index + 1}
                      </td>
                      <td onClick={() => showModalWithData(dataTable[index])}>
                        <p>{moment(created_at).format("DD/MM/YYYY HH:mm")}</p>
                      </td>
                      {work_order_type !== "sweep" ? (
                        handleUserIDPending(
                          index,
                          user_id,
                          first_name,
                          last_name,
                          credit_status,
                          customer_comment
                        )
                      ) : (
                        <> </>
                      )}
                      <td
                        style={styleBankLogoWorkOrderType("sender")}
                        onClick={() => showModalWithData(dataTable[index])}
                      >
                        <div
                          style={{
                            "justify-content": "start",
                            display: "inline",
                            width: "15%",
                          }}
                        >
                          <LogoWithAccountNo2
                            bankShortName={from_bank_short_name}
                            bankFullName={from_bank_name}
                            bankName={from_bank_name}
                            accountNo={from_bank_account_no}
                            workOrderType={work_order_type}
                            workOrderSubType={work_order_sub_type}
                            column={"1"}
                          />
                        </div>
                      </td>
                      <td
                        style={styleBankLogoWorkOrderType("receiver")}
                        onClick={() => showModalWithData(dataTable[index])}
                      >
                        <div
                          style={{
                            "justify-content": "start",
                            display: "inline",
                            width: "15%",
                          }}
                        >
                          <LogoWithAccountNo2
                            bankShortName={to_bank_short_name}
                            bankFullName={to_bank_name}
                            bankName={to_bank_account_name}
                            accountNo={to_bank_account_no}
                            workOrderType={work_order_type}
                            workOrderSubType={work_order_sub_type}
                            column={"2"}
                          />
                        </div>
                      </td>
                      <td onClick={() => showModalWithData(dataTable[index])}>
                        <NumberFormat
                          thousandSeparator={true}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          value={amount}
                          displayType="text"
                          className="text-bold"
                        />
                      </td>
                      {renderSwitch([
                        status,
                        id,
                        status_reason,
                        dataTable[index],
                      ])}
                      {work_order_type !== "sweep" ? (
                        <>
                          {renderSwitch([
                            credit_status,
                            id,
                            credit_reason,
                            dataTable[index],
                          ])}
                          <td>
                            {before_balance ? (
                              <NumberFormat
                                thousandSeparator={true}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                value={before_balance}
                                displayType="text"
                              />
                            ) : (
                              "-"
                            )}
                          </td>
                          <td>
                            {after_balance ? (
                              <NumberFormat
                                thousandSeparator={true}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                value={after_balance}
                                displayType="text"
                              />
                            ) : (
                              "-"
                            )}
                          </td>
                        </>
                      ) : (
                        <> </>
                      )}
                      <td>
                        {updated_by === "system_auto" ? (
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id={`tooltip-update-at`}>
                                อัพเดท{" "}
                                {moment(updated_at).format("DD/MM/YYYY HH:mm")}
                              </Tooltip>
                            }
                          >
                            <i
                              className={`nav-icon fas fa-laptop-code fa-2x`}
                            />
                          </OverlayTrigger>
                        ) : (
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id={`tooltip-update-at`}>
                                อัพเดท{" "}
                                {moment(updated_at).format("DD/MM/YYYY HH:mm")}
                              </Tooltip>
                            }
                          >
                            <p>{updated_by}</p>
                          </OverlayTrigger>
                        )}{" "}
                      </td>
                      <td
                        onClick={() =>
                          handleCommentTracking(
                            comment_tracking,
                            user_id,
                            first_name,
                            last_name,
                            comment
                          )
                        }
                      >
                        {work_order_type === "deposit" &&
                          comment_tracking &&
                          comment_tracking.length > 0 && (
                            <p className={"text-sm"}>
                              <b>{comment_tracking[0].comment}</b>
                            </p>
                          )}
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            comment_tracking ? (
                              <Tooltip id={`tooltip-comment-tracking`}>
                                {
                                  comment_tracking[comment_tracking.length - 1]
                                    .comment
                                }
                              </Tooltip>
                            ) : (
                              <Tooltip id={`tooltip-comment-tracking2`}>
                                {comment}
                              </Tooltip>
                            )
                          }
                        >
                          <p
                            className={`text-sm ${
                              status === "success" &&
                              credit_status === "success"
                                ? "text-primary"
                                : "text-danger"
                            }`}
                          >
                            <i className="fas fa-exclamation-triangle mr-1" />
                            <b>ดูเพิ่มเติม</b>
                          </p>
                        </OverlayTrigger>
                      </td>
                      <td>
                        {display_status === "reserve"
                          ? display_reserved_by
                          : "ว่าง"}
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </div>
    </>
  );
}
