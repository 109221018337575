import React, { useState, useEffect } from "react";

export function SmallStatsCard(props) {
  const { icon, label, value, type } = props;

  return (
    <>
      <div className="info-box">
        <span className={`info-box-icon ${type}`}>
          <i className={icon} />
        </span>
        <div className="info-box-content">
          <span className="info-box-text">{label}</span>
          <span className="info-box-number">{value}</span>
        </div>
      </div>
    </>
  );
}

export function InfoBoxBGStatsCard(props) {
  const { icon, label, unit, value, description, type } = props;

  return (
    <>
      {/*<div className="col-lg-3 col-6">*/}
      <div className={`small-box ${type}`}>
        {value === undefined && (
          <div className="overlay">
            <i className="fas fa-3x fa-sync-alt"></i>
          </div>
        )}
        <div className="inner">
          {/* <p> */}
          <h3>
            {value} {unit}
          </h3>
          {/* </p> */}
          <p>{label}</p>
        </div>
        <div className="icon">
          <i className={icon} />
        </div>
        <div className="small-box-footer">{description}</div>
      </div>
      {/*</div>*/}
    </>
  );
}
