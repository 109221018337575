import React from 'react'
import { Alert, Modal } from 'react-bootstrap'
import EditProspectCustomer from './EditProspectCustomer'

export default function ModalSearchProspectCustomer ( props ) {
  const { customerData, isOpenModal, onSave, onClose, callBackSearch, alertError } = props

  return (
    <>
      <Modal show={isOpenModal} size="xl">
        <Modal.Header>
          <Modal.Title>{customerData?.first_name} {customerData?.last_name} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            ( alertError !== "" ) ?
              ( <Alert variant={"danger"}>{alertError}</Alert> ) : ( <></> )
          }
          <EditProspectCustomer data={customerData} onSubmitForm={onSave} onClose={onClose} alertError={alertError}/>
        </Modal.Body>
      </Modal>
    </>
  )
}