import axios from '../client/axios'
import config from '../config/index'

export default {
    SEARCH_CONFIG: ( data ) => {
        return axios( {
            //headers: {},
            method: 'POST',
            url: `${config.SEARCH_CONFIG}`,
            data: data,
        } )
    },
    UPDATE_CONFIG: ( data ) => {
        return axios( {
            //headers: {},
            method: 'POST',
            url: `${config.UPDATE_CONFIG}`,
            data: data,
        } )
    },
    GET_KPLUS_GEN_QR: () => {
        return axios( {
            //headers: {},
            method: 'GET',
            url: `${config.GET_KPLUS_GEN_QR}`
        } )
    },
}