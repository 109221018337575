import React from "react";

export default function Footer() {

    return (
        <footer className="main-footer">
            <strong>Power by Fever Team.</strong>
            &nbsp; All rights reserved.
            <div className="float-right d-none d-sm-inline-block">
                <b>Version</b> Release 2.0.1
  </div>
        </footer>

    );
}