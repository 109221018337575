import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  FormControl,
  InputGroup,
  Modal,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import { useAsyncDebounce, useGlobalFilter, useTable } from "react-table";
import moment from "moment";
import api from "../../api/dashboard";

export default function ChannelChart() {
  const ed = new Date();
  const std = new Date().setDate(ed.getDate() - 15);
  const [RegisterChannelStartDate, setRegisterChannelStartDate] = useState(
    moment(std).format("YYYY-MM-DD")
  );
  const [RegisterChannelEndDate, setRegisterChannelEndDate] = useState(
    moment(ed).format("YYYY-MM-DD")
  );
  const [loadingRegisterChannel, setLoadingRegisterChannel] = useState(false);
  const [registerChannelData, setRegisterChannelData] = useState([
    {
      agent_name: "-",
      channel_summary: [{ channel_select: "-", total: "-" }],
      money_site_summary: [{ money_site_name: "-", total: "-" }],
    },
  ]);
  const [registerUserIdModal, setRegisterUserIdModal] = useState({
    show: false,
    data: [],
  });
  const [error, setError] = useState();

  const getRegisterChannel = async () => {
    setLoadingRegisterChannel(true);
    setError("");
    try {
      const request = {
        start: RegisterChannelStartDate,
        end: RegisterChannelEndDate,
      };
      const response = await api.DASHBOARD_CUSTOMER_CHANNEL(request);

      const { data } = response.data;
      //   console.log("get register channel : ", data);
      if (data === null) {
        setError("ไม่มีข้อมูล");
        setLoadingRegisterChannel(false);
        return;
      }
      setRegisterChannelData(data);
      setLoadingRegisterChannel(false);
    } catch (error) {
      console.log("get register channel error: ", error.message);
      setLoadingRegisterChannel(false);
      setError("ไม่สามารถค้นหาข้อมูลได้");
    }
  };

  const showUserIdList = (channelName, data, summaryType) => {
    console.log("showUserIdList data ", data);
    setRegisterUserIdModal({
      ...registerUserIdModal,
      show: true,
      channelName: channelName,
      data: data,
      summaryType: summaryType,
    });
  };

  useEffect(() => {
    getRegisterChannel();
  }, []);

  return loadingRegisterChannel ? (
    <Row sm={12} md={12} lg={12}>
      <Col className="text-center">
        <Spinner animation="grow" variant="danger" />
      </Col>
    </Row>
  ) : (
    <>
      <ChannelUserIdModal
        showFlag={registerUserIdModal.show}
        channelName={registerUserIdModal.channelName}
        summaryType={registerUserIdModal.summaryType}
        userId={registerUserIdModal.data}
        closeModal={() =>
          setRegisterUserIdModal({
            show: false,
            data: [],
          })
        }
      />
      <Row>
        <Form.Row>
          <Col sm={5} md={5} lg={5}>
            <Form.Label htmlFor="inlineFormInputGroupStartDate" srOnly>
              2020-09-21
            </Form.Label>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text>Start-Date</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                type="date"
                id="RegisterChannelStartDate"
                placeholder="2020-09-10"
                onChange={(e) =>
                  setRegisterChannelStartDate(
                    moment(e.target.value).format("YYYY-MM-DD")
                  )
                }
                defaultValue={RegisterChannelStartDate}
              />
            </InputGroup>
          </Col>
          <Col sm={5} md={5} lg={5}>
            <Form.Label htmlFor="inlineFormInputGroupEndDate" srOnly>
              2020-09-21
            </Form.Label>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text>End-Date</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                type="date"
                id="RegisterChannelEndDate"
                placeholder="2020-09-21"
                onChange={(e) =>
                  setRegisterChannelEndDate(
                    moment(e.target.value).format("YYYY-MM-DD")
                  )
                }
                defaultValue={RegisterChannelEndDate}
              />
            </InputGroup>
          </Col>
          <Col xs="auto">
            {loadingRegisterChannel ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />{" "}
                กำลังค้นหา
              </>
            ) : (
              <Button type="button" onClick={() => getRegisterChannel()}>
                ค้นหาข้อมูล
              </Button>
            )}
          </Col>
        </Form.Row>
      </Row>
      {error ? (
        <p className="font-bold text-xl mt-4">{error}</p>
      ) : (
        <>
          <Row>
            <p className="font-bold text-base mt-4 ml-2">การสมัครตามช่องทาง</p>
            <Col lg={12} className="text-center pt-4">
              <Table>
                <thead>
                  <tr data-aos="fade-down">
                    <th>ชื่อ Agents</th>
                    <th>ช่องทาง</th>
                    <th>จำนวนคนสมัคร</th>
                    {/*<th>Money Site</th>*/}
                    <th>รายชื่อ(No CC.)</th>
                  </tr>
                </thead>
                <tbody>
                  {registerChannelData.map((dataset, index) =>
                    dataset.channel_summary ? (
                      dataset.channel_summary.map((data, index2) => (
                        <tr key={index2}>
                          <td>{dataset.agent_name}</td>
                          {data?.channel_select === "" ||
                          data?.channel_select === "เลือกช่องทาง" ||
                          !data?.channel_select ? (
                            <td>ไม่ระบุ</td>
                          ) : (
                            <td>{data.channel_select}</td>
                          )}
                          <td>{data.total}</td>
                          <td>
                            <Button
                              variant={"outline-primary"}
                              onClick={() =>
                                showUserIdList(
                                  data.channel_select,
                                  data.user_ids,
                                  "channel"
                                )
                              }
                            >
                              <i className="fas fa-search" />
                            </Button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <></>
                    )
                  )}
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row>
            <p className="font-bold text-base mt-4 ml-2">
              การสมัครตาม Money Site
            </p>
            <Col lg={12} className="text-center pt-4">
              <Table>
                <thead>
                  <tr data-aos="fade-down">
                    <th>ชื่อ Agents</th>
                    <th>ชื่อ Money Site</th>
                    <th>จำนวนคนสมัคร</th>
                    <th>รายชื่อ(No CC.)</th>
                  </tr>
                </thead>
                <tbody>
                  {registerChannelData.map((dataset, index) =>
                    dataset.money_site_summary ? (
                      dataset.money_site_summary.map((data, index2) => (
                        <tr key={index2}>
                          <td>{dataset.agent_name}</td>
                          {data?.money_site_name === "" ||
                          !data?.money_site_name ? (
                            <td>ไม่ระบุ</td>
                          ) : (
                            <td>{data.money_site_name}</td>
                          )}
                          <td>{data.total}</td>
                          <td>
                            <Button
                              variant={"outline-primary"}
                              onClick={() =>
                                showUserIdList(
                                  data.money_site_name,
                                  data.user_ids,
                                  "money_site"
                                )
                              }
                            >
                              <i className="fas fa-search" />
                            </Button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <></>
                    )
                  )}
                </tbody>
              </Table>
            </Col>
          </Row>
        </>
      )}
    </>
  );
}

export function ChannelUserIdModal(props) {
  const { showFlag, channelName, summaryType, userId, closeModal } = props;
  const [show, setShow] = useState(false);

  const data = React.useMemo(() => {
    // console.log( "MEMO userId :", userId )
    let result = [];
    userId.map((data) => {
      summaryType === "channel"
        ? result.push({
            col1: data.user_id,
            col2: data.first_name + " " + data.last_name,
            col3: data?.money_site_name ? data?.money_site_name : "ไม่ระบุ",
            col4: channelName ? channelName : "ไม่ระบุ",
          })
        : result.push({
            col1: data.user_id,
            col2: data.first_name + " " + data.last_name,
            col3: channelName ? channelName : "ไม่ระบุ",
            col4: data?.channel_select ? data?.channel_select : "ไม่ระบุ",
          });
    });
    return result;
  }, [userId]);

  const columns = React.useMemo(
    () => [
      {
        Header: "User Id",
        accessor: "col1", // accessor is the "key" in the data
      },
      {
        Header: "ชื่อ-นามสกุล",
        accessor: "col2", // accessor is the "key" in the data
      },
      {
        Header: "Money Site",
        accessor: "col3",
      },
      {
        Header: "Channel",
        accessor: "col4",
      },
    ],
    []
  );

  // const tableInstance = useTable({ columns, data })
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable({ columns, data }, useGlobalFilter);

  const handleClose = () => {
    setShow(false);
    closeModal();
  };

  const GlobalFilter = ({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
  }) => {
    const count = preGlobalFilteredRows.length;
    const [value, setValue] = useState(globalFilter);
    const onChange = useAsyncDebounce((value) => {
      setGlobalFilter(value || undefined);
    }, 200);

    return (
      <Form>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label> ค้นหา UserId: </Form.Label>
          <Form.Control
            type="text"
            placeholder={`พบทั้งหมด ${count} รายการ`}
            value={value || ""}
            autoFocus={true}
            onChange={(e) => {
              setValue(e.target.value);
              onChange(e.target.value);
            }}
          />
        </Form.Group>
      </Form>
    );
  };

  useEffect(() => {
    // console.log(" ChannelUserIdModal show ", show);
    setShow(showFlag);
  }, [showFlag]);

  return (
    <Modal show={show} onHide={handleClose} size={"lg"}>
      <Modal.Header closeButton>
        <Modal.Title>
          รายชื่อผู้สมัครจาก
          {summaryType === "channel" ? "ช่องทาง" : "Money Site"}{" "}
          {channelName ? channelName : "ไม่ระบุ"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={state.globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
        <Table {...getTableProps()}>
          <thead>
            {
              // Loop over the header rows
              headerGroups.map((headerGroup) => (
                // Apply the header row props
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {
                    // Loop over the headers in each row
                    headerGroup.headers.map((column) => (
                      // Apply the header cell props
                      <th {...column.getHeaderProps()}>
                        {
                          // Render the header
                          column.render("Header")
                        }
                      </th>
                    ))
                  }
                </tr>
              ))
            }
          </thead>
          {/* Apply the table body props */}
          <tbody {...getTableBodyProps()}>
            {
              // Loop over the table rows
              rows.map((row) => {
                // Prepare the row for display
                prepareRow(row);
                return (
                  // Apply the row props
                  <tr {...row.getRowProps()}>
                    {
                      // Loop over the rows cells
                      row.cells.map((cell) => {
                        // Apply the cell props
                        return (
                          <td {...cell.getCellProps()}>
                            {
                              // Render the cell contents
                              cell.render("Cell")
                            }
                          </td>
                        );
                      })
                    }
                  </tr>
                );
              })
            }
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
}
