import axios from '../client/axios'
import config from '../config/index'

// //console.log('config ', config);
export default {
  WORK_ORDER_SEARCH: (data) => {
    //console.log("work order search type api: ", data)
    return axios({
      //headers: {},
      method: 'POST',
      url: `${config.WORK_ORDER_SEARCH}`,
      data: data
    })
  },
  WORK_ORDER_SEARCH_TYPE: (data) => {
    //console.log("work order search type api: ", data)
    return axios({
      //headers: {},
      method: 'POST',
      url: `${config.WORK_ORDER_SEARCH_TYPE}`,
      data: data
    })
  },
  WORK_ORDER_SEARCH_INCOMPLETE: (data) => {
    //console.log("work order search type api: ", data)
    return axios({
      //headers: {},
      method: 'POST',
      url: `${config.WORK_ORDER_SEARCH_INCOMPLETE}`,
      data: data
    })
  },
  WORK_ORDER_CREATE: (data) => {
    //console.log("work order create api: ", data)
    return axios({
      //headers: {},
      method: 'POST',
      url: `${config.WORK_ORDER_CREATE}`,
      data: data
    })
  },
  WORK_ORDER_UPDATE: (data) => {
    //console.log("work order update api: ", data)
    return axios({
      //headers: {},
      method: 'POST',
      url: `${config.WORK_ORDER_UPDATE}`,
      data: data
    })
  },
  WORK_ORDER_UPDATE_PGADMIN: (data) => {
    return axios({
      //headers: {},
      method: 'POST',
      url: `${config.WORK_ORDER_UPDATE_PGADMIN}`,
      data: data
    })
  },
  WORK_ORDER_UPDATE_STATUS: (data) => {
    return axios({
      //headers: {},
      method: 'POST',
      url: `${config.WORK_ORDER_UPDATE_STATUS}`,
      data: data
    })
  },
  WORK_ORDER_UPDATE_NO_RETRY: (data) => {
    //console.log("work order update no retry api: ", data)
    return axios({
      //headers: {},
      method: 'POST',
      url: `${config.WORK_ORDER_UPDATE_NO_RETRY}`,
      data: data
    })
  },
  WORK_ORDER_DELETE: (data) => {
    //console.log("work order delete api: ", data)
    return axios({
      //headers: {},
      method: 'DELETE',
      url: `${config.WORK_ORDER_DELETE}`,
      data: data
    })
  },
  WORK_ORDER_RETRY: (data) => {
    //console.log("work order retry api: ", data)
    return axios({
      //headers: {},
      method: 'POST',
      url: `${config.WORK_ORDER_RETRY}`,
      data: data
    })
  },
  WORK_ORDER_FORCE_SUCCESS: (data) => {
    //console.log("work order force success api: ", data)
    return axios({
      //headers: {},
      method: 'POST',
      url: `${config.WORK_ORDER_FORCE_SUCCESS}`,
      data: data
    })
  },
  BANK_UPDATE: (data) => {
    //console.log("bank update api: ", data)
    return axios({
      //headers: {},
      method: 'POST',
      url: `${config.BANK_UPDATE}`,
      data: data
    })
  },
  UPLOAD_REFUND: (data) => {
    //console.log("upload refund api: ",data)
    return axios({
      headers:{
        'Content-Type':'multipart/form-data',
      },
      method: 'POST',
      url: `${config.UPLOAD_REFUND}`,
      data: data
    })
  },
  UPLOAD_REFUND_CUSTOMERS_ACCEPT: (data) => {
    //console.log("upload refund api: ",data)
    return axios({
      headers:{
        'Content-Type':'multipart/form-data',
      },
      method: 'POST',
      url: `${config.UPLOAD_REFUND_CUSTOMERS_ACCEPT}`,
      data: data
    })
  },
  APPROVE_WORKORDER: (data) => {
    //console.log("approve work order api: ",data)
    return axios({
      //headers: {},
      method: 'POST',
      url: `${config.WORK_ORDER_APPROVE}`,
      data: data
    })
  },
  APPROVE_MANUAL_WORKORDER: (data) => {
    //console.log("approve manual work order api: ",data)
    return axios({
      //headers: {},
      method: 'POST',
      url: `${config.WORK_ORDER_APPROVE_MANUAL}`,
      data: data
    })
  },
  REJECT_WORKORDER: (data) => {
    //console.log("reject work order api: ",data)
    return axios({
      //headers: {},
      method: 'POST',
      url: `${config.WORK_ORDER_REJECT}`,
      data: data
    })
  },
  WORK_ORDER_WITHDRAW_IMAGE: (data) => {
    //console.log(" work order withdraw image api: ",data)
    return axios({
      //headers: {},
      method: 'POST',
      url: `${config.WORK_ORDER_WITHDRAW_IMAGE}`,
      data: data
    })
  },
  APPROVE_DEPOSIT_WORKORDER: (data) => {
    //console.log(" approve deposit work order api: ",data)
    return axios({
      //headers: {},
      method: 'POST',
      url: `${config.APPROVE_DEPOSIT_WORKORDER}`,
      data: data
    })
  },
  WORK_ORDER_MANUAL_MAPPING: (data) => {
    //console.log("work order mapping api: ",data)
    return axios({
      //headers: {},
      method: 'POST',
      url: `${config.WORK_ORDER_MANUAL_MAPPING}`,
      data: data
    })
  },
  SEARCH_REFUND_BONUS: (data) => {
    //console.log("work order mapping api: ",data)
    return axios({
      //headers: {},
      method: 'POST',
      url: `${config.BONUS_SEARCH}`,
      data: data
    })
  },
  FORCE_MAPPING_SUCCESS: (data) => {
    //console.log("work order mapping api: ",data)
    return axios({
      //headers: {},
      method: 'POST',
      url: `${config.WORK_ORDER_FORCE_MAPPING}`,
      data: data
    })
  },
  MANUAL_CREDIT_SUCCESS: (data) =>{
    //console.log("work order manual credit success api: ",data)
    return axios({
      //headers: {},
      method: 'POST',
      url: `${config.WORK_ORDER_MANUAL_CREDIT_SUCCESS}`,
      data: data
    })
  },
  UPLOAD_BONUS_SOCIAL: (data) => {
    //console.log("upload bonus social api: ",data)
    return axios({
      headers:{
        'Content-Type':'multipart/form-data',
      },
      method: 'POST',
      url: `${config.UPLOAD_BONUS_SOCIAL}`,
      data: data
    })
  },
  CUSTOMER_HISTORY: (data) => {
    //console.log("customer history api: ",data)
    return axios({
      method: 'POST',
      url: `${config.CUSTOMER_HISTORY}`,
      data: data
    })
  },
  BONUS_SOCIAL_SEARCH: (data) => {
    return axios({
      headers: {
        'Authorization': `Bearer ${data.token}`
      },
      method: 'POST',
      url: `${config.BONUS_SOCIAL_SEARCH}`,
      data: data.request
    })
  },
  BONUS_SOCIAL_RETRY: (data) => {
    return axios({
      headers: {
        'Authorization': `Bearer ${data.token}`
      },
      method: 'POST',
      url: `${config.BONUS_SOCIAL_RETRY}`,
      data: data.request
    })
  },
  CREATE_TRANSFER: (data) => {
    return axios({
      method: 'POST',
      url: `${config.CREATE_TRANSFER}`,
      data: data
    })
  },
  SEARCH_TRANSFER: (data) => {
    return axios({
      method: 'POST',
      url: `${config.SEARCH_TRANSFER}`,
      data: data
    })
  },
  GATEWAY_SEARCH: (data) => {
    return axios({
      method: 'POST',
      url: `${config.SEARCH_GATEWAY_QR}`,
      data: data
    })
  },
}