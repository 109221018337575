import React, {useState, useEffect} from 'react'
import {Button, Modal, Container, Col, Form, InputGroup, Alert} from 'react-bootstrap'
import { useForm } from "react-hook-form";
import localStorage from "../../store/localStorage";
import api from "../../api/login";
import {setAxiosHeaderAuthorization} from "../../client/axios";
import {useGlobalStore} from "../../store/globalContext";
import {useHistory} from "react-router-dom";

export default function Login2Factor(props) {
    const dispatch = useGlobalStore()[1];
    const history  = useHistory();
    const {show, onHide, authFlag, username, setUserLoginData, onQrScreen} = props
    const { register, handleSubmit, errors, setValue} = useForm({
        defaultValues: { username: username, password: "", otp:""},
        criteriaMode: "all"
    });
    const [errorLogin, setErrorLogin] = useState({errorShow:false, errorMessage:"กรุณาระบุ password"})
    const [validated] = useState(false);

    useEffect(()=>{
        setValue("username", username)
    },[show])

    const onSubmit =  async data => {
            const login_request = {
                username: data.username,
                password: data.password,
                token: data.token,
                partner: process.env.REACT_APP_IMAGE_LOGO
            }
        //console.log("login_request data", login_request);
            try {

                const response = await api.LOGIN_API(login_request);

                if (response.status !== 200) {
                    //console.log("login failed : ", response);
                    setErrorLogin({errorShow:true, errorMessage:"ไม่สามารถเข้าสู่ระบบได้ กรุณาตรวจสอบ Username & Password"});
                    return
                }
                const {data} = response.data
                setUserLoginData(data)
                setAxiosHeaderAuthorization(data.token)

                if(authFlag === false){
                    onHide();
                    onQrScreen();
                }else{
                    localStorage.setAllAccess({ ...data });
                    localStorage.setUsername(username)
                    setAxiosHeaderAuthorization(data.token)
                    dispatch({
                        type: "login_profile",
                        value: data
                    });
                    history.push("/registeruser");
                }

            } catch (error) {
                console.log("catch error", error);
                console.log("login failed : ", error);
                setErrorLogin({errorShow:true, errorMessage:"ไม่สามารถเข้าสู่ระบบได้ กรุณาติดต่อ admin"});
            }
        }

    return(
        <Modal
            show={show}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter" >
                    กรุณายืนยันตัวตน

                </Modal.Title>
            </Modal.Header>
            <Form noValidate validated={validated} onSubmit={handleSubmit(onSubmit)}>
            <Modal.Body className="show-grid">
                <Container>
                    <Form.Row>
                        <Col>
                        <Alert variant={"danger"} show={errorLogin.errorShow}>
                            {errorLogin.errorMessage}
                        </Alert>
                        </Col>
                    </Form.Row>
                        <Form.Row>
                                <Form.Group as={Col} controlId="formLoginNoOTP1">
                                    <InputGroup className="col-12" size="lg">
                                    <InputGroup.Prepend>
                                        <InputGroup.Text id="inputGroup-sizing-default"><i className="fas fa-user-shield mr-2" /></InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control
                                        type="text"
                                        name="username"
                                        placeholder="Username"
                                        readOnly
                                        ref={register({ required: true })}
                                    />
                                    </InputGroup>
                                </Form.Group>
                        </Form.Row>
                        <Form.Row>
                                <Form.Group as={Col} controlId="formLoginNoOTP2">
                                    <InputGroup className="col-12" size="lg">
                                        <InputGroup.Prepend>
                                            <InputGroup.Text id="inputGroup-sizing-default2"><i className="fas fa-key mr-2" /></InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <Form.Control
                                            required
                                            type="password"
                                            name="password"
                                            placeholder="Password"
                                            isInvalid={!!errors.password}
                                            ref={register({ required: true })}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errorLogin.errorMessage}
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>
                        </Form.Row>
                                {
                                    (authFlag === true) ?
                                    (
                                        <Form.Row>
                                        <Form.Group as={Col} controlId="formLoginNoOTP3">
                                            <InputGroup className="col-12" size="lg">
                                                <InputGroup.Prepend>
                                                    <InputGroup.Text id="inputGroup-sizing-default3"><i className="fab fa-google mr-2" /></InputGroup.Text>
                                                </InputGroup.Prepend>
                                                <Form.Control
                                                    type="text"
                                                    name="token"
                                                    placeholder="OTP"
                                                    ref={register({ required: true })}
                                                    required
                                                    isInvalid={!!errors.token}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    กรุณาระบุ otp
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>
                                        </Form.Row>
                                    ):(<></>)
                            }

                </Container>

            </Modal.Body>
            <Modal.Footer>
                <Button variant={"primary"} type="submit" >เข้าสู่ระบบ</Button>
                <Button variant={"secondary"} onClick={() => onHide(false)}>ปิด</Button>
            </Modal.Footer>
        </Form>
        </Modal>
    )

}