import React, { useState } from 'react'
import { Button, Col, Form, Modal, Spinner } from 'react-bootstrap'
import { useForm } from "react-hook-form";
import apiBank from "../../api/managebank"
import { useGlobalStore } from "../../store/globalContext";


export default function ConfirmDeleteBank ( props ) {
    const [ {}, dispatch ] = useGlobalStore();
    const { data, isShow, onClose, callBack } = props;
    const { id, bank_name, bank_account_name, bank_account_no } = data;
    const [ isLoading, setLoading ] = useState( false );


    const [ validated, setValidated ] = useState( false );
    const {
        register: registerDeleteBank,
        handleSubmit: handleSubmitDeleteBank,
        watch: watchDeleteBank,
        errors: errorsDeleteBank,
        setValue: setValueDeleteBank,
        getValues: getValuesDeleteBank,
        setError: setErrorDeleteBank
    } = useForm( {} );

    const validateConfirmBankAccountNo = ( value ) => {
        if ( value === "" ) {
            setErrorDeleteBank( "confirm_bank_account_no", { "message": "กรุณาระบุเลขที่บัญชี" } );
            return
        }
        if ( value !== bank_account_no ) {
            setErrorDeleteBank( "confirm_bank_account_no", { "message": "เลขบัญชีไม่ตรงกัน" } );
        }
    };

    const onSubmit = async() => {
        setLoading( true );

        const request = {
            id: id,
        };

        const response = await apiBank.BANK_DELETE( request );

        if ( response.status !== 200 ) {
            console.log( `delete bank account no failed`, response.data );
            setLoading( false );
            onClose();
            dispatch( {
                type: "TOAST2",
                value: {
                    show: true,
                    type: 'danger',
                    title: `ลบบัญชี`,
                    message: `ลบบัญชี ${bank_account_no} ไม่สำเร็จ กรุณาติดต่อ admin`,
                }
            } );
            return
        }

        callBack();
        setLoading( false );

        dispatch( {
            type: "TOAST2",
            value: {
                show: true,
                type: 'success',
                title: `ลบบัญชี`,
                message: `ลบบัญชี ${bank_account_no} สำเร็จ`,
            }
        } );
    };


    return (
        <div>
            <Modal show={isShow} onHide={onClose} size="lg" centered={true}>
                <Form noValidate validated={validated} onSubmit={handleSubmitDeleteBank( onSubmit )}>
                    <Modal.Header closeButton>
                        <Modal.Title>ลบบัญชี</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Row>
                            <Form.Group as={Col} md="4" lg="4">
                                <Form.Label>ชื่อธนาคาร</Form.Label>
                                <Form.Control type="text" value={bank_name} readOnly={true}/>
                            </Form.Group>
                            <Form.Group as={Col} md="4" lg="4">
                                <Form.Label>ชื่อบัญชี</Form.Label>
                                <Form.Control type="text" value={bank_account_name} readOnly={true}/>
                            </Form.Group>
                            <Form.Group as={Col} md="4" lg="4">
                                <Form.Label>เลขที่บัญชี</Form.Label>
                                <Form.Control type="text" value={bank_account_no} readOnly={true}/>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} md="7" lg="7">
                                <Form.Label>ระบุเลขที่บัญชีที่ต้องการลบ (ต้องตรงกับเลขบัญชีด้านบน) *</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    name="confirm_bank_account_no"
                                    placeholder={bank_account_no}
                                    isInvalid={!!errorsDeleteBank.confirm_bank_account_no && ( getValuesDeleteBank( "confirm_bank_account_no" ) !== bank_account_no )}
                                    ref={registerDeleteBank( {
                                        required: true,
                                        validate: value => value === bank_account_no
                                    } )}
                                    onChange={( e ) => validateConfirmBankAccountNo( e.target.value )}
                                />
                                <Form.Control.Feedback type="invalid" tooltip>
                                    {errorsDeleteBank.confirm_bank_account_no?.message ? errorsDeleteBank.confirm_bank_account_no?.message : 'กรุณาตรวจสอบเลขที่บัญชี'}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" type="submit" disabled={isLoading}>
                            {isLoading ?
                                (
                                    <>
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        /> กำลังดำเนินการ</> ) : 'ยืนยันลบบัญชี'}
                        </Button>
                        <Button variant="secondary" onClick={onClose}>
                            ยกเลิก
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </div>
    )
}
