import {Badge, Button, Card, Col, Form, InputGroup, Modal, Pagination, Row, Spinner, Table} from "react-bootstrap";
import React, {createContext, useContext, useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import api from "../../api/customer";
import api_workorder from "../../api/workorder";
import {useGlobalStore} from "../../store/globalContext";
import ManageHistoryCustomerGameCash from "./ManageHistoryCustomerGameCash";
import moment from "moment";

const searchConditions = [
    {label:"ยูสเซอร์(user id)", value:"user_id"},
    {label:"ชื่อ - นามสกุล", value:"name"},
    {label:"เบอร์โทรศัพท์", value:"mobile_no"},
    {label:"หมายเลขบัญชี", value:"bank_account_no"},
    {label:"id", value:"id"},
]

const generateStatus = ( status ) => {
    if ( status === "normal" ) {
        return ( <td className={"nowrap"}><h6><Badge variant="success">ปกติ</Badge>{' '}</h6></td> )
    } else if ( status === "waiting" ) {
        return ( <td className={"nowrap"}><Spinner animation="grow" variant="primary"/></td> )
    } else if ( status === "blacklist" ) {
        return ( <td className={"nowrap"}><h6><Badge variant="warning">มิจฉาชีพ</Badge>{' '}</h6></td> )
    } else {
        return ( <td className={"nowrap"}><h6><Badge variant="danger">ไม่สำเร็จ</Badge>{' '}</h6></td> )
    }
}

export const ReloadHistoryContext = createContext()

const ModalAdjustCustomerGameCash = (props)=>{

    const value = useContext(ReloadHistoryContext)
    const [ {
        subscribe,
        dashboard_work_order_sweep_wihdraw,
        customers,
        status,
        login_profile
    }, dispatch ] = useGlobalStore();
    const {show, adjust_data, closeModal, setTriggerCC} = props
    const [showModal, setShowModal] = useState(show)

    const {register:registerTransfer, handleSubmit:handleSubmitTransfer, watch:watchTransfer, errors:errorsTransfer, reset:resetTransfer, setValue:setValueTransfer, getValues:getValueTransfer } = useForm({
        defaultValues:{currentPage:1, transfer_type: "transfer-out",amount: 0},
        criteriaMode: "all"
    });

    const submitTransfer = async (formData) =>{
        // console.log("transfer form data: ", formData)
        try{
            const response = await api_workorder.CREATE_TRANSFER(
                formData)
            // console.log(response)
            dispatch({
                type: "TOAST2",
                value: {
                    show: true,
                    type: 'success',
                    title: `ปรับรายการยอดเงินลูกค้า`,
                    message: `ปรับรายการยอดเงินลูกค้า ${formData.user_id} สำเร็จ`,
                }
            });
        }catch (e){
            console.log("create transfer error: ", e.response)
            dispatch({
                type: "TOAST2",
                value: {
                    show: true,
                    type: 'danger',
                    title: `ปรับรายการยอดเงินลูกค้าไม่สำเร็จ`,
                    message: `${e.response.data.message}`,
                }
            });
        }finally {
            resetTransfer()
            closeModal(false)
            value.setTriggerCC(true)
        }

    }

    useEffect(() => {
        // console.log("show: ", show)
        setShowModal(show)
    }, [show]);

    return(
        <Modal show={showModal} size={"xl"} onHide={()=>closeModal(false)}>
            <Modal.Header closeButton className={"text-bold"}>ยืนยันการปรับเงินในเกมลูกค้า</Modal.Header>
            <Modal.Body >
                <Form noValidate className={"justify-content-center"} key={"transfer"} onSubmit={handleSubmitTransfer(submitTransfer)}>
                    <Row className={"w-full"}>
                        <Form.Group as={Col}>
                            <Form.Label>ยูสเซอร์ ID</Form.Label>
                            <Form.Control
                                ref={registerTransfer}
                                name={"user_id"}
                                defaultValue={adjust_data.user_id}
                                readOnly
                            />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>ชื่อ</Form.Label>
                            <Form.Control
                                name={"first_name"}
                                defaultValue={adjust_data.first_name}
                                readOnly
                            />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>นามสกุล</Form.Label>
                            <Form.Control
                                name={"last_name"}
                                defaultValue={adjust_data.last_name}
                                readOnly
                            />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>สถานะ</Form.Label>
                            <Form.Control
                                name={"status"}
                                defaultValue={adjust_data.status}
                                readOnly
                            />
                        </Form.Group>
                    </Row>
                    <Row className={"w-full"}>
                        <Form.Group as={Col}>
                            <Form.Label>กรุณาเลือกประเภทการปรับยอด</Form.Label>
                            <InputGroup>
                                <Form.Check
                                    inline
                                    label="-(ยอดลบ)"
                                    type={'radio'}
                                    name={"transfer_type"}
                                    ref={registerTransfer}
                                    defaultValue={"transfer-out"}
                                />
                                <Form.Check
                                    inline
                                    label="+(ยอดบวก)"
                                    type={'radio'}
                                    name={"transfer_type"}
                                    ref={registerTransfer}
                                    defaultValue={"transfer-in"}
                                />
                            </InputGroup>
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>จำนวนเงิน</Form.Label>
                            <Form.Control
                                required
                                type="number"
                                ref={registerTransfer}
                                // as={"input"}
                                isInvalid={!!errorsTransfer.amount}
                                name={"amount"}
                            />
                        </Form.Group>
                        <Form.Control.Feedback type="invalid" tooltip>กรุณาระบุจำนวนเงินปรับยอด</Form.Control.Feedback>
                    </Row>
                    <Row>
                        <Button className={"btn-danger"} type={"submit"}> ยืนยันปรับยอด </Button>
                        <Button className={"btn-secondary ml-2"} onClick={()=>closeModal(false)}> ปิด </Button>
                    </Row>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

const TableSearchCustomer = (props) =>{
    const {data, setTriggerCC} = props
    // console.log("TableSearchCustomer: ", data)
    const [showModal, setShowModal] = useState(false)
    const [adjustData, setAdjustData] = useState({
        user_id:"",
        first_name:"",
        last_name:"",
        status:""
    })
    const confirmAdjustModal = (user_id, first_name, last_name, status)=>{
        if(status !== "normal"){
            return
        }

        setAdjustData({
            user_id:user_id,
            first_name:first_name,
            last_name:last_name,
            status:status
        })
        setShowModal(true)
    }
    return(
        <>
        <ModalAdjustCustomerGameCash show={showModal} adjust_data={adjustData} closeModal={setShowModal} setTriggerCC={setTriggerCC}/>
            <Table striped bordered hover responsive>
                <thead>
                <tr className={"text-center text-nowrap"}>
                    {/*<th>id</th>*/}
                    <th>ยูสเซอร์ ID</th>
                    <th>ชื่อ</th>
                    <th>นามสกุล</th>
                    {/*<th>เบอร์โทร</th>*/}
                    <th>สถานะ User</th>
                    <th>ฝากล่าสุด</th>
                </tr>
                </thead>
                <tbody>
                {
                    data?.map(d=>{
                        // console.log("d: ", d)
                        return (<tr key={d.id} className={"text-center text-nowrap"} onClick={() => confirmAdjustModal(
                            d.user_id,
                            d.first_name,
                            d.last_name,
                            d.status)}>
                            {/*<td>{d.id}</td>*/}
                            <td>{d.user_id || "-"}</td>
                            <td>{d.first_name || "-"}</td>
                            <td>{d.last_name || "-"}</td>
                            {/*<td>{d.mobile_no || "-"}</td>*/}
                            {
                                generateStatus(d.status)
                            }
                            <td>{moment(d.last_deposit_inquiry).format('DD/MM/YYYY HH:mm') || "-"}</td>
                        </tr>)

                    })
                }
                </tbody>
            </Table>
        </>
    )
}

export default function ManageCustomerGameCash() {

    const {register, handleSubmit, watch, errors, reset, setValue, getValues } = useForm({
        defaultValues:{currentPage:1},
        criteriaMode: "all"
    });

    const [searchButton, setSearchButton] = useState(false)
    const [searchResultData, setSearchResultData] = useState([])
    const [triggerCC, setTriggerCC] = useState("")

    const ccContextValue ={
        triggerCC, setTriggerCC
    }

    const watchFieldSearch = watch("field")
    const submitSearchForm = async (formData)=>{

        try{
            setSearchButton(true)
            // console.log("submitSearchForm data: ", formData)
            const response = await api.SEARCH_CUSTOMER(formData);
            const { data } = response?.data
            // console.log("submitSearchForm response data: ", data)
            setSearchResultData(data)
        }catch (e){
            console.log("submitSearchForm error: ", e)
        }finally {
            setSearchButton(false)
        }
    }

    const resetSearchKeyword = ()=>{
        // handleSearchCondition("")
        setValue("field","")
        setValue("keyword", "")
        setValue("currentPage", 1)
        setSearchResultData([])
    }

    return(
        <ReloadHistoryContext.Provider value={ccContextValue}>
            <div className={"pt-2 pl-2"}>
                <Card className={"card-primary"}>
                    <Card.Header> จัดการเงินลูกค้า</Card.Header>
                    <Card.Body>
                        <Form key={1} onSubmit={handleSubmit(submitSearchForm)}>
                            <Form.Row>
                            <Form.Group className="col-md-2">
                                <Form.Label>ค้นหาด้วย User ID</Form.Label>
                                <Form.Control
                                    ref={register}
                                    as={"select"}
                                    name={"field"}
                                    custom

                                >
                                    <option value="">เลือกเงื่อนไขการค้นหา</option>
                                    {
                                        searchConditions?.map(({ label, value }, index) =>
                                            <option
                                                key={value}
                                                value={value}
                                            >
                                                {label}
                                            </option>
                                        )
                                    }
                                </Form.Control>
                            </Form.Group>
                            <Form.Group className="col-md-6">
                                <Form.Label>พิมพ์ค้นหาลูกค้า</Form.Label>
                                <Form.Control
                                    as="input"
                                    ref={register({required:true})}
                                    name="keyword"
                                    placeholder={"ยูสเซอร์ไอดี/ชื่อ/นามสกุล/เบอร์โทรศัพท์/เลขที่บัญชี"}
                                    defaultValue={""}
                                    disabled={watchFieldSearch === ""}
                                    isInvalid={!!errors.keyword}
                                >
                                </Form.Control>
                                <Form.Control.Feedback type="invalid" tooltip>กรุณาระบุข้อมูลในการค้นหา</Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row>
                            <Form.Row className="col-md-12">
                                <Col>
                                    <Button style={{marginBottom: "10px"}} type={"submit"}
                                            // onClick={() => keySearchInput(getValues("search_customer"), watchFieldSearch,false)} disabled={searchButton}
                                    >
                                        {
                                            (searchButton === true)?
                                                (<Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                    className={"justify-content-center"}
                                                />):(<>ค้นหา</>)
                                        }

                                    </Button>
                                    {" "}
                                    <Button variant={"danger"} style={{marginBottom: "10px"}}
                                            onClick={() => resetSearchKeyword()} disabled={searchButton}
                                        >
                                        <>ล้างข้อมูล</>
                                    </Button>
                                </Col>
                                {/*<Pagination className={"ml-auto"}>*/}
                                {/*    <Pagination.Prev*/}
                                {/*        // onClick={handlePrevPage}*/}
                                {/*    />*/}
                                {/*    <Pagination.Item active={true}>*/}
                                {/*        /!*<span {...register('currentPage', {valueAsNumber: true})} >{watchCurrentPage}</span>*!/*/}
                                {/*    </Pagination.Item>*/}
                                {/*    <Pagination.Next*/}
                                {/*        // onClick={handleNextPage}*/}
                                {/*    />*/}
                                {/*</Pagination>*/}
                            </Form.Row>
                        </Form>
                        <div>
                            <TableSearchCustomer data={searchResultData}/>
                        </div>
                    </Card.Body>
                </Card>
                <ManageHistoryCustomerGameCash/>
            </div>
        </ReloadHistoryContext.Provider>
    )
}