import React, {useCallback, useMemo} from 'react'
import moment from 'moment'
import { Badge, Table } from 'react-bootstrap'


const renderComment = (comment)=>{
    const commentTrim = comment.trimStart().replace(/\)\s*\(/g, ", ").replace(/[()]/g, "")
    const commentTrimArr = commentTrim.split(",")

    return commentTrimArr.map((ca, index)=>{
        return <p key={index}>{ca}</p>
    })

}
export default function TableABNormalStatement ( props ) {
    const { data, editData } = props;

    const generateStatus = useCallback( (status ) => {
        if ( !status ) {
            return ( <td><h6><Badge variant="warning">รอการตรวจสอบ</Badge>{' '}</h6></td> )
        }
        return ( <td><h6><Badge variant="success">ตรวจสอบแล้ว</Badge>{' '}</h6></td> )
    },[])

    const dataMemo = useMemo(() => {
        return data
    }, [data]);

    return (
        <Table responsive hover size="md" className="text-center w-100">
            <thead>
            <tr data-aos="fade-down">
                <th>#</th>
                <th width="10%">ชื่อธนาคาร/ชื่อบัญชี</th>
                {/*<th>ชื่อบัญชี</th>*/}
                <th width="10%">ธนาคาร/เลขบัญชี</th>
                {/*<th>เลขบัญชี</th>*/}
                <th width="10%">วัน/เวลาทำรายการ</th>
                <th width="6%">จำนวนเงิน</th>
                <th>ref_no</th>
                <th width="10%">คำอธิบาย</th>
                <th>สถานะ</th>
                <th width="6%">Teller</th>
                {/*<th>Channel</th>*/}
                <th>หมายเหตุ</th>
            </tr>
            </thead>
            <tbody>
            {
                dataMemo?.map( ( {
                                 id,
                                 bank_name,
                                 bank_account_name,
                                 account_no,
                                 bank_short_name,
                                 transaction_at,
                                 ref_no,
                                 debit,
                                 description,
                                 comment,
                                 completed,
                                 teller,
                                 channel,
                             }, index ) =>

                    <tr key={index + 1} style={{ "cursor": "pointer" }}
                        onClick={() => editData( dataMemo[ index ], true, completed )}
                    >
                        <td className="align"><h6>{index + 1}</h6></td>
                        {/*<td><h6>{bank_name}</h6></td>*/}
                        <td>
                            {/*<h6>*/}
                                <p>{bank_name}</p>
                                <p>{bank_account_name}</p>
                            {/*</h6>*/}
                        </td>
                        <td>
                            <p>{bank_short_name.toUpperCase()}</p>
                            <p>{account_no}</p>
                        </td>
                        {/*<td><h6>{account_no}</h6></td>*/}
                        <td>
                            <p>{moment( transaction_at ).format( 'DD/MM/YYYY' )}</p>
                            <p>{moment( transaction_at ).format( 'HH:mm:ss' )}</p>
                        </td>
                        <td>{debit}</td>
                        <td><h6>{ref_no}</h6></td>
                        <td><h6>{description}</h6></td>
                        {
                            generateStatus( completed )
                        }
                        <td><h6>{teller}</h6></td>
                        {/*<td><h6>{channel}</h6></td>*/}
                        <td className="text-left">
                        {
                            renderComment(comment)
                        }
                        </td>
                    </tr>
                )
            }
            </tbody>
        </Table>
    )
}
