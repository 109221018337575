import React, {useEffect, useState} from 'react';
import {Button, Card, Col, Fade, Form, FormControl, InputGroup, Pagination, Spinner, Table} from "react-bootstrap";
import {useGlobalStore} from "../../store/globalContext";
import api_bank from "../../api/managebank";
import {LogoWithAccountNo2} from "../banks_logo";
import ModalViewBankDetail from "./ModalViewBankDetail";
import moment from "moment";

export default function ManageBanksDetail() {
    const {login_profile} = useGlobalStore()[0];
    const [banks, setBanks] = useState([]);

    const getBankList = async () => {
        try {
            const response = await api_bank.BANK_SEARCH({});
            const {data} = response.data;
            setBanks(data.filter((bank) => bank.bank_short_name === "kbank" && bank.crawler_type === "api"));
        } catch (e) {
            console.log(`search bank_list failed`, e);
        }
    };

    const getThaiBankType = (bankType, isLure) => {
        switch (bankType) {
            case "deposit":
                return isLure ? "ฝากล่อ" : "ฝาก";
            case "withdraw":
                return isLure ? "ถอนล่อ" : "ถอน";
            case "holding":
                return "พัก";
            default:
                return "";
        }
    };

    const [modalViewBankDetailInfo, setModalViewBankDetailInfo] = useState({});
    const [isOpenModalViewBankDetail, setIsOpenModalViewBankDetail] = useState(false);
    const handleOpenModalViewBankDetail = (data, flag) => {
        setModalViewBankDetailInfo(data);
        setIsOpenModalViewBankDetail(flag);
    };

    const handleCloseModalViewBankDetail = () => {
        setIsOpenModalViewBankDetail(false);
    };

    const [username, setUsername] = useState("");
    const [bankAccNo, setBankAccNo] = useState("");

    const [loading, setLoading] = useState(false);
    const ed = new Date();
    const std = new Date().setDate(ed.getDate() - 5);
    const [startDate, setStartDate] = useState(moment(std).format("YYYY-MM-DD"));
    const [endDate, setEndDate] = useState(moment(ed).format("YYYY-MM-DD"));
    const [currentPage, setCurrentPage] = useState(1);
    const [dictLastIndices, setDictLastIndices] = useState({1: ""});
    const limitPage = 20;
    const [searchData, setSearchData] = useState([]);

    const handlePrevPage = () => {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1)
        }
    };

    const handleNextPage = () => {
        if (!searchData) {
            return
        }

        const setId = [...new Set(searchData.map(e => e._id))]
        if (setId.length === limitPage) {
            setCurrentPage(currentPage + 1)
        }
    };

    const searchViewPasswordLogs = async (action) => {
        if (action === "click") {
            setCurrentPage(1);
            setDictLastIndices({1: ""});
        }
        setLoading(true);
        let bank_id = "";
        if (bankAccNo) {
            bank_id = banks.filter((bank => bank.bank_account_no === bankAccNo))[0].id;
        }
        try {
            const request = {
                username: username,
                bank_account_no: bankAccNo,
                start_date: startDate,
                end_date: endDate,
                limit: limitPage,
                last_element_id: dictLastIndices[currentPage],
                sort: -1,
                bank_id,
            };
            const response = await api_bank.BANK_VIEW_PASSWORD_LOGS(request);
            const searchDataList = response.data.data;
            setSearchData(searchDataList);
            if (searchDataList) {
                const newIndex = {...dictLastIndices, [currentPage + 1]: searchDataList[searchDataList.length - 1]._id}
                setDictLastIndices(newIndex)
            }
        } catch (e) {
            console.log("error search view password logs", e.response.data.message);
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    };

    useEffect(() => {
        getBankList();
    }, []);

    useEffect(() => {
        searchViewPasswordLogs();
    }, [currentPage]);

    return (<section className="content pt-4 pr-4 pl-4">
        <div className="container-fluid">
            <div>
                <ModalViewBankDetail
                    data={modalViewBankDetailInfo}
                    isOpenModal={isOpenModalViewBankDetail}
                    onClose={handleCloseModalViewBankDetail}
                />
                <Card className="card-primary">
                    <Card.Header>ดูรายละเอียดธนาคาร</Card.Header>
                    <Card.Body>
                        <Fade in={true}>
                            <Table responsive hover size="md">
                                <thead className="text-center">
                                <tr>
                                    <th>#</th>
                                    <th>ชื่อธนาคาร</th>
                                    <th>เลขที่บัญชี</th>
                                    <th>ประเภทธนาคาร</th>
                                </tr>
                                </thead>
                                <tbody>
                                {banks?.map(({
                                                 id,
                                                 bank_name,
                                                 bank_account_no,
                                                 bank_account_name,
                                                 bank_type,
                                                 bank_short_name,
                                                 is_lure,
                                             }, index) => <tr key={index} className={"text-center cursor-pointer"}
                                                              onClick={() => handleOpenModalViewBankDetail({id}, true)}>
                                    <td><h6>{index + 1}</h6></td>
                                    <td><h6>{bank_name}</h6></td>
                                    <td>
                                        <LogoWithAccountNo2
                                            bankShortName={bank_short_name}
                                            bankFullName={bank_account_name}
                                            bankName={bank_account_name}
                                            accountNo={bank_account_no}
                                        />
                                    </td>
                                    <td><h6>{getThaiBankType(bank_type, is_lure)}</h6></td>
                                </tr>)}
                                </tbody>
                            </Table>
                        </Fade>
                    </Card.Body>
                </Card>
                {login_profile.role === "super_admin" && <Card className="card-indigo">
                    <Card.Header>ประวัติการดูรายละเอียดธนาคาร</Card.Header>
                    <Card.Body>
                        <Form>
                            <Form.Row>
                                <Col sm={4} md={4} lg={4}>
                                    <Form.Label htmlFor="inlineFormInputGroupStartDate" srOnly>
                                        2020-09-21
                                    </Form.Label>
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <InputGroup.Text>Start-Date</InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <FormControl
                                            type="date"
                                            id="inlineFormInputGroupStartDate"
                                            placeholder="2020-09-10"
                                            onChange={(e) => setStartDate(moment(e.target.value).format("YYYY-MM-DD"))}
                                            defaultValue={startDate}
                                        />
                                    </InputGroup>
                                </Col>
                                <Col sm={4} md={4} lg={4}>
                                    <Form.Label htmlFor="inlineFormInputGroupEndDate" srOnly>
                                        2020-09-21
                                    </Form.Label>
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <InputGroup.Text>End-Date</InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <FormControl
                                            type="date"
                                            id="inlineFormInputGroupEndDate"
                                            placeholder="2020-09-21"
                                            onChange={(e) => setEndDate(moment(e.target.value).format("YYYY-MM-DD"))}
                                            defaultValue={endDate}
                                        />
                                    </InputGroup>
                                </Col>
                                <Col sm={4} md={4} lg={4}>
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <InputGroup.Text>เลือกธนาคาร</InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <Form.Control
                                            as="select"
                                            name="bank_account_no"
                                            custom
                                            onChange={(e) => setBankAccNo(e.target.value)}
                                        >
                                            <option value="">Please Select</option>
                                            {banks?.map(({
                                                             bank_name, bank_account_no
                                                         }, index) => <option
                                                key={bank_account_no}
                                                value={bank_account_no}
                                            >
                                                {bank_name} - {bank_account_no}
                                            </option>)}
                                        </Form.Control>
                                        <Form.Control.Feedback type="invalid" tooltip>
                                            กรุณาเลือกธนาคารที่ต้องการค้นหา
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Col>
                            </Form.Row>
                            <Form.Row className={"mt-2"}>
                                <Col sm={6} md={6} lg={6}>
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <InputGroup.Text>ค้นหาจาก username</InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <Form.Control as="input"
                                                      name="username"
                                                      placeholder="eg. username"
                                                      defaultValue=""
                                                      onChange={(e) => setUsername(e.target.value)}
                                        >
                                        </Form.Control>
                                    </InputGroup>
                                </Col>
                                <Col xs="auto">
                                    {loading ? <>
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        /> กำลังค้นหา
                                    </> : <Button type="submit" disabled={loading}
                                                  onClick={() => searchViewPasswordLogs("click")}>ค้นหาข้อมูล</Button>}
                                </Col>
                            </Form.Row>
                        </Form>
                        {searchData ? <>
                            <Fade in={true}>
                                <Table responsive hover size="md" className="mt-4 text-center align-items-baseline">
                                    <thead>
                                    <tr data-aos="fade-down">
                                        <th>วันที่เวลา</th>
                                        <th>username</th>
                                        <th>role</th>
                                        <th>ธนาคารที่ดูรายละเอียด</th>
                                    </tr>
                                    </thead>
                                    <tbody className={"align-middle"}>
                                    {searchData?.map(({
                                                          _id,
                                                          created_at,
                                                          username,
                                                          role,
                                                          bank_name,
                                                          bank_account_no,
                                                          bank_account_name,
                                                          bank_type,
                                                          bank_short_name,
                                                      }, index) => (<tr key={index} objectID={_id}>
                                        <td>{moment(created_at).format('DD/MM/YYYY HH:mm:ss')}</td>
                                        <td>{username}</td>
                                        <td>{role}</td>
                                        <td>{bank_name} - {bank_account_no} - {bank_short_name.toUpperCase()} </td>
                                    </tr>))}
                                    </tbody>
                                </Table>
                            </Fade>
                            <Pagination className={"float-right"}>
                                <Pagination.Prev onClick={handlePrevPage}/>
                                <Pagination.Item active={true}>
                                    {currentPage}
                                </Pagination.Item>
                                <Pagination.Next onClick={handleNextPage}/>
                            </Pagination>
                        </> : <p className={"font-bold text-red-600 mt-2"}>ไม่มีข้อมูล กรุณาลองค้นหาใหม่</p>
                        }
                    </Card.Body>
                </Card>}
            </div>
        </div>
    </section>)
}
