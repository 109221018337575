import React, { useState, useEffect } from 'react'
import { Card, Form, Table } from 'react-bootstrap'
import api from "../../api/agents";
import { number_format } from "../utils/Number";
import ModalAgent from "./Modal"

export default function ManageAgent() {
  const [ agentKeyword, setAgentKeyword ] = useState("")
  const [ dataTable, setDataTable ] = useState([])
  const [ modalProps, setModalProps ] = useState({
    data: {},
    showModal: false,
  })

  const handleSearchAgent = async () => {
    try {
      const keyword = {
        "keyword": agentKeyword,
      }
      console.log(keyword)
      const response = await api.SEARCH_AGENTS(keyword)
      const { data } = response.data
      console.log(data)
      let alignData = []
      data.forEach((d => {
          // console.log(d)
          d.money_sites.forEach(m => {
            const align = {
              "id": d.id,
              "agent_login_name": d.agent_login_name,
              "agent_id": d.agent_id,
              "agent_name": d.agent_name,
              "username": d.username,
              "password": d.password,
              "block_debit_credit": d.block_debit_credit,
              "remaining_credit_line": d.remaining_credit_line,
              "money_site_id": m.id,
              "money_site_name": m.name,
              "line_id": m.line_id,
              "url": m.url,
              "customer_message": m.customer_message,
              "updated_by": d.updated_by,
            }
            alignData.push(align)
          })
        })
      )
      setDataTable(alignData)
    } catch (error) {
      console.log("call search agents error ", error)
    }
  }

  const editAgentData = (data) => {
    console.log("edit agent data: ", data)
    setModalProps(
      {
        ...modalProps,
        data: data,
        showModal: true,
      }
    )
  }

  const closeModal = () => {
    setModalProps(
      {
        ...modalProps,
        showModal: false,
      })
    handleSearchAgent()
  }

  useEffect(() => {
    handleSearchAgent()
  }, [ agentKeyword ])

  return (
    <section className="content pt-4 pr-4 pl-4">
      <div className="container-fluid">
        <div>
          <ModalAgent
            data={ modalProps.data }
            showModal={ modalProps.showModal }
            closeModal={ closeModal }
          />
          <Card className="card-primary">
            <Card.Header>จัดการ Line ID</Card.Header>
            <Card.Body>
              <Form>
                <Form.Group controlId="formAgentSearch">
                  <Form.Label>Agent Search</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={ "ufyu881, ahag41, Line ID" }
                    onChange={ (e) => setAgentKeyword(e.target.value) }/>
                </Form.Group>
              </Form>
              <React.Fragment>
                <Table responsive hover size="md">
                  <thead className="text-center">
                  <tr>
                    <th>ชื่อ</th>
                    <th>Agent</th>
                    <th>ชื่อ Agent</th>
                    <th>Username</th>
                    <th>Money Site</th>
                    <th>Line</th>
                    <th>ยอดเครดิตคงเหลือ</th>
                    <th>อัพเดทโดย</th>
                  </tr>
                  </thead>
                  <tbody className="text-center">
                  {
                    dataTable?.map(({
                                      id,
                                      agent_login_name,
                                      agent_id,
                                      agent_name,
                                      username,
                                      money_site_id,
                                      line_id,
                                      updated_by,
                                      remaining_credit_line,
                                    }, index) =>
                      <tr key={ index } onClick={ () => editAgentData(dataTable[index]) }>
                        <td><h6>{ agent_login_name }</h6></td>
                        <td><h6>{ agent_id }</h6></td>
                        <td><h6>{ agent_name }</h6></td>
                        <td><h6>{ username }</h6></td>
                        <td><h6>{ money_site_id }</h6></td>
                        <td><h6>{ line_id }</h6></td>
                        <td><h6>{ number_format(remaining_credit_line, 2) }</h6></td>
                        <td><h6>{ updated_by }</h6></td>
                      </tr>
                    )
                  }
                  </tbody>
                </Table>
              </React.Fragment>
            </Card.Body>
          </Card>
        </div>
      </div>
    </section>
  )
}
