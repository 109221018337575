import moment from "moment";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {Button, Card, Col, Form, Row} from "react-bootstrap";
import {useToggle} from 'react-use';
import api from "../../api/invoice";
import bankApi from "../../api/customer"
import api_dashboard from "../../api/dashboard"
import {useGlobalStore} from "../../store/globalContext";
import localStorage from "../../store/localStorage";
import TableSummary from "./TableSummary";
import {InfoBoxBGStatsCard} from "../dashboard/StatCard";

export default () => {

    const dispatch = useGlobalStore()[1]
    const name = localStorage.getAccessName()
    const [data, setData] = useState({
        list: [],
        total: {},
        subtotal: {},
        discount: [],
        summary: {},
        remark: [],
        account_info: {
            "line": "",
            "bank_name": "",
            "bank_account_name": "",
            "bank_account_no": "",
        },
    })
    const [invoiceAccount, setInvoiceAccount] = useState({
        line: "",
        bank_name: "",
        bank_account_name: "",
        bank_short_name: "",
        bank_code: "",
        bank_account_no: "",
        enable: true,
    })
    const [thaiBank, setThaiBank] = useState([])

    const [slipImageUpload, setSlipImageUpload] = useState(undefined)
    const slipImageRef = useRef()
    const [slip, setSlip] = useState({
        "slip_image_url": "",
        "mark_paid": false
    })
    const [checkSlip, toggleCheckSlip] = useToggle(false);

    const [dashboardWarningDetails, setDashboardWarningDetails] = useState([]);

    const getInvoiceSummary = async () => {
        try {
            const request = {
                start: moment().subtract(1, "months").startOf("month").format("YYYY-MM"),
            }
            const response = await api.INVOICE(request)

            const {data} = response.data
            setData(data)
        } catch (error) {
            console.log("get invoice summary: ", error.message)
        }
    }

    const getInvoiceAccount = async () => {
        try {
            const response = await api.INVOICE_GET_ACCOUNT()

            const {data} = response.data
            setInvoiceAccount(data)
        } catch (error) {
            console.log("get invoice summary: ", error.message)
        }
    }

    const getBankThai = async () => {
        try {
            const response = await bankApi.GET_THAI_BANK_API()

            const {data} = response.data
            setThaiBank(data)
        } catch (error) {
            console.log("get thai bank list: ", error.message)
        }
    }

    const handleUpdateBankInvoice = async () => {
        try {
            const bank = thaiBank.find(ele => ele.value === invoiceAccount.bank_short_name)
            const request = {
                ...invoiceAccount,
                bank_name: bank.text,
                bank_code: bank.code,
            }

            const response = await api.INVOICE_UPDATE_ACCOUNT(request)
            console.log("updated bank invoice", response.data)
            dispatch({
                type: "TOAST2",
                value: {
                    show: true,
                    type: 'success',
                    title: `แก้ไขบัญชี`,
                    message: `แก้ไข้บัญชีที่ใช้ในการโอน Invoice สำเร็จ`,
                }
            });
        } catch (error) {
            console.log("update bank invoice: ", error.message)
            dispatch({
                type: "TOAST2",
                value: {
                    show: true,
                    type: 'danger',
                    title: `แก้ไขบัญชี`,
                    message: `แก้ไข้บัญชีที่ใช้ในการโอน Invoice ไม่สำเร็จ`,
                }
            });
        }
    }

    const getInvoiceSlip = async () => {
        try {
            const response = await api.INVOICE_GET_SLIP()
            const {data} = response.data
            setSlip(data)
            toggleCheckSlip(data.mark_paid)
            console.log("invoice", data)
        } catch (error) {
            console.log("can't get slip ", error.message)
        }
    }

    const uploadSlipImage = async () => {
        let formData = new FormData();

        if (slipImageUpload !== undefined) {
            let currentFile = slipImageUpload[0];
            formData.append("file", currentFile);
        }

        if(slipImageUpload[0].size / 1024 / 1024 > 6144){

            // console.log("file size limit over currentFile exceed: ", currentFile.size)
            console.log("file size limit over exceed: ", slipImageUpload[0].size)
            dispatch({
                type: "TOAST2",
                value: {
                    show: true,
                    type: 'danger',
                    title: `อัพโหลดสลิป`,
                    message: `ไฟล์ใหญ่เกินไป`,
                }
            });
            return
        }

        try {
            const response = await api.INVOICE_UPLOAD_SLIP(formData)
            console.log("upload slip success ", response)
            // success get image path from invoice slip
            getInvoiceSlip()
            dispatch({
                type: "TOAST2",
                value: {
                    show: true,
                    type: 'success',
                    title: `อัพโหลดสลิป`,
                    message: `อัพโหลดสลิป Invoice สำเร็จ`,
                }
            });
        } catch (error) {
            console.log("can't upload image slip", error.message)
            setSlipImageUpload(undefined)
            dispatch({
                type: "TOAST2",
                value: {
                    show: true,
                    type: 'danger',
                    title: `อัพโหลดสลิป`,
                    message: `อัพโหลดสลิป Invoice ไม่สำเร็จ`,
                }
            });
        }
        if (slipImageUpload !== undefined) {
            slipImageRef.current.value = ""
        }
    }

    const handlePaidSlip = async () => {
        try {
            const request = {}
            const response = await api.INVOICE_MARK_PAID(request)
            console.log("mark paid slip success ", response)
            getInvoiceSlip()
            dispatch({
                type: "TOAST2",
                value: {
                    show: true,
                    type: 'success',
                    title: `ยินยันสลิป`,
                    message: `ยินยันสลิป Invoice สำเร็จ`,
                }
            });
        } catch (error) {
            console.log("can't update paid status ", error.message)
            dispatch({
                type: "TOAST2",
                value: {
                    show: true,
                    type: 'danger',
                    title: `ยินยันสลิป`,
                    message: `ยินยันสลิป Invoice ไม่สำเร็จ`,
                }
            });
        }
    }


    useEffect(() => {
        void getDashboardWarning();
        void getInvoiceSummary();
        void getInvoiceSlip();
        if (name === "Programmer Admin") {
            void getBankThai();
            void getInvoiceAccount();
        }
    }, [])


    const [imagePreview, setImagePreview] = useState(null)
    useEffect(()=>{

        if(slipImageUpload === undefined){
            return
        }

        // console.log("slipImageUpload: ",  typeof(slipImageUpload))
        let objectUrl
        try{
            objectUrl = URL.createObjectURL(slipImageUpload[0])
            setImagePreview(objectUrl)
        }catch (e){
            console.log("transform slip image error: ", e)
        }

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    },[slipImageUpload])

    const getDashboardWarning = async () => {
        await api_dashboard
            .DASHBOARD_WARNING()
            .then((response) => {
                //console.log("dashboard warning response: ", response.data.data)
                const { messages } = response.data.data;
                // setDashboardWarning(messages.length);
                //console.log("dashboardWarning :", dashboardWarning);
                // messages.map( ( msg ) => {
                //     console.log( "msg :", msg )
                // } )
                setDashboardWarningDetails(messages);
                // setSuccessFetchAPI( successFetchAPI + 1 )
            })
            .catch((error) => {
                console.log("get dashboard warning error: ", error.message);
            });
    };

    return (
        <div className="content pt-4 pr-4 pl-4">
            {name === "Programmer Admin" &&
                <>
                    <Row>
                        <div className="col-md-12">
                            <Card className="card-info">
                                <Card.Header>
                                    <h5>จำนวนยูสคงเหลือ (ตาม agent)</h5>
                                </Card.Header>
                                <Card.Body>
                                    <Row lg={6}>
                                        {dashboardWarningDetails?.map((msg, index) => {
                                            return (
                                                <Col lg={6} key={index}>
                                                    <InfoBoxBGStatsCard
                                                        icon={"fas fa-people-arrows"}
                                                        value={msg.label}
                                                        label={msg.value}
                                                        type={"bg-danger"}
                                                    />
                                                </Col>
                                            );
                                        })}
                                    </Row>
                                </Card.Body>
                            </Card>
                            <Card className="card-yellow">
                                <Card.Header style={{backgroundColor: "rgb(45,194,11)"}}>
                                    <h5>{'แก้ไขข้อมูล Invoice'}</h5>
                                </Card.Header>
                                <Card.Body>
                                    <Form>
                                        <Form.Row className="col-md-12">
                                            <Form.Group as={Col} md="6" controlId="validation01">
                                                <Form.Label>Line</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder={"autofv"}
                                                    value={invoiceAccount.line}
                                                    onChange={(e) => setInvoiceAccount({
                                                        ...invoiceAccount,
                                                        line: e.target.value,
                                                    })}/>
                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row className="col-md-12">
                                            <Form.Group as={Col} md="4" controlId="validation02">
                                                <Form.Label>เลขบัญชี</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder={"012-3-45678-9"}
                                                    value={invoiceAccount.bank_account_no}
                                                    onChange={(e) => {
                                                        setInvoiceAccount({
                                                            ...invoiceAccount,
                                                            bank_account_no: e.target.value,
                                                        })
                                                    }}/>
                                            </Form.Group>
                                            <Form.Group as={Col} md="4" controlId="validation022">
                                                <Form.Label>ชื่อบัญชี</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder={"นาย เทพไท รักไทย"}
                                                    value={invoiceAccount.bank_account_name}
                                                    onChange={(e) => {
                                                        setInvoiceAccount({
                                                            ...invoiceAccount,
                                                            bank_account_name: e.target.value,
                                                        })
                                                    }}/>
                                            </Form.Group>
                                            <Form.Group as={Col} md="4" controlId="validation03">
                                                <Form.Label>บัญชีธนาคาร</Form.Label>
                                                <Form.Control
                                                    as="select"
                                                    value={invoiceAccount.bank_short_name}
                                                    onChange={(e) => {
                                                        setInvoiceAccount({
                                                            ...invoiceAccount,
                                                            bank_short_name: e.target.value,
                                                        })
                                                    }}>
                                                    <option value="">Please Select</option>
                                                    {
                                                        thaiBank.map(({text, code, value}, index) =>
                                                            <option
                                                                key={index}
                                                                value={value}
                                                            >
                                                                {text}
                                                            </option>
                                                        )
                                                    }
                                                </Form.Control>
                                            </Form.Group>
                                        </Form.Row>
                                        <Button onClick={handleUpdateBankInvoice}>
                                            บันทึก
                                        </Button>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </div>
                    </Row>
                </>
            }
            <Row>
                <div className="col-md-12">
                    <Card className="card-yellow">
                        <Card.Header style={{backgroundColor: "rgb(11,194,151)"}}>
                            <h5>{'อัพโหลดสลิปจ่ายเงิน'}</h5>
                        </Card.Header>
                        <Card.Body>
                            {
                                slip.mark_paid && <h5 style={{color: "red", fontSize: 30}}>ทำการจ่ายเงินเรียบร้อยแล้ว</h5>}
                            <img
                                style={{
                                    margin: 0,
                                    height: "auto",
                                    // width: 1000,
                                }}
                                src={imagePreview || slip.slip_image_url}
                                alt="slip_image"
                                hidden={imagePreview === undefined && slip.slip_image_url === ''}
                            />
                            <Form>
                                <Form.Row className="col-md-12 pt-4">
                                    <Form.Group as={Col} md="12" lg="6" controlId="validation10">
                                        <Form.Label>อัพโหลดรูปภาพสลิปจ่ายเงิน </Form.Label>
                                        <p style={{fontWeight: "bold", color: "red"}}>(ขนาดรูปภาพไม่เกิน 5 MB)</p>
                                        <Form.File id="form-check-api-regular">
                                            <Form.File.Input
                                                ref={slipImageRef}
                                                accept={'.png, .jpg, .jpeg'}
                                                // onChange={
                                                // (e) => setSlipImageUpload(e.target.files)
                                                // }
                                                onChange={(e) => setSlipImageUpload(e.target.files)}
                                            />
                                        </Form.File>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row className="col-md-12">
                                    <Form.Group as={Col} md="12" lg="4" controlId="validation11">
                                        <Button as={Col} md="6" variant="success"
                                                onClick={uploadSlipImage} >เพิ่มสลิป</Button>
                                    </Form.Group>
                                </Form.Row>
                                {name === "Programmer Admin" &&
                                    <>
                                        <Form.Row className="col-md-12">
                                            <Form.Group as={Col} md="12" lg="4" controlId="validation21">
                                                <Button as={Col} md="6" variant="primary"
                                                        onClick={handlePaidSlip}>ยืนยันการโอน</Button>
                                            </Form.Group>
                                        </Form.Row>
                                    </>
                                }
                            </Form>
                        </Card.Body>
                    </Card>
                </div>
            </Row>
            <Row>
                <div className="col-md-12">
                    <Card className="card-yellow">
                        <Card.Header style={{backgroundColor: "rgb(239,195,84)"}}>
                            <h5 style={{textAlign: "right"}}>{process.env.REACT_APP_BACKOFFICE_NAME}{' '}
                                <br/>
                                เรียกเก็บค่าบริการ{' '}
                                {moment().subtract(1, "months").startOf("month").format("DD/MM/YYYY")}
                                {' '}-{' '}
                                {moment().subtract(1, "months").endOf("month").format("DD/MM/YYYY")}
                            </h5>
                        </Card.Header>
                        <Card.Body className="row">
                            {data ?
                                <TableSummary data={data}/> : <div style={{textAlign: "center", margin: "10px"}}>
                                    ไม่มีข้อมูล</div>
                            }
                        </Card.Body>
                    </Card>
                    <React.Fragment>
                        หมายเหตุ:
                        <br/>
                        {/*1.{' '} กรุณาโอนเงินเข้าบัญชี { data.account_info.bank_account_name } เลขที่ { data.account_info.bank_account_no }*/}
                        {/*<br/>*/}
                        {/*{ '  ' } ชื่อบัญชี { data.account_info.bank_name } และนำสลิปโอนแจ้งได้ที่ Line@: { data.account_info.line }*/}
                        {data.remark.map((remark, index) =>
                            <div key={index}>
                                {remark}
                            </div>
                        )}
                    </React.Fragment>
                </div>
            </Row>
        </div>
    )
}
