import React, { useState } from 'react';
import { Alert, Button, Modal } from 'react-bootstrap';
import EditLuckyDraw from "./EditLuckDraw";
import luckydraw_api from "../../api/luckydraw";
import { useGlobalStore } from "../../store/globalContext";

export default function ModalLuckyDraw ( props ) {
    const dispatch = useGlobalStore()[ 1 ];

    const { luckyDrawData, isOpenModal, onSave, onClose, callBackSearch, alertError } = props;

    // Delete
    const [ showConfirmDeleteLuckyDraw, setShowConfirmDeleteLuckyDraw ] = useState( false );
    const onDeleteLuckyDraw = async() => {
        const deleteLuckyDraw = {
            id: luckyDrawData?.id,
        }

        const response = await luckydraw_api.DELETE_LUCKYDRAW( deleteLuckyDraw )
        if ( response.status !== 200 ) {
            console.log( "delete luckydraw failed" );
            setShowConfirmDeleteLuckyDraw( false );
            onClose();
            dispatch( {
                type: "TOAST2",
                value: {
                    show: true,
                    type: 'danger',
                    title: `ลบเกมชิงโชค`,
                    message: `ลบเกมชิงโชค ${luckyDrawData?.name} ไม่สำเร็จ กรุณาติดต่อ admin`,
                }
            } );
            return
        }
        setShowConfirmDeleteLuckyDraw( false );
        onClose();
        callBackSearch();
        dispatch( {
            type: "TOAST2",
            value: {
                show: true,
                type: 'success',
                title: `ลบเกมชิงโชค`,
                message: `ลบเกมชิงโชค ${luckyDrawData?.name} สำเร็จ`,
            }
        } );

    }

    return (
        <>
            <Modal show={showConfirmDeleteLuckyDraw}>
                <Modal.Header>
                    <Modal.Title>ยืนยันลบเกมชิงโชค {luckyDrawData?.name}</Modal.Title>
                </Modal.Header>

                <Modal.Footer>
                    <Button variant="danger" onClick={() => onDeleteLuckyDraw()}>ยืนยัน</Button>{'  '}
                    <Button variant="secondary" onClick={() => setShowConfirmDeleteLuckyDraw( false )}>ปิด</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={isOpenModal} size="xl">
                <Modal.Header>
                    <Modal.Title>เกมชิงโชค</Modal.Title>
                    <Modal.Title>
                        <Button variant="danger" onClick={() => setShowConfirmDeleteLuckyDraw( true )}>ลบเกมชิงโชค</Button>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        ( alertError !== "" ) ?
                            ( <Alert variant={"danger"}>{alertError}</Alert> ) : ( <></> )
                    }
                    <EditLuckyDraw data={luckyDrawData} onSubmitForm={onSave} onClose={onClose}
                                   alertError={alertError}/>
                </Modal.Body>
            </Modal>
        </>
    )
}