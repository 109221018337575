import React, { useEffect, useState } from "react";
import {
  Badge,
  Button,
  Card,
  Col,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  InputGroup,
  Modal,
  OverlayTrigger,
  Pagination,
  Row,
  Spinner,
  Table,
  Tooltip,
} from "react-bootstrap";
import { useGlobalStore } from "../../store/globalContext";
import NumberFormat from "react-number-format";
import { useForm } from "react-hook-form";
import api_bank from "../../api/managebank";
import api_work_order from "../../api/workorder";
import moment from "moment";
import WorkOrderModal from "./WorkOrderModal";
import ModalCommentTracking from "./ModalCommentTracking";

export default function WorkOrderSweep(props) {
  const dispatch = useGlobalStore()[1];
  const [validated, setValidated] = useState(false);
  const { register, handleSubmit, errors } = useForm({
    defaultValues: {},
    criteriaMode: "all",
  });
  const { workOrderTitleHeader, workOrderTitleBody, workOrderType } = props;
  const [selectBank, setSelectBank] = useState("");

  const [bank, setBank] = useState([]);
  const [startDate, setStartDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [workOrderDetailsData, setWorkOrderDetailsData] = useState([]);
  const [limitPage, setLimitPage] = useState(100);
  const [tempLimitPage, setTempLimitPage] = useState(limitPage);
  const [keyword, setKeyword] = useState("");
  const [totalWorkOrder, setTotalWorkOrder] = useState(0);
  const [bankAmount, setBankAmount] = useState(0);
  const [searchButtonLoading, setSearchButtonLoading] = useState(false);

  const limitPageList = [
    { text: "100", value: 100 },
    { text: "200", value: 200 },
  ];
  const [modalData, setModalData] = useState({
    header: "ยืนยันการแก้ไข",
    headerClassName: "btn-success",
    bodyTitle: "",
    placeholder: "ข้อมูลเครดิตถูกต้อง",
  });
  const [modalShow, setModalShow] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [dictLastIndices, setDictLastIndices] = useState({ 1: "" });
  const subtypeSelect = [
    { text: "โยกฝากไปถอน", value: "withdraw" },
    { text: "โยกถอนไปพัก", value: "holding" },
  ];

  const getBankList = async () => {
    const response = await api_bank.BANK_SEARCH({ keyword: "" });
    if (response.status !== 200) {
      console.log(`search bank_list by user id failed`, response.message);
      return;
    }

    const { data } = response.data;
    //console.log("search deposit bank response data: ", data);
    setBank(data);
  };

  useEffect(() => {
    getBankList();
  }, []);

  const onSubmit = async (workOrderData) => {
    //console.log("submit data: ", workOrderData);
    // console.log("keyword data : ", keyword)
    setSearchButtonLoading(true);

    let bankAcc;
    if (workOrderData.bank_account_no === "") {
      bankAcc = [{ bank_type: "" }];
    } else {
      bankAcc = bank.filter(
        (e) => e.bank_account_no === workOrderData.bank_account_no
      );
    }
    //console.log("bankAcc keyword data : ", bankAcc);
    const work_order_search_data = {
      keyword: keyword,
      bank_account_type: bankAcc[0].bank_type,
      bank_account_no: workOrderData.bank_account_no,
      work_order_type: "sweep",
      date: workOrderData.start_date,
      last_element_id: "",
      limit: parseInt(tempLimitPage),
      work_order_sub_type: workOrderData.sub_type,
    };
    //console.log(work_order_search_data);

    const response = await api_work_order.WORK_ORDER_SEARCH(
      work_order_search_data
    );

    if (response.status !== 200) {
      console.log(`search bank_list by user id failed`, response.message);
      dispatch({
        type: "TOAST2",
        value: {
          show: true,
          type: "danger",
          title: `ค้นหาใบงาน`,
          message: `ขออภัยระบบไม่สามารถดำเนินการได้ในขณะนี้`,
        },
      });
      setSearchButtonLoading(false);
      return;
    }

    const { data } = response.data;
    //console.log("search work order response work_orders length: ", data);
    setTotalWorkOrder(data.total_work_orders);
    setBankAmount(data.bank_amount);
    setWorkOrderDetailsData(data.work_orders);
    setCurrentPage(1);
    setSearchButtonLoading(false);
    setLimitPage(tempLimitPage);
  };

  const renderSwitch = (props) => {
    //console.log("renderSwitch :", typeof(status))
    const [status, reason, id] = props;
    //console.log("statusText :", status)
    switch (status) {
      case "success":
        return (
          <td>
            <h5>
              <Badge variant="success">{"สำเร็จ"}</Badge>
            </h5>
          </td>
        );
      case "failed":
        return (
          <td>
            <h5>
              <OverlayTrigger
                placement="top"
                overlay={
                  reason ? (
                    <Tooltip id={`tooltip-status`}>{reason}</Tooltip>
                  ) : (
                    <div></div>
                  )
                }
              >
                <Badge variant="danger">{"ไม่สำเร็จ"}</Badge>
              </OverlayTrigger>
            </h5>
          </td>
        );
      case "pending":
        return (
          <td>
            <Button
              className="btn-success"
              size="sm"
              onClick={() =>
                enableModalByMode({
                  header: "ยืนยันรายการอนุมัติ",
                  headerclassName: "btn-success",
                  bodyTitle: "ยืนยันการอนุมติ",
                  placeholder: "eg. ข้อมูลเครดิตถูกต้อง",
                  api_type: "approve",
                  id: id,
                })
              }
            >
              <i className={`nav-icon fas fa-check`}></i>
            </Button>
            <Button
              className="btn-warning"
              size="sm"
              onClick={() =>
                enableModalByMode({
                  header: "ยืนยันรายการแก้ไข",
                  headerclassName: "btn-warning",
                  bodyTitle: "ยืนยันการทำ Manual",
                  placeholder: "eg. บันทึกข้อมูลในระบบไปแล้ว",
                  api_type: "approve_manual",
                  id: id,
                })
              }
            >
              <i className={`nav-icon fas fa-cog`}></i>
            </Button>
            <Button
              className="btn-danger"
              size="sm"
              onClick={() =>
                enableModalByMode({
                  header: "ยืนยันรายการยกเลิก",
                  headerclassName: "btn-danger",
                  bodyTitle: "ยืนยันการยกเลิก",
                  placeholder: "eg. ยกเลิกเนื่องจากไม่มีการทำธุรกรรม",
                  api_type: "reject",
                  id: id,
                })
              }
            >
              <i className={`nav-icon fas fa-ban`}></i>
            </Button>
          </td>
        );
      case "deleted":
        return (
          <td>
            <h5>
              <Badge variant="warning">{"ยกเลิก"}</Badge>
            </h5>
          </td>
        );
      default:
        return (
          <td>
            <Spinner animation="grow" variant="info" />
          </td>
        );
    }
  };

  const enableModalByMode = (props) => {
    //console.log("props:", props)
    setModalData(props);
    setModalShow(true);
  };

  const ConfirmActionModal = () => {
    const [comment, setComment] = useState(modalData.placeholder);

    return (
      <Modal
        {...modalData}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalShow}
      >
        <Modal.Header className={modalData.headerclassName} closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {modalData.header}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>{modalData.bodyTitle}</Form.Label>
              <Form.Control
                type="text"
                placeholder={modalData.placeholder}
                onChange={(e) => setComment(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className={modalData.headerclassName}
            onClick={() =>
              approveWorkOrderByType({
                api_type: modalData.api_type,
                id: modalData.id,
                comment: comment,
              })
            }
          >
            ยืนยัน
          </Button>
          <Button className="secondary" onClick={() => setModalShow(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const approveWorkOrderByType = async (props) => {
    //console.log("approve work order data: ", props)
    const api_type = props.type;
    try {
      let response = null;

      switch (api_type) {
        case "approve ":
          response = api_work_order.APPROVE_WORKORDER(props);
          break;
        case "approve_manual":
          response = api_work_order.APPROVE_MANUAL_WORKORDER(props);
          break;
        default:
          response = api_work_order.REJECT_WORKORDER(props);
          break;
      }

      if (response.status !== 200) {
        console.log(`create work_order_deposit_failed`, response.message);
        return;
      }
      //console.log("getWorkOrderListByType response.data: ", response.data)
      if (response.data.data === null || response.data.data === undefined) {
        return;
      }
      console.log(response.data.data);
    } catch (error) {
      console.log("error approve work order : ", error?.response?.status);
    }
  };

  const [editModalData, setEditModalData] = useState();
  const [showWorkOrderModal, setShowWorkOrderModal] = useState(false);
  const showModalWithData = (props) => {
    console.log("modal options :", props);
    setEditModalData(props);
    setShowWorkOrderModal(true);
  };

  const handlePrevPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (!workOrderDetailsData) {
      return;
    }

    if (workOrderDetailsData.length === parseInt(limitPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  // Comment Tracking
  const [showCommentTrackingModal, setShowCommentTrackingModal] =
    useState(false);
  const [commentTracking, setCommentTracking] = useState({
    comment_tracking: [],
    user_id: "",
    first_name: "",
    last_name: "",
  });

  // const handleCommentTracking = (commentTracking) => {
  //     setShowCommentTrackingModal(true)
  //     setCommentTracking(commentTracking)
  // }
  const handleCommentTracking = (
    commentTracking,
    userId,
    firstName,
    lastName
  ) => {
    if (!commentTracking) {
      const commentTrackingDummy = [
        {
          updated_at: "",
          updated_by: "",
          comment: "",
        },
      ];

      setCommentTracking({
        comment_tracking: commentTrackingDummy,
        user_id: userId,
        first_name: firstName,
        last_name: lastName,
      });
    } else {
      setCommentTracking({
        comment_tracking: commentTracking,
        user_id: userId,
        first_name: firstName,
        last_name: lastName,
      });
    }

    setShowCommentTrackingModal(true);
  };

  const handleCloseCommentTracking = () => {
    setShowCommentTrackingModal(false);

    setTimeout(() => {
      setCommentTracking({
        comment_tracking: [],
      });
    }, 200);
  };

  useEffect(() => {
    // console.log(currentPage)
    // console.log(dictLastIndices)

    async function fetchData() {
      const work_order_search_data = {
        keyword: keyword,
        bank_account_no: selectBank,
        work_order_type: "sweep",
        date: startDate,
        last_element_id: dictLastIndices[currentPage],
        limit: parseInt(limitPage),
      };

      const response = await api_work_order.WORK_ORDER_SEARCH(
        work_order_search_data
      );

      if (response.status !== 200) {
        console.log(`search bank_list by user id failed`, response.message);
        dispatch({
          type: "TOAST2",
          value: {
            show: true,
            type: "danger",
            title: `ค้นหาใบงาน`,
            message: `ขออภัยระบบไม่สามารถดำเนินการได้ในขณะนี้`,
          },
        });
        return;
      }

      const { data } = response.data;
      //console.log("search work order response work_orders length: ", data);
      setTotalWorkOrder(data.total_work_orders);
      setBankAmount(data.bank_amount);
      setWorkOrderDetailsData(data.work_orders);

      if (data.work_orders) {
        const newIndex = {
          ...dictLastIndices,
          [currentPage + 1]: data.work_orders[data.work_orders.length - 1].id,
        };
        setDictLastIndices(newIndex);
      }
    }

    fetchData();
  }, [currentPage]);

  return (
    <>
      <div>
        <WorkOrderModal
          action="edit"
          workOrderType={workOrderType}
          modalHeader="แก้ไขใบงาน"
          buttonSubmit="แก้ไขข้อมูลใบงาน"
          data={editModalData}
          show={showWorkOrderModal}
          closeUpdateModal={() => setShowWorkOrderModal(false)}
        />
      </div>
      <div>
        <ModalCommentTracking
          showModal={showCommentTrackingModal}
          data={commentTracking}
          onClose={handleCloseCommentTracking}
        />
      </div>
      <div className="col-md-12">
        <ConfirmActionModal
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
        <Card className="card-primary">
          {/* <Card.Header className="card card-success">ค้นหาใบงานฝาก</Card.Header> */}
          <Card.Header className="card card-success">
            {workOrderTitleHeader}
          </Card.Header>
          <Card.Body>
            <Form
              noValidate
              validated={validated}
              onSubmit={handleSubmit(onSubmit)}
            >
              <Form.Row>
                <Form.Group as={Col} md="3" lg="3" controlId="validation01">
                  <Form.Label>เลือกธนาคาร</Form.Label>
                  <InputGroup>
                    <Form.Control
                      as="select"
                      name="bank_account_no"
                      custom
                      isInvalid={!!errors.bank_account_no}
                      ref={register({ required: false })}
                      onChange={(e) => setSelectBank(e.target.value)}
                    >
                      <option value="">Please Select</option>
                      {bank?.map(({ bank_name, bank_account_no }, index) => (
                        <option key={bank_account_no} value={bank_account_no}>
                          {bank_name}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid" tooltip>
                      กรุณาเลือกธนาคารที่ต้องการค้นหา
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
                <Form.Group as={Col} md="3" lg="3" controlId="validation01">
                  <Form.Label>เลือกประเภทธุรกรรม</Form.Label>
                  <InputGroup>
                    <Form.Control
                      as="select"
                      name="sub_type"
                      custom
                      isInvalid={!!errors.sub_type}
                      ref={register({ required: false })}
                      onChange={(e) => setSelectBank(e.target.value)}
                    >
                      <option value="">Please Select</option>
                      {subtypeSelect?.map(({ text, value }, index) => (
                        <option key={text} value={value}>
                          {text}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid" tooltip>
                      กรุณาเลือกประเภทธุรกรรม
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
                <Form.Group as={Col} md="3" lg="3" controlId="validation02">
                  <Form.Label>เลือกวันที่ต้องการค้นหาใบงาน</Form.Label>
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text>Start-Date</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      type="date"
                      name="start_date"
                      placeholder="2020-09-10"
                      required
                      isInvalid={!!errors.start_date}
                      onChange={(e) =>
                        setStartDate(
                          moment(e.target.value).format("YYYY-MM-DD")
                        )
                      }
                      defaultValue={moment(startDate).format("YYYY-MM-DD")}
                      ref={register({ required: true })}
                    />
                    <Form.Control.Feedback type="invalid" tooltip>
                      กรุณาเลือกวันที่ต้องการค้นหา
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
                <Form.Group as={Col} md="2" lg="2" controlId="validation03">
                  <Form.Label>จำนวนแสดงผลต่อหน้า</Form.Label>
                  <InputGroup>
                    <Form.Control
                      as="select"
                      name="limit"
                      custom
                      required
                      defaultValue={tempLimitPage}
                      // isInvalid={!!errors.to_bank_name}
                      // ref={register({ required: true })}
                      onChange={(e) => setTempLimitPage(e.target.value)}
                    >
                      <option value="">Please Select</option>
                      {limitPageList.map(({ text, value }, index) => (
                        <option key={text} value={value}>
                          {text}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid" tooltip>
                      กรุณาระบุธนาคาร
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <FormGroup as={Col} md="12" lg="12">
                  <FormLabel>{workOrderTitleBody}</FormLabel>
                  <Form.Control
                    required
                    type="text"
                    name="keyword"
                    placeholder="eg. ufyuxxxx/ahagxxxx / 20200911 / 09:09 / ชื่อธนาคาร"
                    onKeyUp={(e) => setKeyword(e.target.value)}
                  ></Form.Control>
                  {/* <input type="" name="email" className="form-control"
                                id="exampleInputEmail1" placeholder="eg. ufyu88xxxx/ahag2xxx, ชื่อ - นามสกุล, เบอร์โทรศัพท์, เลขที่บัญชี"
                            /> */}
                </FormGroup>
              </Form.Row>
              <Form.Row>
                <FormGroup as={Col} md="2" lg="2">
                  <Button type="submit">ค้นหา</Button>
                </FormGroup>
                <Pagination className={"ml-auto"}>
                  {/*<Pagination.First/>*/}
                  <Pagination.Prev onClick={handlePrevPage} />
                  <Pagination.Item active={true}>{currentPage}</Pagination.Item>
                  <Pagination.Next onClick={handleNextPage} />
                  {/*<Pagination.Last onClick={setCurrentPage(currentPage + 1)}/>*/}
                </Pagination>
              </Form.Row>
            </Form>
          </Card.Body>
        </Card>
        {searchButtonLoading ? (
          <Row sm={12} md={12} lg={12}>
            <Col className="text-center">
              <Spinner animation="grow" variant="danger" />
            </Col>
          </Row>
        ) : (
          <Card className="card-info">
            <Card.Header>ข้อมูลใบงาน</Card.Header>
            <Card.Body>
              <Card className="card-info">
                <Card.Body className="row">
                  <Card className="card-primary col-sm-6 col-md-6 col-lg-3">
                    <Card.Header className="text-center">
                      <h5>จำนวนใบงานทั้งหมด</h5>
                    </Card.Header>

                    <Card.Body className="text-center card-block align-middle">
                      <h3>
                        <NumberFormat
                          thousandSeparator={true}
                          value={totalWorkOrder}
                          displayType="text"
                        />
                      </h3>
                    </Card.Body>
                  </Card>
                  <Card className="card-danger col-sm-6 col-md-6 col-lg-3">
                    <Card.Header className="text-center">
                      <h5>ยอดรวมใบงานที่โยกทั้งหมด</h5>
                    </Card.Header>

                    <Card.Body className="text-center card-block align-middle">
                      <h3>
                        <NumberFormat
                          thousandSeparator={true}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          value={bankAmount}
                          displayType="text"
                        />
                      </h3>
                    </Card.Body>
                  </Card>
                </Card.Body>
              </Card>
            </Card.Body>
            <Card.Body className="row ">
              <Table
                striped
                borderless
                hover
                responsive
                className="table table-striped table-valign-middle"
              >
                <thead className="text-center">
                  <tr>
                    <th>#</th>
                    <th>วันที่/เวลา</th>
                    <th>บัญชีโอน</th>
                    <th>บัญชีรับ</th>
                    <th>จำนวนเงิน</th>
                    <th>สถานะ</th>
                    <th>ผู้ทำรายการ</th>
                    <th>อัพเดทล่าสุด</th>
                    <th>หมายเหตุ</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody className="text-center">
                  {workOrderDetailsData?.map(
                    (
                      {
                        id,
                        created_at,
                        from_bank_name,
                        from_bank_account_no,
                        to_bank_name,
                        to_bank_account_no,
                        amount,
                        status,
                        updated_at,
                        updated_by,
                        comment,
                        comment_tracking,
                        credit_reason,
                      },
                      index
                    ) => (
                      // <tr key={id} onClick={() => showModalWithData(dataTable[index])} >
                      <tr
                        key={id}
                        data-aos="fade-down"
                        style={{ cursor: "pointer" }}
                      >
                        <td
                          onClick={() =>
                            showModalWithData(workOrderDetailsData[index])
                          }
                        >
                          {(currentPage - 1) * limitPage + index + 1}
                        </td>
                        <td
                          onClick={() =>
                            showModalWithData(workOrderDetailsData[index])
                          }
                        >
                          {moment(created_at).format("DD/MM/YYYY HH:mm")}
                        </td>
                        <td
                          onClick={() =>
                            showModalWithData(workOrderDetailsData[index])
                          }
                        >
                          {from_bank_name}/{from_bank_account_no}
                        </td>
                        <td
                          onClick={() =>
                            showModalWithData(workOrderDetailsData[index])
                          }
                        >
                          {to_bank_name}/{to_bank_account_no}
                        </td>
                        <td
                          onClick={() =>
                            showModalWithData(workOrderDetailsData[index])
                          }
                        >
                          <NumberFormat
                            thousandSeparator={true}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            value={amount}
                            displayType="text"
                          />
                        </td>
                        {renderSwitch([status, credit_reason, id])}
                        {/* <td>{status}</td> */}
                        <td
                          onClick={() =>
                            showModalWithData(workOrderDetailsData[index])
                          }
                        >
                          {updated_by === "system_auto" ? (
                            <i className={`nav-icon fas fa-laptop`} />
                          ) : (
                            updated_by
                          )}{" "}
                        </td>
                        <td
                          onClick={() =>
                            showModalWithData(workOrderDetailsData[index])
                          }
                        >
                          {moment(updated_at).format("DD/MM/YYYY HH:mm")}
                        </td>
                        {/*<td>*/}
                        {/*    <p className={"text-sm"}>{comment}</p>*/}
                        {/*    <Button onClick={() => handleCommentTracking(comment_tracking, "", "", "")}>ดูหมายเหตุเพิ่มเติม</Button>*/}
                        {/*</td>*/}
                        <td
                          onClick={() =>
                            handleCommentTracking(comment_tracking, "", "", "")
                          }
                        >
                          {comment_tracking && comment_tracking.length > 0 && (
                            <p className={"text-sm"}>
                              <b>{comment_tracking[0].comment}</b>
                            </p>
                          )}
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              comment_tracking ? (
                                <Tooltip id={`tooltip-comment-tracking`}>
                                  {
                                    comment_tracking[
                                      comment_tracking.length - 1
                                    ].comment
                                  }
                                </Tooltip>
                              ) : (
                                <Tooltip id={`tooltip-comment-tracking2`}>
                                  {"-"}
                                </Tooltip>
                              )
                            }
                          >
                            <p
                              className={`text-sm ${
                                status === "success"
                                  ? "text-primary"
                                  : "text-danger"
                              }`}
                            >
                              <i className="fas fa-exclamation-triangle mr-1" />
                              <b>ดูเพิ่มเติม</b>
                            </p>
                          </OverlayTrigger>
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </Table>
              {/* </Card.Footer> */}
              <Pagination className={"ml-auto"}>
                {/*<Pagination.First/>*/}
                <Pagination.Prev onClick={handlePrevPage} />
                <Pagination.Item active={true}>{currentPage}</Pagination.Item>
                <Pagination.Next onClick={handleNextPage} />
                {/*<Pagination.Last onClick={setCurrentPage(currentPage + 1)}/>*/}
              </Pagination>
            </Card.Body>
          </Card>
        )}
      </div>
    </>
  );
}
