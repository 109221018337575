import moment from "moment";
import React, {useEffect, useRef, useState} from 'react'
import {
    Badge,
    Button,
    Card,
    Col,
    Form,
    FormControl,
    InputGroup,
    Modal,
    Pagination,
    Spinner,
    Table
} from 'react-bootstrap'
import {useGlobalStore} from '../../store/globalContext'
import api_workorder from "../../api/workorder"
import {CustomerSocialRetryModal} from "./ModalCustomerAccept";
import {useForm} from "react-hook-form";

export default function RefundSocial() {
    const [searchButton, setSearchButton] = useState(false);
    const [{login_profile}, dispatch] = useGlobalStore();
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [googleAuth, setGoogleAuth] = useState("")
    const [refundFiles, setRefundFiles] = useState(undefined);
    const uploadFileRef = useRef()

    const [startDate, setStartDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
    const [dataTable, setDataTable] = useState([])
    const [searchInput, setSearchInput] = useState("")
    const [currentPage, setCurrentPage] = useState(1)
    const [dictLastIndices, setDictLastIndices] = useState({1: ""})
    const limitPage = 500
    const [modalProps, setModalProps] = useState({
        show: false,
        data: {},
        closeModal: {}
    })

    const [showConfirmUploadModal, setShowConfirmUploadModal] = useState(false);
    const [confirmInput, setConfirmInput] = useState("");

    const uploadRefundFiles = async () => {
        // if (refundFiles === undefined) {
        //     return
        // }

        let currentFile = refundFiles[0];
        console.log("current file: ", currentFile)
        let formData = new FormData();
        formData.append("file", currentFile);
        formData.append("username", username);
        formData.append("password", password);
        formData.append("otp", googleAuth);

        try {
            const response = await api_workorder.UPLOAD_BONUS_SOCIAL(formData)
            console.log("response upload refund: ", response.data)
            if (response.status !== 200) {
                console.log(`search bank_list by user id failed`, response.message);
                return
            }

            dispatch({
                type: "TOAST2",
                value: {
                    show: true,
                    type: 'success',
                    title: `คืนยอดเสีย social`,
                    message: `upload คืนยอดเสีย สำเร็จ`,
                }
            });
        } catch (e) {
            console.log("upload bonus social error: ", e)
            dispatch({
                type: "TOAST2",
                value: {
                    show: true,
                    type: 'danger',
                    title: `คืนยอดเสีย social`,
                    message: `upload คืนยอดเสีย ไม่สำเร็จ`,
                }
            });
        }
        uploadFileRef.current.value = ""
        setShowConfirmUploadModal(false);
        setConfirmInput("");
    }

    const openConfirmUploadModal = () => {
        if (refundFiles !== undefined) {
            setShowConfirmUploadModal(true);
        } else {
            dispatch({
                type: "TOAST2",
                value: {
                    show: true,
                    type: 'danger',
                    title: `upload คืนยอดเสีย social`,
                    message: `กรุณาเลือกไฟล์ที่ต้องการอัพโหลด`,
                }
            });
        }
    };

    const closeConfirmUploadModal = () => {
        setShowConfirmUploadModal(false);
    };

    const getRefundWithoutKeyword = async () => {

        const response = await api_workorder.BONUS_SOCIAL_SEARCH(
            {
                token: login_profile.token,
                request: {
                    keyword: "",
                    date: startDate,
                    limit: limitPage,
                    last_element_id: dictLastIndices[currentPage]
                }
            }
        )

        if (response.status !== 200) {
            console.log(`search customer by user id failed`, response.message);
            return
        }
        // console.log("search refund customer response : ", dictLastIndices)
        if (response.data.data === undefined) {
            return
        }
        const {bonuses} = response.data.data
        if (bonuses === undefined) {
            return
        }

        // console.log("search bonus ", response.data.data)

        setDataTable(bonuses)
        if (bonuses) {
            const newIndex = {...dictLastIndices, [currentPage + 1]: bonuses[bonuses.length - 1].id}
            setDictLastIndices(newIndex)
        }
    }

    const getRefund = async () => {
        console.log("search keyword :", searchInput)
        setSearchButton(true)
        const response = await api_workorder.BONUS_SOCIAL_SEARCH(
            {
                token: login_profile.token,
                request: {
                    keyword: searchInput,
                    date: startDate,
                    limit: limitPage,
                    last_element_id: dictLastIndices[currentPage]
                }
            }
        )
        console.log("search customer by user id response: ", response);
        if (response.status !== 200) {
            console.log(`search customer by user id failed`, response.message);
            setSearchButton(false)
            return
        }

        if (response.data.data === undefined) {
            setSearchButton(false)
            return
        }
        const {bonuses, last_index} = response.data.data
        if (bonuses === undefined) {
            setSearchButton(false)
            return
        }

        console.log("search bonus ", response.data.data)

        setTimeout(() => {
            setSearchButton(false)
        }, 300)
        setDataTable(bonuses)
        if (bonuses) {
            const newIndex = {...dictLastIndices, [currentPage + 1]: bonuses[bonuses.length - 1].id}
            setDictLastIndices(newIndex)
        }
    }

    const handlePrevPage = () => {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1)
        }
    }

    const handleNextPage = () => {
        if (!dataTable) {
            return
        }

        if (dataTable.length === limitPage) {
            setCurrentPage(currentPage + 1)
        }
    }

    const renderStatus = (status) => {
        if (status === "success") {
            return <td><h5><Badge variant="success">{"สำเร็จ"}</Badge></h5></td>;
        } else if (status === "pending") {
            return <td><h5><Badge variant="info">{"รอลูกค้ากดรับ"}</Badge></h5></td>;
        } else {
            return <td><h5><Badge variant="danger">{"ไม่สำเร็จ"}</Badge></h5></td>;
        }
    }

    const retryData = (data) => {
        console.log(data)
        if (data.status === "failed") {
            setModalProps({
                ...modalProps,
                data: data,
                show: true,
                closeModal: {closeModal}
            })
        }
    }

    const closeModal = () => {
        setModalProps(
            {
                ...modalProps,
                show: false,
            })
        // getRefundWithoutKeyword()
    }

    // useEffect(() => {
    //     getRefundWithoutKeyword()
    // }, [startDate, currentPage])

    useEffect(() => {
        getRefund()
    }, [currentPage])

    return (
        <>
            <div className="pt-4 pr-4 pl-4">
                <CustomerSocialRetryModal
                    show={modalProps.show}
                    data={modalProps.data}
                    handleClose={closeModal}
                />
                <Modal
                    show={showConfirmUploadModal}
                    onHide={closeConfirmUploadModal}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>ยืนยันการคืนยอดเสีย</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group>
                                <Form.Label>กรุณาตรวจสอบไฟล์คืนยอดเสียก่อน พิมพ์ ยืนยัน การอัพโหลดไฟล์ โดยระบบจะไม่มีการลบไฟล์ย้อนหลังให้</Form.Label>
                                <Form.Control as="input"
                                              name=""
                                              placeholder="ยืนยัน"
                                              defaultValue=""
                                              onChange={(e) => setConfirmInput(e.target.value)}
                                >
                                </Form.Control>
                                {/*<p className="text-red-500 font-bold pl-1 pt-1">{errorConfirmMsg}</p>*/}
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={uploadRefundFiles}
                                disabled={confirmInput !== "ยืนยัน"}>ยืนยัน</Button>
                        <Button variant="secondary" onClick={() => closeConfirmUploadModal()}>
                            ปิด
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Card className="card-success">
                    <Card.Header>คืนยอดเสีย</Card.Header>
                    <Card.Body>
                        <Form>
                            <div className="mb-3">
                                <Form.Row className="col-md-12">
                                    <Form.File id="formcheck-api" className="pr-4">
                                        <Form.File.Label>ดาวน์โหลด Template ไฟล์คืนยอดเสีย</Form.File.Label>
                                        <div>
                                            <Button href="ufa_template.xlsx" download="ufa_template.xlsx"
                                                    variant="outline-primary">สำหรับ
                                                UFA</Button>{' '}
                                        </div>
                                    </Form.File>
                                </Form.Row>
                                <Form.Row className="col-md-12 pt-4">
                                    <Form.Group as={Col} md="3" lg="3" controlId="validation03">
                                        <Form.Label>Username</Form.Label>
                                        <Form.Control as="input"
                                                      name="username"
                                                      placeholder="eg. ufasocial"
                                                      defaultValue=""
                                                      onChange={(e) => setUsername(e.target.value)}
                                        >
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group as={Col} md="3" lg="3" controlId="validation04">
                                        <Form.Label>Password</Form.Label>
                                        <Form.Control as="input"
                                                      name="password"
                                                      type="password"
                                                      placeholder="eg. 12345678"
                                                      defaultValue=""
                                                      onChange={(e) => setPassword(e.target.value)}
                                        >
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group as={Col} md="3" lg="3" controlId="validation05">
                                        <Form.Label>Google Authentication Code</Form.Label>
                                        <Form.Control as="input"
                                                      name="google_auth"
                                                      placeholder="eg. 123456"
                                                      defaultValue=""
                                                      onChange={(e) => setGoogleAuth(e.target.value)}
                                        >
                                        </Form.Control>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row className="col-md-12 pt-4">
                                    <Form.Group as={Col} md="3" lg="3" controlId="validation02">
                                        <Form.File id="formcheck-api-regular">
                                            <Form.File.Label>อัพโหลดไฟล์คืนยอดเสีย</Form.File.Label>
                                            <Form.File.Input ref={uploadFileRef}
                                                             onChange={(e) => setRefundFiles(e.target.files)}/>
                                        </Form.File>

                                    </Form.Group>
                                </Form.Row>
                                <Form.Row className="col-md-12">
                                    <Form.Group as={Col} md="3" lg="3" controlId="validation02">
                                        <Button as={Col} md="6" variant="success"
                                                onClick={openConfirmUploadModal}>คืนยอดเสีย</Button>
                                    </Form.Group>
                                </Form.Row>
                            </div>
                        </Form>
                    </Card.Body>
                </Card>
                <Card className="card-primary">
                    <Card.Header>คืนยอดเสียรับเอง</Card.Header>
                    <Card.Body>
                        <Form>
                            <Form.Row className="col-md-12">
                                <Form.Group as={Col} md="6" lg="6" controlId="validation02">
                                    <Form.Label>เลือกวันที่ต้องการค้นหาใบงาน</Form.Label>
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <InputGroup.Text>Start-Date</InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <FormControl
                                            type="date"
                                            name="start_date"
                                            placeholder="2020-09-10"
                                            required
                                            // isInvalid={!!errors.start_date}
                                            onChange={(e) => setStartDate(moment(e.target.value).format("YYYY-MM-DD"))}
                                            defaultValue={startDate}
                                            // ref={register({ required: true })}
                                        />
                                        <Form.Control.Feedback type="invalid" tooltip>
                                            กรุณาเลือกวันที่ต้องการค้นหา
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} md="6" lg="6" className="col-md-12">
                                    <Form.Label>พิมพ์ค้นหาลูกค้า</Form.Label>
                                    <Form.Control as="input"
                                                  name="search_customer"
                                                  placeholder="eg. ufyu88xxxx/ahag2xxx, ชื่อ - นามสกุล, เบอร์โทรศัพท์, เลขที่บัญชี"
                                                  defaultValue=""
                                                  onBlur={(e) => setSearchInput(e.target.value)}
                                    >
                                    </Form.Control>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} md="6" lg="6" className="col-md-12">
                                    <Button onClick={() => getRefund()} disabled={searchButton}>
                                        {
                                            (searchButton) ?
                                                (<Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />) : (<>ค้นหา</>)
                                        }
                                    </Button>
                                </Form.Group>
                                <Pagination className={"ml-auto"}>
                                    <Pagination.Prev onClick={handlePrevPage}/>
                                    <Pagination.Item active={true}>
                                        {currentPage}
                                    </Pagination.Item>
                                    <Pagination.Next onClick={handleNextPage}/>
                                </Pagination>
                            </Form.Row>
                            <Table striped borderless hover responsive
                                   className="table table-striped table-valign-middle">
                                <thead className="text-center">
                                <tr>
                                    <th>#</th>
                                    <th>วันที่/เวลา</th>
                                    <th>UserID</th>
                                    <th>ชื่อ-นามสกุล</th>
                                    <th>จำนวนยอดเสีย</th>
                                    <th>จำนวนยอดคืน</th>
                                    <th>สถานะการคืนยอด</th>
                                    <th>ผู้ทำรายการ</th>
                                    <th>อัพเดทล่าสุด</th>
                                    <th>หมายเหตุ</th>
                                </tr>
                                </thead>
                                <tbody className="text-center">

                                {
                                    dataTable?.map((
                                        {
                                            id,
                                            created_at,
                                            user_id,
                                            first_name,
                                            last_name,
                                            lost_amount,
                                            amount,
                                            status,
                                            updated_at,
                                            updated_by,
                                            comment

                                        }, index) =>
                                        <tr key={id} onClick={() => retryData(dataTable[index], true)}>
                                            <td>{((currentPage - 1) * limitPage) + index + 1}</td>
                                            <td>{moment(created_at).format('DD/MM/YYYY HH:mm')}</td>
                                            <td>{user_id || "-"}</td>
                                            <td>{first_name || "-"} {last_name || "-"}</td>
                                            <td>{lost_amount || "-"}</td>
                                            <td>{amount}</td>
                                            {renderStatus(status)}
                                            <td>{updated_by}</td>
                                            <td>{moment(updated_at).format('DD/MM/YYYY HH:mm')}</td>
                                            <td>{comment}</td>
                                        </tr>
                                    )
                                }
                                </tbody>
                            </Table>
                            <Form.Row>
                                <Pagination className={"ml-auto"}>
                                    <Pagination.Prev onClick={handlePrevPage}/>
                                    <Pagination.Item active={true}>
                                        {currentPage}
                                    </Pagination.Item>
                                    <Pagination.Next onClick={handleNextPage}/>
                                </Pagination>
                            </Form.Row>
                        </Form>
                    </Card.Body>

                </Card>
            </div>
        </>
    )
}
