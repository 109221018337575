import { Button, Col, Form } from "react-bootstrap";
import React from "react";
import { useFieldArray } from "react-hook-form";
import { defaultLuckyDrawOptions } from './ManageLuckyDraw';
import { number_format } from "../utils/Number";

export default function LuckyDrawOptions ( props ) {
    const { register, control, watch } = props;

    const {
        fields,
        append,
        prepend,
        remove,
        swap,
        move,
        insert,
        replace
    } = useFieldArray( {
        control,
        name: "lucky_draw_options"
    } );

    const calAmountSummary = () => {
        let luckyDrawOptions = watch( "lucky_draw_options" );
        let sum = 0;
        for ( let i = 0; i < luckyDrawOptions.length; i++ ) {
            sum += (luckyDrawOptions[i]["amount"] * luckyDrawOptions[i]["quota"])
        }
        return sum;
    }

    return (
        <>
            <div className={"pb-3"}>
                <Form.Label>ตั้งค่าเกมชิงโชค</Form.Label>
                {fields.map( ( item, index ) => {
                    return (
                        <div key={item.id}>
                            <Form.Row className={"pt-2 px-3"}>
                                <Form.Label className={"pr-3"}>เงื่อนไขที่ {index + 1}</Form.Label>
                                <Form.Group as={Col} md="2" lg="2">
                                    <Form.Label>จำนวนเงิน (บาท)</Form.Label>
                                    <Form.Control
                                        name={`lucky_draw_options.${index}.amount`}
                                        type="number"
                                        defaultValue={item.amount}
                                        control={control}
                                        ref={register}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md="2" lg="2">
                                    <Form.Label>จำนวนคน</Form.Label>
                                    <Form.Control
                                        name={`lucky_draw_options.${index}.quota`}
                                        type="number"
                                        defaultValue={item.quota}
                                        control={control}
                                        ref={register}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md="2" lg="2">
                                    <Form.Label>ฝากขั้นต่ำ (บาท)</Form.Label>
                                    <Form.Control
                                        name={`lucky_draw_options.${index}.minimum_deposit_amount`}
                                        type="number"
                                        defaultValue={item.minimum_deposit_amount}
                                        control={control}
                                        ref={register}
                                    />
                                </Form.Group>
                                {
                                    ( watch(`lucky_draw_options.${index}.minimum_deposit_amount`) !== "0" && watch(`lucky_draw_options.${index}.minimum_deposit_amount`)!== "" ) ?
                                        <Form.Group as={Col} md="3" lg="3">
                                            <Form.Label>ฝากย้อนหลัง (วัน)</Form.Label>
                                            <Form.Control
                                                name={`lucky_draw_options.${index}.deposit_back_date`}
                                                type="number"
                                                defaultValue={item.deposit_back_date}
                                                control={control}
                                                ref={register}
                                            />
                                        </Form.Group> : <></>
                                }
                            </Form.Row>
                            <Form.Row className={"pt-2 pl-24"}>
                                <Form.Group as={Col} md="6" lg="6">
                                    <Form.Label>เจาะจง UserID</Form.Label>
                                    <Form.Control
                                        name={`lucky_draw_options.${index}.user_ids`}
                                        type="text"
                                        defaultValue={item.user_ids}
                                        control={control}
                                        ref={register}
                                        placeHolder="eg. ufyu8810012 หรือ ufyu8810012,ufyu8810013"
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md="3" lg="3">
                                    <Form.Label>ลิงค์รูปภาพ</Form.Label>
                                    <Form.Control
                                        name={`lucky_draw_options.${index}.image_url`}
                                        type="text"
                                        defaultValue={item.image_url}
                                        control={control}
                                        ref={register}
                                    />
                                </Form.Group>
                                {fields.length !== 1 && <Button
                                    variant="secondary"
                                    className="ml-2 mr-1 mt-4 h-12"
                                    onClick={() => remove( index )}>ลบ</Button>}
                                {( fields.length - 1 === index && fields.length !== 10 ) && <Button
                                    className="ml-1 mt-4 h-12"
                                    onClick={() => {
                                        append( defaultLuckyDrawOptions );
                                    }}
                                >เพิ่ม</Button>
                                }
                            </Form.Row>
                        </div>
                    );
                } )}
            </div>
            <Form.Row className={"pb-3"}>
                <p className={"text-red-500 font-bold"}>
                    ** สรุปเงินทั้งหมดที่แจกในเกมชิงโชค {number_format( calAmountSummary(), 2, '.', ',' )} บาท
                </p>
            </Form.Row>
        </>
    )
}