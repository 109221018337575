import {Button, Card, Col, Form} from "react-bootstrap";
import React, {useEffect} from "react";
import api from "../../api/managebank";
import moment from "moment";
import {useForm} from "react-hook-form";
import {useGlobalStore} from "../../store/globalContext";


export default function ManageHengPayConfig() {

    const { register:registerHengPay, handleSubmit, setValue:setValueHengPay, getValues, trigger, watch:watchHengPay, formState:{isDirty:isDirtyHengPay, dirtyFields},reset } = useForm({
        shouldUnregister: false
    });

    const [{ subscribe, banks, status, login_profile }, dispatch] =
        useGlobalStore();

    const getHengPayConfigFromAPI = async ()=>{
        try {
            const response =  await api.GET_HENGPAY_CONFIG()
            const {data} = response.data

            // console.log("get hengpay config response data : ", data)

            setValueHengPay("enable", data.enable)
            setValueHengPay("hengpay_shop_name", data.hengpay_shop_name)
            setValueHengPay("hengpay_callback_url", data.hengpay_callback_url)
            setValueHengPay("hengpay_secret_key", data.hengpay_secret_key)
        }catch (e) {
            console.log("get hengpay config error: ", e, e.response.data)
        }finally {

        }
    }

    const updateEnableHengPayStatus = async (checked)=>{
        console.log("updateEnableHengPayStatus checked: ", checked)

        try {
            const response =  await api.UPDATE_HENGPAY_CONFIG({"enable": checked})
            // console.log("get vizpay config response data : ", response)
            dispatch({
                type: "TOAST2",
                value: {
                    show: true,
                    type: "success",
                    title: `แก้ไขสถานะเปิด/ปิด ใช้งาน HENGPAY`,
                    message: `แก้ไขสถานะเปิด/ปิด ใช้งาน HENGPAY สำเร็จ`,
                },
            });
        }catch (e) {
            console.log("get hengpay config error: ", e, e.response.data)
            dispatch({
                type: "TOAST2",
                value: {
                    show: true,
                    type: "error",
                    title: `แก้ไขสถานะเปิด/ปิด ใช้งาน HENGPAY`,
                    message: `แก้ไขสถานะเปิด/ปิด ใช้งาน HENGPAY ไม่สำเร็จ ${e.response.data.message}`,
                },
            });
        }finally {
            reset(getValues())
        }
    }

    const submitHengPayConfig = async (submitValue)=>{

        let updateObject= {}

        Object.keys(dirtyFields).map((e)=>{
            updateObject[e] = getValues(e)
            if (e === "hengpay_secret_key"){
                updateObject["enable"] = false
            }

        })

        // console.log("update object: ", updateObject)
        // delete submitValue.enable;
        // delete submitValue.start_time;
        // delete submitValue.end_time;
        // delete submitValue.force_qr_customer;
        // console.log("watchQRNewUser: ", watchQRNewUser)
        try {

            const response =  await api.UPDATE_HENGPAY_CONFIG(updateObject)
            // console.log("get vizpay config response data : ", response)
            dispatch({
                type: "TOAST2",
                value: {
                    show: true,
                    type: "success",
                    title: `แก้ไขข้อมูล HENGPAY`,
                    message: `แก้ไขข้อมูล HENGPAY สำเร็จ`,
                },
            });
        }catch (e) {
            console.log("get hengpay config error: ", e, e.response.data)
            dispatch({
                type: "TOAST2",
                value: {
                    show: true,
                    type: "error",
                    title: `แก้ไขข้อมูล HENGPAY ไม่สำเร็จ`,
                    message: `แก้ไขข้อมูล HENGPAY ไม่สำเร็จ ${e.response.data.message}`,
                },
            });
        }finally {
            reset(getValues())
        }
    }

    useEffect(() => {
        void getHengPayConfigFromAPI()
    }, []);

    const watchEnable = watchHengPay("enable")

    return(
        <Card className={"card-fuchsia"}>
            <Card.Header><i className={"fas fa-qrcode pr-2"}/> จัดการข้อมูลแจ้งลูกค้าของ HENGPAY </Card.Header>
            <Card.Body>
                <Form noValidate onSubmit={handleSubmit(submitHengPayConfig)} id={"hengpay_form"}>
                    <Form.Row className={"flex my-2"}>
                        {/*<Col className={"col-6"}>*/}
                        {/*    <Form.Label>Callback-URL</Form.Label>*/}
                        {/*    <Form.Control id="vizpay_callback" name={"vizpay_callback"} ref={register} readOnly/>*/}
                        {/*</Col>*/}
                        <Col className={"col-xs-12 col-md-4"}>
                            <Form.Label>สถานะใช้งาน HENGPAY</Form.Label>
                            <Form.Switch
                                custom
                                id="enable_hengpay"
                                name="enable"
                                label={watchEnable === true ? "เปิดใช้งาน HENGPAY":"ปิด HENGPAY"}
                                onChange={(e)=>updateEnableHengPayStatus(e.target.checked)}
                                ref={registerHengPay}
                            />
                        </Col>
                        <Col className={"col-6"}>
                            <Form.Label>แก้ไขล่าสุดเมื่อ</Form.Label>
                            <Form.Control id="hengpay_updated_at" name={"updated_at"}  type="datatime" ref={registerHengPay} readOnly defaultValue={moment(new Date()).format("DD/MM/YYYY HH:mm")}/>
                        </Col>
                    </Form.Row>
                    <Form.Row>
                        <Col className={"col-4"}>
                            <Form.Label>Shop Name</Form.Label>
                            <Form.Control id="hengpay_shop_name" name={"hengpay_shop_name"}  type="input" ref={registerHengPay} defaultValue={""} readOnly/>
                        </Col>
                        <Col className={"col-8"}>
                            <Form.Label>Callback-URL</Form.Label>
                            <Form.Control id="hengpay_callback_url" name={"hengpay_callback_url"}  type="input" ref={registerHengPay} defaultValue={""} readOnly/>
                        </Col>
                    </Form.Row>
                    <Form.Row className={"pt-2"}>
                        <Col className={"col-12"}>
                            <Form.Label>Secret-Key</Form.Label>
                            <Form.Control id="hengpay_secret_key" name={"hengpay_secret_key"}  as="textarea" row={15} ref={registerHengPay} defaultValue={""}/>
                        </Col>
                    </Form.Row>
                    <Form.Row className={"pt-2"}>
                        <Button type={"submit"} className={"bg-fuchsia"} disabled={isDirtyHengPay === false}>ตั้งค่าจัดการ HENGPAY</Button>
                    </Form.Row>
                </Form>
            </Card.Body>
        </Card>
    )

}