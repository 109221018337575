import React, { useState } from 'react';
import { Button, Form, Spinner, Table } from "react-bootstrap";
import moment from "moment";
import luckydraw_api from "../../api/luckydraw";
import { useGlobalStore } from "../../store/globalContext";


export default function LuckyDrawStats ( props ) {
    const [ { login_profile } ] = useGlobalStore();
    const { data, id, onClose } = props;

    const [ keyword, setKeyword ] = useState( "" );
    const [ searchButton, setSearchButton ] = useState( false );
    const [ customerStats, setCustomerStats ] = useState( null );

    const handleSearch = async() => {
        setSearchButton( true );
        if ( id !== '' && id !== undefined ) {
            try {
                const response = await luckydraw_api.SEARCH_LUCKYDRAW_STATS( { id: id, user_id: keyword } )
                setCustomerStats( response.data.data?.customer_stats )
                setSearchButton( false );
            } catch ( err ) {
                console.log( err );
                setSearchButton( false );
            }
        }
    }

    return (
        <>
            {
                login_profile.role === "super_admin" &&
                <>
                    <div>
                        <p className='mb-3 font-bold text-red-500'>สถิติแคมเปญ</p>
                        <Table responsive hover size="md" className="text-center">
                            <thead className="text-center">
                            <tr>
                                <th>#</th>
                                <th>period_type</th>
                                <th>period_type_key</th>
                                <th>วันที่ update</th>
                                <th>จำนวนลูกค้าที่รับไปแล้ว (คน)</th>
                                <th>จำนวนเงินที่แจกไปแล้ว (บาท)</th>
                            </tr>
                            </thead>
                            <tbody className="text-center">
                            {
                                data?.campaign_stats ? data?.campaign_stats.map( ( {
                                                                                       id,
                                                                                       count,
                                                                                       total_amount,
                                                                                       updated_at,
                                                                                       period_type,
                                                                                       period_type_key,
                                                                                   }, index ) =>
                                        <tr key={index + 1} className="" objectId={id} style={{ "cursor": "pointer" }}>
                                            <td><h6>{index + 1}</h6></td>
                                            <td><h6>{period_type}</h6></td>
                                            <td><h6>{period_type_key}</h6></td>
                                            <td><h6>{moment( updated_at ).format( 'DD/MM/YYYY HH:mm:ss' )}</h6></td>
                                            <td><h6>{count}</h6></td>
                                            <td><h6>{total_amount}</h6></td>
                                        </tr>
                                    ) :
                                    <p>ไม่มีข้อมูล</p>
                            }
                            </tbody>
                        </Table>
                    </div>
                    <div>
                        <p className='my-3 font-bold text-red-500'>สถิติแคมเปญแยกตามจำนวนเงินที่ตั้งค่า</p>
                        <Table responsive hover size="md" className="text-center">
                            <thead className="text-center">
                            <tr>
                                <th>#</th>
                                <th>period_type</th>
                                <th>period_type_key</th>
                                <th>วันที่ update</th>
                                <th>จำนวนลูกค้าที่รับไปแล้ว (คน)</th>
                                <th>จำนวนเงินที่แจกไปแล้ว (บาท)</th>
                                <th>amount (บาท)</th>
                            </tr>
                            </thead>
                            <tbody className="text-center">
                            {
                                data?.campaign_amount_stats ? data.campaign_amount_stats.map( ( {
                                                                                                    id,
                                                                                                    count,
                                                                                                    total_amount,
                                                                                                    amount,
                                                                                                    updated_at,
                                                                                                    period_type,
                                                                                                    period_type_key,
                                                                                                }, index ) =>
                                    <tr key={index + 1} className="" objectId={id} style={{ "cursor": "pointer" }}>
                                        <td><h6>{index + 1}</h6></td>
                                        <td><h6>{period_type}</h6></td>
                                        <td><h6>{period_type_key}</h6></td>
                                        <td><h6>{moment( updated_at ).format( 'DD/MM/YYYY HH:mm:ss' )}</h6></td>
                                        <td><h6>{count}</h6></td>
                                        <td><h6>{total_amount}</h6></td>
                                        <td><h6>{amount}</h6></td>
                                    </tr>
                                ) : <p>ไม่มีข้อมูล</p>
                            }
                            </tbody>
                        </Table>
                    </div>
                </>
            }
            <div>
                <p className='my-3 font-bold text-red-500'>สถิติการรับโบนัสของลูกค้ารายคน</p>
                <Form.Row className="col-md-12">
                    <Form.Group className="col-md-6">
                        <Form.Label>พิมพ์ค้นหาลูกค้า</Form.Label>
                        <Form.Control as="input"
                                      name="search_customer_luckydrawstats"
                                      placeholder="eg. ufyu88xxxx/ahag2xxx"
                                      defaultValue=""
                                      onChange={( e ) => setKeyword( e.target.value )}
                        >
                        </Form.Control>
                    </Form.Group>
                </Form.Row>
                <Form.Row className="col-md-12">
                    <Button style={{ marginBottom: "10px" }}
                            onClick={handleSearch}
                            disabled={searchButton}
                    >
                        {
                            ( searchButton ) ?
                                ( <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                /> ) : ( <>ค้นหา</> )
                        }
                    </Button>
                </Form.Row>
                <Table responsive hover size="md" className="text-center">
                    <thead className="text-center">
                    <tr>
                        <th>#</th>
                        <th>period_type</th>
                        <th>period_type_key</th>
                        <th>วันที่ update</th>
                        <th>user_id</th>
                        <th>จำนวนลูกค้าที่รับไปแล้ว (คน)</th>
                        <th>จำนวนเงินที่แจกไปแล้ว (บาท)</th>
                        <th>amount (บาท)</th>
                    </tr>
                    </thead>
                    <tbody className="text-center">
                    {
                        customerStats ?
                            customerStats.map( ( {
                                                     id,
                                                     count,
                                                     total_amount,
                                                     amount,
                                                     user_id,
                                                     updated_at,
                                                     period_type,
                                                     period_type_key,
                                                 }, index ) =>
                                <tr key={index + 1} className="" objectId={id} style={{ "cursor": "pointer" }}>
                                    <td><h6>{index + 1}</h6></td>
                                    <td><h6>{period_type}</h6></td>
                                    <td><h6>{period_type_key}</h6></td>
                                    <td><h6>{moment( updated_at ).format( 'DD/MM/YYYY HH:mm:ss' )}</h6></td>
                                    <td><h6>{user_id}</h6></td>
                                    <td><h6>{count}</h6></td>
                                    <td><h6>{total_amount}</h6></td>
                                    <td><h6>{amount}</h6></td>
                                </tr>
                            )
                            : <p>
                                ไม่มีข้อมูล
                            </p>
                    }
                    </tbody>
                </Table>
            </div>
            <Button variant="secondary" onClick={onClose}>ปิด</Button>
        </>
    )
}