import axios from '../client/axios'
import config from '../config/index'

// //console.log('config ', config);
export default {
  DASHBOARD_DEPOSIT_WITHDRAW_STATS: (keyword) => {
    //console.log("get dashboard deposit withdraw stats api: ", keyword)
    return axios({
      //headers: {},
      method: 'POST',
      url: `${config.DASHBOARD_WITHDRAW_STATS}`,
      data:keyword
    })
  },
  DASHBOARD_WORK_ORDER: (keyword) => {
    //console.log("get dashboard deposit withdraw accum api: ", keyword)
    return axios({
      //headers: {},
      method: 'POST',
      url: `${config.DASHBOARD_WORK_ORDER}`,
      data:keyword
    })
  },
  DASHBOARD_ACTIVE_USERS: (keyword) => {
    //console.log("get dashboard active users api: ", keyword)
    return axios({
      //headers: {},
      method: 'POST',
      url: `${config.DASHBOARD_ACTIVE_USERS}`,
      data:keyword
    })
  },
  DASHBOARD_NEW_USERS: (keyword) => {
    //console.log("get dashboard new users api: ", keyword)
    return axios({
      //headers: {},
      method: 'POST',
      url: `${config.DASHBOARD_NEW_USERS}`,
      data:keyword
    })
  },
  DASHBOARD_BONUS_LOST_STATS: (request) => {
    //console.log("get bonus lost stats: ", request)
    return axios({
      method: 'POST',
      url: `${config.DASHBOARD_BONUS_LOST_STATS}`,
      data: request,
    })
  },
  DASHBOARD_CUSTOMER_STATS: (request) => {
    //console.log("get customer top10 stats: ", request)
    return axios({
      method: 'POST',
      url: `${config.DASHBOARD_CUSTOMER_STATS}`,
      data: request,
    })
  },
  DASHBOARD_WARNING: () => {
    //console.log("get dashboard warning:")
    return axios({
      method: 'GET',
      url: `${config.DASHBOARD_WARNING}`,
    })
  },
  DASHBOARD_CUSTOMER_NO_DEPOSIT: (request) => {
    //console.log("get customer no deposit : ", request)
    return axios({
      method: 'POST',
      url: `${config.DASHBOARD_CUSTOMER_NO_DEPOSIT}`,
      data: request,
    })
  },
  DASHBOARD_AGENTS_SUMMARY: (request) => {
    //console.log("get agent : ", request)
    return axios({
      method: 'POST',
      url: `${config.DASHBOARD_AGENTS_SUMMARY}`,
      data: request,
    })
  },
  DASHBOARD_AGENTS_WORKORDERS: (request) => {
    //console.log("get agent : ", request)
    return axios({
      method: 'POST',
      url: `${config.DASHBOARD_AGENTS_WORKORDERS}`,
      data: request,
    })
  },
  DASHBOARD_CUSTOMER_CHANNEL: (request) => {
    //console.log("get register channel : ", request)
    return axios({
      method: 'POST',
      url: `${config.DASHBOARD_CUSTOMER_CHANNEL}`,
      data: request,
    })
  },
  DASHBOARD_CUSTOMER_MONEY_SITE: (request) => {
    //console.log("get register money site : ", request)
    return axios({
      method: 'POST',
      url: `${config.DASHBOARD_CUSTOMER_MONEY_SITE}`,
      data: request,
    })
  },
  CC_WORK_ORDERS:(request) =>{
    return axios({
      method: 'POST',
      url: `${config.CC_WORK_ORDERS}`,
      data: request,
    })
  },
  DASHBOARD_BONUS_AFFILIATE_STATS: (request) => {
    //console.log("get bonus lost stats: ", request)
    return axios({
      method: 'POST',
      url: `${config.DASHBOARD_BONUS_AFFILIATE_STATS}`,
      data: request,
    })
  },
  DASHBOARD_GATEWAY_STATS: (request) => {
    //console.log("get bonus lost stats: ", request)
    return axios({
      method: 'POST',
      url: `${config.DASHBOARD_GATEWAY_STATS}`,
      data: request,
    })
  },
}