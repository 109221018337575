import {Button, Card, Col, Form, Row, Spinner, Table} from "react-bootstrap";
import React, {useContext, useEffect, useState} from "react";
import api_dashboard from "../../api/dashboard";
import api from "../../api/ccusers";
import api_workorder from "../../api/workorder";
import {useForm} from "react-hook-form";
import moment from "moment/moment";
import {useGlobalStore} from "../../store/globalContext";
import {ReloadHistoryContext} from "./ManageCustomerGameCash";


const TableCCHistoryLogs = (props) =>{
    const {historyLogsData} = props

    return(
        <Table striped bordered hover responsive className={"mt-2"}>
            <thead>
            <tr className={"text-center"}>
                <th>TXN ID</th>
                <th>
                    ยูสเซอร์ ID<br/>
                    ชื่อ - นามสกุล
                </th>
                {/*<th>ชื่อ - นามสกุล</th>*/}
                <th>รูปแบบการปรับ</th>
                <th>สถานะปรับ</th>
                <th>ปรับเมื่อ</th>
                <th>ปรับโดย</th>
                <th>ยอดเงินที่ทำรายการ</th>
                <th>ปรับยอดเงิน</th>
                <th>ปรับเครดิตลิมิต</th>
                <th>ปรับเครดิต</th>
            </tr>
            </thead>
            <tbody>
            {
                historyLogsData?.map(d => {
                    return (
                        <tr key={d.transaction_id} className={"text-center"}>
                            <td className={"nowrap"}>{d.transaction_id || "-"}</td>
                            <td className={"nowrap"}>
                                {d.user_id || "-"}<br/>
                                {d.first_name || "-"} {d.last_name}
                            </td>
                            {/*<td className={"nowrap"}>{d.first_name || "-"} {d.last_name}</td>*/}
                            <td className={"nowrap"}>{d.transfer_type || "-"}</td>
                            <td className={"nowrap"}>{d.status || "-"}</td>
                            <td className={"nowrap"}>{moment(d.created_at).format('DD/MM/YYYY HH:mm') || "-"}</td>
                            <td className={"nowrap"}>{d.created_by || "-"}</td>
                            <td className={"nowrap"}>{d.amount || "-"}</td>
                            <td className={"nowrap"}>{d.before_balance || "-"} > {d.after_balance || "-"}</td>
                            <td className={"nowrap"}>{d.before_credit_limit || "-"} > {d.after_credit_limit || "-"}</td>
                            <td className={"nowrap"}>{d.before_credit || "-"} > {d.after_credit || "-"}</td>
                            {/*<td className={"nowrap"}>{d.after_balance || "-"}</td>*/}
                            {/*<td className={"nowrap"}>{d.after_credit_limit || "-"}</td>*/}
                            {/*<td className={"nowrap"}>{d.after_credit || "-"}</td>*/}
                        </tr>
                    )
                })
            }
            </tbody>
        </Table>
    )
}
export default function ManageHistoryCustomerGameCash() {
    const value = useContext(ReloadHistoryContext);

    const [{login_profile}, dispatch] = useGlobalStore();
    // console.log("login_profile: ", login_profile)

    const {register:registerHistoryTransfer, handleSubmit:handleSubmitHistoryTransfer, watch:watchHistoryTransfer,
        errors:errorsHistoryTransfer, reset:resetHistoryTransfer, setValue:setValueHistoryTransfer,
        getValues:getValueHistoryTransfer, formState: { errors, isSubmitting ,isSubmitted }
    } = useForm({
        defaultValues:{created_by:""},
        criteriaMode: "all",
        shouldUnregister:true
    });


    const [ccUsers, setCCusers] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [ccHistoryLogs, setCCHistoryLogs] = useState([])
    const getCCUsers = async (keyword)=>{
        try {
            const response = await api.SEARCH_CC_USER({
                keyword:getValueHistoryTransfer("created_by")
            })
            if (response.status !== 200) {
                //console.log("get CC user failed ");
                return
            }
            //console.log("get CC user response :", response);
            const { data } = response.data
            // console.log("get CC user data :", data);
            setCCusers(data)
        } catch (error) {
            //console.log("error get CC user data :", error);
        }
    }

    const submitTransferCCHistoryLogs = (submitData)=>{
        // console.log("submitTransferCCHistoryLogs submitData: ", isSubmitting)
        void searchCCHistoryLogs(submitData)
    }

    const searchCCHistoryLogs = async (submitData)=>{
        try {
            setIsLoading(true)
            const response = await api_workorder.SEARCH_TRANSFER(submitData)
            const {data} = response.data
            // console.log("search cc history logs data: ", data)
            setCCHistoryLogs(data)
        }catch (e){
            console.log("search cc history logs error: ", e.response.message)
        }finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        void getCCUsers();
        void searchCCHistoryLogs({
            user_id:"",
            created_by:"",
            start_date: moment(new Date(new Date().setDate(new Date().getDate() - 7))).format("YYYY-MM-DD"),
            end_date: moment(new Date()).format("YYYY-MM-DD")
        })
    }, []);

    useEffect(()=>{
        // console.log("use effect value: ", value)
        if(value.triggerCC === true){
            void searchCCHistoryLogs({
                user_id:"",
                created_by:"",
                start_date: moment(new Date()).format("YYYY-MM-DD"),
                end_date: moment(new Date()).format("YYYY-MM-DD")
            })
        }
    value.setTriggerCC(false)
    },[value])

    return(
        <div>
            <Card className={"card-fuchsia"}>
                <Card.Header>ตรวจสอบผู้ทำรายการปรับยอดเงินลูกค้า</Card.Header>
                <Card.Body>
                    <Form key={"history_ref"} onSubmit={handleSubmitHistoryTransfer(submitTransferCCHistoryLogs)}>
                        <Row>
                            <Form.Group as={Col}>
                                <Form.Label>กรุณาระบุ UserID</Form.Label>
                                <Form.Control
                                    ref={registerHistoryTransfer}
                                    name={"keyword"}
                                    placeholder={"ufyxxxxxx"}
                                />
                            </Form.Group>
                            <Form.Group as={Col} hidden={login_profile.name !== "Programmer Admin"}>
                                <Form.Label>รายชื่อ CC หน้างาน</Form.Label>
                                <Form.Control
                                    as={"select"}
                                    id={"created_by"}
                                    name={"created_by"}
                                    ref={registerHistoryTransfer}
                                    custom
                                    // onClick={()=>getCCUsers()}
                                    isInvalid={!!errors.created_by}
                                >
                                    <option key={"00"} value={""}>
                                        กรุณาเลือก CC หน้างาน
                                    </option>
                                    {
                                        ccUsers?.map(bl =>
                                                    <option key={bl.id} value={bl.username}>
                                                        {`${bl.name} - ${bl.role} - ${bl.username}`}
                                                    </option>
                                        )
                                    }
                                </Form.Control>
                                <Form.Control.Feedback type="invalid" tooltip>กรุณาเลือก CC หน้างานในการค้นหา</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>วันที่เริ่มต้น</Form.Label>
                                <Form.Control
                                    ref={registerHistoryTransfer}
                                    type={"date"}
                                    name={"start_date"}
                                    defaultValue={moment(new Date(new Date().setDate(new Date().getDate() - 7))).format("YYYY-MM-DD")}
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>วันที่สิ้นสุด</Form.Label>
                                <Form.Control
                                    ref={registerHistoryTransfer}
                                    type={"date"}
                                    name={"end_date"}
                                    defaultValue={moment(new Date()).format("YYYY-MM-DD")}
                                />
                            </Form.Group>
                        </Row>
                        <Row>
                            <Col>
                                <Button style={{marginBottom: "10px"}} type={"submit"}
                                    // onClick={() => keySearchInput(getValues("search_customer"), watchFieldSearch,false)} disabled={searchButton}
                                >
                                    {
                                        (isLoading === true)?
                                            (<Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                                className={"justify-content-center"}
                                            />):(<>ค้นหา</>)
                                    }

                                </Button>
                                {" "}
                                <Button variant={"danger"} style={{marginBottom: "10px"}}
                                        onClick={() => {
                                            resetHistoryTransfer();
                                            void searchCCHistoryLogs({
                                                user_id:"",
                                                created_by:"",
                                                start_date: moment(new Date(new Date().setDate(new Date().getDate() - 7))).format("YYYY-MM-DD"),
                                                end_date: moment(new Date()).format("YYYY-MM-DD")
                                            })
                                        }}
                                >
                                    <>ล้างข้อมูล</>
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                    <TableCCHistoryLogs historyLogsData={ccHistoryLogs}/>
                </Card.Body>
            </Card>
        </div>
    )
}