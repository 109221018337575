import React from 'react'
import {Button, Table} from 'react-bootstrap'
import {number_format} from "../utils/Number";

export default function TableInvoiceSummaryHistory(props) {
    const {data, editData} = props;

    return (
        <Table responsive hover size="md" className="">
            <thead>
            <tr data-aos="fade-down">
                <th>#หมายเลขบิล</th>
                <th>รอบเดือน</th>
                <th>ยอดชำระ (บาท)</th>
                <th>สถานะ</th>
            </tr>
            </thead>
            <tbody>
            {
                (data.length > 0) && data?.map(({
                                                    invoice_number,
                                                    month,
                                                    amount,
                                                    status,
                                                    invoice_detail,
                                                }, index) =>
                    <tr key={index + 1} className="" style={{"cursor": "pointer"}}
                    >
                        <td><h6>{invoice_number}</h6></td>
                        <td><h6>{month}</h6></td>
                        <td><h6>{number_format(amount)}</h6></td>
                        <td>
                            <h6 className={"flex justify-between"}>
                                <div>{status}</div>
                                <Button onClick={() => editData(data[index], true)}>ดูรายละเอียด</Button>
                            </h6>
                        </td>
                    </tr>
                )
            }
            </tbody>
        </Table>
    )
}
