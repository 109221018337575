import axios from "../client/axios";
import config from "../config";

export default {
  INVOICE: (request) => {
    console.log("get invoice for site: ", request)
    return axios({
      method: 'POST',
      url: `${ config.INVOICE }`,
      data: request,
    })
  },
  INVOICE_GET_ACCOUNT: () => {
    return axios({
      method: 'GET',
      url: `${ config.INVOICE_GET_ACCOUNT }`
    })
  },
  INVOICE_UPDATE_ACCOUNT: (request) => {
    return axios({
      method: 'POST',
      url: `${ config.INVOICE_UPDATE_ACCOUNT }`,
      data: request,
    })
  },
  INVOICE_GET_SLIP: () => {
    return axios({
      method: `GET`,
      url: `${ config.INVOICE_GET_SLIP }`
    })
  },
  INVOICE_UPLOAD_SLIP: (request) => {
    return axios({
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      method: 'POST',
      url: `${ config.INVOICE_UPLOAD_SLIP }`,
      data: request,
    })
  },
  INVOICE_MARK_PAID: (request) => {
    return axios({
      method: 'POST',
      url: `${ config.INVOICE_MARK_PAID }`,
      data: request,
    })
  },
  INVOICE_GET_ALL_SLIP: (request) => {
    return axios( {
      method: 'POST',
      url: `${config.INVOICE_GET_ALL_SLIP}`,
      data: request,
    } )
  },
  INVOICE_SUMMARY_HISTORY: () => {
    return axios( {
      method: 'GET',
      url: `${config.INVOICE_SUMMARY_HISTORY}`,
    } )
  }
}