import React, {useEffect, useMemo, useRef, useState} from 'react'
import {
    route_config,
    route_config_fever,
    route_config_lucky,
    route_config_pgadmin, route_multiple_config, route_multiple_config_no_affiliate,
    route_simple_config
} from '../route_config'
import {Link, NavLink} from 'react-router-dom'
import localStorage from "../store/localStorage"

export default function MenuComponent() {
    const role = localStorage.getAccessRole()
    const name = localStorage.getAccessName()
    let routes = null

    const [renderMenu, setRenderMenu] = useState(null)

    const renderMenuMemo = useMemo(()=>{
        // console.log("renderMenu: ", renderMenu)
        return renderMenu
    },[renderMenu])

    useEffect(()=>{
        window.$('[data-widget="treeview"]').Treeview('init');
    },[renderMenu])

    useEffect(() => {
        // console.log("in useEffect")
        if (!role) {
            return null
        }

        if (name === "Programmer Admin") {

            if(process.env.REACT_APP_LOGIN_AGENT_NAME === "ufa8888"){
                routes = route_multiple_config
            }else{
                routes = route_multiple_config_no_affiliate
            }
            setRenderMenu(routes)
        } else {
            let update_route = []

            if(process.env.REACT_APP_LOGIN_AGENT_NAME === "ufa8888"){
                route_multiple_config.menus.map(m=>{
                    const {sub_menu_list} = m
                    m.sub_menu_list = sub_menu_list.filter((m)=>
                        m.allow_role.indexOf(role) > -1
                    )

                    if( m.sub_menu_list.length > 0){
                        update_route.push(m)
                    }
                })
            }else{
                // console.log(process.env.REACT_APP_ALLOW_FEVER)
                route_multiple_config_no_affiliate.menus.map(m=>{
                    const {sub_menu_list} = m
                    m.sub_menu_list = sub_menu_list.filter((m)=>
                        m.allow_role.indexOf(role) > -1)

                    if(process.env.REACT_APP_ALLOW_FEVER !== "enable")
                        m.sub_menu_list = m.sub_menu_list.filter(mf =>
                            mf.allow_fever !== true
                    )

                    if( m.sub_menu_list.length > 0){
                        update_route.push(m)
                    }
                })
            }


            routes = {
                menus: update_route,
            }

            setRenderMenu(routes)
        }
    }, []);

    // console.log(inputRef.current.value)
    return (
        <>
                {
                    renderMenuMemo?.menus?.map(({name, path, icon, menu, submenu_flag, sub_menu_list}, index) => {
                        return (
                        <ul className="nav nav-pills nav-sidebar flex-column" data-widget={"treeview"} role="menu" data-accordion="true" key={index}>
                            <li className="nav-item">
                                {
                                    (submenu_flag === true) &&
                                        <div className={`nav-item has-treeview ${menu || ""}`}>
                                            {
                                                <NavLink exact to={"#"} className="nav-link"
                                                         activeClassName="nav-link active"
                                                >
                                                    <i className={`nav-icon fas ${icon}`}/>
                                                    <p className={"text-bold"}>
                                                        {name}
                                                        <i className="right fas fa-angle-left"></i>
                                                    </p>
                                                </NavLink>
                                            }
                                            <ul className="nav nav-treeview">
                                                {
                                                    sub_menu_list.map(({name, path, icon}, index) => {
                                                        return (
                                                            <li className="nav-item" key={index}>
                                                                {/* <Link to={path} className="nav-link"> */}
                                                                <NavLink exact to={path}
                                                                         className="nav-link"
                                                                         activeClassName="active text-white bg-gradient-green"
                                                                >
                                                                    <i className={`nav-icon fas ${icon} nav-icon`}></i>
                                                                    <p className={"text-bold"}>{name}</p>
                                                                    {/* </Link> */}
                                                                </NavLink>
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </div>
                                        }
                                        {
                                            !submenu_flag &&
                                            <Link to={path} className="nav-link">
                                                <i className={`nav-icon fas ${icon} nav-icon`}></i>
                                                <p>{name}</p>
                                            </Link>
                                        }
                                    </li>
                                    </ul>
                    )
                })
            }
        </>
    )
}
