import React, { useState } from 'react'
import { Alert, Button, Col, Form, Modal, Spinner } from 'react-bootstrap'
import EditRegisterCustomer from './EditRegisterCustomer'
import { useForm } from 'react-hook-form'
import api_blacklist from '../../api/blacklist'
import api_customer from '../../api/customer'
import { useGlobalStore } from '../../store/globalContext'

export default function ModalSearchCustomer ( props ) {
  const { customerData, isOpenModal, onSave, onClose, callBackSearch, alertError } = props
  const [ { login_profile }, dispatch ] = useGlobalStore();
  const [ lockUnlockStatus, setLockUnlockStatus ] = useState( "" )
  const { register, handleSubmit, watch, errors, setValue } = useForm(
    {
      defaultValues: {
        user_id: customerData?.user_id,
        first_name: customerData?.first_name,
        last_name: customerData?.last_name,
        status: customerData?.status,
        lock_unlock: lockUnlockStatus,
      },
      criteriaMode: "all"
    } );
  const [ openConfirmBlackListModal, setOpenConfirmBlackListModal ] = useState( false )
  const [ validated, setValidated ] = useState( false );
  const [ showConfirmDeleteUser, setShowConfirmDeleteUser ] = useState( false )
  const [ showConfirmResetPassword, setShowConfirmResetPassword ] = useState( false )
  const [ resetLoading, setResetLoading ] = useState( false )
  const [ openConfirmLockUnlockUserModal, setOpenConfirmLockUnlockUserModal ] = useState( false )

  const onSubmit = async editData => {
    //console.log("EDIT DATA", editData);
    const blacklist_request = {
      user_id: editData.user_id,
      comment: editData.comment
    }

    const response = await api_blacklist.BLACK_LIST( blacklist_request )
    if ( response.status !== 200 ) {
      console.log( "create black list failed" );
      return
    }

    const { data } = response.data
    console.log( "create black list response data: ", data )
    setOpenConfirmBlackListModal( false )
    onClose()
    callBackSearch();
    dispatch( {
      type: "TOAST2",
      value: {
        show: true,
        type: 'success',
        title: `เพิ่มข้อมูล Blacklist`,
        message: `เพิ่มข้อมูล ${editData.user_id} ไปยัง Blacklist สำเร็จ`,
      }
    } );
  };

  const onSubmitLockUnlockUser = async( status ) => {
    setResetLoading( true )
    const lockUnlockUser = {
      user_id: customerData?.user_id,
      lock_unlock: status
    }

    await api_customer.LOCK_UNLOCK_CUSTOMER( lockUnlockUser )
      .then( response => {
        console.log( "lock unlock response :", response )
        callBackSearch();
        dispatch( {
          type: "TOAST2",
          value: {
            show: true,
            type: 'success',
            title: `ล็อค / ปลดล็อด User`,
            message: `${lockUnlockUser.lock_unlock} User ${lockUnlockUser.user_id} สำเร็จ`,
          }
        } );

      } ).catch( error => {
        console.log( "lock unlock error :", error )
        dispatch( {
          type: "TOAST2",
          value: {
            show: true,
            type: 'danger',
            title: `ล็อค / ปลดล็อด User`,
            message: `${lockUnlockUser.lock_unlock} User ${lockUnlockUser.user_id} ไม่สำเร็จ กรุณาติดต่อ admin`,
          }
        } );
      } )
    setOpenConfirmLockUnlockUserModal( false );
    setLockUnlockStatus( "" );
    setResetLoading( false )
    onClose();
  }

  const handleLockUnlockClose = async() => {
    setOpenConfirmLockUnlockUserModal( false )
    setLockUnlockStatus( "" )
  }

  const onDeleteCustomer = async() => {
    const deleteUser = {
      id: customerData?.id,
      user_id: customerData?.user_id,
    }

    const response = await api_customer.DELETE_CUSTOMER( deleteUser )
    if ( response.status !== 200 ) {
      console.log( "delete customer failed" );
      setShowConfirmDeleteUser( false );
      onClose();
      dispatch( {
        type: "TOAST2",
        value: {
          show: true,
          type: 'danger',
          title: `ลบข้อมูล user`,
          message: `ลบข้อมูล ${deleteUser.user_id} ไม่สำเร็จ กรุณาติดต่อ admin`,
        }
      } );
      return
    }
    setShowConfirmDeleteUser( false );
    onClose();
    callBackSearch();
    dispatch( {
      type: "TOAST2",
      value: {
        show: true,
        type: 'success',
        title: `ลบข้อมูล user`,
        message: `ลบข้อมูล ${deleteUser.user_id} สำเร็จ`,
      }
    } );

  }

  const onResetPassword = async() => {
    //console.log("customer data :", customerData)
    setResetLoading( true )
    const resetUser = {
      user_id: customerData?.user_id,
    }

    await api_customer.RESET_CUSTOMER_PASSWORD( resetUser )
      .then( response => {
        console.log( "reset password response :", response )
        callBackSearch();
        dispatch( {
          type: "TOAST2",
          value: {
            show: true,
            type: 'success',
            title: `Reset Password`,
            message: `Reset Password ${resetUser.user_id} สำเร็จ`,
          }
        } );

      } ).catch( error => {
        console.log( "reset password error :", error )
        dispatch( {
          type: "TOAST2",
          value: {
            show: true,
            type: 'danger',
            title: `Reset Password`,
            message: `Reset Password ${resetUser.user_id} ไม่สำเร็จ กรุณาติดต่อ admin`,
          }
        } );
      } )
    setShowConfirmResetPassword( false );
    setResetLoading( false )
    onClose();
  }

  const handleLockUnlockChange = ( event ) => {
    setLockUnlockStatus( event.target.value );
  };

  return (
    <>
      <Modal show={showConfirmDeleteUser}>
        <Modal.Header>
          <Modal.Title>ยืนยันการลบ User {customerData?.user_id}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>กรุณายืนยันทำการลบ User {customerData?.user_id}</p>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="success" onClick={() => onDeleteCustomer()}>ยืนยัน</Button>{'  '}
          <Button variant="secondary" onClick={() => setShowConfirmDeleteUser( false )}>ปิด</Button>
        </Modal.Footer>
      </Modal>
      <Modal show={isOpenModal} size="xl">
        <Modal.Header>
          <Modal.Title>{customerData?.user_id} {customerData?.first_name} {customerData?.last_name}</Modal.Title>
          <Modal.Title>
            <Button variant="primary" onClick={() => setShowConfirmResetPassword( true )}>Reset รหัสผ่าน</Button>
            {'  '}
            {
              ( login_profile.role === "super_admin" || login_profile.role === "advanced_admin" || login_profile.role === "admin" ) ?
                ( <Button variant="danger" onClick={() => setShowConfirmDeleteUser( true )}>ลบ User นี้</Button> )
                : ( <></> )
            }
            {'  '}
            <Button variant="warning" onClick={() => setOpenConfirmBlackListModal( true )}>เพิ่มเข้า Blacklist</Button>
            {'  '}
            {
              ( login_profile.role === "super_admin" || login_profile.role === "advanced_admin" || login_profile.role === "admin" || login_profile.role === "user_swap" ) ?
                <Button variant="success" onClick={() => setOpenConfirmLockUnlockUserModal( true )}>ล็อค / ปลดล็อด
                  User</Button>
                : <></>
            }
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          {
            ( alertError !== "" ) ?
              ( <Alert variant={"danger"}>{alertError}</Alert> ) : ( <></> )
          }

          <EditRegisterCustomer data={customerData} onSubmitForm={onSave} onClose={onClose} alertError={alertError}/>
        </Modal.Body>
      </Modal>
      <Modal show={openConfirmBlackListModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>ยืนยันเพิ่ม Blacklist</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleSubmit( onSubmit )}>
            <Form.Row>
              <Form.Group as={Col} md="6" controlId="validationFormik01">
                <Form.Label>User ID</Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="user_id"
                  // value={first_name}
                  // onChange={handleChange}
                  placeholder="-"
                  defaultValue={customerData?.user_id}
                  ref={register( { required: true } )}
                  readOnly
                  isInvalid={!!errors.user_id}
                />
                {/* <Form.Control.Feedback></Form.Control.Feedback> */}
                <Form.Control.Feedback type="invalid" tooltip>
                  กรุณาระบุ user id
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} md="6" controlId="validationFormik02">
                <Form.Label>ชื่อ</Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="first_name"
                  // value={first_name}
                  // onChange={handleChange}
                  placeholder="-"
                  defaultValue={customerData?.first_name}
                  ref={register( { required: true } )}
                  readOnly
                  isInvalid={!!errors.first_name}
                  // isValid={touched.firstName && !errors.firstName}
                />
                {/* <Form.Control.Feedback></Form.Control.Feedback> */}
                <Form.Control.Feedback type="invalid" tooltip>
                  กรุณาระบุชื่อ
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="validationFormik03">
                <Form.Label>นามสกุล</Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="last_name"
                  // value={first_name}
                  // onChange={handleChange}
                  placeholder="-"
                  defaultValue={customerData?.last_name}
                  ref={register( { required: true } )}
                  readOnly
                  isInvalid={!!errors.last_name}
                  // isValid={touched.firstName && !errors.firstName}
                />
                {/* <Form.Control.Feedback></Form.Control.Feedback> */}
                <Form.Control.Feedback type="invalid" tooltip>
                  กรุณาระบุนามสกุล
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} md="12" controlId="validationFormik04">
                <Form.Label>Comment</Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="comment"
                  // value={first_name}
                  // onChange={handleChange}
                  placeholder="สมัครซ้ำ/ปลอมสลิป"
                  defaultValue={customerData?.comment}
                  ref={register( { required: true } )}
                  isInvalid={!!errors.comment}
                  // isValid={touched.firstName && !errors.firstName}
                />
                {/* <Form.Control.Feedback></Form.Control.Feedback> */}
                <Form.Control.Feedback type="invalid" tooltip>
                  กรุณาระบุเหตุผล
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            <Button type="submit" variant="warning">ยืนยัน</Button>{'  '}
            <Button variant="secondary" onClick={() => setOpenConfirmBlackListModal( false )}>ยกเลิก</Button>
          </Form>
        </Modal.Body>

        {/* <Modal.Footer>
                    <Button type="submit" variant="warning">ยืนยัน</Button>
                    <Button variant="secondary" onClick={() => setOpenConfirmBlackListModal(false)}>ยกเลิก</Button>
                </Modal.Footer> */}
      </Modal>
      <Modal show={showConfirmResetPassword}>
        <Modal.Header>
          <Modal.Title>ยืนยัน Reset password User {customerData?.user_id}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>กรุณายืนยัน Reset password User {customerData?.user_id}</p>
          {
            ( customerData?.agent_name === "ufa" ) ?
              ( <p>โดย password ใหม่จะเป็น <b style={{ "color": "red" }}>aa ตามด้วยเบอร์มือถือ 10 หลัก </b></p> ) : (
                <p>โดย password ใหม่จะเป็น <b style={{ "color": "red" }}>aa ตามด้วยเบอร์มือถือ 6 หลัก</b></p> )
          }

        </Modal.Body>

        <Modal.Footer>
          {
            ( resetLoading ) ?
              (
                <Button variant="primary" type="button" disabled={true}>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />กำลังดำเนินการ
                </Button>
              ) :
              (
                <>
                  <Button variant="success" onClick={() => onResetPassword()}>ยืนยัน</Button>{'  '}
                  <Button variant="secondary" onClick={() => setShowConfirmResetPassword( false )}>ปิด</Button>
                </>
              )
          }


        </Modal.Footer>
      </Modal>
      <Modal show={openConfirmLockUnlockUserModal} size="lg">
        <Modal.Header>
          <Modal.Title>ล็อค / ปลดล็อด User {customerData?.user_id}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form>
            <Form.Row>
              <Form.Group as={Col} md="6" controlId="validationFormik01">
                <Form.Label>User ID</Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="user_id"
                  placeholder="-"
                  defaultValue={customerData?.user_id}
                  ref={register( { required: true } )}
                  readOnly
                  isInvalid={!!errors.user_id}
                />
                <Form.Control.Feedback type="invalid" tooltip>
                  กรุณาระบุ user id
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} md="6" controlId="validationFormik02">
                <Form.Label>ชื่อ</Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="first_name"
                  placeholder="-"
                  defaultValue={customerData?.first_name}
                  ref={register( { required: true } )}
                  readOnly
                  isInvalid={!!errors.first_name}
                />
                <Form.Control.Feedback type="invalid" tooltip>
                  กรุณาระบุชื่อ
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="validationFormik03">
                <Form.Label>นามสกุล</Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="last_name"
                  placeholder="-"
                  defaultValue={customerData?.last_name}
                  ref={register( { required: true } )}
                  readOnly
                  isInvalid={!!errors.last_name}
                />
                <Form.Control.Feedback type="invalid" tooltip>
                  กรุณาระบุนามสกุล
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} md="3" controlId="validationFormik12">
                <Form.Label>สถานะ ล็อค / ปลดล็อด User</Form.Label>
                <Form.Control as="select"
                              required
                              defaultValue={lockUnlockStatus}
                              onChange={handleLockUnlockChange}
                              isInvalid={lockUnlockStatus === ""}
                >
                  <option value="">Please Select</option>
                  {
                    <>
                      <option
                        key={0}
                        value="lock"
                      >
                        ล็อค
                      </option>
                      <option
                        key={1}
                        value="unlock"
                      >
                        ปลดล็อค
                      </option>
                    </>
                  }
                </Form.Control>
                <Form.Control.Feedback type="invalid" tooltip>
                  กรุณาเลือกสถานะ
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
          </Form>
          {/*<div className="mt-3">*/}
          {/*  <Button type="submit" variant="primary"*/}
          {/*          onClick={() => onSubmitLockUnlockUser( lockUnlockStatus )}>ยืนยัน</Button>{'  '}*/}
          {/*  <Button variant="secondary" onClick={handleLockUnlockClose}>ยกเลิก</Button>*/}
          {/*</div>*/}
        </Modal.Body>
        <Modal.Footer>
          {
            ( resetLoading ) ?
              (
                <Button variant="primary" type="button" disabled={true}>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />กำลังดำเนินการ
                </Button>
              ) :
              (
                <>
                  <Button type="submit" variant="primary"
                          onClick={() => onSubmitLockUnlockUser( lockUnlockStatus )}>ยืนยัน</Button>{'  '}
                  <Button variant="secondary" onClick={handleLockUnlockClose}>ยกเลิก</Button>
                </>
              )
          }
        </Modal.Footer>
      </Modal>
    </>
  );
}