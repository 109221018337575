import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Alert, Button, Col, Form, InputGroup, Modal, Row, Spinner} from "react-bootstrap";
import {useForm} from "react-hook-form";
import { useGlobalStore } from "../../store/globalContext";
import apibank from "../../api/managebank";
import apiconfig from "../../api/config"
import ConfirmDeleteBank from "./ConfirmDeleteBank";
import ConfirmLockBank from "./ConfirmLockBank";
import QRCode from "qrcode.react";
import {notificationsRecoilState, notificationsObjectRecoilState} from '../../store/atoms'
import {useRecoilState} from "recoil";
import {NotificationModal} from "../Header";


const agentNameOptions = [
  {innerText: "ufa", value: "ufa"},
  {innerText: "gclub", value: "gclub"},
];

const crawlerOptions = [
  {innerText: "Internet Banking", value: "curl", default_value: true},
  {innerText: "Mobile Banking", value: "api", default_value: false},
  {innerText: "LINE Notifications", value: "line", default_value: false}
];

const bankTierOptions = [
  {innerText: "บัญชีล่อ", value: "lure"},
  {innerText: "ทั่วไป", value: "normal"},
  {innerText: "VIP", value: "vip"}
]


export const defaultImageBase64Options = {
  "index":0,
  "image_base64":""
};

export default function ModalRegisterBanks(props) {
  // console.log("PROPS: ", props)
  const [{ subscribe, status, login_profile }, dispatch] = useGlobalStore();
  const {action, modalHeader, buttonSubmit, data, show, openUpdateModal, closeUpdateModal, afterCloseModal, officialBank, onClose} = props
  const [sweepAmountField, setSweepAmountField] = useState(false)
  const [error, setError] = useState("")
  const [notificationObject, setNotificationObject]= useRecoilState(notificationsObjectRecoilState)
  const [confirmChangeBankStatusModal, setConfirmChangeBankStatusModal] = useState({
    size: "lg",
    title: "ยืนยันการเปลี่ยนสถานะบัญชี",
    show: false,
    onClose: ()=>console.log("onClose"),
    children: <div>ยืนยันการเปลี่ยนสถานะบัญชี</div>,
    footer: <div>
      <Button onClick={()=>console.log("confirm")}>ยืนยัน</Button>
    </div>,
    afterCloseModal:props.afterCloseModal
  })

  const handleCloseModal = () => {
    // setValue("bank_short_name", "")
    // setError("");
    clearErrors()
    onClose();
  };

  const {register, handleSubmit, watch, errors, setValue, getValues, control, reset, setError: setErrorForm, clearErrors} = useForm({
    defaultValues: {
      bank_code: "",
      bank_short_name: "",
      bank_type: "",
      bank_name: "",
      bank_account_no: "",
      bank_account_name: "",
      username: "",
      password: "",
      auto_threads: "",
      auto_port: "",
      comment: "",
      auto_approve: false,
      is_lure: false,
      auto_sweep: false,
      token: "",
      crawler_type: "",
      enable: false,
      show_customer: false,
      device_id: "",
      pin: "",
      lock: false,
      obsolete: false,
      agent_name: "",
      api_login_token: "",
      wallet_tmn_id: "",
      tmnone_key_id: "",
      kplus_data_raw: "",
      kplus_data: "",
      card_id:"",
      scb_bulk_transfer: false,
      user_ids: ""
    }, criteriaMode: "all",
    // shouldUnregister: false
  });

  const [crawlerValidate, setCrawlerValidate] = useState(false)

  const [bankConn, setBankConn] = useState(false)
  const [isLoadingBankConn, setIsLoadingBankConn] = useState(false)
  const [messageBankConn, setMessageBankConn] = useState("")

  const crawlerCheck = (value) => {
    if (value === 'api') {
      setCrawlerValidate(true)
    } else {
      setCrawlerValidate(false)
    }
  }

  const onSubmit = async registerData => {
    setError("")
    registerData = {...registerData, login_with_qr: Boolean(registerData.login_with_qr === "true")}
    const registerBank = officialBank.filter(ofb => ofb.value === registerData.bank_short_name).pop()
    let registerDataWithBankCode = {}

    // console.log("registerData: ", registerData)

    let url = apibank.BANK_CREATE

    if (action === "update") {
      url = apibank.BANK_UPDATE
      registerDataWithBankCode = {
        ...registerData, bank_code: registerBank.code, id: data?.id, updated_by: login_profile.name
      }
    } else {
      registerDataWithBankCode = {
        ...registerData,
        bank_code: registerBank.code,
        id: data?.id,
        created_by: login_profile.name,
        updated_by: login_profile.name
      }
    }

    if (registerDataWithBankCode.sweep_amount === "0") {
      registerDataWithBankCode = {
        ...registerDataWithBankCode, auto_sweep: false
      }
    } else {
      registerDataWithBankCode = {
        ...registerDataWithBankCode, auto_sweep: false
      }
    }

    await url(registerDataWithBankCode)
        .then(response => {
          // console.log("response:", response)
          dispatch({
            type: "TOAST2",
            value: {
              show: true,
              type: 'success',
              title: `เพิ่ม / แก้ไข ธนาคาร`,
              message: `เพิ่ม / แก้ไข ธนาคาร สำเร็จ`,
            }
          });
          // closeUpdateModal();
          onClose();
          afterCloseModal();
        })
        .catch(error => {
          console.log("update bank list failed :", error.response.data.message);
          dispatch({
            type: "TOAST2",
            value: {
              show: true,
              type: 'danger',
              title: `เพิ่ม / แก้ไข ธนาคาร`,
              message: error.response.data.message,
            }
          });
          setError(error.response.data.message)
        })
  }

  const requestOTP = async () => {
    const objID = data.id
    const requestOTP = {
      "id": objID
    }
    try {
      const response = await apibank.BANK_GENERATE_OTP(requestOTP)
      const {data} = response.data
      // console.log("request OTP response data: ", data)
      setValue("pac", data.pac)
      setValue("token", data.token)
    } catch (e) {
      console.log("error :", e)
      alert("ขออภัยระบบ OTP มีปัญหา กรุณาติดต่อ Admin")
    }
  }

  const confirmOTP = async () => {
    const objID = data.id
    const pac = getValues("pac")
    const token = getValues("token")
    const otp = getValues("otp")

    const request = {
      "id": objID, "pac": pac, "token": token, "otp": otp
    }
    //console.log("object id: ", objID)
    try {
      const response = await apibank.BANK_CONFIRM_OTP(request)
      const {data} = response.data
      //console.log("confirm OTP response data: ", data)
      // closeUpdateModal();
      onClose();
      const bank_account_no = getValues("bank_account_no")
      afterCloseModal();
    } catch (e) {
      console.log("error :", e)
      alert("ขออภัยยืนยัน OTP มีปัญหา กรุณาติดต่อ Admin")
    }
  }

  const [openModal, setIsOpenModal] = useState(false)
  const [validated, setValidated] = useState(false);

  const [selectBankType, setSelectBankType] = useState([
    {innerText: "บัญชีฝาก", value: "deposit"},
    {innerText: "บัญชีถอน", value: "withdraw"},
    {innerText: "บัญชีพัก", value: "holding"},
    {innerText: "บัญชีโยกออก", value: "sweepout"},
    {innerText: "บัญชี Monitor", value: "monitor"},
    {innerText: "บัญชี VVIP", value: "vvip"}
  ])

  const [requireBankTier, setRequireBankTier] = useState(false)

  const [passwordVisible, setPasswordVisible] = useState("password")

  const changeBankType = (value) => {
    //console.log("changeBankType value:", value)
    if (value === "deposit" || value === "withdraw") {
      setRequireBankTier(true)
    } else {
      setRequireBankTier(false)
    }
    setValue("deposit_tier", "")
  }

  const handleTestBanking = async () => {
    setIsLoadingBankConn(true)

    try {
      // const request = watchData
      let request = getValues()
      request = {...request, login_with_qr: Boolean(request.login_with_qr === "true")}

      const response = await apibank.BANK_TESTING(request)
      setBankConn(true)
      setMessageBankConn(response.data.message)
    } catch (e) {
      console.log("test connection error ", e.response.data)
      setMessageBankConn(e.response.data.message)
    } finally {
      setIsLoadingBankConn(false)
    }
  }

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteModalData, setDeleteModalData] = useState({});

  const handleDeleteModalCallBack = () => {
    setShowDeleteModal(false);
    // closeUpdateModal();
    onClose();
    afterCloseModal();
  }

    const handleDelete = () => {
        setDeleteModalData(data);
        setShowDeleteModal(true);
    };

    const [showLockModal, setShowLockModal] = useState(false);
    const [lockModalData, setLockModalData] = useState({});

  const handleLockModalCallBack = () => {
    setShowLockModal(false);
    // closeUpdateModal();
    onClose();
    afterCloseModal();
  }
  const handleLock = (lock, obsolete, action) => {
    setLockModalData({...data, lock, obsolete, action});
    setShowLockModal(true);
  };

  useEffect(() => {
    // console.log("data: ", data)
    if (action === "update") {
      Object.keys(data).map(function (key, index) {
        // console.log("key: ", data[key])
          setValue(key, data[key]);
      });
    }

    if(login_profile.role !== "super_admin"){
      const selectBankTypeFilter = selectBankType.filter(sb=> sb.value !== "sweepout" && sb.value !== "monitor");
      setSelectBankType(selectBankTypeFilter)
    }
    setIsOpenModal(show);
  }, [show]);

  useEffect(() => {
    const bank_type = getValues("bank_type");
    if (bank_type === "deposit" || bank_type === "withdraw") {
      setRequireBankTier(true);
    } else {
      setRequireBankTier(false);
    }

  }, [openModal]);

  const [kplusQrCodeLink, setQRCodeLink] = useState('')
  const getKplusGenAppQR = async()=>{
    try {
      const response = await apiconfig.GET_KPLUS_GEN_QR();
      const {url} = response.data.data
      setQRCodeLink(url)

    } catch (error) {
      console.log("get kplus qr code app link : ", error);
    }
  }

  const [faceImagesInfo, setFaceImagesInfo] = useState([])

  const [crawlerOptionsState, setCrawlerOptionsState] = useState(crawlerOptions)

  const returnImageB64Field = (inputNum)=>{

    if (inputNum === undefined){
      return
    }
    let imageCountNum = inputNum

    if(imageCountNum === 0){
      return (<></>)
    }

    const formComponentList = []

    for(let i=0;i<imageCountNum;i++){
      formComponentList.push(
          <Form.Control
              as="textarea"
              rows={3}
              id={`image_base64_${i}`}
              name={`image_base64.${i}`}
              placeholder={`กรุณาระบุข้อมูลภาพถ่ายที่ ${i+1} ในรูปแบบ Base64`}
              required={true}
              ref={register({
                required: watchBankShortName === "gsb" &&  watchCrawlerType === "api",
              })}
          />)
    }

    return formComponentList
  }

  const getFaceImages = async () => {

    //console.log(data?.id)
    if(data?.id === undefined){
      return
    }

    const request = {
      id:data?.id,
    }

    try {
      const response = await apibank.BANK_VIEW_FACE_IMAGES(request);
      //console.log("get bank list response", { ...response });
      // console.log(response.data.data);
      if (response.status !== 200) {
        console.log("get face images data failed");
        return;
      }
      const { data } = response.data;
      //console.log("face images data: ", data.length)

      setFaceImagesInfo(data)
      setValue("image_count", data.length)

      data.map((d, index)=>{
        //console.log("data d: ", d, " ", index)
        setValue(`image_base64.${index}`, d.image_base64)
      })

    } catch (error) {
      console.log("get face images data : ", error);
    }
  };

  const updateBankingStatus = async (field, status) => {

    if(data.id === undefined){
      return
    }

    let request  ={
      id : data.id,
    }

    if (field === "enable"){
      request["enable"] = status
      if (status === false){
        request["show_customer"] = false
      }
    }

    if(field === "show_customer"){
      request["show_customer"] = status
    }

    // console.log("update banking status request: ", {...request})

    try {
      const response = await apibank.BANK_UPDATE_STATUS(request);

      if (response.status !== 200) {
        console.log("bank update status failed");
        return;
      }
      const { data } = response.data;
      // console.log("bank update status data: ", data)
      setNotificationObject({
        text: `เปลี่ยนสถานะ ${(field === "enable") ? 'บัญชี':'แสดงลูกค้า'} สำเร็จ`, type: "success", icon:<i className={`far fa-check-circle btn-success`}/>
      })
    } catch (error) {
      console.log("bank update status data : ", error);
      setNotificationObject({
        text: `ขออภัยไม่สามารถเปลี่ยนสถานะเปิด/ปิดได้ กรุณาทำรายการใหม่ภายหลัง`, type: "error", icon:<i className={`fas fa-times-circle bg-danger`}/>
      })
    } finally {
      afterCloseModal();
    }
  };

  const validateJSONArray = (data) =>{
    // console.log(" validateJSONArray data: ", data)
    let validateResult=[]
    try{
       validateResult = Array.from(
          data.replace(/\s/g, "").split(",")
      ).filter(m => m.trim && m.length > 0)

      // console.log("validateJSONArray result: ", validateResult)
    }catch (e){
      console.log(e)
      setErrorForm("user_ids", {message:"กรุณาระบุ vvip user id ให้ถูกต้อง"})
    }finally {
      // console.log("validateResult: ", validateResult)
      setValue("user_ids", validateResult)

    }
  }

  const watchLoginWithQR = watch("login_with_qr")
  const watchEditUserName = watch("edit_username_password")
  const watchBankType = watch("bank_type")
  const watchBankShortName = watch("bank_short_name")
  const watchCrawlerType = watch("crawler_type")
  const watchEnable = watch("enable")
  const watchImageCount = watch("image_count")
  const watchEditPin= watch("edit_pin_device_id")



  useEffect(()=>{
    // console.log("watchBankShortName: ", watchBankShortName)
    // console.log("watchCrawlerType: ", watchCrawlerType)
    // console.log("watchBankType: ", watchBankType)

    // if (watchBankShortName === "kbank"){
    //   setCrawlerOptionsState(crawlerOptions.filter(value=>{
    //     return value.value !== "api"
    //   }))
    // }else{
    //   setCrawlerOptionsState(crawlerOptions)
    // }

    if (watchBankShortName === "kbank" && watchCrawlerType === "api"){
      //void getKplusGenAppQR()
    }

  },[watchBankShortName, watchCrawlerType])

  useEffect(()=>{
    // console.log("watchEnable: ", watchEnable)
    if(watchEnable === false){
      setValue("show_customer", false)
    }
    // },[watchData.enable])
  },[watchEnable])

  useEffect(()=>{
    // console.log("watchEditPin: ", watchEditPin)
    if(watchEditPin === true){
      setValue("pin", "")
      setValue("device_id", "")
    }else{
      // console.log("data: ", data)
      setValue("pin", data.pin)
      setValue("device_id", data.device_id)
    }

  },[watchEditPin])

  useEffect(()=>{
    if(watchBankType === "vvip"){
        setValue("crawler_type", "line")
        // console.log("action: ", action)
    }else{
      setValue("crawler_type", "")
    }
  },[watchBankType])


  const ConfirmModalStatus = (field, status)=>{
    // console.log("ConfirmModalStatus: ", field, status)
    let title
    if(field === "enable"){
      title = "ยืนยันเปิด/ปิด การใช้งาน"
    }else{
      title = "ยืนยันเปิด/ปิด แจกบัญชี"
    }
    if("enable"){
      setConfirmChangeBankStatusModal({
        ...confirmChangeBankStatusModal,
        title: title,
        show:true,
        onClose:()=>{
          setValue(field, !status)
          setConfirmChangeBankStatusModal({...confirmChangeBankStatusModal, show:false})
        },
        children: <div>{title}บัญชี {data?.bank_name} {data?.bank_account_no}</div>,
        footer: <div>
          <Button onClick={()=>{
            void updateBankingStatus(field, status);
            setConfirmChangeBankStatusModal({...confirmChangeBankStatusModal, show:false});
          }
          }>ยืนยัน
          </Button>
        </div>
      })
    }

  }
  const validateNumberOnly = (value)=>{
    // console.log("blur value: ", value)
    const pattern = /^[0-9]{10,18}$/;
    // console.log("Test Pattern: ", pattern.test(value))
    if(!pattern.test(value)){
      setValue("bank_account_no","")
    }
  }

  return (
      <>
        <div>
          <ConfirmDeleteBank
              isShow={showDeleteModal}
              data={deleteModalData}
              onClose={() => setShowDeleteModal(false)}
              callBack={handleDeleteModalCallBack}
          />
          <ConfirmLockBank
              isShow={showLockModal}
              data={lockModalData}
              onClose={() => setShowLockModal(false)}
              callBack={handleLockModalCallBack}
          />
          <NotificationModal {...confirmChangeBankStatusModal}>
            {confirmChangeBankStatusModal.children}
          </NotificationModal>
        </div>
        <div>
          <Modal show={show} size="xl" onHide={props.onClose}>
            <Form
                noValidate
                validated={validated}
                onSubmit={handleSubmit(onSubmit)}
            >
              <Modal.Header>
                <Modal.Title>{modalHeader}</Modal.Title>
                <Modal.Title>
                  {
                      action !== "create" && (
                    login_profile.role === "super_admin" ||
                    login_profile.role === "advanced_admin" ||
                    login_profile.role === "admin") ? (
                      <Button variant="danger" onClick={handleDelete}>
                        ลบบัญชี
                      </Button>
                  ) : (
                      <></>
                  )}
                  {action === "update" &&
                  (login_profile.role === "super_admin" ||
                      login_profile.role === "advanced_admin") &&
                  !data?.lock &&
                  !data?.obsolete ? (
                      <Button
                          variant="primary"
                          className={"ml-2"}
                          onClick={() => handleLock(data.lock, true, "obsolete")}
                      >
                        เตรียมยกเลิกใช้บัญชี
                      </Button>
                  ) : (
                      <></>
                  )}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Row>
                  <Form.Group as={Col} md="3" lg="3" controlId="validation00"  hidden={data?.obsolete === true || watchBankType === "sweepout"}>
                    {/* <Form.Label>เปิด/ปิดการทำงาน</Form.Label> */}
                    <InputGroup>
                      <Form.Check
                          type="switch"
                          name="enable"
                          custom
                          label="เปิด/ปิด การใช้งาน"
                          ref={register}
                          // defaultValue={false}
                          // onChange={()=>updateBankingStatus("enable", getValues("enable"))}
                          onChange={()=>ConfirmModalStatus("enable", getValues("enable"))}
                      />
                      <Form.Control.Feedback type="invalid" tooltip>
                        กรุณาเลือกสถานะเปิด/ปิด การใช้งาน"
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group as={Col} md="3" lg="3" controlId="validation01" hidden={action === "create" || watchEnable === false || watchBankType === "sweepout"}>
                    <InputGroup>
                      <Form.Check
                          type="switch"
                          name="show_customer"
                          custom
                          label="เปิด/ปิด แจกบัญชี"
                          ref={register}
                          // defaultValue={false}
                          // onChange={()=>updateBankingStatus("show_customer", getValues("show_customer"))}
                          onChange={()=>ConfirmModalStatus("show_customer", getValues("show_customer"))}
                      />
                      <Form.Control.Feedback type="invalid" tooltip>
                        กรุณาเลือกสถานะเปิด/ปิด แจกลูกค้า
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} md="6" lg="6" controlId="validation02">
                    <Form.Label>เลือกธนาคาร</Form.Label>
                    <InputGroup>
                      <Form.Control
                          as="select"
                          name="bank_short_name"
                          custom
                          required
                          isInvalid={!!errors.bank_short_name}
                          ref={register({ required: true })}
                      >
                        <option value="">Please Select</option>
                        {officialBank.map(({ innerText, value }, index) => (
                            <option
                                key={index}
                                value={value}
                                selected={getValues("bank_short_name") === value}
                            >
                              {innerText}
                            </option>
                        ))}
                      </Form.Control>
                      <Form.Control.Feedback type="invalid" tooltip>
                        กรุณาเลือกธนาคาร
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group as={Col} md="3" lg="3" controlId="validation03">
                    <Form.Label>ประเภทบัญชีที่ใช้</Form.Label>
                    <InputGroup>
                      <Form.Control
                          as="select"
                          name="bank_type"
                          custom
                          required
                          isInvalid={!!errors.bank_type}
                          ref={register({ required: true })}
                          onChange={(e) => changeBankType(e.target.value)}
                      >
                        <option value="">Please Select</option>
                        {selectBankType.map(({ innerText, value }, index) => (
                            <option key={index} value={value}>
                              {innerText}
                            </option>
                        ))}
                      </Form.Control>
                      <Form.Control.Feedback type="invalid" tooltip>
                        กรุณาเลือกประเภทบัญชี
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group as={Col} md="3" lg="3" controlId="validation04">
                    <Form.Label>กรุณาเลือกระดับบัญชี</Form.Label>
                    <InputGroup>
                      <Form.Control
                          as="select"
                          name="deposit_tier"
                          custom
                          required
                          isInvalid={!!errors.deposit_tier}
                          ref={register({ required: requireBankTier })}
                          disabled={!requireBankTier}
                      >
                        <option value="">Please Select</option>
                        {bankTierOptions.map(({ innerText, value }, index) => (
                            <option key={index} value={value}>
                              {innerText}
                            </option>
                        ))}
                      </Form.Control>
                      <Form.Control.Feedback type="invalid" tooltip>
                        กรุณาเลือกระดับของบัญชีถอน
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group
                      as={Col}
                      md="6"
                      lg="6"
                      controlId="validationCustom05"
                  >
                    <Form.Label>ชื่อธนาคาร</Form.Label>
                    <Form.Control
                        required
                        type="text"
                        name="bank_name"
                        placeholder="กรุงศรี AUTO 2424"
                        isInvalid={!!errors.bank_name}
                        ref={register({ required: true })}
                    />
                    <Form.Control.Feedback type="invalid" tooltip>
                      กรุณาระบุชื่อธนาคาร
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                      as={Col}
                      md="6"
                      lg="6"
                      controlId="validationCustom06"
                  >
                    <Form.Label>เลขที่บัญชี (*เฉพาะตัวเลขเท่านั้น)</Form.Label>
                    <Form.Control
                        required
                        type="text"
                        name="bank_account_no"
                        placeholder="038XXXXXXX"
                        isInvalid={!!errors.bank_account_no}
                        ref={register({required: true})}
                        readOnly={action === "update"}
                        onBlur={()=>validateNumberOnly(getValues("bank_account_no"))}
                    />
                    <Form.Control.Feedback type="invalid" tooltip>
                      กรุณาระบุเลขที่บัญชี/และกรอกเฉพาะตัวเลขเท่านั้น
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group
                      as={Col}
                      md="6"
                      lg="6"
                      controlId="validationCustom07"
                  >
                    <Form.Label>ชื่อบัญชี</Form.Label>
                    <Form.Control
                        required
                        type="text"
                        name="bank_account_name"
                        placeholder="อำภา เลขนอก"
                        isInvalid={!!errors.bank_account_name}
                        ref={register({ required: true })}
                    />
                    <Form.Control.Feedback type="invalid" tooltip>
                      กรุณาระบุชื่อบัญชี
                    </Form.Control.Feedback>
                  </Form.Group>
                  {/*{*/}
                  {/*  <Form.Group*/}
                  {/*      as={Col}*/}
                  {/*      md="6"*/}
                  {/*      lg="6"*/}
                  {/*      controlId="validationCustom177"*/}
                  {/*      hidden={watchBankType !== "deposit"}*/}
                  {/*  >*/}
                  {/*    <Form.Label>เลือกเอเย่นต์</Form.Label>*/}
                  {/*    <Form.Control*/}
                  {/*        as="select"*/}
                  {/*        isInvalid={!!errors.agent_name}*/}
                  {/*        name="agent_name"*/}
                  {/*        ref={register}*/}
                  {/*    >*/}
                  {/*      <option value="">ไม่ระบุ</option>*/}
                  {/*      {agentNameOptions.map(({ innerText, value }, index) => (*/}
                  {/*          <option key={index} value={value}>*/}
                  {/*            {innerText}*/}
                  {/*          </option>*/}
                  {/*      ))}*/}
                  {/*    </Form.Control>*/}
                  {/*  </Form.Group>*/}
                  {/*}*/}
                </Form.Row>
              {(action === "update" && watchCrawlerType === "curl")&& (
                    <Form.Row>
                      <Form.Group
                          as={Col}
                          md="3"
                          lg="4"
                          controlId="validationCustom010"
                      >
                        <Form.Label>
                          กรุณาติ้กถูกเมื่อต้องการแก้ไข username & password *
                        </Form.Label>
                        <Form.Check
                            custom
                            label="แก้ไข username & password"
                            name="edit_username_password"
                            ref={register}
                        />
                      </Form.Group>
                    </Form.Row>
                )}
                {(action === "update" && watchCrawlerType === "api")&& (
                    <Form.Row>
                      <Form.Group
                          as={Col}
                          md="3"
                          lg="4"
                          controlId="validationCustom010"
                      >
                        <Form.Label>
                          กรุณาติ้กถูกเมื่อต้องการแก้ไข PIN & Device ID *<p className={"text-red"}>(ไม่แสดงข้อมูลเดิมในทุกๆกรณี เพื่อความปลอดภัย)</p>
                        </Form.Label>
                        <Form.Check
                            custom
                            label="แก้ไข PIN & Device ID"
                            name="edit_pin_device_id"
                            ref={register}
                        />
                      </Form.Group>
                    </Form.Row>
                )}
                <Form.Row>
                  <Form.Group
                      as={Col}
                      sm="12"
                      md="6"
                      lg="2"
                      controlId="validationCustom08"
                  >
                    <Form.Label>Crawler Type*</Form.Label>
                    <Form.Control
                        as="select"
                        custom
                        required
                        isInvalid={!!errors.crawler_type}
                        name="crawler_type"
                        ref={register({
                          required: watchBankType !== "holding",
                        })}
                        onChange={(e) => crawlerCheck(e.target.value)}
                    >
                      <option value="">Please Select</option>
                      {
                        crawlerOptionsState.map(({ innerText, value, default_value }, index) => (
                                <option
                                    key={index}
                                    value={value}
                                    selected={default_value}
                                >
                                  {innerText}
                                </option>
                            )
                        )
                      }
                    </Form.Control>
                    <Form.Control.Feedback type="invalid" tooltip>
                      กรุณาระบุ Crawler Type
                    </Form.Control.Feedback>
                  </Form.Group>
                        <Form.Group
                            as={Col}
                            md="6"
                            lg="6"
                            controlId="validationCustom212"
                            hidden={watchBankType !== "vvip"}
                            className={""}
                        >
                          <Form.Label>
                            กรุณาระบุ UserID สำหรับบัญชีประเภท VVIP
                          </Form.Label>
                           <Form.Control
                             as={"textarea"}
                             required={true}
                             ref={register}
                             isInvalid={!!errors.user_ids}
                             name="user_ids"
                             placeholder={`กรุณาระบุ user id เช่น ufyu0000001,ufyu000002`}
                             className={"mr-2"}
                             rows={"3"}
                             onBlur={(e)=>validateJSONArray(e.target.value)}
                           />
                          <Form.Control.Feedback type="invalid" tooltip>
                            {errors?.user_ids?.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                  {(watchBankShortName === "scb" &&
                      watchCrawlerType === "api") ||
                  (watchBankShortName === "kbank" &&
                      watchCrawlerType === "api") ? (
                      <></>
                  ) : (
                      <>
                        <Form.Group
                            as={Col}
                            md="3"
                            lg="3"
                            controlId="validationCustom09"
                            hidden={
                              ((action === "update" && !watchEditUserName) || watchCrawlerType === "api" || watchBankType === "vvip")
                            }
                        >
                          <Form.Label>Internet Banking Username*</Form.Label>
                          <Form.Control
                              type="text"
                              name="username"
                              autoComplete="new-password"
                              placeholder="username"
                              ref={register({
                          required: (
                                        action === "create" &&
                              watchBankType !== "holding" &&
                              watchBankType !== "vvip" &&
                              watchCrawlerType === "curl"),
                              })}
                        // required={action === "create"}
                              isInvalid={!!errors.username}
                          />
                          <Form.Control.Feedback type="invalid" tooltip>
                            กรุณาระบุ Internet Banking Username
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                            as={Col}
                            md="3"
                            lg="3"
                            controlId="validationCustom10"
                            hidden={
                              ((action === "update" && !watchEditUserName) || watchCrawlerType === "api" || watchBankType === "vvip")
                            }
                        >
                          <Form.Label>Internet Banking Password*</Form.Label>
                          <Form.Control
                              type={passwordVisible}
                              name="password"
                              autoComplete="new-password"
                              placeholder="password"
                              ref={register({
                          required: (
                                        action === "create" &&
                                      watchBankType !== "holding" &&
                                      watchBankType !== "vvip" &&
                                      watchCrawlerType === "curl"
                          ),
                              })}
                        // required={action === "create"}
                              isInvalid={!!errors.password}
                              onFocus={() => setPasswordVisible("text")}
                              onBlur={() => setPasswordVisible("password")}
                          ></Form.Control>
                          <Form.Control.Feedback type="invalid" tooltip>
                            กรุณาระบุInternet Banking Password
                          </Form.Control.Feedback>
                        </Form.Group>
                        {
                          <Form.Group
                              as={Col}
                              md="6"
                              lg="6"
                              controlId="validationCustom80"
                              hidden={watchBankShortName !== "bay"}
                              className={"flex gap-2"}
                          >
                            <Form.Label>
                              กรุณาเลือกประเภทบัญชีกรุงศรี
                            </Form.Label>
                            <Form.Check
                                type="radio"
                                name="login_with_qr"
                                id="login_with_qr_1"
                                custom
                                label="บัญชีกรุงศรี BIZ (แนะนำ)"
                                ref={register}
                                defaultChecked={true}
                                value={false}
                            />
                            <Form.Check
                                type="radio"
                                name="login_with_qr"
                                id="login_with_qr_2"
                                custom
                                label="บัญชีกรุงศรีปกติ"
                                ref={register}
                                value={true}
                            />
                            <Form.Control.Feedback type="invalid" tooltip>
                              กรุณาเลือกสถานะเปิด/ปิด
                            </Form.Control.Feedback>
                          </Form.Group>
                        }
                      </>
                  )}
                </Form.Row>
                <Form.Row>
                  <Form.Group
                      as={Col}
                      md="3"
                      lg="3"
                      className="text-red-500 font-bold"
                  >
                    {watchBankShortName === "bay"
                        ? watchLoginWithQR === "false"
                            ? "บัญชีกรุงศรี biz"
                            : "บัญชีกรุงศรีปกติ"
                        : ""}
                  </Form.Group>
                </Form.Row>
                <Form.Row hidden={watchBankShortName !== "kbank"}>
                  <Form.Group controlId="validationCustom190" as={Row} className={""}>
                    <p className={"text-red text-sm font-bold"}>
                      ** 🚨 แจ้งทุกทีมครับ บัญชีกสิกร ระบบ
                      Mobile Banking (K+) **
                    </p>
                    {' '}
                    <p className={"text-red text-sm font-bold"}>
                      ** ขณะนี้ยังอยู่ระหว่างแก้ไข เนื่องจากธนาคารมีการเปลี่ยนระบบ
                      ถ้าหากใช้งานได้แล้วทางผู้ให้บริการจะแจ้งไป
                      หากมีข้อสงสัยให้สอบถามเพิ่มที่คู่มือในกลุ่ม telegram **
                    </p>
                  </Form.Group>
                  <Form.Group
                      md="4"
                      lg="4"
                      controlId="validationCustom1001"
                      as={Col}
                      hidden={kplusQrCodeLink === ''}
                  >
                    <Form.Label>KPLUS GEN QR Code 2023</Form.Label>
                    <QRCode
                        value={kplusQrCodeLink}
                        size={100}
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row hidden={watchBankShortName !== "gsb"  ||  watchCrawlerType !== "api"}>
                  <Form.Group controlId="validationCustom191" as={Row}>
                    <p className={"text-red text-sm font-bold"}>
                      ** 🚨 ล่าสุด(2023) สำหรับบัญชีออมสิน ทางทีมเปิดให้ใช้งานผ่านแล้ว
                    </p>
                    <p className={"text-red text-sm font-bold"}>
                      ** สามารถศึกษาวิธีใช้งาน โดยศึกษาเพิ่มที่คู่มือในกลุ่ม telegram **
                    </p>
                  </Form.Group>
                </Form.Row>
                <Form.Row hidden={watchBankShortName !== "gsb" ||  watchCrawlerType !== "api"}>
                  {/*<Form.Group controlId="validationCustom1911" as={Col} md={"12"}><Form.Label>ข้อมูลเพิ่มเติม สำหรับธนาคารออมสิน</Form.Label></Form.Group>*/}
                  <Form.Group
                      controlId="validationCustom1911"
                      as={Col}
                      xs={"12"}
                      sm={"12"}
                      md={"3"}
                      lg={"3"}
                      // hidden={kplusQrCodeLink === ''}
                  >
                    <Form.Label>รหัสบัตรประชาชน*</Form.Label>
                    <Form.Control
                        required
                        isInvalid={!!errors.card_id}
                        name="card_id"
                        ref={register({
                          required: watchBankShortName === "gsb" &&  watchCrawlerType === "api",
                        })}
                        // onChange={(e) => crawlerCheck(e.target.value)}
                    />
                    <Form.Control.Feedback type="invalid" tooltip>
                      กรุณาระบุเลขที่บัตรประชาชน
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId="validationCustom1914" as={Col} xs={"12"} sm={"12"} md={"3"} lg={"3"}>
                    <Form.Label>ภาพถ่าย*<b className={"text-danger"}>(สูงสุด 3 ภาพ)</b></Form.Label>
                    <Form.Control
                        as="select"
                        custom
                        required
                        isInvalid={!!errors.gsb_image_count}
                        name="image_count"
                        ref={register({
                          required: watchBankShortName === "gsb" &&  watchCrawlerType === "api",
                        })}
                        // onChange={(e) => crawlerCheck(e.target.value)}
                    >
                      <option key="0" value={"0"} defaultValue={0}>Please Select</option>
                      <option key="1" value={"1"}>1 ภาพ</option>
                      <option key="2" value={"2"}>2 ภาพ</option>
                      <option key="3" value={"3"}>3 ภาพ</option>
                    </Form.Control>
                    <Form.Control.Feedback type="invalid" tooltip>
                      กรุณาระบุเลือกจำนวนภาพถ่าย
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                <Form.Row hidden={watchBankShortName !== "gsb"  ||  watchCrawlerType !== "api"} >
                  <Form.Group controlId="validationCustom1915" as={Col} xs={"12"} sm={"12"} md={"12"} lg={"12"}>
                    <Form.Label>
                      <Button className={"rounded-xl"} onClick={()=>getFaceImages()}>
                        <i className="fas fa-sync-alt" />
                      </Button>
                      {' '} ข้อมูลภาพถ่ายรูปแบบBase64 * <a href={"https://codebeautify.org/image-to-base64-converter"} target="_blank"  rel="noreferrer" className={"text-primary"}>สามารถดูวิธีการทำข้อมูลBase64 ได้ที่นี่</a></Form.Label>
                    {
                      returnImageB64Field(watchImageCount)
                    }
                  </Form.Group>
                  <Form.Control.Feedback type="invalid" tooltip>
                    กรุณาระบุข้อมูลรูปภาพในรูปแบบ B64
                  </Form.Control.Feedback>
                </Form.Row>
                <Form.Row hidden={(action === "update" && watchEditPin === false)}>
                  <Form.Group
                      as={Col}
                      md="3"
                      lg="3"
                      controlId="validationCustom42"
                  >
                    <Form.Label
                        hidden={
                            !(
                                (watchBankShortName === "scb")&&
                                watchCrawlerType === "api"
                            ) &&
                            !(
                                watchBankShortName === "gsb" &&
                                watchCrawlerType === "api"
                            ) &&
                            watchBankShortName !== "truewallet" &&
                            !(
                                watchBankShortName === "kbank" &&
                                watchCrawlerType === "api"
                            )
                        }
                    >
                      {watchBankShortName !== "truewallet"
                          ? (watchBankShortName === "scb" || watchBankShortName === "gsb")
                              ? "Secure Pin* (ตัวเลข 6 หลัก)"
                              : "KPLUS PIN"
                          : "TRUE WALLET PIN"}
                    </Form.Label>
                    <Form.Control
                        type="text"
                        name="pin"
                        placeholder="123456"
                        ref={register({
                      required: (watchBankShortName === "scb" && watchCrawlerType === "api" && (action === "create" || watchEditPin === true)),
                          maxLength: 6,
                          minLength: 6,
                          pattern: {
                            value: /^[0-9]+$/,
                          },
                        })}
                        isInvalid={!!errors.pin}
                        hidden={
                            !(
                                watchBankShortName === "scb" &&
                                watchCrawlerType === "api"
                            ) &&
                            !(
                                watchBankShortName === "gsb" &&
                                watchCrawlerType === "api"
                            ) &&
                            watchBankShortName !== "truewallet" &&
                            !(
                                watchBankShortName === "kbank" &&
                                watchCrawlerType === "api"
                            )
                        }
                    />
                    <Form.Control.Feedback type="invalid" tooltip>
                      กรุณาระบุ Pin ให้ตรงตามเงื่อนไข
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                      as={Col}
                      md="9"
                      lg="9"
                      controlId="validationCustom41"
                      hidden={
                          !(
                              watchBankShortName === "scb" &&
                              watchCrawlerType === "api"
                          ) &&
                          !(
                              watchBankShortName === "gsb" &&
                              watchCrawlerType === "api"
                          )
                      }
                  >
                    <Form.Label
                        hidden={
                            !(
                                watchBankShortName === "scb" &&
                                watchCrawlerType === "api"
                            ) &&
                            !(
                                watchBankShortName === "gsb" &&
                                watchCrawlerType === "api"
                            )
                        }
                    >
                      Device ID*
                    </Form.Label>
                    <Form.Control
                        as="textarea"
                        row={2}
                        sm={12}
                        md={9}
                        lg={9}
                        name="device_id"
                        placeholder="Device_id"
                        ref={register({
                      required:(watchBankShortName === "scb" && watchCrawlerType === "api" && (action === "create" || watchEditPin === true)),
                        })}
                        required
                        isInvalid={!!errors.device_id}
                        hidden={
                            !(
                                watchBankShortName === "scb" &&
                                watchCrawlerType === "api"
                            ) &&
                            !(
                                watchBankShortName === "gsb" &&
                                watchCrawlerType === "api"
                            )
                        }
                    />
                    <Form.Control.Feedback type="invalid" tooltip hidden={
                        !(
                            watchBankShortName === "scb" &&
                            watchCrawlerType === "api"
                        ) &&
                        !(
                            watchBankShortName === "gsb" &&
                            watchCrawlerType === "api"
                        )
                    }>
                      กรุณาระบุ Device ID
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                {/*<Form.Row>*/}
                {/*  <Form.Group*/}
                {/*      as={Col}*/}
                {/*      md="3"*/}
                {/*      lg="3"*/}
                {/*      controlId="validationCustom42"*/}
                {/*      hidden={*/}
                {/*        !(*/}
                {/*            watchBankShortName === "scb" &&*/}
                {/*            watchCrawlerType === "api"*/}
                {/*        )*/}
                {/*      }*/}
                {/*  >*/}
                {/*    <Form.Label>*/}
                {/*      โอนเงินรูปแบบใหม่ (BETA)*/}
                {/*    </Form.Label>*/}
                {/*    <Form.Check*/}
                {/*        type="checkbox"*/}
                {/*        label="โอนเงินรูปแบบใหม่ (BETA)"*/}
                {/*        name="scb_bulk_transfer"*/}
                {/*        id="scb_bulk_transfer"*/}
                {/*        // placeholder="scb_bulk_transfer"*/}
                {/*        custom*/}
                {/*        ref={register}*/}
                {/*        hidden={*/}
                {/*          !(*/}
                {/*              watchBankShortName === "scb" &&*/}
                {/*              watchCrawlerType === "api"*/}
                {/*          )*/}
                {/*        }*/}
                {/*        defaultChecked={false}*/}
                {/*    />*/}
                {/*  </Form.Group>*/}
                {/*</Form.Row>*/}
                <Form.Row>
                  <Form.Group
                      as={Col}
                      md="4"
                      lg="4"
                      controlId="validationCustom501"
                  >
                    <Form.Label
                        hidden={watchBankShortName !== "truewallet"}
                    >
                      API LOGIN TOKEN
                    </Form.Label>
                    <Form.Control
                        type="text"
                        name="api_login_token"
                        placeholder="api_login_token"
                        ref={register({
                          required: watchBankShortName === "truewallet",
                        })}
                        required
                        isInvalid={!!errors.api_login_token}
                        hidden={watchBankShortName !== "truewallet"}
                    />
                    <Form.Control.Feedback type="invalid" tooltip>
                      กรุณาระบุ API LOGIN TOKEN
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                      as={Col}
                      md="4"
                      lg="4"
                      controlId="validationCustom502"
                  >
                    <Form.Label
                        hidden={watchBankShortName !== "truewallet"}
                    >
                      WALLET TMN ID
                    </Form.Label>
                    <Form.Control
                        type="text"
                        name="wallet_tmn_id"
                        placeholder="wallet_tmn_id"
                        ref={register({
                          required: watchBankShortName === "truewallet",
                        })}
                        required
                        isInvalid={!!errors.wallet_tmn_id}
                        hidden={watchBankShortName !== "truewallet"}
                    />
                    <Form.Control.Feedback type="invalid" tooltip>
                      กรุณาระบุ WALLET TMN ID
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                      as={Col}
                      md="4"
                      lg="4"
                      controlId="validationCustom503"
                  >
                    <Form.Label
                        hidden={watchBankShortName !== "truewallet"}
                    >
                      TMNONE KEY ID
                    </Form.Label>
                    <Form.Control
                        type="text"
                        name="tmnone_key_id"
                        placeholder="tmnone_key_id"
                        ref={register({
                          required: watchBankShortName === "truewallet",
                        })}
                        required
                        isInvalid={!!errors.tmnone_key_id}
                        hidden={watchBankShortName !== "truewallet"}
                    />
                    <Form.Control.Feedback type="invalid" tooltip>
                      กรุณาระบุ TMNONE KEY ID
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                <Form.Row
                    hidden={
                        watchBankShortName !== "kbank" ||
                        watchCrawlerType !== "api"
                    }
                >
                  {/*TODO Validate JSON*/}
                  <Form.Group
                      as={Col}
                      md="12"
                      lg="12"
                      controlId="validationCustom1002"
                  >
                    <Form.Label>KPLUS Data Raw (ได้มาจาก KPLUS GEN)</Form.Label>
                    <p className={"text-red text-sm font-bold"}>
                      ** แนะนำให้ Copy & Paste
                      ไม่แนะนำให้พิมพ์เนื่องจากโอกาสเกิดความผิดพลาดสูง **
                    </p>
                    <Form.Control
                        rows="6"
                        as="textarea"
                        name="kplus_data_raw"
                        placeholder="kplus data raw"
                        ref={register({
                          required:
                              watchBankShortName === "kbank" &&
                              watchCrawlerType === "api",
                        })}
                        required
                        isInvalid={!!errors.kplus_data_raw}
                    />
                    <Form.Control.Feedback type="invalid" tooltip>
                      กรุณาระบุ KPLUS Data Raw
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  {watchBankShortName === "scb" && (
                      <Form.Group as={Col} md="12" controlId="validationCustom190">
                        <p className={"text-red text-sm font-bold"}>
                          ** 🚨 แจ้งทุกทีมครับ ในขณะนี้ได้ดำเนินการแก้ไขการใช้งาน
                          SCB (Mobile Banking เรียบร้อยแล้ว) **
                        </p>
                        <p className={"text-red text-sm font-bold"}>
                          ** ถ้าเลือก Mobile Banking
                          สามารถศึกษาวิธีการแก้ไขบัญชีที่มีการใช้อยู่ในระบบใบงาน
                          และบัญชีใหม่ที่ต้องการเพิ่มได้ตามคู่มือนี้ครับ{" "}
                          <a
                              className={"text-blue"}
                              target="_blank"
                              rel="noopener noreferrer"
                              href="https://main.dugsg4t7q5xtb.amplifyapp.com/manual/manual-scb-easy-app-1-device/"
                          >
                            คู่มือ
                          </a>{" "}
                          **
                        </p>
                      </Form.Group>
                  )}
                  <Form.Group as={Col} md="9" controlId="validationCustom19">
                    <Form.Label>Comment เพิ่มเติม</Form.Label>
                    <Form.Control
                        type="text"
                        name="comment"
                        placeholder="สมัครเมื่อ 2020/09/05"
                        ref={register}
                    />
                  </Form.Group>
                </Form.Row>
                {/*<Form.Group>*/}
                {/*  <Form.Check*/}
                {/*    label="เปิดใช้โหมด Auto Approve"*/}
                {/*    name="auto_approve"*/}
                {/*    ref={ register }*/}
                {/*  />*/}
                {/*</Form.Group>*/}
                {messageBankConn}
              </Modal.Body>
              <Modal.Footer>
                {data?.crawler_type === "api" ? (
                    data?.bank_short_name !== "scb" &&
                    data?.bank_short_name !== "kbank" &&
                    data?.bank_short_name !== "gsb"&&
                    !data?.lock &&
                    !data?.obsolete ? (
                        <Button variant="danger" onClick={() => confirmOTP()}>
                          ยืนยัน OTP
                        </Button>
                    ) : (
                        <></>
                    )
                ) : (
                    <></>
                )}
                {action === "create" ? (
                    <>
                      {(watchBankShortName === "scb" &&
                          watchCrawlerType === "api") ||
                      (watchBankShortName === "kbank" &&
                          watchCrawlerType === "api") ? (
                          <>
                            <Button variant="success" type="submit">
                              {buttonSubmit}
                            </Button>
                          </>
                      ) : watchLoginWithQR === "true"? (
                          <>
                            <Button variant="success" type="submit">
                              {buttonSubmit}
                            </Button>
                          </>
                      ) : (
                          <Button
                              disabled={isLoadingBankConn}
                              variant="danger"
                              onClick={!isLoadingBankConn ? handleTestBanking : null}
                          >
                            ทดสอบ Login
                          </Button>
                      )}
                      {watchBankType === "holding" ? (
                          (watchBankShortName === "scb" &&
                              watchCrawlerType === "api") ||
                          (watchBankShortName === "kbank" &&
                              watchCrawlerType === "api") ? (
                              <></>
                          ) : (
                              <Button variant="success" type="submit">
                                {buttonSubmit}
                              </Button>
                          )
                      ) : (
                          <>
                            {bankConn && (
                                <Button variant="success" type="submit">
                                  {buttonSubmit}
                                </Button>
                            )}
                          </>
                      )}
                      <Button variant="secondary" onClick={()=>handleCloseModal()}>
                        ปิด
                      </Button>
                    </>
                ) : (
                    <>
                      {!data?.lock && !data?.obsolete && (
                          <Button variant="success" type="submit">
                            {buttonSubmit}
                          </Button>
                      )}
                      {data?.lock &&
                          (login_profile.role === "super_admin" ||
                              login_profile.role === "advanced_admin") && (
                              <Button
                                  variant="primary"
                                  onClick={() => handleLock(false, data.obsolete, "lock")}
                              >
                                {"ปลดล็อคบัญชี"}
                              </Button>
                          )}
                      {data?.obsolete &&
                          (login_profile.role === "super_admin" ||
                              login_profile.role === "advanced_admin") && (
                              <Button
                                  variant="primary"
                                  onClick={() => handleLock(data.lock, false, "obsolete")}
                              >
                                {"ปลดเตรียมยกเลิกบัญชี"}
                              </Button>
                          )}
                      <Button variant="secondary" onClick={()=>handleCloseModal()}>
                        ปิด
                      </Button>
                    </>
                )}
              </Modal.Footer>
            </Form>
          </Modal>
        </div>
      </>
  );
}