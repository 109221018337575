import {Button, Card, Col, Form} from "react-bootstrap";
import React, {useEffect} from "react";
import api from "../../api/managebank";
import {useForm} from "react-hook-form";
import moment from "moment/moment";
import {useGlobalStore} from "../../store/globalContext";



const dropdownEnable = [
    {innerText:"เปิดใช้งาน", value: "true"},
    {innerText:"ปิดใช้งาน", value: "false"}
]
export default function ManagePayOneXConfig() {

    const { register:registerPayOneX, handleSubmit:handleSubmitPayOneX, setValue:setValuePayOneX, getValues:getValuesPayOneX, trigger:triggerPayOneX, watch:watchPayOneX, formState:{isDirty: isDirtyPayOneX, dirtyFields:dirtyFieldsPayOneX},reset:resetPayOneX } = useForm({
        shouldUnregister: false
    });

    const [{ subscribe, banks, status, login_profile }, dispatch] =
        useGlobalStore();

    const getPayOneXFromAPI = async ()=>{
        try {
            const response =  await api.GET_PAYONEX_CONFIG()
            const {data} = response.data

            // console.log("get payonex config response data: ", data)

            setValuePayOneX("access_key", data.access_key)
            setValuePayOneX("secret_key", data.secret_key)
            // setValuePayOneX("payonex_callback_deposit_url", data.perfectpays_callback_deposit_url)
            // setValuePayOneX("payonex_callback_withdraw_url", data.perfectpays_callback_withdraw_url)
            setValuePayOneX("updated_at", moment(data.updated_at).format("DD/MM/YYYY HH:mm"))
            // setValuePayOneX("enable", data.enable)
            // setValuePayOneX("perfectpays_qr_transfer_enable", data.perfectpays_qr_transfer_enable)
            // setValuePayOneX("perfectpays_bank_transfer_enable", data.perfectpays_bank_transfer_enable)

        }catch (e) {
            console.log("get vizpay config error: ", e, e.response.data)
        }finally {

        }
    }

    const submitPayOneXConfig = async (submitValue)=>{

        console.log("submitPayOneXConfig: ", submitValue)
        let updateObject= {}

        Object.keys(dirtyFieldsPayOneX).map((e)=>{
            updateObject[e] = getValuesPayOneX(e)
        })
        delete updateObject.updated_at

        console.log("submitPayOneXConfig updateObject: ", updateObject)
        // return
        try {

            const response =  await api.UPDATE_PAYONEX_CONFIG(updateObject)
            // console.log("get vizpay config response data : ", response)
            dispatch({
                type: "TOAST2",
                value: {
                    show: true,
                    type: "success",
                    title: `แก้ไขข้อมูล PAYONEX`,
                    message: `แก้ไขข้อมูล PAYONEX สำเร็จ`,
                },
            });
        }catch (e) {
            console.log("get payonex config error: ", e, e.response.data)
            dispatch({
                type: "TOAST2",
                value: {
                    show: true,
                    type: "error",
                    title: `แก้ไขข้อมูล PAYONEX ไม่สำเร็จ`,
                    message: `แก้ไขข้อมูล PAYONEX ไม่สำเร็จ ${e.response.data.message}`,
                },
            });
        }finally {
            resetPayOneX(getValuesPayOneX(),{ keepValues: true })
        }
    }

    const updateEnablePayOneXStatus = async (key, checked)=>{
        // console.log("updateEnablePayOneXStatus checked: ", checked)
        try {
            const response =  await api.UPDATE_PAYONEX_CONFIG({[key]: checked})
            // console.log("get vizpay config response data : ", response)
            dispatch({
                type: "TOAST2",
                value: {
                    show: true,
                    type: "success",
                    title: `แก้ไขสถานะเปิด/ปิด ใช้งาน PAYONEX`,
                    message: `แก้ไขสถานะเปิด/ปิด ใช้งาน PAYONEX สำเร็จ`,
                },
            });
        }catch (e) {
            console.log("get perfectpays config error: ", e, e.response.data)
            dispatch({
                type: "TOAST2",
                value: {
                    show: true,
                    type: "error",
                    title: `แก้ไขสถานะเปิด/ปิด ใช้งาน PAYONEX`,
                    message: `แก้ไขสถานะเปิด/ปิด ใช้งาน PAYONEX ไม่สำเร็จ ${e.response.data.message}`,
                },
            });
        }finally {
            resetPayOneX(getValuesPayOneX())
        }
    }

    useEffect( ()=>{
        void getPayOneXFromAPI();
    },[])

    return(
        <Card className={""}>
            <Card.Header className={"bg-gradient-maroon"}><i className={"fas fa-qrcode pr-2"}/> จัดการข้อมูลแจ้งลูกค้าของ PAYONEX </Card.Header>
            <Card.Body>
                <Form noValidate onSubmit={handleSubmitPayOneX(submitPayOneXConfig)} id={"payonex_form"}>
                    <Form.Row className={"flex my-2"}>
                        <Col className={"col-4"}>
                            <Form.Label>PayOneX Access Keys</Form.Label>
                            <Form.Control id="access_key" name={"access_key"} ref={registerPayOneX}/>
                        </Col>
                        <Col className={"col-5"}>
                            <Form.Label>PayOneX Secret Keys</Form.Label>
                            <Form.Control id="secret_key" name={"secret_key"}  ref={registerPayOneX}/>
                        </Col>
                        <Col className={"col-3"}>
                            <Form.Label>แก้ไขล่าสุดเมื่อ</Form.Label>
                            <Form.Control id="updated_at" name={"updated_at"}  type="datatime" ref={registerPayOneX} readOnly defaultValue={moment(new Date()).format("DD/MM/YYYY HH:mm")}/>
                        </Col>
                    </Form.Row>
                    <Form.Row className={"pt-2"}>
                        <Button type={"submit"} className={"bg-gradient-maroon"} disabled={isDirtyPayOneX === false}>ตั้งค่าจัดการ PAYONEX</Button>
                    </Form.Row>
                </Form>
            </Card.Body>
        </Card>
    )

}