import axios from '../client/axios'
import config from '../config/index'

export default {
    SEARCH_ABNORMAL_STATEMENT: (data) => {
        return axios({
            //headers: {},
            method: 'POST',
            url: `${ config.SEARCH_ABNORMAL_STATEMENT }`,
            data: data,
        })
    },
    UPDATE_ABNORMAL_STATEMENT: (data) => {
        return axios({
            //headers: {},
            method: 'POST',
            url: `${ config.UPDATE_ABNORMAL_STATEMENT }`,
            data: data,
        })
    },
}