import axios from '../client/axios'
import config from '../config/index'

export default {
  SEARCH_AGENTS: (data) => {
    //console.log("search agent api: ", data)
    return axios({
      //headers: {},
      method: 'POST',
      url: `${ config.SEARCH_AGENTS }`,
      data: data,
    })
  },
  UPDATE_AGENT: (data) => {
    //console.log("update agent api: ", data)
    return axios({
      //headers: {},
      method: 'POST',
      url: `${ config.UPDATE_AGENTS }`,
      data: data,
    })
  },
  NEWS_CREATE: (data) => {
    console.log(data)
    return axios({
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      method: 'POST',
      url: `${ config.NEWS_CREATE }`,
      data: data,
    })
  },
  NEWS_SEARCH: (data) => {
    return axios({
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      method: 'POST',
      url: `${ config.NEWS_SEARCH }`,
      data: data,
    })
  },
  NEWS_UPDATE: (data) => {
    return axios({
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      method: 'POST',
      url: `${ config.NEWS_UPDATE }`,
      data: data,
    })
  },
  NEWS_DELETE: (data) => {
    return axios({
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      method: 'POST',
      url: `${ config.NEWS_DELETE }`,
      data: data,
    })
  },
  AGENT_LIST_GCLUB: () => {
    return axios({
      method: `GET`,
      url: `${ config.AGENT_LIST_GCLUB }`
    })
  },
  ADD_CREDIT_GCLUB: (data) => {
    return axios({
      //headers: {},
      method: 'POST',
      url: `${ config.ADD_CREDIT_GCLUB }`,
      data: data,
    })
  },
  ADD_CREDIT_GCLUB_HISTORY: (data) => {
    return axios({
      //headers: {},
      method: 'POST',
      url: `${ config.ADD_CREDIT_GCLUB_HISTORY }`,
      data: data,
    })
  },
  UNLOCK_AGENT_GCLUB: (data) => {
    return axios({
      //headers: {},
      method: 'POST',
      url: `${ config.UNLOCK_AGENT_GCLUB }`,
      data: data,
    })
  },
  GET_ACTIVE_AGENT:(data)=>{
    return axios({
      //headers: {},
      method: 'GET',
      url: `${ config.ACTIVE_AGENT}`,
      // data: data,
    })
  }
}