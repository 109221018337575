import {Button, Card, Col, Form} from "react-bootstrap";
import React, {useEffect} from "react";
import api from "../../api/managebank";
import {useForm} from "react-hook-form";
import moment from "moment/moment";
import {useGlobalStore} from "../../store/globalContext";



const dropdownEnable = [
    {innerText:"เปิดใช้งาน", value: "true"},
    {innerText:"ปิดใช้งาน", value: "false"}
]
export default function ManageWealthWaveConfig() {

    const { register:registerWealthWave, handleSubmit:handleSubmitWealthWave, setValue:setValueWealthWave, getValues:getValuesWealthWave, trigger:triggerWealthWave, watch:watchWealthWave, formState:{isDirty: isDirtyWealthWave, dirtyFields:dirtyFieldsWealthWave},reset:resetWealthWave } = useForm({
        shouldUnregister: false
    });

    const [{ subscribe, banks, status, login_profile }, dispatch] =
        useGlobalStore();

    const getWealthWaveFromAPI = async ()=>{
        try {
            const response =  await api.GET_WEALTHWAVE_CONFIG()
            const {data} = response.data

            // console.log("get wealth wave config response data: ", data)

            setValueWealthWave("merchant_id", data.merchant_id)
            setValueWealthWave("api_key", data.api_key)
            setValueWealthWave("secret_key", data.secret_key)
            setValueWealthWave("updated_at", moment(data.updated_at).format("DD/MM/YYYY HH:mm"))

        }catch (e) {
            console.log("get wealth wave config error: ", e, e.response.data)
        }finally {

        }
    }

    const submitWealthWaveConfig = async (submitValue)=>{

        // console.log("submitWealthWaveConfig: ", submitValue)
        let updateObject= {}

        Object.keys(dirtyFieldsWealthWave).map((e)=>{
            updateObject[e] = getValuesWealthWave(e)
        })
        // console.log("submitWealthWaveConfig updateObject: ", updateObject)

        try {

            const response =  await api.UPDATE_WEALTHWAVE_CONFIG(updateObject)
            // console.log("get vizpay config response data : ", response)
            dispatch({
                type: "TOAST2",
                value: {
                    show: true,
                    type: "success",
                    title: `แก้ไขข้อมูล WEALTHWAVE`,
                    message: `แก้ไขข้อมูล WEALTHWAVE สำเร็จ`,
                },
            });
        }catch (e) {
            console.log("get wealth wave config error: ", e, e.response.data)
            dispatch({
                type: "TOAST2",
                value: {
                    show: true,
                    type: "error",
                    title: `แก้ไขข้อมูล WEALTHWAVE ไม่สำเร็จ`,
                    message: `แก้ไขข้อมูล WEALTHWAVE ไม่สำเร็จ ${e.response.data.message}`,
                },
            });
        }finally {
            resetWealthWave(getValuesWealthWave(), { keepValues: true })
        }
    }

    const updateEnableWealthWaveStatus = async (key, checked)=>{
        // console.log("updateEnableWealthWaveStatus checked: ", checked)
        try {
            const response =  await api.UPDATE_WEALTHWAVE_CONFIG({[key]: checked})
            // console.log("get vizpay config response data : ", response)
            dispatch({
                type: "TOAST2",
                value: {
                    show: true,
                    type: "success",
                    title: `แก้ไขสถานะเปิด/ปิด ใช้งาน WEALTHWAVE`,
                    message: `แก้ไขสถานะเปิด/ปิด ใช้งาน WEALTHWAVE สำเร็จ`,
                },
            });
        }catch (e) {
            console.log("get wealth wave config error: ", e, e.response.data)
            dispatch({
                type: "TOAST2",
                value: {
                    show: true,
                    type: "error",
                    title: `แก้ไขสถานะเปิด/ปิด ใช้งาน WEALTHWAVE`,
                    message: `แก้ไขสถานะเปิด/ปิด ใช้งาน WEALTHWAVE ไม่สำเร็จ ${e.response.data.message}`,
                },
            });
        }finally {
            resetWealthWave(getValuesWealthWave())
        }
    }

    useEffect( ()=>{
        void getWealthWaveFromAPI();
    },[])

    const watchEnable = watchWealthWave("enable")
    const watchQREnable = watchWealthWave("perfectpays_qr_transfer_enable")
    const watchBankEnable = watchWealthWave("perfectpays_bank_transfer_enable")

    return(
        <Card>
            <Card.Header className={"bg-gradient-olive"}><i className={"fas fa-qrcode pr-2"}/> จัดการข้อมูลแจ้งลูกค้าของ WEALTHWAVE </Card.Header>
            <Card.Body>
                <Form noValidate onSubmit={handleSubmitWealthWave(submitWealthWaveConfig)} id={"perfectpays_form"}>
                    <Form.Row className={"flex"}>
                        <Col className={"col-xs-12 col-md-6"}>
                            <Form.Label>WealthWave Merchant ID</Form.Label>
                            <Form.Control id="merchant_id" name={"merchant_id"} ref={registerWealthWave}/>
                        </Col>
                        <Col className={"col-3"}>
                            <Form.Label>แก้ไขล่าสุดเมื่อ</Form.Label>
                            <Form.Control id="updated_at" name={"updated_at"}  type="datatime" ref={registerWealthWave} readOnly defaultValue={moment(new Date()).format("DD/MM/YYYY HH:mm")}/>
                        </Col>
                    </Form.Row>
                    <Form.Row className={"flex my-2"}>
                        <Col className={"col-6"}>
                            <Form.Label>WealthWave API KEY</Form.Label>
                            <Form.Control id="api_key" name={"api_key"} ref={registerWealthWave}/>
                        </Col>
                        <Col className={"col-6"}>
                            <Form.Label>WealthWave Merchant Keys</Form.Label>
                            <Form.Control id="secret_key" name={"secret_key"}  ref={registerWealthWave}/>
                        </Col>
                    </Form.Row>
                    <Form.Row className={"pt-2"}>
                        <Button type={"submit"} className={"bg-gradient-olive"} disabled={isDirtyWealthWave === false}>ตั้งค่าจัดการ WEALTHWAVE</Button>
                    </Form.Row>
                </Form>
            </Card.Body>
        </Card>
    )

}